import { Download } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECPopover } from 'app/components/ECPopover';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { ECTypography } from 'app/components/ECTypography';
import { DownloadItemStatus, ECDownloadListItem } from './ECDownloadListItem';
import { useGetReportsExportListQuery } from 'services/exportApi';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';

const REPORTS_EXPORT_POLLING_INTERVAL = 1 * 60 * 1000; // 1 min

export const ECDownloadReportsButton = () => {
  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const { data: reportsByModule, isLoading } = useGetReportsExportListQuery(
    undefined,
    {
      pollingInterval: REPORTS_EXPORT_POLLING_INTERVAL,
    },
  );

  useEffect(() => {
    if (!isLoading && !!reportsByModule) {
      const readyForDownloadExports = Object.entries(
        reportsByModule || {},
      )?.reduce((acc, [module, reports]) => {
        const readyForDownloadReports = reports?.filter(
          report => report.status === 'Ready for Download',
        );

        if (readyForDownloadReports.length) {
          acc[module] = readyForDownloadReports;
        }

        return acc;
      }, {});

      const downloadedExportsIds = JSON.parse(
        localStorage.getItem('downloadedExportsIds') || '[]',
      );
      const newDownloadedReportsIds: string[] = [];
      Object.entries(readyForDownloadExports).forEach(([module, reports]) => {
        (reports as any)?.forEach((report, index) => {
          const hasDownloaded = downloadedExportsIds?.includes(
            report.uuid?.toString(),
          );

          if (!hasDownloaded) {
            // Timeout is necessary because some browsers don't accept fast clicks.
            setTimeout(
              () => {
                const hiddenElement = document.createElement('a');

                hiddenElement.id = report.uuid;
                hiddenElement.href = report.link || '';
                hiddenElement.download = report.title;

                document.body.appendChild(hiddenElement);

                hiddenElement.click();
                document.body.removeChild(hiddenElement);
              },
              1000 * (index + 1),
            );

            newDownloadedReportsIds.push(report.uuid);
          }
        });
      });

      if (newDownloadedReportsIds.length) {
        localStorage.setItem(
          'downloadedExportsIds',
          JSON.stringify([
            ...(downloadedExportsIds || []),
            ...newDownloadedReportsIds,
          ]),
        );
      }
    }
  }, [isLoading, reportsByModule]);

  const handleDownloadIconClick = (event: React.MouseEvent<any>) => {
    setAnchorElDownload(event?.currentTarget);
  };

  const handleCloseDownloadPopup = () => {
    setAnchorElDownload(null);
  };

  const openDownloadPopup = Boolean(anchorElDownload);
  const idDownloadPopup = 'download-popover';

  if (_.isEmpty(reportsByModule) && !isLoading) {
    return null;
  }

  return (
    <ECBox display="flex" mx={2}>
      <ECToggleButton
        value="download"
        size="small"
        sx={{
          backgroundColor: 'white',
          '&:hover': {
            bgcolor: theme => theme.palette.common.white,
          },
        }}
        selected={!!anchorElDownload}
        onClick={e => handleDownloadIconClick(e)}
      >
        <Download />
      </ECToggleButton>

      <ECPopover
        open={openDownloadPopup}
        anchorEl={anchorElDownload}
        sx={{
          '& .MuiPopover-paper': {
            width: '378px',
            bgcolor: theme => theme.palette.common.white,
          },
        }}
        onClose={handleCloseDownloadPopup}
        id={idDownloadPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" flexDirection="column" px={3} py={2}>
          <ECTypography variant="h6">Exporting</ECTypography>
        </ECBox>

        <ECBox display="flex" flexDirection="column" px={3} py={2}>
          <ECTypography variant="body2">
            Your files are almost ready! We'll automatically start the download
            in your browser once it's finished. You can track the progress by
            clicking the Download icon in the top right corner of the top bar.
          </ECTypography>
        </ECBox>

        <ECBox display="flex" flexDirection="column" px={3} py={2} gap={2}>
          {Object.entries(reportsByModule || {})?.map(([module, reports]) => {
            return (
              <ECDownloadListItem
                title={module}
                items={reports?.map(report => ({
                  id: report.id?.toString(),
                  uuid: report.uuid,
                  title: report.job,
                  status: DownloadItemStatus[report.status],
                  link: report.link,
                }))}
                onRetryClick={item => console.log('retry item : ', item)}
              />
            );
          })}
        </ECBox>
      </ECPopover>
    </ECBox>
  );
};
