import { Helmet } from 'react-helmet-async';
import {
  ECMainTabBox,
  ECGrid,
  ECPanelBreadcrumbs,
  ECStatisticsCard,
} from 'app/components';
import { ECTabContainer } from '../../components/ECTabs';
import ECHorizontalBarChart from '../../components/ECBarCharts/HorizontalBarChart';
import {
  AccountBalance,
  Construction,
  FilePresent,
  PendingActions,
  Receipt,
  StackedBarChart,
} from '@mui/icons-material';
import { faker } from '@faker-js/faker';
import ECDefaultLineChart from '../../components/ECLineCharts/DefaultLineChart';
import ECVerticalBarChart from '../../components/ECBarCharts/VerticalBarChart';
import ECBubbleChart from '../../components/ECBubbleChart';
import ECDoughnutChart from '../../components/ECDoughnutCharts';
import ECPieChart from '../../components/ECPieChart';
import ECPolarChart from '../../components/ECPolarChart';
import ECRadarChart from '../../components/ECRadarChart';
import ECReportsBarChart from '../../components/ECBarCharts/ReportsBarChart';
import ECReportsLineChart from '../../components/ECLineCharts/ReportsLineChart';

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function FirstReports() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECHorizontalBarChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'Dataset 2',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECDefaultLineChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'Dataset 2',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECVerticalBarChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'Dataset 2',
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 1000 }),
                ),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECBubbleChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: labels,
            datasets: [
              {
                label: 'Red dataset',
                data: Array.from({ length: 10 }, () => ({
                  x: faker.datatype.number({ min: -100, max: 100 }),
                  y: faker.datatype.number({ min: -100, max: 100 }),
                  r: faker.datatype.number({ min: 5, max: 20 }),
                })),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'Blue dataset',
                data: Array.from({ length: 10 }, () => ({
                  x: faker.datatype.number({ min: -100, max: 100 }),
                  y: faker.datatype.number({ min: -100, max: 100 }),
                  r: faker.datatype.number({ min: 5, max: 20 }),
                })),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

function SecondReports() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECDoughnutChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: {
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColors: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(255, 159, 64, 0.5)',
              ],
              borderWidth: 0,
            },
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECPieChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: {
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColors: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(255, 159, 64, 0.5)',
              ],
              borderWidth: 1,
            },
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECPolarChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: {
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(255, 159, 64, 0.5)',
              ],
              borderWidth: 1,
            },
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECRadarChart
          title="daily sales"
          color={theme => theme.palette.primary.main}
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          icon={{
            component: <StackedBarChart sx={{ color: 'white' }} />,
          }}
          chart={{
            labels: [
              'Thing 1',
              'Thing 2',
              'Thing 3',
              'Thing 4',
              'Thing 5',
              'Thing 6',
            ],
            datasets: [
              {
                label: '# of Votes',
                data: [2, 9, 3, 5, 2, 3],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

function ThirdReports() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECReportsBarChart
          color="info"
          title="website views"
          description="Last Campaign Performance"
          date="campaign sent 2 days ago"
          chart={{
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            datasets: {
              label: 'Sales',
              data: [50, 20, 10, 22, 50, 10, 40],
            },
          }}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECReportsLineChart
          color="success"
          title="daily sales"
          description={
            <>
              (<strong>+15%</strong>) increase in today sales.
            </>
          }
          date="updated 4 min ago"
          chart={{
            labels: [
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: {
              label: 'Mobile apps',
              data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
            },
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

function Statistics() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<Construction sx={{ color: 'white' }} />}
          title="Current Period Repair"
          count={281}
          data={[
            { title: 'Spend', value: '$17.180' },
            { title: 'Committed', value: '$21.542' },
            { title: 'Budget', value: '$12,000' },
          ]}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={6}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<PendingActions sx={{ color: 'white' }} />}
          title="Current Period Maintenance"
          count={281}
          data={[
            { title: 'Spend', value: '$1,992' },
            { title: 'Committed', value: '$12,864' },
            { title: 'Budget', value: '$7.200' },
          ]}
        />
      </ECGrid>
      <ECGrid item xs={12}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<AccountBalance sx={{ color: 'white' }} />}
          title="Current Period Capital Expense"
          count={281}
          data={[
            { title: 'Spend', value: '$50' },
            { title: 'Committed', value: '$1,396' },
            { title: 'Budget', value: '$24,000' },
          ]}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={12} lg={6}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<Receipt sx={{ color: 'white' }} />}
          title="Invoice"
          count={281}
          dataTitle={'MTD'}
          data={[
            { title: 'Amount', value: '$18,506' },
            { title: 'Average', value: '$208' },
            { title: 'Count', value: '89' },
          ]}
          additionalDataTitle={'YTD'}
          additionalData={[
            { title: 'Amount', value: '$18,506' },
            { title: 'Average', value: '$208' },
            { title: 'Count', value: '89' },
            { title: 'YTD Budget', value: '$310,000' },
          ]}
        />
      </ECGrid>
      <ECGrid item xs={12} sm={12} md={12} lg={6}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<FilePresent sx={{ color: 'white' }} />}
          title="Proposal"
          count={281}
          dataTitle={'MTD'}
          data={[
            { title: 'Amount', value: '$71.512' },
            { title: 'Average', value: '$1.255' },
            { title: 'Count', value: '57' },
          ]}
          additionalDataTitle={'YTD'}
          additionalData={[
            { title: 'Amount', value: '$71.512' },
            { title: 'Average', value: '$1.255' },
            { title: 'Count', value: '57' },
          ]}
        />
      </ECGrid>
      <ECGrid item xs={12}>
        <ECStatisticsCard
          color={theme => theme.palette.primary.main}
          icon={<FilePresent sx={{ color: 'white' }} />}
          title="Work Orders"
          count={281}
          dataTitle={'Committed'}
          data={[
            { title: 'Amount', value: '$222.811' },
            { title: 'Average', value: '$423' },
            { title: 'Count', value: '527' },
          ]}
          additionalData={[
            { title: 'Emergency (L1)', value: '4' },
            { title: 'Pending SP', value: '66' },
            { title: 'Open 90+', value: '480' },
            { title: 'No Invoice', value: '293' },
          ]}
        />
      </ECGrid>
    </ECGrid>
  );
}

export function ReportingPage() {
  return (
    <>
      <Helmet>
        <title>Reports Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainTabBox component={'main'}>
        <ECGrid container sx={{ padding: 2.3 }}>
          <ECGrid item xs={12}>
            <ECPanelBreadcrumbs />
          </ECGrid>
          <ECGrid item xs={12}>
            <ECTabContainer
              tabs={[
                {
                  value: '7',
                  label: 'First Reports',
                  content: <FirstReports />,
                },
                {
                  value: '8',
                  label: 'Second Reports',
                  content: <SecondReports />,
                },
                {
                  value: '9',
                  label: 'Third Reports',
                  content: <ThirdReports />,
                },
                { value: '10', label: 'Statistics', content: <Statistics /> },
              ]}
            />
          </ECGrid>
        </ECGrid>
      </ECMainTabBox>
    </>
  );
}
