import { AssetDetails } from 'types/Asset';
import { ECBox } from '../ECBox';
import { ECDivider } from '../ECDivider';
import { ECGrid } from '../ECGrid';
import { ECImage } from '../ECImage';
import { ECTypography } from '../ECTypography';
import { ECWarrantyBadge } from '../ECWarrantyBadge';

interface ECAutocompleteAssetItemProps {
  asset: AssetDetails;
  onClick?: (asset: AssetDetails) => void;
}

export const ECAutocompleteAssetItem = ({
  asset,
  onClick,
}: ECAutocompleteAssetItemProps) => {
  const renderAttribute = (name: string, value?: string) => {
    return (
      <ECBox display="flex" mt={1}>
        <ECTypography fontWeight="bold">{name}:&nbsp;</ECTypography>

        <ECTypography variant="body1">{value || '-'}</ECTypography>
      </ECBox>
    );
  };

  const handleClick = () => {
    onClick?.(asset);
  };

  if (!asset) {
    return <></>;
  }

  return (
    <ECBox
      p={1}
      onClick={handleClick}
      sx={{ cursor: onClick ? 'pointer' : 'auto' }}
    >
      <ECBox
        borderColor={theme => theme.palette.other.divider}
        boxShadow={2}
        p={2}
        border="1px"
        borderRadius={2}
        bgcolor={theme => theme.palette.common.white}
      >
        <ECBox display="flex" alignItems="center">
          <ECImage
            sx={{
              height: '40px',
              width: '40px',
              objectFit: 'scale-down',
              mr: 2,
            }}
            alt={`Asset ${asset.name}`}
            src={asset.photo}
          />
          <ECTypography variant="h6">{asset.name}</ECTypography>
        </ECBox>
        <ECDivider />
        <ECGrid container mt={1}>
          <ECGrid item>
            <ECBox display="flex" flexDirection="column">
              {renderAttribute('Asset Group', asset?.assetType?.name)}
              {renderAttribute('Asset ID', asset?.id?.toString())}
              {renderAttribute('Area', asset?.area?.name || asset?.areaName)}
            </ECBox>
          </ECGrid>

          <ECGrid item marginLeft="auto" marginRight={'auto'}>
            <ECBox display="flex" flexDirection="column">
              {renderAttribute(
                'Manufacturer',
                asset.manufacture?.name || asset.manufactureName,
              )}
              {renderAttribute('Model Number', asset?.modelNumber)}
              {renderAttribute('Serial Number', asset?.serialNumber)}
            </ECBox>
          </ECGrid>
        </ECGrid>
        {asset?.warrantyBadge && (
          <ECWarrantyBadge
            warrantyBadgeType={asset?.warrantyBadge}
            optionalSx={{
              marginTop: '10px',
              justifySelf: 'center',
              width: 'fit-content',
            }}
          />
        )}
      </ECBox>
    </ECBox>
  );
};
