import { InputAdornment } from '@mui/material';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECBox } from '../ECBox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { ECIconButton } from '../ECIconButton';
import { ECButton } from '../ECButton';
import { useTranslation } from 'react-i18next';
import { ECTextField } from '../ECTextField';
import { FilterFieldOption } from '.';
import { Close, SearchOutlined } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ECCircularProgress } from '../ECCircularProgress';

const DEFAULT_MIN_SEARCH_LENGTH = 1;

interface ECSimpleSearchProps {
  cols?: any[];
  hiddenCols?: any[];
  minSearchLength?: number;
  onFilterSubmit?: () => void;
  statusFilterOptions?: FilterFieldOption[];
  pmId?: string;
  isSendingRequest?: boolean;
}

export const ECSimpleSearch = ({
  cols,
  hiddenCols,
  minSearchLength = DEFAULT_MIN_SEARCH_LENGTH,
  onFilterSubmit,
  statusFilterOptions,
  pmId,
  isSendingRequest
}: ECSimpleSearchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [simpleSearchQuery, setSimpleSearchQuery] = useState<string>(
    activeFilter?.simpleSearchQuery || '',
  );

  const [showSimpleSearchErrorMessage, setShowSimpleSearchErrorMessage] =
    useState<boolean>(false);

    const [isAutoSearchCompleted, setIsAutoSearchCompleted] =
    useState<boolean>(false);

    useEffect(() => {
        if (!(pmId && cols && cols?.length > 0 && !isAutoSearchCompleted)) return;
        handleRunSimpleSearch();
        setIsAutoSearchCompleted(true);
        navigate(location.pathname, { replace: true });
    }, [cols]);

  const cleanupSimpleSearch = useCallback(() => {
    setSimpleSearchQuery('');
    setShowSimpleSearchErrorMessage(false);
    dispatch(
      setActiveFilter({
        filterFields: [],
        isAdvanced: false,
        endpoint,
      }),
    );
  }, []);

  const handleRunSimpleSearch = useCallback(() => {
    if (simpleSearchQuery?.trim()?.length < minSearchLength) {
      setShowSimpleSearchErrorMessage(true);
      return;
    }

    if (!cols?.length && !hiddenCols?.length) return;

    onFilterSubmit?.();
    setShowSimpleSearchErrorMessage(false);
    const newFilterFields: any = [
      ...cols ?? [],
      ...hiddenCols ?? [],
    ]
      ?.filter(col => col.searchable)
      ?.map(col => ({
        ...col,
        type: col.type,
        value: simpleSearchQuery?.trim(),
        fieldName: col.alias || col.fieldName,
      }));
    if (pmId && !isAutoSearchCompleted) {
      dispatch(
        setActiveFilter({
          filterFields:
            newFilterFields.filter(field => field.name === 'request.pmId') ??
            [],
          isAdvanced: true,
          statuses:
            statusFilterOptions?.map(option => option.fieldName) ||
            activeFilter?.statuses,
          endpoint,
        }),
      );
      return;
    }
    dispatch(
      setActiveFilter({
        filterFields: newFilterFields,
        isAdvanced: false,
        simpleSearchQuery,
        endpoint,
      }),
    );
  }, [simpleSearchQuery, cols, hiddenCols, minSearchLength]);

  const handleSimpleSearchKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleRunSimpleSearch();
      }
    },
    [handleRunSimpleSearch],
  );

  // if there is a simple search value on load, run simple search query
  // this is a fix because the query with search params was running without the COLS fields (that come from BE)
  // so it would not search for the Simple Search nor Advanced Search after going back one page
  useEffect(() => {
    if (simpleSearchQuery) {
      handleRunSimpleSearch();
    }
  }, [cols?.length]);

  useEffect(() => {
    if (pmId) {
      setSimpleSearchQuery(pmId);
    }
  }, []);

  return (
    <>
        <ECBox
            borderColor={theme => theme.palette.action.active}
            sx={{
                tabIndex: '0',
                display: 'flex',
                width: '440px',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: 0,
                p: 0.5,
            }}
        >
            <ECTextField
                variant="standard"
                sx={{
                    minWidth: '100%',
                    '.MuiFormHelperText-root': {
                    color: 'error.main',
                    },
                }}
                placeholder={t('translation:table.search')}
                value={simpleSearchQuery}
                helperText={
                    showSimpleSearchErrorMessage
                    ? t('translation:table.atLeastNChars', {
                        minLength: minSearchLength,
                        })
                    : undefined
                }
                onKeyDown={handleSimpleSearchKeyDown}
                onChange={event => {
                    setSimpleSearchQuery(event.target.value);
                    if (!event.target.value) {
                        cleanupSimpleSearch();
                    }
                }}
                InputProps={{
                    endAdornment: simpleSearchQuery ? (
                    <InputAdornment position="end">
                        <ECIconButton onClick={cleanupSimpleSearch}>
                        <Close />
                        </ECIconButton>
                    </InputAdornment>
                    ) : (
                    <></>
                    ),
                }}
            />
        </ECBox>
        <ECButton
            variant="contained"
            sx={{ height: '40px', width: '40px', minWidth: '40px' }}
            onClick={handleRunSimpleSearch}
        >
            {isSendingRequest ? (
            <ECCircularProgress
                size="24px"
                sx={{
                color: theme => theme.palette.background.paper,
                }}
            />
            ) : (
            <SearchOutlined />
            )}
        </ECButton>
    </>
  );
};
