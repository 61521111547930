import { ECButton, ECEasyForm } from 'app/components';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  dashboardApi,
  useUpdateMultipleStatusMutation,
} from 'services/dashboardApi';
import {
  BulkApprovalStatusUpdateAction,
  StatusActions,
} from 'types/WorkflowStatus';
import { DashboardActionProps } from '.';
import { setSnackbar } from 'store/slice/page';
import { useGetApprovalActionsQuery } from 'services/approvalApi';

const fancyFormRejectProposal = require('../form/fancy_form_config_reject_proposal.json');
const fancyFormRejectInvoice = require('../form/fancy_form_config_reject_invoice.json');

function RejectionContent({
  onClose,
  onCancel,
  selectedRows,
  module,
  queryTag,
}: DashboardActionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doUpdate, { data: updateStausData, isSuccess, isError, isLoading }] =
    useUpdateMultipleStatusMutation();
  const { data: actions, isSuccess: isSuccessActions } =
    useGetApprovalActionsQuery();
  const [clearAction, setClearAction] = useState<() => void>();

  const handleSubmit = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const note = fieldData.find(data => data.fieldName === 'note')?.value;
      const itemIds = selectedRows.map(row => row.id);
      const rejectActionId = actions?.find(
        action => action.name === StatusActions.REJECT,
      )?.id;
      const statusUpdateList: BulkApprovalStatusUpdateAction = {
        itemIds,
        actionId: rejectActionId || 0,
        note,
        declineReason: note,
      };
      doUpdate({ module, statusUpdateList });
      setClearAction(clearOnSuccess);
    },
    [doUpdate, module, selectedRows, actions],
  );

  useEffect(() => {
    clearAction?.();
    const failedCount = updateStausData?.failed?.length;
    const successCount = updateStausData?.success?.length;
    const failedIds = updateStausData?.failed?.map(item => item.id);

    if (isSuccess && failedCount === 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were rejected.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isSuccess && failedCount > 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were rejected. ${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be rejected.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isSuccess && failedCount > 0 && successCount === 0) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be rejected.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `Error rejecting some ${module}s`,
        }),
      );
      onClose();
    }
  }, [dispatch, updateStausData, isError, isSuccess, onClose]);

  return (
    <ECEasyForm
      pattern="modal"
      config={
        module === 'proposal'
          ? fancyFormRejectProposal.config
          : fancyFormRejectInvoice.config
      }
      fields={
        module === 'proposal'
          ? fancyFormRejectProposal.fields
          : fancyFormRejectInvoice.fields
      }
      isSendingData={isLoading}
      isLoadingForm={false}
      onFormSubmit={handleSubmit}
      saveButtonColor="error"
      additionalActions={
        <ECButton
          type="button"
          variant="text"
          sx={{ height: 42 }}
          onClick={onCancel}
        >
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
    />
  );
}

export const rejectAction = permissions => ({
  buttonLabel: 'Reject',
  disabledRule: (selection: number) => selection < 1,
  modalContent:
    requirePermissionWrapper<DashboardActionProps>(RejectionContent),
  scopes: permissions,
});
