import { useEffect, useMemo, useState } from 'react';
import ReactFlow, { useEdgesState, useNodesState, MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { ECBox } from '../ECBox';
import { BoxProps } from '@mui/material';
import { themes } from 'styles/theme/themes';

interface Node {
  id: string;
  label: string;
}

interface Edge {
  id: string;
  label?: string;
  source: string;
  target: string;
}

interface ECGraphProps extends BoxProps {
  nodes: Node[];
  edges: Edge[];
  initialSelectedNodeId?: string;
}

export const ECGraph = ({
  nodes: initialNodes,
  edges: initialEdges,
  initialSelectedNodeId,
  width,
  height,
}: ECGraphProps) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<any>(null);

  const graphInitialNodes = useMemo(
    () =>
      initialNodes?.map((node, index) => ({
        id: node.id,
        position: {
          x: index === 0 ? 200 : index % 2 === 0 ? 0 : 400,
          y: index * 100,
        },
        data: { label: node.label },
        style: {
          backgroundColor:
            node.id === initialSelectedNodeId
              ? themes.dark.palette.other.selectedNodeColor
              : '',
        },
      })) ?? [],
    [initialNodes],
  );

  const graphInitialEdges = useMemo(
    () =>
      initialEdges?.map((edge, index) => ({
        ...edge,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
        },
      })) ?? [],
    [initialEdges],
  );

  useEffect(() => {
    setNodes(graphInitialNodes);
    setEdges(graphInitialEdges);
  }, [graphInitialNodes, graphInitialEdges]);

  useEffect(() => {
    if (!!selectedNode) {
      const newEdges = graphInitialEdges.filter(
        edge => edge.source !== selectedNode.id,
      );
      const edgesFromSelectedNode = graphInitialEdges
        .filter(edge => edge.source === selectedNode.id)
        .map(edge => ({
          ...edge,
          markerEnd: {
            ...edge.markerEnd,
            color: themes.dark.palette.other.selectedEdgeColor,
          },
          style: {
            strokeWidth: 2,
            stroke: themes.dark.palette.other.selectedEdgeColor,
          },
        }));
      const targetNodeIds = edgesFromSelectedNode.map(edge => edge.target);
      newEdges.push(...edgesFromSelectedNode);

      const untouchedNodes = graphInitialNodes.filter(
        node => node.id !== selectedNode.id,
      );
      const newNodes = untouchedNodes.filter(
        node => !targetNodeIds.includes(node.id),
      );
      const targetNodes = untouchedNodes.filter(node =>
        targetNodeIds.includes(node.id),
      );

      targetNodes.forEach(node => {
        return newNodes.push({
          ...node,
          style: {
            backgroundColor: themes.dark.palette.other.selectedNodeTargetColor,
          },
        });
      });
      newNodes.push({
        ...selectedNode,
        style: {
          ...selectedNode.style,
          backgroundColor: themes.dark.palette.other.selectedNodeSourceColor,
        },
      });

      setEdges(newEdges);
      setNodes(newNodes);
    }
  }, [selectedNode, graphInitialEdges, graphInitialNodes]);

  return (
    <ECBox width={width} height={height}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeClick={(_event, node) => setSelectedNode(node)}
        fitView
      />
    </ECBox>
  );
};
