import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const drawerWidth = 900;
export const ECDrawerDetails = styled(Drawer)<DrawerProps>(({ theme }) => ({
  width: drawerWidth,
  minHeight: '100vh',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    border: 'none',
  },
}));
