import React from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import { ECGrid } from '../ECGrid';
import { ECStack } from '../ECStack';
import { BoxProps } from '@mui/material';
import { ECIconButton } from '../ECIconButton';

interface Steps {
  text: string;
  icon?: any;
}

export interface ECStepsDescriptionProps extends BoxProps {
  title: string;
  steps: Steps[];
  onClose?: () => void;
}

export const ECStepsDescription = ({
  title,
  steps,
  onClose,
  ...boxProps
}: ECStepsDescriptionProps) => {
  const renderHowItWorksStep = (
    text: string,
    stepNumber: number,
    icon: any,
  ) => (
    <ECGrid item xs key={stepNumber}>
      <ECStack direction="row" spacing={4}>
        <ECBox display="flex" alignItems="center">
          {stepNumber !== 1 && <ArrowForwardIos />}
        </ECBox>
        <ECBox display="flex" flexDirection="column" alignItems="center">
          {!!icon && icon}
          <ECTypography mt={2} variant="body1" textAlign="center">
            {stepNumber}. {text}
          </ECTypography>
        </ECBox>
      </ECStack>
    </ECGrid>
  );

  return (
    <ECBox
      display="flex"
      color="white"
      bgcolor={theme => theme.palette.primary.dark}
      p={4}
      width="100%"
      justifyContent="center"
      flexDirection="column"
      position="relative"
      {...boxProps}
    >
      {onClose && (
        <ECBox display="flex" position="absolute" top={16} right={16}>
          <ECIconButton squared onClick={onClose} sx={{ borderColor: 'white' }}>
            <Close sx={{ color: 'white' }} />
          </ECIconButton>
        </ECBox>
      )}
      <ECTypography variant="h3" textAlign="center">
        {title}
      </ECTypography>

      <ECGrid
        mt={5}
        container
        columnSpacing={4}
        zeroMinWidth
        justifySelf="center"
      >
        {steps.map((step, index) =>
          renderHowItWorksStep(step.text, index + 1, step.icon),
        )}
      </ECGrid>
    </ECBox>
  );
};
