import { QueryParams } from 'types/QueryParams';
import { JobTitleList, JobTitleMutate } from 'types/JobTitle';
import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['JobTitle'],
});

export const jobTitleApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getJobTitlesList: builder.query<BaseType<JobTitleList[]>, QueryParams>({
      query: params => {
        return {
          url: `jobtitle`,
          params,
        };
      },
      providesTags: ['JobTitle'],
    }),
    createJobTitle: builder.mutation<void, JobTitleMutate>({
      query: body => {
        return {
          url: 'jobtitle',
          method: 'post',
          body: body,
        };
      },
      invalidatesTags: ['JobTitle'],
    }),
    updateJobTitle: builder.mutation<void, JobTitleMutate>({
      query: (jobTitle: JobTitleMutate) => {
        const { id = 0 } = jobTitle;
        return {
          url: `jobtitle/${id}`,
          method: 'put',
          body: jobTitle,
        };
      },
      invalidatesTags: ['JobTitle'],
    }),
    getJobTitlesRoles: builder.query<any, void>({
      query: () => {
        return {
          url: '/role',
          params: {
            t: 0,
          },
        };
      },
    }),
    getJobTitlesByCompanyId: builder.query<any, number>({
      query: companyId => {
        return {
          url: `jobtitle/company/${companyId}`,
        };
      },
    }),
  }),
});

export const {
  useGetJobTitlesListQuery,
  useLazyGetJobTitlesListQuery,
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
  useGetJobTitlesRolesQuery,
  useGetJobTitlesByCompanyIdQuery,
  useLazyGetJobTitlesByCompanyIdQuery,
} = jobTitleApi;
