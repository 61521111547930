import { QueryParams } from 'types/QueryParams';
import {
  PriorityCreate,
  PriorityListFlat,
  PriorityUpdate,
} from 'types/Priority';
import { BaseType } from 'types/BaseType';
import { TIMEFRAME } from 'app/components/ECHourETA';

import { emptyApi } from './emptyApi';
import { profileApi } from './profileApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Priorities'],
});

export const prioritiesApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getPrioritiesList: builder.query<BaseType<PriorityListFlat[]>, QueryParams>(
      {
        query: params => {
          return {
            url: 'priority',
            params,
          };
        },
        providesTags: ['Priorities'],
      },
    ),
    getPrioritiesListForSP: builder.query<
      BaseType<PriorityListFlat[]>,
      {
        moduleName: string;
        moduleId: number;
        companyId?: number;
        queryParams?: QueryParams;
      }
    >({
      query: ({ moduleName, moduleId, companyId, queryParams }) => {
        return {
          // if no company have to send 0 otherwise will return 404 since it expects company id to exists
          url: `priority/${moduleName}/${moduleId}/${companyId || 0}`,
          params: queryParams,
        };
      },
      providesTags: ['Priorities'],
    }),
    createPriorities: builder.mutation<void, PriorityCreate>({
      query: body => {
        let etaHours = body.eta?.value;

        if (body.eta?.timeframe === TIMEFRAME.Day) {
          etaHours = etaHours * 24;
        }
        const updateBody = {
          ...body,
          eta: etaHours,
          overTimeApproval: body.overTimeApproval ? 1 : 0,
          etaStartAtSeven: body.etaStartAtSeven ? 1 : 0,
          etaStartAtSevenMonday: body.etaStartAtSevenMonday ? 1 : 0,
        };
        return {
          url: 'priority',
          method: 'post',
          body: updateBody,
        };
      },
      invalidatesTags: ['Priorities'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(profileApi.util.invalidateTags(['CompanyConfiguration']));
      },
    }),
    updatePriorities: builder.mutation<void, PriorityUpdate>({
      query: ({ id, ...body }) => {
        let etaHours = body.eta?.value;

        if (body.eta?.timeframe === TIMEFRAME.Day) {
          etaHours = etaHours * 24;
        }
        const updateBody = {
          ...body,
          eta: etaHours,
        };

        return {
          url: `priority/${id}`,
          method: 'put',
          body: updateBody,
        };
      },
      invalidatesTags: ['Priorities'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(profileApi.util.invalidateTags(['CompanyConfiguration']));
      },
    }),
  }),
});

export const {
  useGetPrioritiesListQuery,
  useLazyGetPrioritiesListQuery,
  useUpdatePrioritiesMutation,
  useCreatePrioritiesMutation,
  useGetPrioritiesListForSPQuery,
  useLazyGetPrioritiesListForSPQuery,
} = prioritiesApi;
