import React, { useMemo } from 'react';
import { ECBox } from 'app/components/ECBox';
import { ECChip } from 'app/components/ECChip';
import { ECTypography } from 'app/components/ECTypography';
import { themes } from 'styles/theme/themes';
import { ECIconButton } from 'app/components/ECIconButton';
import { Download, Replay } from '@mui/icons-material';
import * as _ from 'lodash';

export enum DownloadItemStatus {
  Queued = 'Queued',
  'In Progress' = 'In Progress',
  'Ready for Download' = 'Completed',
  Failed = 'Failed',
}

interface DownloadItem {
  uuid: string;
  id: string;
  title: string;
  status: DownloadItemStatus;
  link?: string;
}

interface DownloadListItemProps {
  title: string;
  items: DownloadItem[];
  onRetryClick: (item: DownloadItem) => void;
}

const mapStatusToChipStyle = {
  [DownloadItemStatus.Queued]: {
    color: themes.light.palette.secondary.outlinedHoverBackground,
    textColor: themes.light.palette.secondary.dark,
  },
  [DownloadItemStatus['In Progress']]: {
    color: themes.light.palette.primary.outlinedHoverBackground,
    textColor: themes.light.palette.primary.dark,
  },
  [DownloadItemStatus['Ready for Download']]: {
    color: themes.light.palette.success.outlinedHoverBackground,
    textColor: themes.light.palette.success.dark,
  },
  [DownloadItemStatus.Failed]: {
    color: themes.light.palette.error.outlinedHoverBackground,
    textColor: themes.light.palette.error.dark,
  },
};

export const ECDownloadListItem = ({
  title,
  items,
  onRetryClick,
}: DownloadListItemProps) => {
  const handleDownloadClick = (item: DownloadItem) => {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = item.link || '';
    hiddenElement.download = item.title;
    hiddenElement.click();

    const downloadedExportsIds = JSON.parse(
      localStorage.getItem('downloadedExportsIds') || '[]',
    );

    localStorage.setItem(
      'downloadedExportsIds',
      JSON.stringify(_.uniq([...(downloadedExportsIds || []), item.uuid])),
    );
  };

  const renderDownloadItems = useMemo(() => {
    return items?.map(item => (
      <ECBox
        key={item?.uuid}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ECTypography variant="caption">Export {item?.id}</ECTypography>

        <ECBox display="flex" alignItems="center" gap={2}>
          <ECChip
            variant="filled"
            label={item.status}
            color={mapStatusToChipStyle[item.status]?.color}
            textColor={mapStatusToChipStyle[item.status]?.textColor}
            sx={{ cursor: 'default' }}
          />

          <ECIconButton
            sx={{
              visibility:
                item.status === DownloadItemStatus['Ready for Download'] ||
                item.status === DownloadItemStatus.Failed
                  ? 'visible'
                  : 'hidden',
            }}
            onClick={
              item.status === DownloadItemStatus['Ready for Download']
                ? () => handleDownloadClick(item)
                : () => onRetryClick(item)
            }
          >
            {item.status === DownloadItemStatus['Ready for Download'] ? (
              <Download />
            ) : (
              <Replay />
            )}
          </ECIconButton>
        </ECBox>
      </ECBox>
    ));
  }, [items, onRetryClick]);

  return (
    <ECBox display="flex" flexDirection="column" gap={2}>
      <ECBox display="flex" flexDirection="column">
        <ECTypography
          variant="subtitle2"
          color={theme => theme.palette.text.secondary}
        >
          {`${_.startCase(title)} Module Export`}
        </ECTypography>
      </ECBox>

      {renderDownloadItems}
    </ECBox>
  );
};
