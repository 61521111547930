import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { P } from 'types/Permission';
import { DashboardActionProps } from '.';

import { ECButton, FieldTypes } from 'app/components';
import { ECEasyForm } from 'app/components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const form = {
  config: {
    variant: 'simple',
    title: 'Add a comment to Unassigned the Work Order',
    cols: 1,
    submitTitle: 'Unassign',
  },
  fields: [
    {
      type: FieldTypes.Text,
      label: 'Comment',
      fieldName: 'note',
      requiredFieldName: 'Comment',
      placeholder: 'Comment',
      maxLength: 1000,
      required: true,
    },
  ],
};

function UnassignContent({
  onCancel,
  module,
  customAction,
  isLoadingCustomAction,
}: DashboardActionProps) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const note = fieldData.find(data => data.fieldName === 'note')?.value;
      if (customAction) {
        customAction(note);
      }
    },
    [module, customAction],
  );

  return (
    <ECEasyForm
      pattern="modal"
      config={form.config}
      fields={form.fields}
      drawerTitleBarBGColor="transparent"
      isSendingData={isLoadingCustomAction as boolean}
      isLoadingForm={false}
      onFormSubmit={handleSubmit}
      additionalActions={
        <ECButton
          type="button"
          variant="text"
          sx={{ height: 42 }}
          onClick={onCancel}
        >
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
    />
  );
}

export const unassignAction = {
  buttonLabel: 'Cancel',
  disabledRule: (selectionLength: number) => selectionLength < 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(UnassignContent),
  scopes: [P.ChangeWorkorderStatus],
};
