import { useEffect } from 'react';

export const useAddRecaptchaScript = () => {
  useEffect(() => {
    const existingScript = document.getElementById('captcha-snippet');

    if (!existingScript && process.env.REACT_APP_RECAPTCHA_SRC) {
      const script = document.createElement('script');
      script.id = 'captcha-snippet';
      script.src = process.env.REACT_APP_RECAPTCHA_SRC;
      script.defer = true;
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }

    return () => {
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);
};
