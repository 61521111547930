import { Add } from '@mui/icons-material';
import { ECWorkflowTemplate, ECButton } from 'app/components';
import { Outlet, useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import {
  useGetGeneralAssetByIdQuery,
  useUpdateGeneralAssetMutation,
} from 'services/generalAssetApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useTranslation } from 'react-i18next';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetAreaListQuery } from 'services/areaApi';
import { useGetBranchTypesQuery } from 'services/branchApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { GeneralAssetUpdate } from 'types/GeneralAsset';
import { HTTPError } from 'types';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import * as _ from 'lodash';
import { useCreateServiceRequestContext } from 'store/contexts';
import { useCustomerUser } from 'app/hooks';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { P } from 'types/Permission';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

export function GeneralAssetDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const hasEditGeneralAssetPermission = useHasPermission([P.EditGeneralAsset]);

  const isCustomer = useCustomerUser();
  const [validations, setValidations] = useState<any>([]);
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const { data: generalAsset, isSuccess } = useGetGeneralAssetByIdQuery(
    id ? Number(id) : 0,
  );

  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;

  const [cancelEdit, setCancelEdit] = useState<boolean>(false);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues(prevSummaryFieldsValues => ({
      ...prevSummaryFieldsValues,
      [fieldName]: value,
    }));
  };

  const initializeFields = () => {
    if (isSuccess) {
      setSummaryFieldsValues({
        name: generalAsset?.name,
        tagId: generalAsset?.tagId,
        status: generalAsset?.status ? 1 : 0,
        assetType: generalAsset?.assetType,
        branchType: generalAsset?.branchType,
        area: generalAsset?.area,
        priorityId: generalAsset?.priority?.id || 0,
        nte: generalAsset?.nte,
      });
    }
  };

  useEffect(() => {
    if (generalAsset) {
      initializeFields();
    }
  }, [generalAsset, isSuccess]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
  );

  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = fancyFormElementsEdit.fields.find(
        field => field.fieldName === 'flags',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlag, flagsData]);

  const { data: dataPriorities } = useGetPrioritiesListQuery({});

  const handleChangeAssetType = newAssetGroup => {
    if (newAssetGroup?.nte !== undefined) {
      handleSummaryFieldChange('nte')(newAssetGroup?.nte);
    }
    if (newAssetGroup?.priority) {
      handleSummaryFieldChange('priorityId')(newAssetGroup?.priority?.id);
    }
    handleSummaryFieldChange('assetType')(newAssetGroup);
  };

  const [
    doUpdateGeneralAsset,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateGeneralAsset,
    },
  ] = useUpdateGeneralAssetMutation();

  const useUpdateGeneralAsset = useCallback(() => {
    setCancelEdit(false);
    const doUpdate = async data => {
      if (!generalAsset?.id) {
        return;
      }

      const generalAssetBody: GeneralAssetUpdate = {
        id: generalAsset?.id,
        name: summaryFieldsValues?.name,
        nte: summaryFieldsValues?.nte,
        assetTypeId: summaryFieldsValues?.assetType?.id,
        branchTypeIds: summaryFieldsValues?.branchType?.map(
          branchType => branchType?.id,
        ),
        areaId: summaryFieldsValues?.area?.id,
        priorityId:
          summaryFieldsValues?.priorityId?.data ||
          summaryFieldsValues?.priorityId,
        tagId: summaryFieldsValues?.tagId,
        status: summaryFieldsValues.status ? 1 : 0,
        flagsIds: data.flags.map(f => f.id),
      };

      const originalGeneralAssetBody = {
        areaId: generalAsset.area?.id,
        assetTypeId: generalAsset.assetType?.id,
        branchTypeIds: generalAsset.branchType,
        flagsIds: generalAsset.flags.map(f => f.id),
        id: generalAsset.id,
        name: generalAsset.name,
        nte: generalAsset.nte,
        priorityId: generalAsset.priority?.id,
        status: generalAsset.status,
        tagId: generalAsset.tagId,
      };

      const isEqual = _.isEqual(generalAssetBody, originalGeneralAssetBody);

      if (isEqual) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: t('translation:dynamicForm.updateNothing'),
          }),
        );
        setCancelEdit(true);
        return;
      }

      let validation: any = [];

      if (!generalAssetBody.name) {
        validation.push({
          field: 'name',
          validationMessage: 'Asset Name is required!',
        });
      } else if (generalAssetBody.name.length < 3) {
        validation.push({
          field: 'name',
          validationMessage: 'Asset Name must be at least 3 characters!',
        });
      }
      if (!generalAssetBody.assetTypeId) {
        validation.push({
          field: 'assetType',
          validationMessage: 'Asset Group is required!',
        });
      }
      if (!generalAssetBody.priorityId) {
        validation.push({
          field: 'priority',
          validationMessage: 'Priority is required!',
        });
      }
      if (!generalAssetBody.nte) {
        validation.push({
          field: 'nte',
          validationMessage: 'NTE is required!',
        });
      }

      if (validation.length > 0) {
        setValidations(validation);
        return false;
      } else {
        setValidations([]);
        if (!generalAssetBody.areaId) {
          generalAssetBody.areaId = null;
        }
        if (!generalAssetBody.branchTypeIds) {
          generalAssetBody.branchTypeIds = null;
        }
        doUpdateGeneralAsset(generalAssetBody);
      }
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateGeneralAsset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    summaryFieldsValues,
    resetUpdateGeneralAsset,
    generalAsset?.id,
    doUpdateGeneralAsset,
    flagsData,
    validations,
    generalAsset,
    cancelEdit,
  ]);

  useEffect(() => {
    if (updateError) {
      const { data: errorData } = updateError as HTTPError;
      const message = Array.isArray(errorData?.description)
        ? errorData?.description?.[0]
        : errorData?.description || errorData.message;

      if (message && errorData.fieldNameWithError) {
        setValidations([
          {
            field: errorData.fieldNameWithError,
            validationMessage: message,
          },
        ]);
      } else {
        dispatch(
          setSnackbar({
            severity: 'error',
            message:
              (updateError as any)?.data?.message ||
              t('translation:dynamicForm.updateError', {
                name: 'General Asset',
              }),
          }),
        );
      }
    }
  }, [updateError]);

  const { data: attachments } = useGetAttachmentQuery({
    module: 'general-asset',
    moduleId: generalAsset?.id ?? 0,
    category: AttachmentCategory.Photo,
  });

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { dispatch: dispatchServiceRequest } = useCreateServiceRequestContext();

  const handleOpenServiceRequestDrawer = () =>
    dispatchServiceRequest({
      type: 'open',
      payload: { asset: generalAsset, isGeneralAsset: true },
    });

  return (
    <>
      {generalAsset && (
        <ECWorkflowTemplate
          tabsAsLinks
          title={`${t('translation:workflow.sidebar.generalAsset')} ${
            generalAsset.name
          }`}
          showEditTitleStartAdornment={true}
          checkNothingToUpdateEditForm={false}
          cancelEdit={cancelEdit}
          summaryData={[
            {
              id: 'asset',
              label: 'Asset',
              defaultData: generalAsset?.name ?? '',
              data: summaryFieldsValues?.name ?? '',
              type: SummaryFieldTypes.Text,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'name'),
              validationMessage: validations?.find(v => v.field === 'name')
                ?.validationMessage,
              onChange: handleSummaryFieldChange('name'),
              maxLength: 25,
              required: true,
            },
            {
              id: 'assetId',
              label: 'Asset ID',
              defaultData: generalAsset?.id ?? '',
              data: generalAsset?.id ?? '',
              type: SummaryFieldTypes.Text,
              isEditable: false,
            },
            {
              id: 'state',
              label: 'State',
              defaultData: generalAsset?.status ?? 0,
              data: summaryFieldsValues?.status,
              type: SummaryFieldTypes.State,
              isEditable: true,
              onChange: handleSummaryFieldChange('status'),
            },
            {
              id: 'assetGroup',
              label: 'Asset Group',
              data: summaryFieldsValues?.assetType ?? {},
              type: SummaryFieldTypes.SelectPaginated,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'assetType'),
              validationMessage: validations?.find(v => v.field === 'assetType')
                ?.validationMessage,
              useQuery: useGetAssetTypesListQuery,
              onChange: handleChangeAssetType,
              obAlias: 'assttp.name',
            },
            {
              id: 'area',
              label: 'Area',
              data: summaryFieldsValues?.area ?? {},
              type: SummaryFieldTypes.SelectPaginated,
              isEditable: true,
              useQuery: useGetAreaListQuery,
              onChange: handleSummaryFieldChange('area'),
              isVisible: Boolean(areaEnabled),
            },
            {
              id: 'priority',
              label: 'Priority',
              data:
                summaryFieldsValues?.priorityId?.data ||
                summaryFieldsValues?.priorityId ||
                0,
              type: SummaryFieldTypes.Select,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'priority'),
              validationMessage: validations?.find(v => v.field === 'priority')
                ?.validationMessage,
              onChange: handleSummaryFieldChange('priorityId'),
              options: dataPriorities?.data?.map(prrts => ({
                label: prrts.name,
                data: prrts.id,
              })),
            },
            {
              id: 'concept',
              label: 'Concept',
              data: summaryFieldsValues?.branchType,
              type: SummaryFieldTypes.AutocompleteChips,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'branchType'),
              validationMessage: validations?.find(
                v => v.field === 'branchType',
              )?.validationMessage,
              useQuery: useGetBranchTypesQuery,
              onChange: handleSummaryFieldChange('branchType'),
              limitTags: 3,
              obAlias: 'name',
            },
            {
              id: 'nte',
              label: 'NTE',
              data: summaryFieldsValues?.nte ?? '',
              type: SummaryFieldTypes.Currency,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'nte'),
              validationMessage: validations?.find(v => v.field === 'nte')
                ?.validationMessage,
              onChange: handleSummaryFieldChange('nte'),
            },
            {
              id: 'tagId',
              label: 'Tag ID',
              defaultData: generalAsset?.tagId ?? '',
              data: summaryFieldsValues?.tagId ?? '',
              type: SummaryFieldTypes.Text,
              isEditable: true,
              isValid: !validations?.some(v => v.field === 'tagId'),
              validationMessage: validations?.find(v => v.field === 'tagId')
                ?.validationMessage,
              onChange: handleSummaryFieldChange('tagId'),
            },
          ]}
          editConfig={fancyFormElementsEdit.config}
          editFields={fancyFormElementsEdit.fields}
          detailsConfig={formElementsDetails.data.config}
          detailsFields={formElementsDetails.data.fields}
          detailsData={generalAsset}
          imgSrc={assetProfileAttachment?.url}
          profileAttachment={assetProfileAttachment}
          moduleName={'general-asset'}
          moduleId={id}
          useUpdateMutation={useUpdateGeneralAsset}
          additionalTabs={[
            {
              value: 'activity',
              label: t('translation:dynamicForm.activity'),
              scopes: [P.GetGeneralAsset],
              content: <Outlet />,
              link: 'activity',
              editable: false,
            },
          ]}
          detailTabsOrder={['details', 'attachments', 'activity']}
          /* generalAsset={{
            href: id?.toString() ?? '',
            text: generalAsset?.name,
          }} */
          hideNoteTab
          hideActivityTab
          disableAditionalActionWhenEditing={true}
          additionalActions={
            isCustomer && generalAsset && Number(generalAsset.status) > 0
              ? [
                  <ECButton
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpenServiceRequestDrawer}
                  >
                    {t('translation:navBar.createServiceRequest')}
                  </ECButton>,
                ]
              : undefined
          }
          onExitEditModeWithoutSave={() => initializeFields()}
          disableTabsOnEdit={true}
          isEditAllowed={hasEditGeneralAssetPermission[0]}
        />
      )}
    </>
  );
}
