import { ECBox, ECButton } from 'app/components';
import { useTranslation } from 'react-i18next';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { StyleConstants } from 'styles/StyleConstants';
import { useCallback, useState } from 'react';
import { NewStandAloneProposalForm } from './NewStandAloneProposalForm';
import { Add } from '@mui/icons-material';

export const NewStandAloneProposal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ECButton
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        variant="outlined"
      >
        Add New
      </ECButton>

      <ECDrawerDetails
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={<NewStandAloneProposalForm onClose={handleClose} />}
            showOnlyDetailsTab={true}
            onClose={() => setOpen(false)}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
