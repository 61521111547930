import { Workflow, WorkflowStatus } from 'types/Workflow';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Workflow'],
});

export const workflowApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getWorkflowById: builder.query<Workflow, number>({
      query: id => {
        return {
          url: `workflow/${id}`,
        };
      },
    }),
    getWorkflowGraphById: builder.query<Workflow, number>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `workflow/${id}/graph`,
        };
      },
    }),
    getAllWorkflowStatus: builder.query<WorkflowStatus[], string>({
      query: moduleName => {
        if (!moduleName) {
          return {
            url: '',
          };
        }

        return {
          url: `workflow/status/${moduleName}/all`,
        };
      },
      transformResponse: (response: WorkflowStatus[]) => {
        return response.filter(item => item.groupId);
      },
    }),
    getAssigneeUsers: builder.query<
      any[],
      { module: 'proposal' | 'invoice'; id: number }
    >({
      query: args => {
        if (!args.id || !args.module) {
          return {
            url: '',
          };
        }

        return {
          url: `/workflow/${args.module}/${args.id}/assignee-users`,
        };
      },
    }),
  }),
});

export const {
  useGetWorkflowByIdQuery,
  useLazyGetWorkflowByIdQuery,
  useGetWorkflowGraphByIdQuery,
  useLazyGetWorkflowGraphByIdQuery,
  useGetAllWorkflowStatusQuery,
  useLazyGetAllWorkflowStatusQuery,
  useGetAssigneeUsersQuery,
} = workflowApi;
