import { Videocam } from '@mui/icons-material';
import { ECTableCell } from '../ECTable';

interface ECVideoTableCellProps {
  src?: string;
}

export const ECVideoTableCell = ({ src }: ECVideoTableCellProps) => (
  <ECTableCell
    sx={{ height: '100%', alignItems: 'center' }}
    onClick={e => e.stopPropagation()}
  >
    {src && (
      <Videocam sx={theme => ({ color: theme.palette.action.disabled })} />
    )}
  </ECTableCell>
);
