import { useMemo } from 'react';

// porp-types is a library for typechecking of props
// import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Line } from 'react-chartjs-2';

// Material Dashboard 2 React components
import {
  ECBox,
  ECChartContainerCard,
  ECChartIconContainerCard,
  ECChartTitleContainerBox,
  ECTypography,
} from 'app/components';

// DefaultLineChart configurations
import configs from './configs';

function ECDefaultLineChart({
  icon,
  color,
  title,
  description,
  height,
  chart,
}) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map(dataset => ({
        ...dataset,
        tension: 0,
        pointRadius: 3,
        borderWidth: 4,
        backgroundColor: 'transparent',
        fill: true,
        pointBackgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <ECBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <ECBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <ECChartIconContainerCard color={color}>
              {icon.component}
            </ECChartIconContainerCard>
          )}
          <ECChartTitleContainerBox icon={icon}>
            {title && <ECTypography variant="h6">{title}</ECTypography>}
            <ECBox mb={2}>
              <ECTypography variant="button" color="text">
                {description}
              </ECTypography>
            </ECBox>
          </ECChartTitleContainerBox>
        </ECBox>
      ) : null}
      {useMemo(
        () => (
          <ECBox height={height}>
            {/* <Line data={data} options={options} /> */}
          </ECBox>
        ),
        [data, height, options],
      )}
    </ECBox>
  );

  return title || description ? (
    <ECChartContainerCard>{renderChart}</ECChartContainerCard>
  ) : (
    renderChart
  );
}

// Setting default values for the props of DefaultLineChart
ECDefaultLineChart.defaultProps = {
  icon: { color: 'info', component: '' },
  title: '',
  description: '',
  height: '19.125rem',
};

// Typechecking props for the DefaultLineChart
// ECDefaultLineChart.propTypes = {
//   icon: PropTypes.shape({
//     color: PropTypes.oneOf([
//       'primary',
//       'secondary',
//       'info',
//       'success',
//       'warning',
//       'error',
//       'light',
//       'dark',
//     ]),
//     component: PropTypes.node,
//   }),
//   title: PropTypes.string,
//   description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   chart: PropTypes.objectOf(PropTypes.array).isRequired,
// };

export default ECDefaultLineChart;
