import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'PurchaseOrder',
    'PurchaseOrderItemsForDropdown',
    'PurchaseOrderById',
  ],
});

export const purchaseOrder = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAllPurchaseOrderList: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        const { st, ...restParams } = params;
        return {
          url: `purchaseorder`,
          params: {
            ...restParams,
          },
        };
      },
      providesTags: ['PurchaseOrder'],
    }),
    getPurchaseOrderById: builder.query<any, number>({
      query: id => {
        return {
          url: `purchaseorder/${id}`,
        };
      },
      providesTags: ['PurchaseOrderById'],
    }),
    getPurchaseOrderItemsForDropdown: builder.query<
      BaseType<any[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'purchaseorder/items/dropdown',
          params,
        };
      },
      providesTags: ['PurchaseOrderItemsForDropdown'],
    }),
    createPurchaseOrder: builder.mutation<void, any>({
      query: body => {
        return {
          url: 'purchaseorder',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['PurchaseOrder'],
    }),
    updatePurchaseOrder: builder.mutation<void, any>({
      query: ({ id, ...body }) => {
        return {
          url: `purchaseorder/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['PurchaseOrderById'],
    }),
  }),
});

export const {
  useGetAllPurchaseOrderListQuery,
  useLazyGetAllPurchaseOrderListQuery,
  useGetPurchaseOrderItemsForDropdownQuery,
  useLazyGetPurchaseOrderItemsForDropdownQuery,
  useGetPurchaseOrderByIdQuery,
  useLazyGetPurchaseOrderByIdQuery,
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
} = purchaseOrder;
