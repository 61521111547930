import React, { useReducer } from 'react';

type Action = { type: 'open'; payload?: any } | { type: 'close' };
type Dispatch = (action: Action) => void;
type State = { isOpen: boolean; data: any; currentStep: number };

const CreateServiceRequestContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function createServiceRequestReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open': {
      return {
        isOpen: true,
        data: action.payload,
        currentStep: 2,
      };
    }
    case 'close': {
      return {
        isOpen: false,
        data: undefined,
        currentStep: 1,
      };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface CreateServiceRequestProviderProps {
  children: React.ReactNode;
}

export const CreateServiceRequestProvider = ({
  children,
}: CreateServiceRequestProviderProps) => {
  const [state, dispatch] = useReducer(createServiceRequestReducer, {
    isOpen: false,
    data: undefined,
    currentStep: 1,
  });

  const value = { state, dispatch };

  return (
    <CreateServiceRequestContext.Provider value={value}>
      {children}
    </CreateServiceRequestContext.Provider>
  );
};

export const useCreateServiceRequestContext = () => {
  const context = React.useContext(CreateServiceRequestContext);

  if (context === undefined) {
    throw new Error(
      'useCreateServiceRequestContext must be used within a CreateServiceRequestProvider',
    );
  }

  return context;
};
