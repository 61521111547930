import { QueryParams } from 'types/QueryParams';
import {
  AssetTypeToNameCreate,
  AssetTypeToNameList,
  AssetTypeToNameUpdate,
  AssetTypeToNameListQueryParams,
  AssetTypeToNameResponse,
} from 'types/AssetTypeToName';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['AssetTypeToName'],
});

export const assetTypeToNameApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetTypesToNameList: builder.query<
      BaseType<AssetTypeToNameList[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'asset-group/name',
          params,
        };
      },
      providesTags: ['AssetTypeToName'],
    }),
    getAssetTypesToNameByTypeIdBrachTypeId: builder.query<
      BaseType<AssetTypeToNameResponse[]>,
      AssetTypeToNameListQueryParams
    >({
      query: params => {
        if (!params.assetTypeId || !params.branchTypeId) {
          return {
            url: '',
          };
        }
        return {
          url: `asset-group/${params.assetTypeId}/branch/${params.branchTypeId}/name`,
        };
      },
      providesTags: ['AssetTypeToName'],
    }),
    createAssetTypeToName: builder.mutation<void, AssetTypeToNameCreate[]>({
      query: body => {
        return {
          url: 'asset-group/name',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['AssetTypeToName'],
    }),
    updateAssetTypeToName: builder.mutation<void, AssetTypeToNameUpdate>({
      query: body => {
        return {
          url: `asset-group/name/${body.assetTypeNameId}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['AssetTypeToName'],
    }),
    deleteAssetTypeToName: builder.mutation<void, number[]>({
      query: body => ({
        url: 'asset-group/name',
        method: 'delete',
        body,
      }),
      invalidatesTags: ['AssetTypeToName'],
    }),
  }),
});

export const {
  useGetAssetTypesToNameListQuery,
  useLazyGetAssetTypesToNameListQuery,
  useUpdateAssetTypeToNameMutation,
  useCreateAssetTypeToNameMutation,
  useDeleteAssetTypeToNameMutation,
  useGetAssetTypesToNameByTypeIdBrachTypeIdQuery,
  useLazyGetAssetTypesToNameByTypeIdBrachTypeIdQuery,
} = assetTypeToNameApi;
