import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ECGrid } from '../ECGrid';
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableHead,
  ECTableRow,
} from '../ECTable';
import { ECTypography } from '../ECTypography';
import { ECTextField } from '../ECTextField';
import { ECPhoneField } from '../ECPhoneField';
import { cleanedPhoneNumber } from 'utils/strings/phone';
import { ECButton } from '../ECButton';
import AddIcon from '@mui/icons-material/Add';
import { isValidEmail } from 'utils/validate-fields';

export const ECSupplierContactsTable = forwardRef(
  ({ supplierContacts, isEditMode, onChange }: any, ref) => {
    const [erros, setErrors] = useState<any>([]);
    const [contacts, setContacts] = useState(supplierContacts);

    useEffect(() => {
      if (!isEditMode) {
        setContacts(supplierContacts);
      }
    }, [isEditMode, supplierContacts]);

    useEffect(() => {
      onChange(contacts);
    }, [contacts]);

    const handleChange = (value, name, id) => {
      const newContacts = contacts.map(contact => {
        if (contact.id === id) {
          return {
            ...contact,
            [name]: value,
          };
        }

        return contact;
      });

      setContacts(newContacts);
    };

    const handleAddNewContact = () => {
      setContacts([...contacts, {}]);
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        const isValid = contacts.every(contact => {
          return (
            contact.firstName &&
            contact.jobTitle &&
            contact.email &&
            isValidEmail(contact.email) &&
            contact.phone
          );
        });

        setErrors(() => {
          return contacts.map(contact => {
            const errorFields: string[] = [];

            if (!contact.firstName) {
              errorFields.push('firstName');
            }

            if (!contact.jobTitle) {
              errorFields.push('jobTitle');
            }

            if (!contact.email || !isValidEmail(contact.email)) {
              errorFields.push('email');
            }

            if (!contact.phone) {
              errorFields.push('phone');
            }

            return errorFields;
          });
        });

        return isValid;
      },
    }));

    return (
      <ECGrid container spacing={2} px={2}>
        <ECGrid item xs={12}>
          <ECTypography variant="h6">Supplier Contacts</ECTypography>
        </ECGrid>

        <ECGrid item xs={12}>
          <ECTableContainer>
            <ECTable>
              <ECTableHead>
                <ECTableRow>
                  <ECTableCell>First Name</ECTableCell>
                  <ECTableCell>Last Name</ECTableCell>
                  <ECTableCell>Job Title</ECTableCell>
                  <ECTableCell>Email</ECTableCell>
                  <ECTableCell>Phone Number</ECTableCell>
                  <ECTableCell>EXT</ECTableCell>
                </ECTableRow>
              </ECTableHead>
              <ECTableBody>
                {contacts.map((contact, index) => (
                  <ECTableRow key={contact.id}>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={contact.firstName}
                          onChange={e =>
                            handleChange(
                              e.target.value,
                              'firstName',
                              contact.id,
                            )
                          }
                          error={erros?.[index]?.includes('firstName')}
                          helperText={
                            erros?.[index]?.includes('firstName')
                              ? 'Required'
                              : undefined
                          }
                        />
                      ) : (
                        contact.firstName
                      )}
                    </ECTableCell>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={contact.lastName}
                          onChange={e =>
                            handleChange(e.target.value, 'lastName', contact.id)
                          }
                        />
                      ) : (
                        contact.lastName
                      )}
                    </ECTableCell>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={contact.jobTitle}
                          onChange={e =>
                            handleChange(e.target.value, 'jobTitle', contact.id)
                          }
                          error={erros?.[index]?.includes('jobTitle')}
                          helperText={
                            erros?.[index]?.includes('jobTitle')
                              ? 'Required'
                              : undefined
                          }
                        />
                      ) : (
                        contact.jobTitle
                      )}
                    </ECTableCell>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={contact.email}
                          onChange={e =>
                            handleChange(e.target.value, 'email', contact.id)
                          }
                          error={erros?.[index]?.includes('email')}
                          helperText={
                            erros?.[index]?.includes('email')
                              ? 'Required'
                              : undefined
                          }
                        />
                      ) : (
                        contact.email
                      )}
                    </ECTableCell>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECPhoneField
                          id="phone"
                          name="phone"
                          variant="filled"
                          value={contact.phone}
                          onChange={event => {
                            const clearedValue = cleanedPhoneNumber(
                              event.target.value,
                            );
                            handleChange(clearedValue, 'phone', contact.id);
                          }}
                          error={erros?.[index]?.includes('phone')}
                          validationMessage={
                            erros?.[index]?.includes('phone')
                              ? 'Required'
                              : undefined
                          }
                        />
                      ) : (
                        contact.phone
                      )}
                    </ECTableCell>
                    <ECTableCell>
                      {isEditMode ? (
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={contact.ext}
                          inputProps={{
                            maxLength: 3,
                          }}
                          onChange={e =>
                            handleChange(e.target.value, 'ext', contact.id)
                          }
                        />
                      ) : (
                        contact.ext
                      )}
                    </ECTableCell>
                  </ECTableRow>
                ))}
                {isEditMode && (
                  <ECTableRow>
                    <ECTableCell sx={{ borderBottom: 0 }}>
                      <ECButton
                        startIcon={<AddIcon />}
                        onClick={handleAddNewContact}
                      >
                        Add new contact
                      </ECButton>
                    </ECTableCell>
                  </ECTableRow>
                )}
              </ECTableBody>
            </ECTable>
          </ECTableContainer>
        </ECGrid>
      </ECGrid>
    );
  },
);
