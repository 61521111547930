import React from 'react';
import { Rank } from '../ECRankField';
import { ECButton } from '../ECButton';
import { ECBox } from '../ECBox';
import { useTranslation } from 'react-i18next';

interface AddNewButtonProps {
  ranks?: Rank[];
  onAdd: () => void;
  disabled?: boolean;
}

export const AddNewButton: React.FC<AddNewButtonProps> = React.memo(
  ({ ranks, onAdd, disabled }) => {
    const { t } = useTranslation();
    const shouldDisable =
      disabled ||
      (!ranks ? false : ranks?.some(rank => !rank.data || rank.data === ''));
    return (
      <ECBox>
        <ECButton variant="text" onClick={onAdd} disabled={shouldDisable}>
          + {t('translation:dynamicPageTemplate.config.create')}
        </ECButton>
      </ECBox>
    );
  },
);
