import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  ECBox,
  ECCard,
  ECCardContent,
  ECMainTabBox,
  ECTypography,
} from '../../components';
import { ReactComponent as Scene } from './assets/Scenes03.svg';

export function WelcomeToPanelPage() {
  return (
    <>
      <Helmet>
        <title>Panel Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainTabBox
        component={'main'}
        sx={{
          /// These styles are just for a good-looking demo page
          display: 'flex',
          paddingTop: '33vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ECBox
          flexDirection={'column'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Scene />
          <ECTypography sx={{ marginTop: 4 }} variant={'h6'}>
            Welcome to Ecotrak Panel
          </ECTypography>
        </ECBox>
      </ECMainTabBox>
    </>
  );
}
