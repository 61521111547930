import { Checkbox, CheckboxProps } from '@mui/material';
import { ECChip } from '../ECChip';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECAlert } from '../ECAlert';
import { ForwardedRef, forwardRef } from 'react';

interface ECCheckboxProps extends CheckboxProps {
  label?: string;
  withBorder?: boolean;
}

export const ECCheckbox = forwardRef(
  (
    { readOnly, label, withBorder, sx, ...props }: ECCheckboxProps,
    ref: ForwardedRef<any>,
  ) => {
    if (readOnly) {
      return (
        <ECAlert
          severity={props.checked ? 'success' : 'error'}
          variant="outlined"
          icon={false}
          sx={sx}
          ref={ref}
        >
          <ECBox display="flex" alignItems="center" gap={2}>
            <ECChip
              variant="filled"
              label={props.checked ? 'Yes' : 'No'}
              color={props.checked ? 'Light Green' : 'Red'}
              sx={{
                bgcolor: theme =>
                  props.checked
                    ? theme.palette.success.outlinedHoverBackground
                    : theme.palette.error.outlinedHoverBackground,
                color: theme =>
                  props.checked
                    ? theme.palette.success.dark
                    : theme.palette.error.dark,
              }}
            />

            <ECTypography>{label}</ECTypography>
          </ECBox>
        </ECAlert>
      );
    }

    if (withBorder) {
      return (
        <ECAlert
          severity="info"
          variant="outlined"
          icon={false}
          sx={sx}
          ref={ref}
        >
          <ECBox display="flex" alignItems="center">
            <Checkbox {...props} />

            {label && <ECTypography variant="subtitle1">{label}</ECTypography>}
          </ECBox>
        </ECAlert>
      );
    }

    return <Checkbox {...props} ref={ref} />;
  },
);
