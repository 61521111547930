import parse from 'html-react-parser';
import moment from 'moment';

// If entry is an array, try to get only labels or names.
export function getHistoryEntry(body: any, bodyKey: string) {
  if (body?.[bodyKey] === null || body?.[bodyKey] === undefined) return;
  let historyEntry = JSON.stringify(body[bodyKey]);
  if (Array.isArray(body[bodyKey])) {
    historyEntry = body[bodyKey]
      .map((item: any) => item?.label || item?.name || item)
      ?.join(', ');
  }

  const hasInitialPTag = historyEntry?.slice(1, 4) === '<p>';
  if (hasInitialPTag) {
    historyEntry = `"${historyEntry?.slice(4)?.replace('</p>', '')}`;
  }

  historyEntry = historyEntry
    .replaceAll('no-reply@ecotrak.com', '')
    .replaceAll('<', '')
    .replaceAll('>', '')
    .replaceAll('\\r', '')
    .replaceAll(/\\"/g, '"');

  if (historyEntry.startsWith('"') && historyEntry.endsWith('"')) {
    historyEntry = historyEntry.slice(1, -1);
  }

  if (bodyKey === 'resetDateAndTime') {
    historyEntry = moment(historyEntry).format('MMM D, YYYY hh:mm a');
  }

  return parse(historyEntry.replaceAll('\\n', ''));
}

// if it is an array, check if it has at least one item
export function isValidHistoryEntry(body: any, bodyKey: string) {
  if (Array.isArray(body[bodyKey])) {
    return body[bodyKey]?.length > 0;
  }
  return true;
}

export function removeLeadingTrailingQuotes(str: any) {
  if (typeof str === 'string' && str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
}

export function checkIfValidArrayEntry(previousBody, currentBody, bodyKey) {
  if (
    Array.isArray(previousBody?.[bodyKey]) &&
    Array.isArray(currentBody?.[bodyKey])
  ) {
    const lastBody = previousBody[bodyKey]?.join(',');
    const currentBodyStr = currentBody[bodyKey]?.join(',');
    return (
      lastBody !== currentBodyStr &&
      previousBody[bodyKey]?.length !== currentBody[bodyKey]?.length
    );
  }

  return true;
}
