import {
  ECAvtImage,
  ECBox,
  ECButton,
  ECEasyForm,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECTypography,
  ECWorkflowTemplate,
} from 'app/components';
import {
  useApproveAssetVerificationMutation,
  useUpdateAvtAttachmentMutation,
  useGetAssetRejectReasonListQuery,
  useRejectAssetMutation,
} from 'services/avtApi';
import {
  useLazyGetManufactureSeriesListQuery,
  useLazyGetModelsByManufactureSeriesQuery,
  useAddModelForSeriesMutation,
  useLazyGetWarrantyPeriodsByManufactureIdSeriesIdModelIdQuery,
  useGetManufactureListQuery,
} from 'services/manufactureApi';
import { useGetAVTAssetByIdQuery } from 'services/avtApi';
import {
  useGetAttachmentQuery,
  useGetAttachmentDocumentTypesQuery,
} from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import moment from 'moment';
import { populateDropDownFields } from 'utils/pageUtils';
import { Manufacture } from 'types/Asset';
import { ManufactureSeriesResponse } from 'services/manufactureApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { ECModal } from 'app/components/ECModal';
import { StyleConstants } from 'styles/StyleConstants';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import AddIcon from '@mui/icons-material/Add';

const fancyFormElementsApprove = require('./form_config_approve.json');
const fancyFormElementsReject = require('./form_config_reject.json');
const fanceFormElementsAddModel = require('./form_config_new_model.json');
const fancyFormElementsDoctype = require('./form_config_doctype.json');

export function AssetDetailsAvtPage() {
  const { id } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const navigate = useNavigate();
  const branchIdRef = useRef<number | null>(null);

  const { data: documentTypes, isSuccess: isSuccessDocumentTypes } =
    useGetAttachmentDocumentTypesQuery('asset');

  const searchText = useRef<any>(null);
  const [
    doApproveAsset,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useApproveAssetVerificationMutation();

  const [
    doUpdateAttachment,
    {
      isError: isUpdateAttachmentError,
      isSuccess: isUpdateAttachmentSuccess,
      isLoading: isUpdateAttachmentLoading,
      error: updateAttachmentError,
      reset: resetUpdateAttachment,
    },
  ] = useUpdateAvtAttachmentMutation();

  const [
    rejectAsset,
    { isError: isRejectError, error: rejectError, isLoading: isRejectLoading },
  ] = useRejectAssetMutation();

  const [formElementsApprove, setFormElementsApprove] = useState<any>(
    fancyFormElementsApprove,
  );
  const [formElementsReject, setFormElementsReject] = useState<any>(
    fancyFormElementsReject,
  );
  const [formElementsAddModel, setFormElementsAddModel] = useState<any>(
    fanceFormElementsAddModel,
  );
  const [formElementsDoctype, setFormElementsDoctype] = useState<any>(
    fancyFormElementsDoctype,
  );

  const [currentImage, setCurrentImage] = useState<{
    id: number | null;
    url: string | null;
    action: string | null;
  }>({
    id: null,
    url: null,
    action: null,
  });

  const [currentImageId, setCurrentImageId] = useState<number | null>(null);
  const [currentImageDoctype, setCurrentImageDoctype] = useState<number | null>(
    null,
  );
  const [showDoctypeModal, setShowDoctypeModal] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState(false);

  const { data: asset, isSuccess: isSuccessAsset } = useGetAVTAssetByIdQuery(
    id ? Number(id) : 0,
  );

  const [selectedManufacture, setSelectedManufacture] = useState<any>({
    id: asset?.manufactureId || asset?.manufacture?.id || null,
    label: asset?.manufacture?.name || '',
  });
  const [selectedSeries, setSelectedSeries] = useState<any>({});

  const selectedModel = useRef<any>({
    id: asset?.modelNumber || null,
    label: '',
  });

  const [newModelId, setNewModelId] = useState<number | null>(null);

  const { data: rejectReasons, isSuccess: isSuccessRejectReasons } =
    useGetAssetRejectReasonListQuery();

  const { data: manufactures, isSuccess: isSuccessManufactures } =
    useGetManufactureListQuery({});

  const [triggerSeries, seriesResult] = useLazyGetManufactureSeriesListQuery();
  const { data: series, isSuccess: isSuccessSeries } = seriesResult;

  const [triggerModels, modelsResult] =
    useLazyGetModelsByManufactureSeriesQuery();
  const { data: models, isSuccess: isSuccessModels } = modelsResult;

  const [triggerWarrantyPeriods, warrantyPeriodsResult] =
    useLazyGetWarrantyPeriodsByManufactureIdSeriesIdModelIdQuery();
  const { data: warrantyPeriods, isSuccess: isSuccessWarrantyPeriods } =
    warrantyPeriodsResult;

  const { data: attachments } = useGetAttachmentQuery({
    module: asset?.moduleName ?? '',
    moduleId: asset?.id ?? 0,
    category: AttachmentCategory.Photo,
  });

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    if (fieldName === 'avtPlateImage') {
      if (value.action === 'image_change') {
        setCurrentImage(value);
      } else if (value.action === 'doctype_change') {
        setCurrentImageId(value.id);
        setShowDoctypeModal(true);

        const currentImageDoctype = attachments?.others?.find(
          image => image.id === value.id,
        )?.documentCoreType?.id;

        if (currentImageDoctype) {
          setCurrentImageDoctype(currentImageDoctype);
        }
      }
    }
  };

  useEffect(() => {
    if (isSuccessDocumentTypes && documentTypes) {
      const updatedForm = { ...formElementsDoctype };
      const documentTypesField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'documentType',
      );

      if (documentTypesField) {
        documentTypesField.options = documentTypes?.map(type => ({
          label: type.name,
          value: type.id,
        }));

        documentTypesField.optionValues = documentTypes?.map(type => type?.id);
      }

      setFormElementsDoctype(updatedForm);
    }
  }, [
    isSuccessDocumentTypes,
    documentTypes,
    currentImageId,
    currentImageDoctype,
  ]);

  useEffect(() => {
    if (showDoctypeModal) {
      const updatedForm = { ...formElementsDoctype };
      const documentTypesField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'documentType',
      );
      if (currentImageDoctype) {
        const currentDoc = documentTypes?.find(
          type => type.id === currentImageDoctype,
        );
        documentTypesField.value = currentDoc;
      }
    }
  }, [currentImageId, currentImageDoctype, showDoctypeModal]);

  useEffect(() => {
    if (isSuccessManufactures && manufactures) {
      populateDropDownFields({
        responseData: manufactures,
        createFormFields: formElementsApprove.fields,
        dataOptionsFieldName: 'label',
        dataValuesFieldName: 'id',
        formFieldName: 'manufacturer',
      });
    }
  }, [isSuccessManufactures, manufactures]);

  useEffect(() => {
    if (isSuccessAsset && asset && manufactures) {
      branchIdRef.current = asset?.branch?.id;
      const updatedForm = { ...formElementsApprove };

      if (asset?.manufactureId || asset?.manufacture?.id) {
        setSelectedManufacture({
          id: asset?.manufactureId || asset?.manufacture?.id,
          label: asset?.manufacture?.name || '',
        });

        triggerSeries({
          id: asset?.manufactureId || asset?.manufacture?.id,
        });
      } else {
        const manufactureField = updatedForm.fields.find(
          (field: any) => field.fieldName === 'manufacturer',
        );

        if (manufactureField) {
          manufactureField.value = null;
        }
      }

      const dataManufacturer = [...(manufactures as unknown as Manufacture[])];

      const currentManufacturer = dataManufacturer?.find(
        manufacturer => manufacturer.id === asset?.manufactureId,
      );

      if (currentManufacturer) {
        const manufactureField = updatedForm.fields.find(
          (field: any) => field.fieldName === 'manufacturer',
        );

        if (manufactureField) {
          manufactureField.value = {
            id: currentManufacturer.id,
            label: currentManufacturer.name,
          };
        }
      }

      const urlField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'urlManufacture',
      );

      if (urlField && currentManufacturer) {
        const url = dataManufacturer?.find(
          manufacture => manufacture.id === currentManufacturer.id,
        )?.url;
        urlField.value = url;
      }

      const serialNumberField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'serialNumber',
      );

      if (serialNumberField) {
        serialNumberField.value = asset?.serialNumber ?? '';
      }

      const noteField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'manufacturerNotes',
      );

      if (noteField && currentManufacturer) {
        noteField.value = currentManufacturer?.note ?? '';
      }

      const warrantyStartDateField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'warrantyStartDateComponent',
      );

      if (warrantyStartDateField) {
        warrantyStartDateField.value = {
          fieldName: asset?.warrantyStartOption || 'purchaseDate',
        };
        warrantyStartDateField.overrideRowValue = true;

        warrantyStartDateField.options = [
          {
            ...warrantyStartDateField.options[0],
            value: asset?.purchaseDate,
          },
          {
            ...warrantyStartDateField.options[1],
            value: asset?.branch?.openDate,
          },
        ];
      }

      const modelNumberAction = updatedForm.fields.find(
        (field: any) => field.fieldName === 'modelNumber',
      );

      if (modelNumberAction) {
        modelNumberAction.actionButton = {
          ...modelNumberAction.actionButton,
          icon: <AddIcon />,
          onClick: () => {
            setIsOpen(true);
          },
        };
      }

      setFormElementsApprove(updatedForm);
    }

    const updatedFormElementsAddModel = { ...formElementsAddModel };
    const modelNumberFieldModal = updatedFormElementsAddModel.fields.find(
      field => field.fieldName === 'modelNumber',
    );

    if (modelNumberFieldModal) {
      modelNumberFieldModal.value = selectedModel
        ? selectedModel.current.label
        : '';
    }

    setFormElementsAddModel(updatedFormElementsAddModel);
  }, [isSuccessAsset, asset, manufactures, selectedModel]);

  useEffect(() => {
    if (isSuccessRejectReasons && rejectReasons) {
      const updatedForm = { ...formElementsReject };
      const rejectReasonsField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'rejectReasons',
      );

      if (rejectReasonsField) {
        rejectReasonsField.options = rejectReasons?.map(reason => ({
          label: reason.name,
          value: reason.id,
        }));
      }

      setFormElementsReject(updatedForm);
    }
  }, [isSuccessRejectReasons, rejectReasons]);

  useEffect(() => {
    if (selectedManufacture?.id) {
      triggerSeries({
        id: selectedManufacture.id,
      });
    }
  }, [selectedManufacture?.id, triggerSeries]);

  useEffect(() => {
    if (isSuccessModels && models) {
      const updatedForm = { ...formElementsApprove };
      const modelsField = updatedForm.fields.find(
        field => field.fieldName === 'modelNumber',
      );

      const dataModels = [...(models as unknown as any[])];

      if (modelsField) {
        modelsField.options = dataModels.map(m => ({
          id: m.id,
          label: m.name,
        }));

        if (newModelId) {
          const newModel = modelsField.options.find(
            option => option.id === newModelId,
          );
          if (newModel) {
            modelsField.value = newModel;
          }
          setNewModelId(null);
        }
      }

      setFormElementsApprove(updatedForm);
    }
  }, [isSuccessModels, models, selectedSeries?.id, newModelId]);

  useEffect(() => {
    if (isSuccessSeries && series) {
      const updatedForm = { ...formElementsApprove };
      const seriesField = updatedForm.fields.find(
        (field: any) => field.fieldName === 'series',
      );

      const dataSeries = [
        ...(series as unknown as ManufactureSeriesResponse[]),
      ];

      if (seriesField && dataSeries) {
        seriesField.options = dataSeries.map(s => ({
          id: s.id,
          label: s.name,
        }));
      }

      setFormElementsApprove(updatedForm);
    }
  }, [isSuccessSeries, series, selectedManufacture?.id]);

  useEffect(() => {
    const updatedForm = { ...formElementsApprove };
    const warrantyPeriodField = updatedForm.fields.find(
      field => field.fieldName === 'warrantyPeriod',
    );

    if (warrantyPeriodField) {
      if (isSuccessWarrantyPeriods && warrantyPeriods) {
        warrantyPeriodField.warrantyPeriodOptions = warrantyPeriods;
      } else {
        warrantyPeriodField.warrantyPeriodOptions = [];
      }
    }

    setFormElementsApprove(updatedForm);
  }, [
    isSuccessWarrantyPeriods,
    warrantyPeriods,
    selectedManufacture?.id,
    selectedSeries?.id,
    selectedModel?.current?.id,
    selectedManufacture,
  ]);

  const summaryData = useMemo(() => {
    return [
      {
        id: 'avtDetails',
        label: 'Asset Info',
        type: SummaryFieldTypes.AvtDetails,
        data: asset?.id,
        options: [
          {
            label: 'Asset',
            data: asset?.name || '',
          },
          {
            label: 'Asset Group',
            data: asset?.assetType?.name || '',
          },
          {
            label: 'Asset ID',
            data: asset?.id || '',
          },
          {
            label: 'Location',
            data: asset?.branch?.name || '',
          },
          {
            label: 'Store Number',
            data: asset?.branch?.storeNumber || '',
          },
          {
            label: 'Customer',
            data: asset?.company?.name || '',
          },
          {
            label: 'Store Open',
            data: moment(asset?.branch?.openDate).format('MM/DD/YYYY') || '',
          },
        ],
      },
      {
        id: 'avtPlateImage',
        label: 'Set ID Plate Image',
        type: SummaryFieldTypes.AvtPlateImage,
        data: attachments?.others?.slice(0, 5),
        onChange: handleSummaryFieldChange('avtPlateImage'),
      },
    ];
  }, [asset, attachments, currentImage, isSuccessAsset]);

  const refCurrentStateFormField = useRef<any>(null);

  const handleChange = (output: ECEasyFormFieldType[], fieldName?: string) => {
    const selectedManufacturer = output.find(
      field => field.fieldName === 'manufacturer',
    );

    const urlField = output.find(field => field.fieldName === 'urlManufacture');

    const noteField = output.find(
      field => field.fieldName === 'manufacturerNotes',
    );

    const seriesField = output.find(
      (field: any) => field.fieldName === 'series',
    );

    const selectedModelField = output.find(
      field => field.fieldName === 'modelNumber',
    );

    if (fieldName === 'manufacturer') {
      if (selectedManufacturer) {
        setSelectedManufacture(selectedManufacturer.value);

        const selectedValue = selectedManufacturer.value as any;

        if (urlField) {
          urlField.value = selectedValue?.url;
        }

        if (noteField) {
          noteField.value = selectedValue?.note;
        }

        if (seriesField && selectedManufacturer.value) {
          seriesField.options = [];
          seriesField.value = null;
          setSelectedSeries({});
          triggerSeries({
            id: selectedManufacturer?.value['id'],
          });
        }
      }
    }

    if (fieldName === 'series') {
      const selectedSeries = output.find(field => field.fieldName === 'series');

      if (selectedSeries) {
        setSelectedSeries(selectedSeries.value);
      }

      const selectedSeriesValue = selectedSeries?.value as any;

      triggerModels({
        manufactureId: selectedManufacture?.id || asset?.manufactureId,
        seriesId: selectedSeriesValue?.id,
      });
    }

    if (fieldName === 'modelNumber') {
      if (selectedModelField) {
        selectedModel.current = selectedModelField.value;
      }

      const selectedModelValue = selectedModelField?.value as any;

      triggerWarrantyPeriods({
        manufactureId: selectedManufacture?.id,
        seriesId: selectedSeries?.id,
        modelId: selectedModelValue?.id,
      });

      const updatedFormModal = { ...formElementsAddModel };

      const modelNumberFieldModal = updatedFormModal.fields.find(
        field => field.fieldName === 'modelNumber',
      );

      if (modelNumberFieldModal) {
        modelNumberFieldModal.value = selectedModelValue?.label || '';
        setFormElementsAddModel(updatedFormModal);
      }
    }

    setFormElementsApprove({
      ...formElementsApprove,
      fields: output,
    });

    refCurrentStateFormField.current = {
      ...formElementsApprove,
      fields: output,
    };
  };

  useEffect(() => {
    if (selectedSeries?.id && selectedManufacture?.id) {
      triggerModels({
        manufactureId: selectedManufacture.id,
        seriesId: selectedSeries.id,
      });
    }
  }, [selectedSeries, selectedManufacture, triggerModels]);

  useEffect(() => {
    if (isSuccessSeries && series) {
      const updatedForm = { ...formElementsApprove };
      const seriesField = updatedForm.fields.find(
        field => field.fieldName === 'series',
      );

      const dataSeries = [
        ...(series as unknown as ManufactureSeriesResponse[]),
      ];

      if (seriesField) {
        seriesField.options = dataSeries.map(s => ({
          id: s.id,
          label: s.name,
        }));
        seriesField.value = null;
      }

      setFormElementsApprove(updatedForm);
    }
  }, [isSuccessSeries, series]);

  useEffect(() => {
    if (isSuccessModels && models) {
      const updatedForm = { ...formElementsApprove };
      const modelsField = updatedForm.fields.find(
        field => field.fieldName === 'modelNumber',
      );

      const dataModels = [...(models as unknown as any[])];

      if (modelsField) {
        modelsField.options = dataModels.map(m => ({
          id: m.id,
          label: m.name,
        }));
      }

      setFormElementsApprove(updatedForm);
    }
  }, [isSuccessModels, models]);

  const handleSubmit = (formData: FormData, fields: ECEasyFormFieldType[]) => {
    const manufactureId = fields.find(
      field => field.fieldName === 'manufacturer',
    )?.value as any;

    const modelNumberId = fields.find(
      field => field.fieldName === 'modelNumber',
    )?.value as any;

    const serialNumber = fields.find(
      field => field.fieldName === 'serialNumber',
    )?.value as any;

    const manufacturerSeriesId = fields.find(
      field => field.fieldName === 'series',
    )?.value as any;

    const warrantyStartDate = fields.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    )?.value as any;

    const data = {
      manufactureId: manufactureId?.id || null,
      modelNumberId: modelNumberId?.id || null,
      serialNumber: serialNumber || null,
      manufacturerSeriesId: manufacturerSeriesId?.id || null,
      warrantyStartOption: warrantyStartDate?.fieldName || null,
      warrantyStartDate: warrantyStartDate?.value || null,
      // for Manufacturer Date, check if its not selected from radio, if not, get the value from other options
      purchaseDate:
        warrantyStartDate?.fieldName === 'purchaseDate'
          ? warrantyStartDate?.value
          : !!warrantyStartDate?.otherOptions[0]?.value
            ? moment(warrantyStartDate?.otherOptions[0]?.value).format(
                'MM-DD-YYYY',
              )
            : '',
    };

    doApproveAsset({
      id: asset?.id as number,
      data,
    });
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${t('translation:pages.avt.verifySuccess')}`,
        }),
      );
      const branchId = asset?.branch?.id || branchIdRef.current;

      setNewModelId(null);
      // setSelectedModel({ id: null, label: '' });

      selectedModel.current = { id: null, label: '' };

      const updatedForm = { ...formElementsApprove };
      const modelNumberField = updatedForm.fields.find(
        field => field.fieldName === 'modelNumber',
      );

      if (modelNumberField) {
        modelNumberField.options = [];
        modelNumberField.value = null;
      }

      const manufacturerField = updatedForm.fields.find(
        field => field.fieldName === 'manufacturer',
      );

      if (manufacturerField) {
        manufacturerField.options = [];
        manufacturerField.value = null;
      }

      const seriesField = updatedForm.fields.find(
        field => field.fieldName === 'series',
      );

      if (seriesField) {
        seriesField.options = [];
        seriesField.value = null;
      }

      setFormElementsApprove(updatedForm);

      navigate(`/panel/avt-assets/${branchId}`, { replace: true });
    }
  }, [isUpdateSuccess]);

  const handleSubmitReject = (
    formData: FormData,
    fields: ECEasyFormFieldType[],
  ) => {
    const rejectReasonField = fields.find(
      field => field.fieldName === 'rejectReasons',
    )?.value as any;
    const rejectReasonId = rejectReasonField?.value;

    const formFields = [...refCurrentStateFormField?.current?.fields];

    const assetId = id ? Number(id) : 0;

    const manufactureId = formFields.find(
      field => field.fieldName === 'manufacturer',
    )?.value as any;

    const modelNumberId = formFields.find(
      field => field.fieldName === 'modelNumber',
    )?.value as any;

    const serialNumber = formFields.find(
      field => field.fieldName === 'serialNumber',
    )?.value as any;

    const manufacturerSeriesId = formFields.find(
      field => field.fieldName === 'series',
    )?.value as any;

    const warrantyStartDate = formFields.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    )?.value as any;

    const data = {
      manufactureId: manufactureId?.id || null,
      modelNumberId: modelNumberId?.id || null,
      serialNumber: serialNumber || null,
      manufacturerSeriesId: manufacturerSeriesId?.id || null,
      warrantyStartOption: warrantyStartDate?.fieldName || null,
      warrantyStartDate: warrantyStartDate?.value || null,
      // for Manufacturer Date, check if its not selected from radio, if not, get the value from other options
      purchaseDate:
        warrantyStartDate?.fieldName === 'purchaseDate'
          ? warrantyStartDate?.value
          : !!warrantyStartDate?.otherOptions[0]?.value
            ? moment(warrantyStartDate?.otherOptions[0]?.value).format(
                'MM-DD-YYYY',
              )
            : '',
    };

    if (assetId && rejectReasonId) {
      rejectAsset({
        assetId: assetId,
        rejectId: rejectReasonId,
        body: data,
      })
        .then(() => {
          dispatch(
            setSnackbar({
              message: 'The assest successfully rejected',
              severity: 'success',
            }),
          );
          const branchId = asset?.branch?.id || branchIdRef.current;

          const updatedForm = { ...formElementsApprove };

          const modelNumberField = updatedForm.fields.find(
            field => field.fieldName === 'modelNumber',
          );

          if (modelNumberField) {
            modelNumberField.options = [];
            modelNumberField.value = null;
          }

          const manufacturerField = updatedForm.fields.find(
            field => field.fieldName === 'manufacturer',
          );

          if (manufacturerField) {
            manufacturerField.options = [];
            manufacturerField.value = null;
          }

          const seriesField = updatedForm.fields.find(
            field => field.fieldName === 'series',
          );

          if (seriesField) {
            seriesField.options = [];
            seriesField.value = null;
          }

          setFormElementsApprove(updatedForm);

          navigate(`/panel/avt-assets/${branchId}`, { replace: true });
        })
        .catch(error => {
          console.error('Error rejecting asset:', error);
          dispatch(
            setSnackbar({
              severity: 'error',
              message: 'Error occurred while rejecting the asset.',
            }),
          );
        });
    } else {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Error occurred while rejecting the asset.',
        }),
      );
    }
  };

  const handleChangeReject = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'rejectReasons') {
      // if needed, do some stuff here
    }
  };

  const rejectModalContent = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={formElementsReject.config}
        fields={formElementsReject.fields}
        isSendingData={isRejectLoading}
        isLoading={isRejectLoading}
        isLoadingForm={isRejectLoading}
        onFormSubmit={handleSubmitReject}
        onChange={handleChangeReject}
        saveButtonColor={'error'}
        customDrawerTitle={
          'Select Reason to Reject the Warranty Verification of this Asset.'
        }
        additionalActions={
          <ECButton
            type="button"
            variant="text"
            onClick={() => {
              setShowRejectModal(false);
            }}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [rejectReasons, formElementsReject, isRejectLoading]);

  const handleChangeDoctype = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'documentType') {
      // if needed, do some stuff here
    }
  };

  const handleSubmitDoctypeChange = (
    formData: FormData,
    fields: ECEasyFormFieldType[],
  ) => {
    const documentTypeField = fields.find(
      field => field.fieldName === 'documentType',
    )?.value as any;
    const documentTypeId = documentTypeField?.value;

    if (currentImageId && documentTypeId) {
      const updatedData = {
        attachmentId: currentImageId,
        documentCoreTypeId: documentTypeId,
      };

      doUpdateAttachment(updatedData)
        .then(() => {
          dispatch(
            setSnackbar({
              message: 'The document type successfully changed',
              severity: 'success',
            }),
          );
          setShowDoctypeModal(false);
          resetUpdateAttachment();
        })
        .catch(error => {
          console.error('Error changing document type:', error);
          dispatch(
            setSnackbar({
              severity: 'error',
              message: 'Error occurred while changing the document type.',
            }),
          );
        });
    } else {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Error occurred while changing the document type.',
        }),
      );
    }
  };

  const changeDoctypeModalContent = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={formElementsDoctype.config}
        fields={formElementsDoctype.fields}
        isSendingData={isUpdateAttachmentLoading}
        isLoading={isUpdateAttachmentLoading}
        isLoadingForm={isUpdateAttachmentLoading}
        onFormSubmit={handleSubmitDoctypeChange}
        onChange={handleChangeReject}
        customDrawerTitle={'Select the Document Type for the image.'}
        additionalActions={
          <ECButton
            type="button"
            variant="text"
            onClick={() => {
              setShowDoctypeModal(false);
            }}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [documentTypes, formElementsDoctype, currentImageId, attachments]);

  const handleAutoCompleteSearchText = value => {
    if (!value) return;
    searchText.current = value;
  };

  useEffect(() => {
    const updatedForm = { ...formElementsAddModel };
    const currentModelNumberField = updatedForm.fields.find(
      field => field.fieldName === 'currentModelNumber',
    );

    if (currentModelNumberField) {
      currentModelNumberField.value = asset?.modelNumber ?? '';
    }

    const updatedApproveForm = { ...formElementsApprove };
    const modelNumberField = updatedApproveForm.fields.find(
      (field: any) => field.fieldName === 'modelNumber',
    );
    modelNumberField.onChangeAutoCompleteSearchText =
      handleAutoCompleteSearchText;

    setFormElementsAddModel(updatedForm);
    setFormElementsApprove(updatedApproveForm);
  }, [asset]);

  const handleAddModelChange = useCallback(() => {
    // do sth here if needed
  }, []);

  const [
    doCreateModel,
    {
      data: createModelData,
      isError: isCreateModelError,
      error: createModelError,
      isLoading: isCreateModelLoading,
      isSuccess: isCreateModelSuccess,
      reset: resetCreateModel,
    },
  ] = useAddModelForSeriesMutation();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    resetCreateModel();
  }, [setIsOpen]);

  const useAddModel = useCallback(() => {
    const doCreate = async data => {
      if (!selectedManufacture?.id || !selectedSeries?.id) {
        dispatch(
          setSnackbar({
            message: 'Please select manufacture and series',
            severity: 'error',
          }),
        );
        return;
      }

      const body = {
        model: data?.modelNumber,
        manufactureSeriesId: selectedSeries?.id,
        manufactureId: selectedManufacture?.id || asset?.manufactureId,
        seriesId: selectedSeries?.id,
      };
      doCreateModel({
        ...body,
      });
    };

    return [
      doCreate,
      {
        data: createModelData,
        isError: isCreateModelError,
        error: createModelError,
        isLoading: isCreateModelLoading,
        isSuccess: isCreateModelSuccess,
        reset: resetCreateModel,
      },
    ];
  }, [
    selectedManufacture?.id,
    selectedSeries?.id,
    doCreateModel,
    createModelData,
    isCreateModelError,
    createModelError,
    isCreateModelLoading,
    isCreateModelSuccess,
    resetCreateModel,
  ]);

  const handleOnCreateModel = useCallback(
    (createdData: any) => {
      const newCreatedModel = {
        id: createdData.id,
        label: createdData.model,
      };

      setNewModelId(createdData.id);

      const updatedForm = { ...formElementsApprove };
      const modelNumberField = updatedForm.fields.find(
        field => field.fieldName === 'modelNumber',
      );

      if (modelNumberField) {
        modelNumberField.options.push(newCreatedModel);
        modelNumberField.value = newCreatedModel;
      }

      triggerWarrantyPeriods({
        manufactureId: selectedManufacture?.id,
        seriesId: selectedSeries?.id,
        modelId: createdData.id,
      });

      setFormElementsApprove(updatedForm);
    },
    [formElementsApprove],
  );

  useEffect(() => {
    if (isOpen && !selectedModel.current?.label) {
      const updatedForm = { ...formElementsAddModel };
      const addModelNumberField = updatedForm.fields.find(
        field => field.fieldName === 'modelNumber',
      );
      addModelNumberField.value = searchText.current;
      searchText.current = null;
      setFormElementsAddModel(updatedForm);
    }
  }, [isOpen]);

  return (
    <>
      {asset && (
        <>
          <ECWorkflowTemplate
            tabsAsLinks={false}
            showEditForm={true}
            isEditAllowed={false}
            title={asset.name}
            disableTabsOnEdit={true}
            disableSidebarOnEdit={true}
            disableAditionalActionWhenEditing={true}
            summaryData={summaryData}
            editConfig={undefined}
            editFields={undefined}
            detailsConfig={undefined}
            detailsFields={undefined}
            detailsData={asset}
            hideNoteTab
            hideActivityTab
            hideAttachmentTab
            showLogo={false}
            showWarrantyAndLogo={false}
          />
          <ECBox p={2}>
            <ECTypography mb={1} variant="h6">
              Manufacturer Warranty
            </ECTypography>
            <ECBox display={'flex'}>
              <ECAvtImage
                src={currentImage?.url || attachments?.others?.[0]?.url}
                alt={asset?.name}
                showLogoOnError={true}
                handleOnChange={() => {
                  // for extra actions on image if needed here
                }}
                id={asset?.id}
                customHeight="25rem"
                customWidth="25rem"
                showCheckbox={false}
                imageTools={true}
                docType={null}
                helperText="Select an image by the checkboxes above. Use the scroll wheel or Pinch closed with two fingers to zoom out using a trackpad."
              />
              <ECEasyForm
                onChange={handleChange}
                pattern="filled"
                fullHeight={false}
                stickyFooter={true}
                config={formElementsApprove.config}
                fields={formElementsApprove.fields}
                additionalActions={
                  <ECButton
                    type="button"
                    variant="text"
                    sx={{ height: 42 }}
                    onClick={() => {
                      setShowRejectModal(true);
                    }}
                  >
                    {t('translation:pages.avt.reject')}
                  </ECButton>
                }
                onFormSubmit={handleSubmit}
                isSendingData={isUpdateLoading}
                isLoadingForm={false}
                showTitle={false}
                noPaddingY
                hideToolBar={true}
                showSaveButton={true}
                submissionConfirmationModal={{
                  title: 'Are you sure to confirm this asset verification?',
                  buttonText: 'Confirm',
                  bodyText: '',
                  isVisible: true,
                  onClose: () => {
                    // do some stuff on close if needed
                  },
                }}
              />
            </ECBox>
          </ECBox>
          <ECModal
            isOpen={showRejectModal}
            onClose={() => setShowRejectModal(false)}
            noPadding
            sx={{
              width: '40rem',
              maxWidth: '90%',
            }}
          >
            {rejectModalContent}
          </ECModal>
          <ECModal
            isOpen={showDoctypeModal}
            onClose={() => setShowDoctypeModal(false)}
            noPadding
            sx={{
              width: '40rem',
              maxWidth: '90%',
            }}
          >
            {changeDoctypeModalContent}
          </ECModal>
          <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
            <ECBox
              height={'100%'}
              display="flex"
              pt={StyleConstants.NAV_BAR_HEIGHT}
            >
              <ECEasyFormCreate
                useCreateMutation={useAddModel}
                formConfig={formElementsAddModel.config}
                formFields={formElementsAddModel.fields}
                existingData={{}}
                onCreate={handleOnCreateModel}
                onClose={handleClose}
                onChange={handleAddModelChange}
                stickyFooter={true}
              />
            </ECBox>
          </ECDrawerDetails>
        </>
      )}
    </>
  );
}
