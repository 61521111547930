import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { requirePermissionWrapper } from 'app/hoc/require-permission';

export const ToggleButtonStyled = styled(ToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }),
);

export const ECToggleButton =
  requirePermissionWrapper<ToggleButtonProps>(ToggleButtonStyled);
