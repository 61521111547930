import { DialogContent } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter, setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECBox } from '../ECBox';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { ECIconButton } from '../ECIconButton';
import { ECEasyInputTags } from '../ECEasyInputTags';
import { ECButton } from '../ECButton';
import { ECDialog } from '../ECDialog';
import { ECSaveFilter, SaveError } from '../ECSaveFilter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isEmptyValue } from '../ECForm';
import { useTranslation } from 'react-i18next';
import { ECSearchForm } from '../ECDynamicForm';
import { ECBadge } from '../ECBadge';
import { ECSimpleSearch } from './ECSimpleSearch';
import { ECCircularProgress } from '../ECCircularProgress';
import { SearchOutlined } from '@mui/icons-material';

const MAX_ADVANCED_FILTERS_AMOUNT = 20;

interface ECAdvancedFilterProps {
  cols?: any[];
  hiddenCols?: any[];
  showSaveFilterButton?: boolean;
  allSavedFilters?: any[];
  setSimpleSearchQuery?: (query: string) => void;
  isSendingRequest?: boolean;
}

export const ECAdvancedFilter = ({
  cols,
  showSaveFilterButton,
  allSavedFilters,
  setSimpleSearchQuery,
  hiddenCols,
  isSendingRequest,
}: ECAdvancedFilterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [openSaveFilterDialog, setOpenSaveFilterDialog] =
    useState<boolean>(false);

  const [isErrorSaveFilter, setIsErrorSaveFilter] = useState<SaveError>({
    isError: false,
    errorMessage: '',
  });

  const [isSuccessSaveFilter, setIsSuccessSaveFilter] =
    useState<boolean>(false);

  useEffect(() => {
    if (isErrorSaveFilter.isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: isErrorSaveFilter.errorMessage,
          onClose: () => {
            setIsErrorSaveFilter({ isError: false, errorMessage: '' });
          },
        }),
      );
    }
  }, [isErrorSaveFilter.isError, isErrorSaveFilter.errorMessage, dispatch]);

  useEffect(() => {
    if (isSuccessSaveFilter) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('translation:dynamicForm.saveSuccess'),
        }),
      );
    }
  }, [isSuccessSaveFilter, dispatch, t]);

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const isUsingAdvancedSearch = useMemo(() => {
    return activeFilter?.isAdvanced;
  }, [activeFilter]);

  const activeFilterFields = useMemo(() => {
    if (!isUsingAdvancedSearch) return [];
    return activeFilter?.filterFields;
  }, [activeFilter, isUsingAdvancedSearch]);

  const showFilterName = useMemo(
    () =>
      activeFilterFields &&
      activeFilterFields.length > 0 &&
      !isEmptyValue(activeFilter?.name),
    [activeFilter],
  );

  return (
    <>
      <ECIconButton onClick={() => setOpenSearch?.(true)}>
        <ECBadge color="error" variant="dot" invisible={!activeFilterFields?.length} >
          <FilterAltIcon />
        </ECBadge>
      </ECIconButton>
      <ECBox
        borderColor={theme => theme.palette.action.active}
        sx={{
          tabIndex: '0',
          display: 'flex',
          width: '440px',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: 0,
          borderBottom: isUsingAdvancedSearch ? '1px solid rgba(0, 0, 0, 0.42)' : undefined,
          '&:focus, &:hover': theme => ({
            borderColor: theme.palette.action.selected,
          }),
          p: 0.5,
        }}
      >
        {showFilterName && (
          <ECTypography
            variant="h6"
            color={theme => theme.palette.text.secondary}
          >
            {activeFilter?.name && activeFilter?.name?.length > 25
              ? activeFilter?.name?.substring(0, 25) + '...'
              : activeFilter?.name}
          </ECTypography>
        )}
        {!isUsingAdvancedSearch ? (
          <ECSimpleSearch
            cols={cols}
            hiddenCols={hiddenCols}
            isSendingRequest={isSendingRequest}
          />
        ) : (
          <>
            <ECEasyInputTags
              tags={activeFilterFields}
              defaultSearchFieldName={
                cols?.find(col => col.searchable)?.alias ||
                cols?.find(col => col.searchable)?.fieldName
              }
              defaultSearchFieldLabel={cols?.find(col => col.searchable)?.title}
              selectedTags={selectedTags => {
                setSimpleSearchQuery?.('');
                dispatch(
                  setActiveFilter({
                    filterFields: selectedTags,
                    isEdited: true,
                    isAdvanced: !!selectedTags?.length,
                  }),
                );
              }}
            />
            {showSaveFilterButton && (
            <ECButton
              type="button"
              variant="text"
              sx={{ height: 42 }}
              onClick={() => {
                if (
                  (allSavedFilters?.filter(
                    filter => !filter?.body?.some(field => field.isTableLayout),
                  )?.length ?? 0) >= MAX_ADVANCED_FILTERS_AMOUNT
                ) {
                  setIsErrorSaveFilter({
                    isError: true,
                    errorMessage:
                      'you have reached the maximum number of saved filters',
                  });
                } else {
                  setOpenSaveFilterDialog(true);
                }
              }}
              >
                Save as
              </ECButton>
            )}
            <ECButton
              variant="contained"
              sx={{ height: '40px', width: '40px', minWidth: '40px' }}
            >
                {isSendingRequest ? (
                  <ECCircularProgress
                      size="24px"
                      sx={{
                        color: theme => theme.palette.background.paper,
                      }}
                  />
                ) : (
                  <SearchOutlined />
                )}
            </ECButton>
          </>
        )}
      </ECBox>
      <ECDialog
        open={openSaveFilterDialog}
        container={() =>
          document.getElementsByTagName('main')[0] ?? document.body
        }
        sx={{
          position: 'absolute',
        }}
        onClose={() => setOpenSaveFilterDialog(false)}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          variant: 'outlined',
          sx: { alignSelf: 'start', top: '100px' },
        }}
      >
        <DialogContent>
          <ECSaveFilter
            filterEndPoint={endpoint}
            filters={activeFilterFields ?? []}
            onClose={() => setOpenSaveFilterDialog(false)}
            onSuccessSavefilter={setIsSuccessSaveFilter}
            onErrorSaveFilter={setIsErrorSaveFilter}
          />
        </DialogContent>
      </ECDialog>
      {openSearch && cols && hiddenCols && (
        <ECSearchForm
          open={openSearch}
          filterEndPoint={endpoint}
          cols={[...cols, ...hiddenCols]}
          onClose={() => setOpenSearch(false)}
        />
      )}
    </>
  );
};
