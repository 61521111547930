import { useEffect, useMemo } from 'react';
import {
  useLazyGetAssetsListQuery,
  useLazyGetTotalSpentQuery,
} from 'services/assetApi';

export function useCustomGetAssetList() {
  const [assetListTrigger, assetListResult] = useLazyGetAssetsListQuery();
  const [totalSpentTrigger, totalSpentResult] = useLazyGetTotalSpentQuery();

  const { data: assetListData, isSuccess: assetListIsSuccess } =
    assetListResult;

  const { data: totalSpentData, isSuccess: totalSpentIsSuccess } =
    totalSpentResult;

  useEffect(() => {
    if (assetListIsSuccess) {
      const assetIds = assetListData.data.map(asset => asset.id);
      totalSpentTrigger(assetIds, true);
    }
  }, [assetListData?.data, assetListIsSuccess, totalSpentTrigger]);

  const finalResult = useMemo(() => {
    if (totalSpentIsSuccess) {
      return {
        ...assetListResult,
        data: {
          ...assetListData,
          data: assetListData?.data.map(asset => ({
            ...asset,
            totalSpent: totalSpentData?.find(
              totalSpent => totalSpent.id === asset.id,
            )?.total,
          })),
        },
      };
    }
    return assetListResult;
  }, [assetListData, assetListResult, totalSpentData, totalSpentIsSuccess]);

  return [assetListTrigger, finalResult];
}
