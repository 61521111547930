import { SchdulerJob } from 'types/Scheduler';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['ScheduleLog'],
});

export const scheduleApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getScheduleLogList: builder.query<any, null>({
      query: () => {
        return {
          url: 'scheduler/tasks/all',
        };
      },
      transformResponse: (response: any) => {
        return response.map(res => {
          return {
            ...res,
            download: ['custom'],
          };
        });
      },
      providesTags: ['ScheduleLog'],
    }),
    getAvailableSchedulerJobNames: builder.query<SchdulerJob[], void>({
      query: () => {
        return {
          url: 'scheduler/jobs',
        };
      },
    }),
    triggerSchedule: builder.mutation<void, any>({
      query: ({ ...body }) => {
        return {
          url: `scheduler/task`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ScheduleLog'],
    }),
  }),
});

export const {
  useGetScheduleLogListQuery,
  useLazyGetScheduleLogListQuery,
  useTriggerScheduleMutation,
  useGetAvailableSchedulerJobNamesQuery,
  useLazyGetAvailableSchedulerJobNamesQuery,
} = scheduleApi;
