import { ECConfirmationDialog } from '../ECDialog';

interface ECAttachmentDeleteAlertProps {
  open: boolean;
  title: string;
  text: string;
  onDelete: () => void;
  handleCloseModal: () => void;
}

export const ECAttachmentDeleteAlert = ({
  open,
  title = 'Delete attachment?',
  text = 'Please, confirm you want to delete attachment',
  onDelete,
  handleCloseModal,
}: ECAttachmentDeleteAlertProps) => {
  return (
    <ECConfirmationDialog
      open={open}
      title={title}
      text={text}
      acceptButtonTitle="Inactivate"
      acceptButtonColor="error"
      cancelButtonTitle="No"
      onAcceptClick={() => {
        onDelete();
        handleCloseModal();
      }}
      onClose={handleCloseModal}
      onCancelClick={handleCloseModal}
      sx={{ zIndex: 1400 }}
      disableScrollLock
    />
  );
};
