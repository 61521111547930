import { Close } from '@mui/icons-material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSaveFilterMutation } from 'services/filterApi';
import { setActiveFilter } from 'store/slice/page';
import { Filter } from 'types/Filter';
import { ECButton } from '../ECButton';
import {
  ECEasyForm,
  ECEasyFormFieldType,
  FieldTypes,
  isEmptyValue,
} from '../ECForm';
import { ECIconButton } from '../ECIconButton';
import { ECStack } from '../ECStack';
export interface SaveError {
  isError: boolean;
  errorMessage: string;
}
export interface ECSaveFilterProps {
  filterEndPoint: string;
  filters: Filter[];
  onClose: () => void;
  onSuccessSavefilter: (status: boolean) => void;
  onErrorSaveFilter: (error: SaveError) => void;
}

export const ECSaveFilter = (props: ECSaveFilterProps) => {
  const {
    filterEndPoint,
    filters,
    onClose,
    onSuccessSavefilter,
    onErrorSaveFilter,
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [doSaveFilter, result] = useSaveFilterMutation();
  const { isError, error, isSuccess, data } = result;
  useEffect(() => {
    onSuccessSavefilter(isSuccess); // it's needed here to make success message hidden if isSuccess is false
    if (isSuccess) {
      const { name, body } = data; // here data is the respons from POST request, after we save the filter in database the page state will be updaged as well
      dispatch(
        setActiveFilter({
          name,
          filterFields: body,
        }),
      );
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    onErrorSaveFilter({
      isError,
      errorMessage:
        ((error as FetchBaseQueryError)?.data as any)?.message ?? '',
    });
    if (isError) {
      onClose();
    }
  }, [isError]);

  return (
    <ECEasyForm
      config={{
        cols: 1,
        submitTitle: 'Save',
        variant: '',
        title: 'Save Filter',
        subtitle: '',
        titleStyle: {
          fontSize: '20px',
        },
        actionButtonsContainerStyle: {
          bgcolor: theme => theme.palette.background.paper,
        },
      }}
      fields={[
        {
          fieldName: 'name',
          type: FieldTypes.Text,
          label: 'Filter Name',
          placeholder: 'Filter Name',
          variant: 'standard',
          required: true,
          value: '',
          validationMessage: 'Enter a name for this Filter',
        },
      ]}
      isSendingData={false}
      isLoadingForm={false}
      onFormSubmit={(formData: FormData, fields: ECEasyFormFieldType[]) => {
        const { value } = fields[0];
        if (!isEmptyValue(value)) {
          doSaveFilter({
            endpoint: filterEndPoint,
            body: {
              name: value?.toString() ?? '',
              body: filters,
            },
          });
        }
      }}
      toolbarChildren={
        <ECStack direction="row" spacing={1} alignItems="center">
          <ECIconButton squared onClick={() => onClose()}>
            <Close />
          </ECIconButton>
        </ECStack>
      }
      additionalActions={
        <ECButton
          type="button"
          variant="text"
          sx={{ height: 42 }}
          onClick={() => {
            onClose();
          }}
        >
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
    />
  );
};
