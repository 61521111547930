import List, { ListProps } from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { ListItemButton, ListItemButtonProps } from '@mui/material';

interface ECListProps extends ListProps {
  noPadding?: boolean;
}

export const ECList = styled(List, {
  shouldForwardProp: prop => prop !== 'noPadding',
})<ECListProps>(({ theme, noPadding }) => ({
  width: '100%',
  paddingTop: noPadding ? 0 : 8,
  paddingBottom: noPadding ? 0 : 8,
}));

export const ECListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({}));

export const ECListItemIcon = styled(ListItemIcon)<ListItemIconProps>(
  ({ theme }) => ({}),
);

export const ECListItemText = styled(ListItemText)<ListItemTextProps>(
  ({ theme }) => ({}),
);

export const ECListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.outlinedRestingBackground,
      color: theme.palette.common.white,
    },
  }),
);
