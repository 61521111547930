import { useMemo, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECImage } from '../ECImage';
import { ECTableCell } from '../ECTable';
import defaultEmptyImage from '../../../assets/default-empty-image.svg';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { ECAttachmentDetailsModal } from '../ECDynamicPageTemplate/components/ECAttachmentDetailsModal';

interface ECImageTableCellProps {
  src: string;
  alt?: string;
  id?: number;
  moduleName?: string;
  profileAttachment?: any;
}

export const ECImageTableCell = ({
  src,
  alt = 'resource',
  id,
  moduleName,
  profileAttachment,
}: ECImageTableCellProps) => {
  const [isAttachmentViewOpen, setIsAttachmentViewOpen] = useState(false);
  const handleCloseAttachmentModal = () => {
    setIsAttachmentViewOpen(false);
  };

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName ?? '',
      moduleId: id ?? 0,
      category: AttachmentCategory.Photo,
    },
    { skip: !isAttachmentViewOpen || !id || !moduleName },
  );

  const assetProfileAttachment = useMemo(() => {
    const allAttachments = attachments?.others;
    const matchingPhotos = allAttachments?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    } else {
      return allAttachments?.[allAttachments?.length - 1];
    }
  }, [attachments]);

  return (
    <ECTableCell
      sx={{
        alignItems: 'center',
        p: 0,
        maxHeight: '88px !important',
        height: 88,
        position: 'relative',
      }}
      onClick={e => e.stopPropagation()}
    >
      <ECBox
        width={140}
        height={88}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        sx={{
          position: 'relative',
          cursor: 'pointer',
          '&:hover .image-overlay': {
            opacity: src ? 1 : 0,
          },
          '&:hover .image': {
            filter: src ? 'grayscale(100%)' : 'none',
          },
        }}
        onClick={() => {
          if (profileAttachment) {
            setIsAttachmentViewOpen(true);
          }
        }}
      >
        <ECImage
          sx={{
            maxHeight: '100%',
            width: '100%',
            objectFit: 'cover',
            transition: 'filter 0.3s ease',
          }}
          alt={alt}
          src={profileAttachment?.thumbnailURL || src || defaultEmptyImage}
        />
        {src && profileAttachment && (
          <ECBox
            className="image-overlay"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0,
              transition: 'opacity 0.3s ease',
            }}
          >
            VIEW
          </ECBox>
        )}
      </ECBox>
      {profileAttachment && assetProfileAttachment && moduleName && (
        <ECAttachmentDetailsModal
          moduleName={moduleName}
          moduleId={id?.toString() ?? ''}
          attachment={
            assetProfileAttachment ? assetProfileAttachment : profileAttachment
          }
          isOpen={isAttachmentViewOpen}
          onCloseModal={handleCloseAttachmentModal}
        />
      )}
    </ECTableCell>
  );
};
