import { ECButton, ECEasyForm } from 'app/components';
import { ECModal } from 'app/components/ECModal';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { invoiceApi, useUpdateStatusMutation } from 'services/invoiceApi';
import { setSnackbar } from 'store/slice/page';

const fancyFormRejectInvoice = require('./../InvoicesDetailsPage/fancy_form_config_reject.json');

export const InvoiceRejectModal = ({
  showRejectModal,
  setShowRejectModal,
  rejectModalData,
  setRejectModalData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction, reset },
  ] = useUpdateStatusMutation();

  useEffect(() => {
    if (isSuccessUpdateAction) {
      setShowRejectModal(false);
      dispatch(invoiceApi.util.invalidateTags(['InvoiceList']));
      dispatch(invoiceApi.util.invalidateTags(['PendingInvoices']));
      reset();
    }
  }, [isSuccessUpdateAction]);

  const handleRejectInvoice = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const rejectReasonField = fieldData.find(
        data => data.fieldName === 'rejectReason',
      );
      const rejectReason = rejectReasonField?.comment;

      const recipientIds = rejectReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );

      if (!rejectModalData) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Reject action not found. Please try again.',
          }),
        );
        setRejectModalData(null);
        return;
      }
      doUpdateStatus({
        id: Number(rejectModalData.id),
        statusTo: rejectModalData?.statusTo,
        actionId: rejectModalData?.actionId,
        declineReason: rejectReason,
        recipientIds,
      });
    },
    [doUpdateStatus, rejectModalData],
  );

  const onCancelReject = () => {
    setShowRejectModal(false);
  };

  const rejectModal = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormRejectInvoice.config}
        fields={fancyFormRejectInvoice.fields}
        isSendingData={isLoadingActions}
        isLoading={isLoadingActions}
        isLoadingForm={false}
        onFormSubmit={handleRejectInvoice}
        saveButtonColor="error"
        additionalActions={
          <ECButton type="button" variant="text" onClick={onCancelReject}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [onCancelReject, handleRejectInvoice]);

  return (
    <ECModal
      isOpen={showRejectModal && !isSuccessUpdateAction}
      onClose={() => setShowRejectModal(false)}
      noPadding
    >
      {rejectModal}
    </ECModal>
  );
};
