import { QueryParams } from 'types/QueryParams';
import { BaseType } from 'types/BaseType';
import {
  Troubleshoot,
  TroubleshootCreate,
  TroubleshootHistoryCreate,
} from 'types/Troubleshoot';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['TroubleshootingTips'],
});

export const troubleshootingTipsApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getTroubleshootTips: builder.query<BaseType<Troubleshoot[]>, QueryParams>({
      query: params => {
        return {
          url: 'troubleshoot',
          params,
        };
      },
      transformResponse: (response: BaseType<Troubleshoot[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'troubleshoot',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['TroubleshootingTips'],
    }),
    getTroubleshootTipsById: builder.query<Troubleshoot, Troubleshoot>({
      query: ({ id }) => {
        return {
          url: `troubleshoot/${id}`,
        };
      },
      transformResponse: (response: Troubleshoot) => {
        return {
          ...response,
          data: 'troubleshoot',
          moduleName: 'troubleshoot',
        };
      },
      providesTags: ['TroubleshootingTips'],
    }),
    addTroubleshootTips: builder.mutation<void, TroubleshootCreate>({
      query: body => {
        const { name, description, video, images } = body;
        const formData = new FormData();

        formData.append('active', '1');
        formData.append('description', description);
        formData.append('name', name);
        if (video) formData.append('video', video);

        images?.forEach(file => {
          formData.append('files', file.processedFile);
        });
        return {
          url: 'troubleshoot',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['TroubleshootingTips'],
    }),
    addTroubleshootSuccess: builder.mutation<void, TroubleshootHistoryCreate>({
      query: body => {
        return {
          url: 'troubleshoot/success',
          method: 'post',
          body,
        };
      },
    }),
    updateTroubleshootTips: builder.mutation<void, Troubleshoot>({
      query: ({ id, ...body }) => {
        const {
          name,
          description,
          video,
          images,
          status,
          deleteAttachmentsIds,
        } = body;
        const formData = new FormData();

        formData.append('status', status ? '1' : '0');
        formData.append('description', description);
        formData.append('name', name);
        if (video) formData.append('video', video);
        if (deleteAttachmentsIds && deleteAttachmentsIds.length)
          formData.append(
            'deleteAttachmentsIds',
            deleteAttachmentsIds.join(','),
          );

        images?.forEach(file => {
          formData.append('files', file.processedFile);
        });

        return {
          url: `troubleshoot/${id}`,
          method: 'put',
          body: formData,
        };
      },
      invalidatesTags: ['TroubleshootingTips'],
    }),
  }),
});

export const {
  useGetTroubleshootTipsQuery,
  useLazyGetTroubleshootTipsQuery,
  useGetTroubleshootTipsByIdQuery,
  useLazyGetTroubleshootTipsByIdQuery,
  useAddTroubleshootTipsMutation,
  useAddTroubleshootSuccessMutation,
  useUpdateTroubleshootTipsMutation,
} = troubleshootingTipsApi;
