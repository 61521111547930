import React, { useEffect } from 'react';
import { AttachmentTab } from 'app/components/ECDynamicPageTemplate/AttachmentTab';
import { Helmet } from 'react-helmet-async';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useDispatch } from 'react-redux';
import { setTitle } from 'store/slice/page';
import { useTranslation } from 'react-i18next';

export default function RequiredDocsPage() {
  const { data: userProfile } = useGetUserProfileQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.requiredDocuments')));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('translation:panelDrawer.requiredDocuments')}</title>
      </Helmet>
      <AttachmentTab
        row={{ ...userProfile?.company, moduleName: 'serviceprovider' }}
        isCustomerRequiredDocs={true}
        isCustomerOwnRequiredDocs={true}
        showDeleteSwitch
        confirmationDeleteMessage="Delete Required Document?"
        showDeleteAlert
        deleteAlertTitle="Inactivate Required Document?"
        deleteAlertText="Inactivating this file will inactivate all files that were uploaded for this Document type"
      />
    </>
  );
}
