import { HistoryList, HistoryListParams } from 'types/History';
import { BaseType } from 'types/BaseType';
import { spProfileModules } from '../utils/common';
import { QueryParams } from 'types/QueryParams';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'History',
    'TradeAssignmentActivity',
    'ApprovalProcessActivity',
  ],
});

export const historyApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getHistoryList: builder.query<
      BaseType<{ _doc: HistoryList }[]>,
      HistoryListParams & QueryParams
    >({
      query: ({ moduleName, moduleResourceId, ...params }) => {
        if (!moduleName || !moduleResourceId) {
          return {
            url: '',
          };
        }

        const module = spProfileModules.includes(moduleName)
          ? 'spcustomer'
          : moduleName;

        return {
          url: `history/${module}/${moduleResourceId}`,
          params,
        };
      },
      transformResponse: (response: BaseType<{ _doc: HistoryList }[]>) => {
        response.data = response.data.map(row => {
          if (row._doc.module === 'workorder') {
            const { body } = row._doc;
            body.spCompanyName = body.serviceProvider;
            delete body['spCompanyId'];
            delete body['serviceProvider'];
          }
          return row;
        });
        return response;
      },
      providesTags: ['History'],
    }),

    getTradeAssignmentActivities: builder.query<any, any>({
      query: ({ companyId, ...params }) => {
        if (!companyId) {
          return {
            url: '',
          };
        }

        return {
          url: `history/trade-assignment/${companyId}`,
          params,
        };
      },
      providesTags: ['TradeAssignmentActivity'],
    }),
    getApprovalWorkflowActivities: builder.query<any, any>({
      query: ({ id, ...params }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `history/approval-process/${id}`,
          params,
        };
      },
      providesTags: ['ApprovalProcessActivity'],
    }),
  }),
});

export const {
  useGetHistoryListQuery,
  useLazyGetHistoryListQuery,
  useGetTradeAssignmentActivitiesQuery,
  useGetApprovalWorkflowActivitiesQuery,
} = historyApi;
