import { useEffect, useState } from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { AlertColor } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { ECAlert } from '../ECAlert';

interface ECSnackBarProps extends SnackbarProps {
  id: string;
  severity?: AlertColor;
  message?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

export const ECOriginalSnackbar = styled(Snackbar)<SnackbarProps>(
  ({ theme }) => ({}),
);

export const ECSnackbar = (props: ECSnackBarProps) => {
  const { id, severity, message, isOpen, onClose, ...rest } = props;

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, id]);

  const handleClose = () => {
    onClose?.();
    setOpen(false);
  };

  return (
    <ECOriginalSnackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      key={'bottom right'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...rest}
    >
      <ECAlert
        onClose={handleClose}
        severity={severity}
        sx={{
          width: '528px',
          backgroundColor: theme => theme.palette.other.snackbarBackground,
          color: theme => theme.palette.common.white,
          borderRadius: 1,
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
        }}
      >
        {message}
      </ECAlert>
    </ECOriginalSnackbar>
  );
};
