import { ECBox, ECGrid, ECDynamicPageTemplate, ECPaper } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { Copy } from './Copy';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCreateAssetTypeToNameMutation,
  useDeleteAssetTypeToNameMutation,
  useLazyGetAssetTypesToNameListQuery,
} from 'services/assetTypeToNameApi';
import { handleRequestConfigs } from 'utils/ECEasyTableUtils';
import {
  AssetTypeToNameCreate,
  AssetTypeToNameList,
} from 'types/AssetTypeToName';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetLocationTypeQuery } from 'services/locationTypeApi';
import * as _ from 'lodash';
import { loadPageScopes } from 'utils/pageScopes';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from 'store/slice/page';

const tableConfig = require('./table-config.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

let config = tableConfig.data.config;

export function AssetTypeToNamePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, result, lastPromiseInfo] =
    useLazyGetAssetTypesToNameListQuery();
  const [row, setRow] = useState<AssetTypeToNameList | null>();

  const [openedDrawer, setOpenedDrawer] = useState<'copy' | undefined>(
    undefined,
  );

  useEffect(() => {
    loadPageScopes('assetTypeName');
  }, []);

  const [formFieldsCreate, setFormFieldsCreate] = useState(
    fancyFormElementsCreate.data.fields,
  );
  const [formFieldsEdit, setFormFieldsEdit] = useState(
    fancyFormElementsEdit.data.fields,
  );

  const { data: branches, isSuccess: isSuccessBranches } =
    useGetLocationTypeQuery();

  useEffect(() => {
    const assetTypeField = formFieldsCreate.find(
      field => field.fieldName === 'assetTypeName',
    );

    assetTypeField.useQuery = useGetAssetTypesListQuery;
    assetTypeField.obAlias = 'assttp.name';
  }, []);

  useEffect(() => {
    if (branches && isSuccessBranches) {
      let editSelectField = fancyFormElementsEdit.data.fields.find(
        field => field.fieldName === 'branch',
      );

      let createSelectField = fancyFormElementsCreate.data.fields.find(
        field => field.fieldName === 'branchType',
      );

      editSelectField.options =
        branches.data.map(d => ({ id: d.id, label: d.name })) || [];
      editSelectField.optionValues = branches.data.map(d => d.name) || [];

      createSelectField.options =
        branches.data.map(d => ({ id: d.id, label: d.name })) || [];
      createSelectField.optionValues = branches.data.map(d => d.name) || [];
    }
  }, [branches, isSuccessBranches]);

  const [
    doCreate,
    {
      isError: isErrorCreate,
      error: errorCreate,
      isLoading: isLoadingCreate,
      isSuccess: isSuccessCreate,
      reset: resetCreate,
    },
  ] = useCreateAssetTypeToNameMutation();

  const useCreateMutation = useCallback(() => {
    const doCreateAssetName = async createData => {
      let assetGroupAssetNameData: AssetTypeToNameCreate[] = [];
      createData?.assetTemplateNames?.forEach((assetName: string) => {
        createData?.branchType?.forEach(branch => {
          const newAssetGroupAssetName: AssetTypeToNameCreate = {
            name: assetName,
            assetTypeId: createData?.assetTypeName?.id,
            branchTypeId: branch?.id,
            status: 1,
          };
          assetGroupAssetNameData.push(newAssetGroupAssetName);
        });
      });

      await doCreate(assetGroupAssetNameData);
    };

    return [
      doCreateAssetName,
      {
        isError: isErrorCreate,
        error: errorCreate,
        isLoading: isLoadingCreate,
        isSuccess: isSuccessCreate,
        reset: resetCreate,
      },
    ];
  }, [
    isSuccessCreate,
    doCreate,
    errorCreate,
    isErrorCreate,
    isLoadingCreate,
    resetCreate,
  ]);

  useEffect(() => {
    const newFields = formFieldsEdit.map(formField => {
      if (formField.fieldName === 'assetTypeName') {
        return {
          ...formField,
          value: row?.assetTypeName,
          options: [row?.assetTypeName],
          optionValues: [row?.assetTypeName],
        };
      } else if (formField.fieldName === 'branchType') {
        return {
          ...formField,
          value: row?.branchType,
          options: [row?.branchType],
          optionValues: [row?.branchType],
        };
      } else if (formField.fieldName === 'assetTemplateNames') {
        return {
          ...formField,
          chips: row?.assetTemplateNames,
        };
      }
      return formField;
    });
    setFormFieldsEdit(newFields);
  }, [row]);

  const handleDuplicateChip = () => {
    dispatch(
      setSnackbar({
        severity: 'error',
        message: 'Tag already present. Please add another one.',
      }),
    );
  };

  const useDeleteMutation = () => {
    const [
      doDelete,
      {
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ] = useDeleteAssetTypeToNameMutation();

    const doUpdateDelete = async data => {
      if (!row) {
        return;
      }

      const deletedTagsIds = row.assetTemplateNames.map(asset => asset.id);

      if (deletedTagsIds.length) {
        await doDelete(deletedTagsIds);
      }

      if (!isDeleteError || deletedTagsIds.length === 0) {
        handleUpdateSuccess();
      }
    };

    return [
      doUpdateDelete,
      {
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ];
  };

  const useUpdateMutation = () => {
    const [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        reset: resetCreate,
      },
    ] = useCreateAssetTypeToNameMutation();

    const [
      doDelete,
      {
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ] = useDeleteAssetTypeToNameMutation();

    const doUpdate = async data => {
      if (!row) {
        return;
      }

      const oldTagsNames = _.map(row.assetTemplateNames, 'name');
      const updatedTagsNames = data.assetTemplateNames;

      const deletedTagsNames = _.difference(oldTagsNames, updatedTagsNames);
      const deletedTagsIds = deletedTagsNames?.map(
        tag =>
          row.assetTemplateNames.find(assetName => tag === assetName.name)?.id,
      );

      if (deletedTagsIds.length > 0) {
        await doDelete(_.compact(deletedTagsIds));
      }

      const newTagsNames = _.difference(updatedTagsNames, oldTagsNames);
      const newTags = newTagsNames?.map(tagName => ({
        name: tagName,
        assetTypeId: row.assetTypeId,
        branchTypeId: row.branchTypeId,
        status: 1,
        edit: true,
      }));

      if (newTags.length > 0) {
        await doCreate(newTags);
      }

      if (
        (!isCreateError && !isDeleteError) ||
        (deletedTagsIds.length === 0 && newTags.length === 0)
      ) {
        handleUpdateSuccess();
      }
    };

    return [
      doUpdate,
      {
        data: createData,
        isError: isCreateError || isDeleteError,
        error: createError || deleteError,
        isLoading: isCreateLoading || isDeleteLoading,
        isSuccess:
          (isCreateSuccess && isDeleteSuccess) ||
          (isCreateSuccess && !isDeleteError) ||
          (isDeleteSuccess && !isCreateError),
        reset: resetCreate,
      },
    ];
  };

  const handleRowClick = row => {
    setRow(row);
  };

  const handleRequest = useCallback(
    (params?: any) => {
      handleRequestConfigs(params, trigger, config);
    },
    [trigger],
  );

  const handleDuplicatePress = (row: any) => {
    setRow(row);
    handleOpenCopyDrawer();
  };

  const handleOpenCopyDrawer = () => setOpenedDrawer('copy');
  const handleCloseDrawer = () => setOpenedDrawer(undefined);

  const { data, isFetching, isLoading, isSuccess, error, isError } = result;

  const handleUpdateSuccess = useCallback(
    (params: any = {}) => {
      handleRequest(params);
    },
    [handleRequest],
  );

  useEffect(() => {
    const newRow = data?.data?.find(
      assetTypeToName =>
        assetTypeToName.assetTypeId === row?.assetTypeId &&
        assetTypeToName.branchTypeId === row?.branchTypeId,
    );

    if (newRow) {
      setRow(newRow);
    }
  }, [data, row?.assetTypeId, row?.branchTypeId]);

  return (
    <ECBox display="flex" flexDirection="column" flex={1}>
      <ECGrid mt={0} overflow="hidden" container spacing={1}>
        <ECDrawerDetails
          open={!!openedDrawer}
          anchor="right"
          onClose={handleCloseDrawer}
        >
          <ECPaper
            sx={{ height: '100%', paddingTop: '80px' }}
            role="presentation"
          >
            {openedDrawer === 'copy' && (
              <Copy row={row} branches={branches} onClose={handleCloseDrawer} />
            )}
          </ECPaper>
        </ECDrawerDetails>
        <ECDynamicPageTemplate
          pageTitle={t('translation:pages.assetGroupToName.title')}
          useLazyGetListQuery={useLazyGetAssetTypesToNameListQuery}
          showStatusActiveFilter={true}
          useCreateMutation={useCreateMutation}
          useUpdateMutation={useUpdateMutation}
          useDeleteMutation={useDeleteMutation}
          showOnlyDetailsTab
          createFormConfig={fancyFormElementsCreate.data.config}
          createFormFields={formFieldsCreate}
          editFormConfig={fancyFormElementsEdit.data.config}
          editFormFields={formFieldsEdit}
          detailsConfig={fancyFormElementsDetails.data.config}
          detailsFields={fancyFormElementsDetails.data.fields}
          onRowClick={handleRowClick}
          onDuplicateChip={handleDuplicateChip}
          onDuplicatePress={handleDuplicatePress}
          deleteConfirmationTitle={t(
            'translation:pages.assetGroupToName.delete.title',
          )}
          deleteConfirmationBody={t(
            'translation:pages.assetGroupToName.delete.body',
          )}
          updatedRow={row}
          marginTop={false}
          enableExport
          exportModuleName="assetnameassetgroup"
          shouldNotUseActiveFilter
        />
      </ECGrid>
    </ECBox>
  );
}
