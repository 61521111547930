import { Checkbox, IconButton, InputAdornment, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECMenuItem } from '../ECSelect';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECPopover } from '../ECPopover';
import { ArrowDropDown } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetUsersListQuery } from 'services/userApi';
import _ from 'lodash';
import { ECPopper } from '../ECPopper';
import { UserList } from 'types/User';
import { ECChip } from '../ECChip';
import { ECStack } from '../ECStack';
import { ECIconButton } from '../ECIconButton';
import { ECCheckbox } from '../ECCheckbox';

export const ECTechAssignedFilter = () => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [anchorEl, setAnchorElManufacturer] = useState<HTMLDivElement | null>(
    null,
  );
  const [options, setOptions] = useState<any>([]);
  const [searchText, setSearchText] = useState('');

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const idPopover = useMemo(() => {
    return open ? 'popover' : undefined;
  }, [open]);

  const inputRef = useRef(null);

  const handleClickTechnician = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElManufacturer(inputRef?.current);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorElManufacturer(null);
  }, []);

  const { data: internalTechs, isFetching } = useGetUsersListQuery({
    p: 0,
    t: 500,
    ob: 'cmpny.name',
    o: 'a',
    cf: 'internal',
  });

  useEffect(() => {
    const internalTechsData: any[] = internalTechs?.data as any;
    if (internalTechsData && !isFetching) {
      const groupedByCompanyName = internalTechsData.reduce<
        Record<string, UserList[]>
      >((acc, item) => {
        const companyName = item?.company?.name || 'Unknown';
        if (!acc[companyName]) {
          acc[companyName] = [];
        }
        acc[companyName].push(item);
        return acc;
      }, {});

      const sortedGroupedByCompanyName = Object.entries(
        groupedByCompanyName,
      ).map(([companyName, items]) => ({
        companyName,
        items: items.sort((a, b) =>
          a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
        ),
      }));

      const result = sortedGroupedByCompanyName.flatMap(companyGroup =>
        companyGroup.items.map(item => {
          const fullName = item.fullName || '';
          const truncSize = 30;
          const truncatedName =
            fullName.length > truncSize
              ? fullName.slice(0, truncSize) + '...'
              : fullName;

          return {
            group: companyGroup.companyName,
            name: truncatedName,
            label: truncatedName,
            id: item.id,
          };
        }),
      );

      setOptions(_.uniqBy(result, 'id'));
    }
  }, [internalTechs, isFetching]);

  const optionsWithAll = useMemo(() => {
    return [
      {
        label: 'all',
        fieldName: 'all',
        id: 0,
      },
      ...(options || []),
    ];
  }, [options]);

  const filterText = useMemo(() => {
    return activeFilter?.internalTechs?.length || 0;
  }, [activeFilter?.internalTechs]);

  const inputChip = useMemo(() => {
    // if none or all, text should be ALL
    if (
      !activeFilter?.internalTechs?.length ||
      activeFilter?.internalTechs?.length === options?.length
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.internalTechs?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter?.internalTechs, options]);

  const handleChangeTechnician = useCallback(
    (event, value, reason) => {
      if (value[value?.length - 1]?.id === -1) {
        return;
      }

      setSearchText('');
      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            internalTechs:
              activeFilter?.internalTechs?.length === options?.length
                ? []
                : options?.map(option => ({
                    id: option.id,
                    name: option.fieldName || option.name,
                  })),
            isSelectAllInternalTechs: !(
              activeFilter?.internalTechs?.length === options?.length
            ),
          }),
        );
        return;
      }
      dispatch(
        setActiveFilter({
          internalTechs: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllInternalTechs:
            !filterText && value?.length === options?.length,
        }),
      );
    },
    [optionsWithAll, activeFilter?.internalTechs, options],
  );

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  return (
    <ECStack
      direction="row"
      alignItems="center"
      width="260px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
      bgcolor={theme => theme.palette.background.default}
    >
      {optionsWithAll && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Technician Assigned:
          </ECTypography>
          <ECBox
            display="flex"
            alignItems="center"
            onClick={handleClickTechnician}
            ref={inputRef}
          >
            {inputChip}
            <ECIconButton
              type="default"
              size="small"
              noPadding
              sx={{ marginLeft: '5px' }}
            >
              <ArrowDropDown />
            </ECIconButton>
          </ECBox>
          <ECPopover
            id={idPopover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              bgcolor: 'transparent',
              '& .MuiPopover-paper': {
                minWidth: '350px',
                bgcolor: 'transparent',
                padding: 0,
                overflow: 'hidden',
              },
            }}
          >
            <ECBox
              display="flex"
              bgcolor="white !important"
              minHeight="470px"
              width="350px"
            >
              <ECAutocompleteStyled
                multiple
                open
                disablePortal
                sx={{
                  width: '100%',
                  minHeight: '400px',
                  '& .MuiAutocomplete-paper': {
                    maxHeight: '400px',
                    overflowY: 'auto',
                  },
                  '& .MuiAutocomplete-listbox': {
                    maxHeight: '400px',
                    overflowY: 'auto',
                  },
                }}
                options={optionsWithAll}
                groupBy={option => option?.group}
                renderGroup={params => (
                  <ECBox key={params.key}>
                    {params.group && (
                      <ECTypography
                        sx={theme => ({
                          backgroundColor: theme.palette.info.background,
                          color: theme.palette.text.secondary,
                          padding: '8px 16px',
                          fontSize: 14,
                        })}
                      >
                        {params.group}
                      </ECTypography>
                    )}
                    {params.children}
                  </ECBox>
                )}
                value={activeFilter?.internalTechs || []}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                ListboxProps={{
                  sx: {
                    maxHeight: '400px',
                    overflowY: 'auto',
                    bgcolor: 'white',
                    padding: 0,
                    '& ul': {
                      padding: 0,
                      margin: 0,
                    },
                  },
                }}
                disableCloseOnSelect
                onChange={handleChangeTechnician}
                renderInput={params => (
                  <ECBox display="flex" padding={1} bgcolor="white">
                    <ECTextField
                      {...params}
                      placeholder="Search"
                      sx={{
                        minWidth: 'fit-content !important',
                        padding: '0px !important',
                        '.MuiFilledInput-root': {
                          paddingRight: '0px !important',
                        },
                      }}
                      hiddenLabel
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <></>,
                      }}
                    />
                  </ECBox>
                )}
                renderTags={() => ''}
                PopperComponent={renderPopper}
                renderOption={(props, option, state) => {
                  if (option?.fieldName === 'all') {
                    return (
                      <ECMenuItem
                        {...props}
                        key={option?.id || option}
                        id={option?.fieldName || option}
                      >
                        <ECCheckbox
                          checked={!!Number(filterText)}
                          indeterminate={
                            !!Number(filterText) &&
                            Number(filterText) < options.length
                          }
                        ></ECCheckbox>
                        <ECTypography
                          variant="body1"
                          color={theme => theme.palette.primary.main}
                        >
                          {activeFilter?.internalTechs?.length ===
                          options?.length
                            ? 'Deselect All'
                            : 'Select All'}
                        </ECTypography>
                      </ECMenuItem>
                    );
                  }

                  return (
                    <ECMenuItem
                      {...props}
                      data-name={
                        option?.id === optionsWithAll?.[0]?.id ? 'first' : ''
                      }
                      sx={{ width: '100%' }}
                      key={option?.id || option}
                      id={option?.fieldName || option}
                      disabled={option?.disabled}
                    >
                      <Checkbox
                        sx={{ p: 0, m: 0, mr: 1 }}
                        checked={state.selected}
                      />
                      {option?.label}
                    </ECMenuItem>
                  );
                }}
              />
            </ECBox>
          </ECPopover>
        </>
      )}
    </ECStack>
  );
};
