import { useGetApprovalWorkflowActivitiesQuery } from 'services/historyApi';
import ApprovalWorkflowActivity from './ApprovalWorkflowActivity';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECBox } from '../ECBox';
import { useMemo } from 'react';

interface ECApprovalWorkflowActivityProps {
  id: number;
}

export const ECApprovalWorkflowActivities = ({
  id,
}: ECApprovalWorkflowActivityProps) => {
  const { data: approvalProcessActivities, isLoading } =
    useGetApprovalWorkflowActivitiesQuery(
      {
        id: id,
      },
      {
        skip: !id,
      },
    );

  const memoizedActivities = useMemo(() => {
    return approvalProcessActivities?.data?.map(
      (activity: any, index: number) => (
        <ApprovalWorkflowActivity
          key={
            `${activity._doc?._id.toString()}.${Date.now()}` ||
            `${index}.${Date.now()}`
          }
          entry={{
            ...activity._doc,
          }}
        />
      ),
    );
  }, [approvalProcessActivities?.data]);

  return (
    <ECBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
      }}
    >
      <ECBox
        px={3}
        pb={4}
        mt={3}
        display="flex"
        flexDirection="column"
        rowGap={4}
      >
        {isLoading && <ECCircularProgress sx={{ marginX: 'auto' }} />}
        {memoizedActivities}
      </ECBox>
    </ECBox>
  );
};
