import { ECTabContainer } from 'app/components';
import { useServiceProviderUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { ForbiddenPage } from '../ForbiddenPage';
import { ServiceProviderJobTitlesPage } from '../ServiceProviderJobTitlesPage';
import { ServiceProviderUsersPage } from '../ServiceProviderUsersPage';
import { NotificationsSettingsPage } from '../NotificationsSettingsPage';

export function UserManagementPage() {
  const { t } = useTranslation();

  const isServiceProvider = useServiceProviderUser();

  if (!isServiceProvider) {
    return <ForbiddenPage />;
  }

  return (
    <ECTabContainer
      tabsAsLinks
      mt={StyleConstants.NAV_BAR_HEIGHT}
      p={2}
      variant="standard"
      color="marketingSuggestion"
      noPadding
      tabs={[
        {
          value: 'users',
          label: t('translation:panelDrawer.user'),
          content: <ServiceProviderUsersPage />,
          link: 'users',
        },
        {
          value: 'job-titles',
          label: t('translation:panelDrawer.jobTitles'),
          content: <ServiceProviderJobTitlesPage />,
          scopes: [P.GetAllJobTitle],
          link: 'job-titles',
        },
        {
          value: 'notifications',
          label: t('translation:panelDrawer.notifications'),
          content: <NotificationsSettingsPage />,
          scopes: [P.GetUser, P.SPMenu],
          link: 'notifications',
        },
        // {
        //   value: 'permissions',
        //   label: t('translation:panelDrawer.permissions'),
        //   content: <WIPPage />,
        //   link: 'permissions',
        // },
      ]}
    />
  );
}
