import { ECLink } from '../ECLink';
import { ECAlert } from '../ECAlert';
import { ECBox } from '../ECBox';
import { useCreateCoupaPurchaseOrderMutation } from 'services/invoiceApi';
import { ECTypography } from '../ECTypography';
import { ECButton } from '../ECButton';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { themes } from 'styles/theme/themes';
import { moduleApi } from 'services/moduleApi';

interface ECCoupaField {
  href?: string;
  retryCoupa?: boolean;
  requestId?: number;
  linkLabel?: string;
}

export const ECCoupaField = ({
  href,
  retryCoupa,
  requestId,
  linkLabel,
}: ECCoupaField) => {
  const dispatch = useDispatch();

  const [createCoupaPurchaseOrder, { isLoading, isError, error, isSuccess }] =
    useCreateCoupaPurchaseOrderMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            typeof error === 'string'
              ? error
              : `Error creating the Purchase Order on Coupa`,
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `Purchase Order created succesfully on Coupa`,
        }),
      );
    }
  }, [isSuccess]);

  return retryCoupa ? (
    <ECAlert
      severity="warning"
      sx={{
        alignItems: 'center',
        '.MuiAlert-message': {
          paddingRight: '26px',
          width: '100% !important',
        },
      }}
    >
      <ECBox
        display={'flex'}
        sx={{
          alignItems: 'center',
          height: '30px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ECTypography variant="body2" marginRight={'10px'} fontWeight={'bold'}>
          Error Creating Purchase Order.
        </ECTypography>
        {requestId && (
          <ECButton
            variant="text"
            color="inherit"
            sx={{
              color: themes.light.palette.warning.content + ' !important',
              fontWeight: 'bold',
            }} // "#66481c !important", fontWeight: "bold"
            onClick={() => createCoupaPurchaseOrder(requestId)}
            disabled={isLoading}
            isLoading={isLoading}
            loadingSpinnerColor={themes.light.palette.warning.content}
          >
            Retry
          </ECButton>
        )}
      </ECBox>
    </ECAlert>
  ) : (
    <ECAlert
      severity="info"
      sx={{
        alignItems: 'center',
        '.MuiAlert-message': {
          paddingRight: '26px',
        },
      }}
    >
      <ECBox
        display={'flex'}
        sx={{
          alignItems: 'center',
          height: '30px',
          justifyContent: 'space-between',
        }}
      >
        <ECLink
          variant="body2"
          marginRight={'10px'}
          fontWeight={'bold'}
          href={href ? href : ''}
        >
          {linkLabel || 'CLICK HERE TO VIEW THE PURCHASE ORDER IN COUPA'}
        </ECLink>
      </ECBox>
    </ECAlert>
  );
};
