import { styled } from '@mui/material/styles';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

export const ECLinearProgress = styled(LinearProgress)<LinearProgressProps>(
  ({ theme }) => ({
    marginTop: '20px',
  }),
);
