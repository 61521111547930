import { Helmet } from 'react-helmet-async';
import { Route, Routes, useMatch, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  ECMainBox,
  ECBgBox,
  ECNavBar,
  ECRequirePermission,
} from '../../components';
import {
  DashboardPage,
  FormsPage,
  ReportsPage,
  RequestForPricingPage,
  ServiceRequestPage,
  TablesPage,
  ProblemToAssetTypesPage,
  WelcomeToPanelPage,
  ReportingPage,
  Branches,
  OrganizationPage,
  WorkOrdersPage,
  TroubleshootingPage,
  WorkOrderDetailsPage,
  LocationManagerPage,
  UsersPage,
  CustomersPage,
  UserManagementPage,
  SPGeneralSettingsPage,
  GeneralSettingsPage,
  AssetWorkflowPage,
  ProposalsPage,
  ServiceProviderDetailsPage,
  ServiceProviderWorkOrdersPage,
  ServiceProviderWorkOrderDetailsPage,
  ServiceProviderProposalsPage,
  PreventiveMaintenancePage,
  AssetTradeProblemPage,
  AssetTypePage,
  AssetTypeToNamePage,
  AssetTypeToRepairPage,
  Failure,
  ProblemPage,
  TradePage,
  TroubleshootingTipsPage,
  WIPPage,
  CategoriesPage,
  AdminRolesPage,
  AdminUserRolesPage,
  JobTitlesRolePage,
  AdminUsersPage,
  ServiceProviderTradeAndRegionPage,
  ConceptsPage,
  AreaPage,
  InventoryTabsPage,
  LocationsTabsPage,
  SettingsPage,
  PurchaseOrdersTabsPage,
} from '../../pages';
import { PanelDrawer } from '../../sections/PanelDrawer';
import { PanelDrawerCollapsibleItemsProvider } from 'app/sections/PanelDrawer/context/panel-drawer-expanded-item.context';
import { PanelDrawerSecondLevelCollapsibleItemsProvider } from 'app/sections/PanelDrawer/context/panel-drawer-second-level-expanded-item.context';
import { CompanyConfigurationFields, P } from 'types/Permission';
import { SPDirectoryResults } from 'app/pages/SPDirectoryPage/ResultsPage';
import { AssetDetailsPage } from 'app/pages/AssetDetailsPage';
import { WarrantyPage } from 'app/pages/WarrantyPage';
import { ServiceHistoryPage } from 'app/pages/ServiceHistoryPage';
import { InvoicesDetailsPage } from 'app/pages/InvoicesDetailsPage';
import { InvoicesPage } from 'app/pages/InvoicesPage';
import { ProposalDetailsPage } from 'app/pages/ProposalDetailsPage';
import { ApprovalPage } from 'app/pages/ApprovalPage';
import { AccountingPage } from 'app/pages/AccountingPage';
import { ServiceProvidersPage } from 'app/pages/ServiceProvidersPage';
import { AssetTabsPage } from 'app/pages/AssetPage';
import { AdminPage } from 'app/pages/AdminPage';
import { CreateServiceRequestProvider } from '../../../store/contexts';
import { CompanyProfileDetailsPage } from 'app/pages/CompanyProfileDetailsPage';
import { SPProfileDetailsPage } from 'app/pages/SPProfileDetailsPage';
import { DecideUserPage } from '../../pages/DecideUserPage';
import { ServiceProviderInvoicesPage } from 'app/pages/ServiceProviderInvoicesPage';
import { ServiceProviderInvoicesDetailsPage } from 'app/pages/ServiceProviderInvoicesDetailsPage';
import { GeneralAssetDetailsPage } from 'app/pages/GeneralAssetDetailsPage';
import { AdminUsersDetailsPage } from 'app/pages/AdminUsersDetailsPage';
import { Navigate } from 'react-router-dom';
import { ServiceProviderUsersDetailsPage } from 'app/pages/ServiceProviderUsersDetailsPage';
import { PreventiveMaintenanceDetailsPage } from 'app/pages/PreventiveMaintenancePage/components/Details';
import { CustomerDetailsPage } from 'app/pages/CustomerDetailsPage';
import { GlobalSearchPage } from 'app/pages/GlobalSearchPage';

import { ImpersonatePage } from 'app/pages/ImpersonatePage';
import { AssetVerificationToolPage } from 'app/pages/AssetVerificationToolPage';
import { AssetDetailsAvtPage } from 'app/pages/AssetVerificationToolPage/AssetDetailsPage';

import { ServiceProviderProposalDetailsPage } from 'app/pages/ServiceProviderProposalDetailsPage';
import { AttachmentTab } from 'app/components/ECDynamicPageTemplate/AttachmentTab';
import { NoteTab } from 'app/components/ECDynamicPageTemplate/NoteTab';
import { HistoryTab } from 'app/components/ECDynamicPageTemplate/HistoryTab';
import { AssetProblemTroubleshootPage } from 'app/pages/AssetProblemTroubleshootPage';
import { PrioritiesPage } from 'app/pages/PrioritiesPage';
import { FlagsPage } from 'app/pages/FlagsPage';
import { LocationAssignment } from 'app/pages/AdminUsersDetailsPage/LocationAssignment';
import { SPLocationAssignment } from 'app/pages/AdminUsersDetailsPage/SPLocationAssignment';
import { useGetProfileQuery } from 'services/profileApi';
import RequiredDocsPage from 'app/pages/RequiredDocsPage';
import { SPDirectorySearch } from 'app/pages/SPDirectoryPage/SearchPage';
import { SPGridViewPage } from 'app/pages/SPGridViewPage';
import { SPInvitedPage } from 'app/pages/SPInvitedPage';
import { TradeAssignmentPage } from 'app/pages/TradeAssignmentPage';
import { ManagedAssetsPage } from 'app/pages/ManagedAssets';
import { GeneralAssetsPage } from 'app/pages/GeneralAssets';
import { JobTitlesPage } from 'app/pages/JobTitlesPage';
import { CustomerPendingPage } from 'app/pages/CustomerPendingPage';
import { SPCustomersManagementPage } from 'app/pages/SPCustomersManagementPage';
import { ServiceProviderUsersPage } from 'app/pages/ServiceProviderUsersPage';
import { ServiceProviderJobTitlesPage } from 'app/pages/ServiceProviderJobTitlesPage';
import { RatesAndTermsPage } from 'app/pages/RatesAndTermsPage';
import { useCustomerUser } from 'app/hooks';
import { ScheduleLogPage } from 'app/pages/ScheduleLogPage';
import { PMLocations } from 'app/pages/PreventiveMaintenancePage/components/Locations/PreventiveMaintenanceLocations';
import { ErrorPage } from 'app/pages/ErrorPage';
import { CustomerAssetsPage } from 'app/pages/CustomerDetailsPage/customer-assets';
import { SPAssetDetailsPage } from 'app/pages/SPAssetDetailsPage';
import { SPGeneralAssetDetailsPage } from 'app/pages/SPGeneralAssetDetailsPage';
import { NotificationsPage } from 'app/pages/NotificationsPage';
import { LocationAssetsAvt } from 'app/pages/AssetVerificationToolPage/LocationAssets';
import { InventoryPage } from 'app/pages/InventoryPage';
import { InventoryStorageLocations } from 'app/pages/InventoryStorageLocations';
import { Regions } from 'app/pages/Regions';
import { Districts } from 'app/pages/Districts';
import { InventoryDetailsPage } from 'app/pages/InventoryDetailsPage';
import { NotificationsSettingsPage } from 'app/pages/NotificationsSettingsPage';
import { RegionsDistrictsPage } from 'app/pages/RegionsDistrictsPage';
import InventoryAllocatedToPage from 'app/pages/InventoryAllocatedToPage';
import { DispatchTabsPage } from 'app/pages/DispatchPage/DispatchTabsPage';
import { FeedbackTab } from 'app/components/ECDynamicPageTemplate/FeedbackTab';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import Intercom from '@intercom/messenger-js-sdk';
import CryptoJS from 'crypto-js';
import { ServiceProviderRequestForPricingPage } from 'app/pages/ServiceProviderRequestForPricingPage';
import { Suppliers } from 'app/pages/Suppliers';
import { RfpDetailsPage } from 'app/pages/RfpDetailsPage';
import { ServiceProviderRfpDetailsPage } from 'app/pages/ServiceProviderRfpDetailsPage';
import { ExportsPage } from 'app/pages/ExportsPage';
import { SupplierDetailsPage } from 'app/pages/SupplierDetailsPage';
import { PurchaseOrders } from 'app/pages/PurchaseOrders';
import { PurchaseOrderDetailsPage } from 'app/pages/PurchaseOrderDetailsPage';

(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    } as any;
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/osend44f';
      var x = d.getElementsByTagName('script')[0];
      if (x.parentNode) {
        x.parentNode.insertBefore(s, x);
      } else {
        console.log('Intercom script not found ***');
      }
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

export function PanelNavigator() {
  let match = useMatch('*');
  let url = match?.pathnameBase;
  const location = useLocation();
  const { data: companyDetails } = useGetProfileQuery(undefined, {
    pollingInterval: 1000 * 60 * 15, // 15 minutes
  });

  const { data, isSuccess } = useGetUserProfileQuery();
  const isCustomer = useCustomerUser();

  //  old intercom integration

  // useEffect(() => {
  //   if (data && data.userId && isSuccess) {
  //     // Intercom Integration
  //     const secretKey = `${process.env.REACT_APP_INTERCOM_SECRET_KEY}`;
  //     const userIdentifier = data.userId.toString();
  //     const userHash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(
  //       CryptoJS.enc.Hex,
  //     );

  //     Intercom({
  //       app_id: `${process.env.REACT_APP_INTERCOM_APP_ID}`,
  //       user_id: userIdentifier,
  //       name: data.fullName,
  //       email: data.email,
  //       user_hash: userHash,
  //     });
  //   }
  // }, [data?.userId, data?.company?.id, isSuccess]);

  useEffect(() => {
    if (data && data.userId && isSuccess) {
      const secretKey = `${process.env.REACT_APP_INTERCOM_SECRET_KEY}`;
      const userIdentifier = data.userId.toString();
      const userHash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(
        CryptoJS.enc.Hex,
      );

      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          user_id: userIdentifier,
          name: data.fullName,
          email: data.email,
          user_hash: userHash,
        });
      }
    }

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccess && data) {
      if (window.Intercom) {
        window.Intercom('update', {
          last_request_at: Math.floor(Date.now() / 1000),
        });
      }
    }
  }, [location, data, isSuccess]);

  const sessionToken = localStorage.getItem('idToken');
  if (!sessionToken) return <Navigate to={'/'} />;

  return (
    <>
      <Helmet>
        <title>Panel</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet>
      <CreateServiceRequestProvider>
        <PanelDrawerCollapsibleItemsProvider>
          <ECBgBox sx={{ display: 'flex' }}>
            <ECNavBar />
            <PanelDrawerSecondLevelCollapsibleItemsProvider>
              <PanelDrawer />
            </PanelDrawerSecondLevelCollapsibleItemsProvider>
            <ECMainBox component={'main'}>
              <Routes>
                <Route path={`${url}`} element={<DecideUserPage />} />
                <Route path={`${url}/error`} element={<ErrorPage />} />
                <Route path={`${url}/dashboard`} element={<DashboardPage />} />
                <Route
                  path={`${url}/reports`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetQsReport]}
                      redirectNoPermission={true}
                    >
                      <ReportsPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/schedule-log`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetScheduledTask]}
                      redirectNoPermission
                    >
                      <ScheduleLogPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/work-orders`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllWorkOrders]}
                      redirectNoPermission
                    >
                      <WorkOrdersPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/sp/work-orders`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllWorkOrders, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderWorkOrdersPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/sp/workorder/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetWorkOrder, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderWorkOrderDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetWorkOrder, P.SPMenu]}
                        redirectNoPermission
                      >
                        <ServiceProviderWorkOrderDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder, P.GetAttachment, P.SPMenu]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder, P.GetComment, P.SPMenu]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder, P.SPMenu]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/workorder/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetWorkOrder]}
                      redirectNoPermission
                    >
                      <WorkOrderDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetWorkOrder]}
                        redirectNoPermission
                      >
                        <WorkOrderDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="feedback"
                    element={
                      <ECRequirePermission
                        scopes={[
                          P.GetWorkOrder,
                          P.GetSurveyFeedback,
                          P.GetSurveyTemplate,
                        ]}
                        redirectNoPermission
                      >
                        <FeedbackTab moduleName="workorder" />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="workorder"
                        scopes={[P.GetWorkOrder]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/serviceprovider/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetServiceProvider]}
                      redirectNoPermission
                    >
                      <ServiceProviderDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetServiceProvider]}
                        redirectNoPermission
                      >
                        <ServiceProviderDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="rates-and-terms"
                    element={
                      <RatesAndTermsPage
                        onlyEdit={!isCustomer}
                        showEditButton={!isCustomer}
                      />
                    }
                  />
                  <Route
                    path="trades-and-regions"
                    element={<ServiceProviderTradeAndRegionPage />}
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="serviceprovider"
                        idAttributeName="id"
                        requiredDocsCustomerIdName="companyId"
                        requiredDocsSpIdName="spCompanyId"
                        confirmationDeleteMessage="Delete Required Document?"
                        isSpRequiredDocs={true}
                        scopes={[P.GetServiceProvider, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="serviceprovider"
                        scopes={[P.GetServiceProvider, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="serviceprovider"
                        idAttributeName="profileId"
                        scopes={[P.GetServiceProvider]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/proposals`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllProposals, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderProposalsPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/sp/proposal/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetProposal, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderProposalDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetProposal, P.SPMenu]}
                        redirectNoPermission
                      >
                        <ServiceProviderProposalDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="proposal"
                        scopes={[P.GetProposal, P.GetAttachment, P.SPMenu]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="proposal"
                        scopes={[P.GetProposal, P.GetComment, P.SPMenu]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="proposal"
                        scopes={[P.GetProposal, P.SPMenu]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/customer/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetCustomer, P.SPMenu]}
                      redirectNoPermission
                    >
                      <CustomerDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetCustomer, P.SPMenu]}
                        redirectNoPermission
                      >
                        <CustomerDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="assets"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetCustomer, P.SPMenu]}
                        redirectNoPermission
                      >
                        <CustomerAssetsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="rates-and-terms"
                    element={<RatesAndTermsPage />}
                  />
                  <Route
                    path="trades-and-regions"
                    element={<ServiceProviderTradeAndRegionPage />}
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="spcustomer"
                        ownModuleName="serviceprovider"
                        moduleNameToFecthDetails="serviceprovider/customer"
                        idAttributeName="profileId"
                        isSpRequiredDocs={true}
                        requiredDocsCustomerIdName="id"
                        requiredDocsSpIdName="companyId"
                        confirmationDeleteMessage="Delete Required Document?"
                        requiredDocSpIdFromProps={companyDetails?.id}
                        scopes={[P.GetCustomer, P.GetAttachment, P.SPMenu]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="spcustomer"
                        moduleNameToFecthDetails="serviceprovider/customer"
                        scopes={[P.GetCustomer, P.GetComment, P.SPMenu]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="spcustomer"
                        moduleNameToFecthDetails="serviceprovider/customer"
                        idAttributeName="profileId"
                        scopes={[P.GetCustomer, P.SPMenu]}
                      />
                    }
                  />
                </Route>
                <Route path={`${url}/proposals`} element={<ProposalsPage />} />
                <Route
                  path={`${url}/proposal/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetProposal]}
                      redirectNoPermission
                    >
                      <ProposalDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetProposal]}
                        redirectNoPermission
                      >
                        <ProposalDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="proposal"
                        scopes={[P.GetProposal, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="proposal"
                        scopes={[P.GetProposal, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="proposal"
                        scopes={[P.GetProposal]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/general-asset/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.SPMenu, P.GetGeneralAsset]}
                      redirectNoPermission
                    >
                      <SPGeneralAssetDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.SPMenu, P.GetGeneralAsset]}
                        redirectNoPermission
                      >
                        <SPGeneralAssetDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="general-asset"
                        scopes={[P.SPMenu, P.GetGeneralAsset, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="general-asset"
                        scopes={[P.SPMenu, P.GetGeneralAsset, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="general-asset"
                        scopes={[P.SPMenu, P.GetGeneralAsset]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/general-asset/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetGeneralAsset]}
                      redirectNoPermission
                    >
                      <GeneralAssetDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetGeneralAsset]}
                        redirectNoPermission
                      >
                        <GeneralAssetDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="general-asset"
                        scopes={[P.GetGeneralAsset, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="general-asset"
                        scopes={[P.GetGeneralAsset, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="general-asset"
                        scopes={[P.GetGeneralAsset]}
                      />
                    }
                  />
                </Route>
                <Route path={`${url}/reporting`} element={<ReportingPage />} />
                <Route
                  path={`${url}/service-providers`}
                  element={<ServiceProvidersPage />}
                >
                  <Route
                    path="service-providers"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllServiceProviders]}
                        redirectNoPermission
                      >
                        <SPGridViewPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="invited-service-providers"
                    element={<SPInvitedPage />}
                  />
                  <Route path="sp-directory" element={<SPDirectorySearch />} />
                  <Route
                    path="trade-assignment"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetTradeAssignments]}
                        redirectNoPermission
                      >
                        <TradeAssignmentPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route path="required-docs" element={<RequiredDocsPage />} />
                </Route>
                <Route
                  path={`${url}/service-providers/sp-directory/results`}
                  element={<SPDirectoryResults />}
                />
                <Route
                  path={`${url}/locations`}
                  element={<LocationsTabsPage />}
                >
                  <Route
                    path="management"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllBranches]}
                        redirectNoPermission
                      >
                        <Branches />
                      </ECRequirePermission>
                    }
                  />

                  <Route
                    path="regions"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetOrg]}
                        redirectNoPermission
                      >
                        <Regions marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="districts"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetOrg]}
                        redirectNoPermission
                      >
                        <Districts marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="regions-districts"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetOrg]}
                        redirectNoPermission
                      >
                        <RegionsDistrictsPage marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                </Route>

                <Route
                  path={`${url}/inventoy-parts/purchase-orders`}
                  element={<PurchaseOrdersTabsPage />}
                >
                  <Route
                    path="purchase-orders"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetPurchaseOrder]}
                        redirectNoPermission
                      >
                        <PurchaseOrders marginTop={false} />
                      </ECRequirePermission>
                    }
                  />

                  <Route
                    path="suppliers"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetSupplier]}
                        redirectNoPermission
                      >
                        <Suppliers marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="parts-ordering"
                    element={
                      <ECRequirePermission
                        // scopes={[P.qqqqqqqqqqqqqqqqqqqqq]} TODO: ADD THE CORRECT PERMISSION
                        redirectNoPermission
                      >
                        <h2>parts ordering page</h2>
                      </ECRequirePermission>
                    }
                  />
                </Route>

                <Route
                  path={`${url}/purchase-orders/:id`}
                  element={
                    <ECRequirePermission redirectNoPermission>
                      <PurchaseOrderDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission redirectNoPermission>
                        <PurchaseOrderDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="purchase-order"
                        moduleNameToFecthDetails="purchaseorder"
                        scopes={[P.GetPurchaseOrder, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="purchase-order"
                        scopes={[P.GetSupplier]}
                      />
                    }
                  />
                </Route>

                <Route
                  path={`${url}/supplier/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetSupplier]}
                      redirectNoPermission
                    >
                      <SupplierDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetSupplier]}
                        redirectNoPermission
                      >
                        <SupplierDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="supplier"
                        scopes={[P.GetSupplier, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="supplier"
                        scopes={[P.GetSupplier]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/assets`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllAssets]}
                      redirectNoPermission
                    >
                      <AssetTabsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="managed-assets"
                    element={<ManagedAssetsPage marginTop={false} />}
                  />
                  <Route
                    path="general-assets"
                    element={<GeneralAssetsPage marginTop={false} />}
                  />
                </Route>
                <Route
                  path={`${url}/sp/asset/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAsset, P.SPMenu]}
                      redirectNoPermission
                    >
                      <SPAssetDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAsset, P.SPMenu]}
                        redirectNoPermission
                      >
                        <SPAssetDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="asset"
                        scopes={[P.SPMenu, P.GetAsset, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="asset"
                        scopes={[P.SPMenu, P.GetAsset, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="asset"
                        scopes={[P.SPMenu, P.GetAsset]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/asset/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAsset]}
                      redirectNoPermission
                    >
                      <AssetDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAsset]}
                        redirectNoPermission
                      >
                        <AssetDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route path="history" element={<ServiceHistoryPage />} />
                  <Route path="warranty" element={<WarrantyPage />} />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="asset"
                        scopes={[P.GetAsset, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="asset"
                        scopes={[P.GetAsset, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab moduleName="asset" scopes={[P.GetAsset]} />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/pms`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetPreventiveMaintenancesList]}
                      redirectNoPermission
                    >
                      <PreventiveMaintenancePage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/preventivemaintenance/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetPreventiveMaintenance]}
                      redirectNoPermission
                    >
                      <PreventiveMaintenanceDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetPreventiveMaintenance]}
                        redirectNoPermission
                      >
                        <PreventiveMaintenanceDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route path="pmlocation" element={<PMLocations />} />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="preventivemaintenance"
                        scopes={[P.GetPreventiveMaintenance, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="preventivemaintenance"
                        scopes={[P.GetPreventiveMaintenance, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="preventivemaintenance"
                        scopes={[P.GetPreventiveMaintenance]}
                      />
                    }
                  />
                </Route>

                <Route
                  path={`${url}/inventory-parts/inventory`}
                  element={<InventoryTabsPage />}
                >
                  <Route
                    path="inventory-list"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        redirectNoPermission
                      >
                        <InventoryPage />
                      </ECRequirePermission>
                    }
                  />

                  <Route
                    path="storage-locations"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        redirectNoPermission
                      >
                        <InventoryStorageLocations marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                </Route>

                <Route
                  path={`${url}/inventory/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetInventory]}
                      redirectNoPermission
                      companyConfigScopes={[
                        CompanyConfigurationFields.EnableInventory,
                      ]}
                    >
                      <InventoryDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        redirectNoPermission
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      >
                        <InventoryDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="allocated-to"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        redirectNoPermission
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      >
                        <InventoryAllocatedToPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="inventory"
                        scopes={[P.GetInventory, P.GetAttachment]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="inventory"
                        scopes={[P.GetInventory]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      />
                    }
                  />
                </Route>

                <Route
                  path={`${url}/sp/inventory`}
                  element={<InventoryTabsPage />}
                >
                  <Route
                    path="inventory-list"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        redirectNoPermission
                      >
                        <InventoryPage />
                      </ECRequirePermission>
                    }
                  />

                  <Route
                    path="storage-locations"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventoryStorage]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        redirectNoPermission
                      >
                        <InventoryStorageLocations marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                </Route>

                <Route
                  path={`${url}/sp/inventory/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetInventory]}
                      redirectNoPermission
                      companyConfigScopes={[
                        CompanyConfigurationFields.EnableInventory,
                      ]}
                    >
                      <InventoryDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        redirectNoPermission
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      >
                        <InventoryDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="allocated-to"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInventory]}
                        redirectNoPermission
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      >
                        <InventoryAllocatedToPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="inventory"
                        scopes={[P.GetInventory, P.GetAttachment]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="inventory"
                        scopes={[P.GetInventory]}
                        companyConfigScopes={[
                          CompanyConfigurationFields.EnableInventory,
                        ]}
                      />
                    }
                  />
                </Route>

                {/* removed on DEV-13527 <Route
                  path={`${url}/configuration/company-profile`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetOrgTreeByCompany]}
                      redirectNoPermission
                    >
                      <OrganizationPage />
                    </ECRequirePermission>
                  }
                /> */}
                <Route
                  path={`${url}/configuration/asset-workflow`}
                  element={<AssetWorkflowPage />}
                >
                  <Route
                    path={'asset-types'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllAssetTypes]}
                        redirectNoPermission
                      >
                        <AssetTypePage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'asset-type-to-name'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllAssetTypesWithNames]}
                        redirectNoPermission
                      >
                        <AssetTypeToNamePage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'asset-trade-to-problem'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllProblemTypes]}
                        redirectNoPermission
                      >
                        <AssetTradeProblemPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'trades'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllTrades]}
                        redirectNoPermission
                      >
                        <TradePage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'problems'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllProblemTypes]}
                        redirectNoPermission
                      >
                        <ProblemPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'failures'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllRepairTypes]}
                        redirectNoPermission
                      >
                        <Failure />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path={'asset-type-to-failure'}
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllAssetTypesWithRepairs]}
                        redirectNoPermission
                      >
                        <AssetTypeToRepairPage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                <Route
                  path={`${url}/configuration/approval`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllApproval]}
                      redirectNoPermission
                    >
                      <ApprovalPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/configuration/general-settings`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllFlags]}
                      redirectNoPermission
                    >
                      <GeneralSettingsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route path="flags" element={<FlagsPage />} />
                  <Route path="categories" element={<CategoriesPage />} />
                  <Route path="priorities" element={<PrioritiesPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                </Route>
                <Route
                  path={`${url}/sp/configuration/general-settings`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllFlags]}
                      redirectNoPermission
                    >
                      <SPGeneralSettingsPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/configuration/profile`}
                  element={<CompanyProfileDetailsPage />}
                >
                  <Route
                    path="details"
                    element={<CompanyProfileDetailsPage />}
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="company"
                        row={companyDetails}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={<NoteTab moduleName="company" />}
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        row={companyDetails}
                        idAttributeName="id"
                        moduleName="company"
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/configuration/profile`}
                  element={<SPProfileDetailsPage />}
                >
                  <Route path="details" element={<SPProfileDetailsPage />} />
                  <Route
                    path="rates-and-terms"
                    element={<RatesAndTermsPage />}
                  />
                  <Route
                    path="trades-and-regions"
                    element={<ServiceProviderTradeAndRegionPage />}
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="company"
                        row={companyDetails}
                        idAttributeName="id"
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={<NoteTab moduleName="company" />}
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="company"
                        row={companyDetails}
                        idAttributeName="id"
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/configuration/accounting`}
                  element={<AccountingPage />}
                >
                  {/* <Route path="budget-settings" element={<AccountingPage />} /> */}

                  <Route
                    path="exports"
                    element={
                      <ECRequirePermission
                        scopes={[P.ViewLambdaExports]}
                        redirectNoPermission
                      >
                        <ExportsPage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                <Route
                  path={`${url}/configuration/location-management`}
                  element={<LocationManagerPage />}
                >
                  <Route path="location-filter" element={<WIPPage />} />
                  <Route
                    path="areas"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllBranchCategories]}
                        redirectNoPermission
                      >
                        <AreaPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="concepts"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllBranchCategories]}
                        redirectNoPermission
                      >
                        <ConceptsPage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                <Route
                  path={`${url}/configuration/troubleshooting`}
                  element={<TroubleshootingPage />}
                >
                  <Route
                    path="library"
                    element={
                      <ECRequirePermission scopes={[P.GetAllTroubleshoot]}>
                        <TroubleshootingTipsPage marginTop={false} />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="asset-problem-troubleshoot"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllAssetGroupProblemTroubleshoot]}
                        redirectNoPermission
                      >
                        <AssetProblemTroubleshootPage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                <Route
                  path={`${url}/service-request`}
                  element={<ServiceRequestPage />}
                />
                <Route
                  path={`${url}/rfps`}
                  element={<RequestForPricingPage />}
                />
                <Route
                  path={`${url}/rfp/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetRFP]}
                      redirectNoPermission
                    >
                      <RfpDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetRFP]}
                        redirectNoPermission
                      >
                        <RfpDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="rfp"
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                        scopes={[P.GetRFP, P.GetAttachment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab moduleName="rfp" scopes={[P.GetRFP]} />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/rfps`}
                  element={<ServiceProviderRequestForPricingPage />}
                />
                <Route
                  path={`${url}/sp/rfp/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetRFP]}
                      redirectNoPermission
                    >
                      <ServiceProviderRfpDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetRFP]}
                        redirectNoPermission
                      >
                        <ServiceProviderRfpDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="rfp"
                        isReadOnly
                        scopes={[P.GetRFP, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab moduleName="rfp" scopes={[P.GetRFP]} />
                    }
                  />
                </Route>
                <Route path={`${url}/sp/customers`} element={<CustomersPage />}>
                  <Route
                    path="customer-management"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllCustomers, P.SPMenu]}
                        redirectNoPermission
                      >
                        <SPCustomersManagementPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route path="pending" element={<CustomerPendingPage />} />
                  <Route path="locations" element={<WIPPage />} />
                  <Route path="managed-assets" element={<WIPPage />} />
                  <Route path="general-assets" element={<WIPPage />} />
                  <Route path="preventive-maintenance" element={<WIPPage />} />
                </Route>
                <Route
                  path={`${url}/sp/configuration/user-management`}
                  element={<UserManagementPage />}
                >
                  <Route path="users" element={<ServiceProviderUsersPage />} />
                  <Route
                    path="job-titles"
                    element={<ServiceProviderJobTitlesPage />}
                  />
                  <Route
                    path="notifications"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetUser, P.SPMenu]}
                        redirectNoPermission
                      >
                        <NotificationsSettingsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route path="permissions" element={<WIPPage />} />
                </Route>
                <Route path={`${url}/invoices/`} element={<InvoicesPage />} />
                <Route
                  path={`${url}/sp/invoice/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetInvoice, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderInvoicesDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInvoice, P.SPMenu]}
                        redirectNoPermission
                      >
                        <ServiceProviderInvoicesDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice, P.GetAttachment, P.SPMenu]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice, P.GetComment, P.SPMenu]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice, P.SPMenu]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/sp/invoices/`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllInvoices, P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderInvoicesPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/invoice/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetInvoice]}
                      redirectNoPermission
                    >
                      <InvoicesDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetInvoice]}
                        redirectNoPermission
                      >
                        <InvoicesDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice, P.GetAttachment]}
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="notes"
                    element={
                      <NoteTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice, P.GetComment]}
                      />
                    }
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab
                        moduleName="invoice"
                        scopes={[P.GetInvoice]}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/configuration/users`}
                  element={<UsersPage />}
                >
                  <Route
                    path="management"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllUsers]}
                        redirectNoPermission
                      >
                        <AdminUsersPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="job-titles"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllJobTitle]}
                        redirectNoPermission
                      >
                        <JobTitlesPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="notifications"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetUser]}
                        redirectNoPermission
                      >
                        <NotificationsSettingsPage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                <Route path={`${url}/search`} element={<GlobalSearchPage />} />
                <Route
                  path={`${url}/user/:id`}
                  element={<AdminUsersDetailsPage />}
                >
                  <Route path="details" element={<AdminUsersDetailsPage />} />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab
                        moduleName="user"
                        canAddAttachment={P.AddAttachment}
                        canEditAttachment={P.EditAttachment}
                      />
                    }
                  />
                  <Route
                    path="location-assignment"
                    element={<LocationAssignment />}
                  />
                  <Route path="notes" element={<NoteTab moduleName="user" />} />
                  <Route
                    path="activity"
                    element={<HistoryTab moduleName="user" />}
                  />
                </Route>
                <Route
                  path={`${url}/sp/user/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.SPMenu]}
                      redirectNoPermission
                    >
                      <ServiceProviderUsersDetailsPage />
                    </ECRequirePermission>
                  }
                >
                  <Route
                    path="details"
                    element={
                      <ECRequirePermission
                        scopes={[P.SPMenu]}
                        redirectNoPermission
                      >
                        <ServiceProviderUsersDetailsPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="attachments"
                    element={
                      <AttachmentTab moduleName="user" scopes={[P.SPMenu]} />
                    }
                  />
                  <Route
                    path="location-assignment"
                    element={<SPLocationAssignment scopes={[P.SPMenu]} />}
                  />
                  <Route
                    path="notes"
                    element={<NoteTab moduleName="user" scopes={[P.SPMenu]} />}
                  />
                  <Route
                    path="activity"
                    element={
                      <HistoryTab moduleName="user" scopes={[P.SPMenu]} />
                    }
                  />
                </Route>
                <Route
                  path={`${url}/impersonate`}
                  element={
                    <ECRequirePermission
                      scopes={[P.UserOverride]}
                      redirectNoPermission
                    >
                      <ImpersonatePage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/asset-verification`}
                  element={
                    <ECRequirePermission
                      scopes={[P.AssetVerification]}
                      redirectNoPermission
                    >
                      <AssetVerificationToolPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/avt-assets/:id`}
                  element={
                    <ECRequirePermission
                      scopes={[P.AssetVerification]}
                      redirectNoPermission
                    >
                      <LocationAssetsAvt />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/avt-assets/:id/details`}
                  element={
                    <ECRequirePermission
                      scopes={[P.AssetVerification]}
                      redirectNoPermission
                    >
                      <AssetDetailsAvtPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/notifications`}
                  element={
                    <ECRequirePermission
                      scopes={[P.SearchGenericNotification]}
                      redirectNoPermission
                    >
                      <NotificationsPage />
                    </ECRequirePermission>
                  }
                />
                {/* removed on DEV-13527 
                <Route path={`${url}/admin`} element={<AdminPage />}>
                  <Route
                    path="user-roles"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetUser]}
                        redirectNoPermission
                      >
                        <AdminUserRolesPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="admin-roles"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllRolesWithPermissions]}
                        redirectNoPermission
                      >
                        <AdminRolesPage />
                      </ECRequirePermission>
                    }
                  />
                  <Route
                    path="job-title-roles"
                    element={
                      <ECRequirePermission
                        scopes={[P.GetAllJobTitlesWithRoles]}
                        redirectNoPermission
                      >
                        <JobTitlesRolePage />
                      </ECRequirePermission>
                    }
                  />
                </Route>
                */}
                <Route element={<WelcomeToPanelPage />} />
                {/* unused for now */}
                <Route
                  path={`${url}/problem-asset`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetAllAssetTypesWithProblems]}
                      redirectNoPermission
                    >
                      <ProblemToAssetTypesPage />
                    </ECRequirePermission>
                  }
                />
                <Route
                  path={`${url}/dev-samples/tables`}
                  element={<TablesPage />}
                />
                <Route
                  path={`${url}/dev-samples/forms`}
                  element={<FormsPage />}
                />
                <Route
                  path={`${url}/dispatch`}
                  element={
                    <ECRequirePermission
                      scopes={[P.GetDispatch]}
                      companyConfigScopes={[
                        CompanyConfigurationFields.EnableDispatch,
                      ]}
                      redirectNoPermission
                    >
                      <DispatchTabsPage />
                    </ECRequirePermission>
                  }
                />
              </Routes>
            </ECMainBox>
          </ECBgBox>
        </PanelDrawerCollapsibleItemsProvider>
      </CreateServiceRequestProvider>
    </>
  );
}
