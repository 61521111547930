import {
  useLazyGetInvoiceListQuery,
  useLazyGetInvoiceByIdQuery,
} from 'services/invoiceApi';
import { ECButton, ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { MonetizationOn } from '@mui/icons-material';

export function InvoicesSearch({ mustTriggerListQuery }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const match = useMatch('/panel/*');

  const handleOpenDetails = (row: any) => {
    const url = match ? match.pathname.replace('search', row.moduleName) : '';

    return row.moduleName && row.id ? navigate(`${url}/${row.id}`) : undefined;
  };

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:panelDrawer.invoices')}
      useLazyGetListQuery={useLazyGetInvoiceListQuery}
      useLazyGetByIdQuery={useLazyGetInvoiceByIdQuery}
      useCreateMutation={{}}
      moduleName="invoice"
      mustTriggerListQuery={mustTriggerListQuery}
      usingGlobalSearch={true}
      withDrawer={false}
      marginTop={false}
      enableExport
      downloadFileName="invoices.csv"
      onRowClick={handleOpenDetails}
      ignoreHyperlink={true}
      moduleRedirectElement={
        <ECButton
          sx={{ marginBottom: '2rem' }}
          variant="contained"
          onClick={() => navigate(`${match?.pathnameBase}/invoices`)}
          startIcon={<MonetizationOn />}
        >
          Invoices
        </ECButton>
      }
      shouldNotUseActiveFilter
    />
  );
}
