import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const drawerWidth = 500;
export const ECDrawerEdit = styled(Drawer)<DrawerProps>(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: 'none',
  },
}));
