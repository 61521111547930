import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ButtonGroup } from '@mui/material';
import { ECTextField } from '../ECTextField';
import { ECStack } from '../ECStack';
import { ECGrid } from '../ECGrid';
import { ECRadio, ECRadioGroup } from '../ECRadio';
import { ECFormControlLabel } from '../ECForm';
import { ECButton } from '../ECButton';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECMenuItem, ECSelect } from '../ECSelect';

export enum SelectionType {
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export interface ServiceFrequencyValueType {
  daysBetweenService: number | null | undefined;
  serviceMonths: string[] | null;
  dayOfMonth: number | null;
  type?: SelectionType;
}

interface ServiceFrequencyProps {
  onSelectedValuesChange?: (values: ServiceFrequencyValueType) => void;
  dayOfMonth?: number;
  dayBetweenService?: number;
  serviceMonths?: string[] | null;
  value?: ServiceFrequencyValueType | null;
  resetState?: boolean;
}

export const MONTH_DAYS = {
  Jan: { days: 31, value: 1 },
  Feb: { days: 28, value: 2 },
  Mar: { days: 31, value: 3 },
  Apr: { days: 30, value: 4 },
  May: { days: 31, value: 5 },
  Jun: { days: 30, value: 6 },
  Jul: { days: 31, value: 7 },
  Aug: { days: 31, value: 8 },
  Sep: { days: 30, value: 9 },
  Oct: { days: 31, value: 10 },
  Nov: { days: 30, value: 11 },
  Dec: { days: 31, value: 12 },
};

export const ECServiceFrequency: React.FC<ServiceFrequencyProps> = ({
  onSelectedValuesChange,
  dayOfMonth,
  dayBetweenService,
  serviceMonths,
  resetState,
}) => {
  const monthList = Object.keys(MONTH_DAYS).map(key => ({
    label: key,
    value: MONTH_DAYS[key].value,
  }));

  const getMinDays = selectedMonths => {
    if (
      !selectedMonths ||
      !Array.isArray(selectedMonths) ||
      selectedMonths.length === 0
    ) {
      return 31;
    }

    const dayCounts = selectedMonths.map(month => {
      const monthKey = Object.keys(MONTH_DAYS).find(
        key => MONTH_DAYS[key].value === month,
      );
      return monthKey ? MONTH_DAYS[monthKey].days : 31;
    });

    return Math.min(...dayCounts);
  };

  const [selection, setSelection] = useState<SelectionType>(
    dayBetweenService ? SelectionType.DAY : SelectionType.MONTH,
  );

  const [selectedMonths, setSelectedMonths] = useState<
    string[] | null | undefined
  >(serviceMonths);

  const [daysOfMonth, setDaysOfMonth] = useState<number | null | undefined>(
    dayOfMonth,
  );

  const [daysBetweenService, setDaysBetweenService] = useState<
    number | null | undefined
  >(dayBetweenService);

  const toggleMonth = useCallback(monthValue => {
    setSelectedMonths(prev => {
      let updatedMonths;
      if (prev) {
        updatedMonths = prev.includes(monthValue)
          ? prev.filter(m => m !== monthValue)
          : [...prev, monthValue];
      } else {
        updatedMonths = [monthValue];
      }
      return updatedMonths.sort((a, b) => a - b);
    });
  }, []);

  const maxDaysForSelectedMonths = useMemo(() => {
    if (selectedMonths?.length) {
      return getMinDays(selectedMonths);
    }
    return 1;
  }, [selectedMonths]);

  const renderDaysOfMonthMenuItems = useMemo(() => {
    return Array.from({ length: maxDaysForSelectedMonths || 31 }, (_, day) => (
      <ECMenuItem key={day + 1} value={day + 1}>
        {day + 1}
      </ECMenuItem>
    ));
  }, [maxDaysForSelectedMonths]);

  const renderDayBetweenMenuItems = useMemo(() => {
    return Array.from({ length: 366 }, (_, day) => (
      <ECMenuItem key={day + 1} value={day + 1}>
        {day + 1}
      </ECMenuItem>
    ));
  }, []);

  useEffect(() => {
    if (selection === SelectionType.MONTH && selectedMonths) {
      const minDay = getMinDays(selectedMonths);
      if (daysOfMonth && daysOfMonth > minDay) {
        setDaysOfMonth(minDay);
      } else if (!daysOfMonth) {
        setDaysOfMonth(1);
      }
    } else if (!dayBetweenService) {
      setDaysBetweenService(1);
    }

    onSelectedValuesChange?.({
      daysBetweenService: dayBetweenService,
      serviceMonths: serviceMonths || null,
      dayOfMonth: dayOfMonth || null,
      type: selection,
    });
  }, [selection, selectedMonths]);

  useEffect(() => {
    if (typeof onSelectedValuesChange === 'function') {
      if (selection === SelectionType.MONTH) {
        if (daysOfMonth && selectedMonths) {
          onSelectedValuesChange({
            daysBetweenService: null,
            serviceMonths: selectedMonths,
            dayOfMonth: daysOfMonth,
            type: selection,
          });
        }
      } else {
        if (daysBetweenService) {
          onSelectedValuesChange({
            daysBetweenService: daysBetweenService,
            serviceMonths: null,
            dayOfMonth: null,
            type: selection,
          });
        }
      }
    }
  }, [
    selection,
    selectedMonths,
    daysOfMonth,
    daysBetweenService,
    onSelectedValuesChange,
  ]);

  useEffect(() => {
    if (resetState) {
      setSelection(SelectionType.MONTH);
      setSelectedMonths(null);
      setDaysOfMonth(null);
      setDaysBetweenService(null);
    }
  }, [resetState]);

  return (
    <ECRadioGroup
      onChange={e => setSelection(e.target.value as SelectionType)}
      row
    >
      <ECGrid container alignItems="center">
        <ECBox style={{ width: '100%' }}>
          <ECTypography
            variant="subtitle2"
            minWidth="15vw"
            width="15vw"
            maxWidth="20vw"
            color={theme => theme.palette.text.secondary}
          >
            Service by Month
          </ECTypography>
        </ECBox>
        <ECStack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
        >
          <ECBox
            style={{ width: '60%', display: 'flex', alignItems: 'center' }}
          >
            <ECGrid item style={{ marginRight: '1rem' }}>
              <ECFormControlLabel
                value="MONTH"
                control={
                  <ECRadio checked={selection === SelectionType.MONTH} />
                }
                label=""
              />
            </ECGrid>
            <ECGrid
              item
              style={{
                opacity: selection === SelectionType.MONTH ? 1 : 0.5,
                pointerEvents:
                  selection === SelectionType.MONTH ? 'all' : 'none',
              }}
              sx={{ marginLeft: -3 }}
            >
              <ButtonGroup>
                <ECGrid container direction="column">
                  <ECGrid item>
                    {monthList?.slice(0, 6)?.map(month => (
                      <ECButton
                        key={month.label}
                        disabled={selection !== SelectionType.MONTH}
                        sx={{
                          minWidth: '4rem !important',
                          borderRadius: '0 !important',
                        }}
                        variant={
                          selectedMonths?.includes(month.value)
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => toggleMonth(month.value)}
                      >
                        {month.label}
                      </ECButton>
                    ))}
                  </ECGrid>
                  <ECGrid item>
                    {monthList?.slice(6)?.map(month => (
                      <ECButton
                        key={month.label}
                        disabled={selection !== SelectionType.MONTH}
                        sx={{
                          minWidth: '4rem !important',
                          borderRadius: '0 !important',
                        }}
                        variant={
                          selectedMonths?.includes(month.value)
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => toggleMonth(month.value)}
                      >
                        {month.label}
                      </ECButton>
                    ))}
                  </ECGrid>
                </ECGrid>
              </ButtonGroup>
            </ECGrid>
          </ECBox>
          <ECBox style={{ width: '40%' }}>
            <ECGrid
              item
              style={{
                opacity: selection === SelectionType.MONTH ? 1 : 0.5,
                pointerEvents:
                  selection === SelectionType.MONTH ? 'all' : 'none',
              }}
            >
              <ECStack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
              >
                <ECTypography
                  variant="subtitle2"
                  color={theme => theme.palette.text.secondary}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Date of Month
                </ECTypography>
                <ECSelect
                  value={maxDaysForSelectedMonths > 1 ? daysOfMonth || 1 : ''}
                  onChange={event => setDaysOfMonth(Number(event.target.value))}
                  variant="filled"
                  disabled={selection !== SelectionType.MONTH}
                  sx={{ width: '100%' }}
                >
                  {renderDaysOfMonthMenuItems}
                </ECSelect>
              </ECStack>
            </ECGrid>
          </ECBox>
        </ECStack>
        <ECBox style={{ width: '100%' }}>
          <ECTypography
            variant="subtitle2"
            minWidth="15vw"
            width="15vw"
            maxWidth="20vw"
            color={theme => theme.palette.text.secondary}
          >
            OR
          </ECTypography>
        </ECBox>

        <ECStack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', marginBottom: '2rem' }}
        >
          <ECBox style={{ width: '60%' }}>
            <ECGrid item>
              <ECFormControlLabel
                value="DAY"
                control={<ECRadio checked={selection === SelectionType.DAY} />}
                label="Service by Day"
              />
            </ECGrid>
          </ECBox>
          <ECBox style={{ width: '50%' }}>
            <ECGrid
              item
              style={{
                opacity: selection === SelectionType.DAY ? 1 : 0.5,
                pointerEvents: selection === SelectionType.DAY ? 'all' : 'none',
              }}
            >
              <ECStack direction="row" spacing={2} alignItems="center">
                <ECTypography
                  variant="subtitle2"
                  color={theme => theme.palette.text.secondary}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Day Between Service
                </ECTypography>
                <ECSelect
                  value={daysBetweenService || ''}
                  onChange={e => {
                    setDaysBetweenService(Number(e.target.value));
                  }}
                  variant="filled"
                  disabled={selection !== SelectionType.DAY}
                  sx={{ width: '100%' }}
                >
                  {renderDayBetweenMenuItems}
                </ECSelect>
              </ECStack>
            </ECGrid>
          </ECBox>
        </ECStack>
      </ECGrid>
    </ECRadioGroup>
  );
};
