import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import {
  ECMainTabBox,
  ECGrid,
  ECPanelBreadcrumbs,
  ECEasyTable,
} from 'app/components';

import { ECTabContainer } from '../../components/ECTabs';

const fancyMockData = require('./mock_data_fancy.json');
const simpleMockData = require('./mock_data_simple.json');
const miniMockData = require('./mock_data_simple_mini.json');

const fancyMockData2 = require('./mock_data_fancy_2.json');
const flatMockData2 = require('./mock_data_flat_2.json');

function EasyTable2Flat() {
  return (
    <ECGrid item xs={12}>
      <ECEasyTable
        config={flatMockData2.data.config}
        data={flatMockData2.data.content}
        isLoading={false}
        isSendingRequest={false}
        onRowPress={row =>
          console.log('onRowPress', JSON.stringify(row, null, 2))
        }
        onChipClick={(data, chipData, col) => {
          console.log(
            'onChipClick',
            JSON.stringify(
              {
                data,
                chipData,
                col,
              },
              null,
              2,
            ),
          );
        }}
        onChipDelete={(data, chipData, col) => {
          console.log(
            'onChipDelete',
            JSON.stringify(
              {
                data,
                chipData,
                col,
              },
              null,
              2,
            ),
          );
        }}
        onSwitchChange={(newValue, row, col) => {
          console.log(
            'onSwitchChange',
            JSON.stringify(
              {
                newValue,
                row,
                col,
              },
              null,
              2,
            ),
          );
        }}
      />
    </ECGrid>
  );
}

function EasyTable2() {
  return (
    <ECGrid item xs={12}>
      <ECEasyTable
        config={fancyMockData2.data.config}
        data={fancyMockData2.data.content}
        isLoading={false}
        isSendingRequest={false}
        onRowPress={row =>
          console.log('onRowPress', JSON.stringify(row, null, 2))
        }
      />
    </ECGrid>
  );
}

function EasyTransferList() {
  return (
    <ECGrid item xs={12}>
      {/*<ECEasyTransferList*/}
      {/*  leftItems={transferListMockData.data.leftItems}*/}
      {/*  rightItems={transferListMockData.data.rightItems}*/}
      {/*  dataPath={transferListMockData.data.dataPath}*/}
      {/*  onLeftToRight={items => {*/}
      {/*    console.log('onLeftToRight', JSON.stringify(items, null, 2));*/}
      {/*  }}*/}
      {/*  onRightToLeft={items => {*/}
      {/*    console.log('onRightToLeft', JSON.stringify(items, null, 2));*/}
      {/*  }}*/}
      {/*  isLoading={false}*/}
      {/*  leftTitle={transferListMockData.data.leftTitle}*/}
      {/*  rightTitle={transferListMockData.data.rightTitle}*/}
      {/*/>*/}
    </ECGrid>
  );
}

function FancyTables() {
  return (
    <ECGrid item xs={12}>
      <ECEasyTable
        config={fancyMockData.data.config}
        data={fancyMockData.data.content}
        isLoading={false}
        isSendingRequest={false}
        onRowPress={row =>
          console.log('onRowPress', JSON.stringify(row, null, 2))
        }
      />
    </ECGrid>
  );
}

function SimpleTables() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={9}>
        <ECEasyTable
          config={simpleMockData.data.config}
          data={simpleMockData.data.content}
          isLoading={false}
          isSendingRequest={false}
          onRowPress={row =>
            console.log('onRowPress', JSON.stringify(row, null, 2))
          }
        />
      </ECGrid>
      <ECGrid item xs={3}>
        <ECEasyTable
          config={miniMockData.data.config}
          data={miniMockData.data.content}
          isLoading={false}
          isSendingRequest={false}
          onRowPress={row =>
            console.log('onRowPress', JSON.stringify(row, null, 2))
          }
        />
      </ECGrid>
    </ECGrid>
  );
}

export function TablesPage() {
  return (
    <>
      <Helmet>
        <title>Tables Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainTabBox component={'main'}>
        <ECGrid container sx={{ padding: 2.3 }}>
          <ECGrid item xs={12}>
            <ECPanelBreadcrumbs />
          </ECGrid>
          <ECGrid item xs={12}>
            <ECTabContainer
              tabs={[
                {
                  value: '-1',
                  label: 'EasyTable 2 Flat',
                  content: <EasyTable2Flat />,
                },
                { value: '0', label: 'EasyTable 2', content: <EasyTable2 /> },
                // {
                //   value: '1',
                //   label: 'Transfer List',
                //   content: <EasyTransferList />,
                // },
                { value: '2', label: 'Fancy Tables', content: <FancyTables /> },
                {
                  value: '3',
                  label: 'Simple Tables',
                  content: <SimpleTables />,
                },
              ]}
            />
          </ECGrid>
        </ECGrid>
      </ECMainTabBox>
    </>
  );
}
