import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect } from 'react';
import { useRejectRfpProposalMutation } from 'services/proposalApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { moduleApi } from 'services/moduleApi';
import { requestForPricingApi } from 'services/requestForPricingApi';
import { ECModal } from 'app/components/ECModal';

interface RejectRfpProposalModalProps {
  proposalId: number;
  serviceProviderName: string;
  onCloseModal?: () => void;
  onSuccess?: () => void;
  isOpen?: boolean;
}

const fancyFormRejectProposal = require('./fancy_form_config_reject.json');

export function RejectRfpProposalModal({
  proposalId,
  onCloseModal,
  onSuccess,
  serviceProviderName,
  isOpen,
}: RejectRfpProposalModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (proposalId) {
      const addCommentField = fancyFormRejectProposal.fields.find(
        f => f.fieldName === 'rejectReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = proposalId;
      }
    }
  }, [proposalId, fancyFormRejectProposal]);

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useRejectRfpProposalMutation();

  const handleRejectProposal = useCallback(
    (formData, fieldData) => {
      const rejectReasonField = fieldData.find(
        data => data.fieldName === 'rejectReason',
      );
      const rejectReason = rejectReasonField?.comment;

      const recipientIds = rejectReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );

      doUpdateStatus({
        id: proposalId,
        declineReason: rejectReason,
        recipientIds,
      });
    },
    [doUpdateStatus, proposalId],
  );

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(requestForPricingApi.util.invalidateTags(['RfpItem']));
      dispatch(requestForPricingApi.util.invalidateTags(['RfpItemProposals']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal successfully Rejected',
        }),
      );
      onCloseModal?.();
      onSuccess?.();
    }
  }, [isSuccessUpdateAction]);

  return (
    <ECModal isOpen={isOpen} onClose={() => onCloseModal?.()} noPadding>
      <ECEasyForm
        pattern="modal"
        config={fancyFormRejectProposal.config}
        fields={fancyFormRejectProposal.fields}
        isSendingData={isLoadingActions}
        isLoading={isLoadingActions}
        isLoadingForm={false}
        onFormSubmit={handleRejectProposal}
        saveButtonColor="error"
        customDrawerTitle={`Confirm you want to Reject Proposal #${proposalId} from ${serviceProviderName}`}
        additionalActions={
          <ECButton type="button" variant="text" onClick={onCloseModal}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    </ECModal>
  );
}
