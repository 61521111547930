import { QueryParams } from 'types/QueryParams';
import {
  RequestForPricing,
  RequestForPricingCreate,
  RequestForPricingItem,
  RequestForPricingUpdate,
} from 'types/RequestForPricing';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';
import { WorkflowNextStatus, WorkflowStatusUpdate } from 'types/WorkflowStatus';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'RequestForPricing',
    'RfpItem',
    'RfpItemProposals',
    'RFPStatusList',
  ],
});

export const requestForPricingApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getRequestForPricingList: builder.query<
      BaseType<RequestForPricing[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'rfp',
          params,
        };
      },
      transformResponse: (response: BaseType<RequestForPricing[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          hyperLinkModuleName: !!(row as any)?.request?.asset?.generalAsset?.id
            ? 'general-asset'
            : 'asset',
          name:
            row.name.length > 30
              ? `${row.name?.substring(0, 30)}...`
              : row.name,
        }));
        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['RequestForPricing'],
      keepUnusedDataFor: 180,
    }),

    getRfpItemById: builder.query<RequestForPricingItem, number>({
      query: id => {
        return {
          url: `rfp/${id}`,
        };
      },
      providesTags: ['RfpItem'],
    }),

    getSPRfpItemById: builder.query<RequestForPricingItem, number>({
      query: id => {
        return {
          url: `sp/rfp/${id}`,
        };
      },
      providesTags: ['RfpItem'],
    }),

    createRequestForPricing: builder.mutation<void, RequestForPricingCreate>({
      query: body => {
        const formData = new FormData();
        if (body?.branchId) {
          formData.append('branchId', body.branchId?.toString());
        }
        if (body?.assetId) {
          formData.append('assetId', body.assetId?.toString());
        }
        if (body?.name) {
          formData.append('name', body.name?.toString());
        }
        if (body?.problemTypeId) {
          formData.append('problemTypeId', body.problemTypeId?.toString());
        }
        if (body?.priorityId) {
          formData.append('priorityId', body.priorityId?.toString());
        }
        if (body?.categoryTypeId) {
          formData.append('categoryTypeId', body.categoryTypeId?.toString());
        }
        if (body?.requestedBy) {
          formData.append('requestedBy', body.requestedBy?.toString());
        }
        if (body?.description) {
          formData.append('description', body?.description);
        }
        if (body?.isGeneralAsset) {
          formData.append('isGeneralAsset', body.isGeneralAsset.toString());
        }
        if (body?.generalAssetId) {
          formData.append('generalAssetId', body.generalAssetId.toString());
        }

        body?.spCompanyIds?.forEach((spCompanyIds, index) => {
          formData.append(`spCompanyIds[${index}]`, spCompanyIds.id.toString());
        });
        body.files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          }
        });

        return {
          url: 'rfp',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['RequestForPricing'],
    }),

    updateRequestForPricing: builder.mutation<void, RequestForPricingUpdate>({
      query: body => {
        return {
          url: `rfp/${body.id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['RequestForPricing', 'RfpItem'],
    }),

    getRequestForPricingForSPList: builder.query<
      BaseType<RequestForPricing[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'sp/rfp',
          params,
        };
      },
      transformResponse: (response: BaseType<RequestForPricing[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          hyperLinkModuleName: !!(row as any)?.request?.asset?.generalAsset?.id
            ? 'general-asset'
            : 'asset',
          name:
            row.name.length > 30
              ? `${row.name?.substring(0, 30)}...`
              : row.name,
        }));
        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['RequestForPricing'],
      keepUnusedDataFor: 180,
    }),
    getProposalsListByRfpId: builder.query<
      BaseType<any[]>,
      { id: number; queryParams: QueryParams }
    >({
      query: ({ id, queryParams }) => {
        return {
          url: `rfp/${id}/proposals`,
          queryParams,
        };
      },
      providesTags: ['RfpItemProposals'],
      keepUnusedDataFor: 180,
    }),
    getRFPNextStatusList: builder.query<WorkflowNextStatus[], number>({
      query: id => {
        return {
          url: `rfp/${id}/status/next`,
        };
      },
      providesTags: ['RFPStatusList'],
    }),
    updateRfpStatus: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({ id, statusTo, actionId, declineReason, recipientIds }) => {
        return {
          url: `rfp/${id}/status/change`,
          method: 'put',
          body: {
            statusTo,
            actionId,
            declineReason,
            recipientIds,
          },
        };
      },
      invalidatesTags: ['RfpItemProposals', 'RFPStatusList', 'RfpItem'],
    }),
  }),
});

export const {
  useGetRequestForPricingListQuery,
  useLazyGetRequestForPricingListQuery,
  useGetRfpItemByIdQuery,
  useGetSPRfpItemByIdQuery,
  useCreateRequestForPricingMutation,
  useUpdateRequestForPricingMutation,
  useGetRequestForPricingForSPListQuery,
  useLazyGetRequestForPricingForSPListQuery,
  useGetProposalsListByRfpIdQuery,
  useLazyGetProposalsListByRfpIdQuery,
  useGetRFPNextStatusListQuery,
  useLazyGetRFPNextStatusListQuery,
  useUpdateRfpStatusMutation,
} = requestForPricingApi;
