import React from 'react';
import { Group, MarkEmailRead, Search } from '@mui/icons-material';
import { BoxProps } from '@mui/material';
import { ECStepsDescription } from '../ECStepsDescription';
import { useTranslation } from 'react-i18next';

export interface ECSPHowItWorksProps extends BoxProps {
  onClose?: () => void;
}

export const ECSPHowItWorks = ({
  onClose,
  ...boxProps
}: ECSPHowItWorksProps) => {
  const { t } = useTranslation();

  const steps = [
    {
      text: t('translation:spHowItWorks.step1'),
      icon: <Search sx={{ fontSize: '60px' }} />,
    },
    {
      text: t('translation:spHowItWorks.step2'),
      icon: <MarkEmailRead sx={{ fontSize: '60px' }} />,
    },
    {
      text: t('translation:spHowItWorks.step3'),
      icon: <Group sx={{ fontSize: '60px' }} />,
    },
  ];

  return (
    <ECStepsDescription
      title={t('translation:spHowItWorks.title')}
      steps={steps}
      onClose={onClose}
      {...boxProps}
    />
  );
};
