import { Download, Upload } from '@mui/icons-material';
import { ECBox, ECIconButton, ECTypography, ECButton } from '..';
import Alert from '@mui/material/Alert';
import { AttachmentDocumentType } from 'types/Attachment';
import { useRef } from 'react';

export interface ECRequiredDocumentProps {
  attachmentInfo: AttachmentDocumentType;
  handleDownloadExampleAttachment: (fileName: string, id: number) => void;
  isCustomer?: boolean;
  handleUploadAttachment?: (e: any, docType: number) => void;
}

export const ECRequiredDocument = ({
  attachmentInfo,
  handleDownloadExampleAttachment,
  isCustomer,
  handleUploadAttachment,
}: ECRequiredDocumentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleBrowseFileClick = () => {
    inputRef?.current?.click();
  };
  const handleFileChange = (e: any) => {
    handleUploadAttachment?.(
      e,
      attachmentInfo.documentCoreTypeId ||
        attachmentInfo.attachment?.documentCoreType?.id ||
        0,
    );
  };
  return (
    <ECBox display={'flex'} mb={2} alignItems={'center'}>
      <ECBox
        border={1}
        borderRadius={1}
        sx={theme => ({
          width: 650,
          textAlign: 'center',
          alignItems: 'center',
          borderColor: !attachmentInfo.isUploaded
            ? theme.palette.warning.dark
            : attachmentInfo.isExpired
              ? theme.palette.error.dark
              : theme.palette.success.dark,
        })}
        display={'flex'}
      >
        <Alert
          severity={
            !attachmentInfo.isUploaded
              ? 'warning'
              : attachmentInfo.isExpired
                ? 'error'
                : 'success'
          }
          style={{
            width: '100%',
            alignItems: 'center',
            backgroundColor: 'transparent',
          }}
        >
          <ECBox
            display={'flex'}
            sx={{
              alignItems: 'center',
              height: '30px',
              justifyContent: 'space-between',
              width: 550,
            }}
          >
            <ECBox display={'flex'} sx={{ alignItems: 'center' }}>
              <ECTypography variant="body2" margin={'0 5px'} fontWeight={500}>
                {attachmentInfo.attachment?.documentCoreType?.name ||
                  attachmentInfo.description ||
                  attachmentInfo.name}
              </ECTypography>
              <ECTypography variant="body2" fontWeight={'bold'}>
                {!attachmentInfo.isUploaded
                  ? '(Not Uploaded)'
                  : attachmentInfo.isExpired
                    ? '(Expired)'
                    : ''}
              </ECTypography>
            </ECBox>
            {attachmentInfo.attachment &&
              attachmentInfo.attachment.id &&
              !isCustomer && (
                <>
                  <ECBox
                    display={'flex'}
                    sx={{ alignItems: 'center', height: '30px' }}
                  >
                    <ECTypography
                      variant="body2"
                      marginRight={'10px'}
                      fontWeight={'bold'}
                    >
                      DOWNLOAD EXAMPLE
                    </ECTypography>

                    <ECIconButton
                      size="medium"
                      onClick={() =>
                        handleDownloadExampleAttachment(
                          attachmentInfo?.attachment?.originalName || '',
                          attachmentInfo?.attachment?.id || 0,
                        )
                      }
                    >
                      <Download
                        sx={theme => ({ color: theme.palette.grey[600] })}
                      />
                    </ECIconButton>
                  </ECBox>
                </>
              )}
          </ECBox>
        </Alert>
      </ECBox>
      {!isCustomer && !attachmentInfo.isUploaded && (
        <ECBox>
          <ECButton
            variant="contained"
            component="label"
            startIcon={<Upload />}
            sx={{
              marginLeft: '10px',
              height: '46px',
            }}
            size="large"
            onClick={handleBrowseFileClick}
          >
            Upload
            <input
              ref={inputRef}
              type="file"
              id={`${attachmentInfo.id}`}
              name={`${attachmentInfo.id}${attachmentInfo.name}`}
              onChange={handleFileChange}
              hidden
            />
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  );
};
