import { ECBox } from '../ECBox';
import { ECDivider } from '../ECDivider';
import { ECGrid } from '../ECGrid';
import { ECTypography } from '../ECTypography';
import { WorkOrderCard } from 'types/WorkOrders';
import { ECWorkflowStatusBadge } from '../ECWorkflowStatusBadge';
import { ECChipListTableCell } from '../ECTable/ECChipTableCell';
import { formatDateForTable } from 'utils/strings/formatDate';
import _ from 'lodash';

interface ECAssetWorkOrderCardProps {
  workOrder: WorkOrderCard;
}

export const ECAssetWorkOrderCard = ({
  workOrder,
}: ECAssetWorkOrderCardProps) => {
  const renderAttribute = (name: string, value?: string) => {
    if (!value) {
      return;
    }

    return (
      <ECBox display="flex" my={1}>
        <ECTypography fontWeight="bold">{name}:&nbsp;</ECTypography>

        <ECTypography variant="body1">{value}</ECTypography>
      </ECBox>
    );
  };

  if (!workOrder) {
    return <></>;
  }

  return (
    <ECBox p={1} key={workOrder.id}>
      <ECBox
        display="flex"
        borderColor={theme => theme.palette.other.divider}
        boxShadow={2}
        p={1}
        border="1px"
        borderRadius={2}
        bgcolor={theme => theme.palette.common.white}
      >
        <ECBox display="flex" flexDirection="column" width="100%">
          <ECTypography ml={2} variant="h6">
            WO ID #{workOrder.id}
          </ECTypography>
          <ECDivider />

          <ECBox display="flex" px={2} py={1}>
            <ECBox display="flex" flexDirection="column" flex={1}>
              {renderAttribute(
                'ETA',
                formatDateForTable(
                  (_.isNil(workOrder.eta)
                    ? workOrder.originalEta
                    : workOrder.eta) ?? '',
                ),
              )}
              {renderAttribute('NTE', `$${workOrder.nte?.toFixed(2)}`)}
              {renderAttribute('Age of Work Order', workOrder.ageOfWorkOrder)}
              {renderAttribute('Problem', workOrder.request?.problem?.name)}
            </ECBox>

            <ECBox display="flex" flexDirection="column" flex={1}>
              {workOrder.workflowStatus?.name && (
                <ECBox mt={1}>
                  <ECWorkflowStatusBadge
                    status={workOrder.workflowStatus?.name}
                  />
                </ECBox>
              )}
              {renderAttribute(
                'Service Provider',
                workOrder.serviceProvider?.name || 'Unassigned',
              )}
              {renderAttribute('Category', workOrder.request?.category?.name)}
              {!!workOrder?.flags?.length && (
                <ECBox>
                  <ECChipListTableCell
                    col={{ dataPath: 'flags', chipTitleDataPath: 'label' }}
                    customWidthCollapseChips={150}
                    row={workOrder}
                    renderAsTableCell={false}
                  />
                </ECBox>
              )}
            </ECBox>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
