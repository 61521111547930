export function formatDecimal(value) {
  if (!value) return '';
  let sanitizedValue = `${value}`.replace(/[^0-9.,]/g, '');
  if (sanitizedValue.includes('.')) {
    const [integerPart, decimalPart] = sanitizedValue.split('.');
    const formattedIntegerPart = `${Number(integerPart.replaceAll(',', ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const formattedDecimalPart = decimalPart
      ? `.${decimalPart.replaceAll(',', '').substring(0, 2)}`
      : '.';
    sanitizedValue = `${formattedIntegerPart}${formattedDecimalPart}`;
  } else {
    sanitizedValue = `${Number(sanitizedValue.replaceAll(',', ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  if (sanitizedValue.charAt(0) === '.') sanitizedValue = '0' + sanitizedValue;
  if (sanitizedValue.length > 2 && sanitizedValue.charAt(0) === '0') {
    sanitizedValue = '0' + sanitizedValue;
  }
  return sanitizedValue;
}

export function formatClearedDecimal(value, allowDecimal = true) {
  if (!value) return '';

  // Remove all non-numeric characters except the decimal point
  let sanitizedValue = `${value}`.replace(/[^0-9.]/g, '');

  if (allowDecimal && sanitizedValue.includes('.')) {
    const [integerPart, decimalPart] = sanitizedValue.split('.');
    sanitizedValue = `${Number(integerPart)}${
      decimalPart ? `.${decimalPart.substring(0, 2)}` : '.'
    }`;
  } else {
    // Remove any decimal part if allowDecimal is false
    sanitizedValue = `${Number(sanitizedValue.split('.')[0])}`;
  }

  // Ensure the value starts with '0' if it begins with a decimal point
  if (sanitizedValue.charAt(0) === '.') sanitizedValue = '0' + sanitizedValue;

  return sanitizedValue;
}
