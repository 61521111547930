export const USStates = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const PuertoRicoStates = [
  {
    label: 'San Juan',
    value: 'San Juan',
  },
];

export const CanadaStates = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Nunavut',
    value: 'NU',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon Territory',
    value: 'YT',
  },
];

export const MexicoStates = [
  {
    label: 'Distrito Federal',
    value: 'CDMX',
    subdivision: 'federal district',
  },
  {
    label: 'Aguascalientes',
    value: 'Ags.',
    subdivision: 'state',
  },
  {
    label: 'Baja California',
    value: 'B.C.',
    subdivision: 'state',
  },
  {
    label: 'Baja California Sur',
    value: 'BCS',
    subdivision: 'state',
  },
  {
    label: 'Campeche',
    value: 'Camp.',
    subdivision: 'state',
  },
  {
    label: 'Chiapas',
    value: 'Chis.',
    subdivision: 'state',
  },
  {
    label: 'Chihuahua',
    value: 'Chih.',
    subdivision: 'state',
  },
  {
    label: 'Coahuila',
    value: 'Coah.',
    subdivision: 'state',
  },
  {
    label: 'Colima',
    value: 'Col.',
    subdivision: 'state',
  },
  {
    label: 'Durango',
    value: 'Dgo.',
    subdivision: 'state',
  },
  {
    label: 'Guanajuato',
    value: 'Gto.',
    subdivision: 'state',
  },
  {
    label: 'Guerrero',
    value: 'Gro.',
    subdivision: 'state',
  },
  {
    label: 'Hidalgo',
    value: 'Hgo.',
    subdivision: 'state',
  },
  {
    label: 'Jalisco',
    value: 'Jal.',
    subdivision: 'state',
  },
  {
    label: 'Michoacán',
    value: 'Mich.',
    subdivision: 'state',
  },
  {
    label: 'Morelos',
    value: 'Mor.',
    subdivision: 'state',
  },
  {
    label: 'Nayarit',
    value: 'Nay.',
    subdivision: 'state',
  },
  {
    label: 'Nuevo León',
    value: 'N.L.',
    subdivision: 'state',
  },
  {
    label: 'Oaxaca',
    value: 'Oax.',
    subdivision: 'state',
  },
  {
    label: 'Puebla',
    value: 'Pue.',
    subdivision: 'state',
  },
  {
    label: 'Querétaro',
    value: 'Qro.',
    subdivision: 'state',
  },
  {
    label: 'Quintana Roo',
    value: 'Q.R.',
    subdivision: 'state',
  },
  {
    label: 'San Luis Potosí',
    value: 'S.L.P.',
    subdivision: 'state',
  },
  {
    label: 'Sinaloa',
    value: 'Sin.',
    subdivision: 'state',
  },
  {
    label: 'Sonora',
    value: 'Son.',
    subdivision: 'state',
  },
  {
    label: 'Tabasco',
    value: 'Tab.',
    subdivision: 'state',
  },
  {
    label: 'Tamaulipas',
    value: 'Tamps.',
    subdivision: 'state',
  },
  {
    label: 'Tlaxcala',
    value: 'Tlax.',
    subdivision: 'state',
  },
  {
    label: 'Veracruz',
    value: 'Ver.',
    subdivision: 'state',
  },
  {
    label: 'Yucatán',
    value: 'Yuc.',
    subdivision: 'state',
  },
  {
    label: 'Zacatecas',
    value: 'Zac.',
    subdivision: 'state',
  },
];

export const JamaicaStates = [
  {
    label: 'Clarendon',
    value: '13',
  },
  {
    label: 'Hanover',
    value: '09',
  },
  {
    label: 'Kingston',
    value: '01',
  },
  {
    label: 'Manchester',
    value: '12',
  },
  {
    label: 'Portland',
    value: '04',
  },
  {
    label: 'Saint Andrew',
    value: '02',
  },
  {
    label: 'Saint Ann',
    value: '06',
  },
  {
    label: 'Saint Catherine',
    value: '14',
  },
  {
    label: 'Saint Elizabeth',
    value: '11',
  },
  {
    label: 'Saint James',
    value: '08',
  },
  {
    label: 'Saint Mary',
    value: '05',
  },
  {
    label: 'Saint Thomas',
    value: '03',
  },
  {
    label: 'Trelawny',
    value: '07',
  },
  {
    label: 'Westmoreland',
    value: '10',
  },
];
