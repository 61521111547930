import { ECDynamicPageTemplate } from 'app/components';
import {
  useAddProblemTypeMutation,
  useEditProblemMutation,
  useLazyGetProblemListQuery,
} from 'services/problemApi';
import { loadPageScopes } from 'utils/pageScopes';
import { useCallback, useEffect } from 'react';
import { ActivityType } from 'types/Activity';
import { useDispatch } from 'react-redux';
import { historyApi } from 'services/historyApi';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function ProblemPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('problem');
  }, []);

  const [
    doAdd,
    {
      data: addData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useAddProblemTypeMutation();

  const useAddProblemType = useCallback(() => {
    const addProblem = async data => {
      doAdd(data);
    };

    return [
      addProblem,
      {
        data: addData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    addData,
    isAddError,
    addError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    doAdd,
  ]);

  const [
    doUpdate,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useEditProblemMutation();

  const useUpdateProblemMutation = useCallback(() => {
    const update = async data => {
      doUpdate(data);
    };

    return [
      update,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    updateData,
    updateError,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdate,
    doUpdate,
  ]);

  useEffect(() => {
    if (!isUpdateLoading && isUpdateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  useEffect(() => {
    if (!isAddLoading && isAddSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isAddLoading, isAddSuccess]);

  return (
    <ECDynamicPageTemplate
      pageTitle={'Problem'}
      useLazyGetListQuery={useLazyGetProblemListQuery}
      useCreateMutation={useAddProblemType}
      useUpdateMutation={useUpdateProblemMutation}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      downloadFileName="problem.csv"
      marginTop={false}
      showStatusActiveFilter={true}
      enableExport
      shouldNotUseActiveFilter
      showActivityButton
      activityType={ActivityType.Problems}
    />
  );
}
