import { ECBox, ECIconButton } from '../../components';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DispatchTableView } from './DispatchTableView';
import { DispatchSwimlanesView } from './DispatchSwimlanesView';
import { useCallback, useState } from 'react';
import { ViewHeadline, ViewWeek } from '@mui/icons-material';

export function DispatchTabsPage() {
  const isCustomer = useCustomerUser();
  const { t } = useTranslation();

  // custom state and widget to change between table view and swimlanes in the click of a button
  const [isSwimlaneViewMode, setIsSwimlaneViewMode] = useState<boolean>(true);

  const renderSwitchViewsButton = useCallback(() => {
    return (
      <ECBox
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={2}
      >
        <ECBox bgcolor={isSwimlaneViewMode ? "rgb(233, 233, 233)" : undefined}>
          <ECIconButton
            size="medium"
            withBorder
            onClick={() => setIsSwimlaneViewMode(true)}
          >
            <ViewWeek />
          </ECIconButton>
        </ECBox>
        <ECBox bgcolor={!isSwimlaneViewMode ? "rgb(233, 233, 233)" : undefined}>
          <ECIconButton
            size="medium"
            withBorder
            onClick={() => setIsSwimlaneViewMode(false)}
          >
            <ViewHeadline />
          </ECIconButton>
        </ECBox>
      </ECBox>
    );
  }, [isSwimlaneViewMode]);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECBox pt={10} height={"80%"}>
      <Helmet titleTemplate={`Dispatch`} defaultTitle={'Dispatch'} />
      {isSwimlaneViewMode 
        ? <DispatchSwimlanesView renderChangeViewModeButton={renderSwitchViewsButton} /> 
        : <DispatchTableView renderChangeViewModeButton={renderSwitchViewsButton} />
      }
    </ECBox>
  );
}
