export function not(a: readonly any[], b: readonly any[]) {
  return a.filter(value => b.indexOf(value) === -1);
}

export function notObjectArrays(a: readonly any[], b: readonly any[]) {
  const stringArrayA: string[] = [];
  a.forEach(item => stringArrayA.push(JSON.stringify(item)));
  const stringArrayB: string[] = [];
  b.forEach(item => stringArrayB.push(JSON.stringify(item)));

  const stringArrayResult = not(stringArrayA, stringArrayB);
  const objectArrayResult: any[] = [];
  stringArrayResult.forEach(item => objectArrayResult.push(JSON.parse(item)));
  return objectArrayResult;
}

export function intersection(a: readonly any[], b: readonly any[]) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export function intersectionObjectArrays(a: readonly any[], b: readonly any[]) {
  const stringArrayA: string[] = [];
  a.forEach(item => stringArrayA.push(JSON.stringify(item)));
  const stringArrayB: string[] = [];
  b.forEach(item => stringArrayB.push(JSON.stringify(item)));

  const stringArrayResult = stringArrayA.filter(
    value => stringArrayB.indexOf(value) !== -1,
  );
  const objectArrayResult: any[] = [];
  stringArrayResult.forEach(item => objectArrayResult.push(JSON.parse(item)));
  return objectArrayResult;
}

export function union(a: readonly any[], b: readonly any[]) {
  return [...a, ...not(b, a)];
}

export function unionObjectArrays(a: readonly any[], b: readonly any[]) {
  return [...a, ...notObjectArrays(b, a)];
}
