import { Circle, CircleOutlined, Group } from '@mui/icons-material';
import { BoxProps } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { NotificationList } from 'types/Notification';
import { mapMethod } from 'utils/strings/req-method-to-word.mapper';
import { ECBox } from '../ECBox';
import { ECCheckbox } from '../ECCheckbox';
import { ECIconButton } from '../ECIconButton';
import { ECPopover } from '../ECPopover';
import { ECStack } from '../ECStack';
import { ECTooltip } from '../ECTooltip';
import { ECTypography } from '../ECTypography';

interface ECNotificationProps extends BoxProps {
  notification: NotificationList;
  onCheckChange?: (checked: boolean) => void;
}

export const ECNotification = ({
  notification,
  onCheckChange,
  ...boxProps
}: ECNotificationProps) => {
  const userName = localStorage.getItem('sessionInfoUserName');

  const [showRecipients, setShowRecipients] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const id = showRecipients ? 'simple-popover' : undefined;

  const handleRecipientsIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event?.currentTarget);
    toggleShowRecipients();
  };

  const handleRecipientsClose = () => {
    setAnchorEl(null);
    toggleShowRecipients();
  };

  const toggleShowRecipients = () => setShowRecipients(!showRecipients);

  const isRead = useMemo(
    () =>
      notification.recipients.find(recipient => recipient.userName === userName)
        ?.isRead,
    [notification, userName],
  );

  return (
    <ECBox display="flex" flexDirection="column" px={3} py={2} {...boxProps}>
      <ECBox
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        sx={
          isRead
            ? {
                '&:hover': { '& .MuiCheckbox-root': { color: 'gray' } },
              }
            : {}
        }
      >
        <ECBox display="flex" flexDirection="row">
          <ECTypography>
            {`${notification.fullName} ${
              mapMethod[notification.method] ?? 'modified'
            } a ${notification.module}`}
            &nbsp;
            <ECTypography variant="overline">
              {moment(notification.createdAt).fromNow()}
            </ECTypography>
          </ECTypography>
        </ECBox>

        <ECBox display="flex" alignItems="center">
          <ECIconButton
            size="small"
            aria-label="show recipients"
            onClick={handleRecipientsIconClick}
          >
            <ECTooltip title="Recipients">
              <Group />
            </ECTooltip>
          </ECIconButton>

          <ECPopover
            id={id}
            open={showRecipients}
            anchorEl={anchorEl}
            onClose={handleRecipientsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {notification.recipients?.map(recipient => (
              <ECStack direction="column" spacing={2} p={2}>
                <ECTypography
                  key={`recipient-${recipient.userId}-${notification._id}`}
                >
                  {recipient.fullName}
                </ECTypography>
              </ECStack>
            ))}
          </ECPopover>
        </ECBox>

        <ECBox display="flex" alignItems="center">
          <ECTooltip title={isRead ? 'Mark as unread' : 'Mark as read'}>
            <ECCheckbox
              size="small"
              icon={<CircleOutlined />}
              checkedIcon={<Circle />}
              checked={!isRead}
              onChange={(_, checked) => onCheckChange?.(!checked)}
              sx={{ marginX: 1, color: 'transparent' }}
            />
          </ECTooltip>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
