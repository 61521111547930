import {
  ECBox,
  ECDynamicPageTemplate,
  ECEasyTable,
  ECTypography,
} from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useLazyGetNotificationsSearchQuery } from 'services/notificationApi';
import { Popover } from '@mui/material';
import parse from 'html-react-parser';
import { ECModal } from 'app/components/ECModal';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';

export function NotificationsPage() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [anchorElView, setAnchorElView] = useState<any>(null);
  const [row, setRow] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [recipients, setRecipients] = useState<any>(null);
  const [selectedChipId, setSelectedChipId] = useState<any>(null);
  const [config, setConfig] = useState<any>({});
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const openView = Boolean(anchorElView);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/notification'));
  }, []);

  const handleClickRecipientsNotificationEmail = useCallback((row, event) => {
    setAnchorEl(event.currentTarget);
    setRow(row);
    setRecipients(row?.notification?.email?.recipients);
    setSelectedChipId(`email-recipients-${row?.id}`);
    setConfig({
      header: {
        title: 'Recipients',
        variant: 'simple',
        subtitle: '',
        searchable: false,
        searchFieldName: '',
      },
      pagination: {
        isPaginated: false,
        totalResults: 0,
        currentPage: 1,
        perPage: 1,
      },
      sort: {
        fieldName: 'name',
        value: 'desc',
      },
      cols: [
        {
          title: 'Name',
          dataPath: 'name',
          fieldName: 'name',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
        {
          title: 'Email',
          dataPath: 'email',
          fieldName: 'email',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
        {
          title: 'Sending Status',
          dataPath: 'sent',
          fieldName: 'sent',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'sending_status',
        },
        {
          title: 'Reason / Date Received',
          dataPath: 'errorOrDate',
          fieldName: 'errorOrDate',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
      ],
    });
  }, []);

  const handleClickViewNotificationEmail = useCallback((row, event) => {
    setAnchorElView(event.currentTarget);
    setRow(row);
    setData(row?.notification?.email);
    setSelectedChipId(`email-view-${row?.id}`);
  }, []);

  const handleClickRecipientsNotificationMobile = useCallback((row, event) => {
    setAnchorEl(event.currentTarget);
    setRow(row);
    setRecipients(row?.notification?.push?.recipients);
    setSelectedChipId(`mobile-recipients-${row?.id}`);
    setConfig({
      header: {
        title: 'Recipients',
        variant: 'simple',
        subtitle: '',
        searchable: false,
        searchFieldName: '',
      },
      pagination: {
        isPaginated: false,
        totalResults: 0,
        currentPage: 1,
        perPage: 1,
      },
      sort: {
        fieldName: 'name',
        value: 'desc',
      },
      cols: [
        {
          title: 'Name',
          dataPath: 'name',
          fieldName: 'name',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
        {
          title: 'Device Token',
          dataPath: 'token',
          fieldName: 'token',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
        {
          title: 'Sending Status',
          dataPath: 'sent',
          fieldName: 'sent',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'sending_status',
        },
        {
          title: 'Reason / Date Received',
          dataPath: 'errorOrDate',
          fieldName: 'errorOrDate',
          value: '',
          visible: true,
          filterable: false,
          sortable: false,
          type: 'text',
        },
      ],
    });
  }, []);

  const handleClickViewNotificationMobile = useCallback((row, event) => {
    setAnchorElView(event.currentTarget);
    setRow(row);
    setData(row?.notification?.push);
    setSelectedChipId(`mobile-view-${row?.id}`);
  }, []);

  const handleCloseModal = useCallback(() => {
    setAnchorEl(null);
    setAnchorElView(null);
    setRecipients(null);
    setData(null);
    setRow(null);
    setSelectedChipId(null);
  }, []);

  const isEmailViewSelected = data?.body?.includes('<!DOCTYPE html>');

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={'Notifications Control'}
        useLazyGetListQuery={useLazyGetNotificationsSearchQuery}
        shouldCacheLazyQuery={true}
        useCreateMutation={{}}
        marginTop={true}
        withDrawer={false}
        dateRangeDefaultSelectedOption="1 Day"
        showDateFilter
        dateAlias="sent"
        dateSearchGroup="date"
        onClickRecipientsNotificationEmail={
          handleClickRecipientsNotificationEmail
        }
        onClickViewNotificationEmail={handleClickViewNotificationEmail}
        onClickRecipientsNotificationMobile={
          handleClickRecipientsNotificationMobile
        }
        selectedChipUsersTable={selectedChipId}
        onClickViewNotificationMobile={handleClickViewNotificationMobile}
      />
      <Popover
        id={`recipients-${row?.id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={750}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <ECEasyTable
            isDraggableColumns={false}
            data={recipients}
            config={config}
            hideSearchHeadContent={true}
          />
        </ECBox>
      </Popover>

      <ECModal
        isOpen={openView}
        onClose={handleCloseModal}
        noPadding
        sx={{
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: isEmailViewSelected ? 'scroll' : 'auto',
        }}
      >
        <ECBox display="flex" flexDirection="column" p={2}>
          {!isEmailViewSelected && <ECTypography>{data?.title}</ECTypography>}
          <ECTypography variant={isEmailViewSelected ? 'body1' : 'body2'}>
            {parse(data?.body || '')}
          </ECTypography>
        </ECBox>
      </ECModal>
    </>
  );
}
