import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECEasyForm,
} from 'app/components';
import {
  useLazyGetLocationAssetsAvtListQuery,
  useNotApplicableAssetVerificationMutation,
} from 'services/avtApi';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ECModal } from 'app/components/ECModal';

const fancyFormConfirmNotApplicable = require('./form_confirm_not_applicable.json');

export function LocationAssetsAvt() {
  const { t } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [showNotApplicableModal, setShowNotApplicableModal] = useState(false);
  const rowId = useRef<number>();

  const [triggerLocationAssetsAvt, resultLocationAssetsAvt] =
    useLazyGetLocationAssetsAvtListQuery();

  const {
    data: locationAssetsAvt,
    isError: isLocationAssetsAvtError,
    error: locationAssetsAvtError,
    isLoading: isLoadingLocationAssetsAvt,
    isSuccess: isSuccessLocationAssetsAvt,
    originalArgs: originalArgsLocationAssetsAvt,
  } = resultLocationAssetsAvt;

  const [
    doUpdateNotApplicable,
    {
      error: errorUpdateNotApplicable,
      isError: isUpdateNotApplicableError,
      isLoading: isLoadingUpdateNotApplicable,
      isSuccess: isSuccessUpdateNotApplicable,
      reset: resetUpdateNotApplicable,
    },
  ] = useNotApplicableAssetVerificationMutation();

  const useLazyGetOrgBranches = useCallback(() => {
    const doGet = async queryParams => {
      queryParams.st = 1;
      return triggerLocationAssetsAvt({
        locationId: id ? Number(id) : 0,
        queryParams,
      });
    };

    return [
      doGet,
      {
        data: locationAssetsAvt,
        isError: isLocationAssetsAvtError,
        error: locationAssetsAvtError,
        isLoading: isLoadingLocationAssetsAvt,
        isSuccess: isSuccessLocationAssetsAvt,
        originalArgs: originalArgsLocationAssetsAvt,
      },
    ];
  }, [
    triggerLocationAssetsAvt,
    locationAssetsAvt,
    isLocationAssetsAvtError,
    locationAssetsAvtError,
    isLoadingLocationAssetsAvt,
    isSuccessLocationAssetsAvt,
    originalArgsLocationAssetsAvt,
    isSuccessUpdateNotApplicable,
    isLoadingUpdateNotApplicable,
    isUpdateNotApplicableError,
    errorUpdateNotApplicable,
  ]);

  const handleNotApplicableModal = (id: any) => {
    setShowNotApplicableModal(true);
    rowId.current = id;
  };

  const renderStartAvtActionButton = (row: any) => {
    return (
      <ECBox display="flex" justifyContent="flex-end" width="100%">
        <ECButton
          variant="contained"
          onClick={() =>
            navigate('../avt-assets/' + row.id + '/details', { state: { row } })
          }
          key={`${row.id}-view-assets`}
          sx={{ mr: 1 }}
        >
          {t('translation:pages.avt.startAvt')}
        </ECButton>
        <ECButton
          variant="contained"
          color="error"
          sx={{
            width: '40px',
            minWidth: 'unset',
          }}
          size="small"
          onClick={() => handleNotApplicableModal(row.id)}
          key={`${row.id}-not-applicable`}
        >
          <CloseIcon />
        </ECButton>
      </ECBox>
    );
  };

  const onConfirmNotApplicable = () => {
    if (!rowId.current) {
      return;
    }

    doUpdateNotApplicable({ id: rowId.current });
  };

  const handleCloseModal = () => {
    setShowNotApplicableModal(false);
  };

  useEffect(() => {
    if (isSuccessUpdateNotApplicable) {
      setShowNotApplicableModal(false);
      resetUpdateNotApplicable();
      rowId.current = undefined;
    }
  }, [isSuccessUpdateNotApplicable]);

  const notApplicableModalContent = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormConfirmNotApplicable.config}
        fields={fancyFormConfirmNotApplicable.fields}
        saveButtonColor={'error'}
        customDrawerTitle={
          'Are you sure you want to remove this asset from verification?'
        }
        onFormSubmit={onConfirmNotApplicable}
        isSendingData={isLoadingUpdateNotApplicable}
        isLoadingForm={false}
        isLoading={isLoadingUpdateNotApplicable}
        additionalActions={
          <ECButton type="button" variant="text" onClick={handleCloseModal}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [isLoadingUpdateNotApplicable, handleCloseModal, onConfirmNotApplicable]);

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={'AVT'}
        customTableHeader={
          location?.state?.row?.name
            ? `Assets for Location: ${location?.state?.row?.name || ''} ${
                location?.state?.row?.storeNumber || ''
              }`
            : `Assets for Location ID ${id}`
        }
        useLazyGetListQuery={useLazyGetOrgBranches}
        useCreateMutation={null}
        createFormConfig={undefined}
        createFormFields={undefined}
        withDrawer={false}
        shouldUseSearchParams={false}
        customTableBodyMaxHeight="580px"
        renderCustomActionComponent={renderStartAvtActionButton}
        shouldNotUseActiveFilter
      />
      <ECModal
        isOpen={showNotApplicableModal}
        onClose={handleCloseModal}
        noPadding
        noPaddingBottom
        sx={{
          width: '35rem',
          maxWidth: '90%',
        }}
      >
        {notApplicableModalContent}
      </ECModal>
    </>
  );
}
