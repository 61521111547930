import React, { useEffect } from 'react';
import { ECDynamicPageTemplate } from 'app/components';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLazyGetInventoryAllocatedToQuery } from 'services/inventoryApi';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';

const InventoryAllocatedToPage: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(setFilterEndpoint('/filter/inventoryAllocation'));
  }, []);

  return (
    <ECDynamicPageTemplate
      idToGetListBy={id}
      marginTop={false}
      pageTitle={t(
        'translation:pages.inventory.inventoryDetails.allocatedToTab',
      )}
      useCreateMutation={{}}
      withDrawer={false}
      showBadgeExpirationDate={true}
      shouldUseSearchParams={true}
      showTotal={true}
      shouldNotUseActiveFilter={false}
      useLazyGetListQuery={useLazyGetInventoryAllocatedToQuery}
      selectRowsMaxCount={50}
      showColumnVisibilityFilter
      shouldCacheLazyQuery
    />
  );
};

export default InventoryAllocatedToPage;
