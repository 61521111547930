import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ECBox, ECButton, ECEasyTable, ECWorkflowTemplate } from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import {
  useGetPurchaseOrderByIdQuery,
  useUpdatePurchaseOrderMutation,
} from 'services/purchaseOrderApi';
import { formatDateForTable_2 } from 'utils/strings/formatDate';
import ContactInfoIcon from '@mui/icons-material/PermContactCalendar';
import { SupplierInfoModal } from './supplier-info-modal';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { MonetizationOn } from '@mui/icons-material';
import { NewPOInvoice } from './NewPOInvoice';

const tableConfig = require('./purchase_order_details_table_config.json');

export const PurchaseOrderDetailsPage = () => {
  const { id } = useParams();

  const [isSupplierInfoModalOpen, setIsSupplierInfoModalOpen] =
    useState<boolean>(false);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const hasEditPurchaseOrderPermission = useHasPermission([
    P.EditPurchaseOrder,
  ]);

  const { data: purchaseOrder } = useGetPurchaseOrderByIdQuery(Number(id), {
    skip: !id,
  });

  const handleSummaryFieldChange = useCallback(
    (fieldName: string) => (value: any) => {
      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        [fieldName]: value,
      }));
    },
    [],
  );

  const initializeSummaryFields = () => {
    setSummaryFieldsValues({
      ...purchaseOrder,
      storageLocations: purchaseOrder?.inventoryItemStorage?.map(
        storage => storage?.inventoryStorage,
      ),
    });
  };

  useEffect(() => {
    if (purchaseOrder) {
      initializeSummaryFields();
    }
  }, [purchaseOrder]);

  const summaryData = useMemo(() => {
    const _summaryData = [
      {
        id: 'progressBar',
        label: 'Progress Bar',
        data: purchaseOrder,
        dataPath: 'reconcileShipment',
        type: SummaryFieldTypes.ProgressBar,
      },
      {
        id: 'status',
        label: 'PO Status',
        data: purchaseOrder?.workflowStatusName,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'purchaseOrderNumber',
        label: 'Purchase Order Number',
        data: summaryFieldsValues?.purchaseOrderNumber ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'shipToLocation',
        label: 'Storage Location',
        data: summaryFieldsValues?.shippingLocation ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'shippingAddress',
        label: 'Shipping Address',
        data: summaryFieldsValues?.shippingAddress ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'supplier',
        label: 'Supplier',
        data: summaryFieldsValues?.supplier?.name ?? '-',
        icon: <ContactInfoIcon fontSize="large" />,
        actionTitle: 'Contact Info',
        onIconClick: () => {
          setIsSupplierInfoModalOpen(true);
        },
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'assignedTo',
        label: 'Assigned To',
        data: summaryFieldsValues?.assignedTo ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'shipToUser',
        label: 'Ship To User',
        data: summaryFieldsValues?.shipToUser ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'shipToUserPhone',
        label: 'Ship To User Phone #',
        data: summaryFieldsValues?.shipToUserPhone ?? '-',
        type: SummaryFieldTypes.Phone,
      },
      {
        id: 'shipToUserEmail',
        label: 'Ship To User Email',
        data: summaryFieldsValues?.shipToUserEmail ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'dueDate',
        label: 'Due Date',
        data: formatDateForTable_2(summaryFieldsValues?.dueDate),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'approveDate',
        label: 'Approve Date',
        data: formatDateForTable_2(summaryFieldsValues?.approvedDate),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'updatedDate',
        label: 'Updated Date',
        data: formatDateForTable_2(summaryFieldsValues?.updatedAt),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'createdDate',
        label: 'Created Date',
        data: formatDateForTable_2(summaryFieldsValues?.createdAt),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'submittedBy',
        label: 'Submitted By',
        data: summaryFieldsValues?.submittedBy,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'description',
        label: 'Description',
        data: summaryFieldsValues?.description ?? '-',
        type: SummaryFieldTypes.TextArea,
      },
    ];

    _summaryData.forEach((field: any) => {
      if (!purchaseOrder?.mutableFields) return field;
      if (purchaseOrder?.mutableFields?.includes?.(field?.id)) {
        field.isEditable = true;
        field.required = true;
        field.onChange = newValue => {
          handleSummaryFieldChange(field.id)(newValue);
        };
      } else {
        field.isEditable = false;
      }
      return field;
    });

    return _summaryData;
  }, [summaryFieldsValues, purchaseOrder, handleSummaryFieldChange]);

  const [
    doUpdatePurchaseOrder,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateWorkOrder,
    },
  ] = useUpdatePurchaseOrderMutation();

  const useUpdatePurchaseOrder = useCallback(() => {
    const doUpdate = async data => {
      if (!purchaseOrder?.id) return;
      const updatedData = {
        id: purchaseOrder?.id,
      };

      purchaseOrder.mutableFields?.forEach(field => {
        if (purchaseOrder[field] !== summaryFieldsValues[field])
          updatedData[field] = summaryFieldsValues[field];
      });

      doUpdatePurchaseOrder(updatedData);
    };
    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateWorkOrder,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdateWorkOrder,
    purchaseOrder,
    doUpdatePurchaseOrder,
    summaryFieldsValues,
  ]);

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        summaryData={summaryData}
        moduleName="purchase-order"
        title={purchaseOrder?.name || `Purchase Order #${id}`}
        showEditTitleStartAdornment={true}
        detailsData={purchaseOrder}
        editConfig={{
          submitTitle: 'Save',
        }}
        editFields={[]}
        detailsConfig={{}}
        detailsFields={[]}
        useUpdateMutation={useUpdatePurchaseOrder}
        showEditForm={false}
        showLogo={false}
        additionalActions={[
          <NewPOInvoice purchaseOrder={purchaseOrder} />,
        ]}
        onExitEditModeWithoutSave={initializeSummaryFields}
        isEditAllowed={
          hasEditPurchaseOrderPermission[0] &&
          !!purchaseOrder?.mutableFields?.length
        }
      />
      {isDetailsTab && (
        <ECBox display="flex" height="100%" width="100%" px={2}>
          <ECEasyTable
            sx={{ width: '100%' }}
            config={tableConfig}
            data={purchaseOrder?.purchaseOrderItems}
            isLoading={false}
            headerHeight={5}
            hideSearchHeadContent
            shouldNotUseActiveFilter
          />
        </ECBox>
      )}

      <SupplierInfoModal
        isOpen={isSupplierInfoModalOpen}
        onClose={() => setIsSupplierInfoModalOpen(false)}
        id={purchaseOrder?.supplier?.id}
      />
    </>
  );
};
