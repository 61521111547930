import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ECDynamicPageTemplate,
  ECBox,
  ECButton,
  ECIconButton,
  ECRequirePermission,
} from 'app/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useGetDefaultCategoriesQuery,
  useLazyGetServiceProviderWorkOrdersListQuery,
} from 'services/workOrdersApi';
import { loadPageScopes } from 'utils/pageScopes';
import { setFilterEndpoint } from 'store/slice/page';
import { useServiceProviderUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { Popover } from '@mui/material';
import {
  ECWorkOrderUpdateEtaModalContent,
  updateEtaStatusToShow,
} from 'app/components/ECWorkOrderUpdateEtaModalContent';
import {
  ECWorkOrderRejectModalContent,
  rejectStatusToShow,
} from 'app/components/ECWorkOrderRejectModalContent';
import { useGetDeclineTypesQuery } from 'services/lookupApi';
import { CloseOutlined, Edit } from '@mui/icons-material';
import _ from 'lodash';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { P } from 'types/Permission';
import { bulkEditButtonStyle } from '../WorkOrdersPage';
import { BulkEditDrawer } from './BulkEditDrawer';

const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function ServiceProviderWorkOrdersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);
  const [row, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [action, setAction] = useState<'eta' | 'reject' | null>(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);

  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleSelectedRows = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setClearAllSelectedRows(shouldDisable);
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');
  const {
    data: categories,
    isFetching: isFetchingCategories,
    isSuccess: isSuccessCategories,
  } = useGetDefaultCategoriesQuery();

  const { data: declineTypesData } = useGetDeclineTypesQuery();

  const id =
    action === 'eta'
      ? 'simple-popover'
      : action === 'reject'
        ? 'reject-simple-popover'
        : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
    setAction(null);
  };

  useEffect(() => {
    loadPageScopes('workOrder');
    dispatch(setFilterEndpoint('/filter/workorder'));
  }, []);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingCategories &&
        isSuccessWorkflowStatuses &&
        isSuccessCategories,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingCategories,
    isSuccessWorkflowStatuses,
    isSuccessCategories,
  ]);

  const isServiceProvider = useServiceProviderUser();

  const renderActions = (row: any) => {
    return (
      <ECBox key={`${row?.id}-wo-actions-grid-view`}>
        {updateEtaStatusToShow.includes(
          row.workflowStatus?.name?.toUpperCase(),
        ) && (
          <ECButton
            variant="contained"
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedRow(row);
              setAction('eta');
            }}
          >
            Update ETA
          </ECButton>
        )}
        {rejectStatusToShow.includes(
          row.workflowStatus?.name?.toUpperCase(),
        ) && (
          <ECIconButton
            key="wo-reject-button"
            type="error"
            withBorder
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedRow(row);
              setAction('reject');
            }}
            size="small"
          >
            <CloseOutlined />
          </ECIconButton>
        )}
      </ECBox>
    );
  };

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  const categoryFilterOptions = useMemo(() => {
    return (
      categories?.data.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.id?.toString(),
        isInitialSelected: true,
      })) || []
    );
  }, [categories]);

  if (!isServiceProvider) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.workOrders.title')}
        useLazyGetListQuery={useLazyGetServiceProviderWorkOrdersListQuery}
        useCreateMutation={{}}
        useUpdateMutation={() => false}
        editFormConfig={fancyFormElementsDetails.data.config}
        editFormFields={fancyFormElementsDetails.data.fields}
        editFormAdditionalProcess={({ row, formConfig }) => {
          formConfig.title = row?.assetName;
        }}
        dateFilterFieldsOptions={[
          {
            id: 1,
            label: 'Created Date',
            fieldName: 'wrkordr.createdAt',
          },
          {
            id: 2,
            label: 'Completed Date',
            fieldName: 'wrkordr.completedDate',
          },
        ]}
        statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterAlias="wrkflwstts.groupNameId"
        showAwaitingInvoiceFilter
        showStatusFilter
        categoryFilterAlias="ctgry.id"
        showCategoryFilter
        showDateFilter
        dateRangeDefaultSelectedOption="90 Days"
        showAssignedLocationsFilter
        withDrawer={false}
        renderCustomActionComponent={renderActions}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        showColumnVisibilityFilter
        isDraggableColumns
        enableExport
        exportModuleName="workorder"
        multiSelectTable
        customMultiSelectActionButton={() => (
          <ECRequirePermission
            scopes={[P.EditWorkOrder, P.GetWorkOrder]}
            redirectNoPermission
          >
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={bulkEditButtonStyle}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        )}
        clearSelection={clearAllSelectedRows}
        multiSelectedRows={handleSelectedRows}
      />
      <Popover
        id={id}
        open={action === 'eta'}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox
          boxShadow={2}
          sx={{
            position: 'relative',
            mt: '10px',
            backgroundColor: 'green',
            '&::before': {
              bgcolor: theme => theme.palette.common.white,
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(10%)',
            },
          }}
        />
        <ECWorkOrderUpdateEtaModalContent
          row={row}
          handleCloseModal={handleCloseModal}
        />
      </Popover>
      <Popover
        id={id}
        open={action === 'reject'}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox>
          <ECWorkOrderRejectModalContent
            row={row}
            declineTypes={declineTypesData}
            handleCloseModal={handleCloseModal}
          />
        </ECBox>
      </Popover>
      <BulkEditDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
      />
    </>
  );
}
