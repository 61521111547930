import { ECBox, ECButton } from 'app/components';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { StyleConstants } from 'styles/StyleConstants';
import { useState } from 'react';
import { NewInviteForm } from './NewInviteForm';

export const NewInvite = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ECButton
        onClick={() => setOpen(true)}
        startIcon={<SendIcon />}
        variant="outlined"
      >
        {t('translation:pages.serviceProviderInvite.invite')}
      </ECButton>

      <ECDrawerDetails
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={<NewInviteForm onClose={() => setOpen(false)} />}
            showOnlyDetailsTab={true}
            onClose={() => setOpen(false)}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
            hasTopPadding={false}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
