import { ChipProps } from '@mui/material/Chip';
import { ECChip } from '../ECChip';
import { useTranslation } from 'react-i18next';
import Shield from '@mui/icons-material/Shield';
import { themes } from 'styles/theme/themes';

export enum WarrantyBadgeTypes {
  Full = 'full',
  Expired = 'expired',
  NotUnder = 'not-under',
  Active = 'active',
  Partial = 'partial',
  Inactive = 'inactive',
  Info = 'info',
}
export interface ECWarrantyBadgeProps extends ChipProps {
  warrantyBadgeType: WarrantyBadgeTypes;
  showExpiredText?: boolean;
  customizedLabel?: string;
  hideIcon?: boolean;
  optionalSx?: any;
}

export const ECWarrantyBadge = ({
  warrantyBadgeType,
  showExpiredText,
  customizedLabel,
  hideIcon,
  optionalSx,
}: ECWarrantyBadgeProps) => {
  const { t } = useTranslation();

  let backgroundColor = themes.light.palette.other.lightNoWarranty;
  let label = showExpiredText
    ? t('translation:warranty.component.expiredWarranty')
    : t('translation:warranty.component.noWarranty');
  let textColor = themes.light.palette.other.darkNoWarranty;
  let iconColor = themes.light.palette.other.darkNoWarranty;

  if (warrantyBadgeType === WarrantyBadgeTypes.Active) {
    backgroundColor = themes.light.palette.other.lightActiveWarranty;
    label = t('translation:warranty.component.activeWarranty');

    textColor = themes.light.palette.other.darkActiveWarranty;
    iconColor = themes.light.palette.other.darkActiveWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.Partial) {
    backgroundColor = themes.light.palette.other.lightPartialWarranty;
    label = t('translation:warranty.component.partialWarranty');

    textColor = themes.light.palette.other.darkPartialWarranty;
    iconColor = themes.light.palette.other.darkPartialWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.Full) {
    backgroundColor = themes.light.palette.other.lightActiveWarranty;
    label = t('translation:warranty.component.fullWarranty');

    textColor = themes.light.palette.other.darkActiveWarranty;
    iconColor = themes.light.palette.other.darkActiveWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.Expired) {
    backgroundColor = themes.light.palette.other.lightNoWarranty;
    label = t('translation:warranty.component.expiredWarranty');

    textColor = themes.light.palette.other.darkNoWarranty;
    iconColor = themes.light.palette.other.darkNoWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.Inactive) {
    backgroundColor = themes.light.palette.other.lightNoWarranty;
    label = t('translation:warranty.component.inactiveWarranty');

    textColor = themes.light.palette.other.darkNoWarranty;
    iconColor = themes.light.palette.other.darkNoWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.NotUnder) {
    backgroundColor = themes.light.palette.other.lightNoWarranty;
    label = t('translation:warranty.component.notunderWarranty');

    textColor = themes.light.palette.other.darkNoWarranty;
    iconColor = themes.light.palette.other.darkNoWarranty;
  } else if (warrantyBadgeType === WarrantyBadgeTypes.Info) {
    backgroundColor = themes.light.palette.other.lightInfoWarranty;

    textColor = themes.light.palette.other.darkInfoWarranty;
    iconColor = themes.light.palette.other.darkInfoWarranty;
  }

  return (
    <ECChip
      icon={hideIcon ? undefined : <Shield style={{ color: iconColor }} />}
      color={backgroundColor}
      label={customizedLabel || label}
      sx={{
        color: textColor,
        fontWeight: '500',
        height: '30px',
        ...optionalSx,
      }}
    />
  );
};
