import { Metadata } from 'types/Metadata';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Metadata'],
});

export const metadataApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getMetadata: builder.query<Metadata, string>({
      query: moduleName => {
        return {
          url: `metadata/${moduleName}`,
        };
      },
      providesTags: ['Metadata'],
    }),
  }),
});

export const { useGetMetadataQuery } = metadataApi;
