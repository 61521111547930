import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLazyGetUsersWithRolesListQuery } from 'services/userApi';
import { EditUserRoles } from './EditUserRoles';
import {
  ECDrawerEdit,
  ECEasyTable,
  ECGrid,
  ECPanelBreadcrumbs,
  ECPaper,
} from 'app/components';
import { handleRequestConfigs } from 'utils/ECEasyTableUtils';
import { loadPageScopes } from 'utils/pageScopes';

const tableConfig = require('./table-config.json');
let config = tableConfig.data.config;

let queryParams;

export function AdminUserRolesPage() {
  const [trigger, result, lastPromiseInfo] =
    useLazyGetUsersWithRolesListQuery();
  const [open, setOpen] = useState(false);
  const [whichModal, setWhichModal] = useState('create');
  const [row, setRow] = useState<any>();

  const handleRequest = useCallback(
    (params?: any) => {
      handleRequestConfigs(params, trigger, config);
    },
    [trigger],
  );

  useEffect(() => {
    loadPageScopes('userRole');
    handleRequest(config);
  }, []);

  const handleRefreshClick = () => {
    handleRequest(config);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = row => {
    setRow(row);
    setWhichModal('edit');
    setOpen(true);
  };

  const { data, isFetching, isLoading, isSuccess, error, isError } = result;

  return (
    <>
      <Helmet>
        <title>{'User Roles'}</title>
      </Helmet>
      <ECGrid sx={{ padding: 2.3 }}>
        <ECGrid item xs={12}>
          <ECPanelBreadcrumbs />
        </ECGrid>
        <ECDrawerEdit open={open} anchor="right" onClose={handleClose}>
          <ECPaper
            sx={{ height: '100%', paddingTop: '80px' }}
            role="presentation"
          >
            {whichModal === 'edit' ? (
              <EditUserRoles
                lastQueryParams={queryParams}
                onRoleEdited={handleRefreshClick}
                user={row}
                onClose={handleClose}
              />
            ) : null}
          </ECPaper>
        </ECDrawerEdit>
        <ECEasyTable
          config={config}
          data={Array.isArray(data) ? data : (data as any)?.data}
          onDataRequest={handleRequest}
          isLoading={isLoading}
          isSendingRequest={isFetching}
          onRowPress={handleRowClick}
          enableExport={true}
          downloadFileName="user-roles.csv"
          shouldNotUseActiveFilter
        />
      </ECGrid>
    </>
  );
}
