import { Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { ECSwitch } from '../ECSwitch';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { useMemo } from 'react';

export const ECTechNotAssignedFilter = () => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  return (
    <Stack
      direction="row"
      spacing={1}
      pr={1.5}
      alignItems="center"
      justifyContent="right"
    >
      <ECSwitch
        id="id-active-status-filter"
        name="name-active-status-filter"
        checked={
          activeFilter?.techNotAssigned !== undefined &&
          activeFilter?.techNotAssigned
        }
        value={
          activeFilter?.techNotAssigned !== undefined &&
          activeFilter?.techNotAssigned
        }
        onChange={(_, checked) => {
          dispatch(
            setActiveFilter({
              techNotAssigned: checked,
            }),
          );
        }}
      />
      <ECTypography
        sx={theme => ({
          color: theme.palette.text.primary,
        })}
      >
        Tech Not Assigned
      </ECTypography>
    </Stack>
  );
};
