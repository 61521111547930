import { ECDynamicPageTemplate } from 'app/components';
import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useAddAssetTradeProblemMutation,
  useDeleteAssetTradeProblemMutation,
  useLazyGetAssetTradeProblemQuery,
  useEditAssetTradeProblemMutation,
} from 'services/assetTradeProblemApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetProblemListQuery } from 'services/problemApi';
import { useGetTradesListQuery } from 'services/tradeApi';
import { loadPageScopes } from 'utils/pageScopes';
import { setTitle } from 'store/slice/page';
import { ActivityType } from 'types/Activity';
import { historyApi } from 'services/historyApi';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export function AssetTradeProblemPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('trade');
  }, []);

  useEffect(() => {
    dispatch(setTitle(t('translation:pages.assetTradeProblem.title')));
  }, [dispatch]);

  const [row, setRow] = useState<any>();
  const [currentData, setCurrentData] = useState<any>();

  const { data: tradesList, isSuccess: isSuccessTradesList } =
    useGetTradesListQuery({});

  // this field is read only for know
  // no need to populate it. Transformed into a text field
  /* useEffect(() => {
    if (isSuccessTradesList && tradesList) {
      populateDropDownFields({
        responseData: tradesList,
        editFormFields: fancyFormElementsEdit.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'tradeName',
        enabledAfterPopulate: false,
      });
    }
  }, [isSuccessTradesList, tradesList]); */

  const { data: assetTypes, isSuccess: isSuccessAssetTypes } =
    useGetAssetTypesListQuery({});

  // this field is read only for know
  // no need to populate it. Transformed into a text field
  /* useEffect(() => {
    if (isSuccessAssetTypes && assetTypes) {
      populateDropDownFields({
        responseData: assetTypes,
        editFormFields: fancyFormElementsEdit.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'assetType',
        enabledAfterPopulate: false,
      });
    }
  }, [isSuccessAssetTypes, assetTypes]); */

  const { data: problems, isSuccess: isSuccessProblems } =
    useGetProblemListQuery({
      t: 1600,
    });

  useEffect(() => {
    if (problems && isSuccessProblems) {
      let availableProblems = [...problems.data];

      if (row && currentData) {
        const currentlySelectedAssetType = currentData.data.find(
          atp => atp.assetTypeId === row.assetTypeId,
        );
        const otherTrades = currentlySelectedAssetType.tradeProblems.filter(
          tradeProblem => tradeProblem.tradeId !== row.tradeId,
        );
        const alreadySelectedProblemsInOtherTrades = _.map(
          otherTrades,
          'problems',
        );
        const alreadySelectedProblemsInOtherTradesIds = _.map(
          _.flatten(alreadySelectedProblemsInOtherTrades),
          'id',
        );

        availableProblems = availableProblems.filter(
          problem =>
            !alreadySelectedProblemsInOtherTradesIds.includes(problem.id),
        );
      }

      let editSelectField = fancyFormElementsEdit.fields.find(
        field => field.fieldName === 'problems',
      );
      editSelectField.options =
        availableProblems.map(d => ({ label: d.name, id: d.id })) || [];
      editSelectField.optionValues = availableProblems.map(d => d.name) || [];
    }
  }, [problems, isSuccessProblems, row, currentData]);

  const [
    doDelete,
    {
      data: deleteData,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      reset: resetDelete,
    },
  ] = useDeleteAssetTradeProblemMutation();

  const [
    doAdd,
    {
      data: addData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useAddAssetTradeProblemMutation();

  const reset = useCallback(() => {
    resetAdd();
    resetDelete();
  }, [resetDelete, resetAdd]);

  const [
    doEdit,
    {
      data: editData,
      isError: isEditError,
      error: editError,
      isLoading: isEditLoading,
      isSuccess: isEditSuccess,
      reset: resetEdit,
    },
  ] = useEditAssetTradeProblemMutation();

  const useUpdateAsset = useCallback(() => {
    const doUpdate = async data => {
      const oldProblemsIds = row?.problems.map(problem => problem.id);
      const updatedProblemsIds = data.problems.map(problem => problem.id);

      const deletedProblemsIds: number[] = _.difference(
        oldProblemsIds,
        updatedProblemsIds,
      );

      const newProblemsIds: number[] = _.difference(
        updatedProblemsIds,
        oldProblemsIds,
      );

      doEdit({
        assetTypeId: row.assetTypeId,
        newAssetTradeProblem: newProblemsIds.map(problemId => ({
          tradeId: row.tradeId,
          problemId,
        })),
        deletedAssetTradeProblem: deletedProblemsIds.map(problemId => ({
          tradeId: row.tradeId,
          problemId,
        })),
      });
    };

    return [
      doUpdate,
      {
        data: editData,
        isError: isEditError,
        error: editError,
        isLoading: isEditLoading,
        isSuccess: isEditSuccess,
        reset: resetEdit,
      },
    ];
  }, [
    editData,
    isEditError,
    editError,
    isEditLoading,
    isEditSuccess,
    row,
    doEdit,
    resetEdit,
  ]);

  const computeTradeProblemsCreateData = useCallback(
    (data: any) => {
      return data.assetTradeProblem.trades.reduce((acc, trade) => {
        const tradeId =
          tradesList?.data.find(tradeList => tradeList.name === trade.tradeName)
            ?.id ?? 0;

        const newProblemsIds: number[] = _.compact(
          trade.problems?.map(
            newProblem =>
              problems?.data.find(problem => newProblem.label === problem.name)
                ?.id,
          ),
        );

        const tradeProblems = newProblemsIds.map(problemId => ({
          tradeId,
          problemId,
        }));

        return [...acc, ...tradeProblems];
      }, []);
    },
    [problems?.data, tradesList?.data],
  );

  const useCreateAsset = useCallback(() => {
    const doCreate = async data => {
      const assetTypeId = data.assetTradeProblem.assetType.id;

      const body = computeTradeProblemsCreateData(data);
      if (body && body.length > 0) {
        doAdd({
          assetTypeId,
          body,
        });
      }
    };

    return [
      doCreate,
      {
        data: addData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    addData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    assetTypes,
    tradesList,
    problems,
    resetAdd,
    doAdd,
  ]);

  const useDeleteAsset = useCallback(() => {
    const doRemoveRelationship = async data => {
      await doDelete({
        assetTypeId: data.assetTypeId,
        body: data.problems.map(problem => ({
          tradeId: data.tradeId,
          problemId: problem.id,
        })),
      });
    };

    return [
      doRemoveRelationship,
      {
        data: deleteData,
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
        reset: resetDelete,
      },
    ];
  }, [
    deleteData,
    isDeleteError,
    isDeleteLoading,
    isDeleteSuccess,
    deleteError,
    resetDelete,
    doDelete,
  ]);

  useEffect(() => {
    if (
      (!isEditLoading && isEditSuccess) ||
      (!isAddLoading && isAddSuccess) ||
      (!isDeleteLoading && isDeleteSuccess)
    ) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [
    isEditLoading,
    isEditSuccess,
    isAddLoading,
    isAddSuccess,
    isDeleteLoading,
    isDeleteSuccess,
  ]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.assetTradeProblem.title')}
      useLazyGetListQuery={useLazyGetAssetTradeProblemQuery}
      useCreateMutation={useCreateAsset}
      useDeleteMutation={useDeleteAsset}
      useUpdateMutation={useUpdateAsset}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      onRowClick={setRow}
      disclaimer={t('translation:assetTradeProblem.disclaimer')}
      deleteConfirmationTitle={t('translation:assetTradeProblem.delete.title')}
      deleteConfirmationBody={t('translation:assetTradeProblem.delete.body')}
      onNewDataFetched={setCurrentData}
      defaultSortConfig={{ fieldName: 'assttp.name', value: 'ASC' }}
      enableExport
      exportModuleName="assettradeproblem"
      shouldNotUseActiveFilter
      showActivityButton
      activityType={ActivityType.AssetTradeProblem}
    />
  );
}
