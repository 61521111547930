import { ECBox } from '../../../components/ECBox';
import { ECTypography } from '../../../components/ECTypography';
import { WorkOrderCard } from 'types/WorkOrders';
import _ from 'lodash';
import { ECChip, presetColors } from '../../../components/ECChip';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { Error } from '@mui/icons-material';
import { ECTooltip } from 'app/components/ECTooltip';
import { useState } from 'react';
import { DispatchWorkOrderModal } from './DispatchWorkOrderDetailsModal';

interface DispatchWorkOrderCardProps {
  workOrder: WorkOrderCard;
  openCopyWoDrawer?: (value: boolean, workorder: any) => void;
  dragPanelId?: string;
}

export const DispatchWorkOrderCard = ({
  workOrder,
  openCopyWoDrawer,
  dragPanelId,
}: DispatchWorkOrderCardProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  if (!workOrder) {
    return <></>;
  }

  return (
    <>
      <ECBox
        key={workOrder.id}
        display="flex"
        borderColor={theme => theme.palette.other.divider}
        boxShadow={2}
        p={1}
        borderRadius={'0px 4px'}
        bgcolor={theme => theme.palette.common.white}
        borderLeft={theme =>
          workOrder.priorityColor
            ? '4px ' +
              presetColors.find(
                color => color.title === workOrder.priorityColor,
              )?.color +
              ' solid'
            : undefined
        }
        mb={1}
        maxWidth={dragPanelId ? '200px' : 'auto'}
        onClick={() => setIsOpenModal(true)}
        sx={{
          cursor: dragPanelId === 'unAssignedPanel' ? 'not-allowed' : 'grab',
          '&:hover': { boxShadow: 4 },
          transform: dragPanelId ? 'rotate(8deg)' : 'none',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <ECBox display="flex" flexDirection="column" width="100%">
          <ECBox display={'flex'} alignItems={'center'}>
            {workOrder.overDue && (
              <ECTooltip title="Overdue" arrow>
                <Error
                  sx={{
                    color: 'red',
                    height: '18px',
                    width: '18px',
                    marginRight: 1,
                  }}
                />
              </ECTooltip>
            )}
            <ECTypography variant="h6" mr={1} fontSize="16px">
              #{workOrder.id}
            </ECTypography>
            <ECTooltip title={workOrder.priorityName}>
              <ECChip
                sx={{ padding: '0px', height: '20px' }}
                color={workOrder.priorityColor}
                borderColor={workOrder.priorityColor}
                label={limitStringMaxCharacters(
                  workOrder.priorityName ?? '',
                  7,
                )}
              />
            </ECTooltip>
          </ECBox>
          <ECTypography variant="caption" sx={{ opacity: '0.5' }}>
            {limitStringMaxCharacters(
              `Store #${workOrder.locationStoreNumber} • ${workOrder.locationName}`,
              34,
            )}
          </ECTypography>
          <ECTypography variant="caption" sx={{ opacity: '0.5' }}>
            {limitStringMaxCharacters(
              `${workOrder.assetName} • ${workOrder.problem}`,
              30,
            )}
          </ECTypography>
        </ECBox>
      </ECBox>
      {isOpenModal && (
        <DispatchWorkOrderModal
          workOrderId={workOrder.id}
          priorityColor={workOrder.priorityColor}
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          openCopyWoDrawer={openCopyWoDrawer}
        />
      )}
    </>
  );
};
