import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECTypography,
  ECChip,
  ECFormControl,
  ECMenuItem,
  ECSelect,
  ECIconButton,
  TreeNode,
  ECTabContainer,
  ECTextField,
  ECTree,
} from 'app/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSpWorkOrderActionClickMutation } from 'services/workOrdersApi';
import { setSnackbar } from 'store/slice/page';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { Lookup } from 'types/Lookup';
import { InputLabel, FormHelperText, Popover } from '@mui/material';
import {
  Notifications,
  ArrowDropUp,
  ArrowDropDown,
  Close,
} from '@mui/icons-material';
import { P } from 'types/Permission';
import { ECRichTextField } from '../ECRichTextField';
import { useGetUsersByRoleNameAndModuleIdQuery } from 'services/userApi';
import { useCustomerUser } from 'app/hooks';
import _ from 'lodash';
import { findNodeById, findNode } from 'utils/tree';
import { useNavigate } from 'react-router';
import { WorkflowStatusGroupName } from '../ECWorkflowStatusBadge';

export interface ECWorkOrderRejectModalContentProps {
  row: any;
  declineTypes?: Lookup[];
  fromDetails?: boolean;
  handleCloseModal: () => void;
  workOrderNextStatus?: any;
}

export const rejectStatusToShow = ['PENDING SP ACCEPTANCE'];

const SEARCH_DEBOUNCE_TIME = 500;

export const ECWorkOrderRejectModalContent = ({
  row,
  declineTypes,
  fromDetails,
  handleCloseModal,
  workOrderNextStatus,
}: ECWorkOrderRejectModalContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDeclineType, setSelectedDeclineType] = useState<string>('');
  const [selectedDeclineTypeId, setSelectedDeclineTypeId] = useState<number>();

  const [validationSelectReason, setValidationSelectReason] = useState('');
  const [validationReasonText, setValidationReasonText] = useState('');
  const [validationCommentText, setValidationCommentText] = useState('');

  const notifiedCollapsedRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [rejectStatusId, setRejectStatusId] = useState<number>(0);

  const [otherReason, setOtherReason] = useState<string>('');

  const open = Boolean(anchorEl);

  const [currentComment, setCurrentComment] = useState<any | undefined>();

  const isCustomer = useCustomerUser();

  const [initialTree, setInitialTree] = useState<any[]>([]);
  const [tree, setTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [nodesIds, setNodesIds] = useState<number[]>([]);
  const [currentCompanyInitialTree, setCurrentCompanyInitialTree] = useState<
    any[]
  >([]);
  const [currentCompanyTree, setCurrentCompanyTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [currentCompanyNodesIds, setCurrentCompanyNodesIds] = useState<
    number[]
  >([]);

  const { data: allUsers, isSuccess: isSuccessUsers } =
    useGetUsersByRoleNameAndModuleIdQuery({
      moduleName: 'workorder',
      role: 'DIRECT_NOTES',
      userType: 'CSP',
      moduleId: row?.id,
    });

  const users = useMemo(() => {
    if (isCustomer) {
      return allUsers?.customersInfo;
    }
    return allUsers?.spUsersInfo;
  }, [allUsers, isCustomer]);

  const otherUsers = useMemo(() => {
    if (isCustomer) {
      return allUsers?.spUsersInfo;
    }
    return allUsers?.customersInfo;
  }, [allUsers, isCustomer]);

  const popoverId = open ? 'simple-popover' : undefined;

  const handleCloseNotifiedModal = () => {
    setAnchorEl(null);
  };

  const selectedUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        nodesIds.map(nodeId => findNodeById(initialTree, nodeId)),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [nodesIds, initialTree]);

  const selectedCurrentCompanyUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        currentCompanyNodesIds.map(nodeId =>
          findNodeById(currentCompanyInitialTree, nodeId),
        ),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [currentCompanyNodesIds, currentCompanyInitialTree]);

  const mappedCurrentCompanyTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(users)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [users]);

  const mappedTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(otherUsers)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [otherUsers]);

  useEffect(() => {
    if (mappedTree && isSuccessUsers) {
      setTree(mappedTree);
      setInitialTree(mappedTree);
    }
  }, [isSuccessUsers, mappedTree]);

  useEffect(() => {
    if (mappedCurrentCompanyTree && isSuccessUsers) {
      setCurrentCompanyTree(mappedCurrentCompanyTree);
      setCurrentCompanyInitialTree(mappedCurrentCompanyTree);
    }
  }, [isSuccessUsers, mappedCurrentCompanyTree]);

  useEffect(() => {
    if (workOrderNextStatus) {
      const workorderRejectStat = workOrderNextStatus.find(
        workorderStatus => workorderStatus.name === 'Reject',
      );
      if (workorderRejectStat?.statusTo) {
        setRejectStatusId(workorderRejectStat.statusTo);
      }
    }
  }, [workOrderNextStatus]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    if (mappedTree) {
      if (search === '') {
        setTree(mappedTree);
        return;
      }

      const filteredNodes = findNode(mappedTree, search, ['email']);
      setTree(filteredNodes);
    }
  }, [search, mappedTree]);

  const debouncedHandleSearchChange = useRef(
    _.debounce(e => {
      setSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const [currentCompanySearch, setCurrentCompanySearch] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (currentCompanySearch === undefined) {
      return;
    }

    if (mappedCurrentCompanyTree) {
      if (currentCompanySearch === '') {
        setCurrentCompanyTree(mappedCurrentCompanyTree);
        return;
      }

      const filteredNodes = findNode(
        mappedCurrentCompanyTree,
        currentCompanySearch,
        ['email'],
      );
      setCurrentCompanyTree(filteredNodes);
    }
  }, [currentCompanySearch, mappedCurrentCompanyTree]);

  const debouncedHandleCurrentCompanySearchChange = useRef(
    _.debounce(e => {
      setCurrentCompanySearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const [
    doUpdateReject,
    {
      error: updateRejectError,
      isError: isUpdateRejectError,
      isLoading: isUpdateRejectLoading,
      isSuccess: isUpdateRejectSuccess,
      reset: resetUpdateReject,
    },
  ] = useSpWorkOrderActionClickMutation();

  useEffect(() => {
    if (isUpdateRejectSuccess) {
      resetUpdateReject();
      navigate('/panel/sp/work-orders');
    }
  }, [isUpdateRejectSuccess]);

  const handleSaveReject = () => {
    if (!selectedDeclineTypeId) {
      setValidationSelectReason('Please select a reason');
    }

    let workorderRejectUpdateBody: any = {
      id: row.id,
      declineTypeId: selectedDeclineTypeId,
      statusTo: rejectStatusId,
      statusGroupName: WorkflowStatusGroupName.Unassigned,
    };

    if (selectedDeclineType === 'Other') {
      if (!otherReason?.trim()) {
        setValidationReasonText('Please enter a reason');
      }
      workorderRejectUpdateBody.declineReason = otherReason;
    }

    const isEmptyHtmlComment = !currentComment
      ?.replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('&nbsp;', '')
      .replaceAll('\n', '').length;

    if (isEmptyHtmlComment) {
      setValidationCommentText('Please enter a comment');
    }

    if (!selectedDeclineTypeId || !currentComment || isEmptyHtmlComment) {
      return;
    }

    if (!isEmptyHtmlComment) {
      const recipientIds = [
        ...selectedUsers,
        ...selectedCurrentCompanyUsers,
      ]?.map(selectedUser => selectedUser.nodeId);

      workorderRejectUpdateBody.note = currentComment;
      workorderRejectUpdateBody.declineReason = selectedDeclineType;
      if (recipientIds?.length > 0) {
        workorderRejectUpdateBody.recipientIds = recipientIds;
      }
    }
    doUpdateReject(workorderRejectUpdateBody);
  };

  const handleChangeReason = (event: any) => {
    setValidationSelectReason('');
    setValidationReasonText('');
    setOtherReason('');
    const declineTypeName = event.target.value;
    const declineTypeId = declineTypes?.find(
      declineType => declineType.name === declineTypeName,
    )?.id;
    setSelectedDeclineType(declineTypeName);
    setSelectedDeclineTypeId(declineTypeId);
  };

  useEffect(() => {
    if (isUpdateRejectError && updateRejectError) {
      handleCloseModal();
      resetUpdateReject();
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (updateRejectError as any)?.data?.message,
        }),
      );
    }
  }, [updateRejectError, isUpdateRejectError]);

  useEffect(() => {
    if (isUpdateRejectSuccess && !isUpdateRejectLoading) {
      resetUpdateReject();
      handleCloseModal();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Work order rejected Successfully',
        }),
      );
      if (fromDetails) {
        navigate('/panel/sp/work-orders');
      }
    }
  }, [isUpdateRejectSuccess, isUpdateRejectLoading]);

  return (
    <ECBox p={1} bgcolor="transparent" mt={-1}>
      <ECBox
        sx={theme => ({
          borderRadius: 1,
          boxShadow: 2,
          minWidth: '500px',
          bgcolor: theme.palette.common.white,
        })}
      >
        <ECBox display="flex" flexDirection="column" p={2}>
          <ECBox display={'flex'} alignItems={'center'}>
            <ECTypography variant="h6" px={1}>
              Confirm the status change to
              <ECBox
                component="span"
                px={1}
                color={theme => theme.palette.error.dark}
              >
                Rejected
              </ECBox>
            </ECTypography>
          </ECBox>

          <ECBox
            display={'grid'}
            mt={1}
            gridTemplateColumns={'1fr 2fr'}
            width={'100%'}
          >
            <ECTypography fontWeight={500} variant="body1" px={1}>
              Location:
            </ECTypography>
            <ECTypography variant="body1" px={1}>
              {row?.request?.asset?.branch?.name}
            </ECTypography>
          </ECBox>
          <ECBox
            display={'grid'}
            mt={1}
            gridTemplateColumns={'1fr 2fr'}
            width={'100%'}
          >
            <ECTypography fontWeight={500} variant="body1" px={1}>
              Asset:
            </ECTypography>
            <ECTypography variant="body1" px={1}>
              {row?.request?.asset?.name}
            </ECTypography>
          </ECBox>
          <ECFormControl variant="filled" sx={{ width: '100%', marginTop: 1 }}>
            <InputLabel id={t('translation:form.operatingHours.days')}>
              Select a Reason*
            </InputLabel>
            <ECSelect
              variant="filled"
              value={selectedDeclineType}
              label="Select a Reason*"
              name="reject-reason"
              labelId="reject-reason-id"
              placeholder="Select a Reason*"
              onChange={handleChangeReason}
              id="wo-reject-reason"
              error={validationSelectReason ? true : false}
            >
              {declineTypes?.map(option => (
                <ECMenuItem key={option.id} value={option.name}>
                  {option.name}
                </ECMenuItem>
              ))}
            </ECSelect>
            {validationSelectReason && (
              <FormHelperText
                sx={theme => ({
                  color: theme.palette.error.main,
                })}
              >
                {validationSelectReason}
              </FormHelperText>
            )}
          </ECFormControl>
          {selectedDeclineType === 'Other' && (
            <ECFormControl
              variant="filled"
              sx={{ width: '100%', marginTop: 1 }}
            >
              <ECTextField
                key="wo-reject-reason-other"
                id="id-wo-reject-reason-other"
                name="wo-reject-reason-other"
                sx={{ width: '100%' }}
                error={!!validationReasonText}
                helperText={validationReasonText}
                variant="filled"
                label="Reason*"
                placeholder="Reason"
                inputProps={{
                  maxLength: 1000,
                }}
                value={otherReason}
                InputLabelProps={otherReason ? { shrink: true } : {}}
                onChange={event => {
                  setValidationReasonText('');
                  setOtherReason(event.target.value);
                }}
              />
            </ECFormControl>
          )}
          <ECBox
            display="flex"
            bgcolor={theme => theme.palette.background.default}
            flexDirection="column"
            height="100%"
            pt={3}
            pb={3}
          >
            <ECBox display="flex" flexDirection="column">
              <ECBox display="flex" justifyContent="flex-end">
                <ECBox
                  ref={notifiedCollapsedRef}
                  display="flex"
                  bgcolor={theme =>
                    theme.palette.marketingSuggestion.marketingSuggestion
                  }
                  px={1}
                  height="48px"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  sx={{
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                  }}
                >
                  <Notifications
                    sx={theme => ({ color: theme.palette.common.white })}
                  />
                  <ECTypography color={theme => theme.palette.common.white}>
                    NOTIFIED:{' '}
                    {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                    Selected
                  </ECTypography>

                  <ECIconButton
                    size="small"
                    type="white"
                    noPadding
                    sx={theme => ({
                      color: theme.palette.common.white,
                      padding: 0,
                    })}
                    onClick={() => setAnchorEl(notifiedCollapsedRef.current)}
                  >
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                  </ECIconButton>
                </ECBox>
              </ECBox>
              <ECRichTextField
                sx={{ width: '100%', borderColor: 'black' }}
                placeholder={t('translation:richTextField.placeholder')}
                defaultValue={currentComment?.body}
                isLoading={isUpdateRejectLoading}
                isSendButtonVisible={false}
                onChange={setCurrentComment}
                scopes={[P.AddComment]}
              />
              {validationCommentText && (
                <FormHelperText
                  sx={theme => ({
                    color: theme.palette.error.main,
                  })}
                >
                  {validationCommentText}
                </FormHelperText>
              )}
            </ECBox>
          </ECBox>
          <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
            <ECButton variant="text" onClick={handleCloseModal}>
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleSaveReject}>
              {isUpdateRejectLoading ? (
                <ECCircularProgress
                  size={'2rem'}
                  sx={{ color: 'background.paper' }}
                />
              ) : (
                'Confirm'
              )}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECBox>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotifiedModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" flexDirection="column" width={380}>
          <ECBox
            display="flex"
            bgcolor={theme =>
              theme.palette.marketingSuggestion.marketingSuggestion
            }
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <ECTypography
              variant="h6"
              color={theme => theme.palette.common.white}
            >
              Notified
            </ECTypography>

            <ECBox display="flex" gap={2} alignItems="center">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.common.white}
              >
                {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                selected
              </ECTypography>

              <ECIconButton type="white" onClick={handleCloseNotifiedModal}>
                <Close />
              </ECIconButton>
            </ECBox>
          </ECBox>

          <ECTabContainer
            p={2}
            variant="standard"
            noPadding
            clearFilterOnChangeSelectedTab={false}
            tabs={[
              {
                value: '1',
                label: t('translation:noteTab.users', {
                  count: selectedCurrentCompanyUsers?.length ?? 0,
                }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={currentCompanySearch}
                        onChange={debouncedHandleCurrentCompanySearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={currentCompanyTree}
                      showSelectAllOption
                      keepSelectedNodes={currentCompanySearch !== undefined}
                      initialSelectedNodesIds={currentCompanyNodesIds}
                      selectedNodes={currentCompanyNodesIds}
                      onSelectedNodesIdsChange={setCurrentCompanyNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
              {
                value: '2',
                label: isCustomer
                  ? t('translation:noteTab.sp', {
                      count: selectedUsers?.length ?? 0,
                    })
                  : t('translation:noteTab.customer', {
                      count: selectedUsers?.length ?? 0,
                    }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={search}
                        onChange={debouncedHandleSearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={tree}
                      showSelectAllOption
                      keepSelectedNodes={search !== undefined}
                      initialSelectedNodesIds={nodesIds}
                      selectedNodes={nodesIds}
                      onSelectedNodesIdsChange={setNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
            ]}
          />
        </ECBox>
      </Popover>
    </ECBox>
  );
};
