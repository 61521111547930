import {
  CreateSPParams,
  ValidateInvitationCodeResponse,
} from 'types/ServiceProviderTypes';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [],
});

export const spAuthApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    validateInvitationCode: builder.query<
      ValidateInvitationCodeResponse,
      string
    >({
      query: code => {
        return {
          url: 'sp/auth/signup/invitation/verify',
          params: {
            code,
          },
        };
      },
    }),
    validateEmail: builder.query<void, string>({
      query: email => {
        return {
          url: 'sp/auth/signup/email/verify',
          params: {
            email,
          },
        };
      },
    }),
    createSP: builder.mutation<void, CreateSPParams>({
      query: body => {
        return {
          url: 'sp/auth/signup/invitation',
          method: 'post',
          body,
        };
      },
    }),
    getSignUpRequiredFields: builder.query<string[], number>({
      query: companyId => {
        return {
          url: 'sp/auth/signup/required-fields',
          params: {
            companyId,
          },
        };
      },
    }),
  }),
});

export const {
  useValidateInvitationCodeQuery,
  useLazyValidateInvitationCodeQuery,
  useValidateEmailQuery,
  useLazyValidateEmailQuery,
  useCreateSPMutation,
  useGetSignUpRequiredFieldsQuery,
} = spAuthApi;
