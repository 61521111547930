import { ECEasyFormFieldType, ECPaper } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useCallback, useEffect, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { useBulkEditPreventiveMaintenanceLocationMutation } from 'services/preventiveMaintenanceApi';
import { useGetCompanyServiceProvidersDropdownListQuery } from 'services/serviceProviderApi';
import { useLazyGetTradeAssignmentByTradeIdQuery } from 'services/tradeApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import {
  SelectionType,
  ServiceFrequencyValueType,
} from 'app/components/ECServiceFrequency';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getNextServiceDate } from 'utils/dates';
import { StyleConstants } from 'styles/StyleConstants';

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');

interface BulkEditDrawerProps {
  masterPm: any;
  isOpen: boolean;
  onClose: () => void;
  selectedRows?: any[];
  onSelectedRowsChange?: (selectedRows: any[]) => void;
}

export const BulkEditDrawer = ({
  masterPm,
  isOpen,
  selectedRows,
  onClose,
  onSelectedRowsChange,
}: BulkEditDrawerProps) => {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsBulkEdit.fields,
  );
  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  const [reset, setReset] = useState(false);
  const [forceRefreshKey, setForceRefreshKey] = useState(Date.now().toString());

  const [
    doUpdateBulkEdit,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: updateReset,
    },
  ] = useBulkEditPreventiveMaintenanceLocationMutation();

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListQuery({
      ob: 'prrt.name',
      o: 'desc',
    });

  useEffect(() => {
    if (isSuccessPriorities) {
      const updatedFormFields = [...formFields];

      const priorityField = updatedFormFields?.find(
        field => field.fieldName === 'priority',
      );

      if (priorityField) {
        priorityField.options =
          priorities?.data.map(d => ({
            label: d.name,
            colorName: d.color,
            id: d.id,
          })) || [];
        priorityField.optionValues = priorities?.data.map(d => d.id) || [];
      }

      setFormFields(updatedFormFields);
    }
  }, [priorities, isSuccessPriorities]);

  const [trigger, result] = useLazyGetTradeAssignmentByTradeIdQuery({});

  const {
    data: tradeAssigmnents,
    isSuccess: isSuccessTradeAssigments,
    isFetching: isFetchingTradeAssigments,
  } = result;

  const {
    data: allServiceProviders,
    isSuccess: isSuccessAllSPs,
    isFetching: isFetchingAllSPs,
  } = useGetCompanyServiceProvidersDropdownListQuery({
    t: 0,
    st: 1,
    ob: 'cmpny.name',
  });

  useEffect(() => {
    if (isSuccessAllSPs) {
      setServiceProviders(prevSPs =>
        _.uniqBy([...prevSPs, ...allServiceProviders?.data], 'id'),
      );
    }
  }, [allServiceProviders, isSuccessAllSPs]);

  useEffect(() => {
    if (
      isSuccessTradeAssigments &&
      !isFetchingTradeAssigments &&
      allServiceProviders &&
      !isFetchingAllSPs
    ) {
      const updatedFormFields = [...formFields];

      const servideProvidersField = updatedFormFields.find(
        field => field.fieldName === 'serviceProvider',
      );

      const assignedTradeSps = Array.from(
        new Map(
          tradeAssigmnents?.data
            .flatMap(d => d.sp)
            .map(sp => [
              sp['id'],
              {
                label: sp['name'],
                id: sp['id'],
                name: sp['name'],
                group: 'Assigned to this trade',
              },
            ]),
        ).values(),
      );

      const allServiceProvidersMapped = allServiceProviders?.data.map(sp => ({
        label: sp.name,
        name: sp.name,
        id: sp.id,
        group: 'All service providers',
      }));

      const uniqueServiceProviders = _.uniqBy(
        [...assignedTradeSps, ...allServiceProvidersMapped],
        'id',
      );

      if (uniqueServiceProviders?.length) {
        servideProvidersField.options = uniqueServiceProviders;

        servideProvidersField.optionValues = uniqueServiceProviders.map(
          sp => sp.id,
        );
      }

      setFormFields(updatedFormFields);
    }
  }, [
    tradeAssigmnents,
    isSuccessTradeAssigments,
    isFetchingTradeAssigments,
    allServiceProviders,
    isSuccessAllSPs,
    isFetchingAllSPs,
  ]);

  useEffect(() => {
    if (isOpen && masterPm) {
      const updatedFormFields = [...formFields];

      const tradeId = masterPm?.tradeId;

      if (tradeId) {
        trigger({
          id: tradeId,
          t: 0,
        });
      }

      const pmsCountLabelField = updatedFormFields.find(
        field => field.fieldName === 'pmCountLabel',
      );

      if (pmsCountLabelField) {
        pmsCountLabelField.label = `Locations Selected: (${selectedRows?.length})`;
      }

      const selectedPmsChipsField = updatedFormFields.find(
        field => field.fieldName === 'selectedPms',
      );

      if (selectedPmsChipsField) {
        selectedPmsChipsField.value =
          selectedRows?.map(d => ({
            label: d.id,
            color: 'Grey',
            id: d.id,
          })) || [];

        selectedPmsChipsField.readOnly = selectedRows?.length === 1;
      }

      const titleField = updatedFormFields.find(
        field => field.fieldName === 'title',
      );

      if (titleField) {
        titleField.value = masterPm?.name;
      }

      const assetGroupField = updatedFormFields.find(
        field => field.fieldName === 'assetGroup',
      );

      if (assetGroupField) {
        assetGroupField.value = masterPm?.assetType?.name;
      }

      const tradeField = updatedFormFields.find(
        field => field.fieldName === 'trade',
      );

      if (tradeField) {
        tradeField.value = masterPm?.trade?.name;
      }

      setFormFields(updatedFormFields);
    }
  }, [masterPm, isOpen, selectedRows]);

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (
        fieldName === 'priority' ||
        fieldName === 'nte' ||
        fieldName === 'serviceProvider' ||
        fieldName === 'description'
      ) {
        return;
      }

      if (fieldName === 'selectedPms') {
        const pmsCountLabelField = output.find(
          field => field.fieldName === 'pmCountLabel',
        );

        const selectedPmsField = output.find(
          field => field.fieldName === 'selectedPms',
        );

        if (pmsCountLabelField && selectedPmsField) {
          const selectedPms = Array.isArray(selectedPmsField.value)
            ? selectedPmsField.value.length
            : 0;

          if (selectedPms === 1) {
            selectedPmsField.readOnly = true;
          } else {
            selectedPmsField.readOnly = false;
          }

          const selectedPmsIds = Array.isArray(selectedPmsField.value)
            ? selectedPmsField.value.map((item: any) => item.id)
            : [];
          const selectedRowsFiltered = selectedRows?.filter((item: any) =>
            selectedPmsIds.includes(item.id),
          );
          onSelectedRowsChange?.(selectedRowsFiltered || []);

          pmsCountLabelField.label = `Locations Selected: (${selectedPms})`;
        }
      }

      // service frequency related fields
      const serviceFrequencyField = output.find(
        field => field.fieldName === 'serviceFrequency',
      );
      const woInAdvStartingParent = output.find(
        field => field.fieldName === 'woInAdvStartingDate',
      )?.subFields;

      const startingDateField = woInAdvStartingParent?.find(
        field => field.fieldName === 'startDate',
      );
      const woInAdvField = woInAdvStartingParent?.find(
        field => field.fieldName === 'createInAdvance',
      );

      if (
        fieldName === 'serviceFrequency' ||
        fieldName === 'startDate' ||
        fieldName === 'createInAdvance'
      ) {
        // if (serviceFrequencyField && startingDateField && woInAdvField) {
        //   serviceFrequencyField.required = true;
        //   startingDateField.required = true;
        //   woInAdvField.required = true;
        // }

        const woInAdvValue = parseInt(woInAdvField?.value as string);
        const serviceFrequencyValue =
          serviceFrequencyField?.value as ServiceFrequencyValueType;

        let woMinDateAdditionOnDaysBetweenService = 0;

        if (serviceFrequencyValue.type === SelectionType.DAY) {
          woMinDateAdditionOnDaysBetweenService = woInAdvValue || 0;
        } else {
          woMinDateAdditionOnDaysBetweenService = 0;
        }

        if (startingDateField) {
          startingDateField.minDate = moment()
            .add(2 + woMinDateAdditionOnDaysBetweenService, 'days')
            .format('YYYY-MM-DD');
        }

        const updatedDate = moment(startingDateField?.value as Date);

        if (
          startingDateField &&
          woInAdvValue &&
          serviceFrequencyValue.type === SelectionType.DAY &&
          moment(updatedDate).isBefore(moment(startingDateField.minDate))
        ) {
          startingDateField.value = moment(startingDateField.minDate).format(
            'YYYY-MM-DD',
          );
          startingDateField.minDate = moment()
            .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
            .format('YYYY-MM-DD');
        }

        const startingDateValue = updatedDate.format('YYYY-MM-DD');

        if (
          serviceFrequencyValue.type === SelectionType.MONTH &&
          serviceFrequencyValue &&
          serviceFrequencyValue.serviceMonths &&
          serviceFrequencyValue.dayOfMonth &&
          startingDateValue &&
          !isNaN(woInAdvValue)
        ) {
          const nextServiceDate = getNextServiceDate(
            moment(startingDateValue),
            serviceFrequencyValue,
            woInAdvValue,
          );
          const nextServiceDateField = woInAdvStartingParent?.find(
            field => field.fieldName === 'nextServiceDate',
          );
          if (nextServiceDateField && nextServiceDate) {
            nextServiceDateField.value = nextServiceDate;
          }
        } else if (
          serviceFrequencyValue.type === SelectionType.DAY &&
          serviceFrequencyValue &&
          serviceFrequencyValue.daysBetweenService &&
          startingDateValue &&
          !isNaN(woInAdvValue)
        ) {
          if (
            serviceFrequencyValue.daysBetweenService <= woInAdvValue ||
            woInAdvValue <= 0
          ) {
            if (woInAdvField) {
              woInAdvField.helperText = t(
                'translation:pages.preventiveMaintenance.workOrderInAdvanceError',
              );
              woInAdvField.value = null;
            }
          } else {
            const newStartingDate = moment(startingDateField?.value as Date);
            const nextServiceDate = getNextServiceDate(
              moment(newStartingDate),
              serviceFrequencyValue,
              woInAdvValue,
            );
            const nextServiceDateField = woInAdvStartingParent?.find(
              field => field.fieldName === 'nextServiceDate',
            );
            if (nextServiceDateField && nextServiceDate) {
              nextServiceDateField.value = nextServiceDate;
            }
            if (woInAdvField) {
              woInAdvField.helperText = '';
            }
          }
        }
      } else {
        if (serviceFrequencyField && startingDateField && woInAdvField) {
          serviceFrequencyField.required = false;
          startingDateField.required = false;
          woInAdvField.required = false;
          woInAdvField.value = null;
          startingDateField.value = null;
          serviceFrequencyField.value = null;
        }
      }

      setFormFields(output);
    },
    [isOpen],
  );

  const useBulkEditMutation = useCallback(() => {
    const bulkEditMutation = async data => {
      const pmItemIds = _.uniq(selectedRows?.map(item => item._originalId));
      const organizationIds = selectedRows?.map(
        item => item.branch.organizationId,
      );
      const startingDate = data?.startDate
        ? moment(data?.startDate).format('MM/DD/YYYY')
        : null;
      const body = {
        itemIds: pmItemIds || [],
        pmId: masterPm?.id,
        priorityId: data?.priority?.id || null,
        nte: data?.nte == 0 ? 0 : parseFloat(data?.nte) || null,
        startDate: startingDate,
        description: data?.description || null,
        spCompanyId: data?.serviceProvider?.id || null,
        daysBetweenService: data?.serviceFrequency?.daysBetweenService || null,
        serviceMonths:
          data?.serviceFrequency?.serviceMonths?.toString() || null,
        dayOfMonth: data?.serviceFrequency?.dayOfMonth || null,
        createInAdvance: data?.createInAdvance || null,
        organizationIds: organizationIds || [],
      };

      doUpdateBulkEdit(body);
    };

    return [
      bulkEditMutation,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: updateReset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    selectedRows,
    updateReset,
  ]);

  const handleClose = useCallback(() => {
    onClose?.();
    updateReset();
    setReset(true);
  }, [onClose, updateReset]);

  const initialFormFields = fancyFormElementsBulkEdit.fields;

  const resetFields = useCallback(() => {
    const fieldIndex = initialFormFields.findIndex(
      field => field.fieldName === 'serviceFrequency',
    );

    if (fieldIndex !== -1) {
      initialFormFields[fieldIndex] = {
        ...initialFormFields[fieldIndex],
        value: null,
        serviceMonths: null,
        dayOfMonth: null,
        daysBetweenService: null,
      };
    }
    setFormFields(initialFormFields);
    setForceRefreshKey(Date.now().toString());
  }, [reset]);

  useEffect(() => {
    if (reset) {
      resetFields();
      setReset(false);
      setForceRefreshKey(Date.now().toString());
    }
  }, [reset, resetFields, initialFormFields]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: StyleConstants.NAV_BAR_HEIGHT,
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={useBulkEditMutation}
          formConfig={fancyFormElementsBulkEdit.config}
          formFields={formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          onChange={handleOutputChange}
          key={forceRefreshKey}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
