import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { ECEasyFormCreate } from 'app/components';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { proposalApi, useCreateProposalMutation } from 'services/proposalApi';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { useGetPrioritiesListForSPQuery } from 'services/prioritiesApi';
import { useGetProblemListByCompanyIdAndAssetTypeIdQuery } from 'services/problemApi';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onClose }: Step2Props) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const prioritiesField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'priority',
    );
    prioritiesField.useQuery = useGetPrioritiesListForSPQuery;
    prioritiesField.queryParams = {
      sb: '',
      ob: '',
      moduleName: 'asset',
      moduleId: existingData?.asset?.id,
      companyId: existingData?.customer?.id,
    };

    const problemField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'problem',
    );
    problemField.useQuery = useGetProblemListByCompanyIdAndAssetTypeIdQuery;
    problemField.queryParams = {
      sb: '',
      ob: '',
      assetTypeId: existingData?.asset.assetTypeId,
      companyId: existingData?.customer?.id,
    };

    const filesField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'files',
    );
    filesField.required =
      !!existingData?.asset?.requiredFields?.proposalDocumentRequired;
  }, [existingData]);

  const [
    doCreateProposal,
    { data, isError, error, isSuccess, reset, isLoading },
  ] = useCreateProposalMutation();

  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(proposalApi.util.invalidateTags(['SPProposal']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `Proposal ${data.id} successfully created.`,
        }),
      );
    }
  }, [isSuccess, data]);

  const costTableData = useMemo(
    (): CostTableData => ({
      nte: existingData.nte,
      openZeroTotalInfo: openZeroTotalInfo,
      editingTotalCost: true,
      showIncurred: true,
      onClickAwayZeroTotalInfo: () => {
        if (openZeroTotalInfo) {
          setOpenZeroTotalInfo(false);
        }
      },
      itemsRepair: [
        {
          label: 'Labor',
          dataPath: 'costLabor',
        },
        {
          label: 'Material',
          dataPath: 'costMaterial',
        },
        {
          label: 'Tax',
          dataPath: 'costTax',
        },
        {
          label: 'Freight',
          dataPath: 'costFreight',
        },
        {
          label: 'Other',
          dataPath: 'costOther',
        },
        {
          label: 'Incurred',
          dataPath: 'Incurred',
          hidden: true,
        },
      ],
    }),
    [existingData.nte, openZeroTotalInfo],
  );

  const useCreateaMutation = useCallback(() => {
    const create = (proposal: any) => {
      const totalCost = costTableData
        ? sumCurrencyFields(
            ...costTableData?.itemsRepair.map(
              entry => proposal[entry.dataPath as string] ?? 0,
            ),
          )
        : 0;
      if (totalCost === 0) {
        const zeroInfoBox = document.querySelector('#ec-costtable-total');
        // If the total cost is not visible, scroll to the element for the pop-up to be visible
        if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
          zeroInfoBox?.scrollIntoView();
        }
        setOpenZeroTotalInfo(true);
        return;
      }

      const params = {
        id: existingData.asset.id,
        ...proposal,
      };

      if (existingData.asset.isGeneralAsset) {
        params.branch = { id: existingData.branch.id };
      }

      doCreateProposal(params);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isSuccess,
        reset,
        isLoading,
      },
    ];
  }, [
    data,
    isError,
    error,
    isSuccess,
    reset,
    isLoading,
    costTableData,
    existingData,
    doCreateProposal,
  ]);

  return (
    <ECEasyFormCreate
      useCreateMutation={useCreateaMutation}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={fancyFormElementsCreate.fields}
      existingData={existingData}
      costTableData={costTableData}
      isLoading={isLoading}
      showSuccessSnackBar={false}
    />
  );
};
