import { ECLoginTemplate } from 'app/components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

export function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Reset Password Page</title>
        <meta name="description" content="Lost you password?" />
      </Helmet>
      <ECLoginTemplate
        onGoBack={() => navigate('/')}
        onGoBackText={t('translation:pages.ForgotLogin.goBackButton')}
      >
        <Outlet />
      </ECLoginTemplate>
    </>
  );
}

export { default as ResetPasswordForm } from './ResetPasswordForm';
export { default as SendLinkForm } from './SendLinkForm';
