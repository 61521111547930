import { ECCalendarViewUnassignedTechPanel } from 'app/components/ECTable/ECCalendarViewUnassignedTechPanel';
import { WorkOrderCard } from 'types/WorkOrders';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DispatchWorkOrderCard } from './DispatchWorkOrdersCard';
import moment from 'moment';
import { ECBox } from 'app/components';
import { ECCalendarViewFilter } from 'app/components/ECTable/ECCalendarViewFilter';
import { DispatchSwimlaneBoard } from './DispatchSwimlaneBoard';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { setSnackbar } from 'store/slice/page';
import {
  useAssignTechMutation,
  useUpdateWorkOrderEtaMutation,
  workOrdersApi,
} from 'services/workOrdersApi';

function getOriginalContainerByWorkOrderId(
  techniciansWorkorders: Array<{
    id: number;
    assigneeFullName: string;
    dates: Array<{ date: string; workorders: Array<{ id: number }> }>;
  }>,
  workOrderId: number,
): string | null {
  const technician = techniciansWorkorders.find(technician =>
    technician.dates.some(date =>
      date.workorders.some(workorder => workorder.id === workOrderId),
    ),
  );

  if (technician) {
    const date = technician.dates.find(date =>
      date.workorders.some(workorder => workorder.id === workOrderId),
    );

    if (date) {
      return `${technician.id}|${technician.assigneeFullName}|${date.date}`;
    }
  }
  return null;
}

export const findContainer = (
  techniciansWorkorders: any[],
  unassignPanelData: any[],
  activeId: number,
) => {
  const activeTechId = techniciansWorkorders
    .flatMap(technician => technician.dates.flatMap(date => date.workorders))
    .find(item => item.id === activeId)?.id;

  return (
    activeTechId ||
    unassignPanelData.find(item => item.id === activeId)?.id ||
    activeId
  );
};

export const getWOCardById = (woCards: WorkOrderCard[], id: string) => {
  return woCards?.find(wo => Number(wo.id) === Number(id));
};
const unAssignedPanelName = 'unAssignedPanel';

function moveWorkorder(
  techniciansWorkorders,
  originalContainerId,
  destinationContainerId,
  workorder,
  newEtaDate,
  setTechniciansWorkorders,
) {
  if (destinationContainerId === unAssignedPanelName) {
    const updatedWorkorders = _.cloneDeep(techniciansWorkorders);
    const originalTechnician = updatedWorkorders.find(
      t => t.id === Number(originalContainerId),
    );

    if (!originalTechnician) return;

    originalTechnician.dates = originalTechnician.dates.map(dateObj => ({
      ...dateObj,
      workorders: dateObj.workorders.filter(wo => wo.id !== workorder.id),
    }));
    setTechniciansWorkorders(updatedWorkorders);
    return;
  }

  if (originalContainerId === unAssignedPanelName) {
    const updatedWorkorders = _.cloneDeep(techniciansWorkorders);
    const destinationTechnician = updatedWorkorders.find(
      t => t.id === Number(destinationContainerId),
    );
    if (!destinationTechnician) return;

    destinationTechnician.dates?.[0].workorders.push(workorder);

    setTechniciansWorkorders(updatedWorkorders);
    return;
  }

  if (originalContainerId === destinationContainerId) {
    const updatedWorkorders = _.cloneDeep(techniciansWorkorders);
    const technician = updatedWorkorders.find(
      t => t.id === Number(originalContainerId),
    );
    if (!technician) return;

    technician.dates.forEach(dateObj => {
      dateObj.workorders = dateObj.workorders.filter(
        wo => wo.id !== workorder?.id,
      );
    });
    let targetDateObj = technician.dates.find(d => d.date === newEtaDate);
    if (!targetDateObj) {
      targetDateObj = { date: newEtaDate, workorders: [] };
      technician.dates.push(targetDateObj);
    }
    targetDateObj.workorders.push(workorder);
    setTechniciansWorkorders(updatedWorkorders);
    return;
  } else {
    const updatedWorkorders = _.cloneDeep(techniciansWorkorders);
    const originalTechnician = updatedWorkorders.find(
      t => t.id === Number(originalContainerId),
    );
    if (!originalTechnician) return;

    originalTechnician.dates.forEach(dateObj => {
      dateObj.workorders = dateObj.workorders.filter(
        wo => wo.id !== workorder?.id,
      );
    });

    const destinationTechnician = updatedWorkorders.find(
      t => t.id === Number(destinationContainerId),
    );
    if (!destinationTechnician) return;
    const woDate = moment(workorder?.eta).format('YYYY-MM-DD');
    const destinationDateObj = destinationTechnician.dates.find(
      dateObj => dateObj.date === woDate,
    );

    if (destinationDateObj) {
      destinationDateObj.workorders.push(workorder);
    }
    setTechniciansWorkorders(updatedWorkorders);
    return;
  }
}

interface DispatchSwimlanesDNDProps {
  columnViewData: any;
  isSuccessColumnViewData: boolean;
  isLoadingColumnViewData: boolean;
  techniciansSelectedLength?: number;
}

export function DispatchSwimlanesDND({
  columnViewData,
  isSuccessColumnViewData,
  isLoadingColumnViewData,
  techniciansSelectedLength,
}: DispatchSwimlanesDNDProps): JSX.Element {
  const [unassignPanelData, setUnassignPanelData] = useState<any[]>([]);
  const [wOCards, setWOCards] = useState<any[]>([]);
  const [activeWOId, setActiveWOId] = useState<string | undefined>(undefined);
  const [techniciansWorkorders, setTechniciansWorkorders] = useState(
    columnViewData?.data,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (columnViewData?.data) {
      setTechniciansWorkorders(columnViewData?.data);
    }
  }, [columnViewData?.data]);

  const [
    doUpdateEta,
    {
      error: updateEtaError,
      isError: isUpdateEtaError,
      isLoading: isUpdateEtaLoading,
      isSuccess: isUpdateEtaSuccess,
      reset: resetUpdateEta,
    },
  ] = useUpdateWorkOrderEtaMutation();

  const [
    doUpdateAssignTech,
    {
      isSuccess: isUpdateTechAssignSuccess,
      isLoading: isUpdateTechAssignLoading,
      isError: isUpdateTechAssignError,
      reset: resetUpdateAssignTech,
    },
  ] = useAssignTechMutation();

  useEffect(() => {
    if (!isSuccessColumnViewData) return;

    const allWorkorders =
      techniciansWorkorders?.flatMap(technician =>
        technician.dates.flatMap(date => date.workorders || []),
      ) || [];

    const unAssignedPanelWOs = unassignPanelData || [];
    setWOCards([...unAssignedPanelWOs, ...allWorkorders]);
  }, [techniciansWorkorders, isSuccessColumnViewData]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleUnassignedWorkOrderDataUpdate = useCallback(
    (newData: any[]) => {
      setUnassignPanelData(newData);

      setWOCards(prevWOCards => {
        const newUniqueTasks = newData.filter(
          ({ id }) => !prevWOCards.some(prevItem => prevItem.id === id),
        );

        return newUniqueTasks.length
          ? [...prevWOCards, ...newUniqueTasks]
          : prevWOCards;
      });
    },
    [setUnassignPanelData, setWOCards],
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (isUpdateEtaLoading || isUpdateTechAssignLoading) return;

    setActiveWOId(active.id as string);
  };

  const [overPanelContainer, setOverPanelContainer] = useState<
    string | undefined
  >(undefined);

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    if (isUpdateEtaLoading || isUpdateTechAssignLoading) return;
    // Find the containers
    if (over?.id.toString().includes('|')) {
      setOverPanelContainer(over?.id as string);
    } else {
      const overContainer = getOriginalContainerByWorkOrderId(
        techniciansWorkorders,
        Number(over?.id),
      );
      setOverPanelContainer(overContainer ?? undefined);
    }
  };
  const wo = activeWOId ? getWOCardById(wOCards, activeWOId) : null;

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (isUpdateEtaLoading || isUpdateTechAssignLoading) return;

    setOverPanelContainer(undefined);
    if (over?.id) {
      const [destinationContainerId, , newEtaDate] =
        over?.id.toString().split('|') || [];
      const woEta = moment(wo?.eta).format('YYYY-MM-DD');

      const originalContainer = wo
        ? getOriginalContainerByWorkOrderId(techniciansWorkorders ?? [], wo.id)
        : null;
      const originalContainerId = originalContainer?.split('|')?.[0];
      const isSameContainer = originalContainerId === destinationContainerId;
      const isNotUnassigned = over?.id.toString() !== unAssignedPanelName;

      if (
        wo &&
        woEta !== newEtaDate &&
        isSameContainer &&
        isNotUnassigned &&
        originalContainerId
      ) {
        // If the ETA date has changed
        moveWorkorder(
          techniciansWorkorders,
          originalContainerId,
          destinationContainerId,
          wo,
          newEtaDate,
          setTechniciansWorkorders,
        );
        doUpdateEta({ id: wo.id, eta: newEtaDate });
      }

      if (wo && !isSameContainer && isNotUnassigned && originalContainerId) {
        // If the workorder has been moved to another technician
        moveWorkorder(
          techniciansWorkorders,
          originalContainerId,
          destinationContainerId,
          wo,
          newEtaDate,
          setTechniciansWorkorders,
        );
        doUpdateAssignTech({
          id: wo.id,
          spAssigneeUserId: Number(destinationContainerId),
        });
      }

      if (wo && isNotUnassigned && !originalContainerId) {
        // If the workorder has been moved to a technician from unassigned
        moveWorkorder(
          techniciansWorkorders,
          unAssignedPanelName,
          destinationContainerId,
          wo,
          newEtaDate,
          setTechniciansWorkorders,
        );
        doUpdateAssignTech({
          id: wo.id,
          spAssigneeUserId: Number(destinationContainerId),
        });
      }
    }
    setActiveWOId(undefined);
  };

  const handleSuccess = useCallback(
    (message: string, resetFunction: () => void) => {
      dispatch(
        setSnackbar({
          severity: 'success',
          message,
        }),
      );
      dispatch(
        workOrdersApi.util.invalidateTags([
          'DispatchColumn',
          'DispatchColumnUnassigned',
        ]),
      );
      resetFunction();
    },
    [],
  );

  const handleError = useCallback(() => {
    dispatch(
      workOrdersApi.util.invalidateTags([
        'DispatchColumn',
        'DispatchColumnUnassigned',
      ]),
    );
    resetUpdateEta();
    resetUpdateAssignTech();
  }, []);

  useEffect(() => {
    if (isUpdateEtaSuccess) {
      handleSuccess('ETA was successfully updated.', resetUpdateEta);
    }
  }, [isUpdateEtaSuccess, handleSuccess]);

  useEffect(() => {
    if (isUpdateTechAssignSuccess) {
      handleSuccess(
        'Work Order was successfully reassigned.',
        resetUpdateAssignTech,
      );
    }
  }, [isUpdateTechAssignSuccess, handleSuccess]);

  useEffect(() => {
    if (isUpdateEtaError || isUpdateTechAssignError) {
      handleError();
      setTechniciansWorkorders(columnViewData?.data);
    }
  }, [isUpdateEtaError, isUpdateTechAssignError, handleError]);

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <ECBox
        display="flex"
        flexDirection="row"
        width={'100%'}
      >
        <ECBox
          flex={4}
          maxWidth={'82%'}
          sx={{
            position: 'relative',
          }}
        >
          <ECCalendarViewFilter />
          <DispatchSwimlaneBoard
            technicians={techniciansWorkorders || []}
            dragPanelId={overPanelContainer}
            isLoading={isLoadingColumnViewData}
            techniciansSelectedLength={techniciansSelectedLength}
          />
        </ECBox>
        <ECBox
          flex={1}
          sx={{
            marginTop: '13px',
          }}
        >
          <ECCalendarViewUnassignedTechPanel
            onUnassignedWorkOrderDataUpdate={
              handleUnassignedWorkOrderDataUpdate
            }
            isObjectDraged={!!activeWOId}
            dragPanelId={overPanelContainer}
          />
        </ECBox>
      </ECBox>
      <DragOverlay dropAnimation={dropAnimation}>
        {wo ? (
          <DispatchWorkOrderCard
            workOrder={wo}
            dragPanelId={overPanelContainer}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
