import { Address } from './Address';
import { Company, CompanyType, Contact } from './Company';
import { CoreAssetType } from './CoreAssetType';
import { FlagBase } from './Flag';
import { QueryParams } from './QueryParams';

export interface ServiceProviderBase {
  id: number;
  name: string;
  email: string;
  phone: string;
  tradesOrRegions: boolean;
  ratesOrTerms: boolean;
  requiredDocs: boolean;
  city: string;
  state: string;
  flags: FlagBase[];
  type: string;
  companyId: number;
  company: Company;
}
export interface ServiceProviderDirectoryTypes {
  serviceProviderId: number;
  serviceProviderName: string;
  tradeType: string;
  workOrdersCompleted: number;
  workOrdersCompletedInArea: number;
  totalCustomers: number;
  rating: number;
  tradeArea: ServiceProviderTradeArea[];
  email: string;
}

export interface ServiceProviderTradeArea {
  trades: number;
  area: string;
}

export interface ServiceProdiverDirectorySearchParams {
  tradeId: number;
  city?: string;
  state?: string;
  country?: string;
  name?: string;
}

export interface ServiceProdiverDirectoryTradeAreasSearchParams {
  id: number;
}

export interface ServiceProviderTypes {
  id: number;
  name: string;
  logo?: string;
  addressId?: number;
  type?: number;
  viewId?: string;
}

export interface ServiceProviderQueryParams extends QueryParams {
  problemTypeId?: number;
}

export interface ServiceProvider {
  id: number;
  address?: Address;
  addressId?: number;
  billingAddress?: string;
  billingAddressId?: number;
  companyType?: CompanyType;
  companyTypeId?: number;
  email?: string;
  isBillingSameAdAddress?: boolean;
  logo?: string;
  name: string;
  phone?: string;
  phone24?: string;
  phone24Ext?: string;
  phoneExt?: string;
  flags?: FlagBase[];
  status?: number;
  vendorId?: number;
  spProfile: ServiceProviderProfile;
  contacts: Contact[];
  contact: Contact;
}

export interface ServiceProviderDetails {
  company: Company;
  serviceProvider: ServiceProvider;
  flags?: FlagBase[];
  id: number;
  moduleName?: string;
  status?: number;
  vendorId?: number;
  spCompanyId: number;
  internalTech?: number;
  spType?: 'Third-party' | 'Internally Managed' | 'Internal Tech';
}

export interface ServiceProviderConfiguration {
  id: number;
  excludeInvoiceFromReport: number;
  internalTech: number;
  serviceRequestPopup: number;
  workflowEnforcement: number | string;
}

export interface ServiceProviderInvitation {
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
  id: number;
  spId: number;
  companyId: number;
  status: number;
  spName: string;
  spMail: string;
  spPhone: number;
  city: string;
  state: string;
  invitedSP: ServiceProviderInvited;
}

interface ServiceProviderInvited {
  addressId: number;
  address: Address;
}

export interface ServiceProviderProfile {
  companyId: number;
  finishedWorkOrderCount: number;
  finishedWorkOrderRating: string;
  totalCustomers: number;
  internal: number;
  spType: {
    code: string;
    name: string;
    id: number;
  };
  spTypeId?: number;
}

export interface ServiceProviderInvitationContact {
  name?: string;
  email?: string;
  phone?: string;
}

export interface ServiceProviderInvitationCreate {
  spEmail: string;
  spName: string;
  spPhone?: string;
  spPhoneExt?: string;
  smsNotification?: number;
  vendorId?: string;
  spId?: number;
  contacts?: ServiceProviderInvitationContact[];
}

export interface ServiceProviderDetailsUpdate {
  id: number;
  vendorId: string;
  status: number;
  flagsIds: number[];
  excludeInvoiceFromReport?: number;
  internalTech?: number;
  serviceRequestPopup?: number;
  workflowEnforcement?: number | string;
}

export interface MaterialMarkup {
  id: number;
  percentage: number;
  range: number;
  rateAndTermsId: number;
}

export interface OperationHoursType {
  id: number;
  name: string;
}

export interface OperationHours {
  endTimeHour: number;
  endTimeMinute: number;
  id: number;
  rateAndTermsId: number;
  startTimeHour: number;
  startTimeMinute: number;
  type: OperationHoursType;
  typeId: number;
}

export interface ServiceProviderRatesAndTerms {
  billingType?: string;
  createdAt?: Date;
  createdBy?: Date;
  currency?: string;
  customerId?: number;
  fein?: string;
  helperDoubleTime?: number;
  helperOverTime?: number;
  helperRegularTime?: number;
  helperTravelTime?: number;
  helperTripCharge?: number;
  helperTruckCharge?: number;
  id: number;
  journeymanDoubleTime?: number;
  journeymanOverTime?: number;
  journeymanRegularTime?: number;
  journeymanTravelTime?: number;
  journeymanTripCharge?: number;
  journeymanTruckCharge?: number;
  partTerms?: string;
  paymentTerms?: string;
  rateConfigName?: string;
  spCompanyId?: number;
  updatedAt?: Date;
  updatedBy?: Date;
  materialMarkup?: MaterialMarkup[];
  operationHours?: OperationHours[];
  operationHoursFormatted?: any[];
  paymentTermsAmount?: number;
  paymentTermsUnit?: TermsUnit;
  partsTermsAmount?: number;
  partsTermsUnit?: TermsUnit;
  laborTermsAmount?: number;
  laborTermsUnit?: TermsUnit;
  paymentTermsUnitDescription?: string;
  partsTermsUnitDescription?: string;
  laborTermsUnitDescription?: string;
  preferredPayment?: string;
}

export interface ServiceProviderTradeRegion extends Address {
  id: number;
  companyId: number;
  coreTrade: CoreAssetType;
  selfPerform: string;
}

export interface Region {
  serviceArea: string;
  selfPerforming: number;
}

export interface TradeRegion {
  id: number;
  name: string;
  regions: Region[];
  viewId: string;
  regionsQuantity: number;
}

export interface TradeRegionCreateItem {
  tradeId: number;
  selfPerform: number;
  zipCodePostalCode?: string;
  countryCode: string;
  stateProvinceCode?: string;
  cityName?: string;
  status?: number;
  companyId: number;
}

export interface TradeRegionCreateDTO {
  spId: number;
  tradeRegions: TradeRegionCreateItem[];
}

export interface SPRateAndTermsUpdateDTO {
  id?: number;
  spCompanyId?: number;
  customerId?: number;
  currency?: string;
  journeymanRegularTime?: number;
  journeymanOverTime?: number;
  journeymanDoubleTime?: number;
  journeymanTravelTime?: number;
  journeymanTripCharge?: number;
  journeymanTruckCharge?: number;
  helperRegularTime?: number;
  helperOverTime?: number;
  helperDoubleTime?: number;
  helperTravelTime?: number;
  helperTripCharge?: number;
  helperTruckCharge?: number;
  rateConfigName?: string;
  billingType?: string;
  partTerms?: string;
  fein?: string;
  paymentTermsUnitCode?: string;
  paymentTermsAmount?: number;
  preferredPayment?: string;
  laborTerms?: string;
  additionalInformation?: string;
  materialMarkup?: MaterialMarkupDTO[];
  operationHours?: OperationHoursDTO[];
}

export interface MaterialMarkupDTO {
  percentage: number;
  range: number;
}

export interface OperationHoursDTO {
  typeId: number;
  startTimeHour: number;
  startTimeMinute: number;
  endTimeHour: number;
  endTimeMinute: number;
}

export interface TermsUnit {
  code: string;
  description: string;
}

export enum InvitationStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
}

export interface ValidateInvitationCodeResponse {
  companyEmail: string;
  companyName: string;
  companyPhone: string;
  companyPhoneExt?: string;
  inviteCode: string;
}

export interface CreateSPParams {
  addToSPDirectory: boolean;
  address: Address;
  billingAddress?: Address;
  remittance: {
    email: string;
    phone: string;
    phoneExt: string;
  };
  contact?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    phoneExt: string;
    address?: Address;
  };
  fein: string;
  dba: string;
  sicId: number;
  industryId: number;
  federalTaxClassificationId?: number;
  inviteCode: string;
  companyName: string;
  companyEmail: string;
  phone: string;
  phoneExt: string;
  phone24: string;
  phone24Ext: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
