import { Download } from '@mui/icons-material';
import { ECAlert, ECBox, ECButton, ECIconButton } from 'app/components';
import { useCallback } from 'react';
import { useLazyDownloadAttachmentQuery } from 'services/attachmentApi';
import { Attachment, AttachmentCategory } from 'types/Attachment';

interface Props {
  attachment: Attachment;
  onView: (attachment: Attachment) => void;
  moduleName: string;
}

export const AttachmentsListPopoverItem = ({
  attachment,
  onView,
  moduleName,
}: Props) => {
  const [triggerDownload, { isLoading: isLoadingDownloadAttachment }] =
    useLazyDownloadAttachmentQuery();

  const handleDownloadAttachment = useCallback(() => {
    if (attachment?.id) {
      triggerDownload({
        module: moduleName,
        moduleId: attachment.id,
        category: AttachmentCategory.Photo,
        fileName: attachment.originalName || attachment.fileName,
      });
    }
  }, [triggerDownload]);

  return (
    <ECAlert
      variant="outlined"
      icon={<></>}
      severity="success"
      sx={{ '.MuiAlert-message': { width: '100%' } }}
    >
      <ECBox
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        {attachment.originalName}

        <ECBox display="flex" gap={2}>
          <ECButton
            variant="text"
            sx={theme => ({
              color: `${theme.palette.success.dark} !important`,
            })}
            onClick={() => onView(attachment)}
          >
            View
          </ECButton>

          <ECIconButton
            size="medium"
            type="success"
            onClick={handleDownloadAttachment}
            isLoading={isLoadingDownloadAttachment}
          >
            <Download />
          </ECIconButton>
        </ECBox>
      </ECBox>
    </ECAlert>
  );
};
