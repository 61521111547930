import { QueryParams } from 'types/QueryParams';
import {
  AssetProblemTroubleshoot,
  assetProblemTroubleshootBody,
  assetProblemTroubleshootEdit,
  assetProblemTroubleshootUpdate,
} from 'types/AssetProblemTroubleshoot';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['assetProblemTroubleshoot'],
});

export const assetProblemTroubleshootApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetProblemTroubleshoot: builder.query<
      BaseType<AssetProblemTroubleshoot[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'asset-group/problem/troubleshoot',
          params,
        };
      },
      providesTags: ['assetProblemTroubleshoot'],
    }),
    addAssetProblemTroubleshoot: builder.mutation<
      void,
      assetProblemTroubleshootBody[]
    >({
      query: body => {
        return {
          url: `asset-group/problem/troubleshoot`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['assetProblemTroubleshoot'],
    }),
    editAssetProblemTroubleshoot: builder.mutation<
      void,
      assetProblemTroubleshootUpdate
    >({
      query: body => {
        return {
          url: `asset-group/problem/troubleshoot`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['assetProblemTroubleshoot'],
    }),
    deleteAssetProblemTroubleshoot: builder.mutation<
      void,
      assetProblemTroubleshootEdit
    >({
      query: params => {
        return {
          url: `asset-group/problem/troubleshoot`,
          method: 'delete',
          body: params.body,
        };
      },
      invalidatesTags: ['assetProblemTroubleshoot'],
    }),
  }),
});

export const {
  useGetAssetProblemTroubleshootQuery,
  useLazyGetAssetProblemTroubleshootQuery,
  useAddAssetProblemTroubleshootMutation,
  useDeleteAssetProblemTroubleshootMutation,
  useEditAssetProblemTroubleshootMutation,
} = assetProblemTroubleshootApi;
