import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useEffect, useState } from 'react';
import { useCreateAssetTypeToNameMutation } from 'services/assetTypeToNameApi';
import { AssetTypeToNameCreate } from 'types/AssetTypeToName';
import { BaseType } from 'types/BaseType';
import { Locations } from 'types';

interface CopyProps {
  row: any;
  branches?: BaseType<Locations[]>;
  onClose?: () => void;
}

const formConfig = require('./fancy_form_config_copy.json');

export const Copy = ({ row, branches, onClose }: CopyProps) => {
  const [formFields, setFormFields] = useState(formConfig.fields);
  const [rowCopy, setRowCopy] = useState(row);

  useEffect(() => {
    if (branches) {
      let editSelectField = formConfig.fields.find(
        field => field.fieldName === 'branch',
      );

      editSelectField.options =
        branches.data.map(d => ({ id: d.id, label: d.name })) || [];
      editSelectField.optionValues = branches.data.map(d => d.name) || [];
    }
  }, [branches]);

  useEffect(() => {
    if (row) {
      setRowCopy({ ...row, branch: [] });
      const newFields = formConfig.fields.map(formField => {
        if (formField.fieldName === 'assetTypeName') {
          return {
            ...formField,
            value: row?.assetTypeName,
            options: [row?.assetTypeName],
            optionValues: [row?.assetTypeName],
          };
        } else if (formField.fieldName === 'branchType') {
          return {
            ...formField,
            value: row?.branchType,
            options: [row?.branchType],
            optionValues: [row?.branchType],
          };
        } else if (formField.fieldName === 'assetTemplateNames') {
          return {
            ...formField,
            chips: row?.assetTemplateNames,
          };
        }
        return formField;
      });
      setFormFields(newFields);
    }
  }, [row]);

  const useUpdate = () => {
    const [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ] = useCreateAssetTypeToNameMutation();

    const doUpdate = async data => {
      if (!row) {
        return;
      }

      let assetGroupAssetNameData: AssetTypeToNameCreate[] = [];
      data?.assetTemplateNames?.forEach((assetName: string) => {
        data?.branch?.forEach(branch => {
          const newAssetGroupAssetName: AssetTypeToNameCreate = {
            name: assetName,
            assetTypeId: row?.assetTypeId,
            branchTypeId: branch?.id,
            status: 1,
          };
          assetGroupAssetNameData.push(newAssetGroupAssetName);
        });
      });

      if (assetGroupAssetNameData.length > 0) {
        await doCreate(assetGroupAssetNameData);
      }
    };

    return [
      doUpdate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess || isCreateSuccess,
      },
    ];
  };

  return (
    <EditForm2
      row={rowCopy}
      formConfig={formConfig.config}
      formFields={formFields}
      useUpdateMutation={useUpdate}
      onClose={onClose}
      isLoading={false}
      hideToolBar
      onlyEdit
    />
  );
};
