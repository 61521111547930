import Breadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';

export const ECBreadcrumbs = styled(Breadcrumbs)<BreadcrumbsProps>(
  ({ theme }) => ({}),
);

export const ECPanelBreadcrumbs = props => {
  // let match = useRouteMatch();
  let location = useLocation();

  const packageJson = require('../../../../package.json');

  return (
    <ECBreadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      {location.pathname.split('/').map((path, index) => {
        return (
          <Link
            key={`${index}bread-crumb`}
            underline="hover"
            color="inherit"
            href={
              packageJson.homepage.substring(
                0,
                packageJson.homepage.length - 1,
              ) +
              [...location.pathname.split('/').splice(0, index), path].join('/')
            }
          >
            {path}
          </Link>
        );
      })}
    </ECBreadcrumbs>
  );
};
