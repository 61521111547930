import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECEasyFormFieldType, ECWorkflowTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from 'services/profileApi';
import {
  useGetIndustriesQuery,
  useGetSICsQuery,
  useGetTaxClassificationsQuery,
} from 'services/lookupApi';
import _ from 'lodash';
import { equalCustomizer } from 'utils/validate-fields';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { flattenFormFields } from 'utils/form';
import { useGetSignUpRequiredFieldsQuery } from 'services/spAuthApi';
import { fi } from 'date-fns/locale';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

const REQUIRED_FIELDS_PATH = {
  industry: 'industry',
  sic: 'sic',
  federalTaxClassification: 'federalTaxClassification',
  fein: 'fein',
  dba: 'dba',
  remittanceEmail: 'emailRemittance',
  remittancePhone: 'phoneRemittance',
  contactFirstName: 'contact.firstName',
};

export function SPProfileDetailsPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: 'profileDetail' };

  const [validations, setValidations] = useState<any>([]);
  const [editFields, setEditFields] = useState(
    fancyFormElementsEdit.data.fields,
  );
  const [detailsFields, setDetailsFields] = useState(
    formElementsDetails.data.fields,
  );
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAllow, setIsEditingAllow] = useState(true);
  const [disableChangeTab, setDisableChangeTab] = useState(false);
  const navigate = useNavigate();

  const { data: companyDetails, isSuccess } = useGetProfileQuery();
  const { data: taxClassifications } = useGetTaxClassificationsQuery();

  const { data: requiredFields } = useGetSignUpRequiredFieldsQuery(
    companyDetails?.id || 0,
    {
      skip: !companyDetails?.id,
    },
  );

  useEffect(() => {
    if (from === 'customerDetailPage') {
      setIsEditing(true);
    }
  }, [from, setIsEditing]);

  useEffect(() => {
    const disallowedPaths = ['trades-and-regions', 'attachments', 'activity'];
    const isEditingDisallowed = disallowedPaths.some(path =>
      location?.pathname?.includes(path),
    );
    setIsEditingAllow(!isEditingDisallowed);
  }, [location?.pathname, setIsEditingAllow]);

  useEffect(() => {
    const isDetailsTab =
      !window.location.href.includes('rates-and-terms') &&
      !window.location.href.includes('attachments') &&
      !window.location.href.includes('trades-and-regions') &&
      !window.location.href.includes('activity');

    const isDetailOrRatesPage =
      isDetailsTab ||
      location.pathname.includes('details') ||
      location.pathname.includes('rates-and-terms');
    setDisableChangeTab(isDetailOrRatesPage && isEditing);
  }, [location.pathname, isEditing, setDisableChangeTab]);

  const initializeFields = useCallback(() => {
    const addressFields = {
      line1: companyDetails?.address?.line1,
      line2: companyDetails?.address?.line2,
      zipCodePostalCode: companyDetails?.address?.zipCodePostalCode,
      cityName: companyDetails?.address?.cityName,
      stateProvinceCode: companyDetails?.address?.stateProvinceCode,
      countryCode: companyDetails?.address?.countryCode,
    };

    const billingAddressFields =
      companyDetails?.isBillingSameAsAddress?.includes(
        'Use same for Billing Address',
      ) && !companyDetails.billingAddress
        ? addressFields
        : companyDetails?.billingAddress;

    setSummaryFieldsValues({
      name: companyDetails?.name,
      dba: companyDetails?.dba,
      federalTaxClassification: companyDetails?.federalTaxClassification,
      fein: companyDetails?.fein,
    });

    const newFields = _.clone(fancyFormElementsEdit.data.fields) || [];

    newFields.forEach(fieldToCopy => {
      if (!fieldToCopy || fieldToCopy.type === 'group') return;
      const updatedFieldIndex = newFields?.findIndex(
        field => field.fieldName === fieldToCopy.fieldName,
      );

      if (updatedFieldIndex !== -1) {
        newFields[updatedFieldIndex] = {
          ...(fieldToCopy as ECEasyFormFieldType),
          value: _.get(companyDetails, fieldToCopy.fieldName),
        };

        if (
          fieldToCopy.fieldName &&
          fieldToCopy.fieldName.startsWith('billingAddress') &&
          billingAddressFields
        ) {
          newFields[updatedFieldIndex].value = _.get(
            billingAddressFields,
            fieldToCopy.fieldName.replace('billingAddress.', ''),
          );
        }

        if (
          !isEditing &&
          companyDetails?.isBillingSameAsAddress?.includes(
            'Use same for Billing Address',
          )
        ) {
          newFields[updatedFieldIndex].readOnly = true;
        } else {
          newFields[updatedFieldIndex].readOnly = false;
        }
      }

      if (isEditing) {
        const sameBillingCheckbox = newFields.find(
          field => field.fieldName === 'isBillingSameAsAddress',
        );
        if (sameBillingCheckbox) {
          sameBillingCheckbox.disabled = false;
          sameBillingCheckbox.readOnly = false;
        }
      }
    });

    const fields = flattenFormFields(newFields);

    const sicField = fields.getField('sic');
    sicField.useQuery = useGetSICsQuery;
    sicField.obAlias = 'sicid.code';

    const industryField = fields.getField('industry');
    industryField.useQuery = useGetIndustriesQuery;
    industryField.obAlias = 'indtry.name';

    const contactDetails = companyDetails?.contact || {};
    const contactAddressDetails = companyDetails?.contact?.address || {};
    Object.keys(contactDetails).forEach(fieldName => {
      const field = fields.getField('contact.' + fieldName);
      if (field) field.value = contactDetails[fieldName];
    });

    Object.keys(contactAddressDetails).forEach(fieldName => {
      const field = fields.getField('contactAddress.' + fieldName);
      if (field) field.value = contactAddressDetails[fieldName];
    });

    if (isEditing) {
      const postalCodeFields = newFields.filter(field =>
        field?.fieldName?.includes('address.zipCodePostalCode'),
      );

      postalCodeFields.forEach(postalCodeField => {
        postalCodeField.required = !(
          companyDetails?.address?.countryCode?.toLowerCase() === 'jm'
        );
      });

      const postalCodeFieldsBilling = newFields.filter(field =>
        field?.fieldName?.includes('billingAddress.zipCodePostalCode'),
      );

      postalCodeFieldsBilling.forEach(postalCodeField => {
        postalCodeField.required = !(
          companyDetails?.billingAddress?.countryCode?.toLowerCase() === 'jm'
        );
      });

      const allFields = flattenFormFields(newFields);

      for (const key of Object.keys(REQUIRED_FIELDS_PATH)) {
        const fieldPath = REQUIRED_FIELDS_PATH[key];
        const field = allFields.getField(fieldPath);

        if (!field) continue;

        if (key.startsWith('contact')) {
          const contactIsRequired = requiredFields?.includes('contact');
          if (contactIsRequired) {
            allFields.fields
              .filter(
                f =>
                  f.key.startsWith('contact') &&
                  f.key !== 'contactAddress.line2',
              )
              .forEach(f => {
                f.field.required = true;
              });
          }

          continue;
        }

        field.required = requiredFields?.includes(key);
      }
    }

    setEditFields([...newFields]);
  }, [requiredFields, companyDetails, isEditing]);

  useEffect(() => {
    initializeFields();
  }, [companyDetails, isEditing]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues({ ...summaryFieldsValues, [fieldName]: value });
  };

  const [
    doUpdateSPDetails,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateProfileMutation();

  const useUpdateSPProfile = useCallback(() => {
    const doUpdate = async data => {
      const summaryFederalTaxClassId =
        summaryFieldsValues?.federalTaxClassification?.data ||
        summaryFieldsValues?.federalTaxClassification?.id;

      let validation: any = [];

      if (!summaryFieldsValues.name?.trim()) {
        validation.push({
          field: 'name',
          validationMessage: 'Company Name is required!',
        });
      }

      if (!summaryFieldsValues.dba) {
        validation.push({
          field: 'dba',
          validationMessage: 'DBA is required!',
        });
      }

      if (!summaryFederalTaxClassId) {
        validation.push({
          field: 'federalTaxClassification',
          validationMessage: 'Federal Tax Classification is required!',
        });
      }

      if (!summaryFieldsValues.fein || summaryFieldsValues.fein.length < 9) {
        validation.push({
          field: 'fein',
          validationMessage:
            'Please provide a valid FEIN with 9 digits (numbers only)',
        });
      }
      if (validation.length > 0) {
        setValidations(validation);
        return false;
      } else {
        setValidations([]);
      }

      delete data.isBillingSameAsAddress;

      const newAddress: any = Object.keys(data?.address).reduce((prev, key) => {
        if (
          _.isEqualWith(
            companyDetails?.address?.[key],
            data?.address[key],
            equalCustomizer,
          ) ||
          data?.address[key] === undefined
        ) {
          return {
            ...prev,
          };
        }
        return {
          ...prev,
          [key]: data?.address[key],
        };
      }, {});

      data.deleteAddress = Boolean(
        !data?.address?.line1 && companyDetails?.address?.line1,
      );
      if (Object.keys(newAddress).length === 0) {
        delete data.address;
      } else if (Array.isArray(data.address.line1)) {
        data.address.line1 = data.address.line1.filter(
          line => line !== 'Use same for Billing Address',
        );
      }

      const newBillingAddress: any = Object.keys(data?.billingAddress).reduce(
        (prev, key) => {
          if (
            _.isEqualWith(
              companyDetails?.billingAddress?.[key],
              data?.billingAddress[key],
              equalCustomizer,
            ) ||
            data?.billingAddress[key] === undefined
          ) {
            return {
              ...prev,
            };
          }
          return {
            ...prev,
            [key]: data?.billingAddress[key],
          };
        },
        {},
      );

      data.deleteBillingAddress = Boolean(
        !data?.billingAddress?.line1 && companyDetails?.billingAddress?.line1,
      );
      if (Object.keys(newBillingAddress).length === 0) {
        delete data.billingAddress;
      } else if (Array.isArray(data.billingAddress.line1)) {
        data.billingAddress.line1 = data.billingAddress.line1.filter(
          line => line !== 'Use same for Billing Address',
        );
      }

      const updateDto = {
        ...data,
      };
      if (summaryFieldsValues?.name !== companyDetails?.name) {
        updateDto['name'] = summaryFieldsValues?.name.trim();
      }
      // add fein to payload
      updateDto['fein'] = summaryFieldsValues?.fein;

      if (summaryFieldsValues?.dba !== companyDetails?.dba) {
        updateDto['dba'] = summaryFieldsValues?.dba;
      }
      if (
        summaryFederalTaxClassId &&
        summaryFederalTaxClassId !== companyDetails?.federalTaxId
      ) {
        updateDto['federalTaxId'] =
          summaryFieldsValues?.federalTaxClassification.data;
      }

      if (data.sic?.id > 0) updateDto.sic = { id: data.sic?.id };
      else delete updateDto['sic'];
      if (data.industry?.id > 0) updateDto.industry = { id: data.industry?.id };
      else delete updateDto['industry'];
      updateDto.contact = { ...data.contact, address: data.contactAddress };
      delete updateDto['contactAddress'];

      if (
        Array.isArray(updateDto?.address?.countryCode) &&
        updateDto?.address?.countryCode?.length > 0
      ) {
        updateDto.address.countryCode = updateDto.address.countryCode[0];
      }

      if (
        Array.isArray(updateDto?.billingAddress?.countryCode) &&
        updateDto?.billingAddress?.countryCode?.length > 0
      ) {
        updateDto.billingAddress.countryCode =
          updateDto.billingAddress.countryCode[0];
      }

      if (Object.keys(updateDto).length <= 1) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: t('translation:dynamicForm.updateNothing'),
          }),
        );
      } else {
        setIsEditing(false);
        doUpdateSPDetails(updateDto);
      }
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: false,
        reset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    summaryFieldsValues,
    reset,
    doUpdateSPDetails,
  ]);

  useEffect(() => {
    if (isUpdateSuccess) {
      navigate('rates-and-terms');
    }

    if (isUpdateSuccess || isUpdateError) {
      setIsEditing(true);
      setDisableChangeTab(true);
    }
  }, [isUpdateSuccess, isUpdateError]);

  const handleChange = (output: ECEasyFormFieldType[]) => {
    const isBillingSameAsAddressCheckboxSelected = (
      output.find(field => field.fieldName === 'isBillingSameAsAddress')
        ?.value as string[]
    )?.includes('Use same for Billing Address');

    const newFields = _.clone(output) || [];

    const billingAddressFields = newFields.filter(field =>
      field?.fieldName?.includes('billingAddress'),
    );

    const addressCountryCode = newFields?.find(
      data => data?.fieldName === 'address.countryCode',
    );

    if (
      isBillingSameAsAddressCheckboxSelected &&
      Array.isArray(addressCountryCode?.value) &&
      addressCountryCode?.value?.length > 0
    ) {
      addressCountryCode.value = addressCountryCode.value[0];
    }

    if (isBillingSameAsAddressCheckboxSelected) {
      billingAddressFields.forEach(fieldToCopy => {
        const updatedFieldIndex = newFields?.findIndex(
          field => field.fieldName === fieldToCopy.fieldName,
        );

        const fieldValue = newFields?.find(
          field =>
            field.fieldName ===
            fieldToCopy.fieldName.replace('billingAddress', 'address'),
        )?.value;

        newFields[updatedFieldIndex] = {
          ...(fieldToCopy as ECEasyFormFieldType),
          value:
            Array.isArray(fieldValue) && fieldValue?.length > 0
              ? fieldValue?.[0]
              : fieldValue,
          overrideRowValue: true,
          disabled: true,
          required: false,
        };
      });
    } else {
      billingAddressFields.forEach(fieldToCopy => {
        const updatedFieldIndex = newFields?.findIndex(
          field => field.fieldName === fieldToCopy.fieldName,
        );

        newFields[updatedFieldIndex] = {
          ...(fieldToCopy as ECEasyFormFieldType),
          overrideRowValue: false,
          disabled: false,
        };
      });
    }

    const contactEmail = output.find(
      field => field.fieldName === 'contact.email',
    );
    if (contactEmail) contactEmail.checkEmail = !!contactEmail?.value;

    let countryCode =
      newFields?.find(data => data?.fieldName === 'address.countryCode')
        ?.value || companyDetails?.address?.countryCode;

    const postalCodeFields = newFields.filter(field =>
      field?.fieldName?.includes('address.zipCodePostalCode'),
    );

    countryCode = Array.isArray(countryCode)
      ? countryCode[0]
      : countryCode?.value || countryCode;

    if (countryCode)
      postalCodeFields.forEach(postalCodeField => {
        postalCodeField.required = !(countryCode?.toLowerCase() === 'jm');
      });

    let countryCodeBilling =
      newFields?.find(data => data?.fieldName === 'billingAddress.countryCode')
        ?.value || companyDetails?.billingAddress?.countryCode;

    const postalCodeFieldsBilling = newFields.filter(field =>
      field?.fieldName?.includes('billingAddress.zipCodePostalCode'),
    );

    countryCodeBilling = Array.isArray(countryCodeBilling)
      ? countryCodeBilling[0]
      : countryCodeBilling?.value || countryCodeBilling;

    if (countryCodeBilling)
      postalCodeFieldsBilling.forEach(postalCodeField => {
        postalCodeField.required = !(
          countryCodeBilling?.toLowerCase() === 'jm' ||
          isBillingSameAsAddressCheckboxSelected
        );
      });

    setEditFields([...newFields]);
  };

  const summaryDataFields = useMemo(() => {
    const summaryFields: SummaryFieldsData[] = [
      {
        id: 'companyName',
        label: 'Company Name',
        data: summaryFieldsValues?.name,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        isValid: !validations?.some(v => v.field === 'name'),
        validationMessage: validations?.find(v => v.field === 'name')
          ?.validationMessage,
        onChange: handleSummaryFieldChange('name'),
        required: true,
      },
      {
        id: 'dba',
        label: 'DBA',
        data: summaryFieldsValues?.dba,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        isValid: !validations?.some(v => v.field === 'dba'),
        onChange: handleSummaryFieldChange('dba'),
        validationMessage: validations?.find(v => v.field === 'dba')
          ?.validationMessage,
        required: true,
      },
      {
        id: 'federalTaxClassification',
        label: 'Federal Tax Classification',
        defaultData: summaryFieldsValues?.federalTaxClassification?.id,
        options: taxClassifications?.map(taxClassification => ({
          label: taxClassification.name,
          data: taxClassification.id,
        })),
        type: SummaryFieldTypes.Select,
        isEditable: true,
        isValid: !validations?.some(
          v => v.field === 'federalTaxClassification',
        ),
        onChange: handleSummaryFieldChange('federalTaxClassification'),
        validationMessage: validations?.find(
          v => v.field === 'federalTaxClassification',
        )?.validationMessage,
        required: true,
      },
    ];

    // FEIN should only be visible when editing
    if (isEditing) {
      summaryFields.push({
        id: 'fein',
        label: 'FEIN',
        data: summaryFieldsValues?.fein,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        placeholder: '9 digits numbers only',
        isNumeric: true,
        maxLength: 9,
        isValid: !validations?.some(v => v.field === 'fein'),
        onChange: handleSummaryFieldChange('fein'),
        validationMessage: validations?.find(v => v.field === 'fein')
          ?.validationMessage,
        required: true,
      });
    }

    // this is for the order, ecotrak ID should be last
    summaryFields.push({
      id: 'ecotrakId',
      label: 'Ecotrak ID',
      data: companyDetails?.id,
      type: SummaryFieldTypes.Text,
      isNumeric: true,
    });

    return summaryFields;
  }, [
    summaryFieldsValues,
    validations,
    taxClassifications,
    isEditing,
    companyDetails?.id,
    handleSummaryFieldChange,
  ]);

  return companyDetails ? (
    <ECWorkflowTemplate
      tabsAsLinks
      pageTitle={t('translation:panelDrawer.profile')}
      showEditTitleStartAdornment
      title={companyDetails?.name}
      titleProvided={summaryFieldsValues?.name?.trim().length > 0}
      titleNotProvidedLabel={t(
        'translation:workflow.summary.companyNameRequired',
      )}
      summaryData={summaryDataFields}
      readEditModeStatus={setIsEditing}
      beInEditMode={isEditing}
      isEditAllowed={isEditingAllow}
      editConfig={fancyFormElementsEdit.data.config}
      editFields={fancyFormElementsEdit.data.fields}
      detailsConfig={formElementsDetails.data.config}
      detailsFields={detailsFields}
      detailsData={companyDetails}
      onChange={handleChange}
      outsideData={editFields}
      useUpdateMutation={useUpdateSPProfile}
      onExitEditModeWithoutSave={initializeFields}
      imgSrc={companyDetails?.logo}
      showLogoOnError={true}
      isBookmarkVisible={false}
      hideNoteTab={true}
      additionalTabs={[
        {
          value: 'rates-and-terms',
          link: 'rates-and-terms',
          label: 'Rates And Terms',
          editable: true,
          content: <Outlet />,
        },
        {
          value: 'trades-and-regions',
          link: 'trades-and-regions',
          editable: true,
          label: t('translation:panelDrawer.serviceProvidersTradeAndRegion'),
          content: <Outlet />,
        },
      ]}
      attachmentsTitle={'Required Documents'}
      detailTabsOrder={[
        'details',
        'rates-and-terms',
        'attachments',
        'trades-and-regions',
        'activity',
      ]}
      disableChangeTab={disableChangeTab}
    />
  ) : null;
}
