import { ECBox, ECGrid, isEmptyValue } from 'app/components';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useGetWorkorderDispatchColumnViewQuery } from 'services/workOrdersApi';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import _ from 'lodash';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECAdvancedFilter } from 'app/components/ECTable/ECAdvancedFilter';
import { ECHierarchyTableFilter } from 'app/components/ECTable/ECHierarchyTableFilter';
import { OrganizationIdTypeEnum } from 'types/Organization';
import { ECStatusFilter } from 'app/components/ECTable/ECStatusFilter';
import { ECCustomSimpleSelectFilter } from 'app/components/ECTable/ECCustomSimpleSelectFilter';
import { AutoCompleteDropDownOption } from 'types/User';
import { DispatchSwimlanesDND } from './DispatchSwimlaneDND';

interface DispatchSwimlanesViewProps {
  renderChangeViewModeButton: () => JSX.Element;
}

export function DispatchSwimlanesView({
  renderChangeViewModeButton,
}: DispatchSwimlanesViewProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/dispatch'));
  }, []);

  const {
    data: workflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');

  const {
    data: priorities,
  } = useGetPrioritiesListQuery({
    ob: 'prrt.name',
    o: 'desc',
  });

  const customSimpleSelectFilterOptions = useMemo(() => {
    return (
      priorities?.data?.map(customer => ({
        id: customer.id,
        label: customer.name,
        fieldName: customer.name,
        isInitialSelected: true,
      })) || []
    );
  }, [priorities?.data]);

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.name)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  // use the values from redux for the params
  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const endpoint = useSelector(
    (state: RootState) => state.page.filter.endpoint,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const hierarchy = activeFilter?.hierarchy;

  const regionParentIds = hierarchy
    ?.filter(org => org.organizationType === OrganizationIdTypeEnum.Region)
    ?.map(org => org.nodeId);
  const districtParentIds = hierarchy
    ?.filter(org => org.organizationType === OrganizationIdTypeEnum.District)
    ?.map(org => org.nodeId);

  const internalTechs = activeFilter?.internalTechs;
  const isSelectAllInternalTechs = activeFilter?.isSelectAllInternalTechs;
  const statuses = activeFilter?.statuses;
  const selectedPrioritiesFilter = activeFilter?.['prrt.id'];
  const filterFields = activeFilter?.filterFields;
  const isAdvancedSearch = activeFilter?.isAdvanced;

  const queryParamsMemoized = useMemo(() => {
    let searchIndex = 0;
    let searchGroupIndex = 0;
    const queryParams = {};

    if (!!internalTechs?.length && !isSelectAllInternalTechs) {
      queryParams[`sb${searchIndex || ''}`] = 'usr.USER_ID';
      queryParams[`s${searchIndex || ''}`] = internalTechs
        ?.map(internalTech => internalTech?.id)
        ?.join(',');
      queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
      queryParams[`sbo${searchIndex || ''}`] = 'in';
      queryParams[`sglo${searchIndex || ''}`] = 'or';
      searchIndex += 1;
      searchGroupIndex += 1;
    }

    if (statuses) {
      statuses?.forEach(status => {
        const isDigitsOnly = text => /^\d+$/.test(text);
        const isUnshiftedNumbersArray = string => {
          const stringArray = string?.split(',');
          return (
            stringArray?.length > 1 &&
            stringArray?.every(isDigitsOnly) &&
            stringArray
          );
        };
        if (isUnshiftedNumbersArray(status)) {
          status?.split(',').forEach(statusElement => {
            queryParams[`sb${searchIndex || ''}`] = 'wrkflwstts.name';
            queryParams[`s${searchIndex || ''}`] = isDigitsOnly(statusElement)
              ? Number(statusElement)
              : statusElement;
            queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
            searchIndex += 1;
          });
          return;
        }

        queryParams[`sb${searchIndex || ''}`] = 'wrkflwstts.name';
        queryParams[`s${searchIndex || ''}`] = isDigitsOnly(status)
          ? Number(status)
          : status;
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
      });
      searchGroupIndex += 1;
    }

    if (selectedPrioritiesFilter?.length) {
      selectedPrioritiesFilter?.forEach(customValue => {
        queryParams[`sb${searchIndex || ''}`] = 'prrt.id';
        queryParams[`s${searchIndex || ''}`] = customValue;
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
      });
      searchGroupIndex += 1;
    }

    filterFields?.forEach(
      ({ exactSearch, fieldName, alias, isViewId, value, searchGroup }) => {
        if (value && !isEmptyValue(value)) {
          (Array.isArray(alias) ? alias : [alias])?.forEach(aliasName => {
            queryParams[`sb${searchIndex || ''}`] = aliasName || fieldName;

            // usedValue is the simpleSearchQuery
            let usedValue = String(
              (value as AutoCompleteDropDownOption)?.id ?? value,
            );
            /* If the field is a view id, we need to remove the parts that are not part of the real id. Example: WO-135 -> real id is 13*/
            if (isViewId && usedValue?.match(/^\w+-\d+$/)) {
              usedValue = usedValue.replaceAll(/\D/g, '');
              usedValue = usedValue.substring(0, usedValue.length - 1);
            }
            const isMongoDBSearch = !!searchGroup;
            // If it is not an exact search, will use a partial search (LIKE %value%)
            if (!exactSearch && !isViewId && !isMongoDBSearch) {
              usedValue = encodeURI(`*${usedValue}*`);
            }
            if (isAdvancedSearch && isMongoDBSearch) {
              queryParams['slo'] = 'and';
            }

            const SPACE_URL_ENCODING = '%20';
            const usedValueArray =
              usedValue[0] === '*'
                ? usedValue
                    ?.slice(1, usedValue.length - 1)
                    ?.split(',')
                    ?.filter(val => val)
                : usedValue
                    ?.replaceAll(';', ',')
                    ?.split(',')
                    ?.filter(val => val);
            if (usedValueArray?.length > 1) {
              usedValueArray.forEach((usedValueElement, index) => {
                const isOnlyNumbers = usedValueElement
                  ?.replace(SPACE_URL_ENCODING, '')
                  .match(/^\d+$/);
                if (isOnlyNumbers && !exactSearch) {
                  return;
                }

                queryParams[`sb${searchIndex || ''}`] = (
                  value as AutoCompleteDropDownOption
                )?.id
                  ? `${fieldName}.id`
                  : aliasName || fieldName;
                const searchValue =
                  usedValueElement?.slice(0, 3) === SPACE_URL_ENCODING
                    ? usedValueElement?.slice(3)
                    : usedValueElement;
                queryParams[`s${searchIndex || ''}`] =
                  exactSearch || isMongoDBSearch
                    ? `${searchValue?.trim()}`
                    : `*${searchValue?.trim()}*`;
                queryParams[`sg${searchIndex || ''}`] =
                  searchGroup || searchGroupIndex;
                queryParams[`sglo${searchIndex || ''}`] = 'or';
                searchIndex += 1;
              });
            } else {
              // workaround to include fields below in search even if they are all numbers
              // Transforming the values into an array and checking didnt work
              const isStoreNumberSearch = aliasName?.includes('storeNumber'); // hotfix for search while the query performance is not improved in the BE.
              const isInvoiceNumberSearch =
                aliasName?.includes('invoiceNumber');
              const isPMIdSearch = aliasName?.includes('pmId');
              const isQrCodeSearch = aliasName?.includes('qrCode');
              const isSearialNumberSearch = aliasName?.includes('serialNumber');
              const isPhoneNumberSearch = aliasName?.includes('phone');
              const isWoNumberSearch = aliasName?.includes('id');

              const isOnlyNumbers = usedValue
                ?.replaceAll('*', '')
                .match(/^\d+$/);
              if (
                isOnlyNumbers &&
                !exactSearch &&
                !isAdvancedSearch &&
                !isStoreNumberSearch &&
                !isInvoiceNumberSearch &&
                !isPMIdSearch &&
                !isQrCodeSearch &&
                !isSearialNumberSearch &&
                !isPhoneNumberSearch &&
                !isWoNumberSearch
              ) {
                return;
              }

              queryParams[`s${searchIndex || ''}`] = usedValue; // checks if it's an value commes from a dropdown list. in that case value is an object
              queryParams[`sg${searchIndex || ''}`] =
                searchGroup || searchGroupIndex;
              queryParams[`sglo${searchIndex || ''}`] = isAdvancedSearch
                ? 'and'
                : 'or';
              searchIndex += 1;
            }
          });
        }
      },
    );

    return queryParams;
  }, [
    filterFields,
    selectedPrioritiesFilter,
    internalTechs,
    isSelectAllInternalTechs,
    statuses,
  ]);

  const { data: columnViewData, isSuccess: isSuccessColumnViewData, isFetching: isFetchingColumnViewData } =
    useGetWorkorderDispatchColumnViewQuery(
      {
        regionParentIds: regionParentIds?.length ? regionParentIds : undefined,
        districtParentIds: districtParentIds?.length
          ? districtParentIds
          : undefined,
        firstDate: activeFilter?.dateBefore,
        viewType:
          activeFilter?.dateBefore === activeFilter?.dateAfter ? 'DAY' : 'WEEK',
        ...queryParamsMemoized,
      },
      {
        skip: !activeFilter?.dateBefore,
      },
    );

  return (
    <ECBox height={"100%"}>
      <ECGrid
        display="flex"
        justifyContent={'flex-end'}
        pr={'34px'}
        height={50}
        mb={1}
        gap={1}
      >
        <ECAdvancedFilter
          cols={columnViewData?.config.fields}
          hiddenCols={[]}
        />
        {renderChangeViewModeButton()}
      </ECGrid>
      <ECGrid display="flex" justifyContent={'flex-end'} gap={1} pr={'34px'}>
        <ECCustomSimpleSelectFilter
          customSimpleSelectFilterOptions={customSimpleSelectFilterOptions}
          customSimpleSelectFilterLabel={'Priority'}
          customSimpleSelectFilterName={'prrt.id'}
          endpoint={endpoint}
        />
        <ECStatusFilter
          statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved || []}
          endpoint={endpoint}
        />
        <ECHierarchyTableFilter />
      </ECGrid>
      <DispatchSwimlanesDND
        isSuccessColumnViewData={isSuccessColumnViewData}
        isLoadingColumnViewData={isFetchingColumnViewData}
        techniciansSelectedLength={internalTechs?.length}
        columnViewData={columnViewData}
      />
    </ECBox>
  );
}
