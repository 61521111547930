import { ECTabContainer } from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

export function GeneralSettingsPage() {
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:panelDrawer.generalSettings')} - %s`}
        defaultTitle={t('translation:panelDrawer.generalSettings')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'Settings',
            label: t('translation:panelDrawer.settings'),
            content: <Outlet />,
            link: 'settings',
          },
          {
            value: 'flags',
            label: t('translation:panelDrawer.flags'),
            content: <Outlet />,
            scopes: [P.GetAllFlags],
            link: 'flags',
          },
          {
            value: 'categories',
            label: t('translation:panelDrawer.categories'),
            content: <Outlet />,
            link: 'categories',
          },
          {
            value: 'priorities',
            label: t('translation:panelDrawer.priorities'),
            content: <Outlet />,
            link: 'priorities',
          },
        ]}
      />
    </>
  );
}
