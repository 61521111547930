import * as React from 'react';
import {
  ECCard,
  ECCardContent,
  ECTypography,
  ECMainTabBox,
} from 'app/components';

export function ForbiddenPage() {
  return (
    <>
      <ECMainTabBox
        component={'main'}
        sx={{
          /// These styles are just for a good-looking demo page
          display: 'flex',
          paddingTop: '33vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ECCard>
          <ECCardContent>
            <ECTypography sx={{ marginTop: 4 }} variant={'h6'}>
              User does not have access to this page
            </ECTypography>
          </ECCardContent>
        </ECCard>
      </ECMainTabBox>
    </>
  );
}
