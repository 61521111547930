import { useEffect } from 'react';
import {
  ECCard,
  ECCardContent,
  ECTypography,
  ECMainTabBox,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { setTitle } from 'store/slice/page';
import { useTranslation } from 'react-i18next';

export function WIPPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.notifications')));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('translation:panelDrawer.notifications')}</title>
      </Helmet>
      <ECMainTabBox
        component={'main'}
        sx={{
          /// These styles are just for a good-looking demo page
          display: 'flex',
          paddingTop: '33vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ECCard>
          <ECCardContent>
            <ECTypography sx={{ marginTop: 4 }} variant={'h6'}>
              Under Construction
            </ECTypography>
          </ECCardContent>
        </ECCard>
      </ECMainTabBox>
    </>
  );
}
