import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const generalStyle = {};

interface ECTypographyPropsProps extends TypographyProps {
  hideBulletPoint?: boolean;
}

export const ECTypography = styled(Typography)<ECTypographyPropsProps>(
  (/* { theme }*/) => ({
    ...generalStyle,
  }),
);
