export const formatEtaHours = (hours: number): string => {
  if (!hours) return '';

  let etaFormatted = '';

  if ((hours / 24) % 1 !== 0) {
    etaFormatted = `${hours} Hour`;
  } else {
    etaFormatted = `${hours / 24} Day`;
  }

  /* leaving this here in case it's changed to month again in the future
  if (hours <= 48 || (hours < 720 && (hours / 24) % 1 != 0)) {
    etaFormatted = `${hours} Hour`;
  } else if (hours > 48 && ((hours / 720) % 1 != 0 || hours < 720)) {
    etaFormatted = `${hours / 24} Day`;
  } else if (hours >= 720) {
    etaFormatted = `${hours / 720} Month`;
  } else {
    etaFormatted = `${hours}`;
  } */

  return etaFormatted;
};
