import { useState } from 'react';
import { ECBox, ECButton, ECTextField, ECTypography } from 'app/components';
import { useTranslation } from 'react-i18next';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { FormHelperText } from '@mui/material';

export interface ECWorkOrderChangeTechnicianCommentModalContentProps {
  comment?: string;
  isLoading?: boolean;
  handleCloseModal: () => void;
  handleChangeComment: (value: any) => void;
  onConfirmSubmit: () => void;
}

export const ECWorkOrderChangeTechnicianCommentModalContent = ({
  comment,
  isLoading,
  handleCloseModal,
  handleChangeComment,
  onConfirmSubmit,
}: ECWorkOrderChangeTechnicianCommentModalContentProps) => {
  const { t } = useTranslation();

  const [validationCommentText, setValidationCommentText] = useState('');

  const handleConfirmSubmit = () => {
    setValidationCommentText('');
    const isEmptyHtmlComment = !comment
      ?.replaceAll(' ', '')
      .replaceAll('\t', '')
      .replaceAll('\n', '').length;

    if (!comment || isEmptyHtmlComment) {
      setValidationCommentText('Please enter a comment');
      return;
    }

    if (!isEmptyHtmlComment) {
      onConfirmSubmit?.();
    }
  };

  return (
    <ECBox bgcolor="transparent">
      <ECBox
        sx={theme => ({
          minWidth: '500px',
          bgcolor: theme.palette.common.white,
        })}
      >
        <ECBox display="flex" flexDirection="column">
          <ECBox display={'flex'} alignItems={'center'}>
            <ECTypography variant="h6">
              Submit a comment to New User Assigned
            </ECTypography>
          </ECBox>

          <ECBox
            display="flex"
            bgcolor={theme => theme.palette.background.default}
            flexDirection="column"
            mt={2}
          >
            <ECTextField
              variant="filled"
              placeholder={t('translation:richTextField.comment')}
              defaultValue={comment}
              onChange={event => handleChangeComment(event.target.value)}
            />
            {validationCommentText && (
              <FormHelperText
                sx={theme => ({
                  color: theme.palette.error.main,
                })}
              >
                {validationCommentText}
              </FormHelperText>
            )}
          </ECBox>
          <ECBox
            mt={validationCommentText ? 2 : 4}
            display="flex"
            gap={2}
            alignContent={'flex-end'}
            justifyContent="flex-end"
          >
            <ECButton variant="text" onClick={handleCloseModal}>
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleConfirmSubmit}>
              {isLoading ? (
                <ECCircularProgress
                  size={'2rem'}
                  sx={{ color: 'background.paper' }}
                />
              ) : (
                'Submit'
              )}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
