function configs(labels, datasets) {
  // const backgroundColors: string[] = [];

  // if (datasets.backgroundColors) {
  //   datasets.backgroundColors.forEach(color =>
  //     gradients[color]
  //       ? backgroundColors.push(gradients[color].state)
  //       : backgroundColors.push(dark.main),
  //   );
  // } else {
  //   backgroundColors.push(dark.main);
  // }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          backgroundColor: datasets.backgroundColor,
          data: datasets.data,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };
}

export default configs;
