import { QueryParams } from 'types/QueryParams';
import { BaseType } from 'types/BaseType';
import { TradeTypes } from 'types';
import { Branch } from 'types/Branch';
import { ServiceProvider } from 'types/ServiceProviderTypes';

import { emptyApi } from './emptyApi';

export type PreventiveMaintenance = {
  trade: TradeTypes;
  id: number;
  name: string;
  assetTypeId: string;
  requestCategoryId: string;
  tradeNte: number;
  tradeId: string;
  priorityId: number;
  description: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  companyId: number;
  updatedBy: number;
  createdBy: number;
  assetType: { name: string };
  category: { name: string };
};
export interface PreventiveMaintenanceRequest {
  name: string;
  assetTypeId: number;
  tradeId: number;
  requestCategoryId: number;
  priorityId: number;
  orgIds?: number[];
  spCompanyId?: number;
  tradeNte: number;
  serviceMonths: number[];
  dayOfMonth: number;
  daysBetween?: number;
  startDate: string;
  createInAdvance?: number;
  description?: string;
  files?: any[];
}

export interface PreventiveMaintenanceDetailsRequest {
  id: string;
  assetTypeId: number;
  tradeId: number;
  requestCategoryId: number;
  priorityId: number;
  name: string;
  description: string;
  status: number;
}

export interface PreventiveMaintenanceQueryParams extends QueryParams {
  id?: number;
  queryParams?: QueryParams;
}

export type PreventiveMaintenanceWithModule = PreventiveMaintenance & {
  moduleName: string;
};

export type PreventiveMaintenanceLocation = {
  id: number;
  pmId: number;
  priorityId: number;
  nte: any;
  spCompanyId: number | null;
  startDate: string;
  description: string;
  daysBetweenService?: number;
  serviceMonths?: string;
  dayOfMonth?: number;
  createInAdvance: number;
  serviceProvider?: ServiceProvider;
  priority?: string;
  title?: string;
  assetGroup?: string;
  nextServiceDate?: string;
  branch?: Branch;
  rank1SP?: ServiceProvider;
  organizationId: number;
  pmScheduleId?: number;
  _originalId?: number;
};

export type PreventiveMaintenanceLocationBulkEdit = {
  itemIds: number[];
  pmId: number;
  priorityId?: number;
  nte?: number | null;
  startDate?: string | null;
  description?: string | null;
  spCompanyId?: number | null;
  daysBetweenService?: number | null;
  serviceMonths?: string | null;
  dayOfMonth?: number | null;
  createInAdvance?: number | null;
  organizationIds: number[];
};

export type LocationSchedulePM = {
  pmId: number;
  orgIds: number[];
  priorityId: number;
  nte: number;
  description: string;
  createInAdvance: number;
  startDate: string;
  daysBetween?: number;
  serviceMonths?: string;
  dayOfMonth?: number;
  spCompanyId?: number;
};

export type PMLocationDelete = {
  pmId: number;
  organizationId: number;
  pmScheduleId?: number;
};

export type UnAssignedBranches = {
  name: string;
  id: number;
  organizationId: number;
};

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'PreventiveMaintenance',
    'PreventiveMaintenanceById',
    'PreventiveMaintenanceLocation',
  ],
});

export const preventiveMaintenanceApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getPreventiveMaintenanceList: builder.query<
      BaseType<PreventiveMaintenanceWithModule[]>,
      QueryParams
    >({
      query: params => ({
        url: 'preventivemaintenance',
        params,
      }),
      transformResponse: (
        response: BaseType<PreventiveMaintenanceWithModule[]>,
      ) => {
        const dataWithModuleName = (response.data || []).map(row => {
          const assetTypeId =
            row.assetType && row.assetType.name ? row.assetType.name : 'N/A';
          const requestCategoryId =
            row.category && row.category.name ? row.category.name : 'N/A';
          const tradeId = row.trade && row.trade.name ? row.trade.name : 'N/A';

          return {
            ...row,
            moduleName: 'preventivemaintenance',
            assetTypeId,
            requestCategoryId,
            tradeId,
          };
        });

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['PreventiveMaintenance'],
    }),
    getPreventiveMaintenanceById: builder.query<PreventiveMaintenance, number>({
      query: id => ({
        url: !id ? '' : `preventivemaintenance/${id}`,
      }),
      transformResponse: (response: PreventiveMaintenance) => {
        return {
          ...response,
          moduleName: 'preventivemaintenance',
        };
      },
      providesTags: ['PreventiveMaintenanceById'],
    }),
    addPreventiveMaintenance: builder.mutation<
      void,
      PreventiveMaintenanceRequest
    >({
      query: body => {
        const formData = new FormData();
        formData.append('name', body.name);
        formData.append('assetTypeId', body.assetTypeId.toString());
        formData.append('tradeId', body.tradeId.toString());
        formData.append('requestCategoryId', body.requestCategoryId.toString());
        formData.append('priorityId', body.priorityId.toString());
        formData.append('tradeNte', body.tradeNte.toString());

        if (body.serviceMonths) {
          formData.append('serviceMonths', body.serviceMonths.toString());
        }

        if (body.dayOfMonth) {
          formData.append('dayOfMonth', body.dayOfMonth.toString());
        }

        formData.append('startDate', body.startDate);

        if (body.daysBetween) {
          formData.append('daysBetween', body.daysBetween.toString());
        }

        formData.append(
          'createInAdvance',
          body.createInAdvance?.toString() || '',
        );
        formData.append('description', body.description || '');
        formData.append('orgIds', body.orgIds?.toString() || '');
        if (body.spCompanyId) {
          formData.append('spCompanyId', body.spCompanyId.toString());
        }
        const nonProcessedFiles: string[] = [];
        body.files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          } else {
            nonProcessedFiles.push(file.id);
          }
        });

        return {
          url: 'preventivemaintenance',
          body: formData,
          method: 'post',
        };
      },
      invalidatesTags: ['PreventiveMaintenance'],
    }),
    updatePreventiveMaintenance: builder.mutation<
      void,
      PreventiveMaintenanceDetailsRequest
    >({
      query: body => ({
        url: 'preventivemaintenance',
        method: 'put',
        body,
      }),
      invalidatesTags: ['PreventiveMaintenance', 'PreventiveMaintenanceById'],
    }),
    addSheduleLocationPreventiveMaintenance: builder.mutation<
      void,
      LocationSchedulePM
    >({
      query: body => {
        const requestData: any = {
          orgIds: body.orgIds,
          priorityId: body.priorityId,
          nte: body.nte,
          startDate: body.startDate,
          description: body.description,
          createInAdvance: body.createInAdvance,
          spCompanyId: body.spCompanyId,
        };

        if (body.serviceMonths !== null) {
          requestData.serviceMonths = body.serviceMonths;
        }
        if (body.dayOfMonth !== null) {
          requestData.dayOfMonth = body.dayOfMonth;
        }
        if (body.daysBetween !== null) {
          requestData.daysBetween = body.daysBetween;
        }

        return {
          url: `preventivemaintenance/${body.pmId}/schedule`,
          method: 'post',
          body: requestData,
        };
      },
      invalidatesTags: ['PreventiveMaintenanceLocation'],
    }),
    editPreventiveMaintenanceLocation: builder.mutation<
      void,
      PreventiveMaintenanceLocation
    >({
      query: body => {
        const requestData: PreventiveMaintenanceLocation = {
          id: body.id,
          pmId: body.pmId,
          priorityId: body.priorityId,
          nte: body.nte,
          spCompanyId: body.spCompanyId,
          startDate: body.startDate,
          description: body.description,
          createInAdvance: body.createInAdvance,
          organizationId: body.organizationId,
        };

        if (body.daysBetweenService) {
          requestData.daysBetweenService = body.daysBetweenService;
        }

        if (body.serviceMonths) {
          requestData.serviceMonths = body.serviceMonths;
        }

        if (body.dayOfMonth) {
          requestData.dayOfMonth = body.dayOfMonth;
        }

        return {
          url: `preventivemaintenance/schedule/${body.id}`,
          method: 'put',
          body: requestData,
        };
      },
      invalidatesTags: ['PreventiveMaintenanceLocation'],
    }),
    deletePreventiveMaintenanceLocation: builder.mutation<
      void,
      PMLocationDelete
    >({
      query: body => {
        const { pmId, pmScheduleId, organizationId } = body;
        return {
          url: `preventivemaintenance/${pmId}/schedule/${pmScheduleId}/${organizationId}`,
          method: 'delete',
          body,
        };
      },
      invalidatesTags: ['PreventiveMaintenanceLocation'],
    }),

    getPreventiveMaintenanceBranchList: builder.query<
      BaseType<PreventiveMaintenanceLocation[]>,
      PreventiveMaintenanceQueryParams
    >({
      query: ({ id, ...params }) => ({
        url: `preventivemaintenance/${id}/branches`,
        params: params?.queryParams,
      }),
      transformResponse: (
        response: BaseType<PreventiveMaintenanceLocation[]>,
      ) => {
        const newData = (response.data || []).map(row => {
          const newRow = {
            ...row,
          };

          newRow.id = row.branch?.id || row.id;
          newRow._originalId = row.id;

          if (
            newRow.rank1SP &&
            newRow.rank1SP.name &&
            newRow.serviceProvider &&
            newRow.serviceProvider
          ) {
            if (
              newRow.serviceProvider.name === null ||
              newRow.serviceProvider.name === ''
            ) {
              newRow.serviceProvider.name = newRow.rank1SP.name;
            }
          }
          return {
            ...newRow,
          };
        });
        return {
          ...response,
          data: newData,
        };
      },
      providesTags: ['PreventiveMaintenanceLocation'],
    }),
    getMasterPmUnAssignedBranchesById: builder.query<
      BaseType<UnAssignedBranches[]>,
      number
    >({
      query: id => ({
        url: `preventivemaintenance/${id}/unassigned-branches`,
      }),
      providesTags: ['PreventiveMaintenanceLocation'],
    }),
    bulkEditPreventiveMaintenanceLocation: builder.mutation<
      void,
      PreventiveMaintenanceLocationBulkEdit
    >({
      query: body => {
        const requestData: PreventiveMaintenanceLocationBulkEdit = {
          itemIds: body.itemIds,
          pmId: body.pmId,
          organizationIds: body.organizationIds,
          nte: body.nte,
        };

        if (body.priorityId) {
          requestData.priorityId = body.priorityId;
        }

        if (body.startDate) {
          requestData.startDate = body.startDate;
        }

        if (body.description) {
          requestData.description = body.description;
        }

        if (body.spCompanyId) {
          requestData.spCompanyId = body.spCompanyId;
        }

        if (body.daysBetweenService) {
          requestData.daysBetweenService = body.daysBetweenService;
        }

        if (body.serviceMonths) {
          requestData.serviceMonths = body.serviceMonths;
        }

        if (body.dayOfMonth) {
          requestData.dayOfMonth = body.dayOfMonth;
        }

        if (body.createInAdvance) {
          requestData.createInAdvance = body.createInAdvance;
        }

        return {
          url: `preventivemaintenance/bulk/schedule`,
          method: 'put',
          body: requestData,
        };
      },
      invalidatesTags: ['PreventiveMaintenanceLocation'],
    }),
  }),
});

export const {
  useLazyGetPreventiveMaintenanceListQuery,
  useGetPreventiveMaintenanceListQuery,
  useAddPreventiveMaintenanceMutation,
  useUpdatePreventiveMaintenanceMutation,
  useEditPreventiveMaintenanceLocationMutation,
  useDeletePreventiveMaintenanceLocationMutation,
  useGetPreventiveMaintenanceByIdQuery,
  useLazyGetPreventiveMaintenanceByIdQuery,
  useLazyGetPreventiveMaintenanceBranchListQuery,
  useAddSheduleLocationPreventiveMaintenanceMutation,
  useGetMasterPmUnAssignedBranchesByIdQuery,
  useLazyGetMasterPmUnAssignedBranchesByIdQuery,
  useBulkEditPreventiveMaintenanceLocationMutation,
} = preventiveMaintenanceApi;
