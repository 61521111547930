import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import {
  useCreateCategoryMutation,
  useLazyGetCategoryListQuery,
  useUpdateCategoryMutation,
} from 'services/categoryApi';
import { useGetApprovalListQuery } from '../../../services/approvalApi';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsEdit = require('./form_config_edit.json');

export function CategoriesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('category');
  }, []);

  useEffect(() => {
    const approvalField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'approvalProcess',
    );

    approvalField.useQuery = useGetApprovalListQuery;
    approvalField.obAlias = 'apvlprcs.title';
  }, []);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/category'));
  }, [dispatch]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.categories.title')}
      useLazyGetListQuery={useLazyGetCategoryListQuery}
      useCreateMutation={useCreateCategoryMutation}
      useUpdateMutation={useUpdateCategoryMutation}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetails.config}
      detailsFields={fancyFormElementsDetails.fields}
      downloadFileName={`${t('translation:pages.categories.title')}.csv`}
      enableExport={true}
      showOnlyDetailsTab
      marginTop={false}
      showStatusActiveFilter={true}
      shouldNotUseActiveFilter
    />
  );
}
