import React from 'react';
import { ECButton } from '../ECButton';
import { ECBox } from '../ECBox';

interface ECRenderCustomActionButtonProps {
  row: any;
  actionButtonConfig: any;
}

export const ECRenderCustomActionButton: React.FC<
  ECRenderCustomActionButtonProps
> = ({ row, actionButtonConfig }) => {
  return (
    <ECBox
      display="flex"
      alignItems="center"
      p={2}
      sx={
        actionButtonConfig.length >= 2
          ? { flexWrap: 'wrap', minWidth: '22rem', maxWidth: '24rem' }
          : {}
      }
    >
      {actionButtonConfig.map((action, index) => (
        <ECButton
          key={`${row.id}-${action.label}-${index}`}
          variant="outlined"
          startIcon={action.icon}
          onClick={() => action.onClick(row)}
          sx={{ marginRight: '.5rem', marginBottom: '.5rem' }}
        >
          {action.label}
        </ECButton>
      ))}
    </ECBox>
  );
};
