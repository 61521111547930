import { ECBox } from 'app/components/ECBox';
import { ECStepsContainer } from 'app/components/ECSteps';
import { ECTypography } from 'app/components/ECTypography';
import { useState } from 'react';
import { Contact, Step1 } from './step-1';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from 'types/Company';
import { Step2 } from './step-2';
import { StyleConstants } from 'styles/StyleConstants';

interface EditProps {
  onClose?: () => void;
}

export const NewInviteForm = ({ onClose }: EditProps) => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState<CompanyEntity[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const handleSetData = (fieldName: string, value: string) => {
    setData({ ...data, [fieldName]: value });
  };

  const handleCompaniesFetch = (
    companies: CompanyEntity[],
    contacts: Contact[],
  ) => {
    setCompanies(companies);
    setContacts(contacts);
    setCurrentStep(2);
  };

  const renderTabs = () => [
    {
      value: 1,
      label: '1',
      subLabel: t(
        'translation:pages.serviceProviderInvite.serviceProviderDetails',
      ),
      content: (
        <Step1
          existingData={data}
          setExistingData={handleSetData}
          onCompaniesFetch={handleCompaniesFetch}
          onClose={onClose}
        />
      ),
    },
    {
      value: 2,
      label: '2',
      subLabel: t(
        'translation:pages.serviceProviderInvite.confirmInvitationDetails',
      ),
      content: (
        <Step2
          existingData={data}
          companies={companies}
          contacts={contacts}
          onClose={onClose}
        />
      ),
    },
  ];

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox display="flex" width="100%">
        <ECBox
          display="flex"
          width="100%"
          justifyContent="start"
          alignItems="center"
          px={3}
          bgcolor={theme => theme.palette.other.divider}
          height={StyleConstants.DRAWER_TITLE_HEIGHT}
        >
          <ECTypography variant="h4">
            {t('translation:pages.serviceProviderInvite.inviteServiceProvider')}
          </ECTypography>
        </ECBox>
      </ECBox>

      <ECStepsContainer
        stepsContainerProps={{
          px: 4,
          pt: 4,
        }}
        tabs={renderTabs()}
        currentStep={currentStep}
        selectableSteps={false}
      />
    </ECBox>
  );
};
