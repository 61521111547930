import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECEasyFormFieldType,
  ECEasyTable,
  FieldTypes,
} from 'app/components';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useGetAvailableSchedulerJobNamesQuery,
  useLazyGetScheduleLogListQuery,
  useTriggerScheduleMutation,
} from 'services/scheduleApi';
import { Helmet } from 'react-helmet-async';
import { setFilterEndpoint, setTitle } from 'store/slice/page';
import csvDownload from 'json-to-csv-export';

const tableConfig = require('./fancy_form_config_details.json');
const form = require('./fancy_form_config_schedule_job.json');

export function ScheduleLogPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [trigger, result] = useLazyGetScheduleLogListQuery({
    pollingInterval: 5 * 1000,
  });

  const {
    data: availableSchedulers,
    isFetching,
    isSuccess,
  } = useGetAvailableSchedulerJobNamesQuery();

  const { data, isLoading } = result;

  useEffect(() => {
    trigger(null);
    dispatch(setTitle('Schedule Log'));
    dispatch(setFilterEndpoint('/filter/schedule'));
  }, [trigger]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const jobNameField = form.fields.find(
        field => field.fieldName === 'jobName',
      );
      if (jobNameField) {
        jobNameField.options =
          availableSchedulers?.map(scheduler => scheduler.jobName) || [];
        jobNameField.optionValues =
          availableSchedulers?.map(scheduler => scheduler.jobName) || [];
      }
    }
  }, [isSuccess, isFetching, availableSchedulers]);

  const handleChange = (output: ECEasyFormFieldType[], fieldName?: string) => {
    form.fields = [...(output as any)];
    if (fieldName !== 'jobName') {
      return;
    }

    const selectedJobName = output.find(
      field => field.fieldName === 'jobName',
    )?.value;

    const bodyField = form.fields.find(field => field.fieldName === 'body');

    if (bodyField) {
      bodyField.value =
        availableSchedulers?.find(
          scheduler => scheduler.jobName === selectedJobName,
        )?.example || '';
    }
    form.fields = [...form.fields];
  };

  const [doCreate, { isLoading: isLoadingTrigger }] =
    useTriggerScheduleMutation();

  const handleSubmit = useCallback(
    (formData, fieldData) => {
      const body = JSON.parse(
        fieldData.find(data => data.fieldName === 'body')?.value,
      );
      doCreate(body);
    },
    [doCreate],
  );

  const downloadFileJson = myData => {
    // create file in browser
    const fileName = `schedule-ID-${myData?.id}`;
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const renderActions = (row: any) => {
    return (
      <ECBox key={`${row?.id}-wo-actions-grid-view`}>
        <ECButton
          variant="contained"
          onClick={e => {
            downloadFileJson(row);
          }}
          sx={{ mr: 2 }}
        >
          DOWNLOAD JSON
        </ECButton>
        <ECButton
          variant="contained"
          onClick={e => {
            if (row?.returnvalue)
              csvDownload({ data: [row?.returnvalue], delimiter: ',' });
          }}
        >
          DOWNLOAD CSV
        </ECButton>
      </ECBox>
    );
  };

  return (
    <ECBox marginTop={'4rem'} p={'18.4px'}>
      <Helmet>
        <title>Schedule Log</title>
        <meta name="description" content="schedule-log" />
      </Helmet>
      <ECButton variant="contained" onClick={() => trigger(null)}>
        Refresh
      </ECButton>
      <ECEasyTable
        config={tableConfig.config}
        data={data}
        isLoading={isLoading}
        headerHeight={100}
        multiSelectTable={false}
        renderCustomActionComponent={renderActions}
      />
      <ECBox minWidth="50%" maxWidth="1000px">
        <ECEasyForm
          pattern="modal"
          config={form.config}
          fields={form.fields}
          isSendingData={isLoading}
          isLoadingForm={isLoadingTrigger}
          onChange={handleChange}
          onFormSubmit={handleSubmit}
        />
      </ECBox>
    </ECBox>
  );
}
