import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React from 'react';
import { P } from 'types/Permission';
import { ECToggleButton } from '../ECToggleButton';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { ECCircularProgress } from '../ECCircularProgress';

interface ECIconButtonProps extends Omit<IconButtonProps, 'type'> {
  squared?: boolean;
  value?: string;
  scopes?: P[];
  withWhiteBackground?: boolean;
  withBorder?: boolean;
  noPadding?: boolean;
  type?: 'warning' | 'error' | 'default' | 'info' | 'white' | 'success';
  filled?: boolean;
  children: React.ReactElement;
  isLoading?: boolean;
}

export const ECIconButtonStyled = styled(IconButton)(({ theme }) => {
  const baseStyle = {
    // color: theme.palette.background.paper, // Don't change the icon button's color here as we use it on many light/ dark backgrounds
  };

  return baseStyle;
});

const IconButtonModel: React.FC<ECIconButtonProps> = ({
  children,
  squared,
  value,
  scopes = [],
  withWhiteBackground,
  withBorder = false,
  noPadding = false,
  sx,
  filled,
  type = 'default',
  isLoading,
  ...props
}) => {
  const mapButtonType = {
    default: {
      color: null,
      bgColor: theme => (filled ? theme.palette.common.white : null),
      border: withBorder ? 1 : null,
      borderColor: withBorder
        ? theme => `${theme.palette.other.divider} !important`
        : null,
      padding: noPadding ? '0px !important' : null,
    },
    success: {
      color: theme => theme.palette.success.main,
      bgColor: theme => (filled ? theme.palette.success.main : null),
      borderColor: withBorder ? theme => theme.palette.success.main : null,
      border: withBorder ? 1 : null,
      padding: noPadding ? '0px !important' : null,
    },
    info: {
      color: theme => theme.palette.primary.main,
      bgColor: theme => (filled ? theme.palette.primary.main : null),
      borderColor: withBorder ? theme => theme.palette.primary.main : null,
      border: withBorder ? 1 : null,
      padding: noPadding ? '0px !important' : null,
    },
    warning: {
      color: theme => theme.palette.warning.main,
      bgColor: theme => (filled ? theme.palette.warning.main : null),
      borderColor: withBorder ? theme => theme.palette.warning.main : null,
      border: withBorder ? 1 : null,
      padding: noPadding ? '0px !important' : null,
    },
    error: {
      color: theme => theme.palette.warning.main,
      bgColor: theme =>
        withWhiteBackground
          ? theme.palette.common.white
          : filled
            ? theme.palette.warning.main
            : null,
      borderColor: withBorder
        ? theme => `${theme.palette.error.main} !important`
        : null,
      border: withBorder ? 1 : null,
      padding: noPadding ? '0px !important' : null,
    },
    white: {
      color: null,
      bgColor: theme => (filled ? theme.palette.common.white : null),
      border: withBorder ? 1 : null,
      borderColor: withBorder
        ? theme => `${theme.palette.other.divider} !important`
        : null,
      padding: noPadding ? '0px !important' : null,
      ...sx,
    },
  };

  const mapIconType = {
    default: {
      color: null,
      bgcolor: theme => (filled ? theme.palette.common.white : 'transparent'),
      borderColor: null,
      padding: noPadding ? '0px !important' : null,
    },
    success: {
      color: theme => theme.palette.success.dark,
      bgcolor: 'transparent',
      borderColor: null,
      padding: noPadding ? '0px !important' : null,
    },
    info: {
      color: theme => theme.palette.primary.main,
      bgcolor: 'transparent',
      borderColor: theme => theme.palette.primary.main,
      padding: noPadding ? '0px !important' : null,
    },
    warning: {
      color: theme =>
        filled ? theme.palette.common.white : theme.palette.warning.main,
      bgcolor: theme => (filled ? theme.palette.warning.main : 'transparent'),
      borderColor: theme => theme.palette.warning.main,
      padding: noPadding ? '0px !important' : null,
    },
    error: {
      color: theme => theme.palette.error.main,
      bgcolor: 'transparent',
      borderColor: theme => theme.palette.error.main,
      padding: noPadding ? '0px !important' : null,
    },
    white: {
      color: theme => theme.palette.common.white,
      bgcolor: null,
      borderColor: null,
      padding: noPadding ? '0px !important' : null,
    },
  };

  const filledSx = {
    bgcolor: mapButtonType[type].bgColor,
    borderColor: mapButtonType[type].borderColor,
    borderRadius: 1,
    border: mapButtonType[type].border,
    '&:hover': {
      bgcolor: mapButtonType[type].bgColor,
    },
    padding: mapButtonType[type].padding,
  };

  const iconSx = {
    color: mapIconType[type].color,
    bgcolor: mapIconType[type].bgcolor,
    '&:hover': {
      bgcolor: mapIconType[type].bgcolor,
    },
  };

  if (squared) {
    return (
      <ECToggleButton
        value=""
        onChange={props.onClick as any}
        sx={{
          ...filledSx,
          ...sx,
        }}
        scopes={scopes}
      >
        {React.cloneElement(children, {
          sx: {
            ...iconSx,
            ...sx,
          },
        })}
      </ECToggleButton>
    );
  }

  return (
    <ECIconButtonStyled
      sx={{
        ...filledSx,
        ...sx,
      }}
      {...props}
    >
      {isLoading ? (
        <ECCircularProgress
          size="24px"
          sx={{
            color: iconSx.color,
          }}
        />
      ) : (
        React.cloneElement(children, {
          sx: {
            ...iconSx,
            ...sx,
          },
        })
      )}
    </ECIconButtonStyled>
  );
};

export const ECIconButton =
  requirePermissionWrapper<ECIconButtonProps>(IconButtonModel);
