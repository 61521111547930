import React from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { chipColors } from '../ECTable';
import { ECChip } from '../ECChip';
import { ECMenuItem } from '../ECSelect';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';

export interface Chip {
  color?: typeof chipColors;
  label: string;
  name?: string;
  disabled?: boolean;
  id?: number;
}

export interface ChipSearchOptions {
  searchString: string;
  options: string[];
}

interface ECChipAutocompleteProps {
  options: Chip[];
  title?: string;
  placeholder?: string;
  values?: Chip[];
  defaultValues?: any[];
  variant: 'standard' | 'filled' | 'outlined';
  errorMessage?: string;
  readOnly?: boolean;
  getOptionLabel?: (option: any) => string;
  onChange?: (value: Chip[], event?: any, action?: any) => void;
  onChangeSearch?: (value: string) => void;
  loading?: boolean;
  searchString?: string | undefined;
  freeSolo?: boolean;
  limitTags?: number;
  open?: boolean;
  sx?: any;
  paperComponent?: any;
}

export const ECChipAutocomplete: React.FC<ECChipAutocompleteProps> = React.memo(
  ({
    options,
    title,
    placeholder,
    values,
    defaultValues,
    variant = 'filled',
    errorMessage,
    readOnly,
    loading,
    getOptionLabel: getOptionLabelInit,
    onChange,
    onChangeSearch,
    freeSolo,
    searchString,
    limitTags,
    paperComponent,
    ...autocompleteProps
  }) => {
    const getOptionLabel = getOptionLabelInit
      ? getOptionLabelInit
      : option => option.label;

    return (
      <>
        <ECAutocompleteStyled
          {...autocompleteProps}
          multiple
          disableCloseOnSelect
          id="tags-standard"
          options={options}
          loading={loading}
          PaperComponent={paperComponent}
          value={values}
          readOnly={readOnly}
          isOptionEqualToValue={(option, value) =>
            option.id
              ? option.id === value?.id
              : getOptionLabel(option) === getOptionLabel(value)
          }
          filterSelectedOptions
          renderOption={(props, option, state) => {
            if (state.selected) {
              return <></>;
            }
            if (searchString) {
              const searchIndex = option.label.indexOf(searchString);
              return (
                <ECMenuItem
                  {...props}
                  key={option?.id}
                  id={option?.id}
                  disabled={option?.disabled}
                >
                  <span>
                    {searchIndex > -1 && option.label.substring(0, searchIndex)}
                    {searchIndex > -1 && <b>{searchString}</b>}
                    {searchIndex > -1 &&
                      option.label.substring(searchIndex + searchString.length)}
                    {searchIndex === -1 && option.label}
                  </span>
                </ECMenuItem>
              );
            }
            return (
              <ECMenuItem
                {...props}
                key={option?.id}
                id={option?.id}
                disabled={option?.disabled}
              >
                {
                  <ECStack dir="column">
                    <ECTypography variant="body1">
                      {getOptionLabel?.(option) ??
                        (typeof option === 'string' ? option : '')}
                    </ECTypography>
                    {option?.subLabel && (
                      <ECTypography
                        variant="body2"
                        color={option?.subLabelColor}
                      >
                        {option?.subLabel}
                      </ECTypography>
                    )}
                  </ECStack>
                }
              </ECMenuItem>
            );
          }}
          getOptionLabel={getOptionLabel}
          renderInput={params => (
            <TextField
              {...params}
              variant={variant}
              label={title}
              placeholder={
                defaultValues && !!defaultValues.length
                  ? 'Add more'
                  : placeholder
              }
              onChange={e => {
                onChangeSearch?.(e.target.value);
              }}
              sx={theme => ({
                '.MuiInputLabel-root': {
                  color: errorMessage
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                },
                '.MuiInputLabel-root:after': {
                  color: errorMessage
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                },
                '.MuiAutocomplete-input': {
                  minWidth: readOnly
                    ? '0px !important'
                    : 'fit-content !important',
                },
                '.MuiAutocomplete-endAdornment': {
                  visibility: readOnly ? 'hidden' : 'visible',
                },
              })}
            />
          )}
          limitTags={limitTags}
          renderTags={(value, getTagProps) => {
            return value?.map((option: Chip, index: number) => (
              <ECChip
                key={`${option.label}-${index}`}
                label={option.label}
                color={option?.color}
                variant="outlined"
                {...getTagProps({ index })}
                onDelete={
                  readOnly ? undefined : getTagProps({ index }).onDelete
                }
              />
            ));
          }}
          freeSolo={freeSolo}
          onChange={(event, newValues, action, option) => {
            onChange?.(newValues, event, action);
          }}
        />
        <FormHelperText sx={theme => ({ color: theme.palette.error.main })}>
          {errorMessage}
        </FormHelperText>
      </>
    );
  },
);
