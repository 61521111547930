import { BaseType } from 'types/BaseType';
import { Branch, BranchDetail } from 'types/Branch';
import { Profile } from 'types/Profile';
import { QueryParams } from 'types/QueryParams';
import {
  UserList,
  UserMutate,
  UserFormCreate,
  UserFormUpdate,
  UserDetail,
  UserWithRolesList,
  UserFlagActionParams,
  UserFlagEditBody,
  UpdateSpCustomerBranchesParams,
  UserAssignedLocationOptions,
  SPUserAssignedLocationOptions,
} from 'types/User';
import { formatPhoneNumber } from 'utils/strings/phone';
import { Organization, SPCustomerBranches } from 'types/Organization';
import * as _ from 'lodash';
import { emptyApi } from './emptyApi';
import { Lookup } from 'types/Lookup';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'User',
    'UserById',
    'UserOrganizationsById',
    'UserAssignedLocationsOption',
    'SPUserAssignedLocationsOption',
    'UserAssignedLocations',
    'SPUserAssignedLocations',
    'UserBranches',
    'InternalTechs',
    'InternalTechsCompanies',
    'BranchInternalTechs',
    'UsersForDropdown',
  ],
});

// Define a service using a base URL and expected endpoints
export const usersApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getUsersList: builder.query<BaseType<UserList[]>, QueryParams>({
      query: params => {
        const { o, ob, ...restParams } = params;

        const defaultParams = {
          ...restParams,
          o: o !== undefined && o !== '' ? o : 'a',
          ob: ob !== undefined && ob !== '' ? ob : 'usr.id',
        };

        return {
          url: 'user',
          params: defaultParams,
        };
      },
      transformResponse: (response: BaseType<UserList[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'user',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['User'],
    }),
    getUsersListForDropdown: builder.query<BaseType<UserList[]>, QueryParams>({
      query: params => {
        return {
          url: 'user/dropdown',
          params,
        };
      },
      transformResponse: (response: BaseType<UserList[]>) => {
        const dataWithFullName = response.data.map(row => ({
          ...row,
          name: `${row.firstName} ${row.lastName}`,
        }));

        return {
          ...response,
          data: dataWithFullName,
        };
      },
      providesTags: ['UsersForDropdown'],
    }),
    getUsersAdminList: builder.query<BaseType<UserList[]>, QueryParams>({
      query: params => {
        return {
          url: 'user/impersonate',
          params,
        };
      },
      transformResponse: (response: BaseType<UserList[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'user',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['User'],
    }),
    getUsersByRoleNameAndModuleId: builder.query<
      { customersInfo: UserList[]; spUsersInfo: UserList[] },
      {
        moduleName: string;
        moduleId: string;
        role: string;
        userType: string;
      } & QueryParams
    >({
      query: ({ moduleName, moduleId, ...params }) => {
        return {
          url: `user/${moduleName}/${moduleId}`,
          params,
        };
      },
    }),
    getUsersForBulkEdit: builder.query<
      { customersInfo: UserList[]; spUsersInfo: UserList[] },
      { moduleName: string; items: number[] } & QueryParams
    >({
      query: ({ moduleName, items, ...params }) => {
        return {
          url: `user/${moduleName}/bulk/edit`,
          params: {
            role: 'DIRECT_NOTES',
            userType: 'CSP',
            items,
            ...params,
          },
        };
      },
    }),
    getUsersWithRolesList: builder.query<UserWithRolesList[], QueryParams>({
      query: params => {
        return {
          url: 'user/role',
          params,
        };
      },
      providesTags: ['User'],
    }),
    createUser: builder.mutation<void, UserFormCreate>({
      query: formData => {
        const {
          userName,
          firstName,
          lastName,
          email,
          company,
          jobTitle,
          line1,
          line2,
          cityName,
          zipCodePostalCode,
          stateProvinceCode,
          countryCode,
          phone,
          phoneExt,
          password,
          nte,
          timezone,
          ssoUser,
        } = formData;
        let body: UserMutate = {
          userName: userName?.toLowerCase?.() || userName,
          firstName,
          lastName,
          email: email?.toLowerCase?.() || email,
          jobTitleId: jobTitle.id,
          companyId: company?.id ?? 0,
          phone,
          phoneExt,
          password,
          nte,
          timezone,
        };
        const address = {
          line1: line1 ? line1 : null,
          line2: line2 ? line2 : null,
          cityName: cityName ? cityName : null,
          zipCodePostalCode: zipCodePostalCode ? zipCodePostalCode : null,
          stateProvinceCode: stateProvinceCode ? stateProvinceCode : null,
          countryCode: countryCode ? countryCode : null,
        };
        if (
          line1 &&
          cityName &&
          zipCodePostalCode &&
          stateProvinceCode &&
          countryCode
        ) {
          body = { ...body, address };
        }
        return {
          url: 'user',
          method: 'post',
          body: { ...body, ssoUser },
        };
      },
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<void, UserFormUpdate>({
      query: formData => {
        const {
          id,
          userName,
          firstName,
          lastName,
          email,
          companyId,
          line1,
          line2,
          cityName,
          zipCodePostalCode,
          stateProvinceCode,
          countryCode,
          jobTitleName,
          jobTitleId,
          phone,
          phoneExt,
          nte,
          timezone,
          flags,
          status,
          deleteAddress,
          identityProviderId,
        } = formData;
        const address = {
          line1: line1 ? line1 : null,
          line2: line2 ? line2 : null,
          cityName: cityName ? cityName : null,
          zipCodePostalCode: zipCodePostalCode ? zipCodePostalCode : null,
          stateProvinceCode: stateProvinceCode ? stateProvinceCode : null,
          countryCode: countryCode ? countryCode : null,
        };
        const updatedUser: UserMutate = {
          userName: userName?.toLowerCase?.() || userName,
          firstName,
          lastName,
          email: email?.toLowerCase?.() || email,
          companyId,
          jobTitleId,
          jobTitleName,
          phone,
          phoneExt,
          nte,
          timezone,
          status,
          deleteAddress,
          identityProviderId,
        };
        if (
          line1 &&
          cityName &&
          zipCodePostalCode &&
          stateProvinceCode &&
          countryCode
        ) {
          updatedUser.address = address;
        }
        if (flags && flags.length > 0) {
          updatedUser.flags = flags;
        }
        return {
          url: `user/${id}`,
          method: 'put',
          body: updatedUser,
        };
      },
      invalidatesTags: ['User', 'UserById'],
    }),
    deleteUser: builder.mutation<void, UserMutate>({
      query: user => ({
        url: 'user',
        method: 'delete',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    addUserFlags: builder.mutation<void, UserFlagActionParams>({
      query: ({ userId, flagIds }) => ({
        url: `user/${userId}/flag`,
        method: 'post',
        body: flagIds,
      }),
    }),
    editUserFlags: builder.mutation<void, UserFlagEditBody>({
      query: flagEditBody => ({
        url: `user/${flagEditBody.userId}/flag`,
        method: 'put',
        body: flagEditBody.flags,
      }),
    }),
    deleteUserFlags: builder.mutation<void, UserFlagActionParams>({
      query: ({ userId, flagIds }) => ({
        url: `user/${userId}/flag`,
        method: 'delete',
        body: flagIds,
      }),
    }),
    getUserById: builder.query<UserFormUpdate, Number>({
      query: userId => {
        return {
          url: `user/${userId}`,
        };
      },
      transformResponse: (response: UserDetail) => {
        const {
          id,
          userName,
          firstName,
          lastName,
          fullName,
          email,
          companyId,
          flags,
          address,
          jobTitle,
          phone,
          phoneExt,
          nte,
          status,
          timezone,
          identityProviderId,
        } = response;
        const formattedPhone = formatPhoneNumber(phone);
        const trasformedResponseForForm: UserFormUpdate = {
          id,
          userName,
          firstName,
          lastName,
          fullName,
          email,
          companyId,
          flags,
          line1: address?.line1,
          line2: address?.line2,
          cityName: address?.cityName,
          zipCodePostalCode: address?.zipCodePostalCode,
          stateProvinceCode: address?.stateProvinceCode,
          country: address?.country,
          countryCode: address?.countryCode,
          jobTitle: {
            id: jobTitle?.id,
            label: jobTitle?.name,
          },
          formattedPhone,
          phone,
          phoneExt,
          nte,
          status,
          timezone,
          moduleName: 'user',
          identityProviderId,
        };
        return trasformedResponseForForm;
      },
      providesTags: ['UserById'],
    }),

    getOrganizationBranches: builder.query<
      BaseType<Branch[]>,
      { orgIds: number[]; queryParams: QueryParams }
    >({
      query: ({ orgIds, queryParams }) => {
        return {
          url: `user/organizations/branches`,
          method: 'post',
          body: { orgIds },
          params: queryParams,
        };
      },
    }),

    getAssignedOrganizations: builder.query<Organization[], number | string>({
      query: userId => {
        return {
          url: `user/${userId}/organization`,
        };
      },
      transformResponse: (response: Organization[]) => {
        return response?.filter(org => !_.isEmpty(org.name));
      },
      providesTags: ['UserOrganizationsById'],
    }),

    updateUserOrganizations: builder.mutation<
      Organization[],
      { userId: string; orgIds: number[] }
    >({
      query: ({ userId, orgIds }) => ({
        url: `user/${userId}/orgs`,
        method: 'put',
        body: orgIds,
      }),
      invalidatesTags: [
        'UserOrganizationsById',
        'UserAssignedLocationsOption',
        'UserAssignedLocations',
      ],
    }),

    //  Service Provider
    getServiceProviderUsers: builder.query<Profile[], number>({
      query: spId => {
        return {
          url: `serviceprovider/${spId}/users/`,
        };
      },
    }),

    //  Service Provider location /user/branch/:id?userType=SP&spCompanyId=<SP_ID>
    getSPBranchUsers: builder.query<Profile[], any>({
      query: ({ branchId, spCompanyId }) => {
        if (!branchId || !spCompanyId || branchId == undefined) {
          return { url: '' };
        }
        return {
          url: `user/branch/${branchId}/`,
          params: {
            userType: 'SP',
            spCompanyId,
          },
        };
      },
    }),

    getSpCustomers: builder.query<any, number | string>({
      query: userId => {
        return {
          url: `/user/sp/${userId}/customer`,
        };
      },
      providesTags: ['UserOrganizationsById'],
    }),
    getSpCustomerBranches: builder.query<SPCustomerBranches, any>({
      query: ({ userId, companyId, assignedOnly, queryParams }) => {
        return {
          url:
            (companyId &&
              userId &&
              `/user/sp/${userId}/customer/${companyId}/branches?assignedOnly=${assignedOnly}`) ||
            '',
          params: queryParams,
        };
      },
      providesTags: ['UserOrganizationsById'],
    }),
    updateSpUserOrganizations: builder.mutation<
      Organization[],
      UpdateSpCustomerBranchesParams
    >({
      query: ({
        assignToAllCustomers,
        assignToSpecificCustomers,
        assignToSpecificBranches,
        userId,
        newCustomerIds,
        deleteCustomerIds,
        newOrgIds,
        deleteOrgIds,
      }) => ({
        url: `user/sp/${userId}/customer/branches`,
        method: 'put',
        body: {
          assignToAllCustomers,
          assignToSpecificCustomers,
          assignToSpecificBranches,
          newOrgIds,
          deleteOrgIds,
          newCustomerIds,
          deleteCustomerIds,
        },
      }),
      invalidatesTags: [
        'UserOrganizationsById',
        'SPUserAssignedLocations',
        'SPUserAssignedLocationsOption',
      ],
    }),
    clearCacheByUserId: builder.mutation<void, number>({
      query: userId => ({
        url: `user/${userId}/reload`,
        method: 'put',
      }),
    }),
    clearCacheByCompanyId: builder.mutation<void, { company: Lookup }>({
      query: ({ company }) => {
        return {
          url: `company/${company?.id}/clear-cache`,
          method: 'put',
        };
      },
    }),
    getAllCompanies: builder.query<BaseType<Lookup[]>, QueryParams>({
      query: params => ({
        url: 'company/all',
        params,
      }),
    }),
    getAllCompanyUsers: builder.query<
      BaseType<any[]>,
      { companyId: number } & QueryParams
    >({
      query: ({ companyId, ...queryParams }) => {
        const params = { ...queryParams };
        return {
          url: `company/${companyId}/users`,
          params,
        };
      },
    }),
    getUserAssignedLocations: builder.query<
      any,
      { id: number; queryParams: QueryParams }
    >({
      query: ({ id, queryParams }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `user/${id}/branches`,
          params: queryParams,
        };
      },
      providesTags: ['UserAssignedLocations'],
    }),
    getCurrentUserBranches: builder.query<any, QueryParams>({
      query: params => {
        return {
          url: 'user/branches',
          params,
        };
      },
      providesTags: ['UserBranches'],
    }),
    getUserAssignedLocationsOption: builder.query<
      UserAssignedLocationOptions,
      { userId: number; companyId: number }
    >({
      query: ({ userId, companyId }) => {
        if (!userId || !companyId) {
          return {
            url: '',
          };
        }

        return {
          url: `user/${userId}/location/assignment/company/${companyId}`,
        };
      },
      providesTags: ['UserAssignedLocationsOption'],
    }),

    getSPUserAssignedLocationsOption: builder.query<
      SPUserAssignedLocationOptions,
      { userId: number; allCustomers?: number }
    >({
      query: ({ userId, ...params }) => {
        if (!userId) {
          return {
            url: '',
          };
        }

        return {
          url: `user/sp/${userId}/location/assignment`,
          params,
        };
      },
      providesTags: ['SPUserAssignedLocationsOption'],
    }),
    getSPUserAssignedLocations: builder.query<
      BaseType<BranchDetail[]>,
      { id: number; queryParams: QueryParams; allBranches?: number }
    >({
      query: ({ id, queryParams, ...params }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `user/sp/${id}/customer/branches`,
          params: {
            ...queryParams,
            ...params,
          },
        };
      },
      providesTags: ['SPUserAssignedLocations'],
    }),
    getInternalTechs: builder.query<BaseType<any[]>, { params: QueryParams }>({
      query: ({ params }) => {
        return {
          url: 'user/internal-techs',
          params,
        };
      },
      transformResponse: (response: BaseType<any[]>) => {
        const data = response.data.map(row => ({
          ...row,
          name: `${row.firstName} ${row.lastName}`,
        }));

        return {
          ...response,
          data,
        };
      },
      providesTags: ['InternalTechs'],
    }),
    getInternalTechsCompanies: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        return {
          url: 'company/internal-tech',
          params,
        };
      },
      providesTags: ['InternalTechsCompanies'],
    }),
    getInternalTechsByBranchId: builder.query<
      BaseType<any[]>,
      { branchId: number }
    >({
      query: ({ branchId }) => {
        return {
          url: `user/internal-techs/branch/${branchId}`,
        };
      },

      providesTags: ['BranchInternalTechs'],
    }),
  }),
});

export const {
  useGetUsersListQuery,
  useLazyGetUsersListQuery,
  useGetUsersListForDropdownQuery,
  useLazyGetUsersListForDropdownQuery,
  useLazyGetUsersAdminListQuery,
  useLazyGetUsersWithRolesListQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useAddUserFlagsMutation,
  useEditUserFlagsMutation,
  useDeleteUserFlagsMutation,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetServiceProviderUsersQuery,
  useLazyGetServiceProviderUsersQuery,
  useGetSPBranchUsersQuery,
  useLazyGetSPBranchUsersQuery,
  useGetOrganizationBranchesQuery,
  useLazyGetOrganizationBranchesQuery,
  useGetAssignedOrganizationsQuery,
  useLazyGetAssignedOrganizationsQuery,
  useUpdateUserOrganizationsMutation,
  useGetUsersByRoleNameAndModuleIdQuery,
  useLazyGetUsersByRoleNameAndModuleIdQuery,
  useGetUsersForBulkEditQuery,
  useLazyGetUsersForBulkEditQuery,
  useGetSpCustomersQuery,
  useLazyGetSpCustomersQuery,
  useGetSpCustomerBranchesQuery,
  useLazyGetSpCustomerBranchesQuery,
  useUpdateSpUserOrganizationsMutation,
  useClearCacheByUserIdMutation,
  useClearCacheByCompanyIdMutation,
  useGetAllCompaniesQuery,
  useLazyGetAllCompaniesQuery,
  useGetUserAssignedLocationsOptionQuery,
  useLazyGetUserAssignedLocationsOptionQuery,
  useGetUserAssignedLocationsQuery,
  useLazyGetUserAssignedLocationsQuery,
  useGetCurrentUserBranchesQuery,
  useLazyGetCurrentUserBranchesQuery,
  useGetSPUserAssignedLocationsOptionQuery,
  useLazyGetSPUserAssignedLocationsOptionQuery,
  useGetSPUserAssignedLocationsQuery,
  useLazyGetSPUserAssignedLocationsQuery,
  useGetAllCompanyUsersQuery,
  useLazyGetAllCompanyUsersQuery,
  useGetInternalTechsQuery,
  useLazyGetInternalTechsQuery,
  useGetInternalTechsCompaniesQuery,
  useLazyGetInternalTechsCompaniesQuery,
  useGetInternalTechsByBranchIdQuery,
  useLazyGetInternalTechsByBranchIdQuery,
} = usersApi;
