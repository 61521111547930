import { emptyApi } from './emptyApi';
import {
  SurveyFeedback,
  SurveyFeedbackFromApi,
  SurveyFeedbackMutationParams,
  SurveyFeedbackQueryParams,
  SurveyTemplate,
  SurveyTemplateQueryParams,
} from 'types/Survey';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Template', 'Feedback'],
});

export const surveyApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getSurveyTemplate: builder.query<SurveyTemplate, SurveyTemplateQueryParams>(
      {
        query: ({ moduleName, companyId }) => {
          return {
            url: `survey/template/${companyId}/${moduleName}`,
          };
        },
        providesTags: ['Template'],
      },
    ),

    getSurveyFeedback: builder.query<SurveyFeedback, SurveyFeedbackQueryParams>(
      {
        query: ({ companyId, moduleName, id }) => {
          return {
            url: `survey/feedback/${companyId}/${moduleName}/${id}`,
          };
        },
        transformResponse: (response: SurveyFeedbackFromApi) => {
          return {
            ...response,
            questions: response?.questionAnswers?.map(question => ({
              ...question,
              text: question.questionText,
            })),
          };
        },
        providesTags: ['Feedback'],
      },
    ),

    createFeedback: builder.mutation<void, SurveyFeedbackMutationParams>({
      query: ({ companyId, moduleName, id, ...body }) => {
        return {
          url: `survey/feedback/${companyId}/${moduleName}/${id}`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Feedback'],
    }),
  }),
});

export const {
  useGetSurveyTemplateQuery,
  useLazyGetSurveyTemplateQuery,
  useGetSurveyFeedbackQuery,
  useLazyGetSurveyFeedbackQuery,
  useCreateFeedbackMutation,
} = surveyApi;
