import { ECEasyFormCreate, ECPaper } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import _ from 'lodash';
import { themes } from 'styles/theme/themes';
import {
  useGetBulkRanksQuery,
  useUpdateServiceProvidersBulkMutation,
} from 'services/tradeApi';
import { PayloadBulkUpdateTradeAssignmentBySp } from 'types/TradeTypes';
import { useDispatch } from 'react-redux';
import { branchApi } from 'services/branchApi';
import { setSnackbar } from 'store/slice/page';
import { StyleConstants } from 'styles/StyleConstants';

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');

interface BulkEditDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: any[];
  spCompanyId: number;
  tradeId: number;
}

export const BulkEditDrawer = ({
  isOpen,
  selectedRows,
  onClose,
  spCompanyId,
  tradeId,
}: BulkEditDrawerProps) => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsBulkEdit.fields,
  );

  const { data: ranksData, isSuccess: isSuccessRanksData } =
    useGetBulkRanksQuery();

  const [
    doUpdateBulkEdit,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: updateReset,
      data: updateData,
    },
  ] = useUpdateServiceProvidersBulkMutation();

  const handleClose = useCallback(() => {
    onClose?.();
    if (isUpdateSuccess) {
      dispatch(branchApi.util.invalidateTags(['BranchBySpAndTrade']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: updateData?.success?.length
            ? `Bulk Trade Assignment Succesfully Updated Apply to ${updateData?.success?.length} Locations`
            : `Succesfully updated`,
        }),
      );
    }
  }, [onClose, updateData, isUpdateSuccess]);

  const useBulkEditMutation = useCallback(() => {
    const bulkEditMutation = async data => {
      const body: PayloadBulkUpdateTradeAssignmentBySp = {
        tradeId: tradeId,
        spCompanyId: spCompanyId,
        rank: data.spRank.id,
        branchIds: data.locations?.map(d => d.id) || [],
        adminOnly: data.adminOnly ? true : false,
      };

      doUpdateBulkEdit(body);
    };

    return [
      bulkEditMutation,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: updateReset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    tradeId,
    spCompanyId,
    updateReset,
  ]);

  useEffect(() => {
    if (isOpen && selectedRows && isSuccessRanksData && ranksData) {
      const updatedFormFields = [...formFields];

      const pmsCountLabelField = updatedFormFields.find(
        field => field.fieldName === 'pmCountLabel',
      );

      // this changes the LABEL of the field to have the count of selected rows
      if (pmsCountLabelField) {
        pmsCountLabelField.label = `Locations (${selectedRows?.length})`;
      }

      const selectedLocationsChipsField = updatedFormFields.find(
        field => field.fieldName === 'locations',
      );

      // this converts the rows into chips
      if (selectedLocationsChipsField) {
        selectedLocationsChipsField.value =
          selectedRows?.map(d => ({
            label: d.name,
            color: 'Grey',
            id: d.id,
          })) || [];
      }

      // add the ranks to the dropdown
      const ranksField = updatedFormFields
        .find(f => f.groupName === 'groupRankAndAdminOnly')
        .subFields.find(field => field.fieldName === 'spRank');

      if (ranksField) {
        ranksField.options = ranksData.map(rank => {
          return {
            id: rank.code,
            label: rank.name,
          };
        });
        ranksField.optionValues = ranksData.map(rank => rank.code);
      }

      setFormFields(updatedFormFields);
    }
  }, [isOpen, selectedRows, isSuccessRanksData, ranksData]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: StyleConstants.NAV_BAR_HEIGHT,
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={useBulkEditMutation}
          formConfig={fancyFormElementsBulkEdit.config}
          formFields={formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
