import { isEmptyValue } from 'app/components';
import { P } from 'types/Permission';

export const hasPermission = ({ userPermissions, scopes }): boolean => {
  return (
    isEmptyValue(scopes) ||
    scopes.every(
      scope =>
        scope === P.Unset ||
        (userPermissions && userPermissions.includes(scope)),
    )
  );
};
