import React, { useEffect, useMemo, useState } from 'react';
import { CopyWorkorder } from 'app/pages/WorkOrderDetailsPage/CopyWorkorder';
import moment from 'moment';
import { useLazyGetAssetByIdQuery } from 'services/assetApi';
import { useLazyGetGeneralAssetByIdQuery } from 'services/generalAssetApi';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory } from 'types/Attachment';
import { WorkOrderCard } from 'types/WorkOrders';

interface CopyWorkorderContainerProps {
  selectedWorkOrder: WorkOrderCard | null;
  isCopyWoDrawerOpen: boolean;
  onClose: () => void;
}

export const CopyWorkorderContainer: React.FC<CopyWorkorderContainerProps> = ({
  selectedWorkOrder,
  isCopyWoDrawerOpen,
  onClose,
}) => {
  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery({});
  const { data: assetObject, isSuccess: isSuccessAsset } = resultAsset;

  const [triggerOfGeneralAsset, resultOfGeneralAsset] =
    useLazyGetGeneralAssetByIdQuery();
  const { data: generalAssetObject, isSuccess: isSuccessGeneralAsset } =
    resultOfGeneralAsset;

  useEffect(() => {
    if (!isCopyWoDrawerOpen) {
      return;
    }
    if (selectedWorkOrder?.assetId) {
      triggerAsset(selectedWorkOrder.assetId);
    }
    if (selectedWorkOrder?.generalAssetId) {
      triggerOfGeneralAsset(selectedWorkOrder?.generalAssetId);
    }
  }, [selectedWorkOrder?.assetId, selectedWorkOrder?.generalAssetId]);

  const { data: wOAttachments, isSuccess: isSuccessWOAttachments } =
    useGetAttachmentQuery(
      {
        module: 'workorder',
        moduleId: selectedWorkOrder?.id ?? 0,
        category: AttachmentCategory.Photo,
      },
      {
        skip: !selectedWorkOrder?.id,
      },
    );

  const imagesMemo = useMemo(() => {
    if (wOAttachments && isSuccessWOAttachments) {
      return wOAttachments?.others?.map(attachment => ({
        id: attachment.id,
        preview: attachment.url,
        type: attachment.category,
        name: attachment.fileName,
        description: attachment.description,
        uploadedBy: attachment?.createdByUserEmail,
        uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
        documentType: attachment?.documentCoreType?.name,
        fileType: attachment.fileType,
        attachmentType: attachment.attachmentType,
        expiresOn: attachment.expiresOn,
        originalName: attachment.originalName,
        documentRequiredId: attachment.documentRequiredId,
        moduleId: attachment.moduleId,
      }));
    }
  }, [wOAttachments, isSuccessWOAttachments]);

  const existingDataCopyDrawer = useMemo(() => {
    return {
      locationId: selectedWorkOrder?.locationId,
      locationName: selectedWorkOrder?.locationName,
      assetId: selectedWorkOrder?.assetId,
      areaId: selectedWorkOrder?.assetAreaId,
      problemId: selectedWorkOrder?.problemId,
      problem: selectedWorkOrder?.problem,
      asset: selectedWorkOrder?.generalAssetId
        ? !generalAssetObject
          ? {
              id: selectedWorkOrder?.generalAssetId,
              name: selectedWorkOrder?.generalAssetName,
              isGeneralAsset: selectedWorkOrder?.generalAssetId,
              priorityId: selectedWorkOrder?.priorityId,
              openWoCount: selectedWorkOrder?.openWoCount,
            }
          : {
              ...generalAssetObject,
              isGeneralAsset: selectedWorkOrder?.generalAssetId,
              openWoCount: selectedWorkOrder?.openWoCount,
            }
        : !assetObject
          ? {
              id: selectedWorkOrder?.assetId,
              name: selectedWorkOrder?.assetName,
              priorityId: selectedWorkOrder?.priorityId,
              openWoCount: selectedWorkOrder?.openWoCount,
            }
          : { ...assetObject, openWoCount: selectedWorkOrder?.openWoCount },
      generalAssetId: selectedWorkOrder?.generalAssetId,
      generalAssetName: selectedWorkOrder?.generalAssetName,
      serviceProviderName: selectedWorkOrder?.serviceProviderName,
      spCompanyId: selectedWorkOrder?.spCompanyId,
      tradeId: selectedWorkOrder?.tradeId,
      branchId: selectedWorkOrder?.locationId,
      priorityId: selectedWorkOrder?.priorityId,
      overtimeApproved: selectedWorkOrder?.overtimeApproved,
      requestCategoryId: selectedWorkOrder?.requestCategoryId,
      requestedBy: '',
      description: selectedWorkOrder?.description,
      copyWOAttachments: ['Copy WO Attachments'],
      files: imagesMemo,
      originalCopiedWorkorderId: selectedWorkOrder?.id,
      moduleName: 'workorder',
    };
  }, [
    selectedWorkOrder,
    imagesMemo,
    wOAttachments,
    isSuccessWOAttachments,
    assetObject,
    generalAssetObject,
    isSuccessAsset,
  ]);

  return (
    (assetObject || generalAssetObject) && (
      <CopyWorkorder
        isOpen={isCopyWoDrawerOpen}
        onClose={onClose}
        existingData={existingDataCopyDrawer}
      />
    )
  );
};
