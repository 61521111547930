import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useLazyGetRolePermissionsListQuery,
  useDeleteRolePermissionMutation,
} from 'services/rolePermissionApi';

import {
  ECButton,
  ECDrawerEdit,
  ECEasyTable,
  ECGrid,
  ECPanelBreadcrumbs,
  ECPaper,
} from 'app/components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CreateRole } from './CreateRole';
import { EditRole } from './EditRole';
import { Refresh } from '@mui/icons-material';
import { handleRequestConfigs } from 'utils/ECEasyTableUtils';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';

const tableConfig = require('./roles_table_config.json');

let config = tableConfig.data.config;

let queryParams;

export function AdminRolesPage() {
  const { t } = useTranslation();

  const [trigger, result] = useLazyGetRolePermissionsListQuery();
  const [deleteRolePermissionTrigger] = useDeleteRolePermissionMutation();
  const [open, setOpen] = useState(false);
  const [whichModal, setWhichModal] = useState('create');
  const [row, setRow] = useState({});

  const { data: response, isFetching, isLoading } = result;

  const deleteRolePermission = input => {
    const { roleId, permissionId } = input;
    deleteRolePermissionTrigger({
      roleId,
      permissionId,
      lastQueryParams: queryParams,
    });
  };

  const handleRequest = useCallback(
    (params?: any) => {
      handleRequestConfigs(params, trigger, config);
    },
    [trigger],
  );

  useEffect(() => {
    handleRequest(config);
    loadPageScopes('role');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshClick = () => {
    handleRequest(config);
  };

  const handleAddNewClick = () => {
    setWhichModal('create');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = row => {
    setRow(row);
    setWhichModal('edit');
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>{t('translation:pages.roles.title')}</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECGrid sx={{ padding: 2.3 }}>
        <ECGrid item xs={12} sx={{ marginLeft: 2 }}>
          <ECPanelBreadcrumbs />
        </ECGrid>
        <ECDrawerEdit open={open} anchor="right" onClose={handleClose}>
          <ECPaper
            sx={{ height: '100%', paddingTop: '80px' }}
            role="presentation"
          >
            {whichModal === 'create' ? (
              <CreateRole
                onClose={handleClose}
                onRoleAdded={handleRefreshClick}
              />
            ) : (
              <EditRole
                lastQueryParams={queryParams}
                onRoleEdited={() => {
                  handleRefreshClick();
                }}
                role={row}
                onClose={handleClose}
              />
            )}
          </ECPaper>
        </ECDrawerEdit>
        <ECEasyTable
          config={config}
          data={response?.data}
          onDataRequest={handleRequest}
          isLoading={isLoading}
          isSendingRequest={isFetching}
          onRowPress={handleRowClick}
          onChipDelete={(data, chipData) => {
            const { permissionId } = chipData;
            const { roleId } = data;
            deleteRolePermission({ permissionId, roleId });
          }}
          headerChildren={
            <>
              <ECButton
                onClick={handleAddNewClick}
                startIcon={<AddCircleOutlineIcon />}
                variant="outlined"
              >
                {t('translation:dynamicPageTemplate.config.create')}
              </ECButton>
              <ECButton
                onClick={handleRefreshClick}
                startIcon={<Refresh />}
                variant="outlined"
                sx={{ margin: '16px' }}
              >
                {t('translation:dynamicPageTemplate.config.refresh')}
              </ECButton>
            </>
          }
          enableExport={true}
          downloadFileName="roles.csv"
          shouldNotUseActiveFilter
        />
      </ECGrid>
    </>
  );
}
