import {
    ECButton,
} from 'app/components';
import { useCallback, useEffect } from 'react';
import { useAcceptRfpProposalMutation } from 'services/proposalApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { moduleApi } from 'services/moduleApi';
import { Check } from '@mui/icons-material';
import { themes } from 'styles/theme/themes';
import { requestForPricingApi } from 'services/requestForPricingApi';

interface AcceptRfpProposalButtonProps {
    proposal: any;
    statusToId: number;
    onSave?: () => void;
    disabled?: boolean;
}
  
  export function AcceptRfpProposalButton({ proposal, statusToId, onSave, disabled }: AcceptRfpProposalButtonProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [doAcceptRfpProposal, { isLoading: isLoadingAcceptRfpProposal, isSuccess: isSuccessAcceptRfpProposal }] = useAcceptRfpProposalMutation();

    useEffect(() => {
        if (isSuccessAcceptRfpProposal) {
            dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
            dispatch(requestForPricingApi.util.invalidateTags(['RfpItem']));
            dispatch(
                setSnackbar({
                severity: 'success',
                message: 'Proposal status updated successfully',
                }),
            );
            onSave?.();
        }
    },[isSuccessAcceptRfpProposal, dispatch, onSave]);

    const handleAcceptRfpProposal = useCallback(() => {
        doAcceptRfpProposal({ id: proposal.id, statusTo: statusToId });
    }, [doAcceptRfpProposal, proposal.id, statusToId]);

    return (
        <ECButton
            variant="outlined"
            color="success"
            startIcon={
              <Check sx={{ color: theme => theme.palette.success.main }} />
            }
            sx={theme => ({
              border: 1,
              borderColor: `${theme.palette.success.outlinedRestingBackground} !important`,
              color: `${theme.palette.success.main} !important`,
              marginRight: 2,
            })}
            onClick={handleAcceptRfpProposal}
            isLoading={isLoadingAcceptRfpProposal}
            loadingSpinnerColor={themes.light.palette.success.main}
            disabled={disabled}
          >
            Accept
          </ECButton>
    );
  }
