import { useNavigate } from 'react-router-dom';
import {
  ECBox,
  ECButton,
  ECTypography,
  ECCopyright,
  ECTechTrakNotLoggedInBar,
  ECMainBox,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import techTrakLogo from '../../../assets/techtrak-logo.svg';
import appStoreBadge from '../../../assets/appstore-badge.svg';
import googlePlayBadge from '../../../assets/googleplay-badge.svg';
import { Link } from '@mui/material';

export function CreateSPSuccessPage() {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate('/', { state: { redirectedFrom: '/panel/sp/customers' } });
  };

  return (
    <>
      <Helmet>
        <title>Thank you for joining!</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainBox
        component="main"
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        flexDirection="column"
      >
        <ECTechTrakNotLoggedInBar />
        <ECBox
          display="flex"
          flex={2}
          flexDirection="column"
          px={10}
          justifyContent="center"
          width="100%"
          sx={theme => ({
            background: `linear-gradient(to top, ${theme.palette.primary.main}, ${theme.palette.primary.containedHoverBackground})`,
          })}
          gap={4}
        >
          <ECTypography
            textAlign="center"
            fontWeight="bold"
            variant="h3"
            color={theme => theme.palette.common.white}
          >
            Thank you for joining!
          </ECTypography>
          <img height={86} src={techTrakLogo} alt="Ecotrac LLC" />
        </ECBox>

        <ECBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={3}
          mt={4}
          px={10}
          gap={10}
        >
          <ECBox display="flex" flexDirection="column" gap={2}>
            <ECButton variant="contained" onClick={handleExploreClick}>
              Explore Techtrak
            </ECButton>
            <ECButton
              variant="outlined"
              href="https://ecotrak.zendesk.com/hc/en-us/categories/8295898460820-Service-Provider-Getting-Started"
            >
              Tutorial to help you get started
            </ECButton>
          </ECBox>

          <ECBox display="flex" alignItems="center" justifyContent="center">
            <Link href="https://apps.apple.com/us/app/techtrak/id6465394992">
              <img height={40} src={appStoreBadge} alt="AppStore Download" />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.ecotrak.techtrak2&hl=en&gl=US">
              <img
                height={60}
                src={googlePlayBadge}
                alt="GooglePlay Download"
              />
            </Link>
          </ECBox>

          <ECCopyright
            variant="caption"
            color={theme => theme.palette.text.secondary}
          />
        </ECBox>
      </ECMainBox>
    </>
  );
}
