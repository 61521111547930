import { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  ECButton,
  ECChipAutocomplete,
  ECFormControl,
  ECFormControlLabel,
  ECMenuItem,
  ECSelect,
  ECTextField,
  ECTypography,
} from '..';
import { ECBox } from '../ECBox';
import { InputLabel, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ECCheckbox } from '../ECCheckbox';
import {
  useGetResolutionsQuery,
  useGetWarrantyPeriodsQuery,
} from 'services/lookupApi';
import { Lookup } from 'types/Lookup';
import _ from 'lodash';

export interface ECFailureWarrantyProps {
  options: Lookup[];
  variant: 'standard' | 'filled' | 'outlined';
  validationMessage?: string;
  readOnly?: boolean;
  visible?: boolean;
  label?: string;
  defaultValue?: FailureWarrantyOutput[];
  isLoading?: boolean;
  required?: boolean;
  onChange?: (output: any) => void;
  isError?: boolean;
}

export interface FailureWarrantyOutput {
  repairId: number;
  repairName: string;
  warranty?: WarrantyOutput;
}

export const ECFailureWarranty = ({
  options,
  variant,
  validationMessage,
  readOnly,
  label,
  defaultValue,
  isLoading,
  visible = true,
  required,
  onChange,
  isError,
}: ECFailureWarrantyProps) => {
  const { t } = useTranslation();
  const [output, setOutput] = useState<FailureWarrantyOutput[] | undefined>(
    defaultValue,
  );

  useEffect(() => {
    if (!_.isEqual(defaultValue, output)) {
      setOutput(defaultValue);
    }
  }, [defaultValue]);

  const handleFailuresChange = (failureTypes: Chip[]) => {
    setOutput(prevOutput => {
      const updatedOutput = failureTypes.map(failureType => ({
        repairId: failureType.id || 0,
        repairName: failureType.label,
        warranty: prevOutput?.find(
          prevOutputField => prevOutputField.repairId === failureType.id,
        )?.warranty,
      }));

      onChange?.(updatedOutput);
      return updatedOutput;
    });
  };

  const handleRemoveFailureType = useCallback(
    (repairIdToRemove: number) => () => {
      if (output) {
        const updatedOutput = output.filter(
          failureType => failureType.repairId !== repairIdToRemove,
        );
        setOutput(updatedOutput);
        onChange?.(updatedOutput);
      }
    },
    [output],
  );

  if (!visible) {
    return null;
  }

  if (readOnly) {
    return (
      <>
        <ECTypography variant="caption" color="text.secondary">
          {label}
        </ECTypography>
        {defaultValue?.map((failureType, index) => (
          // leaving below key as index because values of failure types were undefined
          <ECBox
            key={index}
            display="flex"
            mb={1}
            width="100%"
            flexDirection="column"
          >
            <ECTypography variant="body1" fontWeight="bold">
              {failureType.repairName}
            </ECTypography>

            {failureType.warranty?.noWarranty ? (
              <ECTypography ml={4} my={0.5} variant="body1">
                No Warranty
              </ECTypography>
            ) : null}
            <ECTypography ml={4} my={0.5} variant="body2">
              Resolution: {failureType.warranty?.resolution}
            </ECTypography>
            {!failureType.warranty?.noWarranty ? (
              <ECTypography ml={4} my={0.5} variant="body2">
                Warranty Periods: {failureType.warranty?.period}{' '}
                {failureType.warranty?.periodLabel}
              </ECTypography>
            ) : null}
          </ECBox>
        ))}
      </>
    );
  }

  return (
    <ECBox display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <ECChipAutocomplete
        loading={isLoading}
        options={
          options?.map(option => ({
            id: option.id,
            label: option.name,
          })) || []
        }
        title={label || 'Failure Types'}
        values={
          output?.map(failureTypes => ({
            id: failureTypes.repairId,
            label: failureTypes.repairName,
          })) || []
        }
        getOptionLabel={option => option?.label || option?.text || option}
        variant={variant}
        onChange={newValue => {
          handleFailuresChange(newValue);
        }}
        errorMessage={
          isError && !output?.length && required
            ? t('translation:form.validation.required', { name: label })
            : undefined
        }
      />

      {output?.map(failureType => (
        <ECBox key={failureType.repairId} display="flex" py={1} width="100%">
          <ECWarrantyField
            {...failureType}
            onRemove={handleRemoveFailureType}
            isError={isError}
            onChange={warranty => {
              const updatedOutput = [...output];
              const updatedWarrantyIndex = updatedOutput.findIndex(
                failure => failure.repairId === failureType.repairId,
              );
              const updatedWarranty = {
                repairId: failureType.repairId,
                repairName: failureType.repairName,
                warranty,
              };

              updatedOutput[updatedWarrantyIndex] = updatedWarranty;
              setOutput(updatedOutput);
              onChange?.(updatedOutput);
            }}
          />
        </ECBox>
      ))}
    </ECBox>
  );
};

export interface WarrantyOutput {
  id?: number;
  noWarranty?: boolean;
  period?: number;
  periodLabel?: string;
  periodCode?: string;
  resolutionId?: number;
  resolution?: string;
}

interface ECWarrantyFieldProps {
  repairId: number;
  repairName: string;
  warranty?: WarrantyOutput;
  onRemove: (repairId: number) => () => void;
  onChange: (warrantyData: WarrantyOutput) => void;
  isError?: boolean;
}

const ECWarrantyField = ({
  repairId,
  repairName,
  warranty,
  onRemove,
  onChange,
  isError,
}: ECWarrantyFieldProps) => {
  const { t } = useTranslation();
  const [warrantyData, setWarrantyData] = useState<WarrantyOutput>(
    warranty || {},
  );

  const { data: resolutions, isLoading: isLoadingResolutions } =
    useGetResolutionsQuery();
  const {
    data: periods,
    isSuccess: isSuccessPeriods,
    isLoading: isLoadingPeriods,
  } = useGetWarrantyPeriodsQuery();

  useEffect(() => {
    if (warrantyData?.noWarranty) {
      setWarrantyData(prevData => ({
        ...prevData,
        period: 0,
      }));
    }
  }, [warrantyData?.noWarranty]);

  useEffect(() => {
    if (isSuccessPeriods && !warranty?.periodCode) {
      const periodWeek = periods?.find(period => period.code === 'W');

      setWarrantyData(prevData => ({
        ...prevData,
        periodLabel: periodWeek?.description,
        periodCode: periodWeek?.code,
      }));
    }
  }, [periods, isSuccessPeriods, warranty?.periodCode]);

  useEffect(() => {
    onChange(warrantyData);
  }, [warrantyData]);

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      width="100%"
      p={1}
      bgcolor={theme => theme.palette.action.hover}
    >
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ECTypography variant="h6">{repairName}</ECTypography>

        <ECButton variant="text" color="error" onClick={onRemove(repairId)}>
          Remove
        </ECButton>
      </ECBox>

      <ECBox px={2} py={1}>
        <ECFormControlLabel
          control={
            <ECCheckbox
              name="noWarranty"
              value="noWarranty"
              checked={warrantyData?.noWarranty}
              onChange={event => {
                setWarrantyData(prevData => ({
                  ...prevData,
                  noWarranty: event.target.checked,
                }));
              }}
            />
          }
          label="No Warranty"
        />
      </ECBox>

      <ECBox display="flex" width="100%" gap={1}>
        <ECTextField
          name="warrantyPeriod"
          sx={{ flex: 1 }}
          inputProps={{
            maxLength: 3,
            readOnly: warrantyData?.noWarranty,
          }}
          error={isError && !warrantyData?.period && warrantyData?.period !== 0}
          helperText={
            !warrantyData?.period && warrantyData?.period !== 0
              ? t('translation:form.validation.required', {
                  name: t('translation:failureWarranty.warrantyPeriod'),
                })
              : null
          }
          variant="filled"
          label={t('translation:failureWarranty.warrantyPeriod') + '*'}
          placeholder={t('translation:failureWarranty.warrantyPeriod')}
          value={warrantyData?.period}
          disabled={warrantyData?.noWarranty}
          InputLabelProps={
            warrantyData?.period || warrantyData?.period === 0
              ? { shrink: true }
              : {}
          }
          onChange={event => {
            setWarrantyData(prevData => ({
              ...prevData,
              period: +event.target.value.replace(/\D+/g, ''),
            }));
          }}
        />

        <ECFormControl variant="filled" sx={{ flex: 1 }}>
          <ECSelect
            name="warrantyPeriodLabel"
            variant="filled"
            hiddenLabel
            value={warrantyData?.periodCode || ''}
            disabled={warrantyData?.noWarranty}
            renderValue={value => {
              const periodDescription = periods?.find(
                period => period.code === value,
              )?.description;

              return (
                <ECBox display="flex" alignItems="center">
                  <ECTypography>{periodDescription}</ECTypography>
                </ECBox>
              );
            }}
            onChange={event => {
              const period = periods?.find(
                periodLabelOption =>
                  periodLabelOption.code === event.target.value,
              );
              setWarrantyData(prevData => ({
                ...prevData,
                periodLabel: period?.description,
                periodCode: period?.code,
              }));
            }}
          >
            {isLoadingPeriods && <ECTypography>Loading...</ECTypography>}
            {periods?.map((option, index) => {
              return (
                <ECMenuItem
                  value={option.code}
                  key={`${option.code}-${option.description}-${option.eul}-${index}`}
                >
                  <ECBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ECTypography>{option.description}</ECTypography>
                  </ECBox>
                </ECMenuItem>
              );
            })}
          </ECSelect>
        </ECFormControl>

        <ECFormControl variant="filled" sx={{ flex: 2 }}>
          <InputLabel>
            {t('translation:failureWarranty.resolution') + '*'}
          </InputLabel>
          <ECSelect
            name="warrantyResolution"
            variant="filled"
            error={isError && !warrantyData?.resolution}
            value={warrantyData?.resolutionId}
            renderValue={value => {
              const label = resolutions?.find(
                resolution => resolution.id === value,
              )?.name;

              return (
                <ECBox display="flex" alignItems="center">
                  <ECTypography>{label}</ECTypography>
                </ECBox>
              );
            }}
            onChange={event => {
              setWarrantyData(prevData => ({
                ...prevData,
                resolutionId: resolutions?.find(
                  resolution => resolution.id === event.target.value,
                )?.id,
                resolution: resolutions?.find(
                  resolution => resolution.id === event.target.value,
                )?.name,
              }));
            }}
          >
            {isLoadingResolutions && <ECTypography>Loading...</ECTypography>}
            {resolutions?.map((option, index) => {
              return (
                <ECMenuItem
                  value={option.id}
                  key={`${option.id}-${option.name}-${option.openDate}-${index}`}
                >
                  <ECBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ECTypography>{option.name}</ECTypography>
                  </ECBox>
                </ECMenuItem>
              );
            })}
          </ECSelect>
          {isError && !warrantyData?.resolution && (
            <FormHelperText
              sx={theme => ({
                color: theme.palette.error.main,
              })}
            >
              {t('translation:form.validation.required', {
                name: t('translation:failureWarranty.resolution'),
              })}
            </FormHelperText>
          )}
        </ECFormControl>
      </ECBox>
    </ECBox>
  );
};
