import React, { useCallback, useEffect, useState } from 'react';
import { ECEasyFormCreate } from 'app/components';
import {
  useCreateOrganizationNodeMutation,
  useGetOrganizationListQuery,
  useGetOrganizationTypeListQuery,
} from 'services/organizationApi';
import { eachDeep } from 'deepdash-es/standalone';
import { OrganizationCreate } from 'types/Organization';

const fancyFormElements = require('./fancy_form_config_create.json');

interface CreatProps {
  onCreate: () => void;
}

const MAX_DEPTH = 3;

export function Create({ onCreate }: CreatProps) {
  const [formFields, setFormFields] = useState(fancyFormElements.data.fields);

  const { data: orgs, isSuccess: isSuccessOrg } = useGetOrganizationListQuery();

  const { data: orgTypes } = useGetOrganizationTypeListQuery();

  const getAllOrganizationNames = useCallback((): string[] => {
    const orgNames: any[] = [];

    eachDeep(
      orgs,
      (org, key, parent, context) => {
        if (context.depth < MAX_DEPTH) {
          orgNames.push({ label: org.name, id: org.id });
        }
      },
      { childrenPath: ['children'] },
    );

    return orgNames;
  }, [orgs]);

  useEffect(() => {
    if (orgs && isSuccessOrg) {
      const newFields = formFields.map(formField => {
        if (formField.fieldName === 'node') {
          const orgNames = getAllOrganizationNames();
          return {
            ...formField,
            options: orgNames,
            optionValues: orgNames,
            value: null,
          };
        }
        if (
          orgTypes &&
          orgTypes?.length &&
          formField.fieldName === 'orgTypes'
        ) {
          return {
            ...formField,
            options: orgTypes?.map(type => type.name),
            optionValues: orgTypes?.map(type => type.id),
            value: null,
          };
        }
        return formField;
      });
      setFormFields(newFields);
    }
  }, [orgs, isSuccessOrg, orgTypes]);

  const [doCreate, { data, isError, error, isLoading, isSuccess }] =
    useCreateOrganizationNodeMutation();

  useEffect(() => {
    if (isSuccess) {
      onCreate?.();
    }
  }, [isSuccess, onCreate]);

  const useCreateMutation = useCallback(() => {
    const doCreateOrg = async createData => {
      const newOrg: OrganizationCreate = {
        parentId: createData?.node?.id,
        name: createData?.name,
        organizationType: createData?.orgTypes?.id,
      };

      await doCreate(newOrg);
    };

    return [doCreateOrg, { data, isError, error, isLoading, isSuccess }];
  }, [isSuccess, data, doCreate, error, isError, isLoading]);

  return (
    <ECEasyFormCreate
      formConfig={fancyFormElements.data.config}
      formFields={formFields}
      useCreateMutation={useCreateMutation}
    />
  );
}
