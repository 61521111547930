import {
  ECBox,
  ECButton,
  ECGrid,
  ECTypography,
  ECTextField,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import Logo from '../../../assets/techtrak-logo.svg';
import { Help } from '@mui/icons-material';
import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyValidateInvitationCodeQuery } from 'services/spAuthApi';
import backgroundImage from '../../../assets/sp-signup-bg-image.jpeg';

export function CreateSPPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [code, setCode] = useState(searchParams.get('code') || '');
  const [errorMessage, setErrorMessage] = useState('');

  const [validateCode, { data, isSuccess, isError, error, isLoading }] =
    useLazyValidateInvitationCodeQuery();

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      setErrorMessage('');
      navigate('/sp-self-signup/register', {
        state: { code, ...data },
      });
    }
  }, [isSuccess, isLoading, data]);

  useEffect(() => {
    if (!isLoading && isError) {
      setErrorMessage((error as any)?.data?.message);
    }
  }, [isError, isLoading]);

  const validateInvitationCode = (code: string) => {
    if (_.isEmpty(code)) {
      setErrorMessage('Required');
      return;
    }

    if (code?.length < 8) {
      setErrorMessage('Invalid code');
      return;
    }

    validateCode(code);
  };

  const handleCreateCompanyClick = () => {
    validateInvitationCode(code);
  };

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECGrid container justifyContent="center" alignItems="center">
        <ECGrid item xs={7}>
          <ECBox
            px={10}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.6)', // overlay to cover background image
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100vh"
          >
            <ECTypography
              variant="h3"
              color={theme => theme.palette.common.white}
            >
              Welcome to
            </ECTypography>

            <img
              src={Logo}
              alt="logo"
              style={{
                height: '88px',
                width: '402px',
                zIndex: 1,
              }}
            />
          </ECBox>
        </ECGrid>

        <ECGrid item xs={5}>
          <ECBox
            px={10}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100vh"
            gap={4}
          >
            <ECTypography variant="h4">Add Invitation Code</ECTypography>

            <ECTextField
              variant="filled"
              placeholder="xxx-xxxx"
              label="Invitation Code"
              required
              mask="###-####"
              error={!!errorMessage}
              helperText={errorMessage}
              value={code}
              onChange={e => setCode(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleCreateCompanyClick();
                }
              }}
            />

            <ECButton
              variant="contained"
              disabled={!code}
              onClick={handleCreateCompanyClick}
              isLoading={isLoading}
            >
              Create Company
            </ECButton>

            <ECTypography>
              <Help /> Need help with Ecotrak?{' '}
              <Link href="https://ecotrak.zendesk.com/hc/en-us/categories/8295898460820-Service-Provider-Getting-Started">
                Click here
              </Link>
            </ECTypography>
          </ECBox>
        </ECGrid>
      </ECGrid>
    </>
  );
}
