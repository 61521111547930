import React, { useState } from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import moment from 'moment';
import { WorkOrderCard } from 'types/WorkOrders';
import { ECBox, ECTypography } from 'app/components';
import { formatDateOnlyForTable } from 'utils/strings/formatDate';
import SortableWorkOrderCardItem from './SortableWorkOrderCardItem';
import { DispatchWorkOrderCard } from './DispatchWorkOrdersCard';
import { useDroppable } from '@dnd-kit/core';

interface DateWorkordersSectionProps {
  technicianName: string;
  technicianId: number;
  date: {
    date: string;
    workorders: WorkOrderCard[];
  };
  openCopyWoDrawer: (value: boolean, workOrder: any) => void;
  dragPanelId?: string;
}

const DateWorkordersSection: React.FC<DateWorkordersSectionProps> = ({
  technicianId,
  technicianName,
  date,
  dragPanelId,
  openCopyWoDrawer,
}) => {
  const objectId = `${technicianId}|${technicianName}|${date.date}`;
  const { setNodeRef } = useDroppable({ id: objectId });

  return (
    <SortableContext
      id={objectId}
      items={date.workorders}
      strategy={verticalListSortingStrategy}
    >
      <ECBox
        pr={1}
        ref={setNodeRef}
        sx={{
          position: 'relative',
          backgroundColor: dragPanelId === objectId ? '#e1f5fe' : 'inherit',
          '&::after':
            dragPanelId === objectId
              ? {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: '1px dashed rgba(0, 0, 0, 0.3)',
                  pointerEvents: 'none',
                  zIndex: 1,
                }
              : undefined,
        }}
      >
        <ECTypography variant="caption" sx={{ opacity: '0.5' }}>
          {moment(date.date).isSame(new Date(), 'day')
            ? 'Today'
            : formatDateOnlyForTable(date.date)}
        </ECTypography>
        {date.workorders?.map((workorder: WorkOrderCard) => (
          <SortableWorkOrderCardItem key={workorder.id} id={workorder.id}>
            <DispatchWorkOrderCard
              workOrder={workorder}
              openCopyWoDrawer={openCopyWoDrawer}
            />
          </SortableWorkOrderCardItem>
        ))}
      </ECBox>
    </SortableContext>
  );
};

export default DateWorkordersSection;
