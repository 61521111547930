import { QueryParams } from 'types/QueryParams';
import { FlagCreate, FlagListFlat, FlagUpdate } from 'types/Flag';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Flags'],
});

export const flagsApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getFlagsList: builder.query<BaseType<FlagListFlat[]>, QueryParams>({
      query: params => {
        return {
          url: 'flag',
          params,
        };
      },
      providesTags: ['Flags'],
    }),
    createFlags: builder.mutation<void, FlagCreate>({
      query: body => {
        return {
          url: 'flag',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Flags'],
    }),
    updateFlags: builder.mutation<void, FlagUpdate>({
      query: ({ id, ...body }) => {
        return {
          url: `flag/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Flags'],
    }),
  }),
});

export const {
  useGetFlagsListQuery,
  useLazyGetFlagsListQuery,
  useUpdateFlagsMutation,
  useCreateFlagsMutation,
} = flagsApi;
