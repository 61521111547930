import {
  useLazyGetBranchesListQuery,
  useCreateBranchMutation,
  useGetBranchTypesQuery,
  useUpdateBranchMutation,
} from 'services/branchApi';
import { useGetOrganizationParentListQuery } from 'services/organizationApi';
import { useGetWarrantyPeriodsQuery } from 'services/lookupApi';
import {
  ECButton,
  ECDynamicPageTemplate,
  ECEasyForm,
  ECEasyFormFieldType,
  ECTypography,
  FieldTypes,
} from 'app/components';
import { populateDropDownFields } from 'utils/pageUtils';
import { loadPageScopes } from 'utils/pageScopes';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { BranchUpdateFormData, Address } from 'types/Branch';
import moment from 'moment';
import * as _ from 'lodash';
import { themes } from 'styles/theme/themes';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { ECModal } from 'app/components/ECModal';
import { useGetBranchesListQuery } from 'services/branchApi';
import { ContentCopy } from '@mui/icons-material';
import { P } from 'types/Permission';
import { useCopyAssetsToLocationMutation } from 'services/assetApi';

const editFormElements = require('./fancy_form_config_edit.json');
const createFormElements = require('./fancy_form_config_create.json');
const formElementsDetails = require('./fancy_form_config_details.json');
const leaseOption1PeriodCode = 'leaseOption1PeriodCode';
const leaseOption2PeriodCode = 'leaseOption2PeriodCode';

const fancyFormConfirmInactiveStatus = require('./fancy_form_config_confirm.json');

export function Branches({ marginTop = true }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('branch');
    dispatch(setFilterEndpoint('/filter/branches'));
  }, []);

  const [row, setRow] = useState<any>();

  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const [isCopyAssetsModalOpen, setIsCopyAssetsModalOpen] = useState(false);
  // const [currentData, setCurrentData] = useState<any>();
  const [createFormFields, setCreateFormFields] = useState<any>(
    _.clone(createFormElements.fields) || [],
  );
  const [editFormFields, setEditFormFields] = useState<any>(
    _.clone(editFormElements.fields) || [],
  );

  const currentData = useRef<any>();

  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    if (userProfile?.costCenterEnabled) {
      // create
      const costCenterField = createFormFields
        .find(field => field.groupName === 'salesAndCostCenter')
        ?.subFields?.find(field => field.fieldName === 'costCenter');
      costCenterField.visible = true;

      // edit
      const editCostCenterField = editFormFields
        ?.find(field => field.groupName === 'salesAndCostCenter')
        ?.subFields?.find(field => field.fieldName === 'costCenter');
      editCostCenterField.visible = true;

      //details
      const detailsCostCenterField = formElementsDetails?.fields
        ?.find(field => field.groupName === 'salesAndCostCenter')
        ?.subFields?.find(field => field.fieldName === 'costCenter');
      detailsCostCenterField.visible = true;

      setEditFormFields([...editFormFields]);
      setCreateFormFields([...createFormFields]);
    }
  }, [userProfile?.costCenterEnabled]);

  useEffect(() => {
    const editAssetTypeField = editFormFields.find(
      field => field.fieldName === 'branchType',
    );
    editAssetTypeField.useQuery = useGetBranchTypesQuery;
    editAssetTypeField.obAlias = 'name';

    const createAssetTypeField = createFormFields.find(
      field => field.fieldName === 'branchType',
    );
    createAssetTypeField.useQuery = useGetBranchTypesQuery;
    createAssetTypeField.obAlias = 'name';

    setEditFormFields([...editFormFields]);
    setCreateFormFields([...createFormFields]);
  }, []);

  const { data: dataOrgLevelList, isSuccess: isSuccessOrgLevel } =
    useGetOrganizationParentListQuery();

  useEffect(() => {
    if (isSuccessOrgLevel && dataOrgLevelList) {
      populateDropDownFields({
        responseData: dataOrgLevelList,
        createFormFields,
        editFormFields,
        isPopulateForChipsComponent: true,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'organization',
      });
    }
  }, [isSuccessOrgLevel, dataOrgLevelList]);

  useEffect(() => {
    const copyTradeAssignmentLocationIdField = createFormFields.find(
      field => field.fieldName === 'copyTradeAssignmentLocationId',
    );
    copyTradeAssignmentLocationIdField.useQuery = useGetBranchesListQuery;
    copyTradeAssignmentLocationIdField.obAlias = 'brnch.name';
    copyTradeAssignmentLocationIdField.queryParams = { st: 1 };
    setCreateFormFields([...createFormFields]);
  }, []);

  const { data: periods, isSuccess: isSuccessPeriods } =
    useGetWarrantyPeriodsQuery();

  useEffect(() => {
    const leaseOptionField1 = formElementsDetails.fields
      .filter(field => field.type === 'group' && field.subFields)
      .flatMap(field => field.subFields)
      .find(subField => subField.fieldName === leaseOption1PeriodCode);
    leaseOptionField1.value = '';

    const editLeaseOptionField1 = editFormElements.fields
      .filter(field => field.type === 'group' && field.subFields)
      .flatMap(field => field.subFields)
      .find(subField => subField.fieldName === leaseOption1PeriodCode);

    const leaseOptionField2 = formElementsDetails.fields
      .filter(field => field.type === 'group' && field.subFields)
      .flatMap(field => field.subFields)
      .find(subField => subField.fieldName === leaseOption2PeriodCode);
    leaseOptionField2.value = '';

    const editLeaseOptionField2 = editFormElements.fields
      .filter(field => field.type === 'group' && field.subFields)
      .flatMap(field => field.subFields)
      .find(subField => subField.fieldName === leaseOption2PeriodCode);

    if (isSuccessPeriods && periods) {
      populateDropDownFields({
        responseData: periods,
        editFormFields: editFormElements.fields
          .filter(field => field.type === 'group' && field.subFields)
          .flatMap(field => field.subFields),
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: leaseOption1PeriodCode,
      });
      editLeaseOptionField1.value = '';

      populateDropDownFields({
        responseData: periods,
        editFormFields: editFormElements.fields
          .filter(field => field.type === 'group' && field.subFields)
          .flatMap(field => field.subFields),
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: leaseOption2PeriodCode,
      });
      editLeaseOptionField2.value = '';

      if (row?.leaseOption1PeriodCode != null) {
        const foundItem = periods?.find(
          item => item.code === row?.leaseOption1PeriodCode,
        );
        leaseOptionField1.value = foundItem?.description;
        editLeaseOptionField1.value = foundItem?.code;
      }

      if (row?.leaseOption2PeriodCode != null) {
        const foundItem = periods?.find(
          item => item.code === row?.leaseOption2PeriodCode,
        );
        leaseOptionField2.value = foundItem?.description;
        editLeaseOptionField2.value = foundItem?.code;
      }
    }
  }, [isSuccessPeriods, periods, row]);

  const isCustomer = useCustomerUser();

  const resetRow = useCallback(() => {
    setRow({});
    setCreateFormFields(_.clone(createFormElements.fields) || []);
    setEditFormFields(_.clone(editFormElements.fields) || []);
  }, []);

  const [
    doUpdateLocation,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateBranchMutation();

  const [
    doCopyAssetToLocation,
    {
      isLoading: isLoadingCopyAssetsToLocation,
      isSuccess: isCopyAssetsToLocationSuccess,
    },
  ] = useCopyAssetsToLocationMutation();

  useEffect(() => {
    if (!isLoadingCopyAssetsToLocation && isCopyAssetsToLocationSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `Assets for Location ${t(
            'translation:dynamicForm.updateSuccess',
          )}`,
        }),
      );
      handleCloseCopyAssets();
    }
  }, [isLoadingCopyAssetsToLocation, isCopyAssetsToLocationSuccess]);

  const useUpdateLocation = useCallback(() => {
    const doUpdate = async data => {
      if (data.status === 0 && !showInactiveModal) {
        setShowInactiveModal(true);
        currentData.current = data;
        return;
      }

      const locationMailingAddress: Address = {
        line1: data.mailingAddress.line1,
        line2: data.mailingAddress.line2,
        zipCodePostalCode: data.mailingAddress.zipCodePostalCode || null,
        cityName: data.mailingAddress.cityName,
        stateProvinceCode: data.mailingAddress.stateProvinceCode,
        countryCode: data.mailingAddress.countryCode,
      };
      const body: BranchUpdateFormData = {
        id: data.id,
        branchId: Number(data.branchType.id),
        parentOrgId: Number(data.organization.id),
        mailingAddress: locationMailingAddress,
        name: data.name,
        storeNumber: data.storeNumber,
        phone: data.phone,
        phoneExt: data.phoneExt,
        leaseLandlordName: data.leaseLandlordName,
        leaseContactName: data.leaseContactName,
        leaseContactPhone: data.leaseContactPhone,
        leaseContactPhoneExt: data.leaseContactPhoneExt,
        leaseContactEmail: data.leaseContactEmail,
        leaseEngineerName: data.leaseEngineerName,
        leaseEngineerPhone: data.leaseEngineerPhone,
        leaseEngineerPhoneExt: data.leaseEngineerPhoneExt,
        leaseEngineerEmail: data.leaseEngineerEmail,
        leaseOption1PeriodCode: data.leaseOption1PeriodCode,
        leaseOption2PeriodCode: data.leaseOption2PeriodCode,
        responsibilities: data.responsibilities,
        averageWeeklySales: data.averageWeeklySales,
        leaseOption1Value: data.leaseOption1Value,
        leaseOption2Value: data.leaseOption2Value,
        costCenter: data.costCenter,
        status: data.status,
        legalEntityName: data.legalEntityName,
      };

      if (data.leaseStartDate) {
        body.leaseStartDate = moment(data.leaseStartDate)?.format('YYYY-MM-DD');
      }
      if (data.leaseEndDate) {
        body.leaseEndDate = moment(data.leaseEndDate)?.format('YYYY-MM-DD');
      }

      if (data.leaseLandlordAddress.line1 !== '') {
        const locationLandlordAddress: Address = {
          line1: data.leaseLandlordAddress.line1,
          line2: data.leaseLandlordAddress.line2,
          zipCodePostalCode:
            data.leaseLandlordAddress.zipCodePostalCode || null,
          cityName: data.leaseLandlordAddress.cityName,
          stateProvinceCode: data.leaseLandlordAddress.stateProvinceCode,
          countryCode: data.leaseLandlordAddress.countryCode,
        };
        if (
          !_.isNil(locationLandlordAddress?.zipCodePostalCode) &&
          !_.isEmpty(locationLandlordAddress?.zipCodePostalCode) &&
          !_.isNil(locationLandlordAddress?.cityName) &&
          !_.isEmpty(locationLandlordAddress?.cityName) &&
          !_.isNil(locationLandlordAddress?.stateProvinceCode) &&
          !_.isEmpty(locationLandlordAddress?.stateProvinceCode) &&
          !_.isNil(locationLandlordAddress?.countryCode) &&
          !_.isEmpty(locationLandlordAddress?.countryCode)
        ) {
          body.leaseLandlordAddress = locationLandlordAddress;
        }
      }
      if (row?.openDate === data.openDate) {
        body.openDate = moment(data.openDate).toDate();
      } else {
        body.openDate = data.openDate;
      }

      doUpdateLocation(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    updateData,
    row,
    showInactiveModal,
  ]);

  const handleCloseModal = () => {
    setShowInactiveModal(false);
  };

  const onConfirmInactive = useCallback(() => {
    const locationMailingAddress: Address = {
      line1: currentData?.current?.mailingAddress.line1,
      line2: currentData?.current?.mailingAddress.line2,
      zipCodePostalCode: currentData?.current?.mailingAddress.zipCodePostalCode,
      cityName: currentData?.current?.mailingAddress.cityName,
      stateProvinceCode: currentData?.current?.mailingAddress.stateProvinceCode,
      countryCode: currentData?.current?.mailingAddress.countryCode,
    };
    const body: BranchUpdateFormData = {
      ...currentData?.current,
      id: currentData?.current?.id,
      branchId: Number(currentData?.current?.branchType.id),
      parentOrgId: Number(currentData?.current?.organization.id),
      mailingAddress: locationMailingAddress,
    };

    if (currentData?.current?.leaseStartDate) {
      body.leaseStartDate = moment(
        currentData?.current?.leaseStartDate,
      )?.format('YYYY-MM-DD');
    }
    if (currentData?.current?.leaseEndDate) {
      body.leaseEndDate = moment(currentData?.current?.leaseEndDate)?.format(
        'YYYY-MM-DD',
      );
    }

    if (currentData?.current?.leaseLandlordAddress.line1 !== '') {
      const locationLandlordAddress: Address = {
        line1: currentData?.current?.leaseLandlordAddress.line1,
        line2: currentData?.current?.leaseLandlordAddress.line2,
        zipCodePostalCode:
          currentData?.current?.leaseLandlordAddress.zipCodePostalCode,
        cityName: currentData?.current?.leaseLandlordAddress.cityName,
        stateProvinceCode:
          currentData?.current?.leaseLandlordAddress.stateProvinceCode,
        countryCode: currentData?.current?.leaseLandlordAddress.countryCode,
      };

      if (
        !_.isNil(locationLandlordAddress?.zipCodePostalCode) &&
        !_.isNil(locationLandlordAddress?.cityName) &&
        !_.isNil(locationLandlordAddress?.stateProvinceCode) &&
        !_.isNil(locationLandlordAddress?.countryCode)
      ) {
        body.leaseLandlordAddress = locationLandlordAddress;
      } else {
        delete body.leaseLandlordAddress;
      }
    } else {
      delete body.leaseLandlordAddress;
    }
    if (row?.openDate === currentData?.current?.openDate) {
      body.openDate = moment(currentData?.current?.openDate).toDate();
    } else {
      body.openDate = currentData?.current?.openDate;
    }

    doUpdateLocation(body);
    setShowInactiveModal(false);
  }, [currentData?.current, doUpdateLocation, row]);

  const inactiveStatusConfirmModal = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormConfirmInactiveStatus.config}
        fields={fancyFormConfirmInactiveStatus.fields}
        saveButtonColor={'error'}
        customDrawerTitle={
          'Inactivating a location will not allow you to create a future Service Request for this location and will be removed from any pertaining PMs'
        }
        onFormSubmit={onConfirmInactive}
        isSendingData={isLoadingUpdate}
        isLoadingForm={false}
        additionalActions={
          <ECButton type="button" variant="text" onClick={handleCloseModal}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, []);

  const handleCopyAssetClick = useCallback(() => {
    setIsCopyAssetsModalOpen(true);
  }, []);

  const handleCloseCopyAssets = useCallback(() => {
    setIsCopyAssetsModalOpen(false);
  }, []);

  useEffect(() => {
    const copyAssetsField = formElementsDetails.fields
      ?.find(field => field.groupName === 'statusAndCopyAssets')
      ?.subFields?.find(field => field.fieldName === 'copyAssets');

    if (copyAssetsField) {
      copyAssetsField.value = (
        <ECButton
          variant="outlined"
          onClick={handleCopyAssetClick}
          sx={{ alignSelf: 'flex-end' }}
          startIcon={<ContentCopy />}
          scopes={[P.EditAsset]}
        >
          Copy Assets
        </ECButton>
      );
    }
  }, []);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    const data = output.reduce((acc, field) => {
      if (field.type === 'group' && field.subFields) {
        field.subFields.forEach(subField => {
          if (subField.type === 'group' && subField.subFields) {
            subField.subFields.forEach(subSubField => {
              acc[subSubField.fieldName] = subSubField.value;
            });
          } else {
            acc[subField.fieldName] = subField.value;
          }
        });
      } else {
        acc[field.fieldName] = field.value;
      }
      return acc;
    }, {});

    currentData.current = data;

    if (fieldName?.indexOf('countryCode') === -1) {
      return;
    }

    let countryCode = data?.['mailingAddress.countryCode'];

    countryCode = Array.isArray(countryCode)
      ? countryCode[0]
      : countryCode?.value || countryCode;

    const newFields = _.clone(output) || [];

    const createZipCodeField = newFields
      ?.find(field => field.groupName === 'cityZipState')
      ?.subFields?.find(
        field => field.fieldName === 'mailingAddress.zipCodePostalCode',
      );

    if (createZipCodeField) {
      createZipCodeField.required = !(countryCode?.toLowerCase() === 'jm');
    }

    setCreateFormFields([...newFields]);

    const editZipCodeField = editFormFields
      ?.find(field => field.groupName === 'cityZipState')
      ?.subFields?.find(
        field => field.fieldName === 'mailingAddress.zipCodePostalCode',
      );

    editZipCodeField.required = !(countryCode?.toLowerCase() === 'jm');

    let countryCodeLease = data?.['leaseLandlordAddress.countryCode'];

    countryCodeLease = Array.isArray(countryCodeLease)
      ? countryCodeLease[0]
      : countryCodeLease?.value || countryCodeLease;

    const editZipCodeFieldLease = editFormFields
      ?.find(field => field.groupName === 'cityZipStateLease')
      ?.subFields?.find(
        field => field.fieldName === 'leaseLandlordAddress.zipCodePostalCode',
      );
    editZipCodeFieldLease.required = !(countryCode?.toLowerCase() === 'jm');
    setEditFormFields([...editFormFields]);
  };

  const handleSubmitCopyAssets = useCallback(
    (data: FormData, fields: ECEasyFormFieldType[]) => {
      const selectedLocation = fields.find(
        field => field.fieldName === 'location',
      )?.value;

      doCopyAssetToLocation({
        fromBranchId: row?.id,
        toBranchId: selectedLocation?.id,
      });
    },
    [row?.id],
  );

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={
          row?.name
            ? row.name + ' - ' + t('translation:workflow.sidebar.location')
            : t('translation:panelDrawer.branches')
        }
        useLazyGetListQuery={useLazyGetBranchesListQuery}
        useCreateMutation={useCreateBranchMutation}
        useUpdateMutation={useUpdateLocation}
        editFormConfig={editFormElements.config}
        editFormFields={editFormElements.fields}
        createFormConfig={createFormElements.config}
        createFormFields={createFormFields}
        detailsConfig={formElementsDetails.config}
        hasDetails={true}
        customDrawerTitle={`Location ${row?.name}`}
        detailsFields={formElementsDetails.fields}
        onRowClick={setRow}
        onDrawerClose={resetRow}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
        showStatusActiveFilter
        showOnlyAttachmentsTab={true}
        onChange={handleOutputChange}
        marginTop={marginTop}
        enableExport
        exportModuleName="branch"
        shouldNotUseActiveFilter
        showHierarchyFilter
        showDrawerDetailTitle={false}
      />
      <ECModal
        isOpen={showInactiveModal}
        onClose={handleCloseModal}
        noPadding
        noPaddingBottom
        sx={{
          width: '35rem',
          maxWidth: '90%',
        }}
      >
        {inactiveStatusConfirmModal}
      </ECModal>

      <ECModal
        isOpen={isCopyAssetsModalOpen}
        onClose={handleCloseCopyAssets}
        p={0}
        sx={{ minWidth: '50vw' }}
      >
        <ECEasyForm
          config={{
            cols: 1,
            submitTitle: 'Copy',
            title: 'Copy Location Assets',
            titleStyle: {
              fontSize: '20px',
            },
            actionButtonsContainerStyle: {
              bgcolor: 'transparent',
            },
          }}
          fields={[
            {
              type: FieldTypes.Custom,
              fieldName: 'label',
              value: (
                <ECTypography variant="body2">
                  Select a Location to Copy Assets to
                </ECTypography>
              ),
            },
            {
              fieldName: 'location',
              type: FieldTypes.SelectPaginated,
              label: 'Location',
              useQuery: useGetBranchesListQuery,
              filterOptionsIds: [row?.id],
              queryParams: { st: 1 },
              obAlias: 'brnch.name',
              placeholder: 'Location',
              variant: 'filled',
              required: true,
            },
          ]}
          isSendingData={isLoadingCopyAssetsToLocation}
          isLoadingForm={false}
          onFormSubmit={handleSubmitCopyAssets}
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              sx={{ height: 42 }}
              onClick={handleCloseCopyAssets}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
          drawerTitleBarBGColor="transparent"
        />
      </ECModal>
    </>
  );
}
