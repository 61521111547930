import * as React from 'react';
import { ThemeProvider as OriginalThemeProvider } from '@mui/material/styles';
import { themes } from './themes';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  return (
    <OriginalThemeProvider theme={themes.light}>
      {React.Children.only(props.children)}
    </OriginalThemeProvider>
  );
};
