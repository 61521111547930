import { alpha, darken, lighten } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

const v2Theme = createTheme({
  palette: {
    primary: {
      main: '#0072CE',
      light: '#5CA0FF',
      dark: '#00489C',
      containedHoverBackground: darken('#0072CE', 0.3),
      outlinedHoverBackground: alpha('#0072CE', 0.08),
      outlinedRestingBackground: alpha('#0072CE', 0.5),
    },
    secondary: {
      main: '#00C1B4',
      light: '#5FF4E6',
      dark: '#009084',
      containedHoverBackground: darken('#00C1B4', 0.3),
      outlinedHoverBackground: alpha('#00C1B4', 0.08),
      outlinedRestingBackground: alpha('#00C1B4', 0.5),
    },
    text: {
      primary: '#000000',
      secondary: alpha('#000000', 0.6),
      disabled: alpha('#000000', 0.38),
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      containedHoverBackground: darken('#F44336', 0.3),
      outlinedHoverBackground: alpha('#F44336', 0.08),
      outlinedRestingBackground: alpha('#F44336', 0.5),
      content: darken('#F44336', 0.6),
      background: lighten('#F44336', 0.9),
    },
    info: {
      main: '#0090FF',
      dark: '#0063CB',
      light: '#69C0FF',
      containedHoverBackground: darken('#0090FF', 0.3),
      outlinedHoverBackground: alpha('#0090FF', 0.08),
      outlinedRestingBackground: alpha('#0090FF', 0.5),
      content: darken('#0090FF', 0.6),
      background: lighten('#0090FF', 0.9),
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      containedHoverBackground: darken('#ED6C02', 0.3),
      outlinedHoverBackground: alpha('#ED6C02', 0.08),
      outlinedRestingBackground: alpha('#ED6C02', 0.5),
      content: darken('#ED6C02', 0.6),
      background: lighten('#ED6C02', 0.9),
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      containedHoverBackground: darken('#4CAF50', 0.3),
      outlinedHoverBackground: alpha('#4CAF50', 0.08),
      outlinedRestingBackground: alpha('#4CAF50', 0.5),
      content: darken('#4CAF50', 0.6),
      background: lighten('#4CAF50', 0.9),
    },
    action: {
      active: alpha('#000000', 0.54),
      hover: alpha('#000000', 0.04),
      selected: alpha('#000000', 0.08),
      disabled: alpha('#000000', 0.26),
      disabledBackground: alpha('#000000', 0.12),
      focus: alpha('#000000', 0.12),
    },
    other: {
      divider: alpha('#000000', 0.12),
      outlineBorder: alpha('#000000', 0.23),
      standardInputLine: alpha('#000000', 0.42),
      backdropOverlay: alpha('#000000', 0.5),
      ratingActive: '#FFB400',
      snackbarBackground: '#323232',
      selectedEdgeColor: '#FF0072',
      selectedNodeColor: '#8EF584',
      selectedNodeSourceColor: '#FC72B0',
      selectedNodeTargetColor: '#7DABF5',
      darkActiveWarranty: '#357B38',
      darkPartialWarranty: '#A64C01',
      darkNoWarranty: '#AB2F26',
      darkInfoWarranty: '#0072CE',
      lightActiveWarranty: '#EDF7ED',
      lightPartialWarranty: '#FEF3EB',
      lightNoWarranty: '#FEECEB',
      lightInfoWarranty: alpha('#0072CE', 0.08),
      background: '#FAFAFA',
      ecYellow: '#FFE921',
    },
    marketingSuggestion: {
      marketingSuggestion: '#181A1D',
      mainBackground: '#F1F4F5',
    },
    flowKit: {
      purple: '#7B61FF',
    },
  },
  typography: {
    fontFamily: 'ROBOTO',
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 4,
      },
      styleOverrides: {
        root: {
          color: '#000000DE',
          borderRadius: '10px',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#E5E5E5',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.disabled) {
            return { color: v2Theme.palette.text.disabled };
          }
          if (ownerState.variant === 'text') {
            return {
              color:
                ownerState.color === 'error'
                  ? `${v2Theme.palette.error.dark} !important`
                  : `${v2Theme.palette.primary.main} !important`,
            };
          }

          if (ownerState.variant === 'contained') {
            return {
              backgroundColor:
                ownerState.color === 'error'
                  ? v2Theme.palette.error.dark
                  : ownerState.color === 'success'
                    ? undefined
                    : v2Theme.palette.primary.main,
              '&:hover': {
                backgroundColor:
                  ownerState.color === 'error'
                    ? v2Theme.palette.error.containedHoverBackground
                    : ownerState.color === 'success'
                      ? undefined
                      : v2Theme.palette.primary.containedHoverBackground,
              },
            };
          }

          if (ownerState.variant === 'outlined') {
            return {
              backgroundColor: v2Theme.palette.common.white,
              border: `1px solid ${v2Theme.palette.primary.main} !important`,
              color: `${v2Theme.palette.primary.main} !important`,
            };
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'gray',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: 'gray',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&::before': () => ({
            borderColor: v2Theme.palette.other.standardInputLine,
          }),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: ({ ownerState }) => {
          if (ownerState.variant === 'filled')
            return {
              transform: 'translate(12px, 7px) scale(0.75) !important',
            };
        },
      },
    },
  },
});

export const themes = {
  light: v2Theme,
  dark: v2Theme,
};
