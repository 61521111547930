import { Shield } from '@mui/icons-material';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

export enum WorkflowWarranty {
  Valid = 'Valid Warranty',
  Expired = 'Expired Warranty',
  Possible = 'Possible Warranty',
}

interface ECWorkflowWarrantyBadgeProps {
  warranty: WorkflowWarranty;
}

export const ECWorkflowWarrantyBadge = ({
  warranty,
}: ECWorkflowWarrantyBadgeProps) => {
  const mapStatusColor = {
    [WorkflowWarranty.Valid]: {
      bgcolor: theme => theme.palette.success.background,
      color: theme => theme.palette.success.main,
      textcolor: theme => theme.palette.success.containedHoverBackground,
    },
    [WorkflowWarranty.Expired]: {
      bgcolor: theme => theme.palette.error.background,
      color: theme => theme.palette.error.main,
      textcolor: theme => theme.palette.error.containedHoverBackground,
    },
    [WorkflowWarranty.Possible]: {
      bgcolor: theme => theme.palette.warning.background,
      color: theme => theme.palette.warning.main,
      textcolor: theme => theme.palette.warning.containedHoverBackground,
    },
  };

  return (
    <ECBox
      display="flex"
      p={1}
      bgcolor={mapStatusColor[warranty].bgcolor}
      borderRadius={4}
      width="fit-content"
    >
      <Shield sx={{ color: mapStatusColor[warranty].color }} />
      <ECTypography
        ml={1}
        variant="body1"
        color={mapStatusColor[warranty].textcolor}
      >
        {warranty}
      </ECTypography>
    </ECBox>
  );
};
