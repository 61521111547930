import { useMemo } from 'react';

// porp-types is a library for typechecking of props
// import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Bar } from 'react-chartjs-2';

// Material Dashboard 2 React components
import { ECBox, ECCard, ECDivider, ECTypography } from '../..';

// ReportsBarChart configurations
import configs from './configs';

import { Schedule } from '@mui/icons-material';

function ECReportsBarChart({ color, title, description, date, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <ECCard sx={{ overflow: 'visible', marginTop: 5 }}>
      <ECBox padding="1rem">
        {useMemo(
          () => (
            <ECCard
              sx={{
                backgroundColor: `primary.main !important`,
                borderRadius: '10px !important',
                py: 2,
                pr: 0.5,
                mt: -5,
                height: '12.5rem',
              }}
            >
              {/* <Bar data={data} options={options} /> */}
            </ECCard>
          ),
          [data, options],
        )}
        <ECBox pt={3} pb={1} px={1}>
          <ECTypography variant="h6" textTransform="capitalize">
            {title}
          </ECTypography>
          <ECTypography variant="button" color="text" fontWeight="light">
            {description}
          </ECTypography>
          <ECDivider />
          <ECBox display="flex" alignItems="center">
            <ECTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Schedule fontSize={'small'} />
            </ECTypography>
            <ECTypography variant="button" color="text" fontWeight="light">
              {date}
            </ECTypography>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECCard>
  );
}

// Setting default values for the props of ReportsBarChart
ECReportsBarChart.defaultProps = {
  color: 'dark',
  description: '',
};

// Typechecking props for the ReportsBarChart
// ECReportsBarChart.propTypes = {
//   color: PropTypes.oneOf([
//     'primary',
//     'secondary',
//     'info',
//     'success',
//     'warning',
//     'error',
//     'dark',
//   ]),
//   title: PropTypes.string.isRequired,
//   description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   date: PropTypes.string.isRequired,
//   chart: PropTypes.objectOf(
//     PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//   ).isRequired,
// };

export default ECReportsBarChart;
