import { CSSProperties } from 'react';
import { ECAutocomplete } from '../ECAutocomplete';
import { COUNTRY_CODES, updatePlacesCountryCodes } from 'utils/address';
import { useGetCountriesForDropdownQuery } from 'services/lookupApi';

interface Props {
  onChange?: (value: any) => void;
  id: string;
  name: string;
  value?: any;
  variant?: 'standard' | 'filled';
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  error?: boolean;
  sx?: any;
  validationMessage?: string;
  hiddenLabel?: boolean;
  countryCode?: string;
}

export const ECCountryField = ({
  countryCode = 'US',
  error,
  ...props
}: Props) => {
  const { value, style, sx, validationMessage, readOnly, onChange, disabled } =
    props;

  const { data: countries = [], isLoading } = useGetCountriesForDropdownQuery();

  const countryOptions = countries;

  const handleChange = (event, value) => {
    onChange?.(value);
    if (value?.value?.toLowerCase() === 'jm') {
      updatePlacesCountryCodes([value?.value?.toLowerCase()]);
    }
  };

  return (
    <ECAutocomplete
      {...props}
      value={
        (typeof value === 'string'
          ? countryOptions?.find(
              option => option.value?.toLowerCase() === value?.toLowerCase(),
            )
          : value) || ''
      }
      onChange={handleChange}
      options={countryOptions}
      error={error}
      errorMessage={validationMessage}
      sx={{
        ...sx,
        ...style,
        cursor: readOnly ? 'pointer !important' : 'auto',
      }}
      variant={readOnly ? 'standard' : 'filled'}
      disabled={disabled || isLoading}
    />
  );
};
