import { useTranslation } from 'react-i18next';
import {
  useGetCustomerServiceProviderRatesAndTermsQuery,
  useUpdateServiceProviderRatesAndTermsMutation,
  useGetServiceProviderCustomerByIdQuery,
  useGetServiceTermsUnitListQuery,
  useGetServiceProviderOperationHourListQuery,
  useGetServiceProviderCurrencyListQuery,
} from 'services/serviceProviderApi';
import { useCallback, useEffect, useState } from 'react';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useGetProfileQuery } from 'services/profileApi';
import _ from 'lodash';
import { useOutletContext, useParams } from 'react-router';
import { populateDropDownFields } from 'utils/pageUtils';
import { useCustomerUser } from 'app/hooks';

const formElementsDetailsRatesAndTerms = require('./fancy_form_config_details_rates_and_terms.json');
const formElementsDetailsRatesAndTermsForOwnCompany = require('./fancy_form_config_details_rates_and_terms_for_own_company.json');
const formElementsEditRatesAndTerms = require('./fancy_form_config_edit_rates_and_terms.json');
const formElementsEditRatesAndTermsForOwnCompany = require('./fancy_form_config_edit_rates_and_terms_for_own_company.json');
interface Props {
  showEditButton?: boolean;
  onlyEdit?: boolean;
}

export function RatesAndTermsPage({ showEditButton, onlyEdit }: Props) {
  const props: any = useOutletContext();
  const { id } = useParams();
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  const isCurrentCompanyRatesAndTerms = !id;
  const { data: customer } = useGetServiceProviderCustomerByIdQuery(
    id ? Number(id) : 0,
    {
      skip: isCustomer,
    },
  );

  const { data: companyDetails } = useGetProfileQuery();

  const { data: ratesAndTermsData } =
    useGetCustomerServiceProviderRatesAndTermsQuery({
      spId: (isCustomer ? Number(id) : Number(companyDetails?.id)) || 0,
      cmpnyId: (isCustomer ? Number(companyDetails?.id) : Number(id)) || 0,
    });
  const [formFieldsEdit, setFormFieldsEdit] = useState(() =>
    isCurrentCompanyRatesAndTerms
      ? formElementsEditRatesAndTermsForOwnCompany.data.fields
      : formElementsEditRatesAndTerms.data.fields,
  );

  const [
    doUpdateRatesAndTerms,
    {
      isError: isUpdateRatesAndTermsError,
      error: updateRatesAndTermsError,
      isLoading: isUpdateRatesAndTermsLoading,
      isSuccess: isUpdateRatesAndTermsSuccess,
      reset: resetUpdateRatesAndTerms,
    },
  ] = useUpdateServiceProviderRatesAndTermsMutation();

  const formFieldDetails = isCurrentCompanyRatesAndTerms
    ? formElementsDetailsRatesAndTermsForOwnCompany
    : formElementsDetailsRatesAndTerms;

  const { data: currencyList, isSuccess: isSuccessCurrencyList } =
    useGetServiceProviderCurrencyListQuery();

  useEffect(() => {
    if (isSuccessCurrencyList && currencyList) {
      const updatedFormFields = [...formFieldsEdit];
      populateDropDownFields({
        responseData: currencyList,
        createFormFields: undefined,
        editFormFields: updatedFormFields?.[4].subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'currency',
        dontChangeValues: true,
      });
      setFormFieldsEdit(updatedFormFields);
    }
  }, [isSuccessCurrencyList, currencyList]);

  const { data: operationHourList, isSuccess: isSuccessOperationHourList } =
    useGetServiceProviderOperationHourListQuery();

  useEffect(() => {
    if (isSuccessOperationHourList && operationHourList) {
      const updatedFormFields = [...formFieldsEdit];
      populateDropDownFields({
        responseData: operationHourList,
        createFormFields: undefined,
        editFormFields: updatedFormFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'operationHours',
        dontChangeValues: true,
      });
      setFormFieldsEdit(updatedFormFields);
    }
  }, [isSuccessOperationHourList, operationHourList]);

  const { data: termsUnitList, isSuccess: isSuccesstermsUnitList } =
    useGetServiceTermsUnitListQuery();

  useEffect(() => {
    if (isSuccesstermsUnitList && termsUnitList) {
      const updatedFormFields = [...formFieldsEdit];
      populateDropDownFields({
        responseData: termsUnitList,
        createFormFields: undefined,
        editFormFields: updatedFormFields?.[17].subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'paymentTermsAmount',
        dontChangeValues: true,
      });
      populateDropDownFields({
        responseData: termsUnitList,
        createFormFields: undefined,
        editFormFields: updatedFormFields?.[12].subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'laborTermsAmount',
        dontChangeValues: true,
      });
      populateDropDownFields({
        responseData: termsUnitList,
        createFormFields: undefined,
        editFormFields: updatedFormFields?.[12].subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'partsTermsAmount',
        dontChangeValues: true,
      });
      setFormFieldsEdit(updatedFormFields);
    }
  }, [isSuccesstermsUnitList, termsUnitList]);

  const useUpdateRatesAndTerms = useCallback(() => {
    const doUpdate = async data => {
      const newData = { ...data };

      if (newData.operationHours) {
        newData.operationHours = newData.operationHours.map(op => {
          const newOp = _.cloneDeep(op);
          newOp.type = operationHourList?.find(opHrList => {
            return opHrList.name === newOp.type.name;
          });
          newOp.typeId = newOp.type?.id;
          delete newOp.type;
          return newOp;
        });
      }

      delete newData.undefined;
      newData.id = companyDetails?.id;
      newData.customerId = customer?.id;

      if (newData?.currency?.id) {
        newData.currency = newData.currency.id;
      }
      if (newData?.preferredPayment?.id) {
        newData.preferredPayment = newData.preferredPayment.id;
      }
      if (newData?.paymentTermsAmount?.value) {
        newData.paymentTermsAmount = newData.paymentTermsAmount.value;
      }
      if (newData?.paymentTermsUnitDescription) {
        newData.paymentTermsUnitCode = termsUnitList?.find(
          tul => tul.description === newData.paymentTermsUnitDescription,
        )?.code;
      }

      if (newData?.laborTermsAmount?.value) {
        newData.laborTermsAmount = newData.laborTermsAmount.value;
      }
      if (newData?.partsTermsAmount?.value) {
        newData.partsTermsAmount = newData.partsTermsAmount.value;
      }
      if (newData?.laborTermsUnitDescription) {
        newData.laborTermsUnitCode = termsUnitList?.find(
          tul => tul.description === newData.laborTermsUnitDescription,
        )?.code;
      }

      if (newData?.partsTermsUnitDescription) {
        newData.partsTermsUnitCode = termsUnitList?.find(
          tul => tul.description === newData.partsTermsUnitDescription,
        )?.code;
      }

      doUpdateRatesAndTerms(newData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateRatesAndTermsError,
        error: updateRatesAndTermsError,
        isLoading: isUpdateRatesAndTermsLoading,
        isSuccess: isUpdateRatesAndTermsSuccess,
        reset: resetUpdateRatesAndTerms,
      },
    ];
  }, [
    isUpdateRatesAndTermsError,
    updateRatesAndTermsError,
    isUpdateRatesAndTermsLoading,
    isUpdateRatesAndTermsSuccess,
    doUpdateRatesAndTerms,
    resetUpdateRatesAndTerms,
    operationHourList,
    companyDetails,
    termsUnitList,
    customer,
  ]);

  return (
    <EditForm2
      row={ratesAndTermsData}
      formConfig={formElementsEditRatesAndTerms.data.config}
      formFields={formFieldsEdit}
      detailsConfig={formFieldDetails.data.config}
      detailsFields={formFieldDetails.data.fields}
      useUpdateMutation={useUpdateRatesAndTerms}
      hideToolBar
      checkEqualFields={false}
      showEditButton={showEditButton}
      onlyEdit={onlyEdit}
      {...props}
    />
  );
}
