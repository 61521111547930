import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetServiceProviderByIdQuery,
  useGetServiceProviderConfigurationByIdQuery,
  useUpdateServiceProviderByIdMutation,
} from 'services/serviceProviderApi';
import { ECWorkflowTemplate } from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { Outlet, useParams } from 'react-router';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useCustomerUser } from 'app/hooks';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

export function ServiceProviderDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const hasEditServiceProviderPermission = useHasPermission([
    P.UpdateServiceProvider,
  ]);

  const [isActive, setIsActive] = useState<boolean>(false);

  const { data: profile } = useGetServiceProviderByIdQuery(
    id ? Number(id) : 0,
    { skip: !id },
  );

  const { data: configuration } = useGetServiceProviderConfigurationByIdQuery(
    id ? Number(id) : 0,
    { skip: !id },
  );

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
  );

  useEffect(() => {
    setIsActive(!!profile?.serviceProvider?.status);
    if (profile && profile?.serviceProvider?.spProfile?.spTypeId === 1) {
      const internalTechFieldEdit = fancyFormElementsEdit.data.fields
        .find(item => item?.groupName === 'switchGroup')
        ?.subFields.find(item => item?.fieldName === 'internalTech');
      const internalTechFieldDetails = formElementsDetails.data.fields
        .find(item => item?.groupName === 'switchGroup')
        ?.subFields.find(item => item?.fieldName === 'internalTech');
      if (internalTechFieldEdit && internalTechFieldDetails) {
        internalTechFieldEdit.visible = true;
        internalTechFieldDetails.visible = true;
      }
    } else {
      const internalTechFieldEdit = fancyFormElementsEdit.data.fields
        .find(item => item?.groupName === 'switchGroup')
        ?.subFields.find(item => item?.fieldName === 'internalTech');
      const internalTechFieldDetails = formElementsDetails.data.fields
        .find(item => item?.groupName === 'switchGroup')
        ?.subFields.find(item => item?.fieldName === 'internalTech');
      if (internalTechFieldEdit && internalTechFieldDetails) {
        internalTechFieldEdit.visible = false;
        internalTechFieldDetails.visible = false;
      }
    }
  }, [profile?.serviceProvider?.status]);

  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = fancyFormElementsEdit.data.fields[0].subFields.find(
        field => field.fieldName === 'flags',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlag, flagsData]);

  const [
    doUpdateSPDetails,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateServiceProviderByIdMutation();

  const useUpdateSPDetails = useCallback(() => {
    const doUpdate = async data => {
      const flagsIds = data.flags?.map(flag => flag?.id);

      doUpdateSPDetails({
        id: profile ? profile.id : 0,
        vendorId: data.vendorId,
        flagsIds,
        status: isActive ? 1 : 0,
        excludeInvoiceFromReport: data.excludeInvoiceFromReport,
        internalTech: data.internalTech,
        serviceRequestPopup: data.serviceRequestPopup,
        workflowEnforcement: data.workflowEnforcement,
      });
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateSPDetails,
    profile,
    isActive,
  ]);

  const isCustomerType = useCustomerUser();

  const detailsData = useMemo(() => {
    return {
      ...profile?.serviceProvider,
      profileId: profile?.id,
      moduleName: profile?.moduleName,
      ...configuration,
    };
  }, [profile, configuration]);

  const handleChange = (data, fieldName, valueChange) => {
    if (fieldName === 'workflowEnforcement') {
      const workflowEnforcementField = data
        .find(item => item?.groupName === 'switchGroup')
        ?.subFields.find(item => item?.fieldName === 'workflowEnforcement');

      if (workflowEnforcementField && valueChange)
        workflowEnforcementField.alert = {
          message:
            'New enforcement settings will be applied to new work orders',
          type: 'info',
        };

      if (!valueChange && workflowEnforcementField.alert)
        workflowEnforcementField.alert = null;
    }
  };

  return profile ? (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={`${t('translation:workflow.sidebar.serviceProvider')} ${
          profile?.serviceProvider?.name
        }`}
        summaryData={[
          {
            id: 'name',
            label: 'Name',
            data: profile?.serviceProvider?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'id',
            label: 'SP ID',
            data: profile?.serviceProvider?.id,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'spType',
            label: 'SP Type',
            data: profile?.spType,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'active',
            label: 'Active',
            data: isActive,
            type: SummaryFieldTypes.Switch,
            onChange: setIsActive,
          },
          {
            id: 'type',
            label: 'Type',
            data: profile?.serviceProvider?.companyType?.name || '',
            type: SummaryFieldTypes.Text,
          },
        ]}
        editConfig={fancyFormElementsEdit.data.config}
        editFields={fancyFormElementsEdit.data.fields}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formElementsDetails.data.fields}
        useUpdateMutation={useUpdateSPDetails}
        detailsData={detailsData}
        historyTabIdAttribute="profileId"
        attachmentTabIdAttribute="profileId"
        hideNoteTab={true}
        imgSrc={profile?.serviceProvider?.logo || ''}
        showLogoOnError={true}
        additionalTabs={[
          {
            value: 'rates-and-terms',
            link: 'rates-and-terms',
            label: 'Rates And Terms',
            editable: !isCustomerType,
            content: <Outlet />,
          },
          {
            value: 'trades-and-regions',
            link: 'trades-and-regions',
            editable: true,
            label: t('translation:panelDrawer.serviceProvidersTradeRegion'),
            content: <Outlet />,
          },
        ]}
        attachmentsTitle={'Docs & Attachments'}
        submissionConfirmationModal={{
          title: 'Are you sure you want to deactivate this Service Provider?',
          buttonText: 'Deactivate',
          bodyText:
            'Deactivating this Service Provider will completely remove them from all trade assignments. This cannot be undone. Any other changes made will be reflected.',
          isVisible: !isActive,
          onClose: () => setIsActive(true),
        }}
        onChange={handleChange}
        isEditAllowed={hasEditServiceProviderPermission[0]}
      />
    </>
  ) : null;
}
