import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  inventoryApi,
  useCreateInventoryMutation,
  useGetInventoryStorageLocationsListQuery,
  useLazyGetValidatePartNumberQuery,
} from 'services/inventoryApi';
import { P } from 'types/Permission';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { PartNumberType } from 'types/Inventory';

export interface AddInventoryItemContentProps {
  onClose: () => void;
  onSubmit?: () => void;
  onChange?: (sp: any) => void;
  isOpen?: boolean;
  updateNewInventoryItemName?: any;
}

const formElementsAddInventoryItem = require('./form_config_add_inventory_item.json');

function AddInventoryItemContent({
  onClose,
  onSubmit,
  onChange,
  isOpen,
  updateNewInventoryItemName,
}: AddInventoryItemContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formElementsAddInventoryFields, setFormElementsAddInventoryFields] =
    useState<ECEasyFormFieldType[]>(formElementsAddInventoryItem.fields);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const [trigger, result] = useLazyGetValidatePartNumberQuery();
  const { data: validatePartNumberData, isLoading } = result;

  useEffect(() => {
    const updatedFormFieldsAddInventory = [...formElementsAddInventoryFields];
    const storageLocationField = updatedFormFieldsAddInventory?.find(
      field => field.fieldName === 'storageLocations',
    );
    if (storageLocationField) {
      storageLocationField.useQuery = useGetInventoryStorageLocationsListQuery;
      storageLocationField.queryParams = {
        ob: 'invntrystrg.name',
      };
    }
    setFormElementsAddInventoryFields(updatedFormFieldsAddInventory);
  }, []);

  const [
    doAddInventoryItem,
    {
      data: addInventoryItemData,
      isError: isAddInventoryItemError,
      error: addInventoryItemErrorData,
      isLoading: isAddInventoryItemLoading,
      isSuccess: isAddInventoryItemSuccess,
      reset: resetAddInventoryItem,
    },
  ] = useCreateInventoryMutation();

  const useAddInventoryItemMutation = useCallback(() => {
    const doAdd = async data => {
      if (isLoading) {
        return;
      }

      if (
        validatePartNumberData?.isDuplicate &&
        validatePartNumberData.matchingField === PartNumberType.PartNumber
      ) {
        return;
      }

      updateNewInventoryItemName(data?.name);
      doAddInventoryItem({
        name: data?.name,
        partNumber: data?.partNumber,
        description: data?.description,
        inventoryActivityReceipts: data.stockReceipts,
        storageLocations: [
          {
            id: data?.storageLocations?.id,
            label: data?.storageLocations?.name,
            name: data?.storageLocations?.name,
            quantityOnHand: data?.quantityOnHand,
            costPerUnit: data?.costPerUnit,
            bin: data?.binNumber,
            lot: data?.lotNumber,
            serialNumber: data?.serialNumber,
          },
        ],
      });
    };
    return [
      doAdd,
      {
        isError: false,
        error: null,
        isSuccess: false,
        isLoading: isAddInventoryItemLoading,
        reset: resetAddInventoryItem,
      },
    ];
  }, [
    addInventoryItemData,
    isAddInventoryItemError,
    addInventoryItemErrorData,
    isAddInventoryItemLoading,
    isAddInventoryItemSuccess,
    resetAddInventoryItem,
  ]);
  useEffect(() => {
    if (isAddInventoryItemSuccess) {
      dispatch(inventoryApi.util.invalidateTags(['Inventory']));
      onClose?.();
    }
  }, [isAddInventoryItemSuccess]);

  const handleClose = useCallback(() => {
    onClose?.();
    setFormElementsAddInventoryFields(
      _.cloneDeep(formElementsAddInventoryItem.fields),
    );

    return {
      data: null,
      isError: false,
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  }, [formElementsAddInventoryFields, onClose]);

  useEffect(() => {
    const updatedFormFields = [...formElementsAddInventoryFields];
    const partNumberField = updatedFormFields.find(
      field => field.fieldName === 'partNumber',
    );

    if (partNumberField) {
      const { isDuplicate, matchingField, inventoryName } =
        validatePartNumberData || {};

      if (isDuplicate && matchingField === PartNumberType.PartNumber) {
        partNumberField.validationMessage = 'Part # is already in use';
        partNumberField.isValid = false;
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(false);
      }

      const isOtherPartNumberDuplicate =
        isDuplicate &&
        (matchingField === PartNumberType.ManufacturePartNumber ||
          matchingField === PartNumberType.SupplierPartNumber);

      if (isOtherPartNumberDuplicate) {
        partNumberField.helperText = `This Part # is used as ${_.startCase(matchingField)} in ${inventoryName}`;
        partNumberField.showErrorIcon = true;
      }

      if (!isDuplicate) {
        partNumberField.helperText = '';
        partNumberField.showErrorIcon = false;
      }

      setFormElementsAddInventoryFields(updatedFormFields);
    }
  }, [validatePartNumberData]);

  const validateAndTrigger = (value: string, fieldName) => {
    if (value && value.trim() !== '') {
      trigger({ inputPartNumber: value, fieldName });
    } else {
      trigger({ inputPartNumber: '!!!', fieldName });
    }
  };

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (fieldName && fieldName === 'partNumber') {
        const partNumberField = output.find(
          field => field.fieldName === fieldName,
        );
        if (partNumberField) {
          validateAndTrigger(partNumberField.value, fieldName);
          setFormElementsAddInventoryFields([...output]);
        }
      }
    },
    [],
  );

  return (
    <ECEasyFormCreate
      id="add-inventoryItem-form-nested"
      useCreateMutation={useAddInventoryItemMutation}
      formConfig={formElementsAddInventoryItem.config}
      formFields={formElementsAddInventoryFields}
      onClose={handleClose}
      stickyFooter={false}
      onChange={handleOutputChange}
      saveButtonDisabled={disableSaveButton}
      pattern="modal"
    />
  );
}

export const addInventoryItemAction = {
  buttonLabel: 'Add Inventory Item',
  modalContent: requirePermissionWrapper<AddInventoryItemContentProps>(
    AddInventoryItemContent,
  ),
  scopes: [P.GetInventory],
};
