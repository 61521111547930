import { ECUsersTable } from '../ECUsersTable';
import { useGetAssigneeUsersQuery } from 'services/workflowApi';
import { ECBox } from '../ECBox';

export interface ModuleData {
  module: 'proposal' | 'invoice';
  id: number;
  preExistingUsers?: any[];
}

export function ECUsersTableModal({
  module,
  id,
  preExistingUsers,
}: ModuleData) {
  const { data, isFetching, isLoading } = useGetAssigneeUsersQuery(
    { module, id },
    {
      skip: preExistingUsers !== undefined || !module || !id,
    },
  );

  return (
    <ECBox id={`popover-users-table`}>
      <ECUsersTable
        users={preExistingUsers || data || []}
        isLoading={isFetching || isLoading}
        hideSearchHeadContent={true}
      />
    </ECBox>
  );
}
