import {
  useLazyGetSPInvoiceByIdQuery,
  useLazyGetSPInvoiceListQuery,
} from 'services/invoiceApi';
import { ECBox, ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useServiceProviderUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import _ from 'lodash';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { useGetDefaultCategoriesQuery } from 'services/workOrdersApi';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { Popover } from '@mui/material';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';

export function ServiceProviderInvoicesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [row, setSelectedRow] = useState<any>(null);

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('invoice');
  const {
    data: categories,
    isFetching: isFetchingCategories,
    isSuccess: isSuccessCategories,
  } = useGetDefaultCategoriesQuery();

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/invoice'));
  }, []);

  const isServiceProvider = useServiceProviderUser();

  const statusFilterOptions = useMemo(() => {
    return (
      _.orderBy(workflowStatuses, 'name')?.map(status => ({
        id: status?.id,
        label: status.name,
        fieldName: status.name,
        isInitialSelected: status.name === WorkflowStatus.PendingApproval,
      })) || []
    );
  }, [workflowStatuses]);

  const categoryFilterOptions = useMemo(() => {
    return (
      categories?.data.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.id?.toString(),
        isInitialSelected: true,
      })) || []
    );
  }, [categories]);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingCategories &&
        isSuccessWorkflowStatuses &&
        isSuccessCategories,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingCategories,
    isSuccessWorkflowStatuses,
    isSuccessCategories,
  ]);

  const onClickViewUsersTable = useCallback((row, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(row);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedRow(null);
  }, []);

  if (!isServiceProvider) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:panelDrawer.invoices')}
        useLazyGetListQuery={useLazyGetSPInvoiceListQuery}
        useLazyGetByIdQuery={useLazyGetSPInvoiceByIdQuery}
        useCreateMutation={{}}
        withDrawer={false}
        enableExport
        exportModuleName="invoice"
        dateFilterFieldsOptions={[
          {
            id: 1,
            label: 'Created Date',
            fieldName: 'invce.createdAt',
          },
          {
            id: 2,
            label: 'Approved Date',
            fieldName: 'invce.approvedDate',
          },
        ]}
        statusFilterOptions={statusFilterOptions}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterAlias="wrkflwstts.name"
        showStatusFilter
        categoryFilterAlias="ctgry.id"
        showCategoryFilter
        showDateFilter
        dateRangeDefaultSelectedOption="All Time"
        onClickViewUsersTable={onClickViewUsersTable}
        selectedChipUsersTable={row?.id}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        showColumnVisibilityFilter
        isDraggableColumns
      />
      <Popover
        id={row?.id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={750}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <ECUsersTableModal module="invoice" id={row?.id} />
        </ECBox>
      </Popover>
    </>
  );
}
