import { useTranslation } from 'react-i18next';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECCardActionArea, ECCardActions, ECCardContent } from '../ECCard';
import { ECDivider } from '../ECDivider';
import { ECPaper } from '../ECPaper';
import { ECTypography } from '../ECTypography';
import { ECCircularProgress } from '../ECCircularProgress';

interface ECSPCardProps {
  id: number;
  name?: string;
  customersCount?: number;
  workOrdersCount?: number;
  workOrdersCompletedInArea?: number;
  spEmail?: string;
  isSelected?: boolean;
  onCardClick: (id: number) => () => void;
  onInviteClick: (data: {
    spEmail: string;
    spName: string;
    spId: number;
  }) => void;
  isLoadingInvite?: boolean;
}

export const ECSPCard = ({
  id,
  name,
  customersCount,
  workOrdersCount,
  workOrdersCompletedInArea,
  isSelected,
  spEmail,
  onCardClick,
  onInviteClick,
  isLoadingInvite,
}: ECSPCardProps) => {
  const { t } = useTranslation();

  const SPCardItem = (name: string, value: string, icon?: any) => (
    <ECBox display="flex" mt={1}>
      {!!icon && icon}
      <ECTypography variant="body1" fontWeight={200}>
        {name}
      </ECTypography>
      <ECTypography variant="body1">&nbsp; {value}</ECTypography>
    </ECBox>
  );

  return (
    <ECPaper
      key={id}
      elevation={3}
      sx={theme => ({
        bgcolor: isSelected
          ? theme.palette.primary.outlinedHoverBackground
          : theme.palette.common.white,
      })}
    >
      <ECCardActionArea onClick={onCardClick(id)} sx={{ px: 2 }}>
        <ECCardContent>
          <ECBox display="flex" flexDirection="column">
            <ECTypography variant="h5" mb={2}>
              {name}
            </ECTypography>

            {SPCardItem(
              t('translation:spDirectory.results.customers'),
              customersCount?.toString() || '',
            )}
            {SPCardItem(
              t('translation:spDirectory.results.workOrdersCompleted'),
              workOrdersCount?.toString() || '',
            )}
            {SPCardItem(
              t('translation:spDirectory.results.completedInSearchedArea'),
              workOrdersCompletedInArea?.toString() || '',
            )}

            <ECDivider sx={{ mt: 1 }} />
          </ECBox>
        </ECCardContent>
      </ECCardActionArea>

      <ECCardActions>
        <ECBox width="50%" display="flex" px={2}>
          <ECButton
            fullWidth
            variant="outlined"
            onClick={() =>
              onInviteClick({
                spEmail: spEmail || '',
                spName: name || '',
                spId: id,
              })
            }
            disabled={isLoadingInvite}
          >
            {isLoadingInvite ? (
              <ECCircularProgress size={'1rem'} />
            ) : (
              t('translation:spDirectory.results.invite')
            )}
          </ECButton>
        </ECBox>
      </ECCardActions>
    </ECPaper>
  );
};
