import {
  NotificationList,
  NotificationSettingsUpdate,
  NotificationUpdate,
} from 'types/Notification';
import { QueryParams } from 'types/QueryParams';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';
import { formatDateForTable } from 'utils/strings/formatDate';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Notification', 'NotificationSearch', 'NotificationSettings'],
});

export const notificationApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getNotificationList: builder.query<
      BaseType<NotificationList[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'notification',
          params,
        };
      },
      providesTags: ['Notification'],
    }),
    getUnreadNotificationsCount: builder.query<number, void>({
      query: () => {
        return {
          url: 'notification/count',
        };
      },
      providesTags: ['Notification'],
    }),
    updateNotification: builder.mutation<void, NotificationUpdate>({
      query: body => {
        return {
          url: `notification/${body._id}/${body.isRead ? 1 : 0}`,
          method: 'put',
        };
      },
      invalidatesTags: ['Notification'],
    }),
    readAllNotification: builder.mutation<void, void>({
      query: () => {
        return {
          url: 'notification/all/1',
          method: 'put',
        };
      },
      invalidatesTags: ['Notification'],
    }),
    unreadAllNotification: builder.mutation<void, void>({
      query: () => {
        return {
          url: 'notification/all/0',
          method: 'put',
        };
      },
      invalidatesTags: ['Notification'],
    }),
    getNotificationsSearch: builder.query<any, QueryParams>({
      query: params => {
        return {
          url: 'notification/search',
          params: {
            ...params,
            ob: 'sent',
          },
        };
      },
      keepUnusedDataFor: 180,
      transformResponse: (response: any) => {
        return {
          ...response,
          config: {
            ...response?.config,
            pagination: {
              ...response?.config?.pagination,
              totalCount: -1,
            },
          },
          data: response?.data?.map(d => ({
            ...d,
            id: d._id,
            notification: {
              ...d?.notification,
              email: {
                ...d?.notification?.email,
                recipients: d?.notification?.email?.recipients?.map(
                  recipient => ({
                    ...recipient,
                    errorOrDate:
                      recipient?.error || formatDateForTable(recipient?.sent),
                  }),
                ),
              },
              push: {
                ...d?.notification?.push,
                recipients: d?.notification?.push?.recipients?.map(
                  recipient => ({
                    ...recipient,
                    errorOrDate:
                      recipient?.error || formatDateForTable(recipient?.sent),
                  }),
                ),
              },
            },
          })),
        };
      },
      providesTags: ['NotificationSearch'],
    }),
    getNotificationSettingByJobTitleId: builder.query<
      any,
      { jobTitleId: number; params: QueryParams }
    >({
      query: body => {
        const { jobTitleId, params } = body;
        return {
          url: `notification/setting/${jobTitleId}`,
          params,
        };
      },
      providesTags: ['NotificationSettings'],
    }),
    updateNotificationSettingByJobTitleId: builder.mutation<
      void,
      NotificationSettingsUpdate
    >({
      query: ({ jobTitleId, notificationSettings }) => {
        return {
          url: `notification/setting/${jobTitleId}`,
          method: 'post',
          body: notificationSettings,
        };
      },
      invalidatesTags: ['NotificationSettings'],
    }),
  }),
});

export const {
  useGetNotificationListQuery,
  useLazyGetNotificationListQuery,
  useGetUnreadNotificationsCountQuery,
  useUpdateNotificationMutation,
  useReadAllNotificationMutation,
  useUnreadAllNotificationMutation,
  useGetNotificationsSearchQuery,
  useLazyGetNotificationsSearchQuery,
  useGetNotificationSettingByJobTitleIdQuery,
  useLazyGetNotificationSettingByJobTitleIdQuery,
  useUpdateNotificationSettingByJobTitleIdMutation,
} = notificationApi;
