import { ECPaper } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType, FieldTypes } from 'app/components/ECForm';
import { useCallback, useEffect, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { themes } from 'styles/theme/themes';
import {
  useCreateInventoryStorageTransferStockMutation,
  useGetInventoryStorageListByInvetoryIdQuery,
  useGetInventoryStorageLocationsListQuery,
} from 'services/inventoryApi';
import _ from 'lodash';

const fancyFormElementsCreate = require('./fancy_form_config_transfer_inventory.json');
interface TransferInventoryProps {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
}

export const TransferInventory = ({
  isOpen,
  existingData,
  onClose,
}: TransferInventoryProps) => {
  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields,
  );

  useEffect(() => {
    if (isOpen) {
      const inventoryItemNameField = formFields?.find(
        field => field.fieldName === 'inventoryItemName',
      );
      inventoryItemNameField.value = existingData?.inventoryItemName;
      const inventoryItemIdField = formFields?.find(
        field => field.fieldName === 'inventoryItemId',
      );
      inventoryItemIdField.value = existingData?.inventoryItemId;
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && fancyFormElementsCreate) {
      setFormFields(
        fancyFormElementsCreate.fields.map(field => ({
          ...field,
          value: null,
        })),
      );
      resetCreateTransferInventory();
    }
  }, [isOpen]);

  const [
    doCreateTransferInventory,
    {
      data: createTransferInventoryData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateTransferInventory,
    },
  ] = useCreateInventoryStorageTransferStockMutation();

  const useCreateTransferInventory = useCallback(() => {
    const doCreate = async data => {
      doCreateTransferInventory({
        inventoryItemId: data?.inventoryItemId,
        fromInventoryStorageId: data?.transferFrom?.inventoryStorageId,
        toInventoryStorageId: data?.transferTo?.id,
        lot: data?.lot,
        bin: data?.bin,
        description: data?.description,
        costPerUnit: parseFloat(data?.costPerUnit),
        count: parseFloat(data.quantityOnHand),
      });
    };
    return [
      doCreate,
      {
        data: createTransferInventoryData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ];
  }, [
    createTransferInventoryData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    doCreateTransferInventory,
  ]);

  useEffect(() => {
    if (isOpen) {
      const transferFromField = formFields?.find(
        field => field.fieldName === 'transferFrom',
      );
      transferFromField.useQuery = isOpen
        ? useGetInventoryStorageListByInvetoryIdQuery
        : {};
      transferFromField.obAlias = 'invntrystrg.name';
      transferFromField.queryParams = {
        id: existingData?.inventoryItemId,
        st: 1,
      };
      const transferToField = formFields?.find(
        field => field.fieldName === 'transferTo',
      );
      transferToField.useQuery = useGetInventoryStorageLocationsListQuery;
      transferToField.obAlias = 'invntrystrg.name';
      transferToField.queryParams = {
        st: 1,
      };
    }
  }, [isOpen]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'transferFrom') {
      const transferFromField = output?.find(
        field => field.fieldName === 'transferFrom',
      );
      const transferToField = output?.find(
        field => field.fieldName === 'transferTo',
      );
      if (transferToField) {
        transferToField.filterOptionsIds = [transferFromField?.value?.id];
      }
      setFormFields(output);
    } else if (fieldName === 'transferTo') {
      const transferFromField = output?.find(
        field => field.fieldName === 'transferFrom',
      );

      const transferToField = output?.find(
        field => field.fieldName === 'transferTo',
      );
      if (transferFromField) {
        transferFromField.filterOptionsIds = [transferToField?.value?.id];
      }
      setFormFields(output);
    }
  };

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper sx={{ height: '100%', paddingTop: '80px' }} role="presentation">
        <ECEasyFormCreate
          useCreateMutation={useCreateTransferInventory}
          formConfig={fancyFormElementsCreate.config}
          formFields={formFields}
          onClose={onClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          onChange={handleOutputChange}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
