import { P } from 'types/Permission';

export * from './addNote';
export * from './approve';
export * from './reject';
export * from './cancel';
export * from './reassign';
export * from './unassign';
export * from './assignTech';

export enum QueryTag {
  UnassignedWorkorders = 'UnassignedWorkorders',
  PendingWorkorders = 'PendingWorkorders',
  PendingProposals = 'PendingProposals',
  PendingInvoices = 'PendingInvoices',
  AwaitingInvoice = 'AwaitingInvoice',
}

export interface DashboardActionProps {
  selectedRows: any[];
  module: string;
  queryTag?: QueryTag;
  onClose: () => void;
  onCancel: () => void;
  reload?: () => void;
  invalidateQuery?: () => void;
  additionalData?: any;
  customAction?: (string) => void;
  isLoadingCustomAction?: boolean;
  customerId?: number;
  onSubmit?: () => void;
  onChange?: (sp: any) => void;
  customHeaderTitle?: any;
  customInputLabel?: any;
}

export interface DashboardAction {
  buttonLabel: string;
  disabledRule: (selection: number) => boolean;
  modalContent: (props) => JSX.Element;
  scopes?: P[];
}
