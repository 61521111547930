import { QueryParams } from 'types/QueryParams';
import { BaseType, DataType } from 'types/BaseType';
import { emptyApi } from './emptyApi';
import { AssetDetails, AssetFlat } from 'types/Asset';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Avt', 'Locations Asset Avt', 'AssetAVT', 'Attachment'],
});

interface AssetVerificationApproveRequest {
  manufactureId?: number;
  modelNumberId?: number;
  serialNumber?: string;
  manufacturerSeriesId?: number;
  warrantyStartDate?: string;
  warrantyStartOption?: number | string;
  purchaseDate?: string;
}

export interface AvtAttachmentUpdate {
  attachmentId: number;
  documentCoreTypeId?: number;
}

export const avtApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAvtLocationsList: builder.query<BaseType<any>, QueryParams>({
      query: params => {
        return {
          url: 'asset-verification/locations',
          params,
        };
      },
      providesTags: ['Avt'],
    }),
    getLocationAssetsAvtList: builder.query<
      BaseType<any>,
      { locationId: number; queryParams }
    >({
      query: ({ locationId, queryParams }) => {
        if (!locationId)
          return {
            url: '',
          };

        return {
          url: `asset-verification/location/${locationId}`,
          params: queryParams,
        };
      },
      providesTags: ['Locations Asset Avt'],
    }),
    getAVTAssetById: builder.query<AssetDetails & DataType, AssetFlat | number>(
      {
        query: data => {
          const id = typeof data === 'number' ? data : data?.id;
          if (!id)
            return {
              url: '',
            };

          return {
            url: `asset-verification/${id}`,
          };
        },
        transformResponse: (response: AssetDetails) => {
          return {
            ...response,
            moduleName: 'asset',
          };
        },
        providesTags: ['AssetAVT'],
      },
    ),
    approveAssetVerification: builder.mutation<
      void,
      { id: number; data: AssetVerificationApproveRequest }
    >({
      query: ({ id, data }) => ({
        url: `asset-verification/${id}/approve`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Avt', 'Locations Asset Avt'],
    }),
    enableAvtLocations: builder.mutation<
      BaseType<any>,
      { branchIds: number[] }
    >({
      query: ({ branchIds }) => ({
        url: `/branch/enable-avt`,
        method: 'PUT',
        body: { branchIds },
      }),
      invalidatesTags: ['Avt'],
    }),
    disableAvtLocations: builder.mutation<BaseType<any>, { branchId: number }>({
      query: ({ branchId }) => ({
        url: `/branch/disable-avt/${branchId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Avt'],
    }),
    updateAvtAttachment: builder.mutation<void, AvtAttachmentUpdate>({
      query: ({ attachmentId, ...body }) => {
        if (!attachmentId) {
          return {
            url: '',
          };
        }

        return {
          url: `asset-verification/${attachmentId}/attachment`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Attachment'],
    }),
    getAssetRejectReasonList: builder.query<any, void>({
      query: () => {
        return {
          url: `asset-verification/reject-reason`,
        };
      },
    }),
    rejectAsset: builder.mutation<
      void,
      { assetId?: number; rejectId?: number; body: any }
    >({
      query: ({ assetId, rejectId, body }) => {
        return {
          url: `asset-verification/${assetId}/${rejectId}/reject`,
          method: 'put',
          body: body,
        };
      },
      invalidatesTags: ['Avt', 'Locations Asset Avt'],
    }),
    notApplicableAssetVerification: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `asset-verification/${id}/not-applicable`,
        method: 'PUT',
      }),
      invalidatesTags: ['Avt', 'Locations Asset Avt'],
    }),
  }),
});

export const {
  useGetAvtLocationsListQuery,
  useLazyGetAvtLocationsListQuery,
  useGetLocationAssetsAvtListQuery,
  useLazyGetLocationAssetsAvtListQuery,
  useGetAVTAssetByIdQuery,
  useLazyGetAVTAssetByIdQuery,
  useEnableAvtLocationsMutation,
  useApproveAssetVerificationMutation,
  useDisableAvtLocationsMutation,
  useUpdateAvtAttachmentMutation,
  useGetAssetRejectReasonListQuery,
  useRejectAssetMutation,
  useNotApplicableAssetVerificationMutation,
} = avtApi;
