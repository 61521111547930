import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const language = useSelector((state: RootState) => state.user.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
};
