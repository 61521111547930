import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

interface BookValueProps {
  percentage?: string;
  colors?: {
    Green: string;
    Orange: string;
    Red: string;
  };
}

export const BookValueCard = (props: BookValueProps) => {
  const { percentage, colors } = props;

  const percentageValue = parseFloat(percentage?.replace('%', '') ?? '0');

  const getBarWidth = (percentageValue: number) => {
    return percentageValue > 100 ? '100%' : `${percentageValue}%`;
  };

  const getColor = (percentageValue: number) => {
    if (percentageValue <= 80) {
      return colors?.Green;
    } else if (percentageValue <= 100) {
      return colors?.Orange;
    } else {
      return colors?.Red;
    }
  };

  const barWidth = getBarWidth(percentageValue);

  return (
    <ECBox
      borderRadius={4}
      p={2}
      bgcolor="white"
      display="flex"
      flexDirection="column"
      width={'100%'}
      mb={2}
    >
      <ECTypography variant="h5" fontWeight={400} color="#111928">
        Book Value
      </ECTypography>
      <ECTypography variant="body1" fontWeight={400} color="#6B7280" mt={1}>
        The difference between the Original Cost and Depreciation made against
        the asset.
      </ECTypography>
      <ECBox
        sx={{
          position: 'relative',
          height: 24,
          borderRadius: 1,
          backgroundColor: '#e0e0e0',
          marginTop: '2rem',
        }}
      >
        <ECBox
          sx={{
            display: 'flex',
            height: '100%',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <ECBox sx={{ width: '80%', backgroundColor: colors?.Green }} />
          <ECBox sx={{ width: '20%', backgroundColor: colors?.Orange }} />
          {percentageValue > 100 && (
            <ECBox
              sx={{
                width: `${percentageValue - 100}%`,
                backgroundColor: colors?.Red,
              }}
            />
          )}
        </ECBox>
        <ECBox
          sx={{
            position: 'absolute',
            top: '-6px',
            left: barWidth,
            height: `calc(100% + 6px)`,
            borderLeft: `3px solid`,
            borderLeftColor: theme => theme.palette.error.content,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme => theme.palette.error.content,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        ></ECBox>
        <ECTypography
          sx={{
            position: 'absolute',
            top: '-2.5rem',
            left:
              percentage === '150%'
                ? `calc(${barWidth} - 50px)`
                : percentage === '0%'
                  ? `${barWidth}px`
                  : `calc(${barWidth} - 30px)`,
            color: '#111928',
          }}
          fontWeight={500}
          variant="h6"
        >
          {percentage === '150%' ? '>150%' : percentage}
        </ECTypography>
      </ECBox>
      <ECBox
        sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}
      >
        <ECTypography color="#6B7280" variant="body1">
          0%
        </ECTypography>
        <ECTypography color="#6B7280" variant="body1">
          {percentageValue <= 100 ? '100%' : '>150%'}
        </ECTypography>
      </ECBox>
      <ECBox sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: colors?.Green,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Repair
          </ECTypography>
        </ECBox>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: colors?.Orange,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Plan Replacement
          </ECTypography>
        </ECBox>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: colors?.Red,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Replace
          </ECTypography>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
