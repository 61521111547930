import { useCallback } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { formatDateForHistory } from 'utils/strings/formatDate';
import { ECGrid } from '../ECGrid';
import { camelCaseToWords } from 'utils/common';
import { P } from 'types/Permission';
import {
  getHistoryEntry,
  isValidHistoryEntry,
} from '../ECHistoryEntry/historyUtil';
import { TradeAssignmentActivityList } from 'types/Activity';
import { ArrowForward } from '@mui/icons-material';

export interface TradeAssignmentActivityProps {
  entry: TradeAssignmentActivityList;
}

const tradeFieldsOrder = [
  'position',
  'spName',
  'adminOnly',
  'locationName',
  'tradeName',
];
export const TradeAssignmentActivity = ({
  entry,
}: TradeAssignmentActivityProps) => {
  const {
    beforeBody,
    createdAt: date,
    fullName: author,
    jobTitle,
    email,
    company,
    body,
    targetPermission,
  } = entry;

  const parseValue = useCallback((value: any, bodyKey: string) => {
    if (bodyKey === 'adminOnly') {
      if (typeof value === 'string') {
        return value === 'true' ? 'Yes' : 'No';
      } else {
        return value ? 'Yes' : 'No';
      }
    }
    return value;
  }, []);

  const isBodyFieldAvailable = !!Object.keys(body).length;

  const renderChanges = useCallback(() => {
    return (
      <>
        {
          <>
            <ECBox>
              <ECBox overflow="visible">
                {
                  <>
                    {targetPermission.some(
                      perm =>
                        perm === P.UpdateTradeAssignments ||
                        perm === P.GetTradeAssignments ||
                        perm === P.AddTrade ||
                        perm === P.EditTrade,
                    ) && (
                      <ECTypography
                        variant="subtitle2"
                        width="fit-content"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                        sx={{
                          background: theme => theme.palette.other.divider,
                          paddingX: 2,
                          paddingY: 0.5,
                          mb: 1,
                        }}
                      >
                        Trade Assignment Updated
                      </ECTypography>
                    )}
                    {Object.keys(isBodyFieldAvailable ? body : beforeBody).map(
                      (bodyKey, index) => {
                        const _bodyKey =
                          tradeFieldsOrder[index] === 'spName'
                            ? 'Service Provider'
                            : tradeFieldsOrder[index] === 'position'
                              ? 'Rank No'
                              : tradeFieldsOrder[index].replace('Name', '');

                        return (
                          <>
                            {isValidHistoryEntry(body, bodyKey) && (
                              <ECBox display="flex" width="100%">
                                <ECBox display="flex" flex={1} width={0}>
                                  <ECTypography
                                    variant="subtitle2"
                                    minWidth="15vw"
                                    maxWidth="20vw"
                                    color={theme =>
                                      theme.palette.text.secondary
                                    }
                                  >
                                    {camelCaseToWords(_bodyKey)}
                                    :&nbsp;
                                  </ECTypography>

                                  <ECBox
                                    display="flex"
                                    flex={1}
                                    justifyContent="center"
                                  >
                                    {!Array.isArray(
                                      beforeBody?.[tradeFieldsOrder[index]],
                                    ) && (
                                      <ECBox
                                        display="flex"
                                        flex={1}
                                        width={0}
                                        visibility={
                                          (beforeBody &&
                                            tradeFieldsOrder[index] ===
                                              'adminOnly') ||
                                          beforeBody?.[tradeFieldsOrder[index]]
                                            ? 'visible'
                                            : 'hidden'
                                        }
                                        marginLeft={2}
                                      >
                                        <ECTypography
                                          variant="subtitle2"
                                          maxWidth="100%"
                                          color={theme =>
                                            theme.palette.error.dark
                                          }
                                          sx={{
                                            textDecoration: 'line-through',
                                          }}
                                        >
                                          {body[tradeFieldsOrder[index]] !==
                                            beforeBody?.[
                                              tradeFieldsOrder[index]
                                            ] &&
                                            parseValue(
                                              JSON.stringify(
                                                beforeBody?.[
                                                  tradeFieldsOrder[index]
                                                ],
                                              ),
                                              tradeFieldsOrder[index],
                                            )}
                                        </ECTypography>
                                      </ECBox>
                                    )}

                                    {!Array.isArray(
                                      beforeBody?.[tradeFieldsOrder[index]],
                                    ) && (
                                      <ECBox display="flex">
                                        <ArrowForward
                                          sx={theme => ({
                                            color:
                                              index === 2
                                                ? theme.palette.action.active
                                                : 'transparent',
                                            mx: 1,
                                            visibility: isBodyFieldAvailable
                                              ? 'visible'
                                              : 'hidden',
                                          })}
                                          fontSize="small"
                                        />
                                      </ECBox>
                                    )}

                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      width={0}
                                      justifyContent={'flex-start'}
                                      visibility={
                                        isBodyFieldAvailable
                                          ? 'visible'
                                          : 'hidden'
                                      }
                                    >
                                      <ECTypography
                                        variant="subtitle2"
                                        maxWidth="100%"
                                        color={theme =>
                                          bodyKey
                                            ?.toLowerCase()
                                            .includes('removed')
                                            ? theme.palette.error.dark
                                            : theme.palette.success.dark
                                        }
                                      >
                                        {parseValue(
                                          getHistoryEntry(
                                            isBodyFieldAvailable
                                              ? body
                                              : beforeBody,
                                            tradeFieldsOrder[index],
                                          ),
                                          tradeFieldsOrder[index],
                                        )}
                                      </ECTypography>
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </ECBox>
                            )}
                          </>
                        );
                      },
                    )}
                  </>
                }
              </ECBox>
            </ECBox>
          </>
        }
      </>
    );
  }, [body, beforeBody, entry, targetPermission]);

  return (
    <ECBox display="flex" flexDirection="column" flexGrow={1}>
      <ECBox sx={{ flexGrow: 1 }}>
        <ECGrid container spacing={2}>
          <ECGrid item xs>
            <ECBox display="flex" alignItems="center">
              <ECBox
                height={12}
                width={12}
                borderRadius={6}
                bgcolor={theme => theme.palette.primary.dark}
                mr={2}
              />
              <ECBox display="flex" flexDirection="column">
                <ECTypography variant="subtitle2" fontWeight="bold">
                  {author}
                </ECTypography>

                <ECTypography
                  variant="body2"
                  color={theme => theme.palette.primary.main}
                >
                  {email}
                </ECTypography>
              </ECBox>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography variant="body2" fontWeight="bold">
                {jobTitle}
              </ECTypography>

              <ECTypography
                variant="subtitle2"
                color={theme => theme.palette.text.secondary}
              >
                {company}
              </ECTypography>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {formatDateForHistory(date)}
              </ECTypography>
            </ECBox>
          </ECGrid>
        </ECGrid>
      </ECBox>

      <ECBox display="flex" gap={4}>
        <ECBox
          bgcolor={theme => theme.palette.grey[400]}
          width="1px"
          display="flex"
          sx={{ marginLeft: '6px !important' }}
        />
        <ECBox
          display="flex"
          flexDirection="column"
          boxShadow={2}
          mt={3}
          borderRadius="md"
          minWidth={0}
          width="100%"
          p={2}
          position="relative"
          overflow="hidden"
        >
          {renderChanges()}
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
