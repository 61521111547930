import { ECBox, ECButton, ECDynamicPageTemplate } from 'app/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import {
  useLazyGetGeneralAssetsListQuery,
  useUpdateGeneralAssetMutation,
  useCreateGeneralAssetMutation,
} from 'services/generalAssetApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetBranchTypesQuery } from 'services/branchApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useGetAreaListQuery } from 'services/areaApi';
import { loadPageScopes } from 'utils/pageScopes';
import { Add } from '@mui/icons-material';
import { useCreateServiceRequestContext } from 'store/contexts';
import { useCustomerUser } from 'app/hooks';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { themes } from 'styles/theme/themes';

const fancyFormElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');

export function GeneralAssetsPage({ marginTop = true }) {
  const { t } = useTranslation();
  const dispatchRedux = useDispatch();
  const isCustomer = useCustomerUser();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    loadPageScopes('assetGeneral');
    dispatchRedux(setFilterEndpoint('/filter/assetgeneral'));
  }, []);

  useEffect(() => {
    const assetTypeField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'assetType',
    );
    assetTypeField.useQuery = isDrawerOpen ? useGetAssetTypesListQuery : {};
    assetTypeField.obAlias = 'assttp.name';
  }, [isDrawerOpen]);

  useEffect(() => {
    const areaField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'area',
    );
    areaField.useQuery = isDrawerOpen ? useGetAreaListQuery : {};
    areaField.obAlias = 'area.name';
  }, [isDrawerOpen]);

  useEffect(() => {
    const branchTypeField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'branchType',
    );
    branchTypeField.useQuery = isDrawerOpen ? useGetBranchTypesQuery : {};
    branchTypeField.obAlias = 'name';
  }, [isDrawerOpen]);

  useEffect(() => {
    const createFormFields = fancyFormElementsCreate.data.fields;
    let priorityField = createFormFields.find(
      field => field.fieldName === 'priority',
    );
    priorityField.useQuery = isDrawerOpen ? useGetPrioritiesListQuery : {};
    priorityField.obAlias = 'prrt.name';
  }, [isDrawerOpen]);

  // if area is not enabled, make area field not visible
  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;
  useEffect(() => {
    const areaField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'area',
    );
    areaField.visible = areaEnabled;
  }, [areaEnabled]);

  const { dispatch } = useCreateServiceRequestContext();

  const handleCreateServiceRequestClick = (row: any) => () => {
    dispatch({ type: 'open', payload: { asset: row, isGeneralAsset: true } });
  };

  const renderCreateServiceRequestActionButton = (row: any) => {
    return row?.status === 'Active' ? (
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <ECButton
          variant="outlined"
          startIcon={<Add />}
          onClick={handleCreateServiceRequestClick(row)}
          key={`${row.id}-create-service-request`}
        >
          {t('translation:navBar.createServiceRequest')}
        </ECButton>
      </ECBox>
    ) : null;
  };

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:panelDrawer.generalAssets')}
      useLazyGetListQuery={useLazyGetGeneralAssetsListQuery}
      useCreateMutation={useCreateGeneralAssetMutation}
      useUpdateMutation={useUpdateGeneralAssetMutation}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      marginTop={marginTop}
      renderCustomActionComponent={
        isCustomer ? renderCreateServiceRequestActionButton : undefined
      }
      showOnlyHistoryTab
      hasDetails
      showStatusActiveFilter
      statusFilterInitialSelected
      withDrawer={false}
      shouldCacheLazyQuery
      onDrawerClose={() => setIsDrawerOpen(false)}
      onAddNewClick={() => setIsDrawerOpen(true)}
      drawerTitleBarBGColor={themes.light.palette.other.divider}
    />
  );
}
