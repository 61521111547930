import { Close } from '@mui/icons-material';
import { Rank } from '.';
import { ECFormControl } from '../ECForm';
import { ECIconButton } from '../ECIconButton';
import { ECMenuItem } from '../ECSelect';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECCheckbox } from '../ECCheckbox';
import { ECBox } from '../ECBox';
import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECTextField } from '../ECTextField';
import * as _ from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface ECRankItemFieldProps {
  position: number;
  id: UniqueIdentifier;
  value: any;
  rank?: Rank;
  options: any[];
  label?: string;
  adminOnly?: boolean;
  shouldShowAdminOnly?: boolean;
  onChange?: (rank: Rank) => void;
  onDelete: (position: number) => () => void;
  renderRankText?: (position: number) => string;
}

export const ECRankItemField = memo(
  ({
    position,
    id,
    value,
    rank,
    options: optionsFromProps,
    label,
    adminOnly,
    shouldShowAdminOnly = true,
    onChange,
    onDelete,
    renderRankText,
  }: ECRankItemFieldProps) => {
    const [serviceProvider, setServiceProvider] = useState<any>(
      optionsFromProps?.find(option => option.name === value),
    );
    const [adminOnlyFlag, setAdminOnlyFlag] = useState<boolean>(
      adminOnly || false,
    );

    const updateRank = useCallback(
      (serviceProvider: any, adminOnlyFlag: boolean) => {
        onChange?.({
          ...rank,
          position: rank?.position ?? rank?.order,
          id: serviceProvider ? serviceProvider?.id : rank?.id,
          data: serviceProvider ? serviceProvider?.name : rank?.data,
          adminOnly: adminOnlyFlag,
        } as Rank);
      },
      [rank],
    );

    useEffect(() => {
      updateRank(serviceProvider, adminOnlyFlag);
    }, [serviceProvider, adminOnlyFlag]);

    const options = useMemo(() => {
      return _.uniqBy(optionsFromProps, 'id');
    }, [optionsFromProps]);

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <ECStack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ cursor: 'grab' }}
        >
          <ECTypography noWrap variant="body2">
            {renderRankText?.(position) || `Rank-${position}`}
          </ECTypography>

          <ECIconButton disableRipple sx={{ cursor: 'grab' }} size="large">
            <DragIndicatorIcon />
          </ECIconButton>

          <ECFormControl variant="filled" sx={{ flex: '1 1 60%' }}>
            <ECAutocompleteStyled
              fullWidth
              value={value}
              options={options}
              isOptionEqualToValue={(option, value) =>
                (value?.id && option?.id === value?.id) ||
                option?.name === value
              }
              getOptionLabel={option => option?.name || option}
              onChange={(e, newValue) => {
                setServiceProvider(newValue);
              }}
              renderInput={params => (
                <ECTextField
                  {...params}
                  placeholder={label}
                  label={label}
                  hiddenLabel={!label}
                  variant="filled"
                />
              )}
              groupBy={option => option?.group}
              renderOption={(props, option, state) => {
                if (state.selected) {
                  return <></>;
                }

                return (
                  <ECMenuItem
                    {...props}
                    key={option?.id || option}
                    id={option?.id || option}
                    disabled={option?.disabled}
                  >
                    {option?.name}
                  </ECMenuItem>
                );
              }}
            />
          </ECFormControl>
          {shouldShowAdminOnly && (
            <ECBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ECCheckbox
                checked={adminOnly}
                onChange={event => {
                  setAdminOnlyFlag(event.target.checked);
                }}
              />
              <ECTypography noWrap variant="body2">
                Admin Only
              </ECTypography>
            </ECBox>
          )}
          <ECIconButton
            type="warning"
            size="small"
            onClick={onDelete(position)}
          >
            <Close />
          </ECIconButton>
        </ECStack>
      </div>
    );
  },
);
