import {
  ECBox,
  ECButton,
  ECFancyFormEdit,
  ECStack,
  ECSupplierContactsTable,
  ECWorkflowTemplate,
} from 'app/components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useGetSupplierByIdQuery,
  useUpdateSupplierMutation,
} from 'services/supplierApi';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { isValidEmail, isValidUrl } from 'utils/validate-fields';

const formLocationInfoDetails = require('./fancy_form_config_location_info_details.json');
const formLocationInfoEdit = require('./fancy_form_config_location_info_edit.json');

export const SupplierDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const editFormRef = useRef<any>();
  const supplierContactsTableRef = useRef<any>();

  const [isEditMode, setIsEditMode] = useState(false);
  const [invalidFields, setInvalidFields] = useState<any[]>([]);
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const { data: supplierItem, isLoading: isLoadingSupplierItem } =
    useGetSupplierByIdQuery(Number(id), {
      skip: !id,
    });

  const [contacts, setContacts] = useState<any>(supplierItem?.contacts || []);

  const detailsData = useMemo(() => {
    if (supplierItem && !isLoadingSupplierItem) {
      return {
        ...supplierItem,
        line1: supplierItem?.address?.line1,
        line2: supplierItem?.address?.line2,
        cityName: supplierItem?.address?.cityName,
        stateProvinceCode: supplierItem?.address?.stateProvinceCode,
        zipCodePostalCode: supplierItem?.address?.zipCodePostalCode,
        countryCode: supplierItem?.address?.countryCode,
      };
    }
  }, [supplierItem, isLoadingSupplierItem]);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('activity');

  useEffect(() => {
    if (supplierItem && !isLoadingSupplierItem) {
      setSummaryFieldsValues({
        name: supplierItem.name,
        status: supplierItem.status,
        phone: supplierItem.phone,
        email: supplierItem.email,
        website: supplierItem.website,
      });
    }
  }, [supplierItem, isLoadingSupplierItem, isEditMode]);

  const handleSummaryFieldChange = useCallback(
    (fieldName: string) => (value: any) => {
      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        [fieldName]: value,
      }));
    },
    [],
  );

  const summaryData = useMemo(() => {
    return [
      {
        id: 'id',
        label: 'Supplier ID',
        data: supplierItem?.id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'Supplier Name',
        data: summaryFieldsValues?.name,
        type: SummaryFieldTypes.Text,
        onChange: handleSummaryFieldChange('name'),
        isValid: !invalidFields.find(field => field.fieldName === 'name'),
        validationMessage: invalidFields.find(
          field => field.fieldName === 'name',
        )?.message,
        isEditable: true,
      },
      {
        id: 'status',
        label: 'State',
        data: summaryFieldsValues?.status,
        type: SummaryFieldTypes.State,
        onChange: handleSummaryFieldChange('status'),
        isEditable: true,
      },
      {
        id: 'phone',
        label: 'Phone',
        data: summaryFieldsValues?.phone,
        type: SummaryFieldTypes.Phone,
        onChange: handleSummaryFieldChange('phone'),
        isValid: !invalidFields.find(field => field.fieldName === 'phone'),
        validationMessage: invalidFields.find(
          field => field.fieldName === 'phone',
        )?.message,
        isEditable: true,
      },
      {
        id: 'email',
        label: 'Email',
        data: summaryFieldsValues?.email,
        type: SummaryFieldTypes.EmailField,
        onChange: handleSummaryFieldChange('email'),
        isValid: !invalidFields.find(field => field.fieldName === 'email'),
        validationMessage: invalidFields.find(
          field => field.fieldName === 'email',
        )?.message,
        isEditable: true,
      },
      {
        id: 'website',
        label: 'Website',
        data: summaryFieldsValues?.website,
        type: SummaryFieldTypes.Hyperlink,
        onChange: handleSummaryFieldChange('website'),
        isValid: !invalidFields.find(field => field.fieldName === 'website'),
        validationMessage: invalidFields.find(
          field => field.fieldName === 'website',
        )?.message,
        isEditable: true,
      },
    ];
  }, [summaryFieldsValues, supplierItem, invalidFields]);

  // will be used in tooo near future

  // const additionalActions = useMemo(() => {
  //   let actions: any = [];
  //   actions.push(
  //     <ECButton
  //       key={'create-purchase-order'}
  //       variant="outlined"
  //       onClick={() => console.log('Create Purchase Order')}
  //       startIcon={<ShoppingCart />}
  //     >
  //       Create Purchase Order
  //     </ECButton>,
  //   );
  //   return actions.length > 0 ? actions : undefined;
  // }, [supplierItem]);

  const [
    updateSupplier,
    { data, isLoading, error, isError, isSuccess, reset },
  ] = useUpdateSupplierMutation();

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setIsEditMode(false);
      reset();
    }
  }, [isSuccess, isLoading]);

  const handleSubmit = () => {
    const isSupplierContactsValid =
      supplierContactsTableRef?.current?.validate();

    let isSummaryFieldsValid = true;
    if (!summaryFieldsValues?.name) {
      setInvalidFields(prev => [
        ...prev,
        {
          fieldName: 'name',
          message: 'Supplier Name is required',
        },
      ]);
      isSummaryFieldsValid = false;
    } else {
      setInvalidFields(prev =>
        prev.filter(field => field.fieldName !== 'name'),
      );
    }

    if (!summaryFieldsValues?.phone) {
      setInvalidFields(prev => [
        ...prev,
        {
          fieldName: 'phone',
          message: 'Phone is required',
        },
      ]);
      isSummaryFieldsValid = false;
    } else {
      setInvalidFields(prev =>
        prev.filter(field => field.fieldName !== 'phone'),
      );
    }

    if (!summaryFieldsValues?.email) {
      setInvalidFields(prev => [
        ...prev,
        {
          fieldName: 'email',
          message: 'Email is required',
        },
      ]);
      isSummaryFieldsValid = false;
    } else if (!isValidEmail(summaryFieldsValues?.email)) {
      setInvalidFields(prev => [
        ...prev,
        {
          fieldName: 'email',
          message: 'Invalid email',
        },
      ]);
      isSummaryFieldsValid = false;
    } else {
      setInvalidFields(prev =>
        prev.filter(field => field.fieldName !== 'email'),
      );
    }

    if (
      summaryFieldsValues?.website &&
      !isValidUrl(summaryFieldsValues?.website)
    ) {
      setInvalidFields(prev => [
        ...prev,
        {
          fieldName: 'website',
          message: 'Invalid website',
        },
      ]);
      isSummaryFieldsValid = false;
    } else {
      setInvalidFields(prev =>
        prev.filter(field => field.fieldName !== 'website'),
      );
    }

    if (!isSummaryFieldsValid || !isSupplierContactsValid) {
      return;
    }

    editFormRef.current?.handleSubmit?.();
  };

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const body = {
        id: supplierItem?.id,
        address: {
          line1: data.line1,
          line2: data.line2,
          cityName: data.cityName,
          stateProvinceCode: data.stateProvinceCode,
          zipCodePostalCode: data.zipCodePostalCode,
          countryCode: data.countryCode,
        },
        ...summaryFieldsValues,
        supplierContact: contacts,
      };

      updateSupplier(body);
    };

    return [
      doUpdate,
      {
        data,
        isError,
        error,
        isLoading,
        isSuccess,
        reset,
      },
    ];
  }, [
    contacts,
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    reset,
    summaryFieldsValues,
    supplierItem?.id,
    updateSupplier,
  ]);

  return (
    <>
      {!isLoadingSupplierItem && supplierItem && (
        <ECWorkflowTemplate
          tabsAsLinks
          summaryData={summaryData}
          moduleName="supplier"
          title={supplierItem?.name || `Supplier #${id}`}
          showEditTitleStartAdornment={true}
          editConfig={{
            submitTitle: 'Save',
          }}
          editFields={[]}
          detailsData={{}}
          detailsConfig={{}}
          detailsFields={[]}
          imgSrc={supplierItem?.attachments?.[0]?.url}
          useUpdateMutation={null}
          hideNoteTab
          showEditForm={false}
          onEditButtonClick={setIsEditMode}
          isEditMode={isEditMode}
        />
      )}

      {isDetailsTab && (
        <>
          <ECFancyFormEdit
            ref={editFormRef}
            row={detailsData}
            config={formLocationInfoEdit.data.config}
            fields={formLocationInfoEdit.data.fields}
            detailsConfig={formLocationInfoDetails.data.config}
            detailsFields={formLocationInfoDetails.data.fields}
            useUpdateMutation={useUpdate}
            onlyEdit={isEditMode}
            showSaveButton={false}
            showTitle={false}
            checkNothingToUpdate={false}
          />

          <ECBox display="flex" height="100%">
            <ECSupplierContactsTable
              ref={supplierContactsTableRef}
              supplierContacts={supplierItem?.contacts || []}
              isEditMode={isEditMode}
              onChange={setContacts}
            />
          </ECBox>

          {isEditMode && (
            <ECBox
              display="flex"
              flexDirection="column"
              bgcolor="white"
              position="sticky"
              zIndex={99}
              bottom={0}
            >
              <ECStack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                p={1}
              >
                <ECButton onClick={() => setIsEditMode(false)} variant="text">
                  Cancel
                </ECButton>

                <ECButton
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Save
                </ECButton>
              </ECStack>
            </ECBox>
          )}
        </>
      )}
    </>
  );
};
