import { ECBox } from '../ECBox';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';

export enum WorkflowPriority {
  Emergency = 'L1 - Emergency',
  SameDay = 'L2 - Same Day',
  OneDay = 'L3 - 24 Hours',
  TwoDays = 'L4 - 48 Hours',
  OneWeek = 'L5 - One Week',
  TwoWeeks = 'L6 - Two Weeks',
  OneMonth = 'L7 - 30 Days',
  SixMonths = 'L8 - 6 Months',
}

const mapPriorityColors = {
  [WorkflowPriority.Emergency]: '#F44336',
  [WorkflowPriority.SameDay]: '#FFB547',
  [WorkflowPriority.OneDay]: '#FFB547',
  [WorkflowPriority.TwoDays]: '#4CAF50',
  [WorkflowPriority.OneWeek]: '#4CAF50',
  [WorkflowPriority.TwoWeeks]: '#4CAF50',
  [WorkflowPriority.OneMonth]: '#4CAF50',
  [WorkflowPriority.SixMonths]: '#4CAF50',
};

interface ECWorkflowPriorityBadgeProps {
  priority: WorkflowPriority;
  priorityColor?: string;
}

export const ECWorkflowPriorityBadge = ({
  priority,
  priorityColor,
}: ECWorkflowPriorityBadgeProps) => {
  return (
    <ECStack direction="row" spacing={1} height="100%">
      <ECBox width="3px" bgcolor={priorityColor ? priorityColor : mapPriorityColors[priority]} />
      <ECTypography variant="body1">{priority}</ECTypography>
    </ECStack>
  );
};
