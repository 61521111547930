import { QueryParams } from 'types/QueryParams';
import { emptyApi } from './emptyApi';
import { ReportExport } from 'types/ReportExport';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['ReportExport'],
});

interface ExportReportParams {
  jobName?:
    | 'proposal'
    | 'invoice'
    | 'workorder'
    | 'branch'
    | 'asset'
    | 'serviceprovider'
    | 'customer'
    | 'tradeassignmentbytrade'
    | 'tradeassignmentbybranch'
    | 'assetnameassetgroup'
    | 'users'
    | 'assettradeproblem'
    | 'locationassignment';
  exportModule?:
    | 'PROPOSAL'
    | 'INVOICE'
    | 'WORKORDER'
    | 'BRANCH'
    | 'ASSET'
    | 'SERVICEPROVIDER'
    | 'CUSTOMER'
    | 'TRADEASSIGNMENTBYTRADE'
    | 'TRADEASSIGNMENTBYBRANCH'
    | 'ASSETNAMEASSETGROUP'
    | 'USERS'
    | 'ASSETTRADEPROBLEM'
    | 'LOCATIONASSIGNMENT';
  queryParams?: QueryParams;
  tradeId?: number;
  branchId?: number;
  userId?: number;
}

export enum DefaultExportOrderBy {
  PROPOSAL = 'prpsl.id',
  INVOICE = 'invce.id',
  WORKORDER = 'wrkordr.id',
  BRANCH = 'brnch.id',
  ASSET = 'asst.id',
  SERVICEPROVIDER = 'cmpny.id',
  CUSTOMER = 'cmpny.id',
  TRADEASSIGNMENTBYTRADE = 'brnch.name',
  TRADEASSIGNMENTBYBRANCH = 'trd.name',
  ASSETNAMEASSETGROUP = '', // no default order by
  USERS = 'usr.id',
}

export const exportApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    requestExport: builder.mutation<void, ExportReportParams>({
      query: ({
        jobName,
        exportModule,
        queryParams,
        tradeId,
        branchId,
        userId,
      }) => {
        let url = `reportexport/${jobName}`;
        let body = { jobName, exportModule };

        if (jobName === 'tradeassignmentbytrade' && tradeId !== undefined) {
          url = `reportexport/trade/assignment/trade/${tradeId}`;
        } else if (
          jobName === 'tradeassignmentbybranch' &&
          branchId !== undefined
        ) {
          url = `reportexport/trade/assignment/branch/${branchId}`;
        } else if (jobName === 'assettradeproblem') {
          url = 'reportexport/asset-group/trade/problem';
        } else if (jobName === 'locationassignment') {
          url = 'reportexport/LocationAssignment/' + userId;
        }

        // default order by if not provided
        if (queryParams && !queryParams.ob) {
          queryParams.ob =
            DefaultExportOrderBy[
              jobName?.toUpperCase() as keyof typeof DefaultExportOrderBy
            ];
        }

        // default order if not provided
        if (queryParams && !queryParams.o) {
          queryParams.o = 'd';
        }

        return {
          url,
          method: 'post',
          body,
          params: {
            ...queryParams,
          },
        };
      },
      invalidatesTags: ['ReportExport'],
    }),
    getReportsExportList: builder.query<ReportExport, void>({
      query: () => {
        return {
          url: 'reportexport',
        };
      },
      providesTags: ['ReportExport'],
    }),
    getRequestExportStatus: builder.query<void, void>({
      query: () => {
        return {
          url: 'reportexport',
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useRequestExportMutation,
  useGetReportsExportListQuery,
  useLazyGetReportsExportListQuery,
} = exportApi;
