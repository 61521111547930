import { ECAlert, ECAlertTitle } from '../../../components/ECAlert';

export const PMSetupAlert = () => {
  return (
    <ECAlert severity="info">
      <ECAlertTitle>Preventive Maintenance Setup overview</ECAlertTitle>
      <b>For Service by Month:</b>
      <ul style={{ marginTop: 0 }}>
        <li>
          Based on the <b>Service Starts Date</b>, your first order will be
          created XX days in advance of the selected <b>Month</b> and{' '}
          <b>Date of Month</b>.
        </li>
        <li>
          If the <b>Date of Next Work Order</b> isn't what you're expecting, you
          may need to adjust the <b>Date of Month</b> or the{' '}
          <b>Generate WO in Advance</b> fields to generate the order according
          to your preferred schedule.
        </li>
      </ul>
      <b>For Service by Day:</b>
      <ul style={{ marginTop: 0 }}>
        <li>
          Based on the <b>Service Starts Date and Days Between Service</b>, your
          first work order will be created <b>XX days</b> in advance.
        </li>
        <li>
          If the <b>Date of Next Work Order</b> isn't what you're expecting, you
          may need to adjust the <b>Date of Month</b> or the{' '}
          <b>Generate WO in Advance</b> fields to generate the order according
          to your preferred schedule.
        </li>
      </ul>
    </ECAlert>
  );
};
