import React, { useCallback, useEffect, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECRankItemField } from './ECRankItemField';
import * as _ from 'lodash';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECChip } from '../ECChip';
import { AddNewButton } from '../ECRankField/ECRankFieldAddNewButton';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  UniqueIdentifier,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ECTextField } from '../ECTextField';

export interface Rank {
  position: number;
  order?: number;
  data?: any;
  adminOnly?: boolean;
  id: number;
}

export interface ECRankFieldProps {
  ranks?: Rank[];
  options: any[];
  label?: string;
  isReadOnly?: boolean;
  onChange?: (output: any) => void;
  shouldShowAdminOnly?: boolean;
  shouldShowRankText?: boolean;
  showReadOnlyAsChips?: boolean;
  maxLength?: number;
}

export const ECRankField = React.memo(
  ({
    ranks,
    options,
    label,
    isReadOnly,
    onChange,
    shouldShowAdminOnly,
    shouldShowRankText = true,
    showReadOnlyAsChips = true,
    maxLength,
  }: ECRankFieldProps) => {
    const [availableOptions, setAvailableOptions] = useState(options);

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 10,
        },
      }),
    );
    useEffect(() => {
      setAvailableOptions(
        options?.filter(
          option =>
            !ranks
              ?.map(rank => rank.data?.name || rank?.data)
              .includes(option?.name),
        ),
      );
    }, [ranks, options]);

    // some pages use order, some use position. This useEffect will normalize to position
    useEffect(() => {
      if (ranks?.some(rank => rank.order && !rank.position)) {
        const ranksWithPosition = ranks.map(rank => ({
          ...rank,
          position: rank.order,
        }));
        const updatedRanks = _.orderBy(ranksWithPosition, 'position');
        onChange?.(updatedRanks);
      }
    }, []);

    const handleDelete = (position: number) => () => {
      const filteredRanks =
        ranks?.filter(rank => rank.position !== position) || [];
      const updatedRanks = _.orderBy(filteredRanks, 'position').map(
        (rank, index) => ({
          ...rank,
          position: index + 1,
        }),
      );
      onChange?.(updatedRanks);
    };

    const handleChange = (updatedRank: Rank) => {
      const untouchedRanks =
        ranks?.filter(
          rank =>
            (rank.position ?? rank.order) !==
            (updatedRank.position ?? updatedRank.order),
        ) ?? [];
      const updatedRanks = _.orderBy(
        [...untouchedRanks, updatedRank],
        'position',
      );
      onChange?.(updatedRanks);
    };

    const handleAddNew = () => {
      const newRank = {
        position: ranks ? ranks.length + 1 : 1,
        data: '',
        id: ranks ? ranks.length + 1 : 1,
      };

      const updatedRanks = [...(ranks || []), newRank];
      setAvailableOptions([...availableOptions, newRank?.data]);
      onChange?.(updatedRanks);
    };

    const renderRankText = useCallback(
      (position: number) => {
        if (!position) return 'No Rank';
        if (shouldShowRankText) return `Rank-${position}`;

        // this logic will return the correct suffix for the position
        // 1st, 2nd, 3rd, 4th, 5th, (n)th...
        const n = position;
        const suffix = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return `Show ${n}${suffix[(v - 20) % 10] || suffix[v] || suffix[0]}`;
      },
      [shouldShowRankText],
    );

    if (isReadOnly) {
      return (
        <>
          {ranks
            ?.sort((a, b) => (a.position ?? a.order) - (b.position ?? b.order))
            ?.map((rank, index) => (
              <ECBox display="flex" p={1} alignItems="center" mb={1}>
                <ECTypography mr={1}>
                  {renderRankText(rank.position ?? rank.order ?? index + 1)}
                </ECTypography>
                {showReadOnlyAsChips ? (
                  <ECChip label={rank.data} />
                ) : (
                  <ECTextField
                    value={rank.data}
                    label={label}
                    variant="standard"
                    sx={{ marginLeft: '30px', width: '50%' }}
                  />
                )}
              </ECBox>
            ))}
        </>
      );
    }

    const handleDragEnd = event => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const oldIndex = ranks?.findIndex(rank => rank.id === active.id)!;
        const newIndex = ranks?.findIndex(rank => rank.id === over.id)!;

        const updatedRanks = arrayMove(ranks!, oldIndex, newIndex).map(
          (rank, index) => ({
            ...rank,
            position: index + 1,
          }),
        );

        onChange?.(updatedRanks);
      }
    };

    return (
      <ECStack direction="column" justifyContent="flex-start" spacing={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={ranks?.map((rank): UniqueIdentifier => rank.id) ?? []}
            strategy={verticalListSortingStrategy}
          >
            {ranks?.map(rank => {
              return (
                <ECRankItemField
                  key={rank.id}
                  id={rank.id}
                  rank={rank}
                  value={rank.data}
                  position={rank.position}
                  options={availableOptions}
                  adminOnly={rank.adminOnly}
                  shouldShowAdminOnly={shouldShowAdminOnly}
                  label={label}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  renderRankText={renderRankText}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <AddNewButton
          ranks={ranks}
          onAdd={handleAddNew}
          disabled={ranks && maxLength ? ranks?.length >= maxLength : undefined}
        />
      </ECStack>
    );
  },
);
