import { useMemo, useState } from 'react';
import { ECBox, ECGrid, ECTypography } from 'app/components';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { CopyWorkorderContainer } from './CopyWorkorderContainer';
import DateWorkordersSection from './DateWorkordersSection';
import { Skeleton } from '@mui/material';

interface DispatchSwimlaneBoardProps {
  technicians: any[];
  dragPanelId?: string;
  isLoading?: boolean;
  techniciansSelectedLength?: number;
}

export const DispatchSwimlaneBoard = ({
  technicians,
  dragPanelId,
  isLoading,
  techniciansSelectedLength,
}: DispatchSwimlaneBoardProps) => {
  const [isCopyWoDrawerOpen, setIsCopyWoDrawerOpen] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>(null);

  const handleOpenCopyWoDrawer = (value: boolean, workOrder: any): void => {
    setIsCopyWoDrawerOpen(value);
    setSelectedWorkOrder(workOrder);
  };

  const loadingSkeletons = useMemo(() => {
    return Array.from(new Array(techniciansSelectedLength && techniciansSelectedLength < 10 ? techniciansSelectedLength : 10)).map((_, index) => {
      return (
        <ECBox
          key={index}
          minWidth="254px"
          maxWidth="254px"
          height={'100%'}
        >
          <Skeleton variant="rectangular" height={'100%'} />
        </ECBox>
      );
    });
  }, [techniciansSelectedLength]);

  const techniciansMemoized = useMemo(() => {
    return technicians?.map(technician => {
      return (
        <ECBox
          sx={themes => ({
            gap: '2rem',
            overflowX: 'hidden',
            backgroundColor: themes.palette.grey[100],
          })}
          p={1}
          key={technician.assigneeID}
          minWidth="254px"
          maxWidth="254px"
          height={'100%'}
        >
          <ECBox
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={1}
          >
            <ECTypography lineHeight={'24px'}>
              {limitStringMaxCharacters(technician.assigneeFullName, 28)}
            </ECTypography>
            <ECTypography
              sx={{ opacity: '0.5' }}
              variant="caption"
              fontSize={'14px'}
              lineHeight={undefined}
            >
              {technician.serviceProviderName}
            </ECTypography>
          </ECBox>
          <ECBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {technician.dates?.map(date => {
              return (
                <DateWorkordersSection
                  key={`${technician.id}-${technician.assigneeFullName}-${date.date}`}
                  technicianId={technician.id}
                  technicianName={technician.assigneeFullName}
                  date={date}
                  openCopyWoDrawer={handleOpenCopyWoDrawer}
                  dragPanelId={dragPanelId}
                />
              );
            })}
          </ECBox>
        </ECBox>
      );
    });
  }, [technicians, dragPanelId]);

  return (
    <>
      <ECGrid
        display="flex"
        gap={2}
        p="16px 32px 0px 32px"
        maxWidth={'100%'}
        overflow={'auto'}
        height='calc(73vh - 20px)'
      >
        {isLoading ? (
          loadingSkeletons
        ) : (
          techniciansMemoized
        )}
      </ECGrid>
      <CopyWorkorderContainer
        selectedWorkOrder={selectedWorkOrder}
        isCopyWoDrawerOpen={isCopyWoDrawerOpen}
        onClose={() => setIsCopyWoDrawerOpen(false)}
      />
    </>
  );
};
