import { Method } from './BaseType';
import { P } from './Permission';

export interface ActivityBaseType {
  description?: string;
  method?: Method;
}
export interface TradeAssignmentActivityList extends ActivityBaseType {
  _id?: string;
  id?: number;
  fullName: string;
  company: string;
  body: Record<string, any>;
  beforeBody: Record<string, any>;
  jobTitle: string;
  email: string;
  module?: string;
  createdByUserId?: number;
  createdAt: number;
  updatedByUserId?: number;
  updatedAt?: number;
  targetPermission: P[];
  endpoint: string;
}

export interface ApprovalWorkflowActivityList {
  _id?: string;
  id?: number;
  fullName: string;
  company: string;
  body: Record<string, any>;
  beforeBody: Record<string, any>;
  jobTitle: string;
  email: string;
  module?: string;
  createdByUserId?: number;
  createdAt: number;
  updatedByUserId?: number;
  updatedAt?: number;
  targetPermission: P[];
  endpoint: string;
}

export enum ActivityType {
  Trade = 'trade',
  JobTitle = 'jobTitle',
  Organization = 'organization',
  Problems = 'problems',
  AssetTradeProblem = 'assetTradeProblem',
}
