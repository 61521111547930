export default function sumCurrencyFields(
  ...fields: (string | number | undefined)[]
) {
  if (!fields) {
    return 0;
  }
  const sum = fields.reduce((prev, next) => {
    const p = typeof prev === 'string' ? parseFloat(prev ?? '') : prev;
    const n = typeof next === 'string' ? parseFloat(next ?? '') : next;
    const normalizedPrev = Number.isNaN(p) ? 0 : p ?? 0;
    const normalizedNext = Number.isNaN(n) ? 0 : n ?? 0;
    return (normalizedPrev + normalizedNext) as number;
  });
  return Number((sum as number).toFixed(2));
}
