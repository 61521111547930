import { useCallback, useMemo } from 'react';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import { useTranslation } from 'react-i18next';

import {
  ECTableContainer,
  ECTableRow,
  ECTableCell,
  ECTableBody,
} from '../ECTable';

import { FormHelperText, Table, TableProps, styled } from '@mui/material';
import ECCurrencyField from '../ECCurrencyField';
import { ECFormControl } from '../ECForm';
import { ECLightTooltip } from '../ECTooltip';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { InventoryItemShape } from 'types/Inventory';
import _ from 'lodash';
import { ECCostTableAddCredit } from './ECCostTableAddCredit';
import { PurchaseOrderItem } from 'types/PurchaseOrderType';
import { PurchaseOrderItemsSubTable } from './purchase-order-items-sub-table';

export interface ItemsInventory {
  name: string;
  items: CostTableItem[];
}

export interface CostTableItem {
  label: string;
  value?: number;
  dataPath?: string;
  required?: boolean;
  isValid?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
}

export interface InventoryItemShapeWithReadOnly extends InventoryItemShape {
  readOnly?: boolean;
}

export interface CostTableData {
  nte?: number; //not to exceed
  Incurred?: number;
  showIncurred?: boolean;
  itemsRepair: CostTableItem[];
  itemsInventory?: InventoryItemShapeWithReadOnly[];
  purchaseOrderItems?: PurchaseOrderItem[];
  allowExceeding?: boolean;
  isEditing?: boolean;
  readOnly?: boolean;
  periodBudget?: number;
  budgetRemaining?: number;
  editingTotalCost?: boolean;
  openZeroTotalInfo?: boolean;
  onClickAwayZeroTotalInfo?: () => void;
  onChange?: (items: CostTableItem) => void;
  updateMutation?: {
    doUpdate: any;
    isError: boolean;
    isBoolean: boolean;
  };
  shouldHideCostTable?: boolean;
  shouldShowAddCredit?: boolean;
  shouldShowAddCreditButton?: boolean;
  creditAmount?: number;
  totalWithDiscountAmount?: number;
  invoiceId?: number;
}

const colors = {
  darkblue: '#0072CE',
  lightblue: 'rgba(0, 114, 206, 0.5)',
  error: 'rgba(244, 67, 54, 0.5)',
  errorSubtitle: 'rgba(244, 67, 54, 0.08)',
  gray: '#f5f5f5',
  darkgray: '#646464',
  lightorange: '#fef3eb',
  orange: '#f6b680',
};

interface Props extends TableProps {
  exceeded?: boolean | string;
}

export const MainTable = styled(Table)<Props>(({ exceeded, theme }) => ({
  '*': {
    border: 'none',
    transition: '0.2s',
  },
  '.header': {
    backgroundColor: exceeded ? colors.error : colors.darkblue,
    th: {
      color: 'white',
    },
    '.sum-total': {
      fontSize: '26px',
      fontWeight: 400,
    },
  },
  '.nte': {
    backgroundColor: exceeded ? colors.errorSubtitle : colors.lightblue,
  },
  '.row': {
    backgroundColor: theme.palette.action.selected,
  },
  '.left-column': {
    color: colors.darkgray,
  },
}));

export const SubTable = styled(Table)<Props>(({ exceeded, theme }) => ({
  '*': {
    border: 'none',
    transition: '0.2s',
  },
  '.header': {
    backgroundColor: colors.lightorange,
  },
  '.emphasis-row': {
    backgroundColor: colors.orange,
    '.total': {
      fontSize: '26px',
    },
  },
  '.row-sub': {
    backgroundColor: colors.gray,
  },
  '.left-column-sub': {
    color: colors.darkgray,
  },
  '.helper-text': {
    color: colors.darkgray,
    whiteSpace: 'normal',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
}));

export function ECWorkflowCostTable({
  itemsInventory,
  itemsRepair,
  purchaseOrderItems,
  nte,
  showIncurred,
  isEditing,
  periodBudget,
  allowExceeding,
  budgetRemaining,
  editingTotalCost = false,
  openZeroTotalInfo = false,
  readOnly = false,
  onChange,
  onClickAwayZeroTotalInfo,
  shouldHideCostTable,
  shouldShowAddCredit,
  shouldShowAddCreditButton,
  totalWithDiscountAmount,
  creditAmount,
  invoiceId,
}: CostTableData) {
  const { t } = useTranslation();

  const itemsRepairTotal = useMemo(() => {
    const totalSum =
      itemsRepair
        ?.filter(i => i?.label?.toLowerCase() !== 'incurred')
        .reduce((acc, item) => {
          if (item.value && !item.hidden) {
            return acc + Number(parseFloat(item.value.toString()).toFixed(2));
          }

          if (Array.isArray(item.value)) {
            const total = item.value.reduce((acc, val) => {
              if (val?.subTotal) {
                acc += Number(parseFloat(val.subTotal.toString()).toFixed(2));
              }
              return acc;
            }, 0);
            return acc + total;
          }

          return acc;
        }, 0) || 0;
    return Number(parseFloat(totalSum.toString()).toFixed(2));
  }, [itemsRepair]);

  const itemsInventoryTotal = useMemo(() => {
    const totalSum =
      itemsInventory?.reduce((acc, item) => {
        if (item.total) {
          acc += Number(parseFloat(item.total.toString()).toFixed(2));
        }

        return acc;
      }, 0) || 0;
    return Number(parseFloat(totalSum.toString()).toFixed(2));
  }, [itemsInventory]);

  const total = Number(
    parseFloat((itemsRepairTotal + itemsInventoryTotal).toString()).toFixed(2),
  );

  const exceeded = useMemo(
    () => (nte ? nte < total && !allowExceeding : false),
    [nte, total, allowExceeding],
  );

  const handleFieldChange = (value: any, label: string) => {
    onChange?.({ value, label });
  };

  const incurredValue = useMemo(() => {
    return itemsRepair.find(item => item.dataPath?.toLowerCase() === 'incurred')
      ?.value;
  }, [itemsRepair]);

  const incurredReadOnly = useMemo(() => {
    return itemsRepair.find(item => item.dataPath?.toLowerCase() === 'incurred')
      ?.readOnly;
  }, [itemsRepair]);

  const handleClickAwayZeroInfo = useCallback(() => {
    onClickAwayZeroTotalInfo?.();
  }, [onClickAwayZeroTotalInfo]);

  const renderAllocatedInventoryItems = useMemo(
    () =>
      itemsInventory?.map((item, index) => {
        return (
          <ECTableRow
            key={index}
            id={`${item.id}`}
            sx={{
              bgcolor: theme => `${theme.palette.action.hover} !important`,
            }}
          >
            <ECTableCell sx={{ border: 0 }}>
              <ECTypography
                display="inline"
                color="rgba(0,0,0,0.6)"
                sx={{ overflowWrap: 'anywhere' }}
              >
                {_.truncate(item.name, { length: 50 })}
              </ECTypography>
              <ECTypography
                display="inline"
                fontWeight={500}
                marginLeft={1}
                color="rgba(0,0,0,0.7)"
              >
                ({item.count} items)
              </ECTypography>
            </ECTableCell>
            <ECTableCell align="right" sx={{ border: 0 }}>
              {!item.readOnly && isEditing ? (
                <ECFormControl key={index}>
                  <ECCurrencyField
                    value={item.total}
                    error={item.total !== 0 && !item.total}
                    id={`${item.id}`}
                    readOnly={item.readOnly}
                  />
                  {isEditing && item.total !== 0 && !item.total && (
                    <FormHelperText
                      sx={theme => ({
                        color: theme.palette.error.main,
                      })}
                    >
                      This field is required
                    </FormHelperText>
                  )}
                </ECFormControl>
              ) : (
                currencyFormatter.format(item.total ?? 0)
              )}
            </ECTableCell>
          </ECTableRow>
        );
      }),
    [itemsInventory, isEditing],
  );

  const zeroTotalInfo = useMemo(
    () => (
      <ECBox onMouseLeave={handleClickAwayZeroInfo}>
        <ECBox
          sx={theme => ({
            maxWidth: '444px',
          })}
        >
          <ECBox display="flex" flexDirection="column" p={2}>
            {editingTotalCost && (
              <ECTypography variant="body2" px={1}>
                {t('translation:costTable.zeroTotalInfoEdit')}
              </ECTypography>
            )}
            {!editingTotalCost && (
              <ECTypography variant="body2" px={1}>
                {t('translation:costTable.zeroTotalInfoCreate')}
              </ECTypography>
            )}
          </ECBox>
        </ECBox>
      </ECBox>
    ),
    [isEditing, handleClickAwayZeroInfo],
  );

  const isInventoryEnabled = itemsInventory && !!itemsInventory.length;

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      gap={isInventoryEnabled ? 1 : 0}
    >
      {!shouldHideCostTable && (
        <>
          <ECBox
            display="flex"
            flexDirection="column"
            borderRadius={2}
            sx={{
              borderBottomLeftRadius: isInventoryEnabled ? undefined : 0,
              borderBottomRightRadius: isInventoryEnabled ? undefined : 0,
            }}
            border={1}
            overflow="hidden"
            borderColor={theme => theme.palette.divider}
          >
            {shouldShowAddCredit && (
              <ECCostTableAddCredit
                invoiceId={invoiceId}
                creditAmount={creditAmount}
                shouldShowAddCreditButton={shouldShowAddCreditButton}
                totalAmount={total}
                totalWithDiscountAmount={totalWithDiscountAmount}
              />
            )}
            <ECBox
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={theme => theme.palette.divider}
              bgcolor={theme =>
                exceeded
                  ? theme.palette.error.outlinedRestingBackground
                  : isInventoryEnabled
                    ? undefined
                    : theme.palette.primary.main
              }
            >
              <ECTypography
                color={theme =>
                  isInventoryEnabled
                    ? exceeded
                      ? theme.palette.common.white
                      : theme.palette.text.secondary
                    : theme.palette.common.white
                }
                variant="h6"
              >
                {!allowExceeding && exceeded
                  ? 'Total must be less than or equal to NTE'
                  : 'Total'}
              </ECTypography>
              <ECLightTooltip
                title={zeroTotalInfo}
                open={openZeroTotalInfo}
                placement="bottom-end"
              >
                <ECTypography
                  color={theme =>
                    isInventoryEnabled
                      ? exceeded
                        ? theme.palette.common.white
                        : undefined
                      : theme.palette.common.white
                  }
                  variant="h4"
                  id="ec-costtable-total"
                >
                  {currencyFormatter.format(total)}
                </ECTypography>
              </ECLightTooltip>
            </ECBox>

            {!!nte && nte > 0 && (
              <ECBox
                className="nte"
                display="flex"
                p={2}
                alignItems="center"
                justifyContent="space-between"
                bgcolor={
                  isInventoryEnabled
                    ? theme => theme.palette.action.selected
                    : exceeded
                      ? colors.errorSubtitle
                      : colors.lightblue
                }
              >
                <ECTypography
                  color={theme => theme.palette.text.secondary}
                  variant="body2"
                >
                  NTE
                </ECTypography>

                <ECTypography variant="body2">
                  {currencyFormatter.format(nte || 0)}
                </ECTypography>
              </ECBox>
            )}
          </ECBox>

          <ECBox
            display="flex"
            flexDirection="column"
            border={1}
            borderRadius={2}
            sx={{
              borderTopLeftRadius: isInventoryEnabled ? undefined : 0,
              borderTopRightRadius: isInventoryEnabled ? undefined : 0,
            }}
            borderColor={theme => theme.palette.divider}
          >
            {!!itemsInventory?.length && (
              <ECBox
                display="flex"
                p={2}
                alignItems="center"
                justifyContent="space-between"
                borderBottom={1}
                borderColor={theme => theme.palette.divider}
              >
                <ECTypography
                  color={theme => theme.palette.text.secondary}
                  variant="body2"
                >
                  Subtotal for repair
                </ECTypography>

                <ECTypography variant="h5" id="ec-costtable-total">
                  {currencyFormatter.format(itemsRepairTotal)}
                </ECTypography>
              </ECBox>
            )}

            <ECTableContainer>
              <MainTable
                exceeded={allowExceeding ? '' : `${exceeded ? 'true' : ''}`}
              >
                <ECTableBody>
                  {itemsRepair
                    .filter(item => !item.hidden)
                    .map((item, index) => {
                      return (
                        <ECTableRow
                          key={index}
                          id={`${item.label}`}
                          sx={{
                            bgcolor: theme =>
                              `${theme.palette.action.hover} !important`,
                          }}
                        >
                          <ECTableCell sx={{ border: 0 }}>
                            {item.label} {item.required ? '*' : ''}
                          </ECTableCell>
                          <ECTableCell align="right" sx={{ border: 0 }}>
                            {!item.readOnly && isEditing ? (
                              <ECFormControl key={index}>
                                <ECCurrencyField
                                  value={item.value}
                                  error={item.isValid === false}
                                  id={`${item.label}`}
                                  onChange={(event, value) => {
                                    handleFieldChange(value, item.label);
                                  }}
                                />
                                {item.required &&
                                  !item.value &&
                                  item.isValid === false && (
                                    <FormHelperText
                                      sx={theme => ({
                                        color: theme.palette.error.main,
                                      })}
                                    >
                                      This field is required
                                    </FormHelperText>
                                  )}
                              </ECFormControl>
                            ) : (
                              currencyFormatter.format(item.value ?? 0)
                            )}
                          </ECTableCell>
                        </ECTableRow>
                      );
                    })}
                </ECTableBody>
              </MainTable>
            </ECTableContainer>
          </ECBox>

          {periodBudget && budgetRemaining && (
            <ECTableContainer
              sx={{
                border: 1,
                borderColor: theme => theme.palette.divider,
                borderRadius: 2,
              }}
            >
              <SubTable>
                <ECTableBody>
                  <ECTableRow sx={{ bgcolor: colors.gray }} className="header">
                    <ECTableCell>This Period Budget</ECTableCell>
                    <ECTableCell align="right">
                      {currencyFormatter.format(periodBudget ?? 0)}
                    </ECTableCell>
                  </ECTableRow>
                  <ECTableRow
                    sx={{ bgcolor: theme => theme.palette.action.selected }}
                    className="emphasis-row"
                  >
                    <ECTableCell>Budget Remaining</ECTableCell>
                    <ECTableCell className="total" align="right">
                      {currencyFormatter.format(budgetRemaining ?? 0)}
                    </ECTableCell>
                  </ECTableRow>
                </ECTableBody>
              </SubTable>
            </ECTableContainer>
          )}

          {showIncurred && (
            <ECTableContainer
              sx={{
                border: 1,
                borderColor: theme => theme.palette.divider,
                borderRadius: 2,
                mt: isInventoryEnabled ? 0 : 2,
              }}
            >
              <SubTable>
                <ECTableBody>
                  <ECTableRow
                    sx={{
                      bgcolor: colors.gray,
                      border: 1,
                      borderColor: theme => theme.palette.divider,
                    }}
                    className="row-sub"
                  >
                    <ECTableCell className="left-column-sub">
                      Incurred Cost
                    </ECTableCell>
                    <ECTableCell align="right">
                      {!incurredReadOnly && isEditing ? (
                        <ECCurrencyField
                          id="Incurred"
                          value={incurredValue}
                          onChange={(event, value) => {
                            handleFieldChange(value, 'Incurred');
                          }}
                        />
                      ) : (
                        currencyFormatter.format(incurredValue ?? 0)
                      )}
                    </ECTableCell>
                  </ECTableRow>

                  <ECTableRow
                    sx={{ bgcolor: theme => theme.palette.action.selected }}
                    className="row-sub"
                  >
                    <ECTableCell className="helper-text" colSpan={2}>
                      {t('translation:costTable.helperText')}
                    </ECTableCell>
                  </ECTableRow>
                </ECTableBody>
              </SubTable>
            </ECTableContainer>
          )}
        </>
      )}

      <PurchaseOrderItemsSubTable
        items={purchaseOrderItems || []}
        isEditing={isEditing}
        onChange={handleFieldChange}
      />

      {isInventoryEnabled && !!itemsInventory?.length && (
        <ECBox
          display="flex"
          flexDirection="column"
          border={1}
          borderRadius={2}
          borderColor={theme => theme.palette.divider}
        >
          <ECBox
            display="flex"
            p={2}
            alignItems="center"
            justifyContent="space-between"
            borderBottom={1}
            borderColor={theme => theme.palette.divider}
          >
            <ECTypography
              color={theme => theme.palette.text.secondary}
              variant="body2"
            >
              Subtotal for Inventory
            </ECTypography>

            <ECTypography variant="h5" id="ec-costtable-inventory-total">
              {currencyFormatter.format(itemsInventoryTotal)}
            </ECTypography>
          </ECBox>

          <ECTableContainer>
            <MainTable
              exceeded={allowExceeding ? '' : `${exceeded ? 'true' : ''}`}
            >
              <ECTableBody>{renderAllocatedInventoryItems}</ECTableBody>
            </MainTable>
          </ECTableContainer>
        </ECBox>
      )}
    </ECBox>
  );
}
