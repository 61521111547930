import {
  Alarm,
  Cancel,
  CheckCircle,
  DirectionsCar,
  GolfCourse,
  NearbyError,
  PersonAddAlt1,
  RestartAlt,
  Send,
  ThumbDown,
  ThumbUp,
  PendingActions,
} from '@mui/icons-material';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

export enum WorkflowStatus {
  Accepted = 'Accepted',
  Approved = 'Approved',
  PendingAcceptance = 'Pending SP Acceptance',
  Unassigned = 'Unassigned',
  Rejected = 'Rejected',
  Completed = 'Completed',
  EnRoute = 'En Route',
  Arrived = 'Arrived',
  PendingParts = 'Pending Parts',
  ProposalSubmitted = 'Proposal Submitted',
  ProposalApproved = 'Proposal Approved',
  Return = 'Return Visit Required',
  NotFixed = 'Not Fixed',
  Cancelled = 'Cancelled',
  PendingApproval = 'Pending Approval',
  ProposalPendingApproval = 'Pending Client Approval',
  AwaitingInvoice = 'Awaiting Invoice',
  MoreInformation = 'More Information',
  Closed = 'Closed',
  PendingSubmission = 'Pending Submission',
  AcceptedPendingSubmission = 'Accepted - Pending Submission',
  Declined = 'Declined',
  PendingReview = 'Pending Review',
}

export enum WorkflowStatusGroupName {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Unassigned = 'UNASSIGNED',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Complete = 'COMPLETE',
  SoftCompleted = 'SOFT_COMPLETED',
  EnRoute = 'EN_ROUTE',
  Submitting = 'SUBMITTING',
  Submitted = 'SUBMITTED',
  ProposalSubmitted = 'PROPOSAL_SUBMITTED',
  ReturnVisitRequired = 'RETURN_VISIT_REQUIRED',
  Approved = 'APPROVED',
  Arrived = 'ARRIVED',
  NotFixed = 'NOT_FIXED',
  Closed = 'CLOSED',
  Paid = 'PAID',
  NotRequired = 'INVOICE_NOT_REQUIRED',
  PendingParts = 'PENDING_PARTS',
  ProposalApproved = 'PROPOSAL_APPROVED',
  ProposalRejected = 'PROPOSAL_REJECTED',
}

interface ECWorkflowStatusBadgeProps {
  status?: WorkflowStatus | string;
  statusGroupName?: WorkflowStatusGroupName;
}

export const ECWorkflowStatusBadge = ({
  status,
  statusGroupName,
}: ECWorkflowStatusBadgeProps) => {
  const mapStatusIcon = {
    [WorkflowStatus.Accepted]: <PersonAddAlt1 />,
    [WorkflowStatus.PendingAcceptance]: <Alarm />,
    [WorkflowStatus.Rejected]: <ThumbDown />,
    [WorkflowStatus.Completed]: <CheckCircle />,
    [WorkflowStatus.EnRoute]: <DirectionsCar />,
    [WorkflowStatus.Arrived]: <GolfCourse />,
    [WorkflowStatus.PendingParts]: <Alarm />,
    [WorkflowStatus.ProposalSubmitted]: <Send />,
    [WorkflowStatus.ProposalApproved]: <ThumbUp />,
    [WorkflowStatus.Return]: <RestartAlt />,
    [WorkflowStatus.NotFixed]: <NearbyError />,
    [WorkflowStatus.Cancelled]: <Cancel />,
    [WorkflowStatus.Unassigned]: <PendingActions />,
  };

  const mapStatusGroupNameIcon = {
    [WorkflowStatusGroupName.Pending]: <Alarm />,
    [WorkflowStatusGroupName.Cancelled]: <Cancel />,
    [WorkflowStatusGroupName.Rejected]: <ThumbDown />,
    [WorkflowStatusGroupName.Accepted]: <PersonAddAlt1 />,
    [WorkflowStatusGroupName.Complete]: <CheckCircle />,
    [WorkflowStatusGroupName.EnRoute]: <DirectionsCar />,
    [WorkflowStatusGroupName.Arrived]: <GolfCourse />,
    [WorkflowStatusGroupName.Unassigned]: <PendingActions />,
    [WorkflowStatusGroupName.Submitting]: <Send />,
    [WorkflowStatusGroupName.Submitted]: <ThumbUp />,
    [WorkflowStatusGroupName.ReturnVisitRequired]: <RestartAlt />,
    [WorkflowStatusGroupName.NotFixed]: <NearbyError />,
    [WorkflowStatusGroupName.Approved]: <ThumbUp />,
  };

  return (
    <ECBox
      display="flex"
      p={1}
      borderRadius="5px"
      border="1px solid"
      height="32px"
      width="fit-content"
      alignItems="center"
      overflow="hidden"
    >
      {status && mapStatusIcon[status]}
      {statusGroupName && mapStatusGroupNameIcon[statusGroupName]}
      <ECTypography
        ml={1}
        variant="body1"
        fontSize="16px"
        fontWeight="400"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {status || statusGroupName}
      </ECTypography>
    </ECBox>
  );
};
