import { ECImage } from '../ECImage';
import { ECBox } from '../ECBox';
import { ECCheckbox } from '../ECCheckbox';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useState } from 'react';
import { ECButton } from '../ECButton';
import { ECStack } from '../ECStack';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { ECTypography } from '../ECTypography';
import { useTranslation } from 'react-i18next';

export interface ECAvtImageProps {
  src: string;
  alt?: string;
  showLogoOnError?: boolean;
  handleOnChange?: (value: any) => void;
  id?: number;
  customWidth?: string;
  customHeight?: string;
  showCheckbox?: boolean;
  imageTools?: boolean;
  helperText?: string;
  docType?: string | null;
}

export const ECAvtImage = ({
  src,
  alt,
  showLogoOnError = true,
  handleOnChange,
  id,
  customWidth = '19.5rem',
  customHeight = '17.5rem',
  showCheckbox = true,
  imageTools = false,
  helperText,
  docType,
}) => {
  const { t } = useTranslation();
  const [rotation, setRotation] = useState(0);

  const handleRotate = () => {
    setRotation(prevRotation => prevRotation - 90);
  };

  const renderImageContent = () => {
    return (
      <ECImage
        src={src}
        alt={alt}
        sx={{
          maxWidth: '100%',
          transform: `rotate(${rotation}deg)`,
          objectFit: 'contain',
          width: customWidth,
          height: customWidth,
        }}
        showLogoOnError={showLogoOnError}
      />
    );
  };

  return (
    <>
      <ECStack>
        <ECBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: customWidth,
            height: customHeight,
            border: '1px solid rgba(0, 0, 0, 0.26)',
            borderRadius: '4px',
            margin: '0 1.5rem 1.5rem 0',
            overflow: 'hidden',
            cursor: imageTools ? 'grab' : 'unset',
          }}
          key={id}
        >
          <ECBox
            sx={{
              position: 'absolute',
              top: '.5rem',
              right: '.5rem',
              zIndex: 1,
            }}
          >
            {showCheckbox && (
              <ECCheckbox
                size="medium"
                onChange={(e: any) => {
                  handleOnChange({
                    id: e.target.checked ? id : null,
                    url: e.target.checked ? src : null,
                    action: 'image_change',
                  });
                }}
                sx={theme => ({
                  background: theme.palette.other.background,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    background: theme.palette.other.background,
                  },
                  borderRadius: '0',
                })}
              />
            )}
          </ECBox>
          {imageTools ? (
            <TransformWrapper
              limitToBounds={true}
              wheel={{
                step: 0.04,
                smoothStep: 0.04,
              }}
            >
              <TransformComponent>{renderImageContent()}</TransformComponent>
            </TransformWrapper>
          ) : (
            renderImageContent()
          )}
        </ECBox>
        {!imageTools && (
          <ECStack>
            <ECBox display={'flex'} width={customWidth}>
              <ECTypography variant="subtitle2" mr={1}>
                {t('translation:pages.avt.imageDocType')}:
              </ECTypography>

              <ECTypography variant="subtitle2" fontWeight={400}>
                {docType}
              </ECTypography>
            </ECBox>
            <ECBox display={'flex'} width={customWidth}>
              <ECButton
                size="small"
                onClick={() =>
                  handleOnChange({ id, url: null, action: 'doctype_change' })
                }
              >
                {t('translation:pages.avt.updateDocument')}
              </ECButton>
            </ECBox>
          </ECStack>
        )}
        <ECBox
          display={'flex'}
          justifyContent={'space-between'}
          width={customWidth}
        >
          {helperText && (
            <ECTypography
              sx={{
                maxWidth: `calc(${customWidth} - 4rem)`,
                marginBottom: '1rem',
              }}
              variant="caption"
            >
              {helperText}
            </ECTypography>
          )}
          {imageTools && (
            <ECButton
              variant="outlined"
              size="small"
              sx={{
                width: '3rem',
                height: '3rem',
                padding: '.5rem',
                minWidth: 'unset',
              }}
              onClick={handleRotate}
            >
              <RotateLeftIcon
                sx={{ transform: `rotate(${rotation}deg)`, fontSize: '1.5rem' }}
              />
            </ECButton>
          )}
        </ECBox>
      </ECStack>
    </>
  );
};
