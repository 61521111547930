import moment from 'moment';
import { ServiceFrequencyValueType, SelectionType } from '../app/components';
import _ from 'lodash';

export function formatHoursWithAmOrPm(hour: number, minutes: number) {
  const formatedHour = `${hour > 12 ? hour - 12 : hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes} ${hour >= 12 ? 'PM' : 'AM'}`;
}

export function formatHoursAndMinutes(hour: number, minutes: number) {
  const formatedHour = `${hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes}`;
}

/**
 * This function converts a decimal representation of hours into a human-readable string format
 * @param {number} hours - The decimal representation of time (e.g., 3.5 for 3 hours and 30 minutes).
 * @returns {string} The formatted string representing the time (e.g., "3 hr(s) 30 min(s)").
 */
export function formatDecimalHours(hours) {
  if (hours < Number.EPSILON) return '0 min';
  const duration = moment.duration(hours, 'hours');
  const hrs = Math.floor(duration.asHours());
  const mins = Math.round(duration.asMinutes() % 60);

  return `${hrs ? `${hrs} hr(s)` : ''} ${mins ? `${mins} min(s)` : ''}`.trim();
}

// also ignores system timezone difference;
export function convertToISOString(date?: Date | string) {
  if (!date) return;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment(date).utc(true).toISOString();
}

export const getNextServiceDate = (
  startDate: moment.Moment,
  serviceFrequency: ServiceFrequencyValueType,
  createWOInAdv: number,
): string => {
  startDate = moment(startDate).startOf('day');
  if (serviceFrequency.type === SelectionType.DAY) {
    if (_.isNil(serviceFrequency.daysBetweenService)) {
      return 'Invalid service frequency configuration for days between services';
    }

    if (moment(startDate).isSame(moment(), 'day')) {
      return moment(startDate)
        .add(2 + createWOInAdv, 'days')
        .format('YYYY-MM-DD');
    }

    const nextServiceDate = moment(startDate).format('YYYY-MM-DD');

    if (moment(nextServiceDate).isBefore(moment(startDate))) {
      return moment(startDate).format('YYYY-MM-DD');
    }

    return nextServiceDate;
  } else if (serviceFrequency.type === SelectionType.MONTH) {
    if (
      !serviceFrequency.serviceMonths ||
      serviceFrequency.dayOfMonth === null
    ) {
      return 'Invalid service frequency configuration';
    }

    // Convert serviceMonths from strings to numbers for comparison, assuming they represent numeric month values.
    const numericServiceMonths = serviceFrequency.serviceMonths
      .map(m => parseInt(m, 10))
      .sort((a, b) => a - b);
    let effectiveDate = moment.max(
      moment().add(Number(createWOInAdv), 'day'),
      moment(startDate),
    );
    let year = effectiveDate.year();
    let month = effectiveDate.month() + 1; // Adjust for moment's 0-based month indexing.
    const { dayOfMonth } = serviceFrequency;

    // Check if the effective month and day are in compliance with the serviceMonths and dayOfMonth
    let nextServiceDate = moment(
      `${year}-${month?.toString().padStart(2, '0')}-${dayOfMonth}`,
    ).startOf('day');
    if (
      numericServiceMonths.includes(month) &&
      effectiveDate.date() <= dayOfMonth &&
      nextServiceDate.isSameOrAfter(startDate.startOf('day'))
    ) {
      // If effectiveDate is exactly the dayOfMonth or before, and is a service month, return it.
      return nextServiceDate.format('YYYY-MM-DD');
    } else {
      // Otherwise, find the next service month that comes after the effectiveDate.
      let nextServiceMonth = numericServiceMonths.find(m => m > month);
      if (!nextServiceMonth) {
        // If there's no later service month in the same year, roll over to the next year.
        nextServiceMonth = numericServiceMonths[0];
        year++;
      }

      // Calculate the next service date with the nextServiceMonth and specified dayOfMonth.
      // Note: This adjustment assumes dayOfMonth is valid for all service months.
      nextServiceDate = moment(
        `${year}-${nextServiceMonth?.toString().padStart(2, '0')}-${dayOfMonth}`,
        'YYYY-MM-DD',
      );

      // Handle the case where dayOfMonth is not valid for the nextServiceMonth by checking against the max day of that month.
      const daysInNextServiceMonth = nextServiceDate.daysInMonth();
      if (dayOfMonth > daysInNextServiceMonth) {
        // If dayOfMonth exceeds the number of days in nextServiceMonth, adjust it to the last day of the month.
        nextServiceDate.date(daysInNextServiceMonth);
      }

      return nextServiceDate.format('YYYY-MM-DD');
    }
  }
  return 'Invalid service frequency configuration';
};
