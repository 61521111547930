import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { P } from 'types/Permission';

interface ECGridProps extends GridProps {
  scopes?: P[];
  scopesOptional?: P[];
}

export const ECGrid = requirePermissionWrapper<ECGridProps>(
  styled(Grid)<ECGridProps>(({ theme }) => ({})),
);
