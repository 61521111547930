// Need to use the React-specific entry point to import createApi
import { Permission } from 'types/Permission';
import { QueryParams } from 'types/QueryParams';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Permission'],
});

// Define a service using a base URL and expected endpoints
export const permissionsApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getPermissionsList: builder.query<Permission[], QueryParams>({
      query: ({ t }) => {
        return {
          url: `permission`,
          params: {
            t: t || 500,
            p: 0,
          },
        };
      },
      providesTags: ['Permission'],
    }),
    createPermission: builder.mutation<void, Permission>({
      query: body => ({
        url: 'permission',
        method: 'post',
        body,
      }),
      invalidatesTags: ['Permission'],
    }),
    updatePermission: builder.mutation<void, Permission>({
      query: permission => {
        return {
          url: `permission`,
          method: 'put',
          body: permission,
        };
      },
      invalidatesTags: ['Permission'],
    }),
    deletePermission: builder.mutation<void, Permission>({
      query: permission => ({
        url: `permission`,
        method: 'delete',
        body: permission,
      }),
      invalidatesTags: ['Permission'],
    }),
  }),
});

export const {
  useGetPermissionsListQuery,
  useLazyGetPermissionsListQuery,
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useUpdatePermissionMutation,
} = permissionsApi;
