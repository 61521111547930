import * as React from 'react';
import { ECTypography, ECMainTabBox, ECBox, ECButton } from 'app/components';
import { history } from 'utils/history';
import ErrorImage from '../../../assets/error.svg';
import MaintenanceErrorImage from '../../../assets/maintenance-error.svg';
import { Dashboard } from '@mui/icons-material';
import { useServiceProviderUser } from 'app/hooks';

export function ErrorPage() {
  const isSPUser = useServiceProviderUser();

  const navigate = history?.navigate;
  const location = history?.location as any;
  const statusCode = location?.state?.statusCode;
  return [502, 503, 504].includes(statusCode) ? (
    <>
      <ECMainTabBox
        component="main"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ECBox>
          <ECTypography variant="h6">
            <ECBox
              component="img"
              // @ts-ignore-next-line
              src={MaintenanceErrorImage}
            />
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECTypography variant="h3" fontWeight="bold">
            Site is currently down for maintenance
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECTypography variant="h4" mt={4}>
            We apologize for any inconvenience caused. We're almost done!
          </ECTypography>
        </ECBox>
      </ECMainTabBox>
    </>
  ) : (
    <>
      <ECMainTabBox
        component="main"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ECBox>
          <ECTypography
            variant="h1"
            fontWeight="bold"
            color={theme => theme.palette.primary.main}
          >
            {statusCode}
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECTypography variant="h6">
            <ECBox
              component="img"
              // @ts-ignore-next-line
              src={ErrorImage}
            />
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECTypography variant="h3" fontWeight="bold">
            Opps, Something is unplugged
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECTypography variant="h4" mt={4}>
            We're sorry, the page you requested could not be found. Please go
            back to the homepage.
          </ECTypography>
        </ECBox>
        <ECBox>
          <ECButton
            variant="contained"
            onClick={() =>
              isSPUser
                ? navigate?.('/panel/sp/work-orders')
                : navigate?.('/panel/dashboard')
            }
            sx={{ marginTop: 4, marginBottom: 4, width: '680px' }}
            size="large"
            startIcon={<Dashboard />}
          >
            GO TO {isSPUser ? 'WORK ORDERS' : 'DASHBOARD'}
          </ECButton>
        </ECBox>
      </ECMainTabBox>
    </>
  );
}
