import {
  ECEasyFormConfigType,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECPaper,
  FieldTypes,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateProposalForWorkorderMutation } from 'services/proposalApi';
import { useGetRepairsListForAssetQuery } from 'services/repairApi';
import { useDispatch } from 'react-redux';
import { workOrdersApi } from 'services/workOrdersApi';
import { setSnackbar } from 'store/slice/page';
import { moduleApi } from 'services/moduleApi';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { StyleConstants } from 'styles/StyleConstants';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
}

const config: ECEasyFormConfigType = {
  variant: 'simple',
  name: 'Proposal',
  title: 'Add Proposal',
  cols: 1,
  submitTitle: 'Save',
  subtitle: '',
};

export function CreateProposalDrawer({ onClose, existingData, isOpen }: Props) {
  const dispatch = useDispatch();

  const { data: failures, isLoading: isLoadingRepairs } =
    useGetRepairsListForAssetQuery(existingData.assetId);

  const [
    doCreateProposal,
    { data, isError, error, isSuccess, reset, isLoading },
  ] = useCreateProposalForWorkorderMutation();

  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(
        workOrdersApi.util.invalidateTags([
          'WorkOrderById',
          'WorkOrderById',
          'WorkOrderStatusList',
        ]),
      );
    }
  }, [isSuccess, dispatch]);

  const fields = useMemo(
    () =>
      [
        {
          fieldName: 'requestId',
          visible: false,
        },
        {
          type: FieldTypes.Group,
          groupColumns: 2,
          subFields: [
            {
              type: FieldTypes.Text,
              label: 'Asset',
              readOnly: true,
              fieldName: 'assetName',
            },
            {
              type: FieldTypes.Text,
              label: 'Work Order ID',
              readOnly: true,
              fieldName: 'workorderId',
            },
            {
              type: FieldTypes.Text,
              label: 'Location',
              readOnly: true,
              value: existingData.branchName,
            },
            {
              type: FieldTypes.Text,
              label: 'Problem',
              readOnly: true,
              fieldName: 'problem',
            },
          ],
        },
        {
          type: FieldTypes.Text,
          label: 'Proposal Title',
          placeholder: 'Proposal Title',
          required: true,
          maxLength: 500,
          fieldName: 'title',
        },
        {
          type: FieldTypes.Text,
          label: 'Service Provider Proposal ID',
          fieldName: 'spProposalId',
        },
        {
          type: FieldTypes.Group,
          groupColumns: 3,
          alignItems: 'center',

          subFields: [
            {
              type: FieldTypes.Text,
              placeholder: 'Completion Time',
              label: 'Completion Time',
              fieldName: 'completionTime',
              isNumeric: true,
              minValue: 1,
              maxLength: 3,
              required: true,
            },
            {
              type: FieldTypes.Select,
              placeholder: 'Completion Time Unit',
              fieldName: 'completionPeriodCode',
              required: true,
              optionValues: ['D', 'W', 'M'],
              options: [
                {
                  label: 'Day(s)',
                  value: 'D',
                },
                {
                  label: 'Week(s)',
                  value: 'W',
                },
                {
                  label: 'Month(s)',
                  value: 'M',
                },
              ],
              value: 'D',
            },
            {
              type: FieldTypes.Label,
              label: 'After Approval',
            },
          ],
        },
        {
          type: FieldTypes.CostTable,
          allowExceeding: true,
        },
        {
          type: FieldTypes.Currency,
          placeholder: 'Incurred Costs($)',
          fieldName: 'incurred',
          helperText:
            'Incurred Costs are not calculated into the total and should be added to the correct cost fields. Example: If you have incurred $80 of Labor and $20 of Material costs, $80 should be included in your final Labor charges and $20 should be included in your final Material charges.',
        },
        {
          type: FieldTypes.TextArea,
          placeholder: 'Description of work',
          label: 'Description of work',
          maxLength: 10000,
          required: true,
          fieldName: 'description',
        },
        {
          type: FieldTypes.FilePicker,
          label: 'Attatchments',
          value: null,
          options: [],
          fieldName: 'images',
          fileType: 'photo',
          required: !!existingData?.requiredFields?.proposalDocumentRequired,
        },
      ] as ECEasyFormFieldType[],
    [failures, isLoadingRepairs, existingData],
  );

  const costTableData = useMemo(
    (): CostTableData => ({
      nte: existingData.nte,
      openZeroTotalInfo: openZeroTotalInfo,
      editingTotalCost: true,
      onClickAwayZeroTotalInfo: () => {
        if (openZeroTotalInfo) {
          setOpenZeroTotalInfo(false);
        }
      },
      itemsRepair: [
        {
          label: 'Labor',
          dataPath: 'labor',
        },
        {
          label: 'Material',
          dataPath: 'material',
        },
        {
          label: 'Tax',
          dataPath: 'tax',
        },
        {
          label: 'Freight',
          dataPath: 'freight',
        },
        {
          label: 'Other',
          dataPath: 'other',
        },
      ],
    }),
    [existingData.nte, openZeroTotalInfo],
  );

  const createProposalMutation = useCallback(() => {
    const create = (proposal: any) => {
      const totalCost = costTableData
        ? sumCurrencyFields(
            ...costTableData?.itemsRepair.map(
              entry => proposal[entry.dataPath as string] ?? 0,
            ),
          )
        : 0;
      if (totalCost === 0) {
        const zeroInfoBox = document.querySelector('#ec-costtable-total');
        // If the total cost is not visible, scroll to the element for the pop-up to be visible
        if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
          zeroInfoBox?.scrollIntoView();
        }
        setOpenZeroTotalInfo(true);
        return;
      }

      const params = {
        ...proposal,
      };
      if (proposal.images) {
        params.files = proposal.images.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }

      doCreateProposal(params);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isSuccess,
        reset,
        isLoading,
      },
    ];
  }, [doCreateProposal, data, isError, error, isSuccess, reset, isLoading]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper
        sx={{ paddingTop: StyleConstants.NAV_BAR_HEIGHT }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={createProposalMutation}
          onClose={onClose}
          formConfig={config}
          formFields={fields}
          existingData={existingData}
          costTableData={costTableData}
          isLoading={isLoading}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
}
