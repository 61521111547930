import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';
import { emptyApi } from './emptyApi';
import {
  InventoryItemParams,
  InventoryItemStorage,
  InventoryList,
  InventoryListQueryParams,
  AdjustInventoryItemStock,
  InventoryCreateForm,
  EditInventoryItemStock,
  AllocateInventoryItem,
  InventoryCreateTransferStock,
  ComputedInventoryItemsWithTotal,
  ValidatePartNumber,
} from 'types/Inventory';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Inventory',
    'InventoryForDropdown',
    'InventoryItem',
    'InventoryStorage',
    'InventoryStorageForDropdown',
    'WorkOrderById',
    'InventoryAssigneeUsers',
    'WOInventoryItems',
  ],
});

export const inventoryApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getInventoryItemsList: builder.query<
      BaseType<InventoryList[]>,
      InventoryListQueryParams
    >({
      query: params => {
        return {
          url: 'inventory',
          params,
        };
      },
      transformResponse: (response: BaseType<InventoryList[]>) => {
        const dataWithModuleName = response.data.map(row => {
          return {
            ...row,
            moduleName: 'inventory',
          };
        });

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Inventory'],
    }),
    getInventoryItemsListForDropdown: builder.query<
      BaseType<InventoryList[]>,
      InventoryListQueryParams
    >({
      query: params => {
        return {
          url: 'inventory/dropdown',
          params,
        };
      },
      providesTags: ['InventoryForDropdown'],
    }),

    getInventoryItemById: builder.query<InventoryList, number>({
      query: id => {
        return {
          url: `inventory/${id}`,
        };
      },
      providesTags: ['InventoryItem'],
    }),

    updateInventoryItemById: builder.mutation<void, InventoryItemParams>({
      query: ({ id, ...body }) => {
        return {
          url: `inventory/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['InventoryItem'],
    }),

    getInventoryStorageListByInvetoryId: builder.query<
      BaseType<InventoryItemStorage[]>,
      { id: number; queryParams: QueryParams }
    >({
      query: ({ id, queryParams }) => {
        return {
          url: `inventory/${id}/storages`,
          params: queryParams,
        };
      },
      transformResponse: (response: BaseType<InventoryItemStorage[]>) => {
        const inventoryData = response.data.map(row => ({
          ...row,
          name: row?.inventoryStorage?.name,
        }));

        return {
          ...response,
          data: inventoryData,
        };
      },
      providesTags: ['Inventory', 'InventoryStorage'],
    }),

    getInventoryStorageLocationsList: builder.query<
      BaseType<any[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'inventory-storage',
          params,
        };
      },
      transformResponse: (response: BaseType<any[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'inventory',
          organization: {
            id: row.id,
            label: row.name,
          },
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Inventory', 'InventoryStorage'],
    }),
    getInventoryStorageLocationsListForDropdown: builder.query<
      BaseType<any[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'inventory-storage/dropdown',
          params,
        };
      },
      providesTags: ['InventoryStorageForDropdown'],
    }),

    createInventory: builder.mutation<void, any>({
      query: body => {
        const formData = new FormData();
        formData.append('name', body.name?.toString());
        formData.append('status', '1');
        if (body?.partNumber) {
          formData.append('partNumber', body.partNumber?.toString());
        }
        if (body?.manufacturePartNumber) {
          formData.append(
            'manufacturePartNumber',
            body.manufacturePartNumber?.toString(),
          );
        }
        if (body?.supplier) {
          formData.append('supplier', body.supplier?.toString());
        }
        if (body?.supplierPartNumber) {
          formData.append(
            'supplierPartNumber',
            body.supplierPartNumber?.toString(),
          );
        }
        if (body?.tagId) {
          formData.append('tagId', body.tagId?.toString());
        }
        if (body?.minimumQuantity) {
          formData.append('minimumQuantity', body.minimumQuantity?.toString());
        }
        if (body?.description) {
          formData.append('description', body.description?.toString());
        }
        body?.assetGroup?.forEach((assetGroup, index) => {
          formData.append(`assetTypeIds[${index}]`, assetGroup.id.toString());
        });
        if (body?.manufacturer?.id) {
          formData.append('manufactureId', body?.manufacturer?.id);
        }
        body.files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          }
        });
        body?.storageLocations?.forEach((sl, index) => {
          formData.append(
            `storageLocations[${index}][inventoryStorageId]`,
            sl.id.toString(),
          );
          formData.append(
            `storageLocations[${index}][count]`,
            sl.quantityOnHand ? sl.quantityOnHand.toString() : '0',
          );
          formData.append(
            `storageLocations[${index}][avgPrice]`,
            sl.costPerUnit ? sl.costPerUnit.toString() : '0',
          );
          formData.append(
            `storageLocations[${index}][lot]`,
            sl.lot ? sl.lot.toString() : '',
          );
          formData.append(
            `storageLocations[${index}][bin]`,
            sl.bin ? sl.bin.toString() : '',
          );
        });
        body?.inventoryActivityReceipts?.forEach(file => {
          if (file.processedFile) {
            formData.append('inventoryActivityReceipts', file.processedFile);
          }
        });
        return {
          url: 'inventory',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['Inventory'],
    }),

    getInventoryStorageById: builder.query<any, { id: number }>({
      query: ({ id }) => {
        return {
          url: `inventory-storage/${id}`,
        };
      },
      providesTags: ['Inventory'],
    }),

    getAssigneeUsersByInventoryStorageId: builder.query<
      any[],
      { storageId: number }
    >({
      query: args => {
        if (!args.storageId) {
          return {
            url: '',
          };
        }

        return {
          url: `/inventory-storage/${args.storageId}/assignee-users`,
        };
      },
      providesTags: ['InventoryAssigneeUsers'],
    }),
    createInventoryStorageLocation: builder.mutation<any, InventoryCreateForm>({
      query: body => {
        return {
          url: 'inventory-storage',
          method: 'post',
          body: body,
        };
      },
      invalidatesTags: ['Inventory'],
    }),
    updateInventoryStorageLocation: builder.mutation<void, any>({
      query: body => {
        const { id, ...rest } = body;
        return {
          url: `inventory-storage/${id}`,
          method: 'put',
          body: rest,
        };
      },
      invalidatesTags: ['Inventory', 'InventoryAssigneeUsers'],
    }),
    adjustInventoryItemStock: builder.mutation<void, AdjustInventoryItemStock>({
      query: body => {
        // destructure all values to add them to form data
        const {
          _id,
          inventoryItemId,
          addStock,
          removeStock,
          lot,
          bin,
          description,
          costPerUnit,
          count,
          inventoryActivityReceipts,
        } = body;

        const formData = new FormData();

        if (_id) formData.append('_id', _id);
        if (inventoryItemId)
          formData.append('inventoryItemId', inventoryItemId.toString());
        if (addStock) formData.append('addStock', addStock.toString());
        if (removeStock) formData.append('removeStock', removeStock.toString());
        if (lot) formData.append('lot', lot);
        if (bin) formData.append('bin', bin);
        if (description) formData.append('description', description);
        if (costPerUnit) formData.append('costPerUnit', costPerUnit.toString());
        if (count) formData.append('count', count.toString());
        inventoryActivityReceipts?.forEach(file => {
          if (file.processedFile) {
            formData.append('inventoryActivityReceipts', file.processedFile);
          }
        });

        return {
          url: `inventory-storage/${body._id}/adjust-stock`,
          method: 'put',
          body: formData,
        };
      },
      invalidatesTags: ['InventoryItem', 'Inventory', 'InventoryStorage'],
    }),
    updateInventoryItemStock: builder.mutation<void, EditInventoryItemStock>({
      query: body => {
        const { _id, ...rest } = body;
        return {
          url: `inventory-storage/activity/${_id}`,
          method: 'put',
          body: rest,
        };
      },
      invalidatesTags: ['Inventory', 'InventoryItem', 'InventoryStorage'],
    }),
    allocateInventoryItemToWO: builder.mutation<any, AllocateInventoryItem>({
      query: body => {
        return {
          url: `inventory/allocate-stock/${body?.workOrderId}`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['WorkOrderById', 'WOInventoryItems', 'Inventory'],
    }),

    adjustWOAllocatedInventoryItems: builder.mutation<
      any,
      AllocateInventoryItem
    >({
      query: body => {
        return {
          url: `inventory/allocated-stock/${body?.workOrderId}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['WorkOrderById', 'WOInventoryItems', 'Inventory'],
    }),

    createInventoryStorageTransferStock: builder.mutation<
      any,
      InventoryCreateTransferStock
    >({
      query: body => {
        return {
          url: 'inventory-storage/transfer/stock',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Inventory'],
    }),

    getInventoryAllocatedTo: builder.query<
      BaseType<InventoryList[]>,
      QueryParams
    >({
      query: params => {
        const { queryParams, id } = params;
        return {
          url: `inventory/${id}/allocated-to-work-orders`,
          params: {
            ...queryParams,
            ob: queryParams?.ob || 'wrkordr.id',
          },
        };
      },
      providesTags: ['InventoryItem'],
      keepUnusedDataFor: 15,
    }),
    getInventoryItemsAllocatedToWO: builder.query<
      ComputedInventoryItemsWithTotal,
      { workOrderId: string }
    >({
      query: params => {
        const { workOrderId } = params;
        if (!workOrderId) {
          return {
            url: '',
          };
        }
        return {
          url: `inventory/allocated-stock/${workOrderId}`,
        };
      },
      providesTags: ['WOInventoryItems'],
    }),
    getValidatePartNumber: builder.query<
      ValidatePartNumber,
      { fieldName: string; inputPartNumber: string }
    >({
      query: params => {
        const { fieldName, inputPartNumber } = params;
        if (!inputPartNumber) {
          return {
            url: '',
          };
        }
        return {
          url: `inventory/check-duplicate/${fieldName}/${inputPartNumber}`,
        };
      },
    }),
  }),
});

export const {
  useGetInventoryItemsListQuery,
  useLazyGetInventoryItemsListQuery,
  useGetInventoryItemsListForDropdownQuery,
  useLazyGetInventoryItemsListForDropdownQuery,
  useGetInventoryItemByIdQuery,
  useLazyGetInventoryItemByIdQuery,
  useUpdateInventoryItemByIdMutation,
  useCreateInventoryMutation,
  useGetInventoryStorageListByInvetoryIdQuery,
  useLazyGetInventoryStorageListByInvetoryIdQuery,
  useGetInventoryStorageLocationsListQuery,
  useLazyGetInventoryStorageLocationsListQuery,
  useGetInventoryStorageLocationsListForDropdownQuery,
  useLazyGetInventoryStorageLocationsListForDropdownQuery,
  useGetAssigneeUsersByInventoryStorageIdQuery,
  useLazyGetAssigneeUsersByInventoryStorageIdQuery,
  useGetInventoryStorageByIdQuery,
  useLazyGetInventoryStorageByIdQuery,
  useUpdateInventoryStorageLocationMutation,
  useCreateInventoryStorageLocationMutation,
  useAdjustInventoryItemStockMutation,
  useUpdateInventoryItemStockMutation,
  useAllocateInventoryItemToWOMutation,
  useCreateInventoryStorageTransferStockMutation,
  useGetInventoryAllocatedToQuery,
  useLazyGetInventoryAllocatedToQuery,
  useGetInventoryItemsAllocatedToWOQuery,
  useLazyGetInventoryItemsAllocatedToWOQuery,
  useAdjustWOAllocatedInventoryItemsMutation,
  useLazyGetValidatePartNumberQuery,
} = inventoryApi;
