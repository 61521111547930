import { ECDynamicPageTemplate } from 'app/components';
import * as _ from 'lodash';
import moment from 'moment';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLazyGetAttachmentQuery } from 'services/attachmentApi';
import {
  useAddTroubleshootTipsMutation,
  useLazyGetTroubleshootTipsQuery,
  useLazyGetTroubleshootTipsByIdQuery,
  useUpdateTroubleshootTipsMutation,
} from 'services/troubleshootingTipsApi';
import { AttachmentCategory } from 'types/Attachment';
import { loadPageScopes } from 'utils/pageScopes';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementDetails = require('./fancy_form_config_details.json');

export function TroubleshootingTipsPage({ marginTop = true }) {
  const { t } = useTranslation();
  const [row, setRow] = useState<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('troubleshooting');
  }, []);

  const [triggerTroubleshoot, resultTroubleshoot] =
    useLazyGetTroubleshootTipsByIdQuery();

  const {
    data: troubleshootTip,
    isSuccess: isSuccessTroubleshootTip,
    isLoading: isLoadingTroubleshootTip,
    isFetching: isFetchingTroubleshootTip,
  } = resultTroubleshoot;

  const [triggerAttachment, resultAttachment] = useLazyGetAttachmentQuery();

  const {
    data: attachments,
    isSuccess: isSuccessAttachment,
    isLoading: isLoadingAttachment,
    isFetching: isFetchingAttachment,
  } = resultAttachment;

  const useGetById = useCallback(() => {
    const doGetById = async row => {
      if (!row) return false;

      await triggerTroubleshoot(row);
      await triggerAttachment({
        module: 'troubleshoot',
        moduleId: row.id,
        category: AttachmentCategory.All,
      });
    };

    const result =
      !troubleshootTip && !attachments
        ? null
        : {
            ...troubleshootTip,
            images: attachments?.others?.map(attachment => {
              return {
                ...attachment,
                id: attachment.id,
                preview: attachment.url,
                type: attachment.attachmentType,
                uploadedBy: attachment?.createdByUserEmail,
                uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
                documentType: attachment?.documentCoreType?.name,
              };
            }),
          };

    return [
      doGetById,
      {
        data: result,
        loading: isLoadingTroubleshootTip || isLoadingAttachment,
        isSuccess: isSuccessTroubleshootTip || isSuccessAttachment,
        isFetching: isFetchingTroubleshootTip || isFetchingAttachment,
      },
    ];
  }, [
    troubleshootTip,
    attachments,
    isLoadingTroubleshootTip,
    isLoadingAttachment,
    isSuccessTroubleshootTip,
    isSuccessAttachment,
    isFetchingTroubleshootTip,
    isFetchingAttachment,
    triggerAttachment,
    triggerTroubleshoot,
  ]);

  const [
    doUpdateTroubleshoot,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateTroubleshootTipsMutation(row);

  const [
    doAddTroubleshootTip,
    {
      data: createTroubleshootTipData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateTroubleshootTip,
    },
  ] = useAddTroubleshootTipsMutation();

  const useCreateTroubleshootTip = useCallback(() => {
    const doCreate = data => {
      doAddTroubleshootTip(data);
    };

    return [
      doCreate,
      {
        data: createTroubleshootTipData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        reset: resetCreateTroubleshootTip,
      },
    ];
  }, [
    createTroubleshootTipData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    resetCreateTroubleshootTip,
    doAddTroubleshootTip,
  ]);

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const newAttachments = data.images.filter(image => !image?.id);

      const deleteAttachmentsIds: number[] = _.difference(
        attachments?.others?.map(image => image.id),
        data.images?.map(image => image.id),
      );

      await doUpdateTroubleshoot({
        id: data.id,
        active: data.active,
        description: data.description,
        name: data.name,
        video: data.video,
        status: data.status,
        deleteAttachmentsIds,
        images: newAttachments,
      });
    };

    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    updateData,
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    doUpdateTroubleshoot,
    attachments,
  ]);

  const onDrawerClose = useCallback(() => {
    setRow({});
  }, []);

  return (
    <ECDynamicPageTemplate
      pageTitle={`${
        row?.name || `${t('translation:pages.troubleshootTipLibrary.title')}`
      }`}
      useLazyGetListQuery={useLazyGetTroubleshootTipsQuery}
      showStatusActiveFilter={true}
      useLazyGetByIdQuery={useGetById}
      useCreateMutation={useCreateTroubleshootTip}
      useUpdateMutation={useUpdate}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementDetails.data.config}
      detailsFields={fancyFormElementDetails.data.fields}
      onDrawerClose={onDrawerClose}
      hasDetails
      showOnlyHistoryTab
      showImageAttachment={false}
      marginTop={marginTop}
      onRowClick={setRow}
      moduleName="troubleshoot"
      shouldNotUseActiveFilter
      showDrawerDetailTitle={false}
    />
  );
}
