import {
  ECAlert,
  ECBox,
  ECEasyFormConfigType,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECImage,
  ECLink,
  ECPaper,
  ECTypography,
  FieldTypes,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateInvoiceMutation } from 'services/invoiceApi';
import { useGetRepairsListForAssetQuery } from 'services/repairApi';
import { workOrdersApi } from 'services/workOrdersApi';
import { useMatch, useNavigate } from 'react-router';
import CoupaCustomerImage from '../../../assets/coupa.svg';
import CoupaSpImage from '../../../assets/coupasp.svg';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import moment from 'moment-timezone';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { useServiceProviderUser } from 'app/hooks';
import { useGetSettingsBulletinQuery } from 'services/lookupApi';
import { moduleApi } from 'services/moduleApi';
import { ComputedInventoryItemsWithTotal } from 'types/Inventory';
import { convertComputedInventoryItemsToInventoryShape } from 'utils/common';
import { StyleConstants } from 'styles/StyleConstants';
import { isoDateWithTimezoneAtZero } from 'utils/strings/formatDate';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
  allocatedInventoryItems?: ComputedInventoryItemsWithTotal;
}

const config: ECEasyFormConfigType = {
  variant: 'simple',
  name: 'Invoice',
  title: 'Add Invoice',
  cols: 1,
  submitTitle: 'Save',
  subtitle: '',
};

export function CreateSPInvoiceDrawer({
  onClose,
  existingData,
  isOpen,
  allocatedInventoryItems,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const isSPUser = useServiceProviderUser();

  const CoupaImage = isSPUser ? CoupaSpImage : CoupaCustomerImage;

  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);
  const [failureTypesReadOnly, setFailureTypesReadOnly] =
    useState<boolean>(false);

  const { data: failures, isLoading: isLoadingGetRepairs } =
    useGetRepairsListForAssetQuery(existingData.assetId);

  const [
    doCreateInvoice,
    { data, isError, error, isSuccess, reset, isLoading },
  ] = useCreateInvoiceMutation();

  useEffect(() => {
    if (
      existingData.pmId ||
      existingData.requestCategoryName === 'Maintenance'
    ) {
      setFailureTypesReadOnly(true);
    }
  }, [existingData]);

  const [isInvoiceRequired, setIsInvoiceRequired] = useState(true);
  useEffect(() => {
    if (isSuccess && data) {
      if (!existingData.enableCoupa) {
        reset();
        navigate(`${match?.pathnameBase}/sp/invoice/${data.id}`);
      }
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
    }
  }, [isSuccess, dispatch]);

  const inventoryAllocatedItems = useMemo(() => {
    return allocatedInventoryItems?.allocatedItems
      ? convertComputedInventoryItemsToInventoryShape(
          allocatedInventoryItems?.allocatedItems,
        )
      : [];
  }, [allocatedInventoryItems?.allocatedItems]);

  const isFailureTypeRequired = useMemo(() => {
    return existingData?.requiredFields?.inventoryFailureTypeRequired &&
      isInvoiceRequired
      ? true
      : false;
  }, [
    existingData?.requiredFields?.inventoryFailureTypeRequired,
    isInvoiceRequired,
  ]);

  const fields = useMemo(
    () =>
      // Coupa is a 3rd party system that integrates the invoices/WO with Purchase Orders
      // so we don't need most fields for Coupa
      existingData?.enableCoupa
        ? ([
            {
              fieldName: 'requestId',
              visible: false,
            },
            {
              type: FieldTypes.Group,
              groupColumns: 2,
              subFields: [
                {
                  type: FieldTypes.Text,
                  label: 'Problem Type',
                  readOnly: true,
                  fieldName: 'problem',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Customer',
                  readOnly: true,
                  fieldName: 'customer',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Work Order ID',
                  readOnly: true,
                  fieldName: 'workorderId',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Asset Name',
                  readOnly: true,
                  fieldName: 'assetName',
                },
              ],
            },
            {
              type: FieldTypes.Text,
              label: 'Location',
              readOnly: true,
              fieldName: 'branchName',
            },
            {
              type: FieldTypes.Checkbox,
              options: ['Invoice is not Required'],
              fieldName: 'notRequired',
              value: isInvoiceRequired ? [] : ['Invoice is not Required'],
              onChange: () => {
                setIsInvoiceRequired(prev => !prev);
              },
              visible: true,
              fullWidth: false,
              optionalSx: {
                '.MuiFormControlLabel-root': {
                  width: 'fit-content',
                },
              },
            },
            {
              type: FieldTypes.Group,
              groupColumns: 2,
              subFields: [
                {
                  type: FieldTypes.Text,
                  label: 'Invoice Number',
                  placeholder: 'Invoice Number',
                  fieldName: 'invoiceNumber',
                  visible: !isInvoiceRequired,
                },
                {
                  type: FieldTypes.DatePicker,
                  label: 'Invoice Date',
                  placeholder: 'Invoice Date',
                  fieldName: 'invoiceDate',
                  visible: !isInvoiceRequired,
                },
              ],
            },
            {
              type: FieldTypes.FailureWarranty,
              label: `Failure Type${!isFailureTypeRequired ? ' *' : ' '}`,
              placeholder: `Failure Type${!isFailureTypeRequired ? ' *' : ' '}`,
              fieldName: 'failures',
              visible: !failureTypesReadOnly,
              options:
                failures && failures?.length
                  ? failures?.map(failure => ({
                      name: failure.name,
                      id: failure.id,
                    }))
                  : [],
              isLoadingGetRepairs,
              required: !isFailureTypeRequired,
            },
            {
              type: FieldTypes.Text,
              label: `Failure Type`,
              placeholder: `Failure Type`,
              readOnly: true,
              value: 'Scheduled Service',
              fieldName: 'failures',
              required: false,
              visible: failureTypesReadOnly,
            },
            {
              type: FieldTypes.Switch,
              label: 'Warranty Used in Repair',
              fieldName: 'warrantyUsed',
            },
            {
              type: FieldTypes.FilePicker,
              label: 'Attatchments',
              value: null,
              options: [],
              fieldName: 'images',
              fileType: 'photo',
              visible: !isInvoiceRequired,
            },
          ] as ECEasyFormFieldType[])
        : ([
            {
              fieldName: 'requestId',
              visible: false,
            },
            {
              type: FieldTypes.Group,
              groupColumns: 2,
              subFields: [
                {
                  type: FieldTypes.Text,
                  label: 'Problem Type',
                  readOnly: true,
                  fieldName: 'problem',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Customer',
                  readOnly: true,
                  fieldName: 'customer',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Work Order ID',
                  readOnly: true,
                  fieldName: 'workorderId',
                },
                {
                  type: FieldTypes.Text,
                  label: 'Asset Name',
                  readOnly: true,
                  fieldName: 'assetName',
                },
              ],
            },
            {
              type: FieldTypes.Text,
              label: 'Location',
              readOnly: true,
              fieldName: 'branchName',
            },
            // TODO: this checkbox should disable rest of form if enable
            {
              type: FieldTypes.Checkbox,
              options: ['Invoice is not Required'],
              fieldName: 'notRequired',
              value: isInvoiceRequired ? [] : ['Invoice is not Required'],
              onChange: () => {
                setIsInvoiceRequired(prev => !prev);
              },
              visible: true,
              fullWidth: false,
              optionalSx: {
                '.MuiFormControlLabel-root': {
                  width: 'fit-content',
                },
              },
            },
            {
              type: FieldTypes.Group,
              groupColumns: 2,
              subFields: [
                {
                  type: FieldTypes.Text,
                  label: 'Invoice Number',
                  placeholder: 'Invoice Number',
                  fieldName: 'invoiceNumber',
                  required: isInvoiceRequired,
                },
                {
                  type: FieldTypes.DatePicker,
                  label: 'Invoice Date',
                  placeholder: 'Invoice Date',
                  fieldName: 'invoiceDate',
                  required:
                    isInvoiceRequired &&
                    !!existingData?.requiredFields?.invoiceDateRequired,
                },
              ],
            },
            {
              type: failureTypesReadOnly
                ? FieldTypes.Text
                : FieldTypes.FailureWarranty,
              label: `Failure Type${isFailureTypeRequired ? ' *' : ' '}`,
              placeholder: `Failure Type${isFailureTypeRequired ? ' *' : ' '}`,
              fieldName: 'failures',
              visible: !failureTypesReadOnly,
              options:
                failures && failures?.length
                  ? failures?.map(failure => ({
                      name: failure.name,
                      id: failure.id,
                    }))
                  : [],
              isLoadingGetRepairs,
              required: isFailureTypeRequired,
            },
            {
              type: FieldTypes.Text,
              label: `Failure Type${!failureTypesReadOnly && isInvoiceRequired ? ' *' : ' '}`,
              placeholder: `Failure Type${!failureTypesReadOnly && isInvoiceRequired ? ' *' : ' '}`,
              readOnly: true,
              value: 'Scheduled Service',
              fieldName: 'failures',
              required: failureTypesReadOnly && !isInvoiceRequired,
              visible: failureTypesReadOnly,
            },
            {
              type: FieldTypes.CostTable,
            },
            {
              type: FieldTypes.Text,
              label: 'Note',
              fieldName: 'description',
              maxLength: 1000,
            },
            {
              type: FieldTypes.Switch,
              label: 'Warranty Used in Repair',
              fieldName: 'warrantyUsed',
            },
            {
              type: FieldTypes.FilePicker,
              label: 'Attatchments',
              value: null,
              options: [],
              fieldName: 'images',
              fileType: 'photo',
              required:
                isInvoiceRequired &&
                !!existingData?.requiredFields?.invoiceDocumentRequired,
            },
          ] as ECEasyFormFieldType[]),
    [
      failures,
      isLoadingGetRepairs,
      isInvoiceRequired,
      failureTypesReadOnly,
      existingData?.requiredFields,
      existingData?.internal,
      existingData?.enableCoupa,
      allocatedInventoryItems,
    ],
  );

  const costTableData = useMemo(
    (): CostTableData => ({
      nte: existingData.nte,
      openZeroTotalInfo: openZeroTotalInfo,
      editingTotalCost: false,
      onClickAwayZeroTotalInfo: () => {
        if (openZeroTotalInfo) {
          setOpenZeroTotalInfo(false);
        }
      },
      itemsRepair: [
        {
          label: 'Labor',
          dataPath: 'costLabor',
          required: false,
        },
        {
          label: 'Material',
          dataPath: 'costMaterial',
          required: false,
        },
        {
          label: 'Tax',
          dataPath: 'costTax',
          required: false,
        },
        {
          label: 'Freight',
          dataPath: 'costFreight',
          required: false,
        },
        {
          label: 'Other',
          dataPath: 'costOther',
          required: false,
        },
      ],
      itemsInventory: inventoryAllocatedItems.map(inventory => ({
        ...inventory,
        readOnly: true,
      })),
      shouldHideCostTable: !isInvoiceRequired,
    }),

    [
      existingData.nte,
      openZeroTotalInfo,
      inventoryAllocatedItems,
      isInvoiceRequired,
    ],
  );

  const createInvoiceMutation = useCallback(() => {
    const create = (invoice: any) => {
      setOpenZeroTotalInfo(false);
      const params = {
        ...invoice,
        enableCoupa: existingData.enableCoupa,
        warrantyUsed: invoice.warrantyUsed ? 1 : 0,
        workorderId: existingData.workorderId,
        spCompanyId: existingData.spCompanyId,
        costFreight:
          invoice.notRequired?.length || !invoice.costFreight
            ? 0
            : invoice.costFreight,
        costLabor:
          invoice.notRequired?.length || !invoice.costLabor
            ? 0
            : invoice.costLabor,
        costMaterial:
          invoice.notRequired?.length || !invoice.costMaterial
            ? 0
            : invoice.costMaterial,
        costOther:
          invoice.notRequired?.length || !invoice.costOther
            ? 0
            : invoice.costOther,
        costTax:
          invoice.notRequired?.length || !invoice.costTax ? 0 : invoice.costTax,
        invoiceDate: isoDateWithTimezoneAtZero(invoice.invoiceDate),
        isFileAttached:
          Array.isArray(invoice.images) && !!invoice.images.length,
        inventoryItems:
          !invoice.notRequired?.length && inventoryAllocatedItems
            ? inventoryAllocatedItems.map(item => ({
                count: item.count,
                price: item.price,
                itemId: item.inventoryId,
                total: Number(item.total),
                storageLocationId: item.inventoryStorageId,
              }))
            : undefined,
      };

      if (!failureTypesReadOnly && typeof invoice?.failures !== 'string') {
        params.warrantyComponents = invoice?.failures?.map(failure => ({
          noWarranty: failure.warranty.noWarranty ? 1 : 0,
          assetId: existingData.assetId,
          repairId: failure.repairId,
          resolutionId: failure.warranty.resolutionId,
          periodCode: failure.warranty.periodCode,
          duration: failure.warranty.period,
        }));
      }

      if (!invoice.notRequired?.length && !existingData.enableCoupa) {
        const totalCost = costTableData
          ? sumCurrencyFields(
              ...costTableData?.itemsRepair.map(
                entry => params[entry.dataPath as string] ?? 0,
              ),
            )
          : 0;
        const subtotalForInventoryCost = inventoryAllocatedItems.reduce(
          (acc, item) => acc + item.price,
          0,
        );

        if (totalCost + subtotalForInventoryCost === 0) {
          const zeroInfoBox = document.querySelector('#ec-costtable-total');
          // If the total cost is not visible, scroll to the element for the pop-up to be visible
          if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
            zeroInfoBox?.scrollIntoView();
          }
          setOpenZeroTotalInfo(true);
          return;
        }
      }

      if (invoice.images) {
        params.files = invoice.images.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }
      doCreateInvoice(params);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isSuccess,
        isLoading,
        reset,
      },
    ];
  }, [
    doCreateInvoice,
    data,
    isError,
    error,
    isSuccess,
    isLoading,
    reset,
    failureTypesReadOnly,
    costTableData?.itemsRepair,
    existingData?.enableCoupa,
  ]);

  const splashScreenTemplate = useCallback(
    (children: ReactElement) => {
      return (
        <ECAlert
          sx={{
            width: '95%',
            height: '96%',
            marginLeft: '23px',
            '.MuiAlert-message': {
              width: '100% !important',
            },
          }}
          severity="info"
          icon={false}
        >
          {children}
        </ECAlert>
      );
    },
    [isSPUser],
  );

  const customSuccessScreen = useMemo(() => {
    return splashScreenTemplate(
      <ECBox
        display={'flex'}
        flexDirection={'column'}
        sx={{
          alignItems: 'center',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <ECImage src={CoupaImage} />
        <ECTypography
          mt={2}
          mb={2}
          variant="body2"
          marginRight={'10px'}
          fontWeight={'bold'}
        >
          Done!
        </ECTypography>
        <ECLink
          mt={2}
          variant="body2"
          marginRight={'10px'}
          fontWeight={'bold'}
          href={
            // I think this HREF is not being used, check it on the field itself
            isSPUser
              ? existingData?.coupaSetting?.coupaBaseUrl
              : existingData?.coupaSetting?.coupaBaseUrl
                ? `${existingData?.coupaSetting?.coupaBaseUrl}/order_headers/${
                    data?.coupaId || existingData?.coupaId
                  }`
                : ''
          }
        >
          Click here to view the Purchase Order in Coupa
        </ECLink>
      </ECBox>,
    );
  }, [existingData, splashScreenTemplate, data, isSPUser, CoupaImage]);

  const customLoadingScreen = useMemo(() => {
    return splashScreenTemplate(
      <ECBox
        display={'flex'}
        flexDirection={'column'}
        sx={{
          alignItems: 'center',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <ECImage src={CoupaImage} />
        <ECTypography
          mt={2}
          mb={2}
          variant="body2"
          marginRight={'10px'}
          fontWeight={'bold'}
        >
          Creating your Purchase Order...
        </ECTypography>
        <ECCircularProgress />
      </ECBox>,
    );
  }, [splashScreenTemplate, CoupaImage]);

  const customErrorScreen = useMemo(() => {
    return splashScreenTemplate(
      <ECBox
        display={'flex'}
        flexDirection={'column'}
        sx={{
          alignItems: 'center',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <ECImage src={CoupaImage} />
        <ECAlert
          severity="info"
          sx={{ marginTop: 1, textAlign: 'center' }}
          icon={false}
        >
          <ECTypography
            fontWeight={'bold'}
            fontSize={20}
            sx={{ width: '550px' }}
          >
            The system is experiencing a configuration issue with creating a
            Purchase Order in the Coupa software.
          </ECTypography>
        </ECAlert>
        <ECBox sx={{ textAlign: 'center' }}>
          <ECTypography
            sx={{ width: '480px' }}
            fontWeight={'500'}
            mt={1}
            fontSize={16}
          >
            A message has been sent to the Flynn site administrator to correct
            this issue.
            <span style={{ fontWeight: 'bold' }}>
              You will be notified when the Purchase Order is available in the
              Coupa Supplier Portal.
            </span>
          </ECTypography>
        </ECBox>

        <ECBox sx={{ textAlign: 'center' }}>
          <ECTypography
            sx={{ width: '480px' }}
            fontWeight={'500'}
            mt={2}
            fontSize={16}
          >
            If you are still looking for your Purchase Order in the Coupa
            Supplier Portal, please email ecotrak@flynnrg.com
          </ECTypography>
        </ECBox>
      </ECBox>,
    );
  }, [CoupaImage]);

  const customBlackoutScreen = useMemo(() => {
    return splashScreenTemplate(
      <ECBox
        display={'flex'}
        flexDirection={'column'}
        sx={{
          alignItems: 'center',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <ECImage src={CoupaImage} />
        <ECAlert
          severity="info"
          sx={{ marginTop: 2, textAlign: 'center' }}
          icon={false}
        >
          <ECTypography
            fontWeight={'bold'}
            fontSize={28}
            sx={{ width: '600px' }}
          >
            Invoicing for Flynn Restaurant locations is temporarily unavailable
            from Jan 22 – Jan 29.
          </ECTypography>
        </ECAlert>
        <ECBox sx={{ textAlign: 'center' }}>
          <ECTypography
            sx={{ width: '480px' }}
            fontWeight={'400'}
            mt={1}
            fontSize={18}
          >
            After completing your Work Order, please return on Jan 29 to submit
            your invoice. Submitting invoices before Jan 29 by alternate
            submission paths (i.e., email) may result in delays in processing.
          </ECTypography>
        </ECBox>
      </ECBox>,
    );
  }, [CoupaImage]);

  useEffect(() => {
    if (existingData.enableCoupa && isError) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [isError, existingData.enableCoupa, dispatch]);

  // during the blackout period we will not allow to create invoices
  // blackout period is from 22nd January to 29th January
  // if you see this code after january 29 2024 please remove it
  const { data: bulletinData } = useGetSettingsBulletinQuery();

  const isFlynnBlackoutPeriod = useMemo(() => {
    // use bulletin to retrieve server time
    const bulletinServerInfo = bulletinData?.find(
      bl => bl.settingName === 'serverTime',
    )?.settingValue;

    const bulletinCoupaInvoiceStartDate = bulletinData?.find(
      bl => bl.settingName === 'COUPA_INVOICE_START_DATE',
    )?.settingValue;

    const blackoutEndDate = moment.tz(
      bulletinCoupaInvoiceStartDate || '2024-01-29T06:00:00-08:00',
      'America/Los_Angeles',
    );
    const currentDate = bulletinServerInfo
      ? moment.tz(bulletinServerInfo, 'America/Los_Angeles')
      : moment();

    return !currentDate.isAfter(blackoutEndDate);
  }, [bulletinData]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper
        sx={{ paddingTop: StyleConstants.NAV_BAR_HEIGHT, height: '100%' }}
        role="presentation"
      >
        {existingData.enableCoupa && isFlynnBlackoutPeriod ? (
          customBlackoutScreen
        ) : (
          <ECEasyFormCreate
            useCreateMutation={createInvoiceMutation}
            onClose={onClose}
            formConfig={config}
            formFields={fields}
            existingData={existingData}
            costTableData={costTableData}
            CustomSuccessScreen={
              existingData.enableCoupa && isInvoiceRequired
                ? customSuccessScreen
                : undefined
            }
            CustomErrorScreen={
              existingData.enableCoupa && isInvoiceRequired
                ? customErrorScreen
                : undefined
            }
            CustomLoadingScreen={
              existingData.enableCoupa && isInvoiceRequired
                ? customLoadingScreen
                : undefined
            }
            isLoading={isLoading}
          />
        )}
      </ECPaper>
    </ECDrawerDetails>
  );
}
