import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { ForwardedRef, forwardRef } from 'react';

export const TextFieldStyled = styled(TextField)<TextFieldProps>(
  ({ theme, error }) => ({
    '& .MuiInput-root:before': {
      borderBottomColor: error
        ? theme.palette.error.main
        : theme.palette.grey[300],
    },
    '& .MuiFilledInput-root:after': {
      transform: error ? 'scaleX(1) !important' : 'scaleX(0) !important',
      borderBottomColor: error
        ? `${theme.palette.error.main} !important`
        : theme.palette.grey[300],
    },
  }),
);

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  mask?: string;
  name: string;
}

// Based on https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        definitions={{
          '#': /[a-zA-Z0-9]/,
        }}
        overwrite
      />
    );
  },
);

type ECTextFieldProps = {
  mask?: string;
} & TextFieldProps;

export const ECTextField = forwardRef(
  ({ mask, ...props }: ECTextFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    if (mask) {
      return (
        <TextFieldStyled
          {...props}
          ref={ref}
          InputProps={{
            ...props.InputProps,
            inputProps: {
              ...(props.InputProps?.inputProps || {}),
              mask,
            },
            inputComponent: TextMaskCustom as any,
          }}
        />
      );
    }

    return <TextFieldStyled {...props} ref={ref} />;
  },
);

export const ECTextFieldWhiteBorders = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    color: theme.palette.background.default,
    '& label.Mui': {
      color: theme.palette.background.default,
    },
    '& label.MuiInputLabel-root': {
      color: theme.palette.background.default,
    },
    '& label.Mui-focused': {
      color: theme.palette.background.default,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.default,
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.background.default,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.background.default,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.background.default,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.default,
        color: theme.palette.background.default,
      },
    },
  }),
);

export const ECOutlinedInputWhiteBorders = styled(
  OutlinedInput,
)<OutlinedInputProps>(({ theme }) => ({
  color: theme.palette.background.default,
  '& fieldset': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  '&:hover fieldset': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  '&.Mui-focused fieldset': {
    borderColor: theme.palette.background.default,
    color: theme.palette.background.default,
  },
}));
