import { AttachmentCategory } from 'types/Attachment';
import { ECAlert, ECBox, ECIconButton, ECTypography } from '..';
import { useCallback, useMemo, useRef } from 'react';
import Compressor from 'compressorjs';
import {
  FilePickerTypes,
  ImagePreview,
  validateFileType,
} from '../ECFilePicker';
import { Close, Upload } from '@mui/icons-material';

export interface ECBannerFilePickerProps {
  label?: string;
  type?: FilePickerTypes;
  convertToBase64?: boolean;
  value?: ImagePreview[];
  maxLength?: number;
  onChange?: (files: any) => void;
  fieldName: string;
}

const MAX_SIZE_IN_BYTES = 10000000; // 10MB
const MAX_WIDTH_SIZE = 2048;
const MAX_HEIGHT_SIZE = 2048;

export const ECBannerFilePicker = ({
  type = FilePickerTypes.Document,
  convertToBase64,
  fieldName,
  value,
  label,
  onChange,
  maxLength,
}: ECBannerFilePickerProps) => {
  const converToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const processFileFn = {
    [FilePickerTypes.Photo]: convertToBase64 ? converToBase64 : null,
    [FilePickerTypes.Document]: null,
  };

  const handleFileInput = async e => {
    const rawFile = e.target.files[0];

    if (rawFile && !validateFileType(rawFile?.name)) {
      alert('Invalid file type');
      return;
    }

    if (e.target.files[0]?.size > MAX_SIZE_IN_BYTES) {
      if (e.target.files[0]?.type?.includes('image')) {
        const result = await new Promise((resolve, reject) => {
          new Compressor(e.target.files[0], {
            convertSize: MAX_SIZE_IN_BYTES,
            width: MAX_WIDTH_SIZE,
            height: MAX_HEIGHT_SIZE,
            resize: 'contain',
            success: resolve,
            error: reject,
          });
        });

        const processedFile = new File([result as any], (result as any).name);
        e.target.value = null;
        const url = URL.createObjectURL(rawFile);
        onChange?.([
          ...(value ?? []),
          {
            processedFile,
            rawFile: processedFile,
            url,
            preview: url,
            name: processedFile?.name || '',
            originalName: processedFile.name || '',
            attachmentType: (result as any).type.includes('image')
              ? AttachmentCategory.Photo
              : AttachmentCategory.Document,
            fileType: (result as any).type?.split('/')[1],
          },
        ]);
        return;
      } else {
        alert('File size cannot exceed 10MB');
        return;
      }
    }
    const processedFile = (await processFileFn[type]?.(rawFile)) ?? rawFile;

    e.target.value = null;
    const url = URL.createObjectURL(rawFile);
    onChange?.([
      ...(value ?? []),
      {
        processedFile: processedFile,
        rawFile,
        url,
        preview: url,
        name: processedFile.name,
        originalName: processedFile.name,
        attachmentType: processedFile.type.includes('image')
          ? AttachmentCategory.Photo
          : AttachmentCategory.Document,
        fileType: processedFile.type?.split('/')[1],
      },
    ]);
  };

  const handleDeletePreview = useCallback(
    (photoUrl: string) => {
      const newImgPreviews =
        value?.filter(img => img.preview !== photoUrl) ?? [];
      onChange?.(newImgPreviews);
    },
    [value],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleBrowseFileClick = () => {
    inputRef?.current?.click();
  };

  const uploadedImages = useMemo(() => {
    // return a ECAlert with severity success for each uploaded image
    return value?.map(img => (
      <ECAlert
        severity="success"
        key={img.preview}
        sx={{
          width: '100%',
          alignItems: 'center',
          '.MuiAlert-message': {
            width: '100% !important',
          },
        }}
      >
        <ECBox
          display={'flex'}
          sx={{
            alignItems: 'center',
            height: '30px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ECTypography variant="body1" margin={'0 5px'} fontWeight={500}>
            {img.originalName}
          </ECTypography>
          <ECIconButton
            size="medium"
            onClick={() => handleDeletePreview(img.preview)}
          >
            <Close color="error" />
          </ECIconButton>
        </ECBox>
      </ECAlert>
    ));
  }, [value, handleDeletePreview]);

  return (
    <ECBox width={'100%'}>
      <ECAlert
        severity="warning"
        variant="outlined"
        sx={{
          width: '100%',
          alignItems: 'center',
          '.MuiAlert-message': {
            width: '100% !important',
          },
        }}
      >
        <ECBox
          display={'flex'}
          sx={{
            alignItems: 'center',
            height: '30px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ECTypography variant="body1" margin={'0 5px'} fontWeight={500}>
            {label}
          </ECTypography>
          <ECBox display={'flex'} sx={{ alignItems: 'center', height: '30px' }}>
            <ECTypography
              variant="body2"
              marginRight={'10px'}
              fontWeight={'bold'}
            >
              UPLOAD
            </ECTypography>

            <ECIconButton
              size="medium"
              onClick={handleBrowseFileClick}
              disabled={maxLength ? value && value?.length >= maxLength : false}
            >
              <>
                <Upload />
                <input
                  ref={inputRef}
                  type="file"
                  id={fieldName}
                  name={fieldName}
                  onChange={handleFileInput}
                  hidden
                />
              </>
            </ECIconButton>
          </ECBox>
        </ECBox>
      </ECAlert>
      {uploadedImages}
    </ECBox>
  );
};
