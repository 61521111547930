import * as React from 'react';

import { saveTheme } from 'styles/theme/utils';
import { themeActions } from 'styles/theme/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ECFormControl, ECFormControlLabel } from '../ECForm';
import { ECRadio, ECRadioGroup } from '../ECRadio';
import { ThemeKeyType } from 'styles/theme/slice/types';
import { selectThemeKey } from 'styles/theme/slice/selectors';

export const ECThemeSwitch = props => {
  const theme = useSelector(selectThemeKey);
  const dispatch = useDispatch();

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ThemeKeyType;
    saveTheme(value);
    dispatch(themeActions.changeTheme(value));
  };
  return (
    <ECFormControl>
      <ECRadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={theme}
        onChange={handleThemeChange}
      >
        <ECFormControlLabel
          value="system"
          control={<ECRadio />}
          label="System theme"
        />
        <ECFormControlLabel value="light" control={<ECRadio />} label="Light" />
        <ECFormControlLabel value="dark" control={<ECRadio />} label="Dark" />
      </ECRadioGroup>
    </ECFormControl>
  );
};
