import { useMemo } from 'react';

// porp-types is a library for typechecking of props
// import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Radar } from 'react-chartjs-2';

// Material Dashboard 2 React components
import {
  ECBox,
  ECChartContainerCard,
  ECChartIconContainerCard,
  ECChartTitleContainerBox,
  ECTypography,
} from 'app/components';

// RadarChart configurations
import configs from './configs';

function ECRadarChart({ icon, color, title, description, chart }) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map(dataset => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <ECBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <ECBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <ECChartIconContainerCard color={color}>
              {icon.component}
            </ECChartIconContainerCard>
          )}
          <ECChartTitleContainerBox icon={icon}>
            {title && <ECTypography variant="h6">{title}</ECTypography>}
            <ECBox mb={2}>
              <ECTypography variant="button" color="text">
                {description}
              </ECTypography>
            </ECBox>
          </ECChartTitleContainerBox>
        </ECBox>
      ) : null}
      {useMemo(
        () => (
          <ECBox p={6}>
            <Radar data={data} options={options} />
          </ECBox>
        ),
        [data, options],
      )}
    </ECBox>
  );

  return title || description ? (
    <ECChartContainerCard>{renderChart}</ECChartContainerCard>
  ) : (
    renderChart
  );
}

// Setting default values for the props of RadarChart
ECRadarChart.defaultProps = {
  icon: { color: 'info', component: '' },
  title: '',
  description: '',
};

// Typechecking props for the RadarChart
// ECRadarChart.propTypes = {
//   icon: PropTypes.shape({
//     color: PropTypes.oneOf([
//       'primary',
//       'secondary',
//       'info',
//       'success',
//       'warning',
//       'error',
//       'light',
//       'dark',
//     ]),
//     component: PropTypes.node,
//   }),
//   title: PropTypes.string,
//   description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   chart: PropTypes.objectOf(PropTypes.array).isRequired,
// };

export default ECRadarChart;
