import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';

export interface Step {
  id: number;
  name: string;
  subLabel?: string;
  isSelected?: boolean;
  showDivider?: boolean;
  onClick?: (name: number) => void;
}

export const ECStepItem = ({
  id,
  name,
  subLabel,
  isSelected,
  showDivider,
  onClick,
}: Step) => {
  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <>
      {showDivider && (
        <ECBox
          display="flex"
          width="100%"
          height="1px"
          bgcolor={theme =>
            isSelected ? theme.palette.primary.main : theme.palette.grey[400]
          }
          mx={2}
        />
      )}
      <ECBox
        sx={{ cursor: onClick ? 'pointer' : 'auto' }}
        display="flex"
        justifyContent={subLabel ? 'space-around' : 'center'}
        alignItems={subLabel ? 'center' : 'flex-start'}
        onClick={handleClick}
      >
        <ECBox
          width="24px"
          height="24px"
          borderRadius="12px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme =>
            isSelected
              ? theme.palette.primary.main
              : theme.palette.action.disabled
          }
        >
          <ECTypography
            variant="caption"
            color={theme => theme.palette.common.white}
          >
            {name}
          </ECTypography>
        </ECBox>
        {subLabel && (
          <ECBox marginLeft="5px">
            <ECTypography variant="caption" whiteSpace="nowrap">
              {subLabel}
            </ECTypography>
          </ECBox>
        )}
      </ECBox>
    </>
  );
};
