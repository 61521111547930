// Need to use the React-specific entry point to import createApi
import { Locations } from '../types';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['LocationType'],
});

// Define a service using a base URL and expected endpoints
export const locationTypeApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getLocationType: builder.query<BaseType<Locations[]>, void>({
      query: () => {
        return {
          url: 'branch/category',
        };
      },
      providesTags: ['LocationType'],
    }),
    addLocationType: builder.mutation({
      query: body => {
        return {
          url: 'branch/category',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['LocationType'],
    }),
    editLocationType: builder.mutation({
      query: body => {
        const reduced = {
          name: body.name,
          status: body.status,
        };
        return {
          url: `branch/category/${body.id}`,
          method: 'put',
          body: reduced,
        };
      },
      invalidatesTags: ['LocationType'],
    }),
    deleteLocationType: builder.mutation({
      query: body => {
        const reduced = {
          locationCategoryId: body.locationCategoryId,
          name: body.name,
          status: body.status,
        };
        return {
          url: 'branch/category',
          method: 'delete',
          body: reduced,
        };
      },
      invalidatesTags: ['LocationType'],
    }),
  }),
});

export const {
  useGetLocationTypeQuery,
  useLazyGetLocationTypeQuery,
  useAddLocationTypeMutation,
  useEditLocationTypeMutation,
  useDeleteLocationTypeMutation,
} = locationTypeApi;
