import React, { useEffect, useMemo, useState } from 'react';
import { ECFancyFormEdit, TreeNode } from 'app/components';
import {
  useDeleteOrganizationNodeMutation,
  useGetOrganizationTypeListQuery,
  useUpdateOrganizationNodeMutation,
} from 'services/organizationApi';
import { OrganizationUpdate } from 'types/Organization';

const fancyFormElements = require('./fancy_form_config_edit.json');
const formDetailsConfig = require('./fancy_form_config_details.json');

interface EditProps {
  node: TreeNode;
  parentNode: TreeNode;
  onDelete?: () => void;
  onUpdate?: () => void;
  onClose?: () => void;
}

export function Edit({
  node,
  parentNode,
  onDelete,
  onUpdate,
  onClose,
}: EditProps) {
  const [formFields, setFormFields] = useState(fancyFormElements.data.fields);
  const [hasChildren, setHasChildren] = useState(false);

  const { data: orgTypes } = useGetOrganizationTypeListQuery();

  const row = useMemo(
    () => ({
      ...node,
      parentLabel: parentNode?.label,
      organizationTypeName: '',
    }),
    [node, parentNode],
  );

  useEffect(() => {
    const newFormFields = [...fancyFormElements.data.fields];

    let parentNodeSelectField = newFormFields.find(
      field => field.fieldName === 'parentLabel',
    );
    parentNodeSelectField.value = parentNode?.label;
    parentNodeSelectField.options = [parentNode?.label];
    parentNodeSelectField.optionValues = [parentNode?.label];

    let nameTextField = newFormFields.find(
      field => field.fieldName === 'label',
    );
    nameTextField.value = node.label;

    if (orgTypes?.length) {
      let orgTypesField = newFormFields.find(
        field => field.fieldName === 'orgTypes',
      );
      orgTypesField.value = node.organizationType;
      orgTypesField.options = orgTypes?.map(type => type.name);
      orgTypesField.optionValues = orgTypes?.map(type => type.id);
      row.organizationTypeName =
        orgTypes?.find(type => type.id === row.organizationType)?.name || '';
    }

    setFormFields(newFormFields);
  }, [node.label, parentNode?.label, orgTypes]);

  const [
    doDelete,
    {
      data: deleteData,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteOrganizationNodeMutation();

  const useDeleteMutation = () => {
    const doDeleteOrg = async data => {
      setHasChildren(data.children.length > 0);

      if (!(data.children.length > 0)) {
        await doDelete({ id: data.nodeId });
      }
    };

    return [
      doDeleteOrg,
      {
        data: deleteData,
        isError: isDeleteError || hasChildren,
        error: deleteError || {
          data: {
            message:
              'This Organization item has some branches/users connect to it, it can not be removed from the Orginization',
          },
        },
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ];
  };

  const [
    doUpdate,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateOrganizationNodeMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      onUpdate?.();
    }
  }, [isUpdateSuccess, onUpdate]);

  useEffect(() => {
    if (isDeleteSuccess) {
      onDelete?.();
    }
  }, [isDeleteSuccess, onDelete]);

  const useUpdateMutation = () => {
    const doUpdateOrg = async updateData => {
      const orgData: OrganizationUpdate = {
        id: updateData.nodeId,
        name: updateData.label,
        parentId: parentNode?.nodeId,
        organizationType: updateData.orgTypes?.id,
      };
      await doUpdate(orgData);
    };

    return [
      doUpdateOrg,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
      },
    ];
  };
  return (
    <ECFancyFormEdit
      row={row}
      config={fancyFormElements.data.config}
      fields={formFields}
      detailsConfig={formDetailsConfig.data.config}
      detailsFields={formDetailsConfig.data.fields}
      useUpdateMutation={useUpdateMutation}
      useDeleteMutation={useDeleteMutation}
      onClose={onClose}
    />
  );
}
