import React, { useState } from 'react';
import { ReactElement } from 'react';
import {
  Announcement,
  ArrowBackIosNew,
  ArrowDropDown,
  StickyNote2,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECGrid } from '../ECGrid';
import { ECImage } from '../ECImage';
import { ECWorkflowActionButton } from '../ECWorkflowActionButton';
import {
  ECWorkflowSummaryField,
  SummaryFieldsData,
} from '../ECWorkflowSummaryField';
import {
  ECWorkflowWarrantyBadge,
  WorkflowWarranty,
} from '../ECWorkflowWarrantyBadge';
import { ECWarrantyBadge, WarrantyBadgeTypes } from '../ECWarrantyBadge';
import { Attachment } from 'types/Attachment';
import { ECAttachmentDetailsModal } from '../ECDynamicPageTemplate/components/ECAttachmentDetailsModal';

interface ECWorkflowSummaryProps {
  warranty?: WorkflowWarranty;
  summaryFieldsData: SummaryFieldsData[];
  imgSrc?: string;
  profileAttachment?: Attachment;
  actions?: WorkflowNextStatus[];
  onActionClick?: (data: any) => Promise<void>;
  onShowWorkflowGraph?: () => void;
  onViewApprovalHistory?: () => void;
  onAddCommentClick?: () => void;
  onLeaveFeedbackClick?: () => void;
  additionalActions?: (ReactElement | null)[];
  ignoredActionNames?: string[];
  workflowStatus?: string;
  showLogoOnError?: boolean;
  isEditMode?: boolean;
  disableAditionalActionWhenEditing?: boolean;
  warrantyBadgeType?: WarrantyBadgeTypes;
  showLogo?: boolean;
  showAdditionalButtons?: boolean;
  isLoadingActions?: boolean;
  showWarrantyAndLogo?: boolean;
  customSummaryComponents?: ReactElement;
  moduleName?: string;
  moduleId?: string;
  fieldsOptionalSx?: any;
}

export const ECWorkflowSummary = ({
  summaryFieldsData,
  warranty,
  imgSrc,
  profileAttachment,
  additionalActions,
  actions,
  workflowStatus,
  onActionClick,
  onShowWorkflowGraph = undefined,
  onAddCommentClick,
  onLeaveFeedbackClick,
  showLogoOnError,
  isEditMode,
  ignoredActionNames,
  showLogo = true,
  disableAditionalActionWhenEditing,
  warrantyBadgeType,
  showAdditionalButtons = true,
  isLoadingActions,
  showWarrantyAndLogo = true,
  customSummaryComponents,
  moduleName,
  moduleId,
  fieldsOptionalSx,
}: ECWorkflowSummaryProps) => {
  const { t } = useTranslation();
  const [showMoreButtons, setShowMoreButtons] = useState(false);

  const toggleShowMore = () => {
    setShowMoreButtons(!showMoreButtons);
  };

  const [isAttachmentViewOpen, setIsAttachmentViewOpen] = useState(false);
  const handleCloseAttachmentModal = () => {
    setIsAttachmentViewOpen(false);
  };

  return (
    <ECGrid
      container
      overflow="hidden"
      p={2}
      display="flex"
      flexWrap={'nowrap'}
    >
      <ECGrid
        item
        container
        xs={customSummaryComponents ? 6 : 7}
        xl={customSummaryComponents ? 6 : showWarrantyAndLogo ? 9 : 12}
        alignItems="center"
        sx={fieldsOptionalSx}
      >
        {summaryFieldsData?.map((field, index) => (
          <ECWorkflowSummaryField
            key={`${field.label}-${index}`}
            field={field}
            isEditMode={isEditMode}
          />
        ))}
        <ECGrid item container gap={1}>
          {actions && (
            <ECWorkflowActionButton
              variant="button"
              actions={actions}
              onActionClick={onActionClick}
              ignoredActionNames={ignoredActionNames}
              staticStatus={workflowStatus}
              disabled={disableAditionalActionWhenEditing && isEditMode}
              isLoading={isLoadingActions}
            />
          )}
          {onShowWorkflowGraph && (
            <ECButton onClick={onShowWorkflowGraph}>
              {t('translation:workflow.summary.showWorkflowGraph')}
            </ECButton>
          )}
          <ECBox display="flex">
            {additionalActions?.map(
              (additionalAction, index) =>
                additionalAction &&
                React.cloneElement(additionalAction, {
                  disabled: disableAditionalActionWhenEditing
                    ? isEditMode
                    : additionalAction.props.disabled,
                  key: `${additionalAction?.props.label}-${index}`,
                }),
            )}
          </ECBox>
          {onAddCommentClick && (
            <ECButton
              variant="outlined"
              onClick={onAddCommentClick}
              startIcon={<StickyNote2 />}
              disabled={disableAditionalActionWhenEditing && isEditMode}
            >
              {t('translation:workflow.summary.addComment')}
            </ECButton>
          )}
          {onLeaveFeedbackClick && (
            <ECButton
              variant="outlined"
              onClick={onLeaveFeedbackClick}
              startIcon={<Announcement />}
              disabled={disableAditionalActionWhenEditing && isEditMode}
            >
              {t('translation:workflow.summary.leaveFeedback')}
            </ECButton>
          )}
        </ECGrid>
      </ECGrid>
      {showWarrantyAndLogo && (
        <ECGrid item xs>
          <ECBox display="flex" justifyContent="flex-end">
            {warranty && (
              <ECBox display="flex" justifyContent="center" maxWidth="100%">
                <ECWorkflowWarrantyBadge warranty={warranty} />
              </ECBox>
            )}
            {warrantyBadgeType && (
              <ECBox justifyContent="center" maxWidth="100%" mt={1} mr={2}>
                <ECWarrantyBadge warrantyBadgeType={warrantyBadgeType} />
              </ECBox>
            )}
            {customSummaryComponents && customSummaryComponents}
            {showLogo &&
              (profileAttachment ? (
                <ECBox display="flex" width="328px" maxHeight="328px">
                  <ECBox
                    sx={{
                      position: 'relative',
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                      display: 'inline-block',
                      cursor: 'pointer',
                      '&:hover .image-overlay': {
                        opacity: 1,
                      },
                      '&:hover .image': {
                        filter: 'grayscale(100%)',
                      },
                    }}
                    onClick={() => setIsAttachmentViewOpen(true)}
                  >
                    <ECImage
                      showLogoOnError={showLogoOnError}
                      src={imgSrc}
                      className="image"
                      sx={{
                        opacity: isEditMode ? 0.3 : 1,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        paddingLeft: '24px',
                        borderRadius: showLogoOnError ? '50%' : '0%',
                        transition: 'filter 0.3s, opacity 0.3s',
                      }}
                    />
                    <ECBox
                      className="image-overlay"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 24,
                        width: '90%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        pointerEvents: 'none',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        boxSizing: 'border-box',
                      }}
                    >
                      VIEW
                    </ECBox>
                  </ECBox>
                </ECBox>
              ) : (
                <ECBox display="flex" width="328px" maxHeight="328px">
                  <ECImage
                    showLogoOnError={showLogoOnError}
                    src={imgSrc}
                    sx={{
                      opacity: isEditMode ? 0.3 : 1,
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                      paddingLeft: '24px',
                      borderRadius: showLogoOnError ? '50%' : '0%',
                    }}
                  />
                </ECBox>
              ))}
          </ECBox>
        </ECGrid>
      )}
      {profileAttachment && moduleName && moduleId && (
        <ECAttachmentDetailsModal
          moduleName={moduleName}
          moduleId={moduleId}
          attachment={profileAttachment}
          isOpen={isAttachmentViewOpen}
          onCloseModal={handleCloseAttachmentModal}
        />
      )}
    </ECGrid>
  );
};
