import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECButton } from '../ECButton';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { P } from 'types/Permission';

interface ECButtonWithViewProps {
  title: string;
  onViewClick?: () => void;
  scopes?: P[];
}

const ECButtonWithView = ({
  title = 'Title',
  onViewClick,
  scopes,
}: ECButtonWithViewProps) => {
  return (
    <ECBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width="13rem"
      paddingX="1rem"
      paddingY="1rem"
      alignSelf="flex-end"
      bgcolor={theme => theme.palette.action.hover}
      boxShadow={1}
      borderRadius={1}
    >
      <ECTypography
        variant="h6"
        textAlign="center"
        lineHeight={1.6}
        color="textPrimary"
        mb={3}
        px={2}
      >
        {title}
      </ECTypography>
      <ECButton
        style={{ width: '100%', flex: 1 }}
        variant="text"
        onClick={() => {
          onViewClick?.();
        }}
      >
        View
      </ECButton>
    </ECBox>
  );
};

export const ECButtonWithViewText =
  requirePermissionWrapper<ECButtonWithViewProps>(ECButtonWithView);
