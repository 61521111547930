import { useCallback, useEffect, useState } from 'react';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useGetProblemListByAssetIdQuery } from 'services/problemApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { useLazyGetAssetTypesProblemTroubleshootsByIdQuery } from 'services/assetTypeApi';
import { AssetTypeProblemTroubleShoot } from 'types/AssetType';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import _ from 'lodash';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
  handleChangeTroubleshoots: (
    troubleshootInformation?: AssetTypeProblemTroubleShoot[],
  ) => void;
  isGeneralAsset?: boolean;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({
  existingData,
  handleChangeTroubleshoots,
  onSubmit,
  onClose,
  isGeneralAsset,
}: Step2Props) => {
  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields,
  );

  const { data: userProfile } = useGetUserProfileQuery();

  const { data: problems, isSuccess: isSuccessProblems } =
    useGetProblemListByAssetIdQuery({
      id: existingData.asset.id,
      isGeneralAsset,
    });

  const [
    triggerAssetTypesProblemTroubleshootsById,
    assetTypesResultProblemTroubleshootsById,
  ] = useLazyGetAssetTypesProblemTroubleshootsByIdQuery();
  const {
    data: assetTypesProblemTroubleshootsById,
    isLoading: isLoadingAssetTypesProblemTroubleshootsById,
    isSuccess: isSuccessAssetTypesProblemTroubleshootsById,
  } = assetTypesResultProblemTroubleshootsById;

  useEffect(() => {
    if (
      isSuccessAssetTypesProblemTroubleshootsById &&
      assetTypesProblemTroubleshootsById
    ) {
      const tradesTroubleshoots = assetTypesProblemTroubleshootsById?.filter(
        apt => apt.troubleshoots?.length,
      );
      const tradesTroubleshootsUniq = tradesTroubleshoots?.map(
        tradeTroubleshoots => ({
          ...tradeTroubleshoots,
          troubleshoots: _.uniqBy(
            tradeTroubleshoots?.troubleshoots?.filter(
              item => item?.status !== 0,
            ),
            'id',
          ),
        }),
      );
      handleChangeTroubleshoots?.(tradesTroubleshootsUniq);
    }
  }, [
    assetTypesProblemTroubleshootsById,
    isSuccessAssetTypesProblemTroubleshootsById,
    handleChangeTroubleshoots,
  ]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const problem = problems?.find(
        problem => problem.name === data['problem.name'],
      );

      onSubmit?.({
        problem,
      });
    };

    return [doSubmit, {}];
  }, [problems, onSubmit]);

  useEffect(() => {
    if (isSuccessProblems) {
      const [updatedFormFields, _] = populateDropDownFields({
        responseData: problems,
        createFormFields: formFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problem.name',
      });

      setFormFields(updatedFormFields);
    }
  }, [problems, isSuccessProblems]);

  const handleOutputChange = (output: ECEasyFormFieldType[]) => {
    const problemFormFieldConfig = formFields.find(
      field => field.fieldName === 'problem.name',
    );
    const prevSelectedProblemName = problemFormFieldConfig?.value;
    const problemFormFieldOutput = output.find(
      field => field.fieldName === 'problem.name',
    );
    const newSelectedProblemName = problemFormFieldOutput?.value;

    if (newSelectedProblemName !== prevSelectedProblemName) {
      const selectedProblemIndex = problemFormFieldConfig?.options?.findIndex(
        option => option === newSelectedProblemName,
      );
      const problemId =
        problemFormFieldConfig.optionValues[selectedProblemIndex];

      if (
        userProfile?.roles?.some(
          role => role.code?.toLowerCase() === 'troubleshooting',
        )
      ) {
        triggerAssetTypesProblemTroubleshootsById({
          assetId:
            existingData?.asset?.assetTypeId ??
            existingData?.asset?.assetType?.id,
          problemId,
        });
      }
    }
  };

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      existingData={existingData}
      onChange={handleOutputChange}
      showSaveButton={!isLoadingAssetTypesProblemTroubleshootsById}
      onClose={onClose}
    />
  );
};
