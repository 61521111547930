import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import {
  useAddTradeTypeMutation,
  useEditTradeTypeMutation,
  useGetCoreTradeTypesQuery,
  useLazyGetTradesListQuery,
} from 'services/tradeApi';
import { loadPageScopes } from 'utils/pageScopes';
import { populateDropDownFields } from 'utils/pageUtils';

import { useEffect } from 'react';
import { setFilterEndpoint } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { ActivityType } from 'types/Activity';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementDetails = require('./fancy_form_config_details.json');

export function TradePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('trade');
  }, []);

  const { data: dataCore, isSuccess: isSuccessCore } =
    useGetCoreTradeTypesQuery();

  if (isSuccessCore && dataCore) {
    populateDropDownFields({
      responseData: dataCore,
      createFormFields: fancyFormElementsCreate.fields,
      editFormFields: fancyFormElementsEdit.fields,
      dataOptionsFieldName: 'name',
      dataValuesFieldName: 'id',
      formFieldName: 'coreTrade.name',
    });
  }

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/trade'));
  }, [dispatch]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.trades.title')}
      useLazyGetListQuery={useLazyGetTradesListQuery}
      showStatusActiveFilter={true}
      useCreateMutation={useAddTradeTypeMutation}
      useUpdateMutation={useEditTradeTypeMutation}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementDetails.config}
      detailsFields={fancyFormElementDetails.fields}
      hasDetails
      downloadFileName="trades.csv"
      enableExport
      showOnlyHistoryTab
      marginTop={false}
      shouldNotUseActiveFilter
      showActivityButton
      activityType={ActivityType.Trade}
      showDrawerDetailTitle={false}
    />
  );
}
