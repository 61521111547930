import { ECDynamicPageTemplate, ECLink } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useLazyGetAllSupplierQuery } from 'services/supplierApi';
import LanguageIcon from '@mui/icons-material/Language';
import { NewSupplier } from './NewSupplier';

export const Suppliers = ({ marginTop = true }) => {
  const { t } = useTranslation();

  const renderTableCustomLink = (url: string) => {
    if (!url) return null;

    const validatedUrl =
      url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `http://${url}`;
    return (
      <ECLink
        href={validatedUrl}
        target="_blank"
        rel="noopener"
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
        }}
      >
        <LanguageIcon style={{ marginRight: '8px' }} />
        {t('translation:pages.purchaseOrders.visitWebsite')}
      </ECLink>
    );
  };

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.purchaseOrders.suppliers')}
        useLazyGetListQuery={useLazyGetAllSupplierQuery}
        useCreateMutation={undefined}
        useUpdateMutation={undefined}
        editFormConfig={[] as any}
        editFormFields={[] as any}
        createFormConfig={undefined as any}
        createFormFields={undefined as any}
        detailsConfig={[] as any}
        detailsFields={[] as any}
        onRowClick={null as any}
        onDrawerClose={null as any}
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        customContent={{
          header: <NewSupplier />,
        }}
        showOnlyDetailsTab
        showStatusActiveFilter
        statusFilterInitialSelected
        shouldCacheLazyQuery
        withDrawer={false}
        showColumnVisibilityFilter
        enableExport
        enableTableCsvExport
        renderTableCustomLink={renderTableCustomLink}
        moduleName="supplier"
      />
    </>
  );
};
