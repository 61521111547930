import { ProblemToAsset, ProblemToAssetParams } from 'types/ProblemToAsset';

import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';
import { ProblemTypes } from 'types';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['AssetTypeToProblem', 'ProblemsByAssetTypeId'],
});

export const assetTypeToProblemApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getProblemToAssetList: builder.query<
      BaseType<ProblemToAsset[]>,
      ProblemToAssetParams
    >({
      query: params => {
        return {
          url: `asset-group/problem`,
          params: params,
        };
      },
      transformResponse: (response: BaseType<ProblemToAsset[]>) => {
        const data = response.data.map(row => ({
          ...row,
          id: row.assetTypeId || 0,
          name: row.assetTypeName || '',
        }));

        return {
          ...response,
          data,
        };
      },
      providesTags: ['AssetTypeToProblem'],
    }),
    getProblemsByAssetTypeId: builder.query<
      ProblemTypes[],
      { id: number; noTroubleshoot?: boolean }
    >({
      query: ({ id, noTroubleshoot }) => {
        return {
          url: `asset-group/${id}/problems`,
          params: { noTroubleshoot },
        };
      },
      providesTags: ['ProblemsByAssetTypeId'],
    }),
    addProblemToAssetType: builder.mutation({
      query: body => {
        const reduced = {
          name: body.name,
          problems: body.problems,
        };
        return {
          url: `asset-group/${body.assetTypeId}/problem`,
          method: 'post',
          body: reduced,
        };
      },
      invalidatesTags: ['AssetTypeToProblem'],
    }),
    editProblemType: builder.mutation({
      query: body => {
        const problemsList = body.problems.map(val => val.problemId);
        return {
          url: `asset-group/${body.assetTypeId}/problem`,
          method: 'delete',
          body: problemsList,
        };
      },
      invalidatesTags: ['AssetTypeToProblem'],
    }),
    deleteProblemToAssetType: builder.mutation({
      query: body => {
        return {
          url: `asset-group/${body.assetTypeId}/problem`,
          method: 'delete',
          body,
        };
      },
      invalidatesTags: ['AssetTypeToProblem'],
    }),
  }),
});

export const {
  useGetProblemToAssetListQuery,
  useLazyGetProblemToAssetListQuery,
  useAddProblemToAssetTypeMutation,
  useEditProblemTypeMutation,
  useDeleteProblemToAssetTypeMutation,

  useGetProblemsByAssetTypeIdQuery,
  useLazyGetProblemsByAssetTypeIdQuery,
} = assetTypeToProblemApi;
