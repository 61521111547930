function configs(labels, datasets) {
  // const backgroundColors: string[] = [];
  //
  // if (datasets.backgroundColors) {
  //   datasets.backgroundColors.forEach(color =>
  //     gradients[color]
  //       ? backgroundColors.push(gradients[color].state)
  //       : backgroundColors.push(dark.main),
  //   );
  // } else {
  //   backgroundColors.push(dark.main);
  // }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: datasets.backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index' as const,
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
