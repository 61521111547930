import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import {
  ECMainTabBox,
  ECGrid,
  ECEasyForm,
  ECPanelBreadcrumbs,
} from 'app/components';
import { ECTabContainer } from '../../components/ECTabs';

const fancyMockFormData = require('./mock_form_config_fancy.json');
const fancyMockFormDataSingleCol = require('./mock_form_config_fancy_single_col.json');

const simpleMockFormData = require('./mock_form_config_simple.json');
const simpleMockFormDataSingleCol = require('./mock_form_config_simple_single_col.json');

const noContainerMockFormData = require('./mock_form_config_no_container.json');

function FirstForms() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={6}>
        <ECEasyForm
          config={fancyMockFormDataSingleCol.data.config}
          fields={fancyMockFormDataSingleCol.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
      <ECGrid item xs={6}>
        <ECEasyForm
          config={fancyMockFormDataSingleCol.data.config}
          fields={fancyMockFormDataSingleCol.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
      <ECGrid item xs={12}>
        <ECEasyForm
          config={fancyMockFormData.data.config}
          fields={fancyMockFormData.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

function SecondForms() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={6}>
        <ECEasyForm
          config={simpleMockFormDataSingleCol.data.config}
          fields={simpleMockFormDataSingleCol.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
      <ECGrid item xs={6}>
        <ECEasyForm
          config={simpleMockFormDataSingleCol.data.config}
          fields={simpleMockFormDataSingleCol.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
      <ECGrid item xs={12}>
        <ECEasyForm
          config={simpleMockFormData.data.config}
          fields={simpleMockFormData.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

function NoContainerForms() {
  return (
    <ECGrid container spacing={2}>
      <ECGrid item xs={12}>
        <ECEasyForm
          config={noContainerMockFormData.data.config}
          fields={noContainerMockFormData.data.fields}
          isSendingData={false}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            fields.forEach(item => {
              if (item.fieldName) {
                console.log(item.fieldName, formData.get(item.fieldName));
              }
            });
          }}
        />
      </ECGrid>
    </ECGrid>
  );
}

export function FormsPage() {
  return (
    <>
      <Helmet>
        <title>Forms Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainTabBox component={'main'}>
        <ECGrid container sx={{ padding: 2.3 }}>
          <ECGrid item xs={12}>
            <ECPanelBreadcrumbs />
          </ECGrid>
          <ECGrid item xs={12}>
            <ECTabContainer
              tabs={[
                { value: '7', label: 'Fancy Forms', content: <FirstForms /> },
                { value: '8', label: 'Simple Forms', content: <SecondForms /> },
                {
                  value: '9',
                  label: 'Flat Forms',
                  content: <NoContainerForms />,
                },
              ]}
            />
          </ECGrid>
        </ECGrid>
      </ECMainTabBox>
    </>
  );
}
