import { ECTabContainer } from 'app/components';
import { useServiceProviderUser } from 'app/hooks';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { ForbiddenPage } from '../ForbiddenPage';
import { SPCustomersManagementPage } from '../SPCustomersManagementPage';
import { WIPPage } from '../WIPPage';
import { Outlet } from 'react-router';
import { P } from 'types/Permission';
import { SPCustomersLocationsPage } from '../SPCustomersLocationsPage';

export function CustomersPage() {
  const { t } = useTranslation();
  const isServiceProvider = useServiceProviderUser();

  if (!isServiceProvider) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.customers.title')} - %s`}
        defaultTitle={t('translation:pages.customers.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'customer-management',
            label: t('translation:panelDrawer.customerManagement'),
            content: <SPCustomersManagementPage />,
            link: 'customer-management',
            scopes: [P.GetAllCustomers, P.SPMenu],
          },
          {
            value: 'pending',
            label: t('translation:panelDrawer.pending'),
            content: <Outlet />,
            link: 'pending',
          },
          {
            value: 'locations',
            label: t('translation:pages.customersLocations.title'),
            content: <SPCustomersLocationsPage />,
            link: 'locations',
          },
          /* removed dev-13527 {
            value: 'managed-assets',
            label: t('translation:panelDrawer.managedAssets'),
            content: <WIPPage />,
            link: 'managed-assets',
          },
          {
            value: 'general-assets',
            label: t('translation:panelDrawer.generalAssets'),
            content: <WIPPage />,
            link: 'general-assets',
          },
          {
            value: 'preventive-maintenance',
            label: t('translation:panelDrawer.preventiveMaintenance'),
            content: <WIPPage />,
            link: 'preventive-maintenance',
          }, */
        ]}
      />
    </>
  );
}
