import {
  useGetAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} from 'services/assetTypeApi';
import {
  useCreateAssetMutation,
  useGetAssetConditionListQuery,
  useLazyGetAssetByIdQuery,
} from 'services/assetApi';
import { useGetLocationsQuery } from 'services/locationsApi';
import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
  FieldTypes,
  MAX_SELECTABLE_OPTIONS,
} from 'app/components';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { Add } from '@mui/icons-material';
import { useCreateServiceRequestContext } from 'store/contexts';
import { useGetAreaListQuery } from 'services/areaApi';
import { useLazyGetDesignatedSPsQuery } from 'services/serviceProviderApi';
import { useCustomerUser } from 'app/hooks';
import { useCustomGetAssetList } from './customApiCall';
import moment from 'moment';
import * as _ from 'lodash';
import { useGetBranchesListQuery } from 'services/branchApi';
import { useLazyGetAssetTypesToNameByTypeIdBrachTypeIdQuery } from 'services/assetTypeToNameApi';
import { AssetTypeToNameResponse } from 'types/AssetTypeToName';
import { StatusBase } from 'types/Lookup';
import { useGetManufactureListQuery } from 'services/manufactureApi';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { populateAutoCompleteFields } from 'utils/pageUtils';
import { themes } from 'styles/theme/themes';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

export function ManagedAssetsPage({ marginTop = true }) {
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatchRedux = useDispatch();
  useEffect(() => {
    loadPageScopes('asset');
    dispatchRedux(setFilterEndpoint('/filter/asset'));
  }, []);

  const isCustomer = useCustomerUser();
  const [_forceRefresh, setForceRefresh] = useState(new Date());
  const [initialFormFields, setInitialFormFields] = useState(
    fancyFormElementsCreate.fields,
  );

  const [formElementsCreate, setFormElementsCreate] = useState(
    fancyFormElementsCreate,
  );

  const [branchId, setBranchId] = useState(0);
  const [branchTypeId, setBranchTypeId] = useState(0);
  const [assetTypeId, setAssetTypeId] = useState(0);

  const { data: assetTypes, isLoading: isLoadingAssetTypes } =
    useGetAssetTypesListQuery({
      t: 0,
    });

  const [trigger, result] = useLazyGetDesignatedSPsQuery();

  const { data: designatedSPs, isSuccess: isSuccessDesignatedSPs } = result;

  const [triggerAssetNames, assetNamesResult, lastPromiseInfo] =
    useLazyGetAssetTypesToNameByTypeIdBrachTypeIdQuery();

  const {
    data: assetTypesToList,
    isSuccess: isSuccessAssetTypesToList,
    isFetching: isFetchingAssetTypesToList,
  } = assetNamesResult;

  useEffect(() => {
    if (branchId && assetTypeId && isDrawerOpen) {
      trigger({
        branchId,
        assetTypeId,
      });
    }
  }, [trigger, branchId, assetTypeId]);

  useEffect(() => {
    if (designatedSPs && isSuccessDesignatedSPs) {
      populateAutoCompleteFields({
        responseData: designatedSPs,
        createFormFields: formElementsCreate?.fields,
        dataGroupFieldName: 'group',
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'spWarrantyCompany',
      });
      setForceRefresh(new Date());
    }
  }, [designatedSPs, isSuccessDesignatedSPs]);

  useEffect(() => {
    const manufactureField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'manufacture',
    );
    manufactureField.useQuery = isDrawerOpen ? useGetManufactureListQuery : {};
  }, [isDrawerOpen]);

  useEffect(() => {
    const createFormFields = fancyFormElementsCreate.fields;
    let assetConditionField = createFormFields.find(
      field => field.fieldName === 'condition',
    );
    assetConditionField.useQuery = isDrawerOpen
      ? useGetAssetConditionListQuery
      : {};
  }, [isDrawerOpen]);

  useEffect(() => {
    const branchField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'branch',
    );
    branchField.useQuery = isDrawerOpen ? useGetLocationsQuery : {};
    branchField.obAlias = 'brnch.name';
    branchField.queryParams = {
      sb1: 'brnch.status',
      s1: 1,
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const assetTypeField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'assetType',
    );
    assetTypeField.useQuery = isDrawerOpen ? useGetAssetTypesListQuery : {};
    assetTypeField.obAlias = 'assttp.name';
  }, [isDrawerOpen]);

  useEffect(() => {
    const createFormFields = fancyFormElementsCreate.fields;
    let priorityField = createFormFields.find(
      field => field.fieldName === 'priority',
    );
    priorityField.useQuery = isDrawerOpen ? useGetPriorityListQuery : {};
    priorityField.obAlias = 'prrt.name';
  }, [isDrawerOpen]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    { skip: !isDrawerOpen },
  );
  useEffect(() => {
    if (isSuccessFlag) {
      let createSelectField = fancyFormElementsCreate.fields.find(
        field => field.fieldName === 'flags',
      );
      createSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      createSelectField.optionValues = flagsData?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlag, flagsData]);

  useEffect(() => {
    const areaField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'area',
    );
    areaField.useQuery = isDrawerOpen ? useGetAreaListQuery : {};
    areaField.obAlias = 'area.name';
  }, [isDrawerOpen]);

  useEffect(() => {
    const warrantyStartDateField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    );
    warrantyStartDateField.options = [
      {
        fieldName: 'installationDate',
        label: 'Installation Date',
        value: undefined,
      },
      {
        fieldName: 'purchaseDate',
        label: 'Manufacturer Date',
        value: undefined,
      },
      {
        fieldName: 'storeOpenDate',
        label: 'Store Open Date',
        value: undefined,
        required: true,
      },
    ];
  }, []);

  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;

  // if AREA is not enabled, make area not visible in CREATE FORM
  useEffect(() => {
    const areaField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'area',
    );
    areaField.visible = areaEnabled;
  }, [areaEnabled]);

  const { data: eulPeriodData, isSuccess: isEulPeriodSuccess } =
    useGetPeriodEULListQuery();
  useEffect(() => {
    if (isEulPeriodSuccess && eulPeriodData) {
      const periodValues = eulPeriodData?.map(period => ({
        label: period.description,
        id: period.code,
      }));
      const eulGroupFieldCreate = fancyFormElementsCreate.fields.find(
        field => field.type === FieldTypes.Group,
      );
      if (eulGroupFieldCreate) {
        const periodFieldCreate = eulGroupFieldCreate.subFields?.find(
          subField => subField.fieldName === 'eulPeriodCode',
        );
        periodFieldCreate.options = periodValues;
      }
    }
  }, [eulPeriodData, isEulPeriodSuccess]);

  const { dispatch } = useCreateServiceRequestContext();

  const handleCreateServiceRequestClick = (row: any) => () => {
    dispatch({ type: 'open', payload: { asset: row } });
  };

  const renderCreateServiceRequestActionButton = (row: any) => {
    return row?.status === StatusBase.Active ? (
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <ECButton
          variant="outlined"
          startIcon={<Add />}
          onClick={handleCreateServiceRequestClick(row)}
          key={`${row.id}-create-service-request`}
        >
          {t('translation:navBar.createServiceRequest')}
        </ECButton>
      </ECBox>
    ) : null;
  };

  const handleChange = (output: ECEasyFormFieldType[]) => {
    const warrantyStartDate = (
      output.find(field => field.fieldName === 'warrantyStartDateComponent')
        ?.value as any
    )?.value;
    const eulGroup = output.find(field => field.type === FieldTypes.Group);
    const eul = eulGroup?.subFields?.find(
      field => field.fieldName === 'estimatedUsefulLife',
    )?.value;
    const eulPeriod = eulGroup?.subFields?.find(
      field => field.fieldName === 'eulPeriodCode',
    )?.value;

    if (warrantyStartDate && eul && eulPeriod) {
      const warrantyEndDate = moment(warrantyStartDate);

      if (eulPeriod === 'Y') {
        warrantyEndDate.add(eul as number, 'years');
      } else if (eulPeriod === 'M') {
        warrantyEndDate.add(eul as number, 'months');
      }

      const estimatedReplacementDateField = output.find(
        field => field.fieldName === 'estimatedReplacementDate',
      );

      if (estimatedReplacementDateField) {
        estimatedReplacementDateField.value = warrantyEndDate?.toDate();
        estimatedReplacementDateField.overrideRowValue = true;
      }
    }

    const branch = output.find(field => field.fieldName === 'branch')
      ?.value as any;
    const branchId = branch?.id;

    const assetType = output.find(field => field.fieldName === 'assetType')
      ?.value as any;

    const storeOpenDate = branch?.openDate;

    const warrantyStartDateField = output.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    );
    const installationDate = output.find(
      field => field.fieldName === 'installationDate',
    )?.value;
    const manufactureDate = output.find(
      field => field.fieldName === 'purchaseDate',
    )?.value;
    if (warrantyStartDateField?.options?.[2]) {
      warrantyStartDateField.options = [...warrantyStartDateField.options];
      warrantyStartDateField.options[0] = {
        ...warrantyStartDateField.options[0],
        value: installationDate,
      };
      warrantyStartDateField.options[1] = {
        ...warrantyStartDateField.options[1],
        value: manufactureDate,
      };
      warrantyStartDateField.options[2] = {
        ...warrantyStartDateField.options[2],
        readOnly: true,
        value: storeOpenDate,
      };
    }

    fancyFormElementsCreate.fields = output;
    setAssetTypeId(assetType?.id);
    setBranchId(branchId);
    setBranchTypeId(branch?.branchTypeId);
  };

  const assetTypeFilterOptions = useMemo(() => {
    return (
      _.orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  useEffect(() => {
    if (assetTypeId && branchTypeId && isDrawerOpen) {
      triggerAssetNames({
        branchTypeId,
        assetTypeId,
      });
    }
  }, [assetTypeId, branchTypeId]);

  useEffect(() => {
    if (
      assetTypesToList &&
      isSuccessAssetTypesToList &&
      !isFetchingAssetTypesToList
    ) {
      const assetList =
        assetTypesToList as unknown as AssetTypeToNameResponse[];

      const assetNameField = fancyFormElementsCreate.fields.find(
        field => field.fieldName === 'name',
      );

      const assetNameOptions = assetList?.map(template => ({
        id: template.id,
        name: template.name,
      }));

      assetNameField.options = assetNameOptions;
      fancyFormElementsCreate.fields = [...fancyFormElementsCreate.fields];
    }
  }, [
    assetTypesToList,
    assetTypeId,
    branchTypeId,
    isSuccessAssetTypesToList,
    isFetchingAssetTypesToList,
  ]);

  const resetFields = () => {
    fancyFormElementsCreate.fields = initialFormFields;
    setFormElementsCreate(fancyFormElementsCreate);
    setForceRefresh(new Date());
  };

  const onAddNewClick = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const createFormFields = useMemo(
    () => [...formElementsCreate.fields],
    [formElementsCreate],
  );

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:panelDrawer.managedAssets')}
      useLazyGetListQuery={useCustomGetAssetList}
      useLazyGetByIdQuery={useLazyGetAssetByIdQuery}
      useCreateMutation={useCreateAssetMutation}
      renderCustomActionComponent={(row: any) => {
        if (isCustomer && row?.branch?.status === 1) {
          return renderCreateServiceRequestActionButton(row);
        }
      }}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={createFormFields}
      onChange={handleChange}
      withDrawer={false}
      onAddNewClick={onAddNewClick}
      marginTop={marginTop}
      hasDetails={false}
      showStatusActiveFilter
      statusFilterInitialSelected
      showAssetTypeNameFilter
      assetTypeFilterOptions={assetTypeFilterOptions}
      showLocationFilter
      useLocationFilterQuery={useGetBranchesListQuery}
      onDrawerClose={resetFields}
      showColumnVisibilityFilter
      isDraggableColumns
      shouldCacheLazyQuery
      enableExport
      exportModuleName="asset"
      drawerTitleBarBGColor={themes.light.palette.other.divider}
    />
  );
}
