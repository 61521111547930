import { useState, useEffect, useCallback } from 'react';
import { Build, Close, HomeRepairService } from '@mui/icons-material';
import { BoxProps, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetCoreTradeTypesQuery } from 'services/tradeApi';
import {
  ECAutocompleteWhiteBorders,
  ECAutcompleteCity,
  AutocompleteCityResult,
} from '../ECAutocomplete';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECTextField } from '../ECTextField';
import { ECIconButton } from '../ECIconButton';

interface ECSPFormInitialData {
  trade: TradeOption;
  location: AutocompleteCityResult;
  name: string;
}

export interface TradeOption {
  id: number;
  label: string;
}

interface ECSPFormProps extends BoxProps {
  initialData?: ECSPFormInitialData;
  variant?: 'outlined' | 'standard';
  onSearch: (
    trade: TradeOption,
    location: AutocompleteCityResult,
    name: string,
  ) => () => void;
}

export const ECSPForm = ({
  initialData,
  variant = 'outlined',
  onSearch,
  ...boxProps
}: ECSPFormProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    trade: initialData?.trade ?? { id: 0, label: '' },
    location: initialData?.location ?? { full: '' },
    name: initialData?.name ?? '',
  });
  const [validations, setValidations] = useState<
    { field: string; validationMessage: string }[]
  >([]);
  const [tradesOptions, setTradesOptions] = useState<any[]>([]);

  const { data: coreTrades, isSuccess } = useGetCoreTradeTypesQuery();

  useEffect(() => {
    if (coreTrades && isSuccess) {
      setTradesOptions(
        coreTrades.map(trade => ({
          id: trade.id,
          label: trade.name,
        })),
      );
    }
  }, [coreTrades, isSuccess, setTradesOptions]);

  const handleChange = (value: any, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const doSearch = useCallback(
    (trade: TradeOption, location: AutocompleteCityResult, name: string) => {
      const newValidations: any[] = [];
      if (trade?.id && !location?.full) {
        newValidations.push({
          field: 'location',
          validationMessage: 'Location is required!',
        });
      } else if (!trade?.id && location?.full) {
        newValidations.push({
          field: 'trade',
          validationMessage: 'Trade is required!',
        });
      } else if (!trade?.id && !location?.full && !name) {
        newValidations.push({
          field: 'name',
          validationMessage: 'Name is required!',
        });
      }
      setValidations(newValidations);
      if (newValidations.length > 0) return;

      onSearch(trade, location, name)();
    },
    [onSearch],
  );

  const isInitialSearchPage = variant === 'outlined';

  return (
    <ECBox display="flex" flex={1} alignItems="center" {...boxProps}>
      <ECBox display="flex" flex={1}>
        {tradesOptions.length > 0 && (
          <ECAutocompleteWhiteBorders
            fullWidth
            options={tradesOptions?.map(option => option.label)}
            defaultValue={tradesOptions?.find(
              option => option.label === formData?.trade?.label,
            )}
            renderInput={params => (
              <ECTextField
                {...params}
                variant={variant}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Build
                        sx={theme => ({ color: theme.palette.grey[400] })}
                      />
                    </InputAdornment>
                  ),
                }}
                error={validations?.some(v => v.field === 'trade')}
                helperText={
                  validations?.find(v => v.field === 'trade')?.validationMessage
                }
                placeholder={t(
                  'translation:spDirectory.search.tradesPlaceholder',
                )}
                label={t('translation:spDirectory.search.tradesLabel')}
              />
            )}
            onChange={(_event, newValue) => {
              handleChange(
                tradesOptions.find(trade => trade.label === newValue),
                'trade',
              );
            }}
          />
        )}
      </ECBox>

      <ECBox ml={2} display="flex" flex={1}>
        <ECAutcompleteCity
          fullWidth
          variant={variant}
          defaultValue={formData.location}
          placeholder={t('translation:spDirectory.search.locationsPlaceholder')}
          label={t('translation:spDirectory.search.locationsLabel')}
          onChange={value => handleChange(value, 'location')}
          error={validations?.some(v => v.field === 'location')}
          helperText={
            validations?.find(v => v.field === 'location')?.validationMessage
          }
        />
      </ECBox>

      <ECBox ml={2} display="flex" flex={1}>
        <ECTextField
          variant="standard"
          value={formData.name}
          InputProps={{
            disableUnderline: isInitialSearchPage,
            startAdornment: (
              <InputAdornment position="start">
                <HomeRepairService
                  sx={theme => ({ color: theme.palette.grey[400] })}
                />
              </InputAdornment>
            ),
            endAdornment:
              formData.name?.length > 0 ? (
                <InputAdornment position="end">
                  <ECIconButton
                    onClick={() => {
                      handleChange('', 'name');
                      if (!isInitialSearchPage) {
                        doSearch(formData.trade, formData.location, '');
                      }
                    }}
                  >
                    <Close />
                  </ECIconButton>
                </InputAdornment>
              ) : undefined,
          }}
          label={t('translation:spDirectory.search.nameLabel')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              doSearch(formData.trade, formData.location, formData.name);
            }
          }}
          onChange={e => handleChange(e.target.value, 'name')}
          error={validations?.some(v => v.field === 'name')}
          helperText={
            validations?.find(v => v.field === 'name')?.validationMessage
          }
        />
      </ECBox>

      <ECButton
        variant="contained"
        onClick={() =>
          doSearch(formData.trade, formData.location, formData.name)
        }
        sx={{ height: 42, ml: 2 }}
      >
        {t('translation:spDirectory.search.submit')}
      </ECButton>
    </ECBox>
  );
};
