import { styled } from '@mui/material/styles';
import { MenuItem, MenuItemProps, Select, SelectProps } from '@mui/material';

interface ECSelectProps extends SelectProps {
  hiddenLabel?: boolean;
}

export const ECSelect = styled(Select)<ECSelectProps>(({ hiddenLabel }) => ({
  '.MuiSelect-iconFilled': {
    color: 'gray',
  },
  '.MuiSelect-filled': {
    color: 'black',
  },
  '.MuiFilledInput-input': {
    paddingTop: hiddenLabel ? 16 : 25,
    paddingBottom: hiddenLabel ? 16 : 8,
  },
}));

export const ECMenuItem = styled(MenuItem)<MenuItemProps>(() => ({}));

export const ECSelectWhiteBorders = styled(Select)<SelectProps>(
  ({ theme }) => ({
    select: {
      '&:before': {
        borderColor: theme.palette.background.default,
      },
      '&:after': {
        borderColor: theme.palette.background.default,
      },
      '&:focused': {
        borderColor: theme.palette.background.default,
      },
    },
    icon: {
      fill: theme.palette.background.default,
    },
  }),
);
