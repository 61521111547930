import { memo, useEffect, useState } from 'react';
import {
  ECChip,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHead,
  ECTableRow,
  ECTypography,
} from 'app/components';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { NotificationSettingUpdate } from 'types/Notification';
import * as _ from 'lodash';

interface Props {
  settingsData: Record<string, any[]>;
  isEditMode?: boolean;
  onChange?: (changes: NotificationSettingUpdate[]) => void;
}

export const ECNotificationsSettingsTable = memo(
  ({ settingsData, isEditMode, onChange }: Props) => {
    const [changes, setChanges] = useState<NotificationSettingUpdate[]>([]);

    const handleCheckboxChange = (isChecked, settingId, settingName) => {
      const setting = changes.find(s => s.settingId === settingId);
      if (setting) {
        setting[settingName] = isChecked;
      } else {
        changes.push({
          settingId,
          [settingName]: isChecked,
        });
      }
      onChange?.([...changes]);
      setChanges([...changes]);
    };

    useEffect(() => {
      if (!isEditMode) {
        setChanges([]);
        onChange?.([]);
      }
    }, [isEditMode, onChange]);

    return (
      <ECTable>
        {Object.entries(settingsData)?.map(([settingsName, settings]) => {
          const changesForThisSetting = changes?.filter(change =>
            settings
              ?.map(setting => setting.settingId)
              .includes(change.settingId),
          );
          const changesEmailsForThisSetting = changesForThisSetting?.filter(
            change => change?.email !== undefined,
          );
          const settingsEmail: any[] = _.uniqBy(
            _.merge(
              settings.map(setting => ({
                settingId: setting.settingId,
                email: setting.email,
              })),
              changesEmailsForThisSetting?.map(change => ({
                settingId: change.settingId,
                email: change.email,
              })),
            ),
            'settingId',
          );
          const isAllEmailOptionsChecked = settingsEmail.every(
            setting => setting.email,
          );
          const isSomeEmailOptionsChecked = settingsEmail.some(
            setting => setting.email,
          );

          const changesMobileForThisSetting = changesForThisSetting?.filter(
            change => change?.mobile !== undefined,
          );
          const settingsMobile = _.uniqBy(
            _.merge(
              settings.map(setting => ({
                settingId: setting.settingId,
                mobile: setting.mobile,
              })),
              changesMobileForThisSetting?.map(change => ({
                settingId: change.settingId,
                mobile: change.mobile,
              })),
            ),
            'settingId',
          );
          const isAllMobileOptionsChecked = settingsMobile.every(
            setting => setting.mobile,
          );
          const isSomeMobileOptionsChecked = settingsMobile.some(
            setting => setting.mobile,
          );

          return (
            <>
              <ECTableHead>
                <ECTableRow>
                  <ECTableCell colSpan={3}>
                    <ECTypography variant="h6">{settingsName}</ECTypography>
                  </ECTableCell>
                </ECTableRow>
                <ECTableRow>
                  <ECTableCell sx={{ pl: 4 }}>
                    <ECTypography
                      variant="caption"
                      color={theme => theme.palette.text.secondary}
                    >
                      Notification | Description
                    </ECTypography>
                  </ECTableCell>
                  <ECTableCell align="center">
                    {isEditMode && (
                      <ECCheckbox
                        onChange={(event, checked) => {
                          settings.forEach(setting => {
                            handleCheckboxChange(
                              checked,
                              setting.settingId,
                              'email',
                            );
                          });
                        }}
                        checked={isAllEmailOptionsChecked}
                        indeterminate={
                          !isAllEmailOptionsChecked && isSomeEmailOptionsChecked
                        }
                      />
                    )}
                    <ECTypography
                      variant="caption"
                      color={theme => theme.palette.text.secondary}
                    >
                      Email
                    </ECTypography>
                  </ECTableCell>
                  <ECTableCell align="center">
                    {isEditMode && (
                      <ECCheckbox
                        onChange={(event, checked) => {
                          settings.forEach(setting => {
                            handleCheckboxChange(
                              checked,
                              setting.settingId,
                              'mobile',
                            );
                          });
                        }}
                        checked={isAllMobileOptionsChecked}
                        indeterminate={
                          !isAllMobileOptionsChecked &&
                          isSomeMobileOptionsChecked
                        }
                      />
                    )}
                    <ECTypography
                      variant="caption"
                      color={theme => theme.palette.text.secondary}
                    >
                      Push
                    </ECTypography>
                  </ECTableCell>
                </ECTableRow>
              </ECTableHead>

              <ECTableBody>
                {(settings as any[])?.map(setting => (
                  <ECTableRow>
                    <ECTableCell
                      sx={{ whiteSpace: 'break-spaces', py: 1, pl: 4 }}
                    >
                      <ECTypography variant="body2">
                        {setting.title}
                      </ECTypography>
                      <ECTypography
                        variant="caption"
                        color={theme => theme.palette.text.secondary}
                      >
                        {setting.description}
                      </ECTypography>
                    </ECTableCell>
                    <ECTableCell align="center">
                      {isEditMode ? (
                        <ECCheckbox
                          onChange={event => {
                            const isChecked = event.target.checked;
                            handleCheckboxChange(
                              isChecked,
                              setting.settingId,
                              'email',
                            );
                          }}
                          checked={
                            changesEmailsForThisSetting?.find(
                              change => change.settingId === setting.settingId,
                            )
                              ? changesEmailsForThisSetting?.find(
                                  change =>
                                    change.settingId === setting.settingId,
                                )?.email
                              : setting.email
                          }
                        />
                      ) : (
                        <ECChip
                          label={setting.email ? 'Yes' : 'No'}
                          variant="filled"
                          sx={{
                            bgcolor: theme =>
                              setting.email
                                ? theme.palette.success.outlinedHoverBackground
                                : theme.palette.error.outlinedHoverBackground,
                            color: theme =>
                              setting.email
                                ? theme.palette.success.dark
                                : theme.palette.error.dark,
                          }}
                        />
                      )}
                    </ECTableCell>
                    <ECTableCell align="center">
                      {isEditMode ? (
                        <ECCheckbox
                          onChange={event => {
                            const isChecked = event.target.checked;
                            handleCheckboxChange(
                              isChecked,
                              setting.settingId,
                              'mobile',
                            );
                          }}
                          checked={
                            changesMobileForThisSetting?.find(
                              change => change.settingId === setting.settingId,
                            )
                              ? changesMobileForThisSetting?.find(
                                  change =>
                                    change.settingId === setting.settingId,
                                )?.mobile
                              : setting.mobile
                          }
                        />
                      ) : (
                        <ECChip
                          label={setting.mobile ? 'Yes' : 'No'}
                          variant="filled"
                          sx={{
                            bgcolor: theme =>
                              setting.mobile
                                ? theme.palette.success.outlinedHoverBackground
                                : theme.palette.error.outlinedHoverBackground,
                            color: theme =>
                              setting.mobile
                                ? theme.palette.success.dark
                                : theme.palette.error.dark,
                          }}
                        />
                      )}
                    </ECTableCell>
                  </ECTableRow>
                ))}
              </ECTableBody>
            </>
          );
        })}
      </ECTable>
    );
  },
);
