import { QueryParams } from 'types/QueryParams';

export enum Sorting {
  ASC = 'a',
  DESC = 'd',
}

export function handleRequestConfigs(params, trigger, config) {
  const queryParams: QueryParams = {};
  if (!params) {
    trigger({});
    return;
  }
  const sort = params?.sort;
  if (sort) {
    queryParams.o = sort.value === 'ASC' ? Sorting.ASC : Sorting.DESC;
    queryParams.ob = sort.fieldName;
  }
  let filter =
    params?.filterInput?.value?.length > 0 ? params?.filterInput.value : '';
  let search =
    params?.searchInput?.value?.length > 0 ? params?.searchInput.value : '';

  let filterField =
    params?.filterInput?.fieldName?.length > 0
      ? params.filterInput.fieldName
      : '';
  let searchedField =
    params?.searchInput?.fieldName?.length > 0
      ? params.searchInput.fieldName
      : '';

  const searchWord = filter ? filter : search;
  const searchField = filterField ? filterField : searchedField;

  if (searchField && searchWord !== '') {
    queryParams.s = searchWord;
    queryParams.sb = searchField;
  }
  const page = params?.pagination?.currentPage || 0;
  const perPage = params?.pagination?.perPage || 50;
  queryParams.p = page;
  queryParams.t = perPage;
  config = { ...config, ...params };
  config.pagination.currentPage = page;
  config.pagination.perPage = perPage;
  trigger(queryParams);
  return config;
}
