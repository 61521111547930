import {
  useLazyGetInvoiceListQuery,
  useLazyGetInvoiceByIdQuery,
  useUpdateStatusMutation,
  invoiceApi,
} from 'services/invoiceApi';
import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECIconButton,
  ECRequirePermission,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import _ from 'lodash';
import {
  WorkflowStatus,
  WorkflowStatusGroupName,
} from 'app/components/ECWorkflowStatusBadge';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { RootState } from 'index';
import { Popover } from '@mui/material';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { P } from 'types/Permission';
import { Close, Edit, Done } from '@mui/icons-material';
import { bulkEditButtonStyle } from '../WorkOrdersPage';
import { BulkEditDrawer } from './BulkEditDrawer';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { InvoiceRejectModal } from './InvoiceRejectModal';

const fancyFormRejectInvoice = require('./../InvoicesDetailsPage/fancy_form_config_reject.json');

export function InvoicesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.user);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [row, setSelectedRow] = useState<any>(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectModalData, setRejectModalData] = useState<{
    id: number;
    statusTo?: number;
    actionId?: number;
  } | null>(null);

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('invoice');
  const {
    data: categories,
    isFetching: isFetchingCategories,
    isSuccess: isSuccessCategories,
  } = useGetCategoryListQuery({ st: 1 });

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction, reset },
  ] = useUpdateStatusMutation();

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/invoice'));
  }, []);

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(invoiceApi.util.invalidateTags(['InvoiceList']));
      dispatch(invoiceApi.util.invalidateTags(['PendingInvoices']));
      reset();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Invoice status updated successfully.',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  const isCustomer = useCustomerUser();

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: status.name,
          isInitialSelected: status.name === WorkflowStatus.PendingApproval,
        }))
      : undefined;
  }, [workflowStatuses]);

  const categoryFilterOptions = useMemo(() => {
    return (
      _.orderBy(categories?.data, 'name')?.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.id?.toString(),
        isInitialSelected: true,
      })) || []
    );
  }, [categories]);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingCategories &&
        isSuccessWorkflowStatuses &&
        isSuccessCategories,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingCategories,
    isSuccessWorkflowStatuses,
    isSuccessCategories,
  ]);

  const onClickViewUsersTable = useCallback((row, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(row);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedRow(null);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);

  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleSelectedRows = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setClearAllSelectedRows(shouldDisable);
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  const handleApproveOrReject = (
    id: number,
    data: WorkflowNextStatus[],
    isReject = false,
  ) => {
    if (isLoadingActions) {
      return;
    }
    let statusParam;
    if (isReject) {
      statusParam = data?.find(
        status =>
          status?.statusGroupName === WorkflowStatusGroupName.Rejected ||
          status?.name?.toLowerCase() === 'reject',
      );
      const addCommentField = fancyFormRejectInvoice.fields.find(
        f => f.fieldName === 'rejectReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = id;
      }
      setRejectModalData({
        id: Number(id),
        statusTo: statusParam?.statusTo,
        actionId: statusParam?.actionId,
      });
      setShowRejectModal(true);
    } else {
      statusParam = data?.find(
        status =>
          status?.statusGroupName === WorkflowStatusGroupName.Approved ||
          status?.name?.toLowerCase() === 'approve',
      );
      doUpdateStatus({
        id: Number(id),
        statusTo: statusParam?.statusTo,
        actionId: statusParam?.actionId,
      });
    }
  };

  const renderApproveAndRejecttActionButton = (row: any) => {
    return (
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        key={`${row?.id}-approve-reject`}
      >
        {row?.workflowStatus?.actions?.find(
          a =>
            a?.name?.toLowerCase() === 'approve' ||
            a?.statusGroupName === WorkflowStatusGroupName.Approved,
        ) && (
          <ECIconButton
            size="medium"
            onClick={() =>
              handleApproveOrReject(
                row?.id,
                row?.workflowStatus?.actions,
                false,
              )
            }
            type="success"
            withBorder
            sx={theme => ({ borderColor: theme.palette.success })}
          >
            <Done sx={theme => ({ color: theme.palette.success })} />
          </ECIconButton>
        )}
        <ECBox sx={{ width: '5px' }} />
        {row?.workflowStatus?.actions?.find(
          a =>
            a?.name?.toLowerCase() === 'reject' ||
            a?.statusGroupName === WorkflowStatusGroupName.Rejected,
        ) && (
          <ECIconButton
            size="medium"
            onClick={() =>
              handleApproveOrReject(row?.id, row?.workflowStatus?.actions, true)
            }
            type="error"
            withBorder
            sx={theme => ({ borderColor: theme.palette.error })}
          >
            <Close sx={theme => ({ color: theme.palette.error })} />
          </ECIconButton>
        )}
      </ECBox>
    );
  };

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:panelDrawer.invoices')}
        useLazyGetListQuery={useLazyGetInvoiceListQuery}
        useLazyGetByIdQuery={useLazyGetInvoiceByIdQuery}
        useCreateMutation={{}}
        withDrawer={false}
        dateFilterFieldsOptions={[
          {
            id: 1,
            label: 'Created Date',
            fieldName: 'invce.createdAt',
          },
          {
            id: 2,
            label: 'Approved Date',
            fieldName: 'invce.approvedDate',
          },
        ]}
        renderCustomActionComponent={(row: any) => {
          if (row?.workflowStatus?.canApproveOrReject) {
            return renderApproveAndRejecttActionButton(row);
          }
        }}
        statusFilterOptions={statusFilterOptions}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterAlias="wrkflwstts.name"
        showStatusFilter
        categoryFilterAlias="ctgry.id"
        showCategoryFilter
        showDateFilter
        enableExport
        exportModuleName="invoice"
        showAssignedToMeFilter
        showAssetTypeFilter={!!user?.assignedAssetTypes?.length}
        dateRangeDefaultSelectedOption="All Time"
        onClickViewUsersTable={onClickViewUsersTable}
        selectedChipUsersTable={row?.id}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        showColumnVisibilityFilter
        isDraggableColumns
        multiSelectTable
        selectRowsMaxCount={50}
        customMultiSelectActionButton={() => (
          <ECRequirePermission scopes={[P.ChangeInvoiceStatus]}>
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={bulkEditButtonStyle}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        )}
        clearSelection={clearAllSelectedRows}
        multiSelectedRows={handleSelectedRows}
      />
      <BulkEditDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
      />
      <Popover
        id={row?.id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={750}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <ECUsersTableModal module="invoice" id={row?.id} />
        </ECBox>
      </Popover>
      {showRejectModal && (
        <InvoiceRejectModal
          rejectModalData={rejectModalData}
          setRejectModalData={setRejectModalData}
          showRejectModal={showRejectModal}
          setShowRejectModal={setShowRejectModal}
        />
      )}
    </>
  );
}
