import { ECBox } from 'app/components/ECBox';
import { ECTypography } from 'app/components/ECTypography';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';
import { ECEasyFormCreate, ECImage, ECLink } from 'app/components';
import { useGetSupplierByIdQuery } from 'services/supplierApi';
import { DocumentCoreTypes } from 'types/Attachment';
import { Language } from '@mui/icons-material';
import { SupplierContactInfo } from './supplier-info-modal';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { useCreateInvoiceMutation } from 'services/invoiceApi';
import { isoDateWithTimezoneAtZero } from 'utils/strings/formatDate';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

interface EditProps {
  purchaseOrder: any;
  onClose?: () => void;
}

export const NewPOInvoiceForm = ({ purchaseOrder, onClose }: EditProps) => {
  const { data: supplier, isLoading: isLoadingSupplier } =
    useGetSupplierByIdQuery(Number(purchaseOrder?.supplier?.id));

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields,
  );

  useLayoutEffect(() => {
    const supplierLogoUrl = supplier?.attachments?.find(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.SupplierProfilePhoto,
    )?.thumbnailURL;

    const supplierHeaderField = formFields.find(
      field => field.fieldName === 'supplierHeader',
    );
    supplierHeaderField.value = (
      <ECBox display="flex" justifyContent="space-between" alignItems="center">
        <ECBox display="flex" gap={1} alignItems="center">
          <ECImage
            sx={{
              width: '100px',
              height: '50px',
              objectFit: 'cover',
              mr: 2,
            }}
            alt="supplier logo"
            src={supplierLogoUrl}
          />

          <ECTypography fontWeight="bold">{supplier?.name}</ECTypography>
        </ECBox>

        <ECLink
          id="hyperlink"
          target="_blank"
          href={
            supplier?.website
              ? supplier?.website.startsWith('http://') ||
                supplier?.website.startsWith('https://')
                ? supplier?.website
                : `http://${supplier?.website}`
              : ''
          }
        >
          <Language sx={{ marginRight: 1 }} /> Website
        </ECLink>
      </ECBox>
    );

    const supplierContactsField = formFields.find(
      field => field.fieldName === 'supplierContacts',
    );
    supplierContactsField.value = (
      <ECBox display="flex" flexDirection="column" gap={1} mb={1}>
        {supplier?.contacts?.map(contact => (
          <SupplierContactInfo
            key={contact.id}
            supplierCountryCode={supplier?.address?.countryCode}
            {...contact}
          />
        ))}
      </ECBox>
    );

    setFormFields([...formFields]);
  }, [supplier]);

  const costTableData = useMemo(
    (): CostTableData => ({
      itemsRepair: [
        {
          label: 'Freight',
          dataPath: 'costFreight',
        },
        {
          label: 'Tax',
          dataPath: 'costTax',
        },
        {
          label: 'Other',
          dataPath: 'costOther',
        },
        {
          label: 'purchaseOrderItems',
          value: purchaseOrder?.purchaseOrderItems,
          dataPath: 'purchaseOrderItems',
          hidden: true,
        },
      ],
    }),
    [purchaseOrder?.purchaseOrderItems],
  );

  const [doCreate, { isLoading }] = useCreateInvoiceMutation();

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const params: any = {
        requestId: purchaseOrder?.requestId,
        invoiceNumber: data.invoiceNumber,
        description: data.description,
        costFreight: data.costFreight,
        costOther: data.costOther,
        costTax: data.costTax,
        invoiceDate: isoDateWithTimezoneAtZero(data.invoiceDate),
        purchaseOrderItems: JSON.stringify(
          data.purchaseOrderItems.map(item => ({
            id: item.id,
            price: item.subTotal,
          })),
        ),
      };

      if (data.files) {
        params.files = data.files.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }

      doCreate?.(params);
    };

    return [doSubmit, { isLoading }];
  }, [isLoading]);

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox
        display="flex"
        justifyContent="start"
        px={4}
        height={StyleConstants.DRAWER_TITLE_HEIGHT}
        bgcolor={themes.light.palette.other.divider}
      >
        <ECTypography variant="h4">Create Invoice</ECTypography>
      </ECBox>

      <ECEasyFormCreate
        useCreateMutation={submit}
        hideToolBar
        showTitle={false}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        onClose={onClose}
        costTableData={costTableData}
      />
    </ECBox>
  );
};
