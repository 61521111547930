import { ArrowDropDown } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WorkflowNextStatus, WorkflowChangeType } from 'types/WorkflowStatus';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECDivider } from '../ECDivider';
import { ECIconButton } from '../ECIconButton';
import { ECPopover } from '../ECPopover';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECCircularProgress } from '../ECCircularProgress';

export interface WorkflowActionData {
  type: WorkflowChangeType;
  name: string;
  statusTo: number;
  approvalProcessId: number;
  approvalProcessStepId: number;
  approvalProcessActionId: number;
  jobTitleId: number;
  workflowLinkId: number;
}

interface ECWorkflowActionButtonProps {
  actions: WorkflowNextStatus[];
  ignoredActionNames?: string[];
  onActionClick?: (action: WorkflowNextStatus) => void;
  variant?: 'dropdown' | 'button';
  staticStatus?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ECWorkflowActionButton = ({
  actions,
  onActionClick,
  variant = 'dropdown',
  staticStatus,
  ignoredActionNames,
  disabled,
  isLoading,
}: ECWorkflowActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [showActions, setShowActions] = useState(false);

  const filteredActions = useMemo(() => {
    if (ignoredActionNames) {
      return actions.filter(
        action => !ignoredActionNames.includes(action.name),
      );
    }
    return actions;
  }, [actions, ignoredActionNames]);

  const selectedAction = useMemo(() => {
    const [action] = actions;
    return {
      id: action ? action.statusTo || action.actionId : 0,
      name: action ? action.name : staticStatus || '',
    };
  }, [actions, staticStatus]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleDropdownIconClick = e => {
    e.stopPropagation();
    setAnchorEl(buttonRef.current);
    toggleShowActionsOptions();
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
    toggleShowActionsOptions();
  };

  const handleSelectionClick = (action: WorkflowNextStatus) => () => {
    onActionClick?.(action);
    handleActionsClose();
  };

  const toggleShowActionsOptions = () => setShowActions(!showActions);
  const closeShowActionOptions = () => setShowActions(false);

  const id = showActions ? 'simple-popover' : undefined;

  useEffect(() => {
    closeShowActionOptions();
  }, [selectedAction, actions]);

  if (variant === 'button') {
    return (
      <ECBox display="flex">
        {filteredActions?.map(action => (
          <ECButton
            key={action.statusTo || action.actionId}
            disabled={disabled || isLoading}
            variant={action.actionId ? 'contained' : 'outlined'}
            onClick={handleSelectionClick(action)}
            sx={{ mr: 2 }}
          >
            {isLoading ? (
              <ECCircularProgress
                size="1rem"
                sx={theme => ({ color: theme.palette.primary.main })}
              />
            ) : (
              action.name
            )}
          </ECButton>
        ))}
      </ECBox>
    );
  }

  return (
    <>
      {(actions.length > 0 || staticStatus) && (
        <ECBox ref={buttonRef}>
          <ECButton
            disabled={disabled}
            variant="outlined"
            sx={{ p: 0 }}
            component="div"
          >
            <ECBox
              display="flex"
              height={40}
              onClick={handleDropdownIconClick}
              alignItems="center"
              textAlign="center"
              pl={1}
            >
              {selectedAction.name}
              <ECDivider
                orientation="vertical"
                flexItem
                sx={theme => ({
                  bgcolor: theme.palette.primary.main,
                  marginLeft: 1,
                })}
              />
              <ECIconButton>
                <ArrowDropDown
                  sx={{ color: theme => theme.palette.primary.main }}
                />
              </ECIconButton>
            </ECBox>
          </ECButton>

          <ECPopover
            id={id}
            open={showActions}
            anchorEl={anchorEl}
            onClose={handleActionsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ECBox display="flex" flexDirection="column" width="270px">
              {actions?.length ? (
                actions.map((action, index) => (
                  <ECStack
                    direction="row"
                    spacing={2}
                    px={2}
                    py={1}
                    sx={theme => ({
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: theme.palette.primary.outlinedHoverBackground,
                      },
                    })}
                    alignItems="center"
                    onClick={handleSelectionClick(action)}
                    key={index}
                  >
                    <ECTypography variant="body1">{action.name}</ECTypography>
                  </ECStack>
                ))
              ) : (
                <ECBox p={2}>
                  <ECTypography variant="body1">
                    No options available
                  </ECTypography>
                </ECBox>
              )}
            </ECBox>
          </ECPopover>
        </ECBox>
      )}
    </>
  );
};
