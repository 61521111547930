import { BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationList } from 'types/Notification';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECNotification } from '../ECNotification';
import { ECTypography } from '../ECTypography';

interface ECNotificationPopoverContentProps extends BoxProps {
  notifications: NotificationList[];
  isLoading?: boolean;
  onNotificationChange: (
    notification: NotificationList,
  ) => (checked: boolean) => void;
  onMarkAllAsRead?: () => void;
  getNextPage?: () => void;
}

export const ECNotificationPopoverContent = ({
  notifications,
  isLoading,
  onNotificationChange,
  onMarkAllAsRead,
  getNextPage,
  ...boxProps
}: ECNotificationPopoverContentProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <ECBox
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="center"
        width={400}
      >
        <ECCircularProgress size="2rem" />
      </ECBox>
    );
  }

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      maxWidth={400}
      minWidth={100}
      {...boxProps}
    >
      {notifications && notifications.length > 0 && (
        <ECBox display="flex" justifyContent="flex-end" p={2}>
          <ECButton variant="text" onClick={onMarkAllAsRead}>
            {t('translation:notifications.markAllAsRead')}
          </ECButton>
        </ECBox>
      )}

      {notifications && notifications.length > 0 ? (
        <>
          {notifications?.map(notification => (
            <ECNotification
              key={`notification-${notification._id}`}
              notification={notification}
              onCheckChange={onNotificationChange(notification)}
            />
          ))}

          <ECButton variant="text" onClick={getNextPage}>
            {t('translation:notifications.loadMore')}
          </ECButton>
        </>
      ) : (
        <ECBox p={4}>
          <ECTypography>{t('translation:notifications.empty')}</ECTypography>
        </ECBox>
      )}
    </ECBox>
  );
};
