import { ECBox } from '../ECBox';
import { ECAlert } from '../ECAlert';
import { ECChip } from '../ECChip';
import { ECGrid } from '../ECGrid';
import _ from 'lodash';
import { ECTypography } from '../ECTypography';
import { ArrowForward } from '@mui/icons-material';
import { ECStack } from '../ECStack';

export interface ECActivityItem {
  itemId: number;
  statusFrom?: string;
  statusTo?: string;
  statusOutcome?: boolean; // true success, false fail
  statusFailReason?: string;
  categoryFrom?: string;
  categoryTo?: string;
  categoryOutcome?: boolean;
  nteFrom?: string;
  nteTo?: string;
  nteFailReason?: string;
  nteOutcome?: boolean;
  flagsFrom?: any[];
  flagsTo?: any[];
  flagsOutcome?: boolean;
  flagsFailReason?: string;
  comment?: string;
  rejectionReason?: string;
  CategoryNameFrom?: string;
  categoryFailReason?: string;
  note?: string;
}

interface ECBulkActivityView {
  moduleName: string | undefined;
  value: any;
}

export const ECBulkActivityView = ({
  moduleName,
  value,
}: ECBulkActivityView) => {
  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.default}
      flexDirection="column"
      sx={{ width: '100%' }}
      textAlign="center"
      alignItems="center"
    >
      {/* <ECAlert
        severity="success"
        style={{
          backgroundColor: 'transparent',
          border: '1px solid green',
          width: '100%',
        }}
      >
        <ECBox style={{ display: 'flex', alignItems: 'center' }}>
          {`Changes successfully Applied to ${value?.length} ${moduleName}`}
        </ECBox>
      </ECAlert> */}
      {value?.map((item: ECActivityItem) => (
        <ECGrid container spacing={2} sx={{ marginTop: '16px' }}>
          <ECGrid item xs={2}>
            <ECChip
              label={item?.itemId}
              variant="outlined"
              sx={{ flex: '0 0 auto' }}
            />
          </ECGrid>
          <ECGrid item xs={10}>
            <ECBox sx={{ flex: '1 0 auto', width: '100%' }}>
              <ECBox
                display="flex"
                borderColor={theme => theme.palette.other.divider}
                boxShadow={2}
                p={1}
                border="1px"
                borderRadius={2}
                bgcolor={theme => theme.palette.common.white}
                sx={{ width: '100%' }}
              >
                <ECGrid container spacing={2}>
                  {!_.isNil(item.statusOutcome) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={2}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Status:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={5}>
                          <ECStack direction="row" spacing={2}>
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme => theme.palette.error.dark}
                              sx={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {item.statusFrom}
                            </ECTypography>
                            <ArrowForward
                              sx={theme => ({
                                color: theme.palette.action.active,
                                mx: 1,
                              })}
                              fontSize="small"
                            />
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme =>
                                item.statusOutcome
                                  ? theme.palette.success.dark
                                  : theme.palette.error.dark
                              }
                            >
                              {item.statusTo}
                            </ECTypography>
                          </ECStack>
                        </ECGrid>
                        <ECGrid item xs={5} style={{ textAlign: 'left' }}>
                          <ECAlert
                            severity={item.statusOutcome ? 'success' : 'error'}
                            style={{
                              backgroundColor: 'transparent',
                              marginTop: -10,
                            }}
                          >
                            {item.statusOutcome
                              ? 'Status updated successfully'
                              : item.statusFailReason}
                          </ECAlert>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.rejectionReason) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={3}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Rejection Reason:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={9} style={{ textAlign: 'left' }}>
                          <ECTypography
                            style={{ textAlign: 'left', fontStyle: 'italic' }}
                          >
                            {'"' + item.rejectionReason + '"'}
                          </ECTypography>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.nteOutcome) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={3}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            NTE:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={4}>
                          <ECStack direction="row" spacing={2}>
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme => theme.palette.error.dark}
                              sx={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {item.nteFrom}
                            </ECTypography>
                            <ArrowForward
                              sx={theme => ({
                                color: theme.palette.action.active,
                                mx: 1,
                              })}
                              fontSize="small"
                            />
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme =>
                                item.nteOutcome
                                  ? theme.palette.success.dark
                                  : theme.palette.error.dark
                              }
                            >
                              {item.nteTo}
                            </ECTypography>
                          </ECStack>
                        </ECGrid>
                        <ECGrid item xs={5} style={{ textAlign: 'left' }}>
                          <ECAlert
                            severity={item.nteOutcome ? 'success' : 'error'}
                            style={{
                              backgroundColor: 'transparent',
                              marginTop: -10,
                            }}
                          >
                            {item.nteOutcome
                              ? 'NTE updated successfully'
                              : item.nteFailReason}
                          </ECAlert>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.statusOutcome) && !_.isNil(item.comment) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={3}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Comment:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={9}>
                          <ECTypography
                            variant="body2"
                            color={theme => theme.palette.action.active}
                            style={{ textAlign: 'left' }}
                          >
                            {item.comment}
                          </ECTypography>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.categoryOutcome) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={2}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Category:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={5}>
                          <ECStack direction="row" spacing={2}>
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme => theme.palette.error.dark}
                              sx={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {item.CategoryNameFrom}
                            </ECTypography>
                            <ArrowForward
                              sx={theme => ({
                                color: theme.palette.action.active,
                                mx: 1,
                              })}
                              fontSize="small"
                            />
                            <ECTypography
                              variant="subtitle2"
                              maxWidth="100%"
                              color={theme =>
                                item.categoryOutcome
                                  ? theme.palette.success.dark
                                  : theme.palette.error.dark
                              }
                            >
                              {item.categoryTo}
                            </ECTypography>
                          </ECStack>
                        </ECGrid>
                        <ECGrid item xs={5} style={{ textAlign: 'left' }}>
                          <ECAlert
                            severity={
                              item.categoryOutcome ? 'success' : 'error'
                            }
                            style={{
                              backgroundColor: 'transparent',
                              marginTop: -10,
                            }}
                          >
                            {item.categoryOutcome
                              ? 'Category updated successfully'
                              : item.categoryFailReason}
                          </ECAlert>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.flagsOutcome) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={2}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Flags:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECGrid item xs={5}>
                          <ECStack direction="row" spacing={2}>
                            <ECBox
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              gap={2}
                            >
                              {item.flagsFrom?.map((flag, index) => (
                                <ECChip
                                  key={`${flag.label}-${index}`}
                                  label={flag.label}
                                  color="Red"
                                  sx={{
                                    textDecoration: flag.isStrikeThrough
                                      ? 'line-through'
                                      : undefined,
                                  }}
                                  variant="outlined"
                                />
                              ))}
                            </ECBox>
                            <ECBox display="flex" alignItems="center">
                              <ArrowForward
                                sx={theme => ({
                                  color: theme.palette.action.active,
                                  mx: 1,
                                })}
                                fontSize="small"
                              />
                            </ECBox>
                            <ECBox
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              gap={2}
                            >
                              {item.flagsTo?.map((flag, index) => (
                                <ECChip
                                  key={`${flag.label}-${index}`}
                                  label={flag.label}
                                  color="Dark Green"
                                  variant="outlined"
                                />
                              ))}
                            </ECBox>
                          </ECStack>
                        </ECGrid>
                      </ECBox>
                    </ECGrid>
                  )}
                  {!_.isNil(item.note) && (
                    <ECGrid item xs={12}>
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <ECGrid item xs={2}>
                          <ECTypography
                            variant="subtitle2"
                            color={theme => theme.palette.text.secondary}
                            style={{ textAlign: 'left', marginLeft: '10px' }}
                          >
                            Comment:&nbsp;
                          </ECTypography>
                        </ECGrid>
                        <ECTypography
                          variant="subtitle2"
                          maxWidth="100%"
                          color={theme => theme.palette.success.dark}
                        >
                          Added
                        </ECTypography>
                      </ECBox>

                      <ECBox display="flex">
                        <ECTypography
                          variant="subtitle2"
                          maxWidth="100%"
                          ml="10px"
                        >
                          "{item.note}"
                        </ECTypography>
                      </ECBox>
                    </ECGrid>
                  )}
                </ECGrid>
              </ECBox>
            </ECBox>
          </ECGrid>
        </ECGrid>
      ))}
    </ECBox>
  );
};
