import moment from 'moment';

export default function daysPassedSince(
  date: string | Date,
  withSuffix = true,
): string {
  if (!date || !moment(date).isValid()) {
    return 'N/A';
  }

  const today = moment().startOf('day');
  const mdate = moment(date).startOf('day');
  return `${today.diff(mdate, 'days')}${withSuffix ? ' days' : ''}`;
}
