export enum FieldTypes {
  Text = 'text_field',
  MultiLineText = 'text_field_multiline',
  Email = 'email',
  Password = 'password',
  PasswordCheckList = 'password_checklist',
  Number = 'number',
  AddressAutocomplete = 'Address_Autocomplete',
  Select = 'select',
  SelectPaginated = 'select_paginated',
  Radio = 'radio',
  RadioSPList = 'radio_sp_list',
  RadioWarrantyStartDate = 'radio_warranty_start_date',
  RadioWithCustomOptions = 'radio_with_custom_options',
  Checkbox = 'checkbox',
  Switch = 'switch',
  DatePicker = 'date_picker',
  TimePicker = 'time_picker',
  DateTimePicker = 'date_time_picker',
  FilePicker = 'file_picker',
  TextArea = 'text_area',
  ChipsList = 'chips_list',
  ChipAutocomplete = 'chip_autocomplete',
  TransferList = 'transfer_list',
  Section = 'section',
  Label = 'label',
  Autocomplete = 'autocomplete',
  AutocompleteAsset = 'autocomplete_asset',
  ColorPicker = 'color_picker',
  TradeRegion = 'trade_region',
  AssetTradeProblem = 'asset_trade_problem',
  AssetProblemTroubleshoot = 'asset_problem_troubleshoot',
  Photo = 'photo',
  RichText = 'rich_text',
  Video = 'video',
  HourEta = 'hour_eta',
  Currency = 'currency',
  Category = 'category',
  Rank = 'rank',
  UsersTable = 'users_table',
  CostTable = 'cost_table',
  Group = 'group',
  Info = 'info',
  MaterialMarkup = 'material_markup',
  OperatingHours = 'operating_hours',
  Address = 'address',
  FailureWarranty = 'failure_warranty',
  Phone = 'phone',
  LinkPhone = 'link_phone',
  Link = 'link',
  ServiceFrequency = 'service_frequency',
  CheckboxAutocomplete = 'checkbox_autocomplete',
  AddComment = 'add_comment',
  AddCommentBulk = 'add_comment_bulk',
  WarrantyPeriod = 'warranty_period',
  ZipCode = 'zip_code',
  Country = 'country',
  BulkActivityView = 'bulk_activity_view',
  Alert = 'alert',
  StorageLocations = 'storage_locations',
  InventoryItems = 'inventory_items',
  AllocatedInventory = 'allocated_inventory',
  Custom = 'custom',
  EditableTable = 'editable_table',
  SiteTime = 'site_time',
  ChipText = 'chip_text',
  BannerFilePicker = 'banner_file_picker',
  checkboxWithCustomOptions = 'checkbox_with_custom_options',
  checkboxGroup = 'checkbox_group',
  sectionWithCustomOptions = 'section_with_custom_options',
  FieldButton = 'field_button',
  Rating = 'rating',
  PMSetupAlert = 'pm_setup_alert',
  PurchaseOrderItems = 'purchase_order_items',
}
