import { TreeNode } from 'app/components';
import { Organization } from 'types/Organization';
import { eachDeep } from 'deepdash-es/standalone';
import _ from 'lodash';

export const mapOrganizationToTreeNode = (org: Organization): TreeNode => {
  return {
    label: org.name,
    nodeId: org.id,
    organizationType: org.organizationType,
    parentNodeId: org.parentId,
    children: org.children?.map(mapOrganizationToTreeNode),
  };
};

export const getChildrenNodes = (tree: TreeNode) => {
  const childrenNodes: TreeNode[] = [];

  eachDeep(
    tree,
    (value, key, parent) => {
      childrenNodes.push(value);
    },
    { childrenPath: ['children'] },
  );

  return childrenNodes;
};

export const selectParentNodes = (
  tree: TreeNode,
  selectedNodesIds: number[],
) => {
  const newSelectedNodes: any[] = [];

  for (let i = 0; i < 3; i++) {
    eachDeep(
      tree,
      (value, key, parent) => {
        if (
          selectedNodesIds.includes(value.nodeId) ||
          (value?.children?.length &&
            value?.children?.every(child =>
              selectedNodesIds.includes(child.nodeId),
            ))
        ) {
          newSelectedNodes.push(value);
        }
      },
      { childrenPath: ['children'] },
    );
  }

  const uniqNodes = _.uniq(newSelectedNodes);

  return uniqNodes;
};

export const selectParentNodesIds = (
  tree: TreeNode,
  selectedNodesIds: number[],
) => {
  const newSelectedNodesId: number[] = _.clone(selectedNodesIds);

  for (let i = 0; i < 3; i++) {
    eachDeep(
      tree,
      (value, key, parent) => {
        if (
          newSelectedNodesId.includes(value.nodeId) ||
          (value?.children?.length &&
            value?.children?.every(child =>
              newSelectedNodesId.includes(child.nodeId),
            ))
        ) {
          newSelectedNodesId.push(value.nodeId);
        }
      },
      { childrenPath: ['children'] },
    );
  }

  const uniqNodes = _.uniq(newSelectedNodesId);

  return uniqNodes;
};

export const findPartialParentNodesIds = (
  tree: TreeNode,
  selectedNodesIds: number[],
) => {
  const partialParentNodesIds: number[] = [];

  for (let i = 0; i < 3; i++) {
    eachDeep(
      tree,
      (value, key, parent) => {
        if (
          !selectedNodesIds.includes(value.nodeId) &&
          value?.children?.length &&
          value?.children?.some(child =>
            selectedNodesIds.includes(child.nodeId),
          )
        ) {
          partialParentNodesIds.push(value.nodeId);
        }
      },
      { childrenPath: ['children'] },
    );
  }

  const uniqNodes = _.uniq(partialParentNodesIds);

  return uniqNodes;
};

export const findNode = (
  tree: TreeNode[],
  searchText: string,
  dataFieldsToSearch?: string[],
): TreeNode[] => {
  const nodes: TreeNode[] = [];
  const childrenNodes: TreeNode[] = [];

  eachDeep(
    tree,
    (value, key, parent) => {
      const searchFieldValueMatchSearch = dataFieldsToSearch
        ?.map(field => {
          return value.data?.[field]
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
        })
        .some(value => value);

      if (
        (value.label.toLowerCase().includes(searchText.toLowerCase()) ||
          searchFieldValueMatchSearch) &&
        !childrenNodes.map(node => node.nodeId).includes(value.nodeId)
      ) {
        nodes.push(value);
        childrenNodes.push(...getChildrenNodes(value));
      }
    },
    { childrenPath: ['children'] },
  );

  const uniqNodes = _.uniqBy(nodes, 'nodeId');

  return uniqNodes;
};

export const findNodeById = (
  tree: TreeNode[],
  id: number = 0,
): TreeNode | null => {
  let node: TreeNode | null = null;

  eachDeep(
    tree,
    (value, key, parent) => {
      if (value.nodeId === id) {
        node = value;
      }
    },
    { childrenPath: ['children'] },
  );

  return node;
};

export const getAllNodesIds = (tree, idFieldName): number[] => {
  const ids: number[] = [];

  eachDeep(
    tree,
    (value, key, parent) => {
      ids.push(value?.[idFieldName]);
    },
    { childrenPath: ['children'] },
  );

  return ids;
};

export const getAllNodes = (tree): TreeNode[] => {
  const nodes: TreeNode[] = [];

  eachDeep(
    tree,
    (value, key, parent) => {
      nodes.push(value);
    },
    { childrenPath: ['children'] },
  );

  return nodes;
};
