import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ECGrid, ECMenuItem, ECSelect } from 'app/components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { setTitle } from 'store/slice/page';
import { StyleConstants } from 'styles/StyleConstants';
import {
  useGetReportsListQuery,
  useGetReportUrlQuery,
} from 'services/reportsApi';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { ECBox, ECTypography } from 'app/components';
import { useTranslation } from 'react-i18next';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

export function ReportsPage() {
  const dispatch = useDispatch();
  const [currentReport, setCurrentReport] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTitle('Reports'));
  }, [dispatch]);

  const { data: userProfile, isSuccess: isSuccessUserProfile } =
    useGetUserProfileQuery();

  const { data: reports, isLoading } = useGetReportsListQuery(
    userProfile?.userId,
    {
      skip: !isSuccessUserProfile,
    },
  );

  const { data: reportUrl } = useGetReportUrlQuery(currentReport, {
    skip: !currentReport,
  });

  const baseSx = {
    mb: 2,
    width: '50%',
    '& .MuiFilledInput-root': {
      bgcolor: theme => `${theme.palette.common.white} !important`,
      '&:hover': {
        bgcolor: theme => theme.palette.common.white,
      },
      '&.Mui-focused': {
        bgcolor: theme => theme.palette.common.white,
      },
      '&.Mui-focused:after': {
        bgcolor: theme => theme.palette.common.white,
      },
    },
  };

  useEffect(() => {
    async function embedDashboard() {
      const containerDiv = document.getElementById('embeddingContainer');
      while (containerDiv?.firstChild) {
        containerDiv.removeChild(containerDiv.firstChild);
      }

      const embeddingContext = await createEmbeddingContext({});

      if (reportUrl) {
        const frameOptions = {
          url: reportUrl?.EmbedUrl, // Use the actual report URL here
          container: '#embeddingContainer',
          height: '700px',
          width: '100%',
          resizeHeightOnSizeChangedEvent: true,
          withIframePlaceholder: true,
        };

        const contentOptions = {
          parameters: [
            {
              Name: 'country',
              Values: ['United States'],
            },
            // ...
          ],
          locale: 'en-US',
          // ...

          toolbarOptions: {
            export: true,
            undoRedo: true,
            reset: true,
            bookmarks: true,
          },
        };

        const embeddedVisualExperience = await embeddingContext.embedDashboard(
          frameOptions,
          contentOptions,
        );
      }
    }

    embedDashboard();
  }, [reportUrl]);

  useEffect(() => {
    if (reports) {
      setCurrentReport(reports[0]?.reportId);
    }
  }, [reports]);

  return (
    <>
      <Helmet>
        <title>Reports</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECGrid
        container
        sx={{ padding: 2.3, marginTop: StyleConstants.NAV_BAR_HEIGHT }}
      >
        {isLoading && (
          <ECBox
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            width={'100%'}
            p={4}
          >
            <ECCircularProgress size={'2rem'} />
            <ECTypography ml={2} sx={{ opacity: 0.7 }} variant={'body1'}>
              {t('translation:table.loading')}
            </ECTypography>
          </ECBox>
        )}
        {reports && (
          <>
            <ECGrid item xs={4}>
              <ECSelect
                id="select-report"
                label="Select Report"
                placeholder="Select Report"
                sx={baseSx}
                value={currentReport}
                onChange={event =>
                  setCurrentReport(event.target.value as string)
                }
              >
                {reports?.map(report => (
                  <ECMenuItem key={report?.title} value={report?.reportId}>
                    {report?.title}
                  </ECMenuItem>
                ))}
              </ECSelect>
            </ECGrid>
            <ECGrid item xs={12}>
              <div
                id="embeddingContainer"
                style={{ height: '700px', width: '100%' }}
              ></div>
            </ECGrid>
          </>
        )}
      </ECGrid>
    </>
  );
}
