import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';
import { Supplier } from 'types/Supplier';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Supplier', 'SupplierById', 'SuppliersForDropdown'],
});

export const supplierApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAllSupplier: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        return {
          url: `supplier`,
          params: {
            ...params,
          },
        };
      },
      providesTags: ['Supplier'],
    }),
    getSuppliersListForDropdown: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        return {
          url: '/supplier/dropdown',
          params,
        };
      },
      providesTags: ['SuppliersForDropdown'],
    }),
    createSupplier: builder.mutation<any, any>({
      query: body => {
        const formData = new FormData();

        formData.append('name', body.name);
        formData.append('phone', body.phone);
        formData.append('phoneExt', body.phoneExt);
        formData.append('email', body.email);

        if (body.website) {
          formData.append('website', body.website);
        }

        if (body.address) {
          formData.append('address', JSON.stringify(body.address));
        }

        formData.append('contacts', JSON.stringify(body.contacts));

        body?.files?.forEach(file => {
          formData.append('files', file.processedFile);
        });

        return {
          url: 'supplier',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['Supplier'],
    }),
    updateSupplier: builder.mutation<any, any>({
      query: ({ id, ...body }) => {
        return {
          url: `supplier/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Supplier', 'SupplierById'],
    }),
    getSupplierById: builder.query<Supplier, number>({
      query: id => {
        return {
          url: `supplier/${id}`,
        };
      },
      providesTags: ['SupplierById'],
    }),
    getSupplierContactsById: builder.query<BaseType<any>, { id: number }>({
      query: ({ id }) => {
        return {
          url: `supplier/${id}/contacts`,
        };
      },
    }),
  }),
});

export const {
  useGetAllSupplierQuery,
  useLazyGetAllSupplierQuery,
  useGetSuppliersListForDropdownQuery,
  useLazyGetSuppliersListForDropdownQuery,
  useCreateSupplierMutation,
  useGetSupplierByIdQuery,
  useLazyGetSupplierByIdQuery,
  useGetSupplierContactsByIdQuery,
  useLazyGetSupplierContactsByIdQuery,
  useUpdateSupplierMutation,
} = supplierApi;
