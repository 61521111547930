import { useCallback, useState } from 'react';
import { MonetizationOn } from '@mui/icons-material';
import { ECBox, ECButton } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { StyleConstants } from 'styles/StyleConstants';
import { NewPOInvoiceForm } from './NewPOInvoiceForm';

export const NewPOInvoice = ({ purchaseOrder }) => {

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ECButton
        variant="outlined"
        onClick={() => setOpen(true)}
        startIcon={<MonetizationOn />}
      >
        Create Invoice
      </ECButton>

      <ECDrawerDetails
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={
              <NewPOInvoiceForm
                purchaseOrder={purchaseOrder}
                onClose={handleClose}
              />
            }
            showOnlyDetailsTab={true}
            onClose={() => setOpen(false)}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
