import { ECBox } from 'app/components/ECBox';
import { ECStepsContainer } from 'app/components/ECSteps';
import { ECTypography } from 'app/components/ECTypography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step2 } from './step-2';
import { Step1 } from './step-1';
import { Step3 } from './step-3';

interface EditProps {
  onClose?: () => void;
}

export const NewPOForm = ({ onClose }: EditProps) => {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const renderTabs = () => [
    {
      value: 1,
      label: '1',
      subLabel: 'Supplier & Order Items',
      content: (
        <Step1
          onClose={onClose}
          existingData={data}
          onSubmit={data => {
            setData(data);
            setCurrentStep(2);
          }}
        />
      ),
    },
    {
      value: 2,
      label: '2',
      subLabel: 'Shipping Details',
      content: (
        <Step2
          existingData={data}
          onClose={onClose}
          onSubmit={data => {
            setData(data);
            setCurrentStep(3);
          }}
        />
      ),
    },
    {
      value: 3,
      label: '3',
      subLabel: 'Review',
      content: <Step3 existingData={data} onClose={onClose} />,
    },
  ];

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox
        display="flex"
        justifyContent="start"
        px={4}
        py={2}
        bgcolor={theme => theme.palette.grey[400]}
      >
        <ECTypography variant="h4">Add Purchase Order</ECTypography>
      </ECBox>

      <ECStepsContainer
        stepsContainerProps={{
          px: 4,
          pt: 4,
        }}
        tabs={renderTabs()}
        currentStep={currentStep}
        onChangeStep={setCurrentStep}
        selectableSteps
      />
    </ECBox>
  );
};
