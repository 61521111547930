import { BoxProps } from '@mui/material';
import { BaseType } from 'types/BaseType';
import { Category } from 'types/Category';
import { ECBox } from '../ECBox';
import { ECCheckbox } from '../ECCheckbox';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECFormControlLabel } from '../ECForm';

interface ECDashboardCategoryPopoverContentProps
  extends Omit<BoxProps, 'onChange'> {
  selectedValues: number[];
  isLoading?: boolean;
  categories?: BaseType<Category[]>;
  onChange?: (selectedValuesIds: number[]) => void;
}

export const ECDashboardCategoryPopoverContent = ({
  selectedValues = [],
  categories,
  isLoading,
  onChange,
  ...boxProps
}: ECDashboardCategoryPopoverContentProps) => {
  if (isLoading) {
    return (
      <ECBox
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="center"
        width={400}
      >
        <ECCircularProgress size="2rem" />
      </ECBox>
    );
  }

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      maxWidth={400}
      minWidth={100}
      {...boxProps}
    >
      {categories?.data?.map((category: Category) => {
        const isChecked = selectedValues.includes(category.id);
        return (
          <ECFormControlLabel
            control={
              <ECCheckbox
                name={category.name}
                value={category}
                checked={isChecked}
                onChange={event => {
                  let newCheckedValues;
                  if (event.target.checked) {
                    newCheckedValues = [...selectedValues, category.id];
                  } else {
                    newCheckedValues = selectedValues.filter(
                      checkedValue => checkedValue !== category.id,
                    );
                  }
                  onChange?.(newCheckedValues);
                }}
              />
            }
            label={category.name}
          />
        );
      })}
    </ECBox>
  );
};
