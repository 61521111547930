import { CSSProperties, ChangeEvent, useMemo } from 'react';
import { ECTextField } from '../ECTextField';
import { formatZipCode } from 'utils/strings/zipcode';

interface Props {
  onChange?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  id: string;
  name: string;
  value?: string;
  variant?: 'standard' | 'filled';
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  error?: boolean;
  sx?: any;
  validationMessage?: string;
  hiddenLabel?: boolean;
  countryCode?: string;
}

export const ECZipCodeField = ({ countryCode = 'US', ...props }: Props) => {
  const {
    variant = 'standard',
    error,
    value,
    style,
    sx,
    validationMessage,
    readOnly,
    hiddenLabel = false,
  } = props;

  const formattedValue = useMemo(
    () => formatZipCode(value, countryCode),
    [value, countryCode],
  );

  return (
    <ECTextField
      {...props}
      value={formattedValue}
      error={error}
      helperText={validationMessage ? validationMessage : null}
      variant={variant}
      sx={{
        ...sx,
        ...style,
        cursor: readOnly ? 'pointer !important' : 'auto',
      }}
      InputLabelProps={value ? { shrink: true } : {}}
      hiddenLabel={hiddenLabel}
      inputProps={{
        readOnly,
      }}
    />
  );
};
