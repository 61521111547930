import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useCallback, useEffect, useState } from 'react';
import {
  useCopyTradeAssignmentToTradeIdsMutation,
  useGetTradesListQuery,
} from 'services/tradeApi';
import * as _ from 'lodash';

interface CopyProps {
  row: any;
  onClose?: () => void;
}

const formConfig = require('./fancy_form_config_copy.json');

export const Copy = ({ row, onClose }: CopyProps) => {
  const { data: trades, isSuccess: isSuccessTrades } = useGetTradesListQuery(
    {},
  );

  const [
    doCopyTradeAssignments,
    {
      isError: isCopyError,
      error: copyError,
      isSuccess: isCopySuccess,
      isLoading: isCopyLoading,
      reset,
    },
  ] = useCopyTradeAssignmentToTradeIdsMutation();

  const [formFields, setFormFields] = useState(formConfig.fields);

  useEffect(() => {
    if (isSuccessTrades) {
      const availableTradesToCopyTo = trades.data.filter(
        trade => trade.id !== row.trade.id,
      );
      let editSelectField = formConfig.fields.find(
        field => field.fieldName === 'toTrades',
      );
      editSelectField.options =
        availableTradesToCopyTo?.map(d => ({
          label: d.name,
          color: '',
        })) || [];
      editSelectField.optionValues =
        availableTradesToCopyTo?.map(d => d.name) || [];

      const updatedFormFields = [...formConfig.fields];
      const updatedIndex = updatedFormFields.findIndex(
        field => field.fieldName === 'toTrades',
      );
      updatedFormFields[updatedIndex] = editSelectField;

      setFormFields(updatedFormFields);
    }
  }, [trades, isSuccessTrades]);

  const useUpdate = useCallback(() => {
    const doCopy = async data => {
      const copyToTradeNames = _.map(data.toTrades, 'label');
      const tradeIds = copyToTradeNames.map(
        tradeName =>
          trades?.data.find(trade => trade.name === tradeName)?.id ?? 0,
      );

      doCopyTradeAssignments({
        branchId: row.branch.id,
        tradeIds,
        spRanks: row.sp.map(sp => ({
          companyId: sp.id,
          position: sp.position,
        })),
      });
    };

    return [
      doCopy,
      {
        isError: isCopyError,
        error: copyError,
        isLoading: isCopyLoading,
        isSuccess: isCopySuccess,
        reset,
      },
    ];
  }, [
    trades,
    row,
    isCopyError,
    copyError,
    isCopyLoading,
    isCopySuccess,
    reset,
    doCopyTradeAssignments,
  ]);

  return (
    <EditForm2
      row={row}
      formConfig={formConfig.config}
      formFields={formFields}
      useUpdateMutation={useUpdate}
      onClose={onClose}
      isLoading={false}
      hideToolBar
      onlyEdit
    />
  );
};
