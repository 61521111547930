import { SxProps } from '@mui/material';
import {
  ECLink,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHead,
  ECTablePagination,
  ECTableRow,
} from 'app/components';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { useCallback, useMemo, useState } from 'react';
import { camelCaseToPhrase } from 'utils/strings/camelcase-to-phrase';
import _ from 'lodash';

interface RowProps<T> {
  tableData: T;
}

interface Props<T> extends RowProps<T> {
  selected: any[];
  onSelect: (rows: any[]) => void;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (page: number) => void;
  sx?: SxProps;
  maxRows?: number;
  minRows?: number;
  hasPagination?: boolean;
  defaultRowsPerPage?: number;
}

interface BaseProps {
  module: string;
  moduleLink: string;
  totalCount: number;
  data: any[];
  cols: string[];
}

const baseUrl: string | undefined = process.env.REACT_APP_URL + 'panel/';

const MAX_CELL_WIDTH = 120;
const MIN_CELL_WIDTH = 80;

//TODO: fix row sizes: 36px sfor head, 32px for body
export default function MultiselectTable<T extends BaseProps>({
  sx,
  tableData,
  selected,
  maxRows,
  minRows,
  hasPagination,
  defaultRowsPerPage = 20,
  handlePageChange,
  handleRowsPerPageChange,
  onSelect,
}: Props<T>) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const isAllSelected = useMemo(() => {
    const allIds = tableData?.data?.map(entry => entry.rowId);
    return (
      selected.length > 0 &&
      allIds?.every(id => selected?.map(row => row.id).includes(id))
    );
  }, [selected, tableData]);

  const handleSelect = useCallback(
    (entry: any) => {
      const index = selected.find(s => s.id === entry.rowId);
      if (index) {
        onSelect(selected.filter(s => s.id !== entry.rowId));
      } else {
        onSelect([...selected, entry]);
      }
    },
    [onSelect, selected],
  );

  const onSelectAll = useCallback(() => {
    const allRows = tableData.data;
    let newSelectedRows: any[] = [];
    if (isAllSelected) {
      newSelectedRows = selected.filter(
        row => !allRows.map(s => s.id).includes(row.id),
      );
    } else {
      newSelectedRows = [
        ...selected,
        ...allRows.filter(row => !selected.map(s => s.id).includes(row.id)),
      ];
    }

    onSelect(newSelectedRows);
  }, [isAllSelected, onSelect, tableData, selected]);

  //Columns
  const cols = useMemo(
    () =>
      tableData.cols?.map(col => (
        <ECTableCell key={col}>{camelCaseToPhrase(col)}</ECTableCell>
      )),
    [tableData],
  );

  //Rows
  const rows = useMemo(() => {
    const dataForMaxRows = _.take(tableData.data, maxRows);
    const dataForMaxRowsAndMinRows = _.assign(
      _.fill(new Array(minRows), undefined),
      dataForMaxRows,
    );
    const fullData = tableData.data;

    const data = maxRows
      ? minRows
        ? dataForMaxRowsAndMinRows
        : dataForMaxRows
      : fullData;
    return data?.map((entry, index) => (
      <ECTableRow
        key={entry ? `${entry.id}-${entry.rowId}` : index}
        onClick={() => handleSelect(entry)}
        sx={{
          '&:hover': {
            backgroundColor: theme =>
              theme.palette.primary.outlinedHoverBackground,
            cursor: 'pointer',
          },
          height: '55px',
        }}
      >
        {entry ? (
          <>
            <ECTableCell
              sx={{
                width: '40px',
                minWidth: '40px',
                maxWidth: '40px',
                flexShrink: 0,
              }}
            >
              <ECCheckbox
                checked={selected.map(row => row.id).includes(entry.rowId)}
                onChange={() => handleSelect(entry)}
              />
            </ECTableCell>
            {Object.entries(entry)
              .filter(
                ([key]) => key !== 'rowId' && tableData.cols?.includes(key),
              )
              .map(([key, value]) => {
                const maxLength =
                  _.max([key?.toString()?.length, value?.toString()?.length]) ||
                  0;
                const calculatedWidth = Math.min(maxLength * 8, MAX_CELL_WIDTH);
                return (
                  <ECTableCell
                    key={`${key}-${value}`}
                    sx={{
                      maxWidth: `${calculatedWidth}px`,
                      minWidth:
                        calculatedWidth > MIN_CELL_WIDTH
                          ? `${calculatedWidth}px`
                          : '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {key === tableData.moduleLink ? (
                      <ECLink
                        href={`/panel/${tableData.module}/${
                          entry.rowId as string
                        }`}
                      >
                        {value as string | number}
                      </ECLink>
                    ) : (
                      (value as string | number)
                    )}
                  </ECTableCell>
                );
              })}
          </>
        ) : (
          <></>
        )}
      </ECTableRow>
    ));
  }, [handleSelect, maxRows, minRows, selected, tableData]);

  return (
    <ECTable sx={sx} size="small">
      <ECTableHead>
        {cols ? (
          <ECTableRow>
            <ECTableCell>
              <ECCheckbox
                // indeterminate={!isAllSelected && selected.length > 0}
                checked={isAllSelected}
                onChange={onSelectAll}
              />
            </ECTableCell>
            {cols}
          </ECTableRow>
        ) : null}
      </ECTableHead>
      <ECTableBody>{rows}</ECTableBody>
      {hasPagination && tableData.totalCount > 0 && (
        <ECTablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20, 25, 50, 100]}
          count={tableData.totalCount}
          onPageChange={(event, page) => {
            setPage(page);
            handlePageChange?.(page);
          }}
          onRowsPerPageChange={event => {
            const newRowsPerPage = parseInt(event.target.value);
            setPage(0);
            handlePageChange?.(0);
            setRowsPerPage(newRowsPerPage);
            handleRowsPerPageChange?.(newRowsPerPage);
          }}
          page={page}
        />
      )}
    </ECTable>
  );
}
