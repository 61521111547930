// custom history object to allow navigation outside react components
// See https://jasonwatmore.com/react-router-6-navigate-outside-react-components

import { Location, NavigateFunction } from 'react-router';

type HistoryType = {
  navigate?: NavigateFunction;
  location?: Location;
};

export const history: HistoryType = {
  navigate: undefined,
  location: undefined,
};
