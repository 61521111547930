import { ECTabContainer } from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { AdminUsersPage } from '..';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

export function UsersPage() {
  const { t } = useTranslation();
  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:panelDrawer.users')} - %s`}
        defaultTitle={t('translation:panelDrawer.users')}
      />

      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'management',
            label: t('translation:panelDrawer.user'),
            content: <AdminUsersPage />,
            scopes: [P.GetAllUsers],
            link: 'management',
          },
          {
            value: 'job-titles',
            label: t('translation:panelDrawer.jobTitles'),
            content: <Outlet />,
            scopes: [P.GetAllJobTitle],
            link: 'job-titles',
          },
          {
            value: 'notifications',
            label: t('translation:panelDrawer.notifications'),
            content: <Outlet />,
            link: 'notifications',
            scopesOptional: [P.GetUser],
          },
        ]}
      />
    </>
  );
}
