import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { useLazyGetCompanyServiceProvidersListQuery } from 'services/serviceProviderApi';
import { loadPageScopes } from 'utils/pageScopes';
import { NewInvite } from './NewInvite';
import { useEffect } from 'react';
import { themes } from 'styles/theme/themes';

const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function SPGridViewPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const match = useMatch('/panel/*');

  useEffect(() => {
    loadPageScopes('serviceProviders');
  }, []);

  const handleOpenDetails = row => {
    return row.moduleName && row.id
      ? navigate(`${match?.pathnameBase}/serviceprovider/${row.id}`)
      : undefined;
  };

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.serviceProvider.title')}
      showStatusActiveFilter
      showMissedVendorIdFilter
      useLazyGetListQuery={useLazyGetCompanyServiceProvidersListQuery}
      useCreateMutation={() => false}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      withDrawer={false}
      onRowClick={handleOpenDetails}
      customContent={{
        header: <NewInvite />,
      }}
      statusFilterInitialSelected
      enableExport
      shouldNotUseActiveFilter
      exportModuleName="serviceprovider"
      drawerTitleBarBGColor={themes.light.palette.other.divider}
    />
  );
}
