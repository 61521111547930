// Need to use the React-specific entry point to import createApi
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Reports'],
});

export const reportsApi = apiWithTag.injectEndpoints({
  endpoints: build => ({
    getReportsList: build.query({
      query: id => {
        return {
          url: `qsreport/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Reports'],
    }),

    getReportUrl: build.query({
      query: reportId => {
        return {
          url: `qsreport/report/${reportId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetReportsListQuery,
  useLazyGetReportsListQuery,
  useGetReportUrlQuery,
  useLazyGetReportUrlQuery,
} = reportsApi;
