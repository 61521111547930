import React, { useState } from 'react';
import { ECPopover } from '../ECPopover';
import { get } from 'lodash';
import { camelCaseToPhrase } from 'utils/strings/camelcase-to-phrase';
import { ECChip, ECTableCell, ECBox, ECTypography } from '..';

const ECChipTextTableCell = props => {
  const { col, row, customPopupHeader } = props;

  const [openDetails, setOpenDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const data = get(row, col.dataPath)?.replace(/<p>|<\/p>|&nbsp;|\n/g, '');
  const headerText = camelCaseToPhrase(col.dataPath?.split('.').pop());

  const handleChipClick = event => {
    const customPopupHeaderLength = customPopupHeader?.length || 0;
    const dataLength = data?.length || 0;
    if (dataLength + customPopupHeaderLength <= 25) return;
    event.stopPropagation();

    setOpenDetails(!openDetails);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpenDetails(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ECTableCell
        key={col.title}
        scope="row"
        sx={{ height: '100%', alignItems: 'center' }}
      >
        <ECChip
          color={openDetails ? 'Dark Blue' : '#EEE'}
          label={customPopupHeader ? `${customPopupHeader}: ${data}` : data}
          sx={{
            color: openDetails ? 'white' : 'black',
            fontWeight: '500',
            height: '30px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '14rem',
          }}
          onClick={handleChipClick}
          aria-describedby="text-details-modal"
        />
      </ECTableCell>
      <ECPopover
        id={'text-details-modal'}
        open={openDetails}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <ECBox
          p={2}
          sx={{
            width: '30rem',
          }}
        >
          <ECTypography variant="h6" textTransform="capitalize">
            {headerText}
          </ECTypography>
          <ECTypography variant="body1">{data}</ECTypography>
        </ECBox>
      </ECPopover>
    </React.Fragment>
  );
};

export default React.memo(ECChipTextTableCell);
