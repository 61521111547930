import { useCallback, useEffect, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECSelect } from '../ECSelect';
import { ECMenuItem } from '../ECSelect';
import { ECBasicTimePicker } from '../ECTimePicker';
import { formatHoursAndMinutes, formatHoursWithAmOrPm } from 'utils/dates';
import * as _ from 'lodash';
import { ECButton } from '../ECButton';
import { FormHelperText, InputLabel } from '@mui/material';
import { ECFormControl } from '../ECForm';
import { useTranslation } from 'react-i18next';

interface Props {
  values: any[];
  readOnly: boolean;
  field;
  baseSx;
  options;
  onChange;
  variant;
}

export const enum OperatingHoursEnum {
  MonFri = 'Mon-Fri',
  SatSun = 'Sat-Sun',
  Mon = 'Mon',
  Tues = 'Tues',
  Wed = 'Wed',
  Thru = 'Thru',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun',
}

const opHoursWeekDays = [
  OperatingHoursEnum.Mon,
  OperatingHoursEnum.Tues,
  OperatingHoursEnum.Wed,
  OperatingHoursEnum.Thru,
  OperatingHoursEnum.Fri,
];
const opHoursWeekendDays = [OperatingHoursEnum.Sat, OperatingHoursEnum.Sun];

export const ECOperatingHours: React.FC<Props> = ({
  values,
  field,
  baseSx,
  options,
  onChange,
  readOnly,
  variant,
}: Props) => {
  const { t } = useTranslation();

  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(prev => {
      let newOptions = _.cloneDeep(options);

      if (values.some(dt => dt.type.name === OperatingHoursEnum.MonFri)) {
        newOptions = newOptions.filter(no => !opHoursWeekDays.includes(no));
      }
      if (values.some(dt => dt.type.name === OperatingHoursEnum.SatSun)) {
        newOptions = newOptions.filter(no => !opHoursWeekendDays.includes(no));
      }
      if (values.some(dt => opHoursWeekDays.includes(dt.type.name))) {
        newOptions = newOptions.filter(no => no !== OperatingHoursEnum.MonFri);
      }
      if (values.some(dt => opHoursWeekendDays.includes(dt.type.name))) {
        newOptions = newOptions.filter(no => no !== OperatingHoursEnum.SatSun);
      }
      return newOptions;
    });
  }, [values]);

  const handleAddNew = useCallback(() => {
    const newData = [
      ...values,
      {
        endTimeHour: 17,
        endTimeMinute: 0,
        startTimeHour: 8,
        startTimeMinute: 0,
        type: { name: '' },
      },
    ];
    onChange?.(newData);
  }, [values]);

  const handleRemove = useCallback(
    index => {
      const newData = _.cloneDeep(values);
      newData.splice(index, 1);
      onChange?.(newData);
    },
    [values],
  );

  const handleSelectDays = useCallback(
    (e, index) => {
      const newData = _.cloneDeep(values);
      newData[index].type = { name: e.target.value };
      onChange?.(newData);
    },
    [values],
  );

  return !readOnly ? (
    <ECBox>
      {values?.map((opHour, index) => {
        const startHour = formatHoursAndMinutes(
          opHour.startTimeHour,
          opHour.startTimeMinute,
        );
        const endHour = formatHoursAndMinutes(
          opHour.endTimeHour,
          opHour.endTimeMinute,
        );

        const extraOptions =
          opHour.type.name === OperatingHoursEnum.MonFri
            ? opHoursWeekDays
            : opHour.type.name === OperatingHoursEnum.SatSun
              ? opHoursWeekendDays
              : [];

        const isPickedTimeInvalid = startHour > endHour;
        return (
          <ECBox display="flex" justifyContent="space-between" key={index}>
            <ECBasicTimePicker
              fieldName={t('translation:form.operatingHours.open')}
              variant={variant}
              error={field.isValid === false || isPickedTimeInvalid}
              helperText={
                isPickedTimeInvalid
                  ? t('translation:form.operatingHours.startValidationMessage')
                  : null
              }
              sx={{ ...baseSx, marginRight: '16px' }}
              label={t('translation:form.operatingHours.open')}
              inputProps={{
                max: endHour,
                step: 1800,
              }}
              value={startHour}
              onChange={time => {
                const splitedTime = time.target.value?.split(':');
                const newData = _.cloneDeep(values);
                newData[index].startTimeHour = splitedTime[0] * 1;
                newData[index].startTimeMinute = splitedTime[1] * 1;
                onChange?.(newData);
                return false;
              }}
            />

            <ECBasicTimePicker
              fieldName={t('translation:form.operatingHours.close')}
              variant={variant}
              error={field.isValid === false || isPickedTimeInvalid}
              helperText={
                isPickedTimeInvalid
                  ? t('translation:form.operatingHours.endValidationMessage')
                  : null
              }
              sx={{ ...baseSx, marginRight: '16px' }}
              label={t('translation:form.operatingHours.close')}
              inputProps={{
                min: startHour,
              }}
              value={endHour}
              onChange={time => {
                const splitedTime = time.target.value?.split(':');
                const newData = _.cloneDeep(values);
                newData[index].endTimeHour = splitedTime[0] * 1;
                newData[index].endTimeMinute = splitedTime[1] * 1;
                onChange?.(newData);
                return false;
              }}
            />

            <ECFormControl variant={variant} sx={{ width: '100%' }}>
              <InputLabel id={t('translation:form.operatingHours.days')}>
                {t('translation:form.operatingHours.days')}
              </InputLabel>
              <ECSelect
                variant="filled"
                value={typeof opHour === 'object' && opHour.type.name}
                sx={{ ...baseSx }}
                label={t('translation:form.operatingHours.days')}
                name={t('translation:form.operatingHours.days')}
                labelId={t('translation:form.operatingHours.days')}
                placeholder={t('translation:form.operatingHours.days')}
                onChange={e => handleSelectDays(e, index)}
                id={typeof opHour === 'object' && opHour.type.id}
                error={opHour.validationMessage ? true : false}
              >
                {[...filteredOptions, ...extraOptions].map(option => (
                  <ECMenuItem
                    key={option}
                    value={option}
                    disabled={values.some(x => x.type.name === option)}
                  >
                    {option}
                  </ECMenuItem>
                ))}
              </ECSelect>
              {opHour.validationMessage && (
                <FormHelperText
                  sx={theme => ({
                    color: theme.palette.error.main,
                  })}
                >
                  {opHour.validationMessage}
                </FormHelperText>
              )}
            </ECFormControl>

            {values.length >= 2 && (
              <ECBox
                minWidth={'100px'}
                display="flex"
                alignItems={'center'}
                justifyContent="center"
              >
                <ECButton variant="text" onClick={() => handleRemove(index)}>
                  {t('translation:form.operatingHours.remove')}
                </ECButton>
              </ECBox>
            )}
          </ECBox>
        );
      })}
      {(filteredOptions.length > values?.length || !values?.length) && (
        <ECBox>
          <ECButton
            variant="text"
            onClick={handleAddNew}
            disabled={values?.some(dat => !dat.type.name)}
          >
            {t('translation:form.operatingHours.addMoreHours')}
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  ) : (
    <ECBox display={'flex'} flexWrap={'wrap'}>
      {values?.map((opHour, index) => {
        return (
          <ECBox
            key={index}
            sx={{
              ...baseSx,
              width: '33% !important',
              paddingLeft: '16px',
              paddingTop: '16px',
            }}
          >
            <ECTextField
              sx={{ ...baseSx }}
              InputLabelProps={{ shrink: true }}
              label={opHour.type.name}
              variant={variant}
              value={`${formatHoursWithAmOrPm(
                opHour.startTimeHour,
                opHour.startTimeMinute,
              )} - ${formatHoursWithAmOrPm(
                opHour.endTimeHour,
                opHour.endTimeMinute,
              )}`}
            />
          </ECBox>
        );
      })}
    </ECBox>
  );
};
