import { emptyApi } from './emptyApi';

export const identityProviderApi = emptyApi.injectEndpoints({
  endpoints: builder => ({
    //     @Get("/get-idp-from-company-id")
    //     @RequirePermissions(PermissionEnum.GetAsset)
    //     async getIdpIdFromCompanyId(@Body() companyId: number) {
    //         return await this.identityProviderService.getIdpByCompanyId(companyId);
    //     }
    // }
    getIdentityProviderFromCompanyId: builder.query<any, any>({
      query: companyId => ({
        url: `identityprovider/company/${companyId}`,
      }),
    }),
  }),
});

export const { useGetIdentityProviderFromCompanyIdQuery } = identityProviderApi;
