import { useCallback, useEffect, useState } from 'react';
import {
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECLoadingProgress,
  ECPaper,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { themes } from 'styles/theme/themes';
import { useDispatch } from 'react-redux';
import { useUpdateMultipleStatusMutation } from 'services/dashboardApi';
import _ from 'lodash';
import { populateDropDownFields } from 'utils/pageUtils';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import { workOrdersApi } from 'services/workOrdersApi';
import { QueryTag } from '../DashboardPage/actions';
import { BulkStatusActions } from 'types/WorkflowStatus';

interface BulkEditDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows?: any[];
}

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');
const fancyFormElementsBulkEditDone = require('./fancy_form_config_bulk_done.json');

export const BulkEditDrawer = ({
  isOpen,
  selectedRows,
  onClose,
}: BulkEditDrawerProps) => {
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState<any[]>(
    _.cloneDeep(fancyFormElementsBulkEdit.fields),
  );

  const [isDone, setIsDone] = useState(false);
  const [comment, setComment] = useState(null);
  const [actionStatus, setActionStatus] = useState<BulkStatusActions>(
    BulkStatusActions.None,
  );

  const queryTag = QueryTag.PendingWorkorders;

  const [doneFields, setDoneFields] = useState<any[]>(
    fancyFormElementsBulkEditDone.fields,
  );

  const [
    doStatusChangeMutation,
    { data: updateStausData, isSuccess, isError, isLoading, reset },
  ] = useUpdateMultipleStatusMutation();

  useEffect(() => {
    if (isOpen) {
      const updated = [...formFields];
      const woCountLabelField = updated?.find(
        field => field.fieldName === 'woCountLabel',
      );
      if (woCountLabelField) {
        woCountLabelField.label =
          'Work Orders Selected: ' + selectedRows?.length;
      }
      const selectedWOsField = updated?.find(
        field => field.fieldName === 'selectedWOs',
      );
      selectedWOsField.value =
        selectedRows?.map(d => ({
          label: d.id,
          color: 'Grey',
          id: d.id,
        })) || [];

      setFormFields(updated);
    }
  }, [isOpen, selectedRows]);

  useEffect(() => {
    const localEditFormElements = [...formFields];
    let radioFields = localEditFormElements?.find(
      field => field.fieldName === 'sectionGroup',
    )?.options;
    let statusGroupSubFields = radioFields?.find(
      field => field.groupName === 'statusGroup',
    )?.fields;
    populateDropDownFields({
      responseData: [
        'Duplicate Work Order',
        'Issue Already Addressed',
        'Outside Service Area',
        'Technician is Unavailable',
        'Payment Terms / Issue',
        'Other',
      ].map(value => ({ name: value, id: value })),
      createFormFields: statusGroupSubFields,
      dataOptionsFieldName: 'name',
      dataValuesFieldName: 'id',
      formFieldName: 'reason',
    });
    setFormFields(localEditFormElements);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    dispatch(workOrdersApi.util.invalidateTags([queryTag]));
    setIsDone(false);
    onClose?.();
    setFormFields(_.cloneDeep(fancyFormElementsBulkEdit.fields));
    setComment(null);
    reset();

    return {
      data: null,
      isError: false,
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  }, [onClose, reset, dispatch, workOrdersApi.util, queryTag, setIsDone]);

  const useDone = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [dispatch, setIsDone, workOrdersApi.util, reset, onClose, queryTag]);

  const useBulkEdit = useCallback(() => {
    const bulkEdit = async data => {
      delete data.undefined;
      const woIds = data?.selectedWOs?.map(wo => wo.id);
      const params: any = {
        itemIds: woIds,
      };

      setComment(data?.changeStatusReason || data?.reason);
      const statusUpdateList = {
        declineReason: data?.changeStatusReason || data?.reason,
        itemIds: woIds,
        statusGroupName:
          data?.statusOperationType?.toLowerCase() === 'accept'
            ? WorkflowStatusGroupName.Accepted
            : WorkflowStatusGroupName.Unassigned,
      };
      params.declineReason = data?.changeStatusReason || data?.reason;

      params.statusGroupName =
        data?.statusOperationType?.toLowerCase() === 'accept'
          ? WorkflowStatusGroupName.Accepted
          : WorkflowStatusGroupName.Unassigned;

      setActionStatus(
        statusUpdateList?.statusGroupName === WorkflowStatusGroupName.Accepted
          ? BulkStatusActions.Accepted
          : BulkStatusActions.Rejected,
      );
      doStatusChangeMutation({
        module: 'workorder',
        statusUpdateList: params,
      });
    };
    return [
      bulkEdit,
      {
        updateStausData,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [doStatusChangeMutation, updateStausData, isSuccess, isError, isLoading]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'statusOperationType') {
      const fieldOptions = output?.find(
        field => field.fieldName === 'sectionGroup',
      );
      const statusGroupFields = fieldOptions?.options?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      const statusField = statusGroupFields?.find(
        field => field.fieldName === 'statusOperationType',
      );

      const isSelectedStatusReject = statusField?.value === 'Reject';

      const changeStatusReasonField = statusGroupFields?.find(
        field => field.fieldName === 'reason',
      );
      changeStatusReasonField.required = isSelectedStatusReject;
      changeStatusReasonField.visible = isSelectedStatusReject;

      if (!isSelectedStatusReject) {
        const changeStatusReasonDescriptionField = statusGroupFields?.find(
          field => field.fieldName === 'changeStatusReason',
        );

        changeStatusReasonDescriptionField.required = isSelectedStatusReject;
        changeStatusReasonDescriptionField.visible = isSelectedStatusReject;
        changeStatusReasonDescriptionField.value = null;
        changeStatusReasonField.value = null;
      }
    }
    if (fieldName === 'reason') {
      const fieldOptions = output?.find(
        field => field.fieldName === 'sectionGroup',
      );
      const statusGroupFields = fieldOptions?.options?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      const changeStatusReasonField = statusGroupFields?.find(
        field => field.fieldName === 'reason',
      );

      const isSelectedReasonOther = changeStatusReasonField?.value === 'Other';

      const changeStatusReasonDescriptionField = statusGroupFields?.find(
        field => field.fieldName === 'changeStatusReason',
      );

      changeStatusReasonDescriptionField.required = isSelectedReasonOther;
      changeStatusReasonDescriptionField.visible = isSelectedReasonOther;
    }
  };

  const isLoadingDrawer = isLoading;

  useEffect(() => {
    let operationRows = (selectedRows || []).map(
      ({ id, flags, workflowStatus, request }) => ({
        itemId: id,
        statusTo: actionStatus,
        flagsFrom: flags,
        statusFrom: workflowStatus.name,
        categoryIdFrom: request?.category?.id,
        CategoryNameFrom: request?.category?.name,
      }),
    );
    if (actionStatus === BulkStatusActions.Rejected) {
      operationRows = operationRows.map(row => ({
        ...row,
        rejectionReason: comment,
      }));
    }
    if (isSuccess && !isLoading) {
      const rejectApprovePerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                statusTo: actionStatus,
                statusOutcome: success,
                statusFailReason: success ? undefined : reason,
              }
            : row,
        );
      };
      updateStausData?.success?.forEach(itemId => {
        operationRows = rejectApprovePerform(itemId, true);
      });

      updateStausData?.failed?.forEach(item => {
        operationRows = rejectApprovePerform(item?.id, false, item?.reason);
      });

      const updatedFields = [...doneFields];
      const bulkActivityViewField = updatedFields?.find(
        field => field.fieldName === 'bulkActivityView',
      );
      bulkActivityViewField.value = operationRows;
      setDoneFields(updatedFields);
      setIsDone(true);
      reset();
    }
  }, [
    updateStausData,
    isError,
    isLoading,
    isSuccess,
    onClose,
    reset,
    selectedRows,
    doneFields,
  ]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{ height: '100%', paddingTop: '80px', boxShadow: 'none' }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={isDone ? useDone : useBulkEdit}
          formConfig={
            isDone
              ? fancyFormElementsBulkEditDone.config
              : fancyFormElementsBulkEdit.config
          }
          formFields={isDone ? doneFields : formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          CustomLoadingScreen={<ECLoadingProgress linearWithLabel />}
          CustomErrorScreen={<ECLoadingProgress linearWithLabel error />}
          CustomSuccessScreen={<ECLoadingProgress linearWithLabel success />}
          isLoading={isLoadingDrawer}
          onChange={handleOutputChange}
          hideCancelButton={isDone}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
