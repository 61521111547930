import {
  ECBox,
  ECEasyTable,
  ECGrid,
  ECStack,
  ECTextField,
  ECList,
  ECListItemText,
  ECListItemButton,
  ECEasyTableConfigType,
  initTableConfig,
  ECPaper,
  ECEasyTableDataRequestParamsType,
  NAV_BAR_HEIGHT_PX,
  TABS_HEIGHT_IN_PX,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetTradesListQuery,
  useLazyGetTradesListQuery,
  useLazyGetTradeAssignmentByTradeIdQuery,
} from 'services/tradeApi';
import { TradeTypes } from 'types';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { Copy } from './Copy';
import { Edit } from './Edit';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { setFilterEndpoint } from 'store/slice/page';

import { useGetCompanyServiceProvidersDropdownListQuery } from 'services/serviceProviderApi';
import { StyleConstants } from 'styles/StyleConstants';

export function TradeAssignmentByTradePage() {
  const { t } = useTranslation();

  const [listHeight, setListHeight] = useState(0);

  const [tradeTrigger, tradehResult] = useLazyGetTradesListQuery();
  const { data: tradesData, isSuccess: isSuccessTrades } = tradehResult;

  const [trigger, result] = useLazyGetTradeAssignmentByTradeIdQuery();

  const {
    data: tradeAssigmnents,
    isSuccess: isSuccessTradeAssigments,
    isLoading,
  } = result;

  const [selectedTrade, setSelectedTrade] = useState<TradeTypes | undefined>(
    undefined,
  );
  const [trades, setTrades] = useState<TradeTypes[] | undefined>(undefined);
  const [configState, setConfigState] =
    useState<ECEasyTableConfigType>(initTableConfig);
  const [search, setSearch] = useState('');
  const [row, setRow] = useState<any>();
  const [openedDrawer, setOpenedDrawer] = useState<'edit' | 'copy' | undefined>(
    undefined,
  );
  const [params, setParams] = useState<ECEasyTableDataRequestParamsType>({
    pagination: {
      currentPage: 0,
      perPage: 50,
    },
    sort: {
      fieldName: '',
      value: '',
    },
  });

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  useEffect(() => {
    if (isSuccessTradeAssigments) {
      setConfigState(
        getConfigStateFromApiOrMock(tradeAssigmnents, configState, t),
      );
    }
  }, [isSuccessTradeAssigments, tradeAssigmnents]);

  useEffect(() => {
    if (isSuccessTrades) {
      setSelectedTrade(tradesData?.data[0]);
      setTrades(tradesData?.data);
    }
  }, [tradesData, isSuccessTrades]);

  const handleRowPress = (row: any) => {
    setRow(row);
    handleOpenEditDrawer();
  };

  const handleDuplicatePress = (row: any) => {
    setRow(row);
    handleOpenCopyDrawer();
  };

  const handleTradeSelect = (trade: TradeTypes) => {
    setSelectedTrade(trade);
  };

  useEffect(() => {
    if (selectedTrade?.id) {
      trigger({
        id: selectedTrade.id,
        t: params.pagination.perPage,
        p: params.pagination.currentPage,
        o: params.sort?.value === 'ASC' ? 'a' : 'd',
        ob: params.sort?.fieldName || 'brnch.name',
        sb: activeFilter?.filterFields?.[0]?.fieldName,
        s: activeFilter?.filterFields?.[0]?.fieldName
          ? `*${activeFilter?.filterFields?.[0]?.value
              ?.toString()
              ?.toLowerCase()}*`
          : '',
      });
    }
  }, [selectedTrade, params, activeFilter]);

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    tradeTrigger({
      p: 0,
      t: params.pagination.perPage * 3,
      o: 'd',
      ob: '',
      sb: 'trd.name',
      s: `*${search}*`,
    });
  }, [search]);

  const handleCloseDrawer = () => setOpenedDrawer(undefined);
  const handleOpenEditDrawer = () => setOpenedDrawer('edit');
  const handleOpenCopyDrawer = () => setOpenedDrawer('copy');

  const handleDataRequest = useCallback(
    (params?: ECEasyTableDataRequestParamsType) => {
      if (params) {
        setParams(params);
      }
    },
    [],
  );

  const {
    data: allServiceProviders,
    isSuccess: isSuccessAllSPs,
    isLoading: isLoadingAllSps,
  } = useGetCompanyServiceProvidersDropdownListQuery({
    t: 0,
    st: 1,
    ob: 'cmpny.name',
  });

  return (
    <ECBox display="flex" flexDirection="column" flex={1}>
      <ECGrid mt={0} overflow="hidden" container spacing={1}>
        <ECGrid mt={0} pt={0} item xs={3}>
          <ECBox p={2}>
            <ECTextField
              fullWidth
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          </ECBox>
          <ECStack
            minHeight={
              window.innerHeight - NAV_BAR_HEIGHT_PX - TABS_HEIGHT_IN_PX
            }
            height={listHeight}
            overflow="auto"
            direction="column"
            spacing={2}
          >
            <ECList noPadding>
              {trades?.map(coreTrade => (
                <ECListItemButton
                  selected={selectedTrade?.id === coreTrade.id}
                  onClick={() => handleTradeSelect(coreTrade)}
                >
                  <ECListItemText primary={coreTrade.name} />
                </ECListItemButton>
              ))}
            </ECList>
          </ECStack>
        </ECGrid>

        <ECDrawerDetails
          open={!!openedDrawer}
          anchor="right"
          onClose={handleCloseDrawer}
        >
          <ECPaper
            sx={{
              height: '100%',
              paddingTop: StyleConstants.NAV_BAR_HEIGHT,
              boxShadow: 'none',
            }}
            role="presentation"
          >
            {openedDrawer === 'edit' && (
              <Edit
                allServiceProviders={allServiceProviders}
                isSuccessAllSPs={isSuccessAllSPs}
                row={row}
                onClose={handleCloseDrawer}
                isLoadingAllSps={isLoadingAllSps}
              />
            )}
            {openedDrawer === 'copy' && (
              <Copy row={row} onClose={handleCloseDrawer} />
            )}
          </ECPaper>
        </ECDrawerDetails>

        <ECGrid item xs={9} p={2}>
          {isSuccessTradeAssigments && configState.cols.length > 0 && (
            <ECEasyTable
              config={configState}
              data={tradeAssigmnents?.data}
              onDataRequest={handleDataRequest}
              isLoading={isLoading}
              onRowPress={handleRowPress}
              onDuplicatePress={handleDuplicatePress}
              onLayout={(height, _) => setListHeight(height)}
              enableExport
              exportModuleName="tradeassignmentbytrade"
              currentQueryParams={{
                p: params.pagination.currentPage,
                t: params.pagination.perPage,
                o: params.sort.value === 'ASC' ? 'a' : 'd',
                ob: params.sort?.fieldName || 'brnch.name',
                sb: activeFilter?.filterFields?.[0]?.fieldName,
                s: activeFilter?.filterFields?.[0]?.fieldName
                  ? `*${activeFilter?.filterFields?.[0]?.value
                      ?.toString()
                      ?.toLowerCase()}*`
                  : '',
              }}
              exportTradeId={selectedTrade?.id}
              shouldNotUseActiveFilter
            />
          )}
        </ECGrid>
      </ECGrid>
    </ECBox>
  );
}
