export function formatPhone(phone?: string, countryCode?: string | null) {
  if (!phone) return '';
  let normalizedPhone = phone.replace(/\D/g, '');
  // Mexico
  if (countryCode === 'MX') {
    if (normalizedPhone.length > 10) {
      normalizedPhone = normalizedPhone.slice(0, 10);
    }

    if (normalizedPhone.length >= 6) {
      normalizedPhone = normalizedPhone.replace(
        /(\d{2})(\d{4})(\d*)/,
        '$1 $2 $3',
      );
    } else if (normalizedPhone.length >= 2) {
      normalizedPhone = normalizedPhone.replace(/(\d{2})(\d+)/, '$1 $2');
    }
    return normalizedPhone;
  }

  if (countryCode === 'US') {
    if (normalizedPhone.length > 10) {
      normalizedPhone = normalizedPhone.slice(0, 10);
    }

    if (normalizedPhone.length >= 7) {
      normalizedPhone = normalizedPhone.replace(
        /(\d{3})(\d{3})(\d*)/,
        '($1) $2-$3',
      );
    } else if (normalizedPhone.length >= 4) {
      normalizedPhone = normalizedPhone.replace(/(\d{3})(\d+)/, '($1) $2');
    }
  }
  return normalizedPhone;
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  let formattedPhone = '';
  let patternIndex = 0;
  for (let i = 0; i < cleanedPhoneNumber.length; i++) {
    if (patternIndex >= phonePattern.length) {
      break;
    }

    if (phonePattern[patternIndex] === '#') {
      formattedPhone += cleanedPhoneNumber[i];
      patternIndex++;
    } else {
      formattedPhone += phonePattern[patternIndex];
      patternIndex++;
      formattedPhone += cleanedPhoneNumber[i];
      patternIndex++;
    }
  }

  return formattedPhone;
};

export const cleanedPhoneNumber = (
  phoneNumber: string | undefined | null,
): string => {
  if (phoneNumber === null || phoneNumber === undefined) {
    return '';
  }
  const tempCleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const cleanedPattern = phonePattern.replace(/[^#]/g, '');

  if (tempCleanedPhoneNumber.length === cleanedPattern.length) {
    return tempCleanedPhoneNumber;
  } else if (tempCleanedPhoneNumber.length > cleanedPattern.length) {
    return tempCleanedPhoneNumber.slice(0, cleanedPattern.length);
  }
  return '';
};

export const phonePattern = '###-###-####';
