import React, { useReducer } from 'react';

type Action =
  | { type: 'expand'; payload: State }
  | { type: 'close'; payload?: State };
type Dispatch = (action: Action) => void;
type State = { expandedItemTitle?: string };

const PanelDrawerSecondLevelExpandedItemContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function panelDrawerSecondLevelExpandedItemReducer(
  state: State,
  action: Action,
): State {
  switch (action.type) {
    case 'expand': {
      return { ...state, ...action.payload };
    }
    case 'close': {
      return { ...state, expandedItemTitle: undefined };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface PanelDrawerSecondLevelCollapsibleItemsProviderProps {
  children: React.ReactNode;
}

export const PanelDrawerSecondLevelCollapsibleItemsProvider = ({
  children,
}: PanelDrawerSecondLevelCollapsibleItemsProviderProps) => {
  const [state, dispatch] = useReducer(
    panelDrawerSecondLevelExpandedItemReducer,
    {
      expandedItemTitle: undefined,
    },
  );

  const value = { state, dispatch };

  return (
    <PanelDrawerSecondLevelExpandedItemContext.Provider value={value}>
      {children}
    </PanelDrawerSecondLevelExpandedItemContext.Provider>
  );
};

export const usePanelDrawerSecondLevelExpandedItem = () => {
  const context = React.useContext(PanelDrawerSecondLevelExpandedItemContext);

  if (context === undefined) {
    throw new Error(
      'usePanelDrawerSecondLevelExpandedItem must be used within a PanelDrawerSecondLevelCollapsibleItemsProvider',
    );
  }

  return context;
};
