import { TradeAssignmentActivity } from './TradeAssignmentActivity';
import { ECDrawerDetails } from '../ECDrawerDetails';
import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { useGetTradeAssignmentActivitiesQuery } from 'services/historyApi';
import { useMemo } from 'react';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { StyleConstants } from 'styles/StyleConstants';

export const ECTradeAssignmentActivities = ({
  isActivityDrawerOpen,
  onClose,
}: {
  isActivityDrawerOpen: boolean;
  onClose: () => void;
}) => {
  const companyId = useMemo(() => {
    const companyProfile = localStorage.getItem('userProfile');
    if (!companyProfile) return null;
    return JSON.parse(companyProfile)?.company?.id;
  }, []);

  const { data: tradeAssignmentActivities, isLoading } =
    useGetTradeAssignmentActivitiesQuery({
      companyId: companyId,
      skip: !companyId,
    });

  return (
    <ECDrawerDetails
      open={isActivityDrawerOpen}
      anchor="right"
      onClose={onClose}
    >
      <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
        <>
          <ECBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 'none',
            }}
          >
            <ECBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              sx={{
                backgroundColor: theme => theme.palette.other.divider,
                height: StyleConstants.DRAWER_TITLE_HEIGHT,
              }}
            >
              <ECTypography variant={'h4'}>Trade Assignment</ECTypography>
              <ECIconButton
                sx={{ background: '#fff' }}
                squared
                onClick={onClose}
              >
                <Close />
              </ECIconButton>
            </ECBox>
            <ECBox
              px={3}
              pb={4}
              mt={3}
              display="flex"
              flexDirection="column"
              rowGap={4}
            >
              {isLoading && <ECCircularProgress sx={{ marginX: 'auto' }} />}

              {tradeAssignmentActivities?.data?.map((tradeEntry, index) => (
                <TradeAssignmentActivity
                  key={index}
                  entry={{
                    ...tradeEntry._doc,
                  }}
                />
              ))}
            </ECBox>
          </ECBox>
        </>
      </ECBox>
    </ECDrawerDetails>
  );
};
