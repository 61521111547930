import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ECBox } from 'app/components';

type SortableWorkOrderCardItemProps = {
  children: React.ReactNode;
  id: number;
};

const SortableWorkOrderCardItem = ({
  children,
  id,
}: SortableWorkOrderCardItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <ECBox ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </ECBox>
  );
};

export default SortableWorkOrderCardItem;
