import { ECDynamicPageTemplate } from 'app/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreatePrioritiesMutation,
  useLazyGetPrioritiesListQuery,
  useUpdatePrioritiesMutation,
} from 'services/prioritiesApi';
import { loadPageScopes } from 'utils/pageScopes';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function PrioritiesPage() {
  const { t } = useTranslation();

  useEffect(() => {
    loadPageScopes('priorities');
  }, []);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.priorities.title')}
      useLazyGetListQuery={useLazyGetPrioritiesListQuery}
      showStatusActiveFilter={true}
      useCreateMutation={useCreatePrioritiesMutation}
      useUpdateMutation={useUpdatePrioritiesMutation}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      showEditButton={true}
      hasDetails={true}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      shouldNotUseActiveFilter
      showDrawerDetailTitle={false}
    />
  );
}
