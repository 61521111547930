import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import { ApprovalRoleType, ApprovalSteps } from './Approval';
import { JobTitleList } from './JobTitle';
import { StatusGroupNames } from './StatusGroups';

export interface WorkflowStatusUpdate {
  id: number;
  statusTo?: number;
  actionId?: number;
  declineTypeId?: number;
  declineReason?: string;
  note?: string;
  recipientIds?: number[];
  statusGroupName?: string;
}

export interface StatusUpdateByGroup {
  statusGroupName: StatusGroupNames;
  moduleItemId: number;
  approvalStepId: number;
  note?: string;
}

export interface ApprovalProcessAction {
  statusToGroupName: StatusGroupNames;
  moduleId: number;
  approvalProcessActionId: number;
  note?: string;
}

export interface WorkflowStepActionUpdate {
  id: number;
  approvalProcessStepId: number;
  approvalProcessId: number;
  statusTo: number;
  approvalProcessActionId: number;
  workflowLinkId: number;
}

export interface WorkflowStatus {
  id: number;
  name: string;
  group: WorkflowStatusGroup;
}

export interface WorkflowStatusGroup {
  name: WorkflowStatusGroupName;
}

interface WorkflowStepAction {
  id: number;
  name: string;
  approvalRoleTypeId: number;
  workflowActionId: number;
}

interface WorkflowSteps extends ApprovalSteps {
  id: number;
  jobTitle: JobTitleList;
  approvalRoleType: ApprovalRoleType;
  approvalProcessId: number;
  actions: WorkflowStepAction[];
}

export interface WorkflowNextStatus {
  name: string;
  actionId?: number;
  statusTo?: number;
  color?: string;
  icon?: string;
  groupName?: string;
  statusGroupName?: string;
}

export enum WorkflowChangeType {
  Status,
  Action,
}

export enum ActionId {
  REJECT = 2,
}

export interface BulkApprovalStatusUpdateAction {
  itemIds: number[];
  actionId?: number;
  statusTo?: number;
  statusGroupName?: string;
  latitude?: number;
  longitude?: number;
  declineTypeId?: number;
  declineReason?: string;
  note?: string;
  recipientsIds?: [];
}

export enum StatusActions {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  NOTFIXED = 'NOT FIXED',
}

export enum BulkStatusActions {
  Approved = 'Approved',
  Rejected = 'Rejected',
  None = 'none',
  Accepted = 'Accepted',
}

export const StatusActionsOptions = {
  None: { id: 0, label: 'None' },
  Approve: { id: 1, label: 'Approve' },
  Reject: { id: 2, label: 'Reject' },
};
export interface BulkActionStatusUpdateResponse {
  success: number[];
  failed: { id: number; reason: string }[];
}

export enum FlagBulkEditOptionEnum {
  'Add Selected Flags' = 1,
  'Override with Selected Flags' = 2,
  'Remove Selected Flags' = 3,
}

export enum PrposalActionEnum {
  Approve = 'approve',
  Reject = 'reject',
  Comment = 'comment',
}
