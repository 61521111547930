import { useEffect, useMemo, useState } from 'react';
import { ECStack } from '..';
import { ECBox } from '../ECBox';
import { ECHistoryEntry } from '../ECHistoryEntry';
import { ECTypography } from '../ECTypography';
import { ECCircularProgress } from '../ECCircularProgress';
import { useLazyGetHistoryListQuery } from 'services/historyApi';
import { capitalizeFirstLetter } from 'utils/strings/capitalize-first-letter';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';
import * as _ from 'lodash';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useCustomerUser } from 'app/hooks';
import { requirePermissionWrapper } from 'app/hoc/require-permission';

export interface HistoryTabProps {
  sx?: any;
  row?: any;
  moduleName?: string;
  moduleNameToFecthDetails?: string;
  idAttributeName?: string;
}

const HISTORY_POLLING_INTERVAL = 30 * 1000; // 30 seconds
const ACTIVITY_PAGE_SIZE = 4;

const HistoryContent = ({
  row,
  moduleName,
  moduleNameToFecthDetails,
  idAttributeName = 'id',
}: HistoryTabProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  const [moduleResourceId, setModuleResourceId] = useState<string | undefined>(
    undefined,
  );
  const [page, setPage] = useState(1);

  const { ref, inView } = useInView();

  const { data: moduleData } = useGetModuleDetailsByIdQuery(
    {
      id: id || '',
      moduleName: `${isCustomer ? '' : 'sp/'}${
        moduleNameToFecthDetails || moduleName || ''
      }`,
    },
    {
      skip: !id,
    },
  );

  const isModuleStatusApproved = useMemo(() => {
    if (!moduleData) return false;
    return (
      moduleData.workflowStatus?.name?.toLowerCase() === 'approved' ||
      moduleData.workflowStatusName?.toLowerCase() === 'approved'
    );
  }, [moduleData]);

  const [trigger, result] = useLazyGetHistoryListQuery({
    pollingInterval: HISTORY_POLLING_INTERVAL,
  });

  useEffect(() => {
    setModuleResourceId(
      _.get(moduleData ?? row, idAttributeName, moduleData?.id),
    );
  }, [idAttributeName, moduleData]);

  useEffect(() => {
    if (moduleName && moduleResourceId) {
      trigger({
        moduleName,
        moduleResourceId,
        t: ACTIVITY_PAGE_SIZE * page,
      });
    }
  }, [moduleResourceId, trigger, page]);

  const { data: history, isLoading: isLoadingHistory } = result;

  useEffect(() => {
    if (inView) {
      setPage(prevPage => prevPage + 1);
    }
  }, [inView]);

  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.paper}
      flexDirection="column"
      p={3}
    >
      <ECStack
        direction="column"
        spacing={4}
        maxHeight="75vh"
        overflow="auto"
        pr={1}
        pb={1}
      >
        {isLoadingHistory && <ECCircularProgress />}
        {!isLoadingHistory &&
          !!history &&
          history.data?.length > 0 &&
          history.data?.map((historyEntry, index) => (
            <ECHistoryEntry
              key={historyEntry._doc?.id.toString()}
              moduleName={capitalizeFirstLetter(moduleName)}
              entry={historyEntry?._doc}
              previousBody={historyEntry._doc?.beforeBody}
              showVerticalDivider={index !== 0}
              isApproved={isModuleStatusApproved}
            />
          ))}
        {!!history?.data?.length &&
          page * ACTIVITY_PAGE_SIZE <
            history?.config?.pagination?.totalCount && (
            <ECBox display="flex" justifyContent="center" ref={ref} height={70}>
              <ECCircularProgress sx={{ margin: 1 }} size="2rem" />
            </ECBox>
          )}
        {!isLoadingHistory && !!history && history.data?.length === 0 && (
          <ECTypography variant="subtitle2">
            {t('translation:historyTab.empty')}
          </ECTypography>
        )}
      </ECStack>
    </ECBox>
  );
};

export const HistoryTab =
  requirePermissionWrapper<HistoryTabProps>(HistoryContent);
