import {
  ECBox,
  ECGrid,
  ECStack,
  ECEasyTableConfigType,
  initTableConfig,
  ECDynamicPageTemplate,
  ECButton,
  ECTypography,
  ECChip,
  ECFormControlLabel,
  ECTextField,
  ECChipAutocomplete,
  ECRadio,
  ECTree,
  TreeNode,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { useGetOrganizationListQuery } from 'services/organizationApi';
import {
  useGetUserAssignedLocationsOptionQuery,
  useGetUserByIdQuery,
  useUpdateUserOrganizationsMutation,
  useLazyGetUserAssignedLocationsQuery,
  useGetUserAssignedLocationsQuery,
  useGetAssignedOrganizationsQuery,
  useLazyGetCurrentUserBranchesQuery,
} from 'services/userApi';
import { ECModal } from 'app/components/ECModal';
import _ from 'lodash';
import { Alert } from '@mui/material';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { findNode, mapOrganizationToTreeNode } from 'utils/tree';
import { EmptyType } from '../EmptyStatePage';

const SEARCH_DEBOUNCE_TIME = 500;

interface LocationAssignmentProps {
  onlyEdit?: boolean;
  onClose?: () => void;
  userId?: string;
  userFullName?: string;
}

const LocationAssignmentContent = ({
  onlyEdit: isEditModeFromProps = false,
  onClose: onCloseFromProps = undefined,
  userId = '',
  userFullName = '',
}: LocationAssignmentProps) => {
  const props: any = useOutletContext();
  const {
    onlyEdit: isEditModeFromOutletContext,
    onClose: onCloseFromOutletContext,
  } = props;

  const isEditMode = isEditModeFromProps || isEditModeFromOutletContext;
  const onClose = onCloseFromProps || onCloseFromOutletContext;

  const { id } = useParams();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data: user } = useGetUserByIdQuery(id ? +id : 0);
  const { data: options } = useGetUserAssignedLocationsOptionQuery({
    userId: user?.id ?? (id ? +id : 0),
    companyId: user?.companyId ?? 0,
  });

  const [tree, setTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [initialOrgIds, setInitialOrgIds] = useState<any[]>([]);
  const [orgIds, setOrgIds] = useState<TreeNode[]>([]);
  const [branchIds, setBranchIds] = useState<TreeNode[]>([]);
  const [newSelectedBranches, setNewSelectedBranch] =
    useState<{ name: string; id: number }[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAssignOption, setSelectedAssignOption] = useState('');

  useEffect(() => {
    if (options?.assignedToAll) {
      setSelectedAssignOption('assignToAll');
    } else if (options?.assignedToOrg) {
      setSelectedAssignOption('assignToOrg');
    } else if (options?.assignedToSpecific) {
      setSelectedAssignOption('assignToSpecific');
    }
  }, [options, isEditMode]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/branch-assignment'));
  }, []);

  const { data: userOrgs, isSuccess: isSuccessAssignedOrganizations } =
    useGetAssignedOrganizationsQuery(user?.id ?? userId);

  const { data: userBranches, isSuccess: isSuccessAssignedLocations } =
    useGetUserAssignedLocationsQuery({
      id: user?.id || (_.isEmpty(userId) ? 0 : +userId),
      queryParams: {
        t: 0,
      },
    });

  const { data: orgTree, isSuccess: isSuccessOrgTree } =
    useGetOrganizationListQuery();

  const [updateOrgs, resultUpdateOrgs] = useUpdateUserOrganizationsMutation();
  const [initialSelectedLeafs, setInitialSelectedLeafs] = useState<any[]>([]);
  const [selectedLeafs, setSelectedLeafs] = useState<any[]>([]);

  const {
    isLoading: isLoadingUpdateOrgs,
    isSuccess: isSuccessUpdateOrgs,
    reset: resetUpdateOrgs,
  } = resultUpdateOrgs;

  useEffect(() => {
    if (isSuccessUpdateOrgs) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `User ${t('translation:dynamicForm.updateSuccess')}`,
        }),
      );
      onClose?.();
      setInitialSelectedLeafs([]);
      setNewSelectedBranch(undefined);
    }
  }, [isSuccessUpdateOrgs, dispatch, t]);

  const [configState, setConfigState] =
    useState<ECEasyTableConfigType>(initTableConfig);
  const [search, setSearch] = useState<string | undefined>(undefined);

  const [triggerOrgBranches, resultOrgBranhces] =
    useLazyGetCurrentUserBranchesQuery();

  const {
    data: orgBranches,
    isError: isOrgBranchesError,
    error: orgBranchesError,
    isLoading: isLoadingOrgBranches,
    isSuccess: isSuccessOrgBranches,
    originalArgs: originalArgsOrgBranches,
  } = resultOrgBranhces;

  useEffect(() => {
    if (isSuccessOrgBranches) {
      setConfigState(getConfigStateFromApiOrMock(orgBranches, configState, t));
    }
  }, [isSuccessOrgBranches, orgBranches]);

  useEffect(() => {
    if (
      isSuccessAssignedLocations &&
      userBranches &&
      isSuccessOrgTree &&
      orgTree
    ) {
      if (orgTree) {
        const initibalBranches =
          userBranches?.data?.map(org => ({
            nodeId: org.organizationId,
            label: org.name,
          })) ?? [];
        setBranchIds(initibalBranches);
      } else {
        setBranchIds([]);
      }
    }
  }, [
    userBranches,
    isSuccessAssignedLocations,
    orgTree,
    isSuccessOrgTree,
    isEditMode,
  ]);

  useEffect(() => {
    if (
      isSuccessAssignedOrganizations &&
      userOrgs &&
      isSuccessOrgTree &&
      orgTree
    ) {
      if (orgTree) {
        const initialOrgs =
          userOrgs?.map(org => ({
            nodeId: org.id,
            label: org.name,
          })) ?? [];
        setInitialOrgIds(initialOrgs);
        setOrgIds(initialOrgs);
      } else {
        setOrgIds([]);
      }
    }
  }, [
    userOrgs,
    isSuccessAssignedOrganizations,
    orgTree,
    isSuccessOrgTree,
    isEditMode,
  ]);

  useEffect(() => {
    if (isSuccessUpdateOrgs && !isLoadingUpdateOrgs) {
      handleCloseModal();
      resetUpdateOrgs();
      setSelectedLeafs([]);
    }
  }, [isSuccessUpdateOrgs, isLoadingUpdateOrgs]);

  const handleAssignedChipRemove = location => {
    setSelectedLeafs(prevSelectedLeafs =>
      prevSelectedLeafs.filter(leaf => leaf !== location),
    );
  };

  const handleUnassignedChipClick = location => {
    setSelectedLeafs(prevSelectedLeafs => [...prevSelectedLeafs, location]);
  };

  const orgBranchesRefined = useMemo(() => {
    if (!orgBranches) {
      return undefined;
    }

    return {
      ...orgBranches,
      data: orgBranches?.data?.map(orgBranch => ({
        ...orgBranch,
        isAssigned: branchIds
          ?.map(org => org.nodeId)
          ?.includes(orgBranch?.organizationId),
      })),
    };
  }, [orgBranches, branchIds]);

  useEffect(() => {
    if (initialSelectedLeafs?.length) {
      return;
    }

    setInitialSelectedLeafs(
      orgBranches?.data?.filter(orgBranch =>
        branchIds?.map(org => org.nodeId)?.includes(orgBranch?.id),
      ) ?? [],
    );
  }, [orgBranches, branchIds, orgTree]);

  const useLazyGetOrgBranches = useCallback(() => {
    const doGet = async ({ ...queryParams }) => {
      triggerOrgBranches({
        ...queryParams,
        st: 1,
      });
    };

    return [
      doGet,
      {
        data: orgBranchesRefined,
        isError: isOrgBranchesError,
        error: orgBranchesError,
        isLoading: isLoadingOrgBranches,
        isSuccess: isSuccessOrgBranches,
        originalArgs: originalArgsOrgBranches,
      },
    ];
  }, [
    triggerOrgBranches,
    orgBranchesRefined,
    isOrgBranchesError,
    orgBranchesError,
    isLoadingOrgBranches,
    isSuccessOrgBranches,
    originalArgsOrgBranches,
  ]);

  const newAssignedLocations = useMemo(() => {
    if (selectedAssignOption === 'assignToSpecific') return newSelectedBranches;

    const newAssignedLocationsIds = _.difference(
      selectedAssignOption === 'assignToOrg'
        ? _.map(orgIds, 'nodeId')
        : _.map(branchIds, 'nodeId'),
      selectedAssignOption === 'assignToOrg'
        ? _.map(userOrgs, 'id')
        : _.map(userBranches?.data, 'organizationId'),
    );

    return selectedAssignOption === 'assignToOrg'
      ? orgIds?.filter(org => newAssignedLocationsIds.includes(org.nodeId))
      : orgBranches?.data?.filter(org =>
          newAssignedLocationsIds.includes(org.organizationId),
        ) ?? [];
  }, [
    branchIds,
    orgBranches,
    selectedAssignOption,
    userOrgs,
    orgIds,
    userBranches,
    newSelectedBranches,
  ]);

  const unassignedLocations = useMemo(() => {
    const unassignedLocationsIds = _.difference(
      selectedAssignOption === 'assignToOrg'
        ? _.map(userOrgs, 'id')
        : _.map(userBranches?.data, 'organizationId'),
      selectedAssignOption === 'assignToOrg'
        ? _.map(orgIds, 'nodeId')
        : _.map(branchIds, 'nodeId'),
    );

    return selectedAssignOption === 'assignToOrg'
      ? userOrgs?.filter(org => unassignedLocationsIds.includes(org.id))
      : userBranches?.data?.filter(org =>
          unassignedLocationsIds.includes(org.organizationId),
        ) ?? [];
  }, [branchIds, selectedAssignOption, userOrgs, orgIds, userBranches]);

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => setIsModalVisible(false);

  const handleUpdateOrgs = () => {
    const newOrgIds =
      selectedAssignOption === 'assignToAll'
        ? [orgTree?.[0]?.id || 0]
        : selectedAssignOption === 'assignToOrg'
          ? _.map(orgIds, 'nodeId')
          : _.map(branchIds, 'nodeId');
    updateOrgs({
      userId: user?.id?.toString() ?? userId,
      orgIds: newOrgIds,
    });
    setSelectedLeafs([]);
  };

  const texts = useMemo(() => {
    if (isEditMode) {
      if (selectedAssignOption === 'assignToAll') {
        return {
          title: 'Assigned to All Company Company Locations',
          description:
            "This user's location assignment will automatically be updated with locations added or removed from the company.",
        };
      } else if (selectedAssignOption === 'assignToOrg') {
        return {
          title: 'Select Org Hierarchy Locations',
          description:
            'Assign Locations by checking the checkboxes of Org Hierarchy Level (Region, District, etc) , expand or Collapse to view more of the tree view.',
        };
      } else if (selectedAssignOption === 'assignToSpecific') {
        return {
          title: 'Select Specific Locations',
          description:
            'Assign Locations by checking the checkboxes of each row.',
        };
      }
    } else {
      if (options?.assignedToAll) {
        return {
          title: 'Assigned to All Company Locations',
          description:
            "This user's location assignment will automatically be updated with locations added or removed from the company.",
        };
      } else if (options?.assignedToOrg) {
        return {
          title: 'Assigned to Org Hierarchy Locations',
          description:
            'All the locations from these Districts and Regions are assigned to this user.',
        };
      } else if (options?.assignedToSpecific) {
        return {
          title: 'Assigned to Specific Locations',
        };
      }
    }
  }, [options, isEditMode, selectedAssignOption]);

  const assignOptions = [
    {
      label: 'Assign to All Company Locations',
      value: 'assignToAll',
    },
    {
      label: 'Assign to Org Hierarchy Locations',
      value: 'assignToOrg',
    },
    {
      label: 'Assign to Specific Locations',
      value: 'assignToSpecific',
    },
  ];

  const mappedOrganizationTree = useMemo(
    () => (orgTree ? orgTree?.map(mapOrganizationToTreeNode) : undefined),
    [orgTree],
  );

  useEffect(() => {
    if (mappedOrganizationTree && isSuccessOrgTree) {
      setTree(mappedOrganizationTree);
    }
  }, [isSuccessOrgTree, mappedOrganizationTree]);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    if (mappedOrganizationTree) {
      if (search === '') {
        setTree(mappedOrganizationTree);
        return;
      }

      const filteredNodes = findNode(mappedOrganizationTree, search);
      setTree(filteredNodes);
    }
  }, [search, mappedOrganizationTree]);

  const debouncedHandleSearchChange = useRef(
    _.debounce(e => {
      setSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  useEffect(() => {
    if (!isEditMode) {
      setSearchParams({ page: '0' });
    }
  }, [isEditMode]);

  const handleDeselectAllButtonClicked = () => {
    setBranchIds([]);
    setNewSelectedBranch([]);
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      flex={1}
      minWidth={0}
      width="100%"
    >
      {isEditMode && (
        <ECBox
          display="flex"
          flexDirection="column"
          gap="10px"
          px={4}
          mt={2}
          py={1}
        >
          <ECTypography variant="h6">Assign Locations by</ECTypography>

          <ECBox display="flex" flexDirection="row">
            {assignOptions?.map((option, index) => {
              return (
                <ECFormControlLabel
                  control={
                    <ECRadio
                      value={option.value}
                      checked={option.value === selectedAssignOption}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedAssignOption(option.value);
                        }
                      }}
                    />
                  }
                  sx={{
                    my: 1,
                    '& .MuiFormControlLabel-label': { width: '100%' },
                  }}
                  value={option.value}
                  label={option.label}
                />
              );
            })}
          </ECBox>
        </ECBox>
      )}

      {isEditMode && !_.isEmpty(selectedAssignOption) ? (
        <>
          <ECBox
            display="flex"
            flexDirection="column"
            gap="10px"
            px={4}
            py={1}
            mt={isEditMode ? 0 : 2}
            borderTop={1}
            borderBottom={1}
            borderColor={theme => theme.palette.other.divider}
          >
            <ECTypography variant="h6">{texts?.title}</ECTypography>

            <ECTypography variant="subtitle1">
              {texts?.description ?? ''}
            </ECTypography>

            {selectedAssignOption === 'assignToAll' && (
              <ECTextField
                variant="standard"
                inputProps={{ readOnly: true }}
                label="Company"
                value={orgTree?.[0]?.name}
              />
            )}

            {selectedAssignOption === 'assignToOrg' && (
              <>
                <ECTextField
                  fullWidth
                  placeholder="Search..."
                  onChange={debouncedHandleSearchChange}
                />
                <ECTree
                  tree={tree}
                  defaultExpanded={[tree?.[0]?.nodeId?.toString()]}
                  initialSelectedNodesIds={_.map(initialOrgIds, 'nodeId')}
                  selectedNodes={orgIds}
                  onSelectedNodesChange={setOrgIds}
                  keepSelectedNodes={search !== undefined}
                  showOnlyUnselectAllOption
                />
              </>
            )}

            {selectedAssignOption === 'assignToSpecific' && (
              <ECDynamicPageTemplate
                marginTop={false}
                pageTitle={t('translation:pages.locationAssignment.title')}
                useLazyGetListQuery={useLazyGetOrgBranches}
                useCreateMutation={{}}
                withDrawer={false}
                preSelectRowsFieldname="isAssigned"
                multiSelectTable
                showSelectAllPageRows={false}
                emptyType={EmptyType.EMPTY_STATE_LOCATION_ASSIGNMENT}
                onMultiTableSelect={(leaves, isUnselecting, row) => {
                  if (isUnselecting) {
                    setBranchIds(branchIds =>
                      _.uniqBy(
                        branchIds?.filter(
                          branch => branch.nodeId !== row?.organizationId,
                        ),
                        'nodeId',
                      ),
                    );
                    setNewSelectedBranch(
                      prev =>
                        prev?.filter(
                          selectedBranch => selectedBranch.id !== row?.id,
                        ) || [],
                    );
                  } else {
                    setBranchIds(branchIds =>
                      _.uniqBy(
                        [
                          ...branchIds,
                          ..._.uniqBy(leaves, 'organizationId')?.map(leaf => ({
                            nodeId: leaf.organizationId,
                            label: leaf.name,
                          })),
                        ],
                        'nodeId',
                      ),
                    );
                    if (row?.id) {
                      const selectedBranch = { name: row?.name, id: row?.id };
                      setNewSelectedBranch(prev =>
                        prev ? [...prev, selectedBranch] : [selectedBranch],
                      );
                    }
                  }
                }}
                customMultiSelectActionButton={rows => (
                  <ECStack direction="row" spacing={2}>
                    <ECTypography
                      mt="10px !important"
                      sx={theme => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      {rows?.length} Location
                      {!rows?.length || rows?.length === 1 ? '' : 's'} selected
                    </ECTypography>
                    <ECButton
                      variant="text"
                      sx={{ textTransform: 'none' }}
                      onClick={handleDeselectAllButtonClicked}
                    >
                      Deselect All
                    </ECButton>
                  </ECStack>
                )}
                shouldNotUseActiveFilter
              />
            )}
          </ECBox>
        </>
      ) : (
        <>
          <ECBox
            display="flex"
            flexDirection="column"
            gap="10px"
            px={4}
            py={1}
            mt={isEditMode ? 0 : 2}
            borderTop={1}
            borderBottom={1}
            borderColor={theme => theme.palette.other.divider}
          >
            <ECTypography variant="h6">{texts?.title}</ECTypography>

            <ECTypography variant="subtitle1">
              {texts?.description ?? ''}
            </ECTypography>

            {options?.assignedToAll && (
              <ECTextField
                variant="standard"
                label="Company"
                inputProps={{ readOnly: true }}
                value={options?.companyName}
              />
            )}

            {options?.assignedToOrg && (
              <ECChipAutocomplete
                variant="standard"
                limitTags={5}
                options={
                  options?.levelNames?.map((levelName, index) => ({
                    id: index,
                    label: levelName,
                  })) || []
                }
                values={options?.levelNames?.map((levelName, index) => ({
                  id: index,
                  label: levelName,
                }))}
                readOnly
                title="Levels"
                sx={{ width: '50%' }}
              />
            )}
          </ECBox>
          <ECBox display="flex" flex={1}>
            <ECGrid
              mt={0}
              overflow="hidden"
              container
              spacing={1}
              columns={4}
              minWidth={0}
              width="100%"
              wrap="nowrap"
            >
              <ECGrid item xs p={2} minWidth={0} width="100%">
                <ECDynamicPageTemplate
                  marginTop={false}
                  emptyType={EmptyType.EMPTY_STATE_LOCATION_ASSIGNMENT}
                  pageTitle={t('translation:pages.locationAssignment.title')}
                  useLazyGetListQuery={useLazyGetUserAssignedLocationsQuery}
                  idToGetListBy={user?.id ?? (id ? +id : 0)}
                  useCreateMutation={{}}
                  withDrawer={false}
                  enableExport
                  exportUserId={user?.id ?? (id ? +id : 0)}
                  exportModuleName="locationassignment"
                  showStatusActiveFilter={true}
                  shouldNotUseActiveFilter
                />
              </ECGrid>
            </ECGrid>
          </ECBox>
        </>
      )}

      {isEditMode && (
        <ECBox display="flex" width="100%">
          <ECStack
            bgcolor={theme => theme.palette.marketingSuggestion.mainBackground}
            direction="row"
            justifyContent="right"
            p={2}
            spacing={2}
            width="100%"
            bottom={0}
          >
            <ECButton variant="text" onClick={onClose}>
              Cancel
            </ECButton>
            <ECButton
              variant="contained"
              onClick={
                selectedAssignOption === 'assignToAll'
                  ? handleUpdateOrgs
                  : handleOpenModal
              }
            >
              Save
            </ECButton>
          </ECStack>
        </ECBox>
      )}

      <ECModal isOpen={isModalVisible} onClose={handleCloseModal} sx={{ p: 2 }}>
        <ECBox display="flex" flexDirection="column">
          <ECTypography variant="h6">
            Confirm changes to user: {user?.fullName ?? userFullName}
          </ECTypography>

          <Alert severity="info" sx={{ my: 1 }}>
            {t('translation:pages.locationAssignment.info')}
          </Alert>

          <ECTypography
            mt={2}
            variant="h6"
            color={theme => theme.palette.primary.light}
          >
            Assigned New Locations ({newAssignedLocations?.length})
          </ECTypography>
          <ECBox display="flex" flexWrap="wrap">
            {newAssignedLocations?.slice(0, 4).map((newLocations, index) => (
              <ECChip
                key={index}
                variant="outlined"
                label={newLocations?.name || newLocations?.label}
                onDelete={() => handleAssignedChipRemove(newLocations)}
                sx={{
                  height: 30,
                  m: 1,
                }}
              />
            ))}
            {newAssignedLocations?.length > 4 && (
              <ECChip
                variant="filled"
                label={`+${newAssignedLocations?.length - 4}`}
                sx={{
                  height: 30,
                  m: 1,
                }}
              />
            )}
          </ECBox>

          <ECTypography
            mt={2}
            variant="h6"
            color={theme => theme.palette.primary.light}
          >
            Unassigned Locations ({unassignedLocations?.length})
          </ECTypography>
          <ECBox display="flex" flexWrap="wrap">
            {unassignedLocations
              ?.slice(0, 4)
              .map((unassignedLocation, index) => (
                <ECChip
                  key={index}
                  variant="outlined"
                  onDelete={() => handleUnassignedChipClick(unassignedLocation)}
                  label={unassignedLocation?.name || unassignedLocation?.label}
                  sx={{
                    height: 30,
                    m: 1,
                  }}
                />
              ))}
            {unassignedLocations?.length > 4 && (
              <ECChip
                variant="filled"
                label={`+${unassignedLocations?.length - 4}`}
                sx={{
                  height: 30,
                  m: 1,
                }}
              />
            )}
          </ECBox>

          <ECBox
            bgcolor={theme => theme.palette.marketingSuggestion.mainBackground}
            display="flex"
            justifyContent="flex-end"
            p={2}
            mt={2}
            mx={-2}
            mb={-2}
            gap={2}
          >
            <ECButton variant="text" onClick={handleCloseModal}>
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleUpdateOrgs}>
              {isLoadingUpdateOrgs ? (
                <ECCircularProgress
                  size="24px"
                  sx={{ color: theme => theme.palette.background.paper }}
                />
              ) : (
                'Confirm'
              )}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECModal>
    </ECBox>
  );
};

export const LocationAssignment =
  requirePermissionWrapper<LocationAssignmentProps>(LocationAssignmentContent);
