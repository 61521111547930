import {
  ECBox,
  ECGrid,
  ECStack,
  ECTextField,
  ECList,
  ECListItemText,
  ECListItemButton,
  NAV_BAR_HEIGHT_PX,
  TABS_HEIGHT_IN_PX,
  ECButton,
  ECFormControl,
} from 'app/components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetJobTitlesByCompanyIdQuery } from 'services/jobTitleApi';
import { JobTitleList } from 'types/JobTitle';
import {
  useLazyGetNotificationSettingByJobTitleIdQuery,
  useUpdateNotificationSettingByJobTitleIdMutation,
} from 'services/notificationApi';
import { ECNotificationsSettingsTable } from './components/notifications-settings-table';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { Edit } from '@mui/icons-material';
import { NotificationSettingUpdate } from 'types/Notification';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { P } from 'types/Permission';
import { useGetInternalTechsCompaniesQuery } from 'services/userApi';
import { ECAutocompleteStyled } from 'app/components/ECAutocomplete';

export function NotificationsSettingsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [changes, setChanges] = useState<NotificationSettingUpdate[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: number;
    label: string;
    name: string;
  } | null>(null);

  const [
    doUpdateNotificationsSettings,
    { error, isError, isLoading: isLoadingUpdate, isSuccess },
  ] = useUpdateNotificationSettingByJobTitleIdMutation();

  const [trigger, result] = useLazyGetNotificationSettingByJobTitleIdQuery();
  const [triggerJobTitlesData, resultJobTitlesData] =
    useLazyGetJobTitlesByCompanyIdQuery();

  const {
    data: userInternalCompaies,
    isSuccess: isSuccessUserInternalCompaies,
  } = useGetInternalTechsCompaniesQuery({
    t: 10000,
    st: 1,
    ob: 'cmpny.name',
    o: 'a',
    own: '1',
  });

  const options = useMemo(() => {
    if (!userInternalCompaies) return [];

    const arrayUserInternalCompanies = Array.isArray(userInternalCompaies)
      ? userInternalCompaies
      : [userInternalCompaies];

    return arrayUserInternalCompanies.map((item, index) => {
      if (index === 0) {
        return {
          label: item.name,
          name: item.name,
          id: item.id,
        };
      } else {
        return {
          label: item.name,
          name: item.name,
          id: item.id,
          group: 'Internal Company',
        };
      }
    });
  }, [userInternalCompaies]);

  useEffect(() => {
    if (isSuccessUserInternalCompaies && userInternalCompaies) {
      setSelectedCompany({
        id: userInternalCompaies[0]?.id,
        label: userInternalCompaies[0]?.name,
        name: userInternalCompaies[0]?.name,
      });
    }
  }, [userInternalCompaies, isSuccessUserInternalCompaies]);

  useEffect(() => {
    if (selectedCompany) {
      triggerJobTitlesData(selectedCompany?.id);
    }
  }, [selectedCompany, setSelectedCompany]);

  const {
    data: notificationSettings,
    isSuccess: isSuccessNotificationSettings,
  } = result;

  const { data: jobTitlesData, isSuccess: isSuccessJobTitles } =
    resultJobTitlesData;

  const [selectedJobTitle, setSelectedJobTitle] = useState<
    JobTitleList | undefined
  >(undefined);
  const [jobTitles, setJobTitles] = useState<JobTitleList[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isSuccessJobTitles) {
      setJobTitles(jobTitlesData?.data || []);
      setSelectedJobTitle(jobTitlesData?.data[0]);
      const jobTitleId = jobTitlesData?.data[0]?.id;
      const companyId = selectedCompany?.id;
      const optionsCompanyId = options[0]?.id;

      if (jobTitleId) {
        const cf = optionsCompanyId !== companyId ? companyId : undefined;
        trigger({ jobTitleId, params: { cf } });
      }
    }
  }, [jobTitlesData, isSuccessJobTitles]);

  useEffect(() => {
    if (selectedJobTitle?.id) {
      const companyId = selectedCompany?.id;
      const optionsCompanyId = options[0]?.id;
      const cf = optionsCompanyId !== companyId ? companyId : undefined;
      trigger({ jobTitleId: selectedJobTitle.id, params: { cf } });
    }
  }, [selectedJobTitle]);

  const handleJobTitleSelect = (jobTitle: JobTitleList) => {
    setSelectedJobTitle(jobTitle);
  };

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setJobTitles(() => {
      if (search) {
        return (
          jobTitlesData?.data?.filter(jobTitle =>
            jobTitle.name.toLowerCase().includes(search.toLowerCase()),
          ) || []
        );
      }
      return jobTitlesData?.data || [];
    });
  }, [search]);

  const handleCancelButtonClick = () => setIsEditMode(false);
  const handleEditButtonClick = () => setIsEditMode(prev => !prev);

  const doUpdate = () => {
    if (selectedJobTitle?.id) {
      doUpdateNotificationsSettings({
        jobTitleId: selectedJobTitle?.id,
        notificationSettings: changes,
      });
    }
  };

  useEffect(() => {
    if (!isLoadingUpdate && isSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Notifications successfully updated.',
        }),
      );
      setIsEditMode(false);
    }
  }, [isLoadingUpdate, isSuccess]);

  useEffect(() => {
    if (!isLoadingUpdate && isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: typeof error === 'string' ? error : 'Error updating.',
        }),
      );
      setIsEditMode(false);
    }
  }, [isLoadingUpdate, isError, error]);

  return (
    <ECBox display="flex" flexDirection="column" flex={1}>
      <ECGrid mt={0} overflow="hidden" container spacing={1}>
        <ECGrid mt={0} pt={0} item xs={3}>
          {options?.length > 1 && (
            <ECFormControl
              sx={{ width: '100%', marginLeft: 2, paddingRight: 4 }}
            >
              <ECAutocompleteStyled
                sx={{ width: '100%' }}
                disablePortal
                groupBy={option => option?.group}
                value={selectedCompany}
                options={options}
                onChange={(event, newValue) => {
                  setSelectedCompany(newValue);
                }}
                renderInput={params => {
                  return (
                    <ECTextField
                      {...params}
                      id="company"
                      name="company"
                      placeholder="Company"
                      label="Company"
                      variant="filled"
                    />
                  );
                }}
              />
            </ECFormControl>
          )}
          <ECBox p={2}>
            <ECTextField
              fullWidth
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          </ECBox>
          <ECStack
            height={window.innerHeight - NAV_BAR_HEIGHT_PX - TABS_HEIGHT_IN_PX}
            overflow="auto"
            direction="column"
            spacing={2}
          >
            <ECList noPadding>
              {jobTitles?.map(jobTitle => (
                <ECListItemButton
                  disabled={isEditMode}
                  selected={selectedJobTitle?.id === jobTitle.id}
                  onClick={() => handleJobTitleSelect(jobTitle)}
                >
                  <ECListItemText primary={jobTitle.name} />
                </ECListItemButton>
              ))}
            </ECList>
          </ECStack>
        </ECGrid>

        <ECGrid item xs={9} p={2} position="relative" height="83vh">
          <ECBox display="flex" justifyContent="flex-end" p={2}>
            <ECToggleButton
              value="edit"
              selected={isEditMode}
              onChange={handleEditButtonClick}
              scopes={[P.EditUser]}
            >
              <Edit />
            </ECToggleButton>
          </ECBox>

          {isSuccessNotificationSettings && (
            <ECBox
              display="flex"
              width="95%"
              overflow="auto"
              position="absolute"
              bottom={isEditMode ? 68 : 0}
              top={88}
            >
              <ECNotificationsSettingsTable
                onChange={setChanges}
                isEditMode={isEditMode}
                settingsData={notificationSettings}
              />
            </ECBox>
          )}

          {isEditMode && (
            <ECStack
              bgcolor={theme => theme.palette.common.white}
              display="flex"
              position="absolute"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
              bottom={0}
              px={4}
              py={2}
              zIndex={10}
              width="100%"
            >
              <ECButton variant="text" onClick={handleCancelButtonClick}>
                Cancel
              </ECButton>

              <ECButton
                variant="contained"
                onClick={doUpdate}
                isLoading={isLoadingUpdate}
              >
                Save
              </ECButton>
            </ECStack>
          )}
        </ECGrid>
      </ECGrid>
    </ECBox>
  );
}
