import { useMemo } from 'react';

// porp-types is a library for typechecking of props
// import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Line } from 'react-chartjs-2';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 React components
import { ECBox, ECCard, ECTypography } from 'app/components';

// ReportsLineChart configurations
import configs from './configs';
import { Schedule } from '@mui/icons-material';

function ECReportsLineChart({ color, title, description, date, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ overflow: 'visible', marginTop: 5 }}>
      <ECBox padding="1rem">
        {useMemo(
          () => (
            <ECCard
              sx={{
                backgroundColor: 'primary.main !important',
                borderRadius: 'lg',
                py: 2,
                pr: 0.5,
                mt: -5,
                height: '12.5rem',
              }}
            >
              {/* <Line data={data} options={options} /> */}
            </ECCard>
          ),
          [data, options],
        )}
        <ECBox pt={3} pb={1} px={1}>
          <ECTypography variant="h6" textTransform="capitalize">
            {title}
          </ECTypography>
          <ECTypography variant="button" color="text" fontWeight="light">
            {description}
          </ECTypography>
          <Divider />
          <ECBox display="flex" alignItems="center">
            <ECTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Schedule fontSize={'small'} />
            </ECTypography>
            <ECTypography variant="button" color="text" fontWeight="light">
              {date}
            </ECTypography>
          </ECBox>
        </ECBox>
      </ECBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ECReportsLineChart.defaultProps = {
  color: 'dark',
  description: '',
};

// Typechecking props for the ReportsLineChart
// ECReportsLineChart.propTypes = {
//   color: PropTypes.oneOf([
//     'primary',
//     'secondary',
//     'info',
//     'success',
//     'warning',
//     'error',
//     'dark',
//   ]),
//   title: PropTypes.string.isRequired,
//   description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   date: PropTypes.string.isRequired,
//   chart: PropTypes.objectOf(
//     PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//   ).isRequired,
// };

export default ECReportsLineChart;
