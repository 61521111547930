import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

export const ECAccordionSummary = styled(
  AccordionSummary,
)<AccordionSummaryProps>(() => ({}));

export const ECAccordionDetails = styled(
  AccordionDetails,
)<AccordionDetailsProps>(() => ({}));

interface ECAccordionProps {
  title: string;
  children?: any[];
  accordionSx?: any;
  summarySx?: any;
  detailsSx?: any;
  defaultExpanded?: boolean;
}

export const ECAccordion = ({
  title,
  children,
  accordionSx,
  summarySx,
  detailsSx,
  defaultExpanded,
}: ECAccordionProps) => {
  return (
    <Accordion sx={accordionSx} defaultExpanded={defaultExpanded}>
      <ECAccordionSummary sx={summarySx} expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontWeight: '700' }}>{title}</Typography>
      </ECAccordionSummary>
      {children?.map((child, index) => (
        <ECAccordionDetails key={index} sx={detailsSx}>
          {child}
        </ECAccordionDetails>
      ))}
    </Accordion>
  );
};
