import { ECButton } from '../ECButton';
import { ECBox } from '../ECBox';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECTextField } from '../ECTextField';

interface ECWarrantyPeriodListProps {
  warrantyPeriodOptions?:
    | {
        periodOrder: number;
        duration: number;
        periodCode: string;
        periodName: string;
        warrantyTypeName: string;
      }[]
    | null;
}

export const ECWarrantyPeriodList = ({
  warrantyPeriodOptions,
}: ECWarrantyPeriodListProps) => {
  if (!warrantyPeriodOptions?.length || !warrantyPeriodOptions) {
    return (
      <ECBox sx={{ marginBottom: '3rem' }}>
        <ECTypography variant={'body1'} mb={2}>
          No Warranty Periods
        </ECTypography>
      </ECBox>
    );
  }

  return (
    <ECBox sx={{ marginBottom: '3rem' }} display={'flex'} flexWrap={'wrap'}>
      {warrantyPeriodOptions?.length &&
        warrantyPeriodOptions?.map((item, index) => {
          return (
            <ECStack
              sx={{
                margin: '1rem 3rem 2rem 0',
              }}
            >
              <ECTypography variant={'body1'} mb={2}>
                Period {index + 1}
              </ECTypography>
              <ECBox
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <ECTextField
                  value={item?.duration}
                  variant="standard"
                  label={'Duration'}
                />
                <ECTextField
                  value={item?.periodName}
                  variant="standard"
                  label={'Period'}
                />
                <ECTextField
                  value={item?.warrantyTypeName}
                  variant="standard"
                  label={'Warranty Type'}
                />
              </ECBox>
            </ECStack>
          );
        })}
    </ECBox>
  );
};
