import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useAwardRfpProposalMutation } from 'services/proposalApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { moduleApi } from 'services/moduleApi';
import { StarsRounded } from '@mui/icons-material';
import { themes } from 'styles/theme/themes';
import { requestForPricingApi } from 'services/requestForPricingApi';
import { ECModal } from 'app/components/ECModal';

interface AwardRfpProposalButtonProps {
  proposalId: number;
  serviceProviderName?: string;
  onSave?: () => void;
  disabled?: boolean;
}

const fancyFormAwardInvoice = require('./fancy_form_config_award.json');

export function AwardRfpProposalButton({
  onSave,
  disabled,
  proposalId,
  serviceProviderName,
}: AwardRfpProposalButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    doAwardRfpProposal,
    {
      isLoading: isLoadingAwardRfpProposal,
      isSuccess: isSuccessAwardRfpProposal,
    },
  ] = useAwardRfpProposalMutation();

  useEffect(() => {
    if (isSuccessAwardRfpProposal) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(requestForPricingApi.util.invalidateTags(['RfpItem']));
      dispatch(requestForPricingApi.util.invalidateTags(['RfpItemProposals']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal successfully Awarded',
        }),
      );
      onSave?.();
    }
  }, [isSuccessAwardRfpProposal, dispatch, onSave]);

  const handleAwardRfpProposal = useCallback(() => {
    doAwardRfpProposal(proposalId);
  }, [doAwardRfpProposal, proposalId]);

  // award was changed to open a modal with confirmation
  const [isAwardModalOpen, setIsAwardModalOpen] = useState(false);

  // change the title of modal to include the SP name
  useEffect(() => {
    if (serviceProviderName) {
      fancyFormAwardInvoice.config.title = `Confirm Awardance of the Proposal for ${serviceProviderName}`;
    }
  }, [serviceProviderName]);

  return (
    <>
      <ECButton
        variant="outlined"
        color="success"
        startIcon={
          <StarsRounded sx={{ color: theme => theme.palette.success.main }} />
        }
        sx={theme => ({
          border: 1,
          borderColor: `${theme.palette.success.outlinedRestingBackground} !important`,
          color: `${theme.palette.success.main} !important`,
          marginRight: 2,
        })}
        onClick={() => setIsAwardModalOpen(true)}
        isLoading={isLoadingAwardRfpProposal}
        loadingSpinnerColor={themes.light.palette.success.main}
        disabled={disabled}
      >
        Award
      </ECButton>
      <ECModal
        isOpen={isAwardModalOpen}
        onClose={() => onSave?.()}
        sx={{ maxWidth: 380 }}
        noPadding
      >
        <ECEasyForm
          pattern="modal"
          config={fancyFormAwardInvoice.config}
          fields={[]}
          isSendingData={isLoadingAwardRfpProposal}
          isLoading={isLoadingAwardRfpProposal}
          isLoadingForm={false}
          onFormSubmit={handleAwardRfpProposal}
          saveButtonColor="success"
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              onClick={() => setIsAwardModalOpen(false)}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
          drawerTitleBarBGColor="transparent"
        />
      </ECModal>
    </>
  );
}
