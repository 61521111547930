import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useLazyGetAllPurchaseOrderListQuery } from 'services/purchaseOrderApi';
import { NewPO } from './NewPO';

export const PurchaseOrders = ({ marginTop = true }) => {
  const { t } = useTranslation();

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.purchaseOrders.purchaseOrderList')}
        useLazyGetListQuery={useLazyGetAllPurchaseOrderListQuery}
        useCreateMutation={undefined}
        useUpdateMutation={undefined}
        editFormConfig={[] as any}
        editFormFields={[] as any}
        createFormConfig={undefined as any}
        createFormFields={undefined as any}
        detailsConfig={[] as any}
        detailsFields={[] as any}
        onRowClick={null as any}
        onDrawerClose={null as any}
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        customContent={{
          header: <NewPO />,
        }}
        showOnlyDetailsTab
        showStatusActiveFilter
        statusFilterInitialSelected
        shouldCacheLazyQuery
        withDrawer={false}
        showColumnVisibilityFilter
        enableExport
        enableTableCsvExport
        moduleName="purchase-orders"
        conditionalLinearProgressColor
      />
    </>
  );
};
