import { ImagePreview } from 'app/components/ECFilePicker';
import { Manufacture } from './Asset';
import { QueryParams } from './QueryParams';

export interface AddressInfo {
  line1: string;
  line2: string;
  cityName: string;
  zipCodePostalCode: string;
  stateProvinceCode: string;
  countryCode: string;
}
export interface InventoryStorage {
  id: number;
  name: string;
}

export interface InventoryItemStorage {
  id: number;
  avgPrice: number;
  count: number;
  createdAt: string;
  createdBy: number;
  inventoryItemId: number;
  inventoryStorageId: number;
  updatedAt: string;
  updatedBy: number;
  inventoryStorage?: InventoryStorage;
}

export interface InventoryList {
  companyId: number;
  createdAt: string;
  createdBy: number;
  description: string;
  id: number;
  inventoryItemStorage: InventoryItemStorage[];
  manufacture: Manufacture;
  manufacturePartNumber: string;
  name: string;
  partNumber: string;
  status: number;
  supplier: string;
  supplierPartNumber: string;
  tagId: string;
  updatedAt: string;
  updatedBy: number;
  viewId: string;
  avgPrice?: number;
  photo?: string;
  totalQuantity?: number;
}

export interface InventoryCreateForm {
  name: string;
  inventoryStorageTypeId: number;
  licensePlate?: string;
  address?: Partial<AddressInfo>;
}

export interface ComputedInventoryItem {
  id: number;
  count: number;
  price: number;
  inventoryItemStorage: {
    id: number;
    inventoryItem: {
      id: number;
      name: string;
    };
    inventoryStorage: {
      id: number;
      name: string;
    };
  };
  total: number;
}

export interface ComputedInventoryItemsWithTotal {
  allocatedItems: ComputedInventoryItem[];
  total: number;
}

export interface ValidatePartNumber {
  id: number;
  isDuplicate: boolean;
  inventoryName: string;
  matchingField: string;
}

export interface InventoryItemShape {
  count: number;
  price: number;
  id: number;
  inventoryId: number;
  inventoryStorageId: number;
  name: string;
  storageLocation: string;
  total: number;
}
export interface InventoryCreateTransferStock {
  inventoryItemId: number;
  fromInventoryStorageId: number;
  toInventoryStorageId: number;
  lot: string;
  bin: string;
  description: string;
  costPerUnit: number;
  count: number;
}

export interface AllocateInventoryLocation {
  inventoryItemId: number;
  inventoryStorageId: number;
  quantity: number;
  price: number;
}
export interface AllocateInventoryItem {
  workOrderId?: number;
  allocateInventory?: AllocateInventoryLocation[];
}

export interface InventoryListQueryParams extends QueryParams {
  locationId?: number;
}

export interface InventoryItemParams {
  id: string;
  name?: string;
  status?: number;
  partNumber?: string;
  manufacturePartNumber?: string;
  manufactureId?: number;
  supplier?: string;
  supplierPartNumber?: string;
  count?: number;
  avgPrice?: number;
  description?: string;
  tagId?: string;
  assetTypeIds?: number[];
  storageLocationIds?: number[];
  minimumQuantity?: number;
  companyId?: number;
}

interface InventoryBase {
  name: string;
}

export interface InventoryCreate extends InventoryBase {}

export interface InventoryUpdate extends InventoryBase {
  status: number;
}

export interface InventoryCreateFormData extends InventoryBase {}

export interface AdjustInventoryItemStock {
  _id: string;
  inventoryItemId: number;
  addStock?: boolean;
  removeStock?: boolean;
  lot?: string;
  bin?: string;
  description?: string;
  costPerUnit: number;
  count: number;
  inventoryActivityReceipts?: ImagePreview[];
}

export interface EditInventoryItemStock {
  _id: string;
  inventoryItemId: number;
  lot?: string;
  bin?: string;
  description?: string;
  costPerUnit: number;
  count: number;
  adjustReason?: string;
}

export enum PartNumberType {
  PartNumber = 'partNumber',
  ManufacturePartNumber = 'manufacturePartNumber',
  SupplierPartNumber = 'supplierPartNumber',
  None = 'none',
}
