import { ContentCopy, Edit } from '@mui/icons-material';
import { ECIconButton } from '../ECIconButton';
import { ECStack } from '../ECStack';
import { ECTableCell } from '../ECTable';

export enum ColumnActions {
  Edit = 'edit',
  Duplicate = 'duplicate',
  Custom = 'custom',
}

interface ECActionsTableCellProps {
  actions: ColumnActions[];
  customActionComponent?: React.ReactNode;
  onEditClick?: (row: any) => void;
  onDuplicateClick?: (row: any) => void;
}

export const ECActionsTableCell = ({
  actions,
  customActionComponent,
  onEditClick,
  onDuplicateClick,
}: ECActionsTableCellProps) => {
  const mapActionButton = {
    [ColumnActions.Edit]: (
      <ECIconButton
        key={ColumnActions.Edit}
        type="info"
        onClick={onEditClick}
        size="small"
        squared
      >
        <Edit />
      </ECIconButton>
    ),
    [ColumnActions.Duplicate]: (
      <ECIconButton
        key={ColumnActions.Duplicate}
        type="info"
        onClick={onDuplicateClick}
        size="small"
        squared
      >
        <ContentCopy />
      </ECIconButton>
    ),
    [ColumnActions.Custom]: customActionComponent,
  };

  return (
    <ECTableCell
      sx={{ height: '100%', alignItems: 'center', p: 0 }}
      onClick={e => e.stopPropagation()}
    >
      <ECStack direction="row" spacing={2}>
        {actions?.map(action => mapActionButton[action])}
      </ECStack>
    </ECTableCell>
  );
};
