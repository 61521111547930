import { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  ECButton,
  ECCard,
  ECChipAutocomplete,
  ECEasyTable,
  ECFormControl,
  ECMenuItem,
  ECStack,
  ECSwitch,
  ECTextField,
  ECTypography,
} from '..';
import { ECBox } from '../ECBox';
import { useGetCoreTradeTypesQuery } from 'services/tradeApi';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import AddIcon from '@mui/icons-material/Add';

import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { normalizeZipcode } from 'utils/strings/zipcode';
import { FormControlLabel } from '@mui/material';
import { COUNTRY_CODES, TYPES_REGIONS } from 'utils/address';

export interface ECAssetTradeRegionCreateProps {
  onChange?: (output: any) => void;
}

interface TradeRegionOutput {
  tradeId: number;
  selfPerform: number;
  zipCodePostalCode?: string;
  countryCode: string;
  stateProvinceCode?: string;
  countyDistrictCode?: string;
  cityName?: string;
}

export interface CreateTradeRegionOutput {
  tradeRegions?: TradeRegionOutput[];
}

const tableConfigWorkTradesRegions = require('./tableConfigTradesRegions.json');

export const ECAssetTradeRegionCreate = ({
  onChange,
}: ECAssetTradeRegionCreateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [tradeRegionTableConfig, setTradeRegionTableConfig] = useState<any>(
    tableConfigWorkTradesRegions.config,
  );
  const [output, setOutput] = useState<CreateTradeRegionOutput | null>(null);
  const [selectedTrades, setSelectedTrades] = useState<any[]>([]);
  const [tradesOptions, setTradesOptions] = useState<any>([]);
  const [tradesErrorMessage, setTradesErrorMessage] = useState<string>('');

  const [address, setAddress] = useState<string>('');
  const [addressObject, setAddressObject] = useState<any>({});
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>('');
  const [addressIsSelected, setAddressIsSelected] = useState<boolean>(false);

  const [selfPerforming, setSelfPerforming] = useState<boolean>(true);

  const [tradeRegions, setTradesRegions] = useState<any[]>([]);

  const [paginatedTradeRegions, setPaginatedTradeRegions] = useState<any[]>([]);

  const { data: tradesList, isSuccess: isSuccessTradesList } =
    useGetCoreTradeTypesQuery();

  useEffect(() => {
    if (isSuccessTradesList) {
      setTradesOptions(
        tradesList?.map(d => ({
          id: d.id,
          label: d.name,
          color: '',
        })) || [],
      );
    }
  }, [tradesList, isSuccessTradesList]);

  useEffect(() => {
    onChange?.(output);
  }, [output]);

  const handleAddTradesRegions = useCallback(() => {
    if (selectedTrades.length === 0) {
      setTradesErrorMessage(
        t('translation:pages.spProfile.tradesRegions.tradeRequired'),
      );
      return;
    }
    if (!addressIsSelected) {
      setAddressErrorMessage(
        t('translation:pages.spProfile.tradesRegions.regionNameRequired'),
      );
      return;
    }
    const newTradesRegions = selectedTrades.map(trade => ({
      tradeId: trade.id,
      location: address,
      tradeName: trade.label,
      selfPerforming: selfPerforming ? 'Yes' : 'No',
      status: 'Active',
      zipCodePostalCode: addressObject.zipCodePostalCode,
      countryCode: addressObject.countryCode,
      stateProvinceCode: addressObject.stateProvinceCode,
      countyDistrictCode: addressObject.countyDistrictCode,
      cityName: addressObject.cityName,
    }));

    let isDuplicate = false;
    newTradesRegions.forEach(tradeRegion => {
      // Ignoring selfPerforming field to check for duplicates
      if (
        tradeRegions.find(tr =>
          _.isEqual(
            { ...tradeRegion, selfPerforming: 'Yes' },
            { ...tr, selfPerforming: 'Yes' },
          ),
        )
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t(
              'translation:pages.spProfile.tradesRegions.duplicatedTradeRegion',
            ),
          }),
        );
        isDuplicate = true;
      }
    });
    if (isDuplicate) {
      return;
    }

    const newFinalTradesRegions = [...tradeRegions, ...newTradesRegions];
    setTradesRegions(newFinalTradesRegions);
    setTradeRegionTableConfig({
      ...tradeRegionTableConfig,
      pagination: {
        ...tradeRegionTableConfig.pagination,
        totalResults: newFinalTradesRegions.length,
      },
    });

    updatePaginatedTradeRegions(
      newFinalTradesRegions,
      tradeRegionTableConfig.pagination.currentPage,
      tradeRegionTableConfig.pagination.perPage,
    );
    setOutput({
      tradeRegions: newFinalTradesRegions.map(tradeRegion => ({
        tradeId: tradeRegion.tradeId,
        selfPerform: tradeRegion.selfPerforming === 'Yes' ? 1 : 0,
        zipCodePostalCode: tradeRegion.zipCodePostalCode,
        countryCode: tradeRegion.countryCode,
        stateProvinceCode: tradeRegion.stateProvinceCode,
        countyDistrictCode: tradeRegion.countyDistrictCode,
        cityName: tradeRegion.cityName,
      })),
    });
  }, [
    selectedTrades,
    address,
    tradeRegions,
    tradeRegionTableConfig,
    addressObject,
    selfPerforming,
    addressIsSelected,
  ]);

  const handleRemoveTradeRegion = (index: number) => {
    const newTradesRegions = tradeRegions.filter((_, i) => i !== index);
    setTradeRegionTableConfig({
      ...tradeRegionTableConfig,
      pagination: {
        ...tradeRegionTableConfig.pagination,
        totalResults: newTradesRegions.length,
      },
    });
    setTradesRegions(newTradesRegions);
    updatePaginatedTradeRegions(
      newTradesRegions,
      tradeRegionTableConfig.pagination.currentPage,
      tradeRegionTableConfig.pagination.perPage,
    );
    setOutput({
      tradeRegions: output?.tradeRegions?.filter((_, i) => i !== index) || [],
    });
  };

  const handleSelectAddress = useCallback(async (address: string) => {
    const result = await geocodeByAddress(address);
    const addressComponents = result[0]['address_components'];
    const addressObjectTemp = {};
    addressComponents.forEach(component => {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressObjectTemp[`line1`] = `${component.long_name}`;
          break;
        }
        case 'route': {
          const line1 = addressObjectTemp[`line1`];
          addressObjectTemp[`line1`] = `${line1 ? line1 + ' ' : ''}${
            component.long_name
          }`;
          break;
        }
        case 'postal_code': {
          addressObjectTemp[`zipCodePostalCode`] = `${normalizeZipcode(
            component.long_name,
          )}`;
          break;
        }
        case 'locality':
          addressObjectTemp[`cityName`] = component.long_name;
          break;

        case 'administrative_area_level_1': {
          addressObjectTemp[`stateProvinceCode`] = component.short_name;
          break;
        }
        case 'administrative_area_level_2': {
          addressObjectTemp[`countyDistrictCode`] = component.short_name;
          break;
        }
        case 'country':
          addressObjectTemp[`country`] = component.long_name;
          addressObjectTemp[`countryCode`] = component.short_name;
          break;
      }
    });

    if (
      addressObjectTemp[`countyDistrictCode`] &&
      (addressObjectTemp['zipCodePostalCode'] || addressObjectTemp['cityName'])
    ) {
      delete addressObjectTemp[`countyDistrictCode`];
    }

    setAddressIsSelected(true);
    setAddressObject(addressObjectTemp);
    setAddress(result[0].formatted_address);
  }, []);

  const updatePaginatedTradeRegions = useCallback(
    (tradeRegionsList: any[], page: number, perPage: number) => {
      setPaginatedTradeRegions(
        tradeRegionsList.slice(page * perPage, perPage * page + perPage),
      );
    },
    [],
  );

  const handlePageChange = useCallback(
    (newPage: number, perPage: number) => {
      setTradeRegionTableConfig({
        ...tradeRegionTableConfig,
        pagination: {
          ...tradeRegionTableConfig.pagination,
          currentPage: newPage,
          perPage,
        },
      });
      updatePaginatedTradeRegions(tradeRegions, newPage, perPage);
    },
    [tradeRegions, updatePaginatedTradeRegions],
  );

  return (
    <ECBox display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <ECFormControl sx={{ width: '100%' }}>
        <ECChipAutocomplete
          loading={tradesOptions?.length === 0}
          options={tradesOptions}
          title="Trades"
          values={
            Array.isArray(selectedTrades)
              ? (selectedTrades as Chip[])
              : undefined
          }
          getOptionLabel={option => option?.label || option?.text || option}
          placeholder="Trades"
          variant="filled"
          onChange={newValue => {
            setTradesErrorMessage('');
            setSelectedTrades(newValue);
          }}
          errorMessage={tradesErrorMessage}
        />
      </ECFormControl>

      <ECFormControl sx={{ width: '100%', marginTop: '1rem' }}>
        <PlacesAutocomplete
          value={address}
          searchOptions={{
            componentRestrictions: {
              country: COUNTRY_CODES,
            },
            types: TYPES_REGIONS,
          }}
          onChange={address => {
            setAddressErrorMessage('');
            setAddressObject({});
            setAddressIsSelected(false);
            setAddress(address);
          }}
          onSelect={handleSelectAddress}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <>
              <ECTextField
                {...getInputProps({
                  id: 'addressId',
                  name: 'addressName',
                  label: 'Region Name',
                  placeholder:
                    'Type any City, State, Region, Providence, Zip Code or Country Name...',
                  autoComplete: false,
                })}
                sx={{
                  mb: 2,
                  width: '100%',
                  '& .MuiFilledInput-root': {
                    bgcolor: theme =>
                      `${theme.palette.common.white} !important`,
                    '&:hover': {
                      bgcolor: theme => theme.palette.common.white,
                    },
                    '&.Mui-focused': {
                      bgcolor: theme => theme.palette.common.white,
                    },
                    '&.Mui-focused:after': {
                      bgcolor: theme => theme.palette.common.white,
                    },
                  },
                }}
                inputProps={{
                  maxLength: 500,
                }}
                error={addressErrorMessage.length > 0}
                helperText={addressErrorMessage}
                autoComplete="off"
                InputLabelProps={address ? { shrink: true } : {}}
                variant="filled"
              />
              {loading && <div>Loading...</div>}
              <ECCard
                key={`AddressList`}
                sx={theme => ({
                  position: 'absolute',
                  backgroundColor: theme.palette.background.paper,
                  zIndex: '1001',
                })}
              >
                {suggestions.map(suggestion => {
                  return (
                    <ECMenuItem
                      key={suggestion}
                      {...getSuggestionItemProps(suggestion, {})}
                    >
                      <ECTypography variant="inherit">
                        {suggestion.description}
                      </ECTypography>
                    </ECMenuItem>
                  );
                })}
              </ECCard>
            </>
          )}
        </PlacesAutocomplete>
      </ECFormControl>

      <ECFormControl
        sx={{
          width: '100%',
          marginTop: '1rem',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <ECStack direction="row" spacing={1}>
          <FormControlLabel
            value="end"
            control={
              <ECSwitch
                id={'self-performing-switch'}
                name={'self-performing-switch'}
                checked={selfPerforming ? true : false}
                value={selfPerforming}
                onChange={e => {
                  setSelfPerforming(e.target.checked);
                }}
              />
            }
            label="Self Performing"
            labelPlacement="end"
          />
          <ECButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddTradesRegions}
          >
            {t('translation:dynamicForm.addEntry')}
          </ECButton>
        </ECStack>
      </ECFormControl>

      {tradeRegions?.length > 0 && (
        <ECFormControl sx={{ width: '100%', marginTop: '1rem' }}>
          <ECEasyTable
            config={tradeRegionTableConfig}
            data={paginatedTradeRegions}
            isLoading={false}
            headerHeight={5}
            onPageChange={handlePageChange}
            onPerPageChange={handlePageChange}
            onRemove={handleRemoveTradeRegion}
            shouldNotUseActiveFilter
          />
        </ECFormControl>
      )}
    </ECBox>
  );
};
