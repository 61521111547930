import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
  },
  reducers: {
    setToken: (auth, action) => {
      auth.token = action.payload.token;
    },
  },
});

export const { setToken } = slice.actions;

export default slice.reducer;
