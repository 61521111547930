export const clearFormFields = (form: any[]) => {
  const clearedForm = form.map((field: any) => {
    field.value = null;

    if (field.subFields) {
      field.subFields = clearFormFields(field.subFields);
    }

    return field;
  });

  return clearedForm;
};
