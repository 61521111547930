import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['ModuleDetails'],
});

export const moduleApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getModuleDetailsById: builder.query<
      any,
      { moduleName: string; id: string }
    >({
      query: ({ moduleName, id }) => {
        if (!moduleName || !id) {
          return {
            url: '',
          };
        }

        return {
          url: `${moduleName}/${id}`,
        };
      },
      providesTags: ['ModuleDetails'],
    }),
  }),
});

export const {
  useGetModuleDetailsByIdQuery,
  useLazyGetModuleDetailsByIdQuery,
} = moduleApi;
