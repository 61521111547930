import React, { useMemo, useState } from 'react';
import { FormHelperText } from '@mui/material';
import { ECAutocomplete } from '../ECAutocomplete';
import { ECMenuItem } from '../ECSelect';
import { ECCheckbox } from '../ECCheckbox';
import { ECListItem, ECListItemText } from '../ECList';

export interface ECCheckboxAutocompleteProps {
  options: any[];
  label?: string;
  placeholder?: string;
  values?: any[];
  errorMessage?: string;
  readOnly?: boolean;
  getOptionLabel?: (option: any) => string;
  onChange?: (value: any[]) => void;
}

export const ECCheckboxAutocomplete: React.FC<ECCheckboxAutocompleteProps> = ({
  options,
  label,
  placeholder,
  values = [],
  errorMessage,
  readOnly,
  getOptionLabel = option => option.label,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  const customOptions = useMemo(() => {
    return [{ fieldName: 'all', label: 'Select All' }, ...options];
  }, [options]);

  const handleChange = (option, isSelected) => {
    if (option.fieldName === 'all') {
      onChange?.(isSelected ? options : []);
    } else {
      const newValues = isSelected
        ? [...values, option]
        : values.filter(val => val !== option);
      onChange?.(newValues);
    }
  };

  return (
    <>
      <ECAutocomplete
        multiple
        label={label}
        placeholder={values.length > 0 ? '' : placeholder}
        id="checkbox-autocomplete"
        options={customOptions}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        value={values}
        onChange={(event, newValue) => onChange?.(newValue)}
        renderTags={(value, getTagProps) => {
          const overflowCount = value.length - 3;
          return value.length > 3
            ? `${value
                .slice(0, 3)
                .map(getOptionLabel)
                .join(', ')} & ${overflowCount} more`
            : value.map(getOptionLabel).join(', ');
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        readOnly={readOnly}
        renderOption={(props, option, { selected }) => {
          if (option.fieldName === 'all') {
            return (
              <ECListItem
                {...props}
                key={option.fieldName}
                onClick={() => {
                  handleChange(option, values.length !== options.length);
                }}
              >
                <ECCheckbox
                  checked={values.length === options.length}
                  onChange={e => handleChange(option, e.target.checked)}
                />
                <ECListItemText primary={option.label} />
              </ECListItem>
            );
          } else {
            return (
              <ECMenuItem
                {...props}
                sx={{ width: '100%' }}
                key={option.fieldName}
              >
                <ECCheckbox
                  checked={selected}
                  onChange={e => handleChange(option, e.target.checked)}
                />
                {getOptionLabel(option)}
              </ECMenuItem>
            );
          }
        }}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};
