import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import {
  useLazyGetServiceProviderInvitationListQuery,
  useLazyResendInviteQuery,
  useLazyGetServiceProviderInvitationByIdQuery,
  serviceProviderApi,
} from 'services/serviceProviderApi';
import { loadPageScopes } from 'utils/pageScopes';
import { ECIconButton } from 'app/components';
import { ECTooltip } from 'app/components/ECTooltip';
import { Link } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { InvitationStatus } from 'types/ServiceProviderTypes';

//const fancyFormElementsCreate = require('./fancy_form_config_create.json');
//const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function SPInvitedPage() {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [trigger, result] = useLazyGetServiceProviderInvitationByIdQuery();
  const [resendInviteTrigger, resendInviteResult] = useLazyResendInviteQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('serviceProviders');
  }, []);

  const copySPInvitationUrlToClipboard = useCallback(() => {
    if (result.isSuccess) {
      navigator.clipboard
        .writeText(result.data?.url)
        .then(() => {
          dispatch(
            setSnackbar({
              message: 'The invitation was successfully copied.',
              severity: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(
            setSnackbar({
              message: 'Error copying invitation link to clipboard',
              severity: 'error',
            }),
          );
        });
    }
  }, [result]);

  useEffect(() => {
    if (selectedRow) {
      trigger(selectedRow);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (result.isSuccess) {
      copySPInvitationUrlToClipboard();
    }
  }, [result]);

  const handleCopyInvitationLink = (row: any) => {
    if (row !== selectedRow) {
      setSelectedRow(row);
    } else {
      copySPInvitationUrlToClipboard();
    }
  };

  const renderCopyInvitationLinkButton = (row: any) => {
    return (
      <ECIconButton
        key={`${row?.id}-copy-invitation-link`}
        type="info"
        withBorder={true}
        onClick={() => {
          handleCopyInvitationLink(row);
        }}
      >
        <>
          <ECTooltip title="Copy invitation link" arrow placement="top">
            <Link color="primary" />
          </ECTooltip>
        </>
      </ECIconButton>
    );
  };

  const handleMultiSelectTableAction = async (selectedRows: any[]) => {
    await Promise.allSettled(
      selectedRows.map(row => resendInviteTrigger(row.id)),
    );
  };

  useEffect(() => {
    if (resendInviteResult.isSuccess && !resendInviteResult.isFetching) {
      dispatch(
        setSnackbar({
          message: `Service Providers were successfully reinvited.`,
          severity: 'success',
        }),
      );
      dispatch(serviceProviderApi.util.invalidateTags(['ServiceProvider']));
    }
  }, [resendInviteResult.isSuccess, resendInviteResult.isFetching]);

  useEffect(() => {
    if (resendInviteResult.isError && !resendInviteResult.isFetching) {
      dispatch(
        setSnackbar({
          message: 'Error resending invitation',
          severity: 'error',
        }),
      );
    }
  }, [resendInviteResult.isError, resendInviteResult.isFetching]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:panelDrawer.invitedServiceProviders')}
      useLazyGetListQuery={useLazyGetServiceProviderInvitationListQuery}
      showStatusActiveFilter={true}
      useCreateMutation={() => false}
      //useUpdateMutation={useUpdatePrioritiesMutation}
      //createFormConfig={fancyFormElementsCreate.data.config}
      //createFormFields={fancyFormElementsCreate.data.fields}
      //editFormConfig={fancyFormElementsEdit.data.config}
      //editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      renderCustomActionComponent={renderCopyInvitationLinkButton}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      withDrawer={false}
      multiSelectTable={true}
      multiSelectTableConfig={{
        actionButton: {
          label: 'REINVITE',
          onClick: handleMultiSelectTableAction,
        },
        confirmActionModal: {
          body: {
            action: 'Reinvite',
            module: 'Service Providers',
          },
          confirmButtonLabel: 'YES, REINVITE',
          cancelButtonLabel: 'CANCEL',
        },
      }}
      customActiveFilter={{
        paramName: 'status',
        value: InvitationStatus.Pending,
      }}
      customParams={{
        status: InvitationStatus.Rejected,
      }}
      shouldNotUseActiveFilter
      statusFilterInitialSelected={true}
      activeStatusFilterActiveLabel={'Pending'}
      activeStatusFilterInactiveLabel={'Rejected'}
    />
  );
}
