// Need to use the React-specific entry point to import createApi
import { AddRolesToUserParams, DeleteRolesFromUserParams } from 'types/User';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['User', 'UserRoles'],
});

// Define a service using a base URL and expected endpoints
export const userRolesApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    addRolesToUser: builder.mutation<void, AddRolesToUserParams>({
      query: params => {
        return {
          url: `user/${params.userId}/role`,
          method: 'post',
          body: params.roleIds,
        };
      },
      invalidatesTags: ['User', 'UserRoles'],
    }),
    deleteRolesFromUser: builder.mutation<void, DeleteRolesFromUserParams>({
      query: params => {
        return {
          url: `user/${params.userId}/role`,
          method: 'delete',
          body: params.roleIds,
        };
      },
      invalidatesTags: ['User', 'UserRoles'],
    }),
  }),
});

export const { useAddRolesToUserMutation, useDeleteRolesFromUserMutation } =
  userRolesApi;
