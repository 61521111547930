import { useMemo } from 'react';

// porp-types is a library for typechecking of props
// import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Bar } from 'react-chartjs-2';

import {
  ECBox,
  ECChartContainerCard,
  ECChartIconContainerCard,
  ECTypography,
} from 'app/components';

// HorizontalBarChart configurations
import configs from 'app/components/ECBarCharts/HorizontalBarChart/configs';

function ECHorizontalBarChart({
  icon,
  color,
  title,
  description,
  height,
  chart,
}) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map(dataset => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: dataset.backgroundColor,
        fill: false,
        maxBarThickness: 35,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <ECBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <ECBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <ECChartIconContainerCard color={color}>
              {icon.component}
            </ECChartIconContainerCard>
          )}
          <ECBox mt={icon.component ? -2 : 0} ml={icon.component ? 2 : 0}>
            {title && <ECTypography variant="h6">{title}</ECTypography>}
            <ECBox mb={2}>
              <ECTypography variant="button" color="text">
                {description}
              </ECTypography>
            </ECBox>
          </ECBox>
        </ECBox>
      ) : null}
      {useMemo(
        () => (
          <ECBox
            sx={{
              height,
            }}
          >
            {/* <Bar data={data} options={options} /> */}
          </ECBox>
        ),
        [data, options, height],
      )}
    </ECBox>
  );

  return title || description ? (
    <ECChartContainerCard>{renderChart}</ECChartContainerCard>
  ) : (
    renderChart
  );
}

// Setting default values for the props of HorizontalBarChart
ECHorizontalBarChart.defaultProps = {
  icon: { color: 'info', component: '' },
  title: '',
  description: '',
  height: '19.125rem',
};

// Typechecking props for the HorizontalBarChart
// ECHorizontalBarChart.propTypes = {
//   icon: PropTypes.shape({
//     color: PropTypes.oneOf([
//       'primary',
//       'secondary',
//       'info',
//       'success',
//       'warning',
//       'error',
//       'light',
//       'dark',
//     ]),
//     component: PropTypes.node,
//   }),
//   title: PropTypes.string,
//   description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   chart: PropTypes.objectOf(PropTypes.array).isRequired,
// };

export default ECHorizontalBarChart;
