import {
  ECBox,
  ECEasyTable,
  ECGrid,
  ECStack,
  ECTextField,
  ECList,
  ECListItemText,
  ECListItemButton,
  ECEasyTableConfigType,
  initTableConfig,
  ECEasyTableDataRequestParamsType,
  NAV_BAR_HEIGHT_PX,
  TABS_HEIGHT_IN_PX,
  ECFormControl,
  ECTypography,
  ECButton,
} from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetTradesListQuery } from 'services/tradeApi';
import { TradeTypes } from 'types';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { useGetCompanyServiceProvidersListQuery } from 'services/serviceProviderApi';
import { ECAutocompletePaginated } from 'app/components/ECAutocompletePaginated';
import { useLazyGetBranchListBySpAndTradeQuery } from 'services/branchApi';
import { ServiceProvider } from 'types/ServiceProviderTypes';
import { Edit } from '@mui/icons-material';
import { BulkEditDrawer } from './BulkEditDrawer';
import { OrganizationIdTypeEnum } from 'types/Organization';

export function TradeAssignmentBySpPage() {
  const { t } = useTranslation();

  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);

  const [listHeight, setListHeight] = useState(0);

  const [selectedSp, setSelectedSp] = useState<ServiceProvider | null>(null);

  const [tradeTrigger, tradehResult] = useLazyGetTradesListQuery();
  const { data: tradesData, isSuccess: isSuccessTrades } = tradehResult;

  const [trigger, result] = useLazyGetBranchListBySpAndTradeQuery();

  const {
    data: tradeAssigmnents,
    isSuccess: isSuccessTradeAssigments,
    isFetching,
  } = result;

  const [selectedTrade, setSelectedTrade] = useState<TradeTypes | undefined>(
    undefined,
  );
  const [trades, setTrades] = useState<TradeTypes[] | undefined>(undefined);
  const [configState, setConfigState] =
    useState<ECEasyTableConfigType>(initTableConfig);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<ECEasyTableDataRequestParamsType>({
    pagination: {
      currentPage: 0,
      perPage: 50,
    },
    sort: {
      fieldName: '',
      value: '',
    },
  });

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  useEffect(() => {
    if (isSuccessTradeAssigments) {
      setConfigState(
        getConfigStateFromApiOrMock(tradeAssigmnents, configState, t),
      );
    }
  }, [isSuccessTradeAssigments, tradeAssigmnents]);

  useEffect(() => {
    if (isSuccessTrades) {
      setSelectedTrade(tradesData?.data[0]);
      setTrades(tradesData?.data);
    }
  }, [tradesData, isSuccessTrades]);

  const handleTradeSelect = (trade: TradeTypes) => {
    setSelectedTrade(trade);
  };

  useEffect(() => {
    if (selectedTrade?.id && selectedSp?.id) {
      const bodyParams: any = {
        spId: selectedSp?.id,
        tradeId: selectedTrade.id,
        id: selectedTrade.id,
        t: params.pagination.perPage,
        p: params.pagination.currentPage,
        o: params.sort?.value === 'ASC' ? 'a' : 'd',
        ob: params.sort?.fieldName,
        // search by branch name
        sb1: 'brnch.name',
        s1: activeFilter?.filterFields?.[0]?.fieldName
          ? `*${activeFilter?.filterFields?.[0]?.value
              ?.toString()
              ?.toLowerCase()}*`
          : '',
        sglo1: 'or',
        // search by branch id
        sb2: 'brnch.id',
        s2: activeFilter?.filterFields?.[0]?.fieldName
          ? `*${activeFilter?.filterFields?.[0]?.value
              ?.toString()
              ?.toLowerCase()}*`
          : '',
        sglo2: 'or',
        // search by org
        sb3: 'orgnztnp.name',
        s3: activeFilter?.filterFields?.[0]?.fieldName
          ? `*${activeFilter?.filterFields?.[0]?.value
              ?.toString()
              ?.toLowerCase()}*`
          : '',
        sglo3: 'or',
      };

      const regionParentIds = activeFilter?.hierarchy
        ?.filter(org => org.organizationType === OrganizationIdTypeEnum.Region)
        ?.map(org => org.nodeId);
      const districtParentIds = activeFilter?.hierarchy
        ?.filter(
          org => org.organizationType === OrganizationIdTypeEnum.District,
        )
        ?.map(org => org.nodeId);

      if (regionParentIds?.length) {
        bodyParams.regionParentIds = regionParentIds;
      }
      if (districtParentIds?.length) {
        bodyParams.districtParentIds = districtParentIds;
      }

      if (activeFilter?.users?.length) {
        bodyParams.userIds = activeFilter?.users?.map(user => user.nodeId);
      }

      trigger(
        bodyParams,
        // param below is to cache lazy query
        true,
      );
    }
  }, [selectedTrade, params, activeFilter, selectedSp]);

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    tradeTrigger({
      p: 0,
      t: params.pagination.perPage * 3,
      o: 'a',
      ob: 'trd.name',
      sb: 'trd.name',
      s: `*${search}*`,
    });
  }, [search]);

  const handleDataRequest = (params?: ECEasyTableDataRequestParamsType) => {
    if (params) {
      setParams(params);
    }
  };

  const tradesList = useMemo(() => {
    return trades?.map(coreTrade => (
      <ECListItemButton
        selected={selectedTrade?.id === coreTrade.id}
        onClick={() => handleTradeSelect(coreTrade)}
      >
        <ECListItemText primary={coreTrade.name} />
      </ECListItemButton>
    ));
  }, [trades, selectedTrade]);

  const handleSelectRow = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setDisableEditButton(shouldDisable);
    setClearAllSelectedRows(shouldDisable);
    setSelectedRows(rows);
  }, []);

  const handleCloseBulkEditModal = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setIsBulkEditDrawerOpen(true);
  }, []);

  return (
    <ECBox display="flex" flexDirection="column" flex={1}>
      <ECGrid mt={0} overflow="hidden" container spacing={1}>
        <ECGrid mt={0} pr={1} item xs={3} sx={{ paddingTop: '0px !important' }}>
          <ECTypography variant="h5" fontWeight={600} pl={2} py={1}>
            Service Provider & Trades
          </ECTypography>
          <ECFormControl sx={{ width: '100%', marginBottom: '10px' }}>
            <ECAutocompletePaginated
              sx={{ width: '100%' }}
              fieldName="serviceProvider"
              value={selectedSp}
              placeholder={'Service Provider'}
              variant="filled"
              useQuery={useGetCompanyServiceProvidersListQuery}
              obAlias="cmpny.name"
              onChange={setSelectedSp}
              queryParams={{
                t: 10,
                st: 1,
                ob: 'cmpny.name',
                o: 'a',
              }}
              shouldUseFirstOptionAsDefault={true}
            />
          </ECFormControl>

          <ECBox p={1}>
            <ECTextField
              fullWidth
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          </ECBox>

          <ECStack
            minHeight={
              window.innerHeight - NAV_BAR_HEIGHT_PX - TABS_HEIGHT_IN_PX
            }
            height={listHeight}
            overflow="auto"
            direction="column"
            spacing={2}
          >
            <ECList noPadding>{tradesList}</ECList>
          </ECStack>
        </ECGrid>

        <ECGrid
          item
          xs={9}
          border={15}
          borderColor={theme =>
            theme.palette.marketingSuggestion.mainBackground
          }
          sx={{ paddingTop: '0px !important' }}
        >
          <ECTypography
            variant="h5"
            fontWeight={600}
            mb={7}
            ml={-1}
            bgcolor={'rgba(0, 0, 0, 0.12)'}
            pl={2}
            py={1}
          >
            {selectedTrade?.name}
          </ECTypography>
          {isSuccessTradeAssigments && configState.cols.length > 0 && (
            <ECEasyTable
              config={configState}
              data={tradeAssigmnents?.data}
              onDataRequest={handleDataRequest}
              isLoading={isFetching}
              onLayout={(height, _) => setListHeight(height)}
              // multiSelectTable props below
              multiSelectTable
              multiSelectedRows={handleSelectRow}
              clearSelection={clearAllSelectedRows}
              selectRowsMaxCount={50}
              showHierarchyFilter
              showUserFilter
              maxSelectionUsersFilter={10}
              shouldNotUseActiveFilter
              customMultiSelectActionButton={() => (
                <ECButton
                  variant="outlined"
                  startIcon={<Edit />}
                  disabled={disableEditButton}
                  onClick={handleOpenDrawer}
                  sx={{ marginRight: 'auto !important' }}
                >
                  {t('translation:dynamicForm.bulk')}
                </ECButton>
              )}
            />
          )}
        </ECGrid>
      </ECGrid>
      {isBulkEditDrawerOpen && selectedSp?.id && selectedTrade?.id && (
        <BulkEditDrawer
          isOpen={isBulkEditDrawerOpen}
          onClose={handleCloseBulkEditModal}
          selectedRows={selectedRows}
          spCompanyId={selectedSp.id}
          tradeId={selectedTrade.id}
        />
      )}
    </ECBox>
  );
}
