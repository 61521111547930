// Need to use the React-specific entry point to import createApi
import { QueryParams } from 'types/QueryParams';
import { Repair, RepairUpdate } from 'types/RepairType';
import { BaseType } from 'types/BaseType';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Repair', 'RepairForAsset'],
});

// Define a service using a base URL and expected endpoints
export const repairApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    addRepairType: builder.mutation({
      query: body => {
        return {
          url: 'failure',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Repair'],
    }),
    editRepairType: builder.mutation<void, RepairUpdate>({
      query: (body: Repair) => {
        const id = body.id;
        const reducedBody: RepairUpdate = {
          name: body.name,
          status: body.status,
        };
        return {
          url: `failure/${id}`,
          method: 'put',
          body: reducedBody,
        };
      },
      invalidatesTags: ['Repair'],
    }),
    deleteRepairType: builder.mutation({
      query: body => {
        return {
          url: 'failure',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Repair'],
    }),
    getRepairsList: builder.query<
      BaseType<Repair[]>,
      (QueryParams & { companyId?: number }) | undefined
    >({
      query: params => {
        return {
          url: 'failure',
          params,
        };
      },
      providesTags: ['Repair'],
    }),
    getRepairsListForAsset: builder.query<Repair[], string | undefined>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `failure/asset/${id}`,
        };
      },
      providesTags: ['RepairForAsset'],
    }),
  }),
});

export const {
  useAddRepairTypeMutation,
  useEditRepairTypeMutation,
  useDeleteRepairTypeMutation,
  useGetRepairsListQuery,
  useLazyGetRepairsListQuery,
  useGetRepairsListForAssetQuery,
  useLazyGetRepairsListForAssetQuery,
} = repairApi;
