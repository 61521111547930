import { ECBox, ECButton } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { useCallback, useState } from 'react';
import { AddNewForm } from './AddNewForm';
import { Add } from '@mui/icons-material';

export const AddNew = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ECButton startIcon={<Add />} onClick={handleOpen} variant="outlined">
        Add New
      </ECButton>

      <ECDrawerDetails open={open} anchor="right" onClose={handleClose}>
        <ECBox pt={5} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={<AddNewForm onClose={handleClose} />}
            showOnlyDetailsTab={true}
            onClose={handleClose}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
