import { useEffect } from 'react';
import { ECBox, ECEasyForm, ECLinearProgress } from 'app/components';
import { useLazyGetPermissionsListQuery } from 'services/permissionApi';
import {
  ECEasyTransferList,
  TransferListData,
} from '../../components/ECEasyTransferList';
import { Permission } from '../../../types/Permission';
import {
  useAddPermissionsToRoleMutation,
  useCreateRoleMutation,
} from '../../../services/roleApi';
import { Role } from '../../../types/Role';

const fancyFormElements = require('./fancy_form_config_create_role.json');

let transferListData: TransferListData;
let lastCreatedRole: Role = {
  roleId: -1,
  name: '',
};

export function CreateRole({ onClose, onRoleAdded }) {
  const [trigger, result] = useLazyGetPermissionsListQuery();

  const [
    addPermissionsToRoleTrigger,
    { isLoading: addPermissionsToRoleIsLoading },
  ] = useAddPermissionsToRoleMutation();

  const [createRoleTrigger, { isLoading: createRoleIsLoading }] =
    useCreateRoleMutation({
      selectFromResult: createRoleResult => {
        if (
          createRoleResult.data &&
          createRoleResult.data.roleId.toString() !==
            lastCreatedRole.roleId.toString()
        ) {
          lastCreatedRole = createRoleResult.data;
          const permissionIds = transferListData.right.map(
            (item: Permission) => item.permissionId,
          );
          addPermissionsToRoleTrigger({
            roleId: +createRoleResult.data.roleId,
            permissionIds: permissionIds,
          });
          onClose();
          onRoleAdded({
            roleId: createRoleResult.data.roleId,
            name: createRoleResult.data.name,
            permissionIds,
          });
        }
        return createRoleResult;
      },
    });

  const { data, isLoading } = result;

  const fetchPermissions = () => {
    trigger({});
  };

  const init = () => {
    transferListData = {
      right: [],
      left: [],
      checked: [],
    };
    fetchPermissions();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ECEasyForm
      config={fancyFormElements.data.config}
      fields={fancyFormElements.data.fields}
      isSendingData={createRoleIsLoading || addPermissionsToRoleIsLoading}
      isLoadingForm={false}
      additionalElements={
        <>
          <ECBox width={'100%'} p={2} pr={0}>
            {data && !isLoading ? (
              <ECEasyTransferList
                initialData={{ right: [], checked: [], left: data }}
                isObjectArray={true}
                dataPath={'name'}
                onDataChange={data => {
                  transferListData = data;
                }}
                isLoading={isLoading}
                leftTitle={undefined}
                rightTitle={undefined}
              />
            ) : (
              <ECLinearProgress />
            )}
          </ECBox>
        </>
      }
      onFormSubmit={formData => {
        const roleName = formData.get('name');
        if (roleName) {
          createRoleTrigger({
            name: roleName.toString(),
          });
        }
      }}
    />
  );
}
