import { QueryParams } from 'types/QueryParams';
import {
  WorkOrdersList,
  WorkOrderUpdate,
  WorkOrderGetById,
  WorkOrderFlags,
  WorkOrderCreate,
  SPWorkOrder,
  UpdateSPWorkOrder,
  WorkOrderUpdateEta,
  GetWOLinkedParams,
  WOLinkedParamsMutate,
} from 'types/WorkOrders';
import { BaseType } from 'types/BaseType';
import {
  WorkflowNextStatus,
  WorkflowStatusUpdate,
  WorkflowStepActionUpdate,
} from 'types/WorkflowStatus';
import daysPassedSince from 'utils/datediff';
import { updateEtaStatusToShow } from 'app/components/ECWorkOrderUpdateEtaModalContent';
import { rejectStatusToShow } from 'app/components/ECWorkOrderRejectModalContent';
import { convertToISOString } from 'utils/dates';
import { groupAndProcessInventoryItems } from 'utils/common';

import { emptyApi } from './emptyApi';
import _ from 'lodash';
import { Category } from 'types/Category';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'WorkOrders',
    'WorkOrderById',
    'WorkOrderStatusList',
    'PendingWorkorders',
    'Dispatch',
    'DispatchColumn',
    'DispatchColumnUnassigned',
  ],
});

export const workOrdersApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    //GET
    getWorkOrdersList: builder.query<BaseType<WorkOrdersList[]>, QueryParams>({
      query: params => {
        let updatedParams = { ...params };
        let lastIndex = -1;
        for (let i = 0; i < Object?.keys(updatedParams)?.length; i++) {
          const key = Object?.keys(updatedParams)[i];
          if (key?.startsWith('sb')) {
            lastIndex = i;
          }
        }

        for (let i = 0; i <= lastIndex; i++) {
          const sbKey = `sb${i}`;
          const sKey = `s${i}`;
          let sKeyItems =
            typeof updatedParams[sKey] === 'string'
              ? updatedParams[sKey]?.split('%20')
              : String(updatedParams[sKey])?.split(' ');

          const isFullName = key =>
            updatedParams[sbKey]?.includes(key) &&
            updatedParams[`sglo${i}`] === 'and';

          const replaceKey = addedColumn => {
            if (sKeyItems.length > 0) {
              let valueToAdd = sKeyItems[0];
              if (sKeyItems[0]?.charAt(sKeyItems[0]?.length - 1) !== '*') {
                valueToAdd += '*';
              }
              updatedParams[sKey] = valueToAdd;
              updatedParams[`sb${lastIndex + 1}`] = addedColumn;
              updatedParams[`sg${lastIndex + 1}`] = updatedParams[`sg${i}`];

              let valueToAddedColumn = sKeyItems[sKeyItems?.length - 1];
              if (sKeyItems[sKeyItems.length - 1]?.charAt(0) !== '*') {
                valueToAddedColumn = '*' + valueToAddedColumn;
              }
              updatedParams[`s${lastIndex + 1}`] = valueToAddedColumn;
              updatedParams[`sglo${lastIndex + 1}`] =
                sKeyItems.length === 1 ? 'or' : 'and';
            }
          };
          if (sKeyItems && sKeyItems.length > 0) {
            if (isFullName('usrassignee.firstName')) {
              replaceKey('usrassignee.lastName');
            }
            if (isFullName('usrassigneeSPUser.firstName')) {
              replaceKey('usrassigneeSPUser.lastName');
            }
          }
        }

        return {
          url: 'workorder',
          params: {
            ...updatedParams,
            ob: updatedParams.ob || 'wrkordr.id',
          },
        };
      },

      transformResponse: (response: BaseType<WorkOrdersList[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'workorder',
          workOrderAge: daysPassedSince(row.createdAt),
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['WorkOrders', 'PendingWorkorders'],
    }),

    getDefaultCategories: builder.query<BaseType<Category[]>, number[] | void>({
      query: (companyId?: number[]) => {
        return {
          url: 'category/default',
          params: companyId ? { companyId } : {},
        };
      },
    }),

    getCategoriesByCompanyList: builder.query<
      BaseType<Category[]>,
      number[] | void
    >({
      query: (companyId?: number[]) => {
        return {
          url: 'category/getByCompany',
          params: companyId ? { companyId } : {},
        };
      },
    }),

    //GET NEXT WORKFLOW STATUS DROPDOWN
    getWorkOrderNextStatusList: builder.query<WorkflowNextStatus[], number>({
      query: workOrder => {
        return {
          url: `workorder/${workOrder}/status/next`,
        };
      },
      providesTags: ['WorkOrderStatusList'],
    }),

    //GET BY ID
    getWorkOrderById: builder.query<
      WorkOrderGetById,
      { id: number; includeLastCommentUserInfo?: number }
    >({
      query: ({ id, includeLastCommentUserInfo }) => {
        return {
          url: `workorder/${id}`,
          params: {
            ...(includeLastCommentUserInfo !== undefined && {
              includeLastCommentUserInfo,
            }),
          },
        };
      },
      transformResponse: (response: WorkOrderGetById) => {
        const { allocatedInventory, ...res } = response;
        return {
          ...res,
          workOrderAge: daysPassedSince(response.workOrderDate),
          assigneeUser: {
            ...response?.assigneeUser,
            name: response?.assigneeUser?.fullName,
          },
          moduleName: 'workorder',
          _allocatedInventory: response.allocatedInventory,
          processedAllocatedInventory: groupAndProcessInventoryItems(
            response.allocatedInventory,
          ),
        };
      },
      providesTags: ['WorkOrderById'],
    }),

    //GET Possibe WO links
    getPossibleWOLinks: builder.query<any, GetWOLinkedParams>({
      query: ({ woId, params }) => {
        return {
          url: `workorder/${woId}/possible-links`,
          params,
        };
      },
    }),

    createLinkedWorkorders: builder.mutation<any, WOLinkedParamsMutate>({
      query: ({ workorderId, linkedWorkordersIds }) => {
        return {
          url: `workorder/${workorderId}/link`,
          body: { linkedWorkordersIds },
          method: 'post',
        };
      },
    }),

    deleteLinkedWorkorders: builder.mutation<void, WOLinkedParamsMutate>({
      query: ({ workorderId, linkedWorkordersIds }) => ({
        url: `workorder/${workorderId}/link`,
        method: 'delete',
        body: { linkedWorkordersIds },
      }),
    }),

    getWorkOrderFlags: builder.query<WorkOrderFlags, number>({
      query: workOrder => {
        return {
          url: `workorder/${workOrder}/flags`,
          workOrder,
        };
      },
    }),
    // CREATE
    createWorkorder: builder.mutation<WorkOrdersList, WorkOrderCreate>({
      query: body => {
        const formData = new FormData();
        formData.append('assetId', body.assetId?.toString() || '0');
        formData.append('branchId', body.branchId?.toString() || '0');
        formData.append('description', body.description);
        const nonProcessedFiles: string[] = [];
        body.files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          } else {
            nonProcessedFiles.push(file.id);
          }
        });
        if (
          nonProcessedFiles.length > 0 &&
          Array.isArray(body?.copyWOAttachments) &&
          body?.copyWOAttachments.length !== 0
        ) {
          formData.append('attachmentsToCopyIds', nonProcessedFiles.join(', '));
        }

        formData.append(
          'isGeneralAsset',
          body.isGeneralAsset?.toString() || '0',
        );
        formData.append(
          'generalAssetId',
          body.generalAssetId?.toString() || '0',
        );
        formData.append(
          'overtimeApproved',
          body.overtimeApproved?.toString() || '0',
        );
        formData.append('priorityId', body.priorityId?.toString() || '0');
        formData.append('problemId', body.problemId?.toString() || '0');
        formData.append(
          'requestCategoryId',
          body.requestCategoryId?.toString() || '0',
        );
        formData.append('spCompanyId', body.spCompanyId?.toString() || '0');
        formData.append('requestedBy', body.requestedBy || '');
        if (body.originalCopiedWorkorderId) {
          formData.append(
            'originalCopiedWorkorderId',
            body.originalCopiedWorkorderId?.toString(),
          );
        }

        return {
          url: 'workorder',
          body: formData,
          method: 'post',
        };
      },
    }),
    //UPDATE
    updateWorkOrder: builder.mutation<void, WorkOrderUpdate>({
      query: ({ id, ...body }) => {
        return {
          url: `workorder/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['WorkOrderById'],
    }),

    //UPDATE WITH NO INVALIDATE TAGS
    updateWorkOrderNoInvalidate: builder.mutation<void, WorkOrderUpdate>({
      query: ({ id, ...body }) => {
        return {
          url: `workorder/${id}`,
          method: 'put',
          body,
        };
      },
    }),

    bulkWorkOrderAddCommentChange: builder.mutation<void, WorkflowStatusUpdate>(
      {
        query: body => {
          return {
            url: 'workorder/comment/change',
            method: 'put',
            body,
          };
        },
        invalidatesTags: ['PendingWorkorders'],
      },
    ),
    bulkWorkOrderNTERaise: builder.mutation<void, WorkflowStatusUpdate>({
      query: params => {
        return {
          url: 'workorder/nte/change',
          method: 'put',
          body: params,
        };
      },
      invalidatesTags: ['PendingWorkorders'],
    }),
    bulkWorkOrderCategoryChange: builder.mutation<void, WorkflowStatusUpdate>({
      query: body => {
        return {
          url: 'workorder/category/change',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['PendingWorkorders'],
    }),
    bulkWorkOrderFlagsChange: builder.mutation<void, WorkflowStatusUpdate>({
      query: body => {
        return {
          url: 'workorder/flag/change',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['PendingWorkorders'],
    }),

    workOrderActionClick: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({
        id,
        statusTo,
        declineTypeId,
        declineReason,
        note,
        recipientIds,
      }) => {
        let updatedWorkOrderStatus: any = {
          statusTo,
          declineTypeId,
          declineReason,
        };
        if (!_.isNil(note)) {
          updatedWorkOrderStatus.note = note;
        }
        if (!_.isNil(recipientIds)) {
          updatedWorkOrderStatus.recipientIds = recipientIds;
        }
        return {
          url: `workorder/${id}/status/change`,
          method: 'put',
          body: updatedWorkOrderStatus,
        };
      },
      invalidatesTags: ['WorkOrderById', 'WorkOrderStatusList'],
    }),

    spWorkOrderActionClick: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({
        id,
        statusTo,
        statusGroupName,
        declineTypeId,
        declineReason,
        note,
        recipientIds,
      }) => {
        let updatedWorkOrderStatus: any = {
          statusTo,
          statusGroupName,
          declineTypeId,
          declineReason,
          note,
          recipientIds,
        };
        return {
          url: `sp/workorder/${id}/status/change`,
          method: 'put',
          body: updatedWorkOrderStatus,
        };
      },
      invalidatesTags: ['WorkOrders', 'WorkOrderStatusList'],
    }),

    updateWorkOrderAction: builder.mutation<void, WorkflowStepActionUpdate>({
      query: ({ id, ...rawBody }) => {
        const body = {
          approvalProcessStepId: rawBody.approvalProcessStepId,
          approvalProcessId: rawBody.approvalProcessId,
          statusTo: rawBody.statusTo,
          approvalProcessActionId: rawBody.approvalProcessActionId,
          workflowLinkId: rawBody.workflowLinkId,
        };

        return {
          url: `workorder/${id}/action`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['WorkOrderById', 'WorkOrderStatusList'],
    }),

    // SERVICE PROVIDER
    getServiceProviderWorkOrdersList: builder.query<
      BaseType<WorkOrdersList[]>,
      QueryParams
    >({
      query: params => {
        let updatedParams = { ...params };
        let lastIndex = -1;

        for (let i = 0; i < Object.keys(updatedParams)?.length; i++) {
          const key = Object.keys(updatedParams)[i];
          if (key?.startsWith('sb')) {
            lastIndex = i;
          }
        }

        for (let i = 0; i <= lastIndex; i++) {
          const sbKey = `sb${i}`;
          const sKey = `s${i}`;
          let sKeyItems =
            typeof updatedParams[sKey] === 'string'
              ? updatedParams[sKey]?.split('%20')
              : String(updatedParams[sKey])?.split(' ');

          const isFullName = key =>
            updatedParams[sbKey]?.includes(key) &&
            updatedParams[`sglo${i}`] === 'and';

          const replaceKey = addedColumn => {
            if (sKeyItems.length > 0) {
              let valueToAdd = sKeyItems[0];
              if (sKeyItems[0]?.charAt(sKeyItems[0]?.length - 1) !== '*') {
                valueToAdd += '*';
              }
              updatedParams[sKey] = valueToAdd;
              updatedParams[`sb${lastIndex + 1}`] = addedColumn;
              updatedParams[`sg${lastIndex + 1}`] = updatedParams[`sg${i}`];

              let valueToAddedColumn = sKeyItems[sKeyItems?.length - 1];
              if (sKeyItems[sKeyItems.length - 1]?.charAt(0) !== '*') {
                valueToAddedColumn = '*' + valueToAddedColumn;
              }
              updatedParams[`s${lastIndex + 1}`] = valueToAddedColumn;
              updatedParams[`sglo${lastIndex + 1}`] =
                sKeyItems.length === 1 ? 'or' : 'and';
            }
          };
          if (sKeyItems && sKeyItems.length > 0) {
            if (isFullName('usr.firstName')) {
              replaceKey('usr.lastName');
            }
          }
        }
        return {
          url: 'sp/workorder',
          params: {
            ...updatedParams,
            ob: updatedParams.ob || 'wrkordr.id',
          },
        };
      },

      transformResponse: (response: BaseType<WorkOrdersList[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          actions:
            updateEtaStatusToShow.includes(
              row.workflowStatus?.name?.toUpperCase(),
            ) ||
            rejectStatusToShow.includes(row.workflowStatus?.name?.toUpperCase())
              ? ['custom']
              : null,
          moduleName: 'workorder',
          workorderAge: daysPassedSince(row.createdAt),
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['WorkOrders', 'PendingWorkorders'],
    }),

    getServiceProviderWorkOrderById: builder.query<SPWorkOrder, number>({
      query: id => {
        return {
          url: `sp/workorder/${id}`,
        };
      },
      transformResponse: (response: SPWorkOrder) => {
        return {
          ...response,
          moduleName: 'workorder',
          workorderAge: response.workorderAge + ' days',
        };
      },
      providesTags: ['WorkOrderById'],
    }),
    updateServiceProviderWorkOrder: builder.mutation<void, UpdateSPWorkOrder>({
      query: ({ id, ...body }) => {
        return {
          url: `sp/workorder/${id}`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['WorkOrderById'],
    }),
    updateWorkOrderEta: builder.mutation<void, WorkOrderUpdateEta>({
      query: body => {
        body.eta = convertToISOString(body.eta);
        return {
          url: `sp/workorder/${body.id}`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['WorkOrders', 'WorkOrderById'],
    }),

    getWorkorderDispatch: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        return {
          url: 'workorder/dispatch',
          params,
        };
      },
      transformResponse: (response: BaseType<any[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'workorder',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Dispatch'],
    }),

    getWorkorderDispatchColumnView: builder.query<BaseType<any[]>, QueryParams>(
      {
        query: params => {
          return {
            url: 'workorder/dispatch/column',
            params,
          };
        },
        providesTags: ['DispatchColumn'],
      },
    ),

    getWorkorderDispatchColumnUnassignedView: builder.query<
      BaseType<any[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'workorder/dispatch/column/unassigned',
          params,
        };
      },
      providesTags: ['DispatchColumnUnassigned'],
    }),

    assignTech: builder.mutation<
      void,
      { id: number; spAssigneeUserId: number | null }
    >({
      query: ({ id, spAssigneeUserId }) => {
        return {
          url: `workorder/${id}/technician/assign`,
          method: 'put',
          body: { spAssigneeUserId },
        };
      },
      invalidatesTags: ['WorkOrderById'],
    }),
  }),
});

export const {
  useGetWorkOrdersListQuery,
  useLazyGetWorkOrdersListQuery,
  useUpdateWorkOrderMutation,
  useUpdateWorkOrderNoInvalidateMutation,
  useLazyGetWorkOrderByIdQuery,
  useGetWorkOrderByIdQuery,
  useGetPossibleWOLinksQuery,
  useCreateLinkedWorkordersMutation,
  useDeleteLinkedWorkordersMutation,
  useLazyGetPossibleWOLinksQuery,
  useGetWorkOrderFlagsQuery,
  useGetWorkOrderNextStatusListQuery,
  useLazyGetWorkOrderNextStatusListQuery,
  useGetDefaultCategoriesQuery,
  useGetCategoriesByCompanyListQuery,
  useBulkWorkOrderAddCommentChangeMutation,
  useBulkWorkOrderNTERaiseMutation,
  useBulkWorkOrderCategoryChangeMutation,
  useBulkWorkOrderFlagsChangeMutation,
  useLazyGetCategoriesByCompanyListQuery,
  useWorkOrderActionClickMutation,
  useSpWorkOrderActionClickMutation,
  useCreateWorkorderMutation,
  useUpdateWorkOrderActionMutation,
  useGetServiceProviderWorkOrdersListQuery,
  useLazyGetServiceProviderWorkOrdersListQuery,
  useGetServiceProviderWorkOrderByIdQuery,
  useLazyGetServiceProviderWorkOrderByIdQuery,
  useUpdateServiceProviderWorkOrderMutation,
  useUpdateWorkOrderEtaMutation,

  // DISPATCH
  useGetWorkorderDispatchQuery,
  useLazyGetWorkorderDispatchQuery,
  useGetWorkorderDispatchColumnViewQuery,
  useLazyGetWorkorderDispatchColumnViewQuery,
  useGetWorkorderDispatchColumnUnassignedViewQuery,
  useLazyGetWorkorderDispatchColumnUnassignedViewQuery,
  useAssignTechMutation,
} = workOrdersApi;
