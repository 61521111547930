import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  ECCard,
  ECCardContent,
  ECTypography,
  ECThemeSwitch,
  ECMainTabBox,
} from 'app/components';

export function ServiceRequestPage() {
  return (
    <>
      <Helmet>
        <title>Service Request Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMainTabBox
        component={'main'}
        sx={{
          /// These styles are just for a good-looking demo page
          display: 'flex',
          paddingTop: '33vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ECCard>
          <ECCardContent>
            <ECTypography sx={{ marginTop: 4 }} variant={'h6'}>
              Welcome to Ecotrak Servie Request
            </ECTypography>
            <ECTypography
              sx={{ marginTop: 2, marginBottom: 2 }}
              variant={'body1'}
            >
              In following you can switch themes
            </ECTypography>
            <ECThemeSwitch />
          </ECCardContent>
        </ECCard>
      </ECMainTabBox>
    </>
  );
}
