export const COUNTRY_CODES = ['au', 'us', 'ca', 'mx', 'pr', 'jm'];
export const COUNTRY_NAMES = [
  'Australia',
  'USA',
  'Canada',
  'Mexico',
  'Puerto Rico',
  'Jamaica',
];

let PLACES_COUNTRY_CODES = [];

export const updatePlacesCountryCodes = array => {
  PLACES_COUNTRY_CODES = array;
};

export { PLACES_COUNTRY_CODES };

export const TYPES_REGIONS = ['(regions)'];

export const TYPES_ADDRESS = ['street_address'];

export const getAddressDataFromFieldValues = (data: any) => ({
  line1: data['address.line1'] ? data['address.line1'] : null,
  line2: data['address.line2'] ? data['address.line2'] : null,
  zipCodePostalCode: data['address.zipCodePostalCode']
    ? data['address.zipCodePostalCode']
    : null,
  cityName: data['address.cityName'] ? data['address.cityName'] : null,
  stateProvinceCode: data['address.stateProvinceCode']
    ? data['address.stateProvinceCode']
    : null,
  countryCode: data['address.countryCode'] ? data['address.countryCode'] : null,
});

export const buildAddress = (address: any): string => {
  const {
    line1 = '',
    line2 = '',
    zipCodePostalCode = '',
    cityName = '',
    stateProvinceCode = '',
  } = address || {};
  return `${line1}${line2 ? `, ${line2}` : ''}, ${cityName}, ${stateProvinceCode} ${zipCodePostalCode}`;
};
