import { P } from 'types/Permission';
import { ECEasyFormConfigType } from '.';
import { ECGrid } from '../ECGrid';
import { ECRequirePermission } from '../ECRequirePermission';

interface ECEasyFormGridItemProps {
  children: React.ReactNode;
  config: ECEasyFormConfigType;
  optionalSx?: any;
  scopes?: P[];
}

export const ECEasyFormGridItem = ({
  children,
  config,
  optionalSx,
  scopes = [],
}: ECEasyFormGridItemProps) => {
  const md = Math.floor(12 / Number(config.cols));
  return (
    <ECRequirePermission scopes={scopes}>
      <ECGrid item xs={12} sm={12} md={md} sx={optionalSx}>
        {children}
      </ECGrid>
    </ECRequirePermission>
  );
};
