import { FieldTypes } from 'app/components';

export function filterableFields(
  type: FieldTypes,
  hasEndpoint: boolean,
): FieldTypes | null {
  if (hasEndpoint) return FieldTypes.Autocomplete;
  switch (type) {
    // types that should render just like any form:
    case FieldTypes.Switch:
    case FieldTypes.Checkbox:
    case FieldTypes.DatePicker:
    case FieldTypes.DateTimePicker:
    case FieldTypes.Radio:
      return type;
    // types that (for now) should not appear on search modal:
    case FieldTypes.Photo:
    case FieldTypes.Video:
    case FieldTypes.TransferList:
    case FieldTypes.Password:
      return null;
    // everything else should render normal text field;
    default:
      return FieldTypes.Text;
  }
}
