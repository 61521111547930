import { QueryParams } from 'types/QueryParams';
import { TradeTypes } from '../types';
import { BaseType } from 'types/BaseType';
import {
  CopyTradeAssignmentToBranchIdMutation,
  CopyTradeAssignmentToBranchIdsMutation,
  CopyTradeAssignmentToTradeIdsMutation,
  PayloadBulkUpdateTradeAssignmentBySp,
  SPByTradeAssignment,
  SPByTradeAssignmentParams,
  ServiceProvidersAvailable,
  ServiceProvidersByTradeAndBranchGet,
  TradeAssignment,
  UpdateServiceProvidersMutation,
} from 'types/TradeTypes';

import { emptyApi } from './emptyApi';
import { historyApi } from './historyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Trades', 'TradeAssignment', 'TradeAssignmentActivity'],
});

export const tradeApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getCoreTradeTypes: builder.query<TradeTypes[], void>({
      query: () => {
        return {
          url: 'trade/core',
        };
      },
    }),
    getTradesList: builder.query<BaseType<TradeTypes[]>, QueryParams>({
      query: params => {
        return {
          url: 'trade',
          params,
        };
      },
      transformResponse: (response: BaseType<TradeTypes[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'trade',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Trades'],
    }),
    addTradeType: builder.mutation({
      query: body => {
        const addBody = {
          name: body.name,
          coreTradeId: body.coreTrade.id,
        };

        return {
          url: 'trade',
          method: 'post',
          body: addBody,
        };
      },
      invalidatesTags: ['Trades'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(historyApi.util.invalidateTags(['History']));
      },
    }),
    editTradeType: builder.mutation({
      query: body => {
        return {
          url: `trade/${body.id}`,
          method: 'put',
          body: { ...body, coreTradeId: body.coreTrade?.id },
        };
      },
      invalidatesTags: ['Trades'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(historyApi.util.invalidateTags(['History']));
      },
    }),
    deleteTradeType: builder.mutation({
      query: body => {
        return {
          url: `trade/${body.id}`,
          method: 'delete',
          body,
        };
      },
      invalidatesTags: ['Trades'],
    }),
    getTradeAssignmentByTradeId: builder.query<
      BaseType<TradeAssignment[]>,
      QueryParams & { id: number }
    >({
      query: ({ id, ...queryParams }) => {
        return {
          url: `trade/assignment/trade/${id}`,
          params: queryParams,
        };
      },
      providesTags: ['TradeAssignment'],
    }),
    getTradeAssignmentByBranchId: builder.query<
      BaseType<TradeAssignment[]>,
      QueryParams & { id: number }
    >({
      query: ({ id, ...queryParams }) => {
        return {
          url: `trade/assignment/branch/${id}`,
          params: queryParams,
        };
      },
      providesTags: ['TradeAssignment'],
    }),
    getServiceProvidersByTradeIdAndBranchId: builder.query<
      ServiceProvidersAvailable[],
      ServiceProvidersByTradeAndBranchGet
    >({
      query: ({ tradeId, branchId }) => {
        if (!tradeId || !branchId) {
          return {
            url: '',
          };
        }

        return {
          url: `trade/${tradeId}/branch/${branchId}/service-provider`,
        };
      },
    }),
    updateServiceProvidersByTradeIdAndBranchId: builder.mutation<
      TradeTypes[],
      UpdateServiceProvidersMutation
    >({
      query: ({ tradeId, branchId, spRank }) => {
        return {
          url: `trade/${tradeId}/branch/${branchId}/service-provider`,
          method: 'put',
          body: {
            spRank,
          },
        };
      },
      invalidatesTags: ['TradeAssignment', 'TradeAssignmentActivity'],
    }),
    copyTradeAssignmentToBranchIds: builder.mutation<
      TradeTypes[],
      CopyTradeAssignmentToBranchIdsMutation
    >({
      query: ({ tradeId, branchIds, spRanks }) => {
        return {
          url: `trade/assignment/trade/${tradeId}/copy`,
          method: 'post',
          body: {
            branchIds,
            spRanks,
          },
        };
      },
      invalidatesTags: ['TradeAssignment'],
    }),
    copyTradeAssignmentToTradeIds: builder.mutation<
      TradeTypes[],
      CopyTradeAssignmentToTradeIdsMutation
    >({
      query: ({ branchId, tradeIds, spRanks }) => {
        return {
          url: `trade/assignment/branch/${branchId}/copy`,
          method: 'post',
          body: {
            tradeIds,
            spRanks,
          },
        };
      },
      invalidatesTags: ['TradeAssignment'],
    }),
    copyTradeAssignmentToBranchId: builder.mutation<
      void,
      CopyTradeAssignmentToBranchIdMutation
    >({
      query: ({ fromBranchId, toBranchId }) => {
        return {
          url: `trade/assignment/branch/${fromBranchId}/copy/${toBranchId}`,
          method: 'post',
        };
      },
      invalidatesTags: ['TradeAssignment'],
    }),
    getSPsForServiceRequest: builder.query<
      SPByTradeAssignment[],
      SPByTradeAssignmentParams
    >({
      query: params => {
        return {
          url: 'serviceprovider/trade-assignment',
          method: 'get',
          params,
        };
      },
    }),
    getTradesByAssetGroupId: builder.query<any, any>({
      query: ({ id }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `trade/asset-group/${id}`,
        };
      },
      providesTags: ['Trades'],
    }),

    // gets the bulk rank options for trade assignment by SP
    getBulkRanks: builder.query<any, void>({
      query: () => {
        return {
          url: `trade/bulk-ranks`,
          // this is basically hardcoded so we can cache it for 15 minutes
          keepUnusedDataFor: 900,
        };
      },
    }),

    // mutation for bulk updating trade assignment by SP
    updateServiceProvidersBulk: builder.mutation<
      any,
      PayloadBulkUpdateTradeAssignmentBySp
    >({
      query: body => {
        return {
          url: `trade/${body.tradeId}/service-provider/${body.spCompanyId}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['TradeAssignment'],
    }),
  }),
});

export const {
  useGetCoreTradeTypesQuery,
  useLazyGetCoreTradeTypesQuery,
  useGetTradesListQuery,
  useLazyGetTradesListQuery,
  useAddTradeTypeMutation,
  useEditTradeTypeMutation,
  useDeleteTradeTypeMutation,
  useGetTradeAssignmentByTradeIdQuery,
  useLazyGetTradeAssignmentByTradeIdQuery,
  useGetTradeAssignmentByBranchIdQuery,
  useLazyGetTradeAssignmentByBranchIdQuery,
  useGetServiceProvidersByTradeIdAndBranchIdQuery,
  useLazyGetServiceProvidersByTradeIdAndBranchIdQuery,
  useUpdateServiceProvidersByTradeIdAndBranchIdMutation,
  useCopyTradeAssignmentToBranchIdsMutation,
  useCopyTradeAssignmentToTradeIdsMutation,
  useCopyTradeAssignmentToBranchIdMutation,
  useGetSPsForServiceRequestQuery,
  useLazyGetSPsForServiceRequestQuery,
  useGetTradesByAssetGroupIdQuery,
  useLazyGetTradesByAssetGroupIdQuery,
  useGetBulkRanksQuery,
  useLazyGetBulkRanksQuery,
  useUpdateServiceProvidersBulkMutation,
} = tradeApi;
