import { useCallback, useEffect, useMemo } from 'react';
import { ECFormControl, ECGrid, ECTypography } from '..';
import { ECBox } from '../ECBox';
import { ECChip } from '../ECChip';
import { ECBulletPoints } from '../ECBulletPoints';
import {
  useGetProblemToAssetListQuery,
  useGetProblemsByAssetTypeIdQuery,
} from 'services/assetTypeToProblemApi';
import { useGetTroubleshootTipsQuery } from 'services/troubleshootingTipsApi';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AssetProblemTroubleshoot,
  Problem,
} from 'types/AssetProblemTroubleshoot';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ProblemToAsset } from 'types/ProblemToAsset';
import { ECRankField, Rank } from '../ECRankField';

export interface ECAssetProblemTroubleshootCreateProps {
  value?: AssetProblemTroubleshoot;
  errorMessage?: string;
  onChange?: (output: any) => void;
}

interface ProblemTroubleshootOutput {
  problemName: string;
  troubleshootTips: string[];
}

export interface AssetProblemTroubleshootOutput {
  assetType: ProblemToAsset;
  problems?: ProblemTroubleshootOutput[];
  body: ProblemTroubleshootBody[];
}

interface ProblemTroubleshootBody {
  troubleshootId?: number;
  assetTradeProblemId: number;
}

export const ECAssetProblemTroubleshootCreate = ({
  value,
  errorMessage,
  onChange,
}: ECAssetProblemTroubleshootCreateProps) => {
  const { t } = useTranslation();

  const {
    data: troubleshootTipsList,
    isSuccess: isSuccesstroubleshootTipsList,
  } = useGetTroubleshootTipsQuery({
    t: 0,
  });

  const { data: problemToAssetList } = useGetProblemsByAssetTypeIdQuery(
    { id: Number(value?.assetGroupId) || 0, noTroubleshoot: true },
    { skip: !value?.assetGroupId },
  );

  // after loading problems list, we need to add empty problems into the value
  useEffect(() => {
    if (problemToAssetList && value?.assetGroupId && value?.assetGroupName) {
      onChange?.({
        ...value,
        problemTroubleshoots: problemToAssetList.map(problem => {
          return {
            problemId: problem.id,
            problemName: problem.name,
            troubleshoots: null,
            assetTradeProblemId: problem.atpId,
          };
        }),
      });
    } else {
      onChange?.({
        ...value,
        problemTroubleshoots: [],
      });
    }
  }, [problemToAssetList, value?.assetGroupId, value?.assetGroupName]);

  const handleOnChangeTroubleshoot = useCallback(
    (troubleshoots: Problem) => {
      onChange?.({
        ...value,

        problemTroubleshoots: value?.problemTroubleshoots?.map(pt => {
          return pt.problemId === troubleshoots.problemId
            ? {
                ...pt,
                assetTradeProblemId: troubleshoots.assetTradeProblemId,
                troubleshoots: troubleshoots.troubleshoots,
              }
            : pt;
        }),
      });
    },
    [onChange, value],
  );

  const problemsToAssetListMemoized = useMemo(() => {
    return value?.problemTroubleshoots?.map(problem => (
      <ECGrid container mt={1} mb={4} columnSpacing={2} key={problem.problemId}>
        <ECGrid item xs>
          <ECBox display="flex" flex={1} my={1} alignItems="center">
            <ECChip variant="outlined" label={problem.problemName} />
          </ECBox>
          <ECBox paddingLeft={9}>
            <ECRankField
              key={problem.problemId}
              ranks={
                value?.problemTroubleshoots
                  ?.find(pt => pt.problemId === problem.problemId)
                  ?.troubleshoots?.map(tbs => {
                    return {
                      position: tbs.position,
                      data: (tbs as any).data,
                      id: tbs.id,
                    };
                  }) as Rank[]
              }
              shouldShowAdminOnly={false}
              options={troubleshootTipsList?.data || []}
              onChange={data => {
                handleOnChangeTroubleshoot({
                  problemId: problem.problemId,
                  problemName: problem.problemName,
                  assetTradeProblemId: problem.assetTradeProblemId,
                  troubleshoots: data,
                });
              }}
              label={'Troubleshoot Tip'}
              shouldShowRankText={false}
              maxLength={10}
            />
          </ECBox>
        </ECGrid>
      </ECGrid>
    ));
  }, [value?.problemTroubleshoots, troubleshootTipsList]);

  const queryParams = useMemo(() => {
    return {
      p: 0,
      t: 500,
      noTroubleshoot: true,
      ob: 'assttp.name',
      o: 'a',
    };
  }, []);

  return (
    <ECBox display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <ECBulletPoints>
        <ECFormControl sx={{ width: '100%' }}>
          <ECAutocompletePaginated
            sx={{ width: '100%' }}
            fieldName="assetType"
            value={{ id: value?.assetGroupId, name: value?.assetGroupName }}
            placeholder={t(
              'translation:pages.assetProblemTroubleshoot.asset.placeholder',
            )}
            variant="filled"
            useQuery={useGetProblemToAssetListQuery}
            obAlias="assttp.name"
            onChange={newAssetType => {
              onChange?.({
                assetGroupId: newAssetType.id,
                assetGroupName: newAssetType.name,
                problemTroubleshoots: [],
              });
            }}
            queryParams={queryParams}
            error={!!errorMessage}
            validationMessage={
              errorMessage && !value?.assetGroupId ? errorMessage : undefined
            }
          />
        </ECFormControl>

        <ECTypography
          my={2}
          variant="caption"
          alignSelf="center"
          sx={{ opacity: '0.6' }}
        >
          {t('translation:pages.assetProblemTroubleshoot.description')}
        </ECTypography>

        <ECTypography
          mb={1}
          sx={{ width: '100%' }}
          variant="subtitle2"
          hideBulletPoint
        >
          <Alert severity="info" variant="outlined" sx={{ fontWeight: 500 }}>
            {t('translation:pages.assetProblemTroubleshoot.info')}
          </Alert>
        </ECTypography>
        {problemsToAssetListMemoized}
      </ECBulletPoints>
    </ECBox>
  );
};
