import {
  DefaultRequestCategoryParams,
  RequestCategoryParams,
  RequestResponse,
} from 'types/Request';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Request'],
});

// Define a service using a base URL and expected endpoints
export const requestApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getRequestCategoryList: builder.query<
      RequestResponse[],
      RequestCategoryParams
    >({
      query: params => {
        return {
          url: 'request/categories',
          params,
        };
      },
      providesTags: ['Request'],
    }),
    getDefaultRequestCategory: builder.query<
      { requestCategoryId: number; categoryName: string },
      DefaultRequestCategoryParams
    >({
      query: params => {
        return {
          url: 'request/category/default',
          params,
        };
      },
    }),
  }),
});

export const {
  useGetRequestCategoryListQuery,
  useLazyGetRequestCategoryListQuery,
  useGetDefaultRequestCategoryQuery,
  useLazyGetDefaultRequestCategoryQuery,
} = requestApi;
