import { useCallback, useEffect, useMemo } from 'react';
import { ECEasyFormCreate, FieldTypes } from '..';
import { ECBox } from '../ECBox';
import { useNavigate, useParams } from 'react-router';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import {
  useCreateFeedbackMutation,
  useGetSurveyFeedbackQuery,
  useGetSurveyTemplateQuery,
} from 'services/surveyApi';
import { useGetProfileQuery } from 'services/profileApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { SurveyTemplateQuestion } from 'types/Survey';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { isEmpty } from 'lodash';
import { hasPermission } from 'utils/permissions';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { P } from 'types/Permission';

const formConfig = {
  cols: 1,
  submitTitle: 'Submit',
  name: 'Survey',
};

export interface FeedbackTabProps {
  sx?: any;
  row?: any;
  moduleName?: string;
  moduleNameToFecthDetails?: string;
  idAttributeName?: string;
}

const FeedbackContent = ({
  row,
  moduleName,
  moduleNameToFecthDetails,
  idAttributeName = 'id',
}: FeedbackTabProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: companyProfile } = useGetProfileQuery();
  const { data: userProfile } = useGetUserProfileQuery();

  const { data: moduleData } = useGetModuleDetailsByIdQuery(
    {
      id: id || '',
      moduleName: moduleNameToFecthDetails || moduleName || '',
    },
    {
      skip: !id,
    },
  );

  const { data: surveyTemplate } = useGetSurveyTemplateQuery(
    {
      moduleName: moduleNameToFecthDetails || moduleName || '',
      companyId: companyProfile?.id?.toString() || '',
    },
    {
      skip: !companyProfile?.id || !(moduleNameToFecthDetails || moduleName),
    },
  );

  const { data: surveyFeedback } = useGetSurveyFeedbackQuery(
    {
      moduleName: moduleNameToFecthDetails || moduleName || '',
      id: id || '',
      companyId: companyProfile?.id?.toString() || '',
    },
    {
      skip:
        !id || !companyProfile?.id || !(moduleNameToFecthDetails || moduleName),
    },
  );

  const [
    createFeedback,
    { isLoading: isLoadingCreateFeedback, isSuccess: isSuccessCreateFeedback },
  ] = useCreateFeedbackMutation();

  const convertToFormConfig = (
    questions: SurveyTemplateQuestion[],
    disableFields?: boolean,
  ) => {
    return questions?.reduce((prevQuestions, question) => {
      return [
        ...prevQuestions,
        {
          type: 'label',
          label: question.text,
          optionalSx: {
            marginBottom: 0,
          },
        },
        {
          type: 'radio',
          value: question?.selectedAnswer?.score?.toString(),
          required: true,
          fieldName: question.text,
          borderless: true,
          disabled: disableFields,
          orientation: 'horizontal',
          options: question.choices?.map(choice => ({
            id: choice.score.toString(),
            label: choice.choiceText,
          })),
        },
      ];
    }, [] as any);
  };

  const isFeedbackAlreadySubmitted = !isEmpty(surveyFeedback?.questions);
  const isUserWO = moduleData?.createdByUserId === userProfile?.userId;

  const fields: any = useMemo(() => {
    const formFooterFields = isFeedbackAlreadySubmitted
      ? [
          {
            type: 'text_area',
            label: 'Comment',
            placeholder: 'Comment',
            value: surveyFeedback?.comment,
            maxLength: 1000,
            minLength: 3,
            fieldName: 'comment',
            readOnly: true,
          },
          {
            type: 'group',
            groupColumns: 2,
            fieldName: 'submissionGroup',
            subFields: [
              {
                type: 'text_field',
                label: 'Submitted by',
                placeholder: 'Submitted by',
                value: moduleData?.createdBy,
                readOnly: true,
                fieldName: 'submittedBy',
              },
              {
                type: 'date_time_picker',
                label: 'Date Submitted',
                placeholder: 'Date Submitted',
                value: surveyFeedback?.date,
                fieldName: 'dateSubmitted',
                readOnly: true,
              },
            ],
          },
        ]
      : [
          {
            type: 'text_area',
            label: 'Comment',
            placeholder: 'Comment',
            value: null,
            maxLength: 1000,
            minLength: 3,
            fieldName: 'comment',
            helperText: 'Max. 1000 characters',
            readOnly: !isUserWO,
          },
        ];

    return [
      isFeedbackAlreadySubmitted
        ? {
            type: 'rating',
            label: 'SP Performance Score',
            value: surveyFeedback?.score,
            readOnly: true,
            optionalSx: {
              marginBottom: 2,
            },
          }
        : {
            type: 'alert',
            severity: 'info',
            variant: 'outlined',
            label: `Feedback Pending: ${moduleData?.createdBy ?? ''}`,
            optionalSx: {
              marginBottom: 2,
              width: 'fit-content',
            },
          },
      ...convertToFormConfig(
        isFeedbackAlreadySubmitted
          ? surveyTemplate?.questions?.map(question => {
              const selectedAnswer = surveyFeedback?.questions?.find(
                answer => answer.text === question.text,
              )?.selectedAnswer;

              return {
                ...question,
                selectedAnswer,
              };
            }) || []
          : surveyTemplate?.questions || [],
        isFeedbackAlreadySubmitted || !isUserWO,
      ),
      ...formFooterFields,
    ];
  }, [
    surveyFeedback,
    surveyTemplate?.questions,
    isFeedbackAlreadySubmitted,
    moduleData,
    isUserWO,
  ]);

  const questionsFields = useMemo(() => {
    return fields?.filter(field => field.type === FieldTypes.Radio);
  }, [fields]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const body = {
        serviceProviderId: moduleData?.spCompanyId,
        comment: data.comment,
        questionAnswers: questionsFields?.map(field => {
          const fieldValue = data?.[field?.fieldName];
          const selectedAnswer = {
            choiceText: field?.options?.find(option => option.id === fieldValue)
              ?.label,
            score: +fieldValue,
          };

          return {
            questionText: field?.fieldName,
            selectedAnswer,
          };
        }),
      };

      createFeedback({
        moduleName: moduleNameToFecthDetails || moduleName || '',
        id: id || '',
        companyId: companyProfile?.id?.toString() || '',
        ...body,
      });
    };

    return [
      doSubmit,
      {
        isLoading: isLoadingCreateFeedback,
      },
    ];
  }, [
    isLoadingCreateFeedback,
    moduleNameToFecthDetails,
    moduleName,
    id,
    companyProfile?.id,
    questionsFields,
    moduleData,
    createFeedback,
  ]);

  useEffect(() => {
    if (!isLoadingCreateFeedback && isSuccessCreateFeedback) {
      navigate('../details');
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Thank you for the Feedback!',
        }),
      );
    }
  }, [isSuccessCreateFeedback, isLoadingCreateFeedback]);

  const hideSubmitButton =
    isFeedbackAlreadySubmitted ||
    !hasPermission({
      userPermissions: userProfile?.permissions?.map(p => p.action),
      scopes: [P.AddSurveyFeedback],
    }) ||
    !isUserWO;

  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.paper}
      flexDirection="column"
      p={3}
    >
      <ECEasyFormCreate
        useCreateMutation={submit}
        formConfig={formConfig}
        showTitle={false}
        formFields={fields}
        hideActions={hideSubmitButton}
        onClose={() => navigate('../details')}
      />
    </ECBox>
  );
};

export const FeedbackTab =
  requirePermissionWrapper<FeedbackTabProps>(FeedbackContent);
