import { ApiTableConfig } from './ApiTableConfig';

export interface DataType {
  moduleName?: string;
}

export interface BaseType<BaseDataType> {
  config: ApiTableConfig;
  data: DataType & BaseDataType;
  totalSpent?: number;
  selectedRecipients?: number[];
}

export enum Method {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
