import { QueryParams } from 'types/QueryParams';
import {
  Branch,
  BranchDetail,
  BranchDetailFlat,
  BranchMutate,
  BranchUpdateMutate,
  BranchTypeLookup,
  BranchUpdateFormData,
} from 'types/Branch';
import { flatToNested, nestedToFlat } from 'utils/common';
import { BaseType } from 'types/BaseType';
import { AssetFlat, AssetQueryParams } from 'types/Asset';
import { CompanyBranchesForSpQueryParams } from 'types/Branch';

import { emptyApi } from './emptyApi';
import { Lookup } from 'types/Lookup';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Branch',
    'BranchType',
    'AssetsByBranchIdAndAssetGroupId',
    'BranchSP',
    'BranchBySpAndTrade',
    'BranchesByCustomerId',
  ],
});

// Define a service using a base URL and expected endpoints
export const branchApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getBranchesList: builder.query<BaseType<Branch[]>, QueryParams>({
      query: params => {
        const { o, ob, ...restParams } = params;
        const defaultParams = {
          ...restParams,
          o: o !== undefined && o !== '' ? o : 'a',
          ob: ob !== undefined && ob !== '' ? ob : 'brnch.id',
        };

        if (
          params?.regionParentIds?.length ||
          params?.districtParentIds?.length
        ) {
          defaultParams['regionParentIds'] = params?.regionParentIds?.join(',');
          defaultParams['districtParentIds'] =
            params?.districtParentIds?.join(',');
        }

        return {
          url: 'branch',
          params: defaultParams,
        };
      },
      transformResponse: (response: BaseType<Branch[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'branch',
          branchType: { name: row.branchType?.name, id: row.branchTypeId },
          organization: {
            ...row.organization,
            label: row.organization.parent.name,
          },
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Branch'],
    }),
    getBranchesByGeneralAssetIdList: builder.query<BaseType<Branch[]>, number>({
      query: generalAssetId => {
        return {
          url: `branch/general-asset/${generalAssetId}/all`,
        };
      },
      providesTags: ['Branch'],
    }),
    getServiceRequestBranchesList: builder.query<
      BaseType<Branch[]>,
      number | QueryParams
    >({
      query: params => {
        if (typeof params === 'number') {
          return {
            url: `branch/general-asset/${params}/all`,
          };
        }
        return {
          url: 'branch',
          params,
        };
      },
      transformResponse: (response: any) => {
        return response.data ? response : { data: response };
      },
      providesTags: ['Branch'],
    }),
    createBranch: builder.mutation<void, any>({
      query: body => {
        body.branchTypeId = body.branchType?.id;
        body.organizationId = body.organization?.id;
        const nestedBody = flatToNested(body);
        const {
          organizationId,
          mailingAddress,
          name,
          branchTypeId,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId = 1,
          costCenter,
          copyTradeAssignmentLocationId,
          legalEntityName,
        } = nestedBody as BranchMutate;
        const newBranch: BranchMutate = {
          organizationId,
          mailingAddress: {
            ...mailingAddress,
            countryCode:
              (mailingAddress?.countryCode as any)?.value ||
              mailingAddress?.countryCode,
            zipCodePostalCode: mailingAddress?.zipCodePostalCode || null,
          } as any,
          name,
          branchTypeId,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId,
          costCenter,
          copyTradeAssignmentLocationId:
            (copyTradeAssignmentLocationId as any)?.id || null,
          legalEntityName,
        };

        return {
          url: 'branch',
          method: 'post',
          body: newBranch,
        };
      },
      invalidatesTags: ['Branch'],
    }),
    updateBranch: builder.mutation<void, any>({
      query: body => {
        body.branchTypeId = body.branchType?.id;
        body.organizationId = body.organization?.id;
        const nestedBody = flatToNested(body);
        const {
          id,
          branchId,
          parentOrgId,
          mailingAddress,
          name,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId = 1,
          leaseLandlordAddress,
          leaseLandlordName,
          leaseContactName,
          leaseContactPhone,
          leaseContactPhoneExt,
          leaseContactEmail,
          leaseEngineerName,
          leaseEngineerPhone,
          leaseEngineerPhoneExt,
          leaseEngineerEmail,
          leaseStartDate,
          leaseEndDate,
          leaseOption1PeriodCode,
          leaseOption1Value,
          leaseOption2PeriodCode,
          leaseOption2Value,
          responsibilities,
          costCenter,
          status,
          legalEntityName,
        } = nestedBody as BranchUpdateFormData;
        const updateBody: BranchUpdateMutate = {
          organizationId: parentOrgId,
          branchTypeId: branchId,
          mailingAddress,
          name,
          storeNumber,
          phone,
          phoneExt,
          industryId,
          leaseLandlordName,
          leaseContactName,
          leaseContactPhone,
          leaseContactPhoneExt,
          leaseContactEmail,
          leaseEngineerName,
          leaseEngineerPhone,
          leaseEngineerPhoneExt,
          leaseEngineerEmail,
          leaseStartDate,
          leaseEndDate,
          responsibilities,
          costCenter,
          status,
          legalEntityName,
        };
        if (averageWeeklySales !== undefined) {
          updateBody.averageWeeklySales = averageWeeklySales;
        }
        if (leaseLandlordAddress && leaseLandlordAddress !== undefined) {
          updateBody.leaseLandlordAddress = leaseLandlordAddress;
        }

        if (
          leaseOption1PeriodCode !== undefined &&
          leaseOption1PeriodCode !== ''
        ) {
          if (leaseOption1PeriodCode === -1) {
            updateBody.leaseOption1PeriodCode = null;
            updateBody.leaseOption1Value = null;
          } else {
            updateBody.leaseOption1PeriodCode = leaseOption1PeriodCode;
            updateBody.leaseOption1Value =
              leaseOption1Value !== undefined && leaseOption1Value !== ''
                ? Number(leaseOption1Value)
                : 0;
          }
        }
        if (updateBody.leaseOption1Value === 0) {
          updateBody.leaseOption1PeriodCode = null;
          updateBody.leaseOption1Value = null;
        }

        if (
          leaseOption2PeriodCode !== undefined &&
          leaseOption2PeriodCode !== ''
        ) {
          if (leaseOption2PeriodCode === -1) {
            updateBody.leaseOption2PeriodCode = null;
            updateBody.leaseOption2Value = null;
          } else {
            updateBody.leaseOption2PeriodCode = leaseOption2PeriodCode;
            updateBody.leaseOption2Value =
              leaseOption2Value !== undefined && leaseOption2Value !== ''
                ? Number(leaseOption2Value)
                : 0;
          }
        }
        if (updateBody.leaseOption2Value === 0) {
          updateBody.leaseOption2PeriodCode = null;
          updateBody.leaseOption2Value = null;
        }

        if (openDate !== undefined) {
          updateBody.openDate = openDate;
        }

        if (body.mailingAddress)
          updateBody.mailingAddress = body.mailingAddress;
        if (body.name) updateBody.name = body.name;
        if (body.storeNumber) updateBody.storeNumber = body.storeNumber;
        if (body.phone) updateBody.phone = body.phone;
        if (body.phoneExt) updateBody.phoneExt = body.phoneExt;
        if (body.industryId) updateBody.industryId = body.industryId;
        if (body.leaseLandlordName)
          updateBody.leaseLandlordName = body.leaseLandlordName;
        if (body.leaseContactName)
          updateBody.leaseContactName = body.leaseContactName;
        if (body.leaseContactPhone)
          updateBody.leaseContactPhone = body.leaseContactPhone;
        if (body.leaseContactPhoneExt)
          updateBody.leaseContactPhoneExt = body.leaseContactPhoneExt;
        if (body.leaseContactEmail)
          updateBody.leaseContactEmail = body.leaseContactEmail;
        if (body.leaseEngineerName)
          updateBody.leaseEngineerName = body.leaseEngineerName;
        if (body.leaseEngineerPhone)
          updateBody.leaseEngineerPhone = body.leaseEngineerPhone;
        if (body.leaseEngineerPhoneExt)
          updateBody.leaseEngineerPhoneExt = body.leaseEngineerPhoneExt;
        if (body.leaseEngineerEmail)
          updateBody.leaseEngineerEmail = body.leaseEngineerEmail;
        if (body.leaseStartDate) {
          updateBody.leaseStartDate = body.leaseStartDate;
        } else {
          delete updateBody.leaseStartDate;
        }
        if (body.leaseEndDate) {
          updateBody.leaseEndDate = body.leaseEndDate;
        } else {
          delete updateBody.leaseEndDate;
        }
        if (body.responsibilities)
          updateBody.responsibilities = body.responsibilities;
        if (body.costCenter) updateBody.costCenter = body.costCenter;
        if (body.status) updateBody.status = body.status;

        return {
          url: `branch/${id}`,
          method: 'put',
          body: updateBody,
        };
      },
      invalidatesTags: ['Branch'],
    }),
    deleteBranch: builder.mutation({
      query: id => {
        return {
          url: `branch/${id}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Branch'],
    }),
    getBranchById: builder.query<BranchDetailFlat, Branch>({
      query: row => {
        const { id } = row;
        const numberId: number = Number(id);
        return {
          url: `branch/${numberId}`,
        };
      },
      transformResponse: (response: BranchDetail) => {
        const {
          branchId,
          organizationId,
          branchTypeId,
          mailingAddress,
          name,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId,
        } = response;

        const reducedResponse: BranchDetail = {
          branchId,
          organizationId,
          branchTypeId,
          mailingAddress,
          name,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId,
        };
        const flatResponse: BranchDetailFlat =
          nestedToFlat<BranchDetailFlat>(reducedResponse);
        return flatResponse;
      },
    }),
    getUsersByBranchId: builder.query<Lookup[], number>({
      query: id => {
        return {
          url: `branch/${id}/users`,
        };
      },
      transformResponse: (response: any) => {
        return response?.data?.map(d => ({
          id: d.id,
          name: `${d.firstName} ${d.lastName}`,
        }));
      },
    }),
    // Branch Types (Concepts) (BranchCategories on backend)
    getBranchTypes: builder.query<BaseType<BranchTypeLookup[]>, QueryParams>({
      query: params => {
        return {
          url: 'branch/category',
          params,
        };
      },
      providesTags: ['BranchType'],
    }),
    // TODO: finish CRUD for concepts
    getBranchTypeById: builder.query<any, any>({
      query: id => ({ url: `branch/category/${id}` }),
    }),
    createBranchType: builder.mutation<void, any>({
      query: body => ({ url: 'branch/category', method: 'post', body }),
      invalidatesTags: ['BranchType'],
    }),
    updateBranchType: builder.mutation<void, any>({
      query: body => ({
        url: `branch/category/${body.id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['BranchType'],
    }),

    getAssetsByBranchIdAndAssetGroupId: builder.query<
      BaseType<AssetFlat[]>,
      AssetQueryParams
    >({
      query: ({ id, ...params }) => {
        return {
          url: `branch/${id}/assets`,
          params,
        };
      },
      providesTags: ['AssetsByBranchIdAndAssetGroupId'],
    }),

    getCustomersBranchListForSp: builder.query<BaseType<Branch[]>, QueryParams>(
      {
        query: params => {
          return {
            url: 'branch/sp',
            params,
          };
        },
        providesTags: ['BranchSP'],
      },
    ),

    getCustomersBranchListByGeneralAssetForSp: builder.query<
      Branch[],
      { customerId: number; generalAssetId: number }
    >({
      query: ({ customerId, generalAssetId }) => {
        return {
          url: `branch/sp/customer/${customerId}/general-asset/${generalAssetId}/all`,
        };
      },
      providesTags: ['BranchSP'],
    }),

    getCompanyBranchesListFroSp: builder.query<
      BaseType<Branch[]>,
      CompanyBranchesForSpQueryParams
    >({
      query: ({ companyId, ...params }) => {
        return {
          url: `branch/sp/customer/${companyId}`,
          params,
        };
      },
      providesTags: ['BranchSP'],
    }),

    getBranchListBySpAndTrade: builder.query<
      BaseType<Branch[]>,
      QueryParams & { spId: number; tradeId: number }
    >({
      query: ({ orgIds, spId, tradeId, ...params }) => {
        return {
          url: `/branch/organization?spId=${spId}&tradeId=${tradeId}`,
          params: { ...params, orgIds },
        };
      },
      keepUnusedDataFor: 15,
      providesTags: ['BranchBySpAndTrade'],
    }),
  }),
});

export const {
  useGetBranchesListQuery,
  useLazyGetBranchesListQuery,
  useGetBranchesByGeneralAssetIdListQuery,
  useLazyGetBranchesByGeneralAssetIdListQuery,
  useGetServiceRequestBranchesListQuery,
  useCreateBranchMutation,
  useLazyGetBranchByIdQuery,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
  // branch types
  useGetBranchTypesQuery,
  useLazyGetBranchTypesQuery,
  useGetBranchTypeByIdQuery,
  useCreateBranchTypeMutation,
  useUpdateBranchTypeMutation,
  useGetAssetsByBranchIdAndAssetGroupIdQuery,
  useLazyGetAssetsByBranchIdAndAssetGroupIdQuery,

  useGetUsersByBranchIdQuery,
  useLazyGetUsersByBranchIdQuery,

  //sp
  useGetCustomersBranchListForSpQuery,
  useLazyGetCustomersBranchListForSpQuery,
  useGetCustomersBranchListByGeneralAssetForSpQuery,
  useLazyGetCustomersBranchListByGeneralAssetForSpQuery,
  useGetCompanyBranchesListFroSpQuery,
  useLazyGetCompanyBranchesListFroSpQuery,

  // trade assignment
  useLazyGetBranchListBySpAndTradeQuery,
} = branchApi;
