import { Close } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECStepsContainer } from 'app/components/ECSteps';
import { ECTypography } from 'app/components/ECTypography';
import { useCallback, useEffect, useState } from 'react';
import { useCreateWorkorderMutation } from 'services/workOrdersApi';
import { useCreateServiceRequestContext } from 'store/contexts';
import { setSnackbar } from 'store/slice/page';
import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StepTroubleshoots } from './step-troubleshoots';
import { AssetTypeProblemTroubleShoot } from 'types/AssetType';
import { useMatch, useNavigate } from 'react-router';
import { Troubleshoot } from 'types/Troubleshoot';
import { useLazyGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory } from 'types/Attachment';

interface EditProps {
  onClose?: () => void;
}

export const ServiceRequestCreate = ({ onClose }: EditProps) => {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(1);
  const {
    state: { data: existingData },
  } = useCreateServiceRequestContext();

  const [troubleshootInformation, setTroubleshootInformation] = useState<
    AssetTypeProblemTroubleShoot[] | undefined
  >();

  const [
    createWorkOrder,
    {
      data: createWOResponse,
      isLoading: isCreateWOLoading,
      isError: isCreateWOError,
      isSuccess: isCreateWOSuccess,
      reset: resetCreateWO,
    },
  ] = useCreateWorkorderMutation();

  const [triggerAttachment] = useLazyGetAttachmentQuery();

  const handleChangeTroubleshoots = useCallback(
    async (troubleshootInformation?: AssetTypeProblemTroubleShoot[]) => {
      if (troubleshootInformation) {
        const updatedTroubleshootInformation: AssetTypeProblemTroubleShoot[] =
          [];
        for (const assetTypeProblem of troubleshootInformation) {
          const updatedTroubleshoots: Troubleshoot[] = [];

          for (const troubleshoot of assetTypeProblem.troubleshoots) {
            const { data } = await await triggerAttachment({
              module: 'troubleshoot',
              moduleId: troubleshoot.id,
              category: AttachmentCategory.All,
            });
            if (data && data?.others) {
              const updatedTroubleshoot = {
                ...troubleshoot,
                others: data?.others || null,
              };
              updatedTroubleshoots.push(updatedTroubleshoot);
            } else {
              updatedTroubleshoots.push(troubleshoot);
            }
          }
          updatedTroubleshootInformation.push({
            ...assetTypeProblem,
            troubleshoots: updatedTroubleshoots,
          });
        }
        setTroubleshootInformation(updatedTroubleshootInformation);
      }
    },
    [triggerAttachment],
  );

  const handleSubmitStep1 = useCallback(
    (formData: any) => {
      setData({
        ...data,
        ...formData,
      });
      setCurrentStep(2);
    },
    [currentStep],
  );

  const handleSubmitStep2 = useCallback(
    (formData: any) => {
      setData({
        ...data,
        ...formData,
      });
      setCurrentStep(3);
    },
    [currentStep],
  );

  const handleSubmitStepTroubleshoot = useCallback(() => {
    setCurrentStep(4);
  }, []);

  const handleSubmitStep3 = useCallback(
    (formData: any) => {
      const workOrderData = {
        ...data,
        ...formData,
      };

      const isGeneralAssetCheck =
        existingData?.isGeneralAsset || workOrderData.asset?.isGeneralAsset;

      createWorkOrder({
        description: workOrderData.description,
        requestCategoryId: workOrderData.requestCategory.id,
        assetId: workOrderData.asset.id,
        branchId: workOrderData?.branch?.id,
        problemId: workOrderData.problem.id,
        priorityId: workOrderData.priority.id,
        spCompanyId: workOrderData.serviceProvider,
        overtimeApproved: workOrderData.otApproved?.length,
        files: workOrderData.files,
        isGeneralAsset: isGeneralAssetCheck ? 1 : 0,
        requestedBy: workOrderData.requestedByUser,
        generalAssetId: isGeneralAssetCheck ? workOrderData.asset.id : null,
      });
    },
    [currentStep, data, existingData?.isGeneralAsset],
  );

  useEffect(() => {
    if (isCreateWOSuccess && createWOResponse) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message:
            (createWOResponse as any)?.message ??
            t('translation:createServiceRequest.createSuccess'),
        }),
      );
      navigate(`${match?.pathnameBase}/workorder/${createWOResponse.id}`);
      onClose?.();
    } else if (isCreateWOError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            (createWOResponse as any)?.message ??
            t('translation:createServiceRequest.createError'),
        }),
      );
    }

    resetCreateWO();
  }, [isCreateWOSuccess, isCreateWOError, createWOResponse, dispatch, t]);

  const handleChangeStep = (tab: number) => {
    if (tab === 1) {
      const newData: any = { ...data };
      if (newData.problem) {
        delete newData.problem;
      }
      setData(newData);
    }
    setCurrentStep(tab);
  };

  const renderTabs = () =>
    !!troubleshootInformation?.length
      ? [
          {
            value: 1,
            label: '1',
            content: (
              <Step1
                existingData={existingData || data}
                readOnly={!!existingData}
                onSubmit={handleSubmitStep1}
                onClose={onClose}
              />
            ),
          },
          {
            value: 2,
            label: '2',
            content: (
              <Step2
                existingData={data}
                isGeneralAsset={
                  existingData?.isGeneralAsset || data?.asset?.isGeneralAsset
                }
                onSubmit={handleSubmitStep2}
                handleChangeTroubleshoots={handleChangeTroubleshoots}
                onClose={onClose}
              />
            ),
          },
          {
            value: 3,
            label: '⚙',
            content: (
              <StepTroubleshoots
                existingData={data}
                troubleshootInformation={troubleshootInformation}
                onSubmit={handleSubmitStepTroubleshoot}
                onClose={onClose}
              />
            ),
          },
          {
            value: 4,
            label: '3',
            content: (
              <Step3
                existingData={data}
                isGeneralAsset={
                  existingData?.isGeneralAsset || data.asset?.isGeneralAsset
                }
                tradeId={troubleshootInformation?.[0]?.trade?.id}
                onSubmit={handleSubmitStep3}
                isLoading={isCreateWOLoading}
                onClose={onClose}
              />
            ),
          },
        ]
      : [
          {
            value: 1,
            label: '1',
            content: (
              <Step1
                existingData={existingData || data}
                readOnly={!!existingData}
                onSubmit={handleSubmitStep1}
                onClose={onClose}
              />
            ),
          },
          {
            value: 2,
            label: '2',
            content: (
              <Step2
                existingData={data}
                isGeneralAsset={
                  existingData?.isGeneralAsset || data?.asset?.isGeneralAsset
                }
                onSubmit={handleSubmitStep2}
                handleChangeTroubleshoots={handleChangeTroubleshoots}
                onClose={onClose}
              />
            ),
          },
          {
            value: 3,
            label: '3',
            content: (
              <Step3
                existingData={data}
                isGeneralAsset={
                  existingData?.isGeneralAsset || data.asset?.isGeneralAsset
                }
                tradeId={troubleshootInformation?.[0]?.trade?.id}
                onSubmit={handleSubmitStep3}
                onClose={onClose}
                isLoading={isCreateWOLoading}
              />
            ),
          },
        ];

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECIconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: 10, top: 10, color: 'white' }}
      >
        <Close />
      </ECIconButton>
      <ECBox
        display="flex"
        justifyContent="center"
        py={2}
        bgcolor={theme => theme.palette.primary.main}
      >
        <ECTypography variant="h4" color={theme => theme.palette.common.white}>
          New Service Request
        </ECTypography>
      </ECBox>

      <ECStepsContainer
        stepsContainerProps={{
          px: 6,
          pt: 4,
        }}
        tabs={renderTabs()}
        currentStep={currentStep}
        onChangeStep={handleChangeStep}
        selectableSteps={true}
      />
    </ECBox>
  );
};
