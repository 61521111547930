import { createSlice } from '@reduxjs/toolkit';
import { Profile } from 'types/Profile';

export const initialState: Profile = {
  assignedAssetTypes: [],
  permissions: [],
  jobTitle: undefined,
  company: undefined,
  username: '',
  id: 0,
  userId: 0,
  fullName: '',
  email: '',
  language: '',
  timezone: '',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_user, action) => {
      return {
        ...action.payload,
      };
    },
    setUserName: (user, action) => {
      return {
        ...user,
        userName: action.payload,
      };
    },
  },
});

export const { setUserName, setUser } = slice.actions;

export default slice.reducer;
