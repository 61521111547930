import { Backdrop, Fade, Modal } from '@mui/material';
import { ECBox } from '../ECBox';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { ECDivider } from '../ECDivider';

interface ECModalProps {
  isOpen?: boolean;
  noPadding?: boolean;
  noPaddingBottom?: boolean;
  onClose: () => void;
  p?: number;
  children: any;
  sx?: any;
  showCloseButton?: boolean;
  showDivider?: boolean;
  header?: any;
  footer?: any;
}

export const ECModal = ({
  isOpen = false,
  noPadding,
  noPaddingBottom,
  onClose,
  p,
  children,
  sx,
  showCloseButton,
  showDivider,
  header,
  footer,
}: ECModalProps) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={isOpen}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen}>
      <ECBox
        position="absolute"
        top="50%"
        left="50%"
        bgcolor={theme => theme.palette.background.paper}
        boxShadow={2}
        p={noPadding ? 0 : p ?? 4}
        pb={noPaddingBottom ? 0 : p ?? 4}
        borderRadius={1}
        maxHeight="75vh"
        maxWidth="75vw"
        overflow="auto"
        sx={{
          transform: 'translate(-50%,-50%)',
          ...sx,
        }}
      >
        <ECBox
          display="flex"
          justifyContent="flex-end"
          width="100%"
          alignItems="center"
        >
          {header && <ECBox marginRight="auto">{header}</ECBox>}
          {showCloseButton && (
            <ECIconButton onClick={onClose} size="medium" withBorder>
              <Close sx={theme => ({ color: theme.palette.grey[600] })} />
            </ECIconButton>
          )}
        </ECBox>
        {showDivider && <ECDivider sx={{ marginTop: 2 }} />}
        {children}
        {footer && <ECBox marginRight="auto">{footer}</ECBox>}
      </ECBox>
    </Fade>
  </Modal>
);
