import React, { useReducer } from 'react';

type Action =
  | { type: 'expand'; payload: State }
  | { type: 'close'; payload?: State }
  | { type: 'hideSideMenu' }
  | { type: 'openDrawer' }
  | { type: 'closeDrawer' };
type Dispatch = (action: Action) => void;
type State = {
  expandedItemTitle?: string;
  openSecondLevelDrawer?: boolean;
  isDrawerOpen?: boolean;
};

const PanelDrawerExpandedItemContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function panelDrawerExpandedItemReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'expand': {
      return { ...state, ...action.payload };
    }
    case 'close': {
      return {
        ...state,
        expandedItemTitle: undefined,
        openSecondLevelDrawer: false,
      };
    }
    case 'hideSideMenu': {
      return { ...state, openSecondLevelDrawer: false };
    }
    case 'openDrawer': {
      return { ...state, isDrawerOpen: true };
    }
    case 'closeDrawer': {
      return { ...state, isDrawerOpen: false };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface PanelDrawerCollapsibleItemsProviderProps {
  children: React.ReactNode;
}

export const PanelDrawerCollapsibleItemsProvider = ({
  children,
}: PanelDrawerCollapsibleItemsProviderProps) => {
  const [state, dispatch] = useReducer(panelDrawerExpandedItemReducer, {
    expandedItemTitle: undefined,
  });

  const value = { state, dispatch };

  return (
    <PanelDrawerExpandedItemContext.Provider value={value}>
      {children}
    </PanelDrawerExpandedItemContext.Provider>
  );
};

export const usePanelDrawerExpandedItem = () => {
  const context = React.useContext(PanelDrawerExpandedItemContext);

  if (context === undefined) {
    throw new Error(
      'usePanelDrawerExpandedItem must be used within a PanelDrawerCollapsibleItemsProvider',
    );
  }

  return context;
};
