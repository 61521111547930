import { ECDynamicPageTemplate } from 'app/components';
import { useEffect } from 'react';

import {
  useAddProblemToAssetTypeMutation,
  useEditProblemTypeMutation,
} from 'services/assetTypeToProblemApi';

import { useLazyGetJobTitleRoleListQuery } from 'services/jotbtitleroleApi';
import { loadPageScopes } from 'utils/pageScopes';

const tableConfig = require('./tableConfig.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export function JobTitlesRolePage() {
  useEffect(() => {
    loadPageScopes('jobTitleRole');
  }, []);
  return (
    <ECDynamicPageTemplate
      pageTitle={'Job Titles Role'}
      useLazyGetListQuery={useLazyGetJobTitleRoleListQuery}
      useCreateMutation={useAddProblemToAssetTypeMutation}
      useUpdateMutation={useEditProblemTypeMutation}
      tableConfig={tableConfig.data.config}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      enableExport={true}
      marginTop={false}
      downloadFileName="jobs-roles.csv"
      shouldNotUseActiveFilter
    />
  );
}
