import { useCallback, useEffect, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECTypography,
  ECChip,
  ECEasyForm,
} from 'app/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from 'store/slice/page';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import { Add, Edit } from '@mui/icons-material';
import { ECModal } from '../ECModal';
import { useAddInvoiceCreditMutation } from 'services/invoiceApi';
import { moduleApi } from 'services/moduleApi';

export interface ECWorkOrderUpdateEtaModalContentProps {
  invoiceId?: number;
  creditAmount?: number;
  shouldShowAddCreditButton?: boolean;
  totalAmount?: number;
  totalWithDiscountAmount?: number;
}

export const updateEtaStatusToShow = [
  'ACCEPTED',
  'EN ROUTE',
  'ENROUTE',
  'EN_ROUTE',
  'RETURN_VISIT_REQUIRED',
  'RETURN VISIT REQUIRED',
  'RETURNVISITREQUIRED',
];

const fancyFormAddCredit = require('./fancy_form_config_add_credit.json');

export const ECCostTableAddCredit = ({
  invoiceId,
  creditAmount,
  shouldShowAddCreditButton = true,
  totalAmount,
  totalWithDiscountAmount,
}: ECWorkOrderUpdateEtaModalContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // set the value of credit field
  useEffect(() => {
    const creditField = fancyFormAddCredit.fields.find(
      (field: any) => field.fieldName === 'credit',
    );

    if (creditField) {
      creditField.value = creditAmount;

      if (totalAmount) {
        creditField.helperText = `The value must be less than or equal to the Total ($${totalAmount})`;
        creditField.maxValue = totalAmount;
        creditField.minValue = creditAmount ? null : 1;
      }

      fancyFormAddCredit.config.title = creditAmount
        ? 'Edit Credit'
        : 'Add Credit';
    }
  }, [creditAmount, totalAmount]);

  // mutation to add credit
  // if the credit is ever added into proposals
  // change this mutation to receive the module
  const [
    doAddInvoiceCredit,
    {
      isError: isAddInvoiceCreditError,
      error: addInvoiceCreditError,
      isLoading: isLoadingAddInvoiceCredit,
      isSuccess: isAddInvoiceCreditSuccess,
      data: addInvoiceCreditData,
      reset: resetAddInvoiceCreditMutation,
    },
  ] = useAddInvoiceCreditMutation();

  const handleAddCredit = useCallback(
    (formData, output) => {
      // find credit field
      const data = output.find((field: any) => field.fieldName === 'credit');
      doAddInvoiceCredit({
        id: invoiceId ?? 0,
        credit: parseFloat(data?.value),
      });
    },
    [doAddInvoiceCredit],
  );

  useEffect(() => {
    if (isAddInvoiceCreditError && addInvoiceCreditError) {
      resetAddInvoiceCreditMutation();
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (addInvoiceCreditError as any)?.data?.message,
        }),
      );
    }
  }, [addInvoiceCreditError, isAddInvoiceCreditError]);

  const addedCredit = (addInvoiceCreditData as any)?.credit;

  useEffect(() => {
    if (isAddInvoiceCreditSuccess && !isLoadingAddInvoiceCredit) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: creditAmount
            ? `The credit was updated${
                addedCredit ? ' to $' + addedCredit : ''
              } and was applied against the invoice total`
            : `A ${
                addedCredit ? '$' + addedCredit : ''
              } credit was applied against the invoice total`,
        }),
      );
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      resetAddInvoiceCreditMutation();
      handleCloseModal();
    }
  }, [isAddInvoiceCreditSuccess, isLoadingAddInvoiceCredit, addedCredit]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      {creditAmount ? (
        <ECBox
          className="nte"
          display="flex"
          p={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <ECBox>
            <ECTypography variant="body2">
              {totalWithDiscountAmount && totalWithDiscountAmount > 0
                ? 'Total with Credit'
                : 'Total with Discount'}
            </ECTypography>
          </ECBox>
          <ECTypography variant="body2" fontSize={20}>
            {currencyFormatter.format(totalWithDiscountAmount || 0)}
          </ECTypography>
        </ECBox>
      ) : null}
      <ECBox
        className="nte"
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="space-between"
        bgcolor={theme => 'rgba(0, 114, 206, 0.08)'}
      >
        <ECBox>
          <ECTypography variant="body2">Credit</ECTypography>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            fontSize={12}
          >
            This value will be deducted from the Invoice Total Amount.
          </ECTypography>
          {shouldShowAddCreditButton && invoiceId && (
            <ECButton
              onClick={handleOpenModal}
              startIcon={creditAmount ? <Edit /> : <Add />}
              variant="outlined"
              sx={theme => ({
                my: 1,
                backgroundColor: 'transparent', //#0072CE80
                borderColor: 'rgba(0, 114, 206, 0.5)',
              })}
            >
              {creditAmount ? 'EDIT' : 'ADD'} CREDIT
            </ECButton>
          )}
        </ECBox>

        <ECTypography variant="body2">
          {currencyFormatter.format(creditAmount || 0)}
        </ECTypography>
      </ECBox>
      <ECModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        noPadding
        sx={{ width: 400 }}
      >
        <ECEasyForm
          pattern="modal"
          id="add-credit-form-nested"
          config={fancyFormAddCredit.config}
          fields={fancyFormAddCredit.fields}
          isSendingData={isLoadingAddInvoiceCredit}
          isLoadingForm={false}
          onFormSubmit={handleAddCredit}
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              sx={{ height: 42 }}
              onClick={handleCloseModal}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
        />
      </ECModal>
    </>
  );
};
