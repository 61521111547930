import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ECBox } from '../ECBox';
import { debounce } from 'lodash';
import { ECStack } from '../ECStack';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECTypography } from '../ECTypography';

interface ECInfiniteScrollProps {
  queryFn: any;
  ContainerComponent: React.ElementType;
  wrapperProps?: any;
  containerProps?: any;
  queryParams: any;
  renderItem: (item: any, index: any) => any;
  searchParam?: string;
  onDataUpdate?: (data: any) => void;
  rerenderDisabled?: boolean;
}

export const ECInfiniteScroll = ({
  queryFn,
  ContainerComponent,
  wrapperProps,
  containerProps,
  queryParams,
  renderItem,
  searchParam = '',
  onDataUpdate,
  rerenderDisabled = false,
}: ECInfiniteScrollProps) => {
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const listRef = useRef(null);
  const [data, setData] = useState<any>([]);
  const lastSearch = useRef<string>('');

  const [trigger, result] = queryFn?.();
  const { isFetching } = result;

  const fetchData = () => {
    if (!isFetching && !rerenderDisabled) {
      trigger({
        ...queryParams,
        ...(searchParam?.length > 0 && { s: `*${searchParam}*` }),
        p: page,
      })
        .unwrap()
        .then(newData => {
          const currentPage = parseInt(
            String(newData.config.pagination.page),
            10,
          );
          const perPage = parseInt(String(newData.config.pagination.take), 10);
          const totalCount = parseInt(
            String(newData.config.pagination.totalCount),
            10,
          );

          const adjustedTotalCount = totalCount === -1 ? 10000 : totalCount;
          const morePages = (currentPage + 1) * perPage < adjustedTotalCount;

          setHasMore(morePages);

          if (newData.data.length > 0) {
            setData(prevData => {
              const newUniqueData = newData.data.filter(
                (item: any) =>
                  !prevData.some((prevItem: any) => prevItem.id === item.id),
              );
              const updatedData = [...prevData, ...newUniqueData];
              if (onDataUpdate) {
                onDataUpdate(updatedData);
              }
              return updatedData;
            });
          } else if (page === 0) {
            setData([]);
            if (onDataUpdate) {
              onDataUpdate([]);
            }
          }
        })
        .catch(error => {
          console.error('Failed to fetch data:', error);
          setHasMore(false);
        });
    }
  };

  useEffect(() => {
    if (rerenderDisabled) {
      return;
    }
    if (lastSearch.current !== searchParam) {
      setData([]);
      setPage(0);
    } else if (searchParam === '' && !hasMore) {
      setPage(0);
    }
    lastSearch.current = searchParam;
    fetchData();
  }, [page, searchParam, hasMore]);

  useEffect(() => {
    if (rerenderDisabled) {
      return;
    }
    setData([]);
    setPage(0);
    fetchData();
  }, [queryParams]);

  const handleScroll = useCallback(
    debounce(() => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !isFetching &&
          hasMore
        ) {
          setPage(prevPage => prevPage + 1);
        }
      }
    }, 100),
    [isFetching, hasMore],
  );

  return (
    <ECStack
      {...wrapperProps}
      ref={listRef}
      onScroll={handleScroll}
      overflow="auto"
    >
      <ContainerComponent {...containerProps}>
        {data?.map((item, index) => renderItem(item, index))}
        {!hasMore && !isFetching && (
          <ECBox style={{ padding: '10px', textAlign: 'center' }}>
            <ECTypography variant="subtitle2">
              No more data to load
            </ECTypography>
          </ECBox>
        )}
      </ContainerComponent>
      {isFetching && (
        <ECBox display="flex" justifyContent="center">
          <ECCircularProgress size={40} />
        </ECBox>
      )}
    </ECStack>
  );
};
