import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ECButton,
  ECDynamicPageTemplate,
  ECRequirePermission,
} from 'app/components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useLazyGetWorkOrdersListQuery,
  useUpdateWorkOrderMutation,
  useLazyGetWorkOrderByIdQuery,
} from 'services/workOrdersApi';
import { loadPageScopes } from 'utils/pageScopes';
import { setFilterEndpoint } from 'store/slice/page';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import _ from 'lodash';
import { RootState } from 'index';
import { Edit } from '@mui/icons-material';
import { BulkEditDrawer } from './BulkEditDrawer';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { P } from 'types/Permission';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export const bulkEditButtonStyle = {
  height: '42px',
};

export function WorkOrdersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.user);

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');
  const {
    data: categories,
    isFetching: isFetchingCategories,
    isSuccess: isSuccessCategories,
  } = useGetCategoryListQuery({ st: 1 });

  useEffect(() => {
    loadPageScopes('workOrder');
    dispatch(setFilterEndpoint('/filter/workorder'));
  }, []);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingCategories &&
        isSuccessWorkflowStatuses &&
        isSuccessCategories,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingCategories,
    isSuccessWorkflowStatuses,
    isSuccessCategories,
  ]);

  const isCustomer = useCustomerUser();

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  const categoryFilterOptions = useMemo(() => {
    return (
      _.orderBy(categories?.data, 'name')?.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.id?.toString(),
        isInitialSelected: true,
      })) || []
    );
  }, [categories]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);

  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleSelectedRows = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setClearAllSelectedRows(shouldDisable);
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <React.Fragment>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.workOrders.title')}
        useLazyGetListQuery={useLazyGetWorkOrdersListQuery}
        useCreateMutation={{}}
        useLazyGetByIdQuery={useLazyGetWorkOrderByIdQuery}
        useUpdateMutation={useUpdateWorkOrderMutation}
        editFormConfig={fancyFormElementsEdit.data.config}
        editFormFields={fancyFormElementsEdit.data.fields}
        editFormAdditionalProcess={({ row, formConfig }) => {
          formConfig.title = row?.assetName;
        }}
        dateFilterFieldsOptions={[
          {
            id: 1,
            label: 'Created Date',
            fieldName: 'wrkordr.createdAt',
          },
          {
            id: 2,
            label: 'Completed Date',
            fieldName: 'wrkordr.completedDate',
          },
        ]}
        statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterAlias="wrkflwstts.groupNameId"
        showStatusFilter
        categoryFilterAlias="rqstctgry.id"
        showCategoryFilter
        showDateFilter
        showAwaitingInvoiceFilter
        showAssetTypeFilter={!!user?.assignedAssetTypes?.length}
        withDrawer={false}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        showColumnVisibilityFilter
        isDraggableColumns
        enableExport
        // add this to enable table csv export instead of calling the export api
        // enableTableCsvExport
        exportModuleName="workorder"
        multiSelectTable
        selectRowsMaxCount={50}
        customMultiSelectActionButton={() => (
          <ECRequirePermission scopes={[P.EditWorkOrder, P.GetWorkOrder]}>
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={bulkEditButtonStyle}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        )}
        clearSelection={clearAllSelectedRows}
        multiSelectedRows={handleSelectedRows}
      />
      <BulkEditDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
      />
    </React.Fragment>
  );
}
