import { ECUsersTable } from '../../../components/ECUsersTable';
import { useGetAssigneeUsersByInventoryStorageIdQuery } from 'services/inventoryApi';
import { ECBox } from '../../../components/ECBox';

export interface ModuleData {
  id: number;
  preExistingUsers?: any[];
}

export function AssigneeUsersTable({ id, preExistingUsers }: ModuleData) {
  const { data, isFetching, isLoading } =
    useGetAssigneeUsersByInventoryStorageIdQuery(
      { storageId: id },
      {
        skip: preExistingUsers !== undefined || !id,
      },
    );

  return (
    <ECBox id={`popover-users-table`}>
      <ECUsersTable
        users={preExistingUsers || data || []}
        isLoading={isFetching || isLoading}
        hideSearchHeadContent={true}
      />
    </ECBox>
  );
}
