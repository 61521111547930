import { UserList } from './User';

export interface Approval {
  id: number;
  priorityId: number;
  name: string;
}

export enum StepsLkpModule {
  Invoice = 'Invoice',
  Proposal = 'Proposal',
  Workorder = 'Workorder',
  Rfp = 'rfp',
}
export enum ApprovalStepsType {
  SuperApproval = 'superApproval',
  SpecialApproval = 'specialApproval',
  WorkFlow = 'workFlow',
}

interface ApprovalBase {
  id: number;
  title: string;
  description?: string;
  branchType?: string;
  requestCategory?: any[];
  amount: string;
  active?: boolean;
}

export interface ApprovalList extends ApprovalBase {
  id: number;
  createdByUser: UserList;
  updatedByUser: UserList;
  companyId: number;
}

export interface ApprovalListFlat extends ApprovalBase {
  id: number;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  companyId: number;
  status: string;
}

export interface ApprovalUpdate extends ApprovalBase {
  branchTypeId?: number;
  requestCategory?: number[];
}

export interface ApprovalCreate extends ApprovalBase {
  branchTypeId?: number;
  requestCategory?: number[];
}

export interface ApprovalAction {
  id: number;
  name: string;
}

export interface ApprovalRoleType {
  id: number;
  name: string;
}

interface ApprovalStepBase {
  approvalRoleTypeId: number;
  activeDays: number;
  limit: number;
  order: number;
}

export interface ApprovalSteps extends ApprovalStepBase {
  approvalId: number;
  approvalProcessStepId: number;
  jobTitleId: number;
}

export interface ApprovalStepForm extends ApprovalStepBase {
  id?: number;
  approvalProcessId: number | null;
  jobTitleId: number | null;
  jobTitle: {
    id: number | null;
    name: string;
  } | null;
  step: number;
  approvalRoleType: {
    id: number;
    name: string;
  };
  actions:
    | {
        id: number;
        name: string;
      }
    | null[];
  isNew?: boolean;
}

export interface GetStepsParams {
  id: number;
  module:
    | StepsLkpModule.Invoice
    | StepsLkpModule.Proposal
    | StepsLkpModule.Rfp
    | StepsLkpModule.Workorder;
}

export interface GetValidateParams {
  branchTypeIds: number[];
  categoryIds: number[];
  amount: number;
  approvalId?: number;
}

export interface GetApprovalHistoryParams {
  id: number;
  module: string;
}
