import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { hasPermission } from 'utils/permissions';
import { P } from 'types/Permission';

export const useHasPermission = (permissions: P[]) => {
  const user = useSelector((state: RootState) => state?.user);

  if (!user?.permissions) {
    return [false];
  }

  return permissions.map(permission =>
    hasPermission({
      userPermissions: user.permissions.map(p => p.action),
      scopes: [permission],
    }),
  );
};
