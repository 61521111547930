import { Add } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { ECBox, ECButton, ECEasyFormCreate, FieldTypes } from 'app/components';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useCreateSupplierMutation } from 'services/supplierApi';
import { clearFormFields } from 'utils/strings/form';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

interface EditProps {
  onClose?: () => void;
}

export const NewSupplierForm = forwardRef(
  ({ onClose }: EditProps, ref: ForwardedRef<any>) => {
    const dispatch = useDispatch();
    const [formFields, setFormFields] = useState<any[]>(
      fancyFormElementsCreate.fields,
    );

    useImperativeHandle(ref, () => ({
      clearAllFields() {
        fancyFormElementsCreate.fields = clearFormFields(
          fancyFormElementsCreate.fields,
        );

        const contactsCount = fancyFormElementsCreate.fields.filter(
          field =>
            field?.type === FieldTypes.Group &&
            field.fieldName?.includes('contactGroup'),
        )?.length;

        for (let i = 2; i <= contactsCount; i++) {
          handleRemoveContactGroup(2);
        }

        fancyFormElementsCreate.fields = fancyFormElementsCreate.fields?.map(
          field => {
            if (field.fieldName === 'addressGroup') {
              field.subFields?.map(subField => {
                if (subField.fieldName) {
                  subField.required = false;
                }

                return subField;
              });
            }

            return field;
          },
        );
      },
    }));

    const [
      doCreateSupplier,
      { data, isError, error, isSuccess, reset, isLoading },
    ] = useCreateSupplierMutation();

    const buildContactFields = (index: number) => [
      {
        type: 'group',
        groupColumns: 2,
        fieldName: `contactGroup${index}`,
        subFields: [
          {
            type: 'label',
            label: `Contact ${index}`,
            optionalSx: {
              marginBottom: 0,
            },
          },
          {
            type: 'custom',
            fieldName: `removeContact${index}`,
            value: (
              <ECBox display="flex" justifyContent="flex-end">
                <ECButton
                  onClick={() => handleRemoveContactGroup(index)}
                  variant="text"
                  sx={{
                    '.MuiButton-startIcon': {
                      ml: 0,
                      mr: 0,
                    },
                  }}
                  startIcon={<Close color="error" />}
                ></ECButton>
              </ECBox>
            ),
          },
        ],
      },
      {
        type: 'group',
        groupColumns: 2,
        fieldName: `contactNameGroup${index}`,
        subFields: [
          {
            type: 'text_field',
            label: 'Contact First Name',
            placeholder: 'Contact First Name',
            value: null,
            required: true,
            fieldName: `contactFirstName${index}`,
          },
          {
            type: 'text_field',
            label: 'Contact Last Name',
            placeholder: 'Contact Last Name',
            value: null,
            fieldName: `contactLastName${index}`,
          },
        ],
      },
      {
        type: 'text_field',
        label: 'Job Title',
        placeholder: 'Job Title',
        value: '',
        required: true,
        fieldName: `contactJobTitle${index}`,
      },
      {
        type: 'group',
        groupColumns: 3,
        fieldName: `contactEmailGroup${index}`,
        subFields: [
          {
            type: 'text_field',
            label: 'Contact Email',
            placeholder: 'Contact Email',
            checkEmail: true,
            value: null,
            maxLength: 200,
            required: true,
            fieldName: `contactEmail${index}`,
          },
          {
            type: 'phone',
            label: 'Contact Phone',
            placeholder: 'Contact Phone',
            value: '',
            isNumeric: true,
            maxLength: 45,
            required: true,
            fieldName: `contactPhone${index}`,
          },
          {
            type: 'text_field',
            label: 'EXT',
            placeholder: 'EXT',
            value: '',
            isNumeric: true,
            maxLength: 5,
            fieldName: `contactPhoneExt${index}`,
          },
        ],
      },
    ];

    const handleAddNew = () => {
      const contactsCount = fancyFormElementsCreate.fields.filter(
        field =>
          field?.type === FieldTypes.Group &&
          field.fieldName?.includes('contactGroup'),
      )?.length;

      const newContactFields = buildContactFields(contactsCount + 1);

      const indexToAdd = fancyFormElementsCreate.fields.findIndex(
        field => field.fieldName === 'addNewContact',
      );

      fancyFormElementsCreate.fields = [
        ...fancyFormElementsCreate.fields?.slice(0, indexToAdd),
        ...newContactFields,
        ...fancyFormElementsCreate.fields?.slice(indexToAdd),
      ];

      if (contactsCount === 9) {
        const addNewContactfield = fancyFormElementsCreate.fields.find(
          field => field.fieldName === 'addNewContact',
        );

        addNewContactfield.value = null;
      }

      setFormFields([...fancyFormElementsCreate.fields]);
    };

    useEffect(() => {
      const addNewContactField = fancyFormElementsCreate.fields.find(
        field => field.fieldName === 'addNewContact',
      );

      addNewContactField.value = (
        <ECBox display="flex">
          <ECButton onClick={handleAddNew} variant="text" startIcon={<Add />}>
            Add New Contact
          </ECButton>
        </ECBox>
      );
      setFormFields([...fancyFormElementsCreate.fields]);
    }, []);

    const updateContactFieldsIndexes = () => {
      let index = 1;
      fancyFormElementsCreate.fields = fancyFormElementsCreate.fields.map(
        field => {
          if (field.fieldName?.includes('contact')) {
            field.fieldName = field.fieldName.replace(/\d+/, `${index}`);
            field.subFields = field.subFields?.map(subField => {
              if (subField.fieldName) {
                subField.fieldName = subField.fieldName.replace(
                  /\d+/,
                  `${index}`,
                );
              }

              if (subField.label) {
                subField.label = subField.label.replace(/\d+/, `${index}`);
              }

              if (subField.type === FieldTypes.Custom && index !== 1) {
                const indexThatWorks = index;
                subField.value = (
                  <ECBox display="flex" justifyContent="flex-end">
                    <ECButton
                      onClick={() => handleRemoveContactGroup(indexThatWorks)}
                      variant="text"
                      sx={{
                        '.MuiButton-startIcon': {
                          ml: 0,
                          mr: 0,
                        },
                      }}
                      startIcon={<Close color="error" />}
                    ></ECButton>
                  </ECBox>
                );
              }
              return subField;
            });

            if (field.fieldName?.includes('contactEmailGroup')) {
              index++;
            }
          }

          return field;
        },
      );

      setFormFields([...fancyFormElementsCreate.fields]);
    };

    const handleRemoveContactGroup = (index: number) => {
      fancyFormElementsCreate.fields = fancyFormElementsCreate.fields?.filter(
        field =>
          !field.fieldName?.includes(`contactGroup${index}`) &&
          !field.fieldName?.includes(`contactNameGroup${index}`) &&
          !field.fieldName?.includes(`contactJobTitle${index}`) &&
          !field.fieldName?.includes(`contactEmailGroup${index}`),
      );

      updateContactFieldsIndexes();

      const addNewContactField = fancyFormElementsCreate.fields.find(
        field => field.fieldName === 'addNewContact',
      );

      addNewContactField.value = (
        <ECBox display="flex">
          <ECButton onClick={handleAddNew} variant="text" startIcon={<Add />}>
            Add New Contact
          </ECButton>
        </ECBox>
      );

      setFormFields([...fancyFormElementsCreate.fields]);
    };

    const useCreateaMutation = useCallback(() => {
      const create = (supplier: any) => {
        const contactsCount = Object.keys(supplier).filter(key =>
          key.includes('contactFirstName'),
        ).length;

        const contacts = Array.from({ length: contactsCount }, (_, i) => {
          return {
            firstName: supplier[`contactFirstName${i + 1}`],
            lastName: supplier[`contactLastName${i + 1}`],
            jobTitle: supplier[`contactJobTitle${i + 1}`],
            email: supplier[`contactEmail${i + 1}`],
            phone: supplier[`contactPhone${i + 1}`],
            phoneExt: supplier[`contactPhoneExt${i + 1}`],
          };
        });

        const address = supplier.line1
          ? {
              line1: supplier.line1,
              line2: supplier.line2,
              cityName: supplier.cityName,
              stateProvinceCode: supplier.stateProvinceCode,
              zipCodePostalCode: supplier.zipCodePostalCode || null,
              countryCode: supplier.countryCode,
            }
          : undefined;

        const data = {
          ...supplier,
          contacts,
        };

        if (address) {
          data.address = address;
        }

        doCreateSupplier(data);
      };

      return [
        create,
        {
          data,
          isError,
          error,
          isSuccess,
          reset,
          isLoading,
        },
      ];
    }, [data, isError, error, isSuccess, reset, isLoading, doCreateSupplier]);

    const handleChange = output => {
      fancyFormElementsCreate.fields = output;
    };

    return (
      <ECEasyFormCreate
        useCreateMutation={useCreateaMutation}
        onClose={onClose}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        isLoading={isLoading}
        showSuccessSnackBar={false}
        onChange={handleChange}
      />
    );
  },
);
