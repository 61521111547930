import { ECTabContainer } from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import { useMemo } from 'react';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';

export function LocationManagerPage() {
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;

  const locationManagementTabs = useMemo(() => {
    const tabs = [
      {
        value: 'concepts',
        link: 'concepts',
        label: t('translation:panelDrawer.branchTypes'),
        content: <Outlet />,
        scopes: [P.GetAllBranchCategories],
      },
    ];

    if (areaEnabled) {
      tabs.push({
        value: 'areas',
        link: 'areas',
        label: t('translation:panelDrawer.areas'),
        content: <Outlet />,
        scopes: [P.GetAllAreas],
      });
    }

    return tabs;
  }, [areaEnabled, t]);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'translation:panelDrawer.locationManagement',
        )} - %s`}
        defaultTitle={t('translation:panelDrawer.locationManagement')}
      />

      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={locationManagementTabs}
      />
    </>
  );
}
