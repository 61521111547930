import { ECTabContainer } from 'app/components';
import { useServiceProviderUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { FlagsPage } from '../FlagsPage';
import { ForbiddenPage } from '../ForbiddenPage';

export function SPGeneralSettingsPage() {
  const { t } = useTranslation();

  const isServiceProvider = useServiceProviderUser();

  if (!isServiceProvider) {
    return <ForbiddenPage />;
  }

  return (
    <ECTabContainer
      mt={StyleConstants.NAV_BAR_HEIGHT}
      p={2}
      variant="standard"
      color="marketingSuggestion"
      noPadding
      tabs={[
        {
          value: '1',
          label: t('translation:panelDrawer.flags'),
          content: <FlagsPage />,
          scopes: [P.GetAllFlags],
        },
      ]}
    />
  );
}
