import { ECBox } from '../ECBox';
import { ECFancyFormEdit } from '../ECDynamicForm';
import { ECEasyFormConfigType, ECEasyFormFieldType } from '../ECForm';
import { ECTypography } from '../ECTypography';
import { CostTableData } from '../ECWorkflowCostTable';
import { SubmissionConfirmationModal } from '../ECForm';

interface EditForm2Props<DataObject> {
  row: DataObject;
  formConfig?: ECEasyFormConfigType;
  formFields?: ECEasyFormFieldType[];
  detailsConfig?: ECEasyFormConfigType;
  detailsFields?: ECEasyFormFieldType[];
  useUpdateMutation?: any;
  useDeleteMutation?: any;
  additionalDeleteAction?: () => void;
  deleteConfirmationTitle?: string;
  deleteConfirmationBody?: string;
  additionalActions?: React.ReactNode;
  additionalElements?: React.ReactNode;
  onlyEdit?: boolean;
  hideToolBar?: boolean;
  isLoading?: boolean;
  disclaimer?: string;
  costTable?: CostTableData;
  editFormAdditionalProcess?: ({ row, formConfig, formFields }) => void;
  onEditClose?: () => void;
  onClose?: () => void;
  onDuplicateChip?: () => void;
  showEditButton?: boolean;
  showCloseButton?: boolean;
  showSaveButton?: boolean;
  onChange?: (
    output: ECEasyFormFieldType[],
    fieldName?: string,
    valueChange?: any,
  ) => void;
  onEditButtonClick?: (boolean) => void;
  showTitle?: boolean;
  outsideData?: ECEasyFormFieldType[];
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  fullHeight?: boolean;
  checkNothingToUpdate?: boolean;
  checkEqualFields?: boolean;
  stickyFooter?: boolean;
  submissionConfirmationModal?: SubmissionConfirmationModal;
  drawerTitleBarBGColor?: string;
  customDrawerTitle?: string;
}
export function EditForm2<DataObject>(props: EditForm2Props<DataObject>) {
  const {
    row,
    formConfig,
    formFields,
    detailsConfig,
    detailsFields,
    useUpdateMutation,
    useDeleteMutation,
    additionalDeleteAction,
    deleteConfirmationTitle,
    deleteConfirmationBody,
    additionalActions,
    additionalElements,
    onlyEdit,
    hideToolBar,
    isLoading,
    disclaimer,
    costTable,
    editFormAdditionalProcess,
    onEditClose,
    onClose,
    onDuplicateChip,
    showEditButton = true,
    showCloseButton,
    showSaveButton,
    onChange,
    onEditButtonClick,
    showTitle = true,
    outsideData,
    noPaddingX,
    noPaddingY,
    fullHeight,
    checkNothingToUpdate,
    checkEqualFields,
    stickyFooter,
    submissionConfirmationModal,
    drawerTitleBarBGColor,
    customDrawerTitle,
  } = props;

  if (
    editFormAdditionalProcess &&
    typeof editFormAdditionalProcess === 'function'
  ) {
    editFormAdditionalProcess({
      row,
      formConfig,
      formFields,
    });
  }
  return (
    <>
      {!onlyEdit && detailsConfig?.fieldAsSubtitle && (
        <ECBox p={3}>
          <ECTypography variant="h5">
            {row[detailsConfig.fieldAsSubtitle]}
          </ECTypography>
        </ECBox>
      )}

      <ECFancyFormEdit
        row={row}
        config={formConfig ?? ({} as any)}
        fields={formFields ?? []}
        detailsConfig={detailsConfig}
        detailsFields={detailsFields}
        useUpdateMutation={useUpdateMutation}
        useDeleteMutation={useDeleteMutation}
        additionalDeleteAction={additionalDeleteAction}
        additionalActions={additionalActions}
        additionalElements={additionalElements}
        onClose={onClose}
        onEditClose={onEditClose}
        onDuplicateChip={onDuplicateChip}
        onlyEdit={onlyEdit}
        hideToolBar={hideToolBar}
        isLoading={isLoading}
        disclaimer={disclaimer}
        deleteConfirmationTitle={deleteConfirmationTitle}
        deleteConfirmationBody={deleteConfirmationBody}
        costTable={costTable}
        onChange={onChange}
        onEditButtonClick={onEditButtonClick}
        showEditButton={showEditButton}
        showCloseButton={showCloseButton}
        showSaveButton={showSaveButton}
        showTitle={showTitle}
        outsideData={outsideData}
        noPaddingX={noPaddingX}
        noPaddingY={noPaddingY}
        fullHeight={fullHeight}
        checkNothingToUpdate={checkNothingToUpdate}
        checkEqualFields={checkEqualFields}
        stickyFooter={stickyFooter}
        submissionConfirmationModal={submissionConfirmationModal}
        drawerTitleBarBGColor={drawerTitleBarBGColor}
        customDrawerTitle={customDrawerTitle}
      />
    </>
  );
}
