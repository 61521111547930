import { ECDrawerDetails } from '../ECDrawerDetails';
import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { useGetHistoryListQuery } from 'services/historyApi';
import { useMemo } from 'react';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { ECActivityCard } from '../ECActivityCard';
import { ECButton } from '../ECButton';
import { ActivityType } from 'types/Activity';
import { StyleConstants } from 'styles/StyleConstants';

export const ECDrawerActivities = ({
  isActivityDrawerOpen,
  onClose,
  openActivityDrawer,
  activityType,
}: {
  isActivityDrawerOpen: boolean;
  onClose: () => void;
  openActivityDrawer?: () => void;
  activityType?: ActivityType;
}) => {
  const companyId = useMemo(() => {
    const companyProfile = localStorage.getItem('userProfile');
    if (!companyProfile) return null;
    return JSON.parse(companyProfile)?.company?.id;
  }, []);

  const activityInfo: { title: string; moduleName: string } = useMemo(() => {
    switch (activityType) {
      case ActivityType.Trade:
        return { title: 'Trades Activity', moduleName: 'trade' };
      case ActivityType.Organization:
        return { title: 'Org Chart Activity', moduleName: 'organization' };
      case ActivityType.JobTitle:
        return { title: 'Job Titles Activity', moduleName: 'job-title' };
      case ActivityType.Problems:
        return { title: 'Problem Activity', moduleName: 'problem' };
      case ActivityType.AssetTradeProblem:
        return {
          title: 'Asset - Trade - Problem Activity',
          moduleName: 'asset-trade-problem',
        };
      default:
        return { title: '', moduleName: '' };
    }
  }, [activityType]);

  const { data: activities, isLoading } = useGetHistoryListQuery(
    {
      moduleName: activityInfo.moduleName,
      moduleResourceId: companyId,
    },
    { skip: !companyId || !activityInfo.moduleName },
  );

  return (
    <>
      <ECButton
        variant="outlined"
        style={{
          background: 'transparent',
          paddingTop: '7px',
          paddingBottom: '7px',
          height: '40px',
        }}
        onClick={() => {
          openActivityDrawer?.();
        }}
      >
        Activity
      </ECButton>
      <ECDrawerDetails
        open={isActivityDrawerOpen}
        anchor="right"
        onClose={onClose}
      >
        <ECBox
          pt={StyleConstants.NAV_BAR_HEIGHT}
          minHeight="100vh"
          position="relative"
        >
          <>
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
              }}
            >
              <ECBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={3}
                sx={{
                  backgroundColor: theme => theme.palette.other.divider,
                  height: StyleConstants.DRAWER_TITLE_HEIGHT,
                }}
              >
                <ECTypography variant={'h4'}>{activityInfo.title}</ECTypography>
                <ECIconButton
                  sx={{ background: '#fff' }}
                  squared
                  onClick={onClose}
                >
                  <Close />
                </ECIconButton>
              </ECBox>
              <ECBox
                px={3}
                pb={4}
                mt={3}
                display="flex"
                flexDirection="column"
                rowGap={4}
              >
                {isLoading && <ECCircularProgress sx={{ marginX: 'auto' }} />}

                {activities?.data?.map((entry, index) => (
                  <ECActivityCard
                    key={index}
                    entry={{
                      ...entry._doc,
                    }}
                  />
                ))}
              </ECBox>
            </ECBox>
          </>
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
