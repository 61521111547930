import { useEffect } from 'react';
import { ECBox, ECDynamicPageTemplate } from 'app/components';
import {
  useAddProblemTypeMutation,
  useEditProblemMutation,
  useGetProblemTypesQuery,
  useLazyGetProblemListQuery,
} from 'services/problemApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { loadPageScopes } from 'utils/pageScopes';

const tableConfig = require('./tableConfig.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export function ProblemTypesPage() {
  useEffect(() => {
    loadPageScopes('problem');
  }, []);

  const {
    data: template,
    error: errorTemplate,
    isLoading: isLoadingTemplate,
    isSuccess: isSuccessTemplate,
  } = useGetProblemTypesQuery();

  useEffect(() => {
    if (isSuccessTemplate && template) {
      populateDropDownFields({
        responseData: template,
        createFormFields: fancyFormElementsCreate.data.fields,
        editFormFields: fancyFormElementsEdit.data.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problemTemplateId',
      });
    }
  }, [isSuccessTemplate, template]);

  return (
    <ECBox sx={{ padding: '20px' }}>
      <ECDynamicPageTemplate
        pageTitle={'Problem'}
        useLazyGetListQuery={useLazyGetProblemListQuery}
        useCreateMutation={useAddProblemTypeMutation}
        useUpdateMutation={useEditProblemMutation}
        tableConfig={tableConfig.data.config}
        createFormConfig={fancyFormElementsCreate.data.config}
        createFormFields={fancyFormElementsCreate.data.fields}
        editFormConfig={fancyFormElementsEdit.data.config}
        editFormFields={fancyFormElementsEdit.data.fields}
        downloadFileName="problem.csv"
        enableExport={true}
        shouldNotUseActiveFilter
      />
    </ECBox>
  );
}
