import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['JobTitleRole'],
});

export const jobTitleRoleApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getJobTitleRoleList: builder.query({
      query: params => {
        return {
          url: 'jobtitle/role',
          params,
        };
      },
      providesTags: ['JobTitleRole'],
    }),
  }),
});

export const { useGetJobTitleRoleListQuery, useLazyGetJobTitleRoleListQuery } =
  jobTitleRoleApi;
