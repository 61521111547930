import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  ECBox,
  ECIconButton,
  ECInfiniteScroll,
  ECList,
  ECStack,
  ECTextField,
  ECTypography,
} from '..';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { ECAutocomplete } from '../ECAutocomplete';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { useLazyGetWorkorderDispatchColumnUnassignedViewQuery } from 'services/workOrdersApi';
import { DispatchWorkOrderCard } from 'app/pages/DispatchPage/DispatchSwimlanesView/DispatchWorkOrdersCard';
import SortableWorkOrderCardItem from 'app/pages/DispatchPage/DispatchSwimlanesView/SortableWorkOrderCardItem';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import _ from 'lodash';
const unAssignedPanelName = 'unAssignedPanel';
interface ECCalendarViewUnassignedTechPanelProps {
  onUnassignedWorkOrderDataUpdate?: (data: any) => void;
  isObjectDraged?: boolean;
  dragPanelId?: string;
}

export const ECCalendarViewUnassignedTechPanel: React.FC<
  ECCalendarViewUnassignedTechPanelProps
> = ({ onUnassignedWorkOrderDataUpdate, isObjectDraged, dragPanelId }) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { setNodeRef } = useDroppable({ id: unAssignedPanelName });
  const [panelWOCards, setPanelWOCards] = useState<any[]>([]);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');

  const statusOptions = useMemo(() => {
    const uniqueStatuses = _.uniqBy(workflowStatuses, 'name');
    const orderedStatuses = _.orderBy(uniqueStatuses, 'name');

    return (
      orderedStatuses?.map(status => ({
        id: status?.id,
        label: status.name,
        fieldName: workflowStatuses
          ?.filter(
            workflowStatus =>
              workflowStatus.name === status.name && workflowStatus.groupId,
          )
          ?.map(workflowStatus => workflowStatus.groupId)
          ?.join(','),
        isInitialSelected: status.name === 'Unassigned',
      })) ?? []
    );
  }, [workflowStatuses]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const handleSearchClicked = useCallback(e => {
    setSearchButtonClicked(true);
  }, []);

  const renderItem = useMemo(
    () => (workorder, index) => {
      return (
        <SortableWorkOrderCardItem id={workorder?.id} key={workorder?.id}>
          <DispatchWorkOrderCard
            key={`${workorder?.id}-${workorder?.assetName}-${workorder?.locationName}-${index}`}
            workOrder={workorder ?? {}}
          />
        </SortableWorkOrderCardItem>
      );
    },
    [searchTerm],
  );

  useEffect(() => {
    if (!selectedOption) {
      onUnassignedWorkOrderDataUpdate?.([]);
    }
  }, [selectedOption]);

  const handleUnassignedWorkOrderDataUpdate = useCallback(
    (newData: any[]) => {
      onUnassignedWorkOrderDataUpdate?.(newData);

      setPanelWOCards(prevWOCards => {
        const newUniqueTasks = newData.filter(
          item => !prevWOCards.some(prevItem => prevItem.id === item.id),
        );

        if (newUniqueTasks.length === 0) {
          return prevWOCards;
        }
        return [...prevWOCards, ...newUniqueTasks];
      });
    },
    [setPanelWOCards],
  );
  const [searchButtonClicked, setSearchButtonClicked] = useState(true);

  const queryParamsMemoized = useMemo(() => {
    const queryParams = {
      t: 50,
      o: 'd',
      ob: '',
      sb: 'wrkflwstts.name',
      s: 'Unassigned',
    };

    if (searchButtonClicked) {
      if (selectedOption) {
        queryParams['sb'] = 'wrkflwstts.name';
        queryParams['s'] = selectedOption?.label;
      }
      const searchFields = [
        'wrkordr.id',
        'asst.name',
        'asst.id',
        'rqstctgry.name',
        'assttp.name',
        'brnch.id',
        'brnch.name',
        'brnch.storeNumber',
      ];

      if (searchTerm) {
        searchFields.forEach((field, index) => {
          queryParams[`sb${index}`] = field;
          queryParams[`s${index}`] = `*${searchTerm}*`;
          queryParams[`sg${index}`] = 10;
          queryParams[`sglo${index}`] = 'or';
        });
      }
    }
    return queryParams;
  }, [selectedOption, searchTerm, searchButtonClicked]);

  return (
    <ECBox
      sx={{
        minWidth: '254px',
        height: 'calc(73vh - 20px)', 
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        zIndex: 10,
        marginTop: '2px',
        position: 'relative',
      }}
    >
      <ECBox
        sx={{
          backgroundColor: 'white',
          border: '1px solid #E0E0E0',
          borderTop: 'none',
        }}
      >
        <ECBox sx={{ width: '100%' }}>
          <ECStack
            direction="column"
            border="1px solid #E0E0E0"
            borderRadius="4px"
            display="flex"
            justifyContent="space-between"
            bgcolor="#0072CE"
            minWidth="200px"
            sx={{ height: '56px' }}
          >
            <ECTypography
              variant="body2"
              color={theme => theme.palette.background.default}
              fontWeight={500}
              fontSize={12}
              whiteSpace="nowrap"
              marginLeft="10px"
              lineHeight="24px"
            >
              No Technician Assigned
            </ECTypography>
            <hr
              color="#eeeeee"
              style={{
                width: '100%',
                margin: 0,
                border: 'none',
                borderTop: '1px solid #ffffff',
              }}
            />
            <ECBox sx={{ pointerEvents: 'auto' }}>
              <ECAutocomplete
                id="wo-status-select"
                placeholder="Unassigned"
                options={statusOptions}
                getOptionLabel={option =>
                  option?.label || option?.text || option
                }
                autoSelect
                value={selectedOption}
                onChange={handleChange}
                sx={{
                  backgroundColor: '#0072CE',
                  height: '30px',
                  '& .MuiAutocomplete-inputRoot': {
                    padding: '0px',
                    height: '30px',
                  },
                  '& .MuiInputBase-input': {
                    color: theme => theme.palette.background.default,
                    fontSize: '16px',
                    fontWeight: 500,
                    marginLeft: '8px',
                    transform: 'translateY(-5px)',
                    pointerEvents: 'auto',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiAutocomplete-paper': {
                    backgroundColor: '#0072CE',
                    zIndex: 1300,
                    pointerEvents: 'auto',
                  },
                  '& .MuiAutocomplete-option': {
                    pointerEvents: 'auto',
                    color: theme => theme.palette.background.default,
                  },
                  '& .MuiIconButton-root': {
                    color: theme => theme.palette.background.default,
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    color: theme => theme.palette.background.default,
                  },
                }}
              />
            </ECBox>
          </ECStack>
          <ECBox sx={{ padding: 1, height: 'calc(73vh - 20px)' }}>
            <ECTextField
              fullWidth
              variant="filled"
              value={searchTerm}
              sx={{ marginBottom: '10px' }}
              onChange={e => {
                setSearchTerm(e.target.value);
                setSearchButtonClicked(false);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ECIconButton onClick={handleSearchClicked} size="small">
                      <SearchIcon />
                    </ECIconButton>
                  </InputAdornment>
                ),
                placeholder: 'Search...',
              }}
            />
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SortableContext
                id="noTechAssigned-popover"
                items={panelWOCards}
                strategy={verticalListSortingStrategy}
              >
                <ECBox
                  ref={setNodeRef}
                  sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    opacity: dragPanelId === unAssignedPanelName ? 0.6 : 1,
                    filter:
                      dragPanelId === unAssignedPanelName
                        ? 'grayscale(40%)'
                        : 'none',
                  }}
                >
                  <ECInfiniteScroll
                    queryFn={
                      useLazyGetWorkorderDispatchColumnUnassignedViewQuery
                    }
                    ContainerComponent={ECList}
                    containerProps={{ noPadding: true }}
                    queryParams={queryParamsMemoized}
                    renderItem={renderItem}
                    wrapperProps={{
                      height: '64.5vh',
                    }}
                    onDataUpdate={handleUnassignedWorkOrderDataUpdate}
                    rerenderDisabled={isObjectDraged || !searchButtonClicked}
                  />
                </ECBox>
              </SortableContext>
            </ECBox>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
