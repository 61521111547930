import { QueryParams } from 'types/QueryParams';
import { BaseType } from 'types/BaseType';
import { GeneralAsset, GeneralAssetUpdate } from 'types/GeneralAsset';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['GeneralAsset', 'GeneralAssetById'],
});

export const generalAssetApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getGeneralAssetsList: builder.query<BaseType<GeneralAsset[]>, QueryParams>({
      query: params => {
        return {
          url: 'general-asset',
          params,
        };
      },
      transformResponse: (response: BaseType<GeneralAsset[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          status: row.status ? 'Active' : 'Inactive',
          moduleName: 'general-asset',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['GeneralAsset'],
    }),
    updateGeneralAsset: builder.mutation<void, GeneralAssetUpdate>({
      query: ({ id, ...body }) => {
        return {
          url: `general-asset/${id}`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['GeneralAssetById'],
    }),
    createGeneralAsset: builder.mutation<GeneralAsset, GeneralAsset>({
      query: body => {
        const formData = new FormData();

        formData.append('name', body?.name);
        formData.append('assetTypeId', body?.assetType.id.toString());
        formData.append('nte', body?.nte.toString() || '');
        if (body?.area?.id) {
          formData.append('areaId', body?.area?.id.toString() || '');
        }
        formData.append('priorityId', body?.priority?.id.toString());
        formData.append('status', '1');
        if (body?.branchType) {
          formData.append(
            'branchTypeIds',
            body?.branchType?.map(branchType => branchType?.id)?.join(',') ||
              '',
          );
        }
        body?.files?.forEach(file => {
          formData.append('files', file.processedFile);
        });

        return {
          url: 'general-asset',
          body: formData,
          method: 'post',
        };
      },
      invalidatesTags: ['GeneralAsset'],
    }),
    getGeneralAssetById: builder.query<GeneralAsset, number>({
      query: id => {
        return {
          url: `general-asset/${id}`,
        };
      },
      transformResponse: (response: GeneralAsset) => {
        return {
          ...response,
          moduleName: 'general-asset',
        };
      },
      providesTags: ['GeneralAssetById'],
    }),
  }),
});

export const {
  useGetGeneralAssetsListQuery,
  useLazyGetGeneralAssetsListQuery,
  useUpdateGeneralAssetMutation,
  useCreateGeneralAssetMutation,
  useGetGeneralAssetByIdQuery,
  useLazyGetGeneralAssetByIdQuery,
} = generalAssetApi;
