import { useEffect } from 'react';
import { ECTabContainer } from 'app/components';
import { useTranslation } from 'react-i18next';
import { TradeAssignmentByBranchPage } from '../TradeAssignmentByBranchPage';
import { TradeAssignmentByTradePage } from '../TradeAssignmentByTradePage';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { setTitle } from 'store/slice/page';
import { TradeAssignmentBySpPage } from '../TradeAssignmentBySpPage';

export function TradeAssignmentPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.tradeAssignment')));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('translation:panelDrawer.tradeAssignment')}</title>
      </Helmet>
      <ECTabContainer
        p={2}
        variant="standard"
        type="contained"
        noPadding
        tabs={[
          {
            value: '1',
            label: t('translation:pages.tradeAssignment.byTrade'),
            content: <TradeAssignmentByTradePage />,
          },
          {
            value: '2',
            label: t('translation:pages.tradeAssignment.byLocation'),
            content: <TradeAssignmentByBranchPage />,
          },
          {
            value: '3',
            label: t('translation:pages.tradeAssignment.bySp'),
            content: <TradeAssignmentBySpPage />,
          },
        ]}
        showActivityButton
      />
    </>
  );
}
