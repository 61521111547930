import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useCallback, useEffect, useState } from 'react';
import { useCopyTradeAssignmentToBranchIdMutation } from 'services/tradeApi';
import { Branch } from 'types/Branch';
import { useGetBranchesListQuery } from 'services/branchApi';

interface Props {
  selectedBranch: Branch;
  onClose?: () => void;
}

const formConfig = require('./fancy_form_config_copy_trade_assignment.json');

export const CopyTradeAssignment = ({ selectedBranch, onClose }: Props) => {
  const [formFields, setFormFields] = useState(formConfig.fields);

  const [
    doCopyTradeAssignments,
    {
      isError: isCopyError,
      error: copyError,
      isSuccess: isCopySuccess,
      isLoading: isCopyLoading,
      reset,
    },
  ] = useCopyTradeAssignmentToBranchIdMutation();

  useEffect(() => {
    const updatedFormFields = [...formFields];

    const branchToField = updatedFormFields.find(
      field => field.fieldName === 'branchTo',
    );

    branchToField.useQuery = useGetBranchesListQuery;
    branchToField.obAlias = 'brnch.name';
    branchToField.queryParams = { st: 1 };
    branchToField.filterOptionsIds = [selectedBranch?.id];

    setFormFields(updatedFormFields);
  }, [selectedBranch?.id]);

  const useUpdate = useCallback(() => {
    const doCopy = async data => {
      const fromBranchId = selectedBranch.id;
      const toBranchId = data.branchTo?.id;

      doCopyTradeAssignments({ fromBranchId, toBranchId });
    };

    return [
      doCopy,
      {
        isError: isCopyError,
        error: copyError,
        isLoading: isCopyLoading,
        isSuccess: isCopySuccess,
        reset,
      },
    ];
  }, [
    selectedBranch,
    isCopyError,
    copyError,
    isCopyLoading,
    isCopySuccess,
    reset,
    doCopyTradeAssignments,
  ]);

  return (
    <EditForm2
      row={selectedBranch}
      formConfig={formConfig.config}
      formFields={formFields}
      useUpdateMutation={useUpdate}
      onClose={onClose}
      isLoading={false}
      hideToolBar
      showTitle
      onlyEdit
    />
  );
};
