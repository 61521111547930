import { ECBox, ECTypography } from '..';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const rgbaColor = 'rgba(193, 225, 255, 55)';
const fullColor = 'rgba(126, 185, 235, 255)';

const DotFlashing = () => {
  const dotContainerStyle = {
    position: 'relative',
    width: '120px',
    height: '60px',
  };

  const dotFlashingStyle = {
    position: 'absolute',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: rgbaColor,
    animation: 'dot-flashing 2s infinite linear',
  };

  const dotBeforeStyle = {
    ...dotFlashingStyle,
    left: '0',
    animationDelay: '0s',
  };

  const dotMiddleStyle = {
    ...dotFlashingStyle,
    left: '50%',
    transform: 'translateX(-50%)',
    animationDelay: '0.5s',
  };

  const dotAfterStyle = {
    ...dotFlashingStyle,
    right: '0',
    animationDelay: '1s',
  };

  return (
    <ECBox sx={dotContainerStyle}>
      <ECBox sx={dotBeforeStyle}></ECBox>
      <ECBox sx={dotMiddleStyle}></ECBox>
      <ECBox sx={dotAfterStyle}></ECBox>
      <style>
        {`
         @keyframes dot-flashing {
           0% {
             background-color: ${rgbaColor};
           }
           50% {
             background-color: ${fullColor}; 
           }
           100% {
             background-color: ${rgbaColor};
           }
         }
       `}
      </style>
    </ECBox>
  );
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <ECBox
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {' '}
      {/* Add justifyContent property */}
      <ECBox sx={{ width: '35%' }}>
        <LinearProgress variant="determinate" {...props} />
      </ECBox>
      <ECBox sx={{ minWidth: 35 }}>
        <ECTypography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</ECTypography>
      </ECBox>
    </ECBox>
  );
}

interface ECLoadingProgressProps {
  linearWithLabel?: boolean;
  success?: boolean;
  error?: boolean;
}
export const ECLoadingProgress: React.FC<ECLoadingProgressProps> = ({
  linearWithLabel,
  success,
  error,
}) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(1);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress >= 100 ? 0 : prevProgress + 1;
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (linearWithLabel) {
    return (
      <ECBox
        display="flex"
        bgcolor={theme => theme.palette.background.default}
        flexDirection="column"
        sx={{
          width: '90%',
          marginTop: '35%',
          marginLeft: '10%',
          justifyContent: 'center',
        }}
        textAlign="center"
        alignItems="center"
        p={3}
      >
        <ECBox textAlign="center" sx={{ width: '100%' }}>
          <ECTypography
            sx={{ fontWeight: 700, fontSize: 22, marginBottom: '30px' }}
            color={success ? '#303f9f' : error ? '' : '#303f9f'}
          >
            {!success && !error
              ? t('translation:dynamicPageTemplate.config.progress')
              : ''}
          </ECTypography>
          {success ? (
            <CheckIcon
              fontSize="large"
              color="success"
              style={{ fontSize: '96px' }}
            />
          ) : error ? (
            <ErrorIcon
              fontSize="large"
              color="error"
              style={{ fontSize: '96px' }}
            />
          ) : (
            <LinearProgressWithLabel value={progress} />
          )}
        </ECBox>
      </ECBox>
    );
  }
  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.default}
      flexDirection="column"
      sx={{ width: '90%', marginTop: '20%', marginLeft: '10%' }}
      textAlign="center"
      alignItems="center"
      p={3}
    >
      <ECBox textAlign="center">
        <DotFlashing></DotFlashing>
        <ECTypography
          sx={{ marginLeft: '-50%', fontWeight: 700 }}
          color={fullColor}
        >
          {t('translation:dynamicPageTemplate.config.progress')}
        </ECTypography>
      </ECBox>
    </ECBox>
  );
};
