import { ECTabContainer } from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { AssetTypePage } from '..';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

export function AssetWorkflowPage() {
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:panelDrawer.assetWorkflow')} - %s`}
        defaultTitle={t('translation:panelDrawer.assetWorkflow')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'asset-types',
            label: t('translation:panelDrawer.assetTypes'),
            content: <AssetTypePage />,
            scopes: [P.GetAllAssetTypes],
            link: 'asset-types',
          },
          {
            value: 'asset-type-to-name',
            label: t('translation:panelDrawer.assetTypeToName'),
            content: <Outlet />,
            scopes: [P.GetAllAssetTypesWithNames],
            link: 'asset-type-to-name',
          },
          {
            value: 'asset-trade-to-problem',
            label: t('translation:panelDrawer.assetTradeProblem'),
            content: <Outlet />,
            link: 'asset-trade-to-problem',
          },
          {
            value: 'trades',
            label: t('translation:panelDrawer.trades'),
            content: <Outlet />,
            scopes: [P.GetAllTrades],
            link: 'trades',
          },
          {
            value: 'problems',
            label: t('translation:panelDrawer.problems'),
            content: <Outlet />,
            scopes: [P.GetAllProblemTypes],
            link: 'problems',
          },
          {
            value: 'failures',
            label: t('translation:panelDrawer.failures'),
            content: <Outlet />,
            scopes: [P.GetAllRepairTypes],
            link: 'failures',
          },
          {
            value: 'asset-type-to-failure',
            label: t('translation:panelDrawer.assetTypeToRepair'),
            content: <Outlet />,
            scopes: [P.GetAllAssetTypesWithRepairs],
            link: 'asset-type-to-failure',
          },
        ]}
      />
    </>
  );
}
