import { Checkbox, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { useMemo, } from 'react';
import _ from 'lodash';
import { ECChip } from '../ECChip';

interface ECCustomSimpleSelectFilterProps {
    customSimpleSelectFilterOptions: any[];
    customSimpleSelectFilterLabel: string;
    customSimpleSelectFilterName: string;
    endpoint: string;
    onFilterSubmit?: () => void;
}

export const ECCustomSimpleSelectFilter = ({
    customSimpleSelectFilterOptions,
    customSimpleSelectFilterLabel,
    customSimpleSelectFilterName,
    endpoint,
    onFilterSubmit,
}: ECCustomSimpleSelectFilterProps) => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  return (
    <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        width="200px"
        height="40px"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-around"
      >
        {!!customSimpleSelectFilterOptions?.length && (
          <>
            <ECTypography
              color={theme => theme.palette.text.secondary}
              variant="caption"
            >
              {customSimpleSelectFilterLabel}:
            </ECTypography>
            <ECSelect
              labelId="mutiple-select-label"
              multiple
              variant="standard"
              disableUnderline
              value={activeFilter?.[customSimpleSelectFilterName] || []}
              onClose={() => {
                if (
                  activeFilter?.[customSimpleSelectFilterName]?.length === 0
                ) {
                  dispatch(
                    setActiveFilter({
                      [customSimpleSelectFilterName]:
                        customSimpleSelectFilterOptions?.map(
                          option => option?.id,
                        ) || [],
                      endpoint,
                    }),
                  );
                }
                onFilterSubmit?.();
              }}
              onChange={e => {
                const value = e.target.value as any;
                if (value[value.length - 1] === 'all') {
                  dispatch(
                    setActiveFilter({
                      [customSimpleSelectFilterName]:
                        activeFilter?.[customSimpleSelectFilterName]?.length ===
                        customSimpleSelectFilterOptions.length
                          ? []
                          : customSimpleSelectFilterOptions?.map(
                              option => option?.id,
                            ),
                      endpoint,
                    }),
                  );
                  onFilterSubmit?.();
                  return;
                }
                dispatch(
                  setActiveFilter({
                    [customSimpleSelectFilterName]: value,
                    endpoint,
                  }),
                );
                onFilterSubmit?.();
              }}
              displayEmpty
              renderValue={(selected: any) => {
                if (
                  !selected?.length ||
                  selected?.length === customSimpleSelectFilterOptions?.length
                ) {
                  return (
                    <ECChip
                      sx={{ width: '48px', height: '24px' }}
                      label="All"
                      color="Light Grey"
                    />
                  );
                }

                return (
                  <ECChip
                    sx={{ width: '48px', height: '24px' }}
                    label={selected?.length}
                    color="Dark Blue"
                  />
                );
              }}
            >
              <ECMenuItem value="all">
                <ECTypography
                  variant="body1"
                  color={theme => theme.palette.primary.main}
                >
                  {activeFilter?.[customSimpleSelectFilterName]?.length ===
                  customSimpleSelectFilterOptions?.length
                    ? 'Deselect All'
                    : 'Select All'}
                </ECTypography>
              </ECMenuItem>
              {customSimpleSelectFilterOptions?.map(option => (
                <ECMenuItem key={option?.id} value={option?.id}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        !!(
                          activeFilter?.[customSimpleSelectFilterName] &&
                          activeFilter?.[
                            customSimpleSelectFilterName
                          ]?.indexOf?.(option?.id) > -1
                        )
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={option?.label} />
                </ECMenuItem>
              ))}
            </ECSelect>
          </>
        )}
      </Stack>
  );
};
