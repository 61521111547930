import { ECDynamicPageTemplate, ECBox } from 'app/components';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  InputAdornment,
  FormControl,
  Input,
  FormHelperText,
  Grid,
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import {
  ECWarrantyBadge,
  WarrantyBadgeTypes,
} from 'app/components/ECWarrantyBadge';

import {
  useLazyGetAssetsWarrantyComponentQuery,
  useGetAssetsWarrantyQuery,
  useGetWarrantyPeriodsQuery,
  useGetWarrantyTypesQuery,
} from 'services/warrantyApi';
import moment from 'moment';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { ECManufacturerWarrantyPeriodTable } from 'app/components/ECManufacturerWarrantyPeriodTable';
import { useParams } from 'react-router';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useEffect } from 'react';

function changeDateFormat(res) {
  return moment(res).format('MMM DD, YYYY');
}

interface WarrantyPageProps {
  onlyEdit?: boolean;
  onClose?: () => void;
}

export function WarrantyPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('warranty');
    dispatch(setFilterEndpoint('/filter/warranty'));
  }, []);

  const { data: warrantyList } = useGetAssetsWarrantyQuery(id ?? '', {
    skip: !id,
  });

  const { data: periodsList } = useGetWarrantyPeriodsQuery();

  const { data: typesList } = useGetWarrantyTypesQuery();

  const outletProps = useOutletContext() as WarrantyPageProps;

  return (
    <>
      <ECBox p={1} px={2} display="" justifyContent="space-between">
        <Typography variant="h4">
          {t('translation:warranty.manufacturer.title')}
        </Typography>
        <>
          <Typography variant="subtitle1">
            {t('translation:warranty.manufacturer.help')}
          </Typography>
          {/* 
            requested by product to remove this part
            <Alert severity="success">
              Manufacturer Warranty is Verified by Ecotrak
            </Alert> */}
          <ECBox m={1}>
            <ECManufacturerWarrantyPeriodTable
              values={warrantyList?.periodTable || []}
              notes={warrantyList?.notes || ''}
              readOnly={outletProps?.onlyEdit}
              assetId={id ?? ''}
              optionsWarrantyType={typesList || []}
              optionsPeriod={periodsList || []}
              onClose={outletProps?.onClose}
            />
          </ECBox>
        </>
      </ECBox>
      {!outletProps?.onlyEdit && (
        <>
          <ECBox p={1} px={2} display="" justifyContent="space-between">
            <Typography variant="h4">
              {t('translation:warranty.serviceprovider.title')}{' '}
              {warrantyList?.spName != '-' ? (
                !warrantyList?.materialIsExpired &&
                !warrantyList?.laborIsExpired ? (
                  <ECWarrantyBadge
                    warrantyBadgeType={WarrantyBadgeTypes.Active}
                    customizedLabel="Full Warranty"
                  />
                ) : !warrantyList?.materialIsExpired ||
                  !warrantyList?.laborIsExpired ? (
                  <ECWarrantyBadge
                    warrantyBadgeType={WarrantyBadgeTypes.Partial}
                  />
                ) : (
                  <ECWarrantyBadge
                    warrantyBadgeType={WarrantyBadgeTypes.Inactive}
                    showExpiredText
                  />
                )
              ) : (
                ''
              )}
            </Typography>
            <Typography variant="subtitle1">
              {t('translation:warranty.serviceprovider.help')}
            </Typography>
            {warrantyList && (
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Grid container gap={10} sx={{ marginBottom: '30px' }}>
                  <Grid md={6}>
                    <FormHelperText id="standard-weight-helper-text">
                      Service Provider Name
                    </FormHelperText>
                    <Input
                      aria-label=""
                      fullWidth
                      id="standard-adornment-weight"
                      defaultValue={warrantyList?.spName}
                      endAdornment={
                        <InputAdornment
                          sx={{ marginBottom: '20px' }}
                          position="end"
                        >
                          <ListItem>
                            <ListItemText
                              primary={String.fromCharCode(160)}
                              secondary={
                                String.fromCharCode(160) +
                                String.fromCharCode(160)
                              }
                            />
                          </ListItem>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        readOnly: true,
                        'aria-label': 'weight',
                      }}
                    />
                  </Grid>

                  <Grid md={5}>
                    <FormHelperText id="standard-weight-helper-text">
                      Material
                    </FormHelperText>
                    <Input
                      aria-label=""
                      fullWidth
                      id="standard-adornment-weight"
                      defaultValue={warrantyList?.material}
                      endAdornment={
                        <InputAdornment
                          sx={{ marginBottom: '20px' }}
                          position="end"
                        >
                          <ListItem>
                            <ListItemText
                              primary={
                                warrantyList?.material == '-' ? (
                                  ''
                                ) : warrantyList?.materialIsExpired ? (
                                  <Typography color="error" variant="subtitle1">
                                    Expired
                                  </Typography>
                                ) : (
                                  'Exp. Date:'
                                )
                              }
                              secondary={
                                warrantyList?.material !== '-' &&
                                !warrantyList?.materialIsExpired
                                  ? changeDateFormat(
                                      warrantyList?.materialExpirationDate,
                                    )
                                  : ''
                              }
                            />
                          </ListItem>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        readOnly: true,
                        'aria-label': 'weight',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container gap={10} sx={{ marginBottom: '30px' }}>
                  <Grid md={6}>
                    <FormHelperText id="standard-weight-helper-text">
                      Labor
                    </FormHelperText>
                    <Input
                      aria-label=""
                      fullWidth
                      id="standard-adornment-weight"
                      defaultValue={warrantyList?.labor}
                      endAdornment={
                        <InputAdornment
                          sx={{ marginBottom: '20px' }}
                          position="end"
                        >
                          <ListItem>
                            <ListItemText
                              primary={
                                warrantyList?.labor == '-' ? (
                                  ''
                                ) : warrantyList?.laborIsExpired ? (
                                  <Typography color="error" variant="subtitle1">
                                    Expired
                                  </Typography>
                                ) : (
                                  'Exp. Date:'
                                )
                              }
                              secondary={
                                warrantyList?.labor !== '-' &&
                                !warrantyList?.laborIsExpired
                                  ? changeDateFormat(
                                      warrantyList?.laborExpirationDate,
                                    )
                                  : ''
                              }
                            />
                          </ListItem>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        readOnly: true,
                        'aria-label': 'weight',
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
          </ECBox>

          <ECBox p={1} px={2} justifyContent="space-between" width="50%">
            <Typography variant="h4">
              {t('translation:warranty.component.title')}{' '}
              {warrantyList?.componentWarrantyStatus == 'expired' ? (
                <ECWarrantyBadge
                  customizedLabel="Expire Warranty"
                  warrantyBadgeType={WarrantyBadgeTypes.Inactive}
                />
              ) : (
                <ECWarrantyBadge
                  warrantyBadgeType={WarrantyBadgeTypes.Active}
                />
              )}
            </Typography>
            <Typography variant="subtitle1">
              {t('translation:warranty.component.help')}
            </Typography>
          </ECBox>

          <ECDynamicPageTemplate
            idToGetListBy={id}
            marginTop={false}
            pageTitle={t('translation:warranty.component.title')}
            useLazyGetListQuery={useLazyGetAssetsWarrantyComponentQuery}
            useCreateMutation={{}}
            withDrawer={false}
            enableExport
            showBadgeExpirationDate={true}
            shouldNotUseActiveFilter
          />
        </>
      )}
    </>
  );
}
