import { ECPaper } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyGetAreaListQuery } from 'services/areaApi';
import {
  useLazyGetAssetByIdQuery,
  useLazyGetServiceRequestAssetsListQuery,
} from 'services/assetApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCreateWorkorderMutation } from 'services/workOrdersApi';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { useLazyGetProblemListByAssetIdQuery } from 'services/problemApi';
import { useLazyGetSPsForServiceRequestQuery } from 'services/tradeApi';
import { SPByTradeAssignment } from 'types/TradeTypes';
import { useGetPriorityListQuery } from 'services/assetTypeApi';
import { WorkflowPriority } from 'app/components/ECWorkflowPriorityBadge';
import { Priority } from 'types/Priority';
import { useMatch } from 'react-router';
import { StyleConstants } from 'styles/StyleConstants';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

interface CopyWorkorderProps {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
}

const ASSETS_PER_PAGE = 10;
const REDIRECT_DEBOUNCE_TIME = 500;

export const CopyWorkorder = ({
  isOpen,
  existingData,
  onClose,
}: CopyWorkorderProps) => {
  const match = useMatch('/panel/*');
  const [selectedArea, setSelectedArea] = useState<any>();
  const currentAssets = useRef<any[]>([]);
  const [assetPage, setAssetPage] = useState(0);
  const [assetSearchText, setAssetSearchText] = useState<string | undefined>(
    undefined,
  );
  const [emergencyItem, setEmergencyItem] = useState<Priority | undefined>();
  const [overtimeApproved, setOvertimeApproved] = useState<boolean>(false);
  const [isSpPopulated, setIsSPpopulated] = useState<boolean>(false);
  const [isAttachmentsPopulated, setIsAttachmentsPopulated] =
    useState<boolean>(false);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      return {
        ...field,
        value: _.get(existingData, field.fieldName),
      };
    },
    [existingData],
  );
  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );
  const [selectedAreaValue, setSelectedAreaValue] = useState(
    existingData?.areaId,
  );
  const [selectedAsset, setSelectedAsset] = useState(existingData?.asset);

  const [triggerSP, resultSP] = useLazyGetSPsForServiceRequestQuery({});
  const { data: serviceProviders, isSuccess: isSuccessServiceProviders } =
    resultSP;

  const isGeneralAsset = useMemo(
    () => !!existingData?.generalAssetId,
    [existingData],
  );

  useEffect(() => {
    if (isOpen) {
      triggerSP({
        assetId: isGeneralAsset ? undefined : existingData?.asset?.id,
        problemId: existingData.problemId,
        generalAssetId: isGeneralAsset
          ? existingData?.generalAssetId
          : undefined,
        branchId: isGeneralAsset ? existingData?.branchId : undefined,
      });
      setSelectedArea({});
      setOvertimeApproved(false);
      setSelectedAreaValue(existingData?.areaId);
      setSelectedAsset(existingData?.asset);
      triggerAsset(existingData?.assetId);
      const updated = [...formFields];
      const requestedByField = updated?.find(
        field => field.fieldName === 'requestedBy',
      );
      if (requestedByField) {
        requestedByField.value = '';
      }
      const problemField = updated.find(
        field => field.fieldName === 'problemId',
      );
      if (problemField) {
        problemField.value = existingData?.problemId;
      }
      triggerProblem({
        id: existingData?.assetId,
        isGeneralAsset: !!existingData?.generalAssetId,
      });
      const assetField = updated?.find(field => field.fieldName === 'asset');
      assetField.value = existingData?.asset;
      setFormFields(updated);
    }
  }, [isOpen, isGeneralAsset]);

  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery();
  const { data: asset, isSuccess: isSuccessAsset } = resultAsset;

  useEffect(() => {
    if (asset && isSuccessAsset) {
      existingData.areaId = asset?.areaId;
      existingData.area = { id: asset?.areaId, name: asset?.area?.name };
      const resourcesField = formFields?.find(
        field => field.fieldName === 'files',
      );
      if (
        resourcesField &&
        resourcesField.value === undefined &&
        existingData?.files
      ) {
        resourcesField.value = existingData?.files;
      }
      setIsAttachmentsPopulated(true);
    }
  }, [asset, isSuccessAsset, isOpen, isAttachmentsPopulated]);

  useEffect(() => {
    const handleOnClose = () => {
      if (fancyFormElementsCreate.fields) {
        setFormFields(fancyFormElementsCreate.fields.map(handleExistingData));
      }
      setIsSPpopulated(false);
    };
    if (isOpen === false) {
      handleOnClose();
    }
  }, [isOpen, onClose]);

  const [triggerArea, areaResult] = useLazyGetAreaListQuery();
  const { data: areas, isSuccess: isSuccessArea } = areaResult;

  const [triggerAssets, assetsResult] = useLazyGetServiceRequestAssetsListQuery(
    {},
  );
  const {
    data: assets,
    isSuccess: isSuccessAssets,
    isFetching: isFetchingAssets,
  } = assetsResult;

  const fieldToGetAlias = assets?.config.fields.filter(
    f => f.name === 'name' || f.name === 'assetType.name',
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!selectedArea) {
      setFormFields(prevFormFields =>
        prevFormFields.map(field => {
          if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
            return {
              ...field,
              value: null,
            };
          }
          return {
            ...field,
            disabled: false,
          };
        }),
      );
      currentAssets.current = [];
      setAssetPage(0);
      setAssetSearchText(undefined);
      triggerAssets({
        branchId: existingData?.locationId,
        p: 0,
        t: ASSETS_PER_PAGE,
      });
      return;
    }

    const newFormFields = formFields.map(field => {
      if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
        return {
          ...field,
          value: null,
        };
      }
      return {
        ...field,
      };
    });
    setFormFields(newFormFields);
    currentAssets.current = [];
    setAssetSearchText(undefined);
    setAssetPage(0);
    triggerAssets({
      areaId: selectedArea?.id,
      branchId: existingData?.locationId,
      p: 0,
      t: ASSETS_PER_PAGE,
    });
  }, [selectedArea]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    triggerArea({});
    currentAssets.current = [];
    setAssetSearchText(undefined);
    setAssetPage(0);
    triggerAssets({
      branchId: existingData?.locationId,
      p: 0,
      t: ASSETS_PER_PAGE,
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const searchParams = fieldToGetAlias?.reduce(
      (prevParams, fieldAlias, index) => ({
        ...prevParams,
        [`sb${index > 0 ? index : ''}`]: fieldAlias?.alias ?? fieldAlias?.name,
        [`s${index > 0 ? index : ''}`]: `*${assetSearchText}*`,
        [`sg${index > 0 ? index : ''}`]: '1',
        [`sglo${index > 0 ? index : ''}`]: 'or',
      }),
      {},
    );

    if (selectedArea?.id) {
      triggerAssets({
        areaId: selectedArea?.id,
        branchId: existingData?.locationId,
        p: assetPage,
        t: ASSETS_PER_PAGE,
        ...searchParams,
      });
      return;
    }
    triggerAssets({
      branchId: existingData?.locationId,
      p: assetPage,
      t: ASSETS_PER_PAGE,
      ...searchParams,
    });
  }, [assetPage, assetSearchText]);

  const currentAssetSearchText = useRef<string | undefined>('');
  const handleLoadMoreData = (fieldName: string, newValue?: string) => {
    if (newValue !== currentAssetSearchText.current) {
      setAssetPage(0);
      currentAssets.current = [];
      currentAssetSearchText.current = newValue;
      setAssetSearchText(newValue);
    } else {
      currentAssetSearchText.current = newValue;
      setAssetSearchText(newValue);
      setAssetPage(currentValue => currentValue + 1);
    }
  };

  useEffect(() => {
    if (isSuccessArea) {
      const [updatedFormFields, _fields] = populateDropDownFields({
        responseData: areas.data,
        createFormFields: formFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'areaId',
      });
      const areaField = updatedFormFields.find(
        field => field.fieldName === 'areaId',
      );
      if (areaField) {
        areaField.value = existingData?.areaId;
      }
    }
  }, [areas, isSuccessArea, isOpen]);

  const [triggerProblem, resultProblem] = useLazyGetProblemListByAssetIdQuery();
  const { data: problems, isSuccess: isSuccessProblems } = resultProblem;

  useEffect(() => {
    if (problems && isSuccessProblems) {
      let problemsData = [...problems];
      const alreadyPresentProblemIndex = problems.findIndex(
        problem => problem.id === existingData?.problemId,
      );
      if (alreadyPresentProblemIndex !== -1) {
        [problemsData[0], problemsData[alreadyPresentProblemIndex]] = [
          problemsData[alreadyPresentProblemIndex],
          problemsData[0],
        ];
      } else {
        problemsData = [
          {
            id: existingData?.problemId,
            name: existingData?.problem,
          },
          ...problemsData,
        ];
      }
      const updated = [...formFields];
      populateDropDownFields({
        responseData: _.uniqBy(problemsData, 'id'),
        editFormFields: updated,
        dontChangeValues: true,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problemId',
      });
      setFormFields(updated);
    }
  }, [problems, isSuccessProblems, isOpen]);

  useEffect(() => {
    if (isSuccessServiceProviders && serviceProviders) {
      let serviceProvidersData = [...serviceProviders];
      const alreadyPresentSPIndex = serviceProvidersData?.findIndex(
        sp => sp && sp.id === existingData?.spCompanyId,
      );
      if (alreadyPresentSPIndex === -1) {
        serviceProvidersData = [
          {
            id: existingData?.spCompanyId,
            name: existingData?.serviceProviderName,
          } as SPByTradeAssignment,
          ...serviceProvidersData,
        ];
      }
      const fiteredServiceProvidersData = serviceProvidersData?.filter(
        item => !!item,
      );

      const updatedFormFields = [...formFields];

      const spFieldIndex = updatedFormFields.findIndex(
        field => field.fieldName === 'serviceProvider',
      );

      if (spFieldIndex !== -1) {
        const spField = { ...updatedFormFields[spFieldIndex] };
        spField.options = fiteredServiceProvidersData;

        const selectedServiceProvider = fiteredServiceProvidersData?.find(
          sp => sp.name === existingData?.serviceProviderName,
        );
        spField.value = selectedServiceProvider?.id;
        updatedFormFields[spFieldIndex] = spField;
        setFormFields(updatedFormFields);
        setIsSPpopulated(true);
      }
    }
  }, [serviceProviders, isSuccessServiceProviders, isOpen, isSpPopulated]);

  useEffect(() => {
    if (isSuccessAssets && !isFetchingAssets) {
      currentAssets.current = _.uniqBy(
        [...currentAssets.current, ...assets.data],
        'id',
      );
      const [updatedFormFields, _fields] = populateDropDownFields({
        responseData: currentAssets.current,
        responseConfig: assets.config,
        createFormFields: formFields,
        dataValuesFieldName: 'id',
        formFieldName: 'asset',
      });
      const assetField = updatedFormFields.find(
        field => field.fieldName === 'asset',
      );
      const selectedAreaFieldValue = updatedFormFields.find(
        field => field.fieldName === 'areaId',
      )?.value;
      if (assetField && selectedAreaFieldValue === existingData?.areaId) {
        assetField.value = selectedAsset;
      }
      const overtimeApprovedField = updatedFormFields.find(
        field => field.fieldName === 'overtimeApproved',
      );
      if (overtimeApprovedField) {
        overtimeApprovedField.value = existingData.overtimeApproved;
      }
      setFormFields(
        updatedFormFields.map(field => ({
          ...field,
        })),
      );
    }
  }, [assets, isSuccessAssets, isFetchingAssets]);

  const { data: priorityData, isSuccess: isPrioritySuccess } =
    useGetPriorityListQuery(
      {},
      {
        skip: !isOpen,
      },
    );

  useEffect(() => {
    if (isPrioritySuccess && priorityData) {
      populateDropDownFields({
        responseData: priorityData,
        createFormFields: formFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priorityId',
      });
      setEmergencyItem(
        priorityData?.data?.find(
          item => item.name === WorkflowPriority.Emergency,
        ),
      );
    }
  }, [isPrioritySuccess, priorityData, isOpen]);

  useEffect(() => {
    const selectedAreaFieldValue = formFields?.find(
      field => field.fieldName === 'areaId',
    )?.value;
    const selectedArea = areas?.data.find(
      area => area.id === selectedAreaFieldValue,
    );
    setSelectedArea(selectedArea);
  }, [selectedAreaValue, areas]);

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (fieldName === 'requestedBy') return;
      if (fieldName === 'description') return;

      const selectedAreaFieldValue = output.find(
        field => field.fieldName === 'areaId',
      )?.value;
      const assetField = output.find(field => field.fieldName === 'asset');
      const problemField = output.find(
        field => field.fieldName === 'problemId',
      );
      const spField = output?.find(
        field => field.fieldName === 'serviceProvider',
      );
      const priorityField = output?.find(
        field => field?.fieldName === 'priorityId',
      );
      const overtimeApprovedField = output?.find(
        field => field?.fieldName === 'overtimeApproved',
      );
      const assetFieldValue: any = assetField?.value;
      if (
        fieldName === 'areaId' &&
        assetField &&
        problemField &&
        spField &&
        priorityField &&
        overtimeApprovedField
      ) {
        assetField.value = null;
        problemField.value = null;
        priorityField.value = null;
        spField.options = [];
        overtimeApprovedField.value = 0;
        setSelectedArea(
          areas?.data.find(area => area.id === selectedAreaFieldValue),
        );
      } else if (problemField && spField && fieldName === 'asset') {
        problemField.disabled = false;
        problemField.value = null;
        spField.value = null;
        if (!!assetFieldValue) {
          triggerProblem({
            id: assetFieldValue?.id,
            isGeneralAsset: assetFieldValue.isGeneralAsset,
          });
        }
      }

      if (!assetFieldValue && spField && problemField) {
        spField.options = [];
        problemField.options = [];
        problemField.value = null;
        spField.value = null;
      }

      if (
        fieldName === 'problemId' &&
        !!problemField &&
        !!problemField?.value &&
        assetFieldValue &&
        spField
      ) {
        const isGeneralAsset = Number(assetFieldValue.isGeneralAsset);
        spField.options = [];
        triggerSP({
          assetId: !isGeneralAsset ? assetFieldValue.id : undefined,
          problemId: Number(problemField.value),
          generalAssetId: isGeneralAsset
            ? Number(assetFieldValue.id)
            : undefined,
          branchId: isGeneralAsset ? existingData?.branchId : undefined,
        });
      }

      if (
        overtimeApprovedField &&
        priorityField?.value === emergencyItem?.id &&
        !overtimeApproved
      ) {
        overtimeApprovedField.value = 1;
        setOvertimeApproved(true);
      } else {
        setOvertimeApproved(false);
      }
      setSelectedAreaValue(selectedAreaFieldValue as number);
      setSelectedAsset(assetField?.value);
      setFormFields(
        output.map(field => ({
          ...field,
        })),
      );
    },
    [
      areas,
      existingData,
      isOpen,
      triggerProblem,
      triggerSP,
      emergencyItem,
      formFields,
      overtimeApproved,
      selectedAreaValue,
    ],
  );

  const [
    doCreateWorkorder,
    {
      data: createWorkorderData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateWorkorder,
    },
  ] = useCreateWorkorderMutation();

  const useCreateCopyWorkorder = useCallback(() => {
    const doCreate = async data => {
      delete data.undefined;
      doCreateWorkorder({
        ...data,
        areaId: data?.areaId?.id,
        assetId: data?.asset?.id,
        branchId: existingData?.branchId,
        isGeneralAsset: data?.asset?.isGeneralAsset,
        generalAssetId: data?.asset?.isGeneralAsset,
        priorityId: data?.priorityId?.id,
        problemId: data?.problemId?.id,
        spCompanyId: data?.serviceProvider,
        requestCategoryId: existingData?.requestCategoryId,
        originalCopiedWorkorderId: existingData?.originalCopiedWorkorderId,
      });
    };

    return [
      doCreate,
      {
        data: createWorkorderData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ];
  }, [
    createWorkorderData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    doCreateWorkorder,
  ]);

  useEffect(() => {
    if (createWorkorderData?.id) {
      resetCreateWorkorder();
      const setLocation = () => {
        window.location.href = `${match?.pathnameBase}/${existingData?.moduleName}/${createWorkorderData.id}`;
      };
      const debouncedSetLocation = _.debounce(
        setLocation,
        REDIRECT_DEBOUNCE_TIME,
      );
      debouncedSetLocation();
    }
  }, [
    createWorkorderData?.id,
    existingData,
    isOpen,
    match?.pathnameBase,
    resetCreateWorkorder,
  ]);
  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper
        sx={{ height: '100%', paddingTop: StyleConstants.NAV_BAR_HEIGHT }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={useCreateCopyWorkorder}
          formConfig={fancyFormElementsCreate.config}
          formFields={
            isSpPopulated && isAttachmentsPopulated ? formFields : formFields
          }
          onChange={handleOutputChange}
          onClose={onClose}
          isLoading={isFetchingAssets}
          onLoadMoreData={handleLoadMoreData}
          drawerTitleBarBGColor={themes.light.palette.other.darkInfoWarranty}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
