// Need to use the React-specific entry point to import createApi
import {
  AddPermissionsToRoleParams,
  CreateRoleParams,
  DeletePermissionsFromRoleParams,
  DeleteRoleParams,
  Role,
} from 'types/Role';
import { QueryParams } from 'types/QueryParams';
import { rolePermissionsApi } from './rolePermissionApi';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Role', 'RolePermission'],
});

// Define a service using a base URL and expected endpoints
export const rolesApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getRolesList: builder.query<Role[], QueryParams>({
      query: params => {
        return {
          url: `role`,
          params,
        };
      },
      providesTags: ['Role'],
    }),
    createRole: builder.mutation<Role, CreateRoleParams>({
      query: body => ({
        url: 'role',
        method: 'post',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    updateRole: builder.mutation<void, Role>({
      query: role => {
        return {
          url: `role`,
          method: 'put',
          body: role,
        };
      },
      invalidatesTags: ['Role'],
    }),
    deleteRole: builder.mutation<void, DeleteRoleParams>({
      query: params => ({
        url: `role/${params.role.roleId}`,
        method: 'delete',
        body: params.role,
      }),
      invalidatesTags: ['RolePermission'],
      async onQueryStarted(
        { role, lastQueryParams },
        { dispatch, queryFulfilled },
      ) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update
        const patchResult = dispatch(
          rolePermissionsApi.util.updateQueryData(
            'getRolePermissionsList',
            lastQueryParams,
            draft => {
              // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
              const rolePermission = draft.data.find(
                rolePermission => rolePermission.roleId === role.roleId,
              );
              if (rolePermission) {
                const rolePermissionIndex = draft.data.indexOf(rolePermission);
                draft.data.splice(rolePermissionIndex, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addPermissionsToRole: builder.mutation<void, AddPermissionsToRoleParams>({
      query: params => {
        return {
          url: `role/${params.roleId}/permission`,
          method: 'post',
          body: params.permissionIds,
        };
      },
      invalidatesTags: ['Role', 'RolePermission'],
    }),
    deletePermissionsFromRole: builder.mutation<
      void,
      DeletePermissionsFromRoleParams
    >({
      query: params => {
        return {
          url: `role/${params.roleId}/permission`,
          method: 'delete',
          body: params.permissionIds,
        };
      },
      invalidatesTags: ['Role', 'RolePermission'],
    }),
    getControlledRoles: builder.query<any[], void>({
      query: () => {
        return {
          url: 'role/controlled',
        };
      },
    }),
  }),
});

export const {
  useGetRolesListQuery,
  useLazyGetRolesListQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useAddPermissionsToRoleMutation,
  useDeletePermissionsFromRoleMutation,
  useGetControlledRolesQuery,
  useLazyGetControlledRolesQuery,
} = rolesApi;
